import { connect } from 'react-redux';
import BankJournal from '../../../../views/Finance/Bookkeeping/BankJournal/BankJournal.finance.view';
import { getBankJournal, postJournalPosting, postJournalUnposting } from '../../../../states/thunks/finance.thunk';


function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBankJournal: payload => dispatch(getBankJournal(payload)),
    handlePostJournalPosting: payload => dispatch(postJournalPosting(payload)),
    handlePostJournalUnposting: payload => dispatch(postJournalUnposting(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BankJournal);
