import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';

export default function UploadRPPForm(props) {
    const { onFormChange, form } = props;
    return (
        <div className="nilai-kognitif__custom-form" >
            <div className="nilai-kognitif__custom-form-row">
                <div className="nilai-kognitif__custom-form-column__field">
                    <Input
                        name ="tahun_ajaran"
                        label="Tahun Ajaran"
                        onChange={onFormChange}
                        placeholder={'Tahun Ajaran'}
                        style = {{backgroundColor : 'gray'}}
                    />
                    <Input
                        name ="mata_pelajaran"
                        label="Mata pelajaran"
                        onChange={onFormChange}
                        placeholder={'Mata Pelajaran'}
                        style = {{backgroundColor : 'gray'}}
                    />
                    <Input
                        name ="kelas"
                        label="Kelas"
                        onChange={onFormChange}
                        placeholder={'Kelas'}
                        style = {{backgroundColor : 'gray'}}
                    />
                    <Input
                        name ="type"
                        label="Type"
                        onChange={onFormChange}
                        placeholder={'Type'}
                        style = {{backgroundColor : 'gray'}}
                    />
                </div>
                <div className="nilai-kognitif__custom-form-column__field">
                    <h2>Tujuan Pembelajaran</h2>
                    <Textarea
                        name ="tujuan_pembelajaran"
                        style ={{backgroundColor : 'yellow', height : 100}}
                        onChange={onFormChange}
                    />
                </div>
            </div>
        </div>
    );
}