import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop } from "lodash";
import { Input, Button, Select, Input2 } from "../../../../components/base/index";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";

const padStr = (num) => {
  // if (num)
  // num.length > 0
  // num !==
  if (num !== "" && num !== null && typeof num !== "undefined") {
    return num.toString().padStart(2, "0");
    // if (num < 10) {
    //   return "0" + num;
    // }
    // return num;
  }

  return null;
};

export default class JadwalKelasTable extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list,
      error,
      onChange,
      fieldName,
      plafond_name,
      onFormChange,
      param,
    } = this.props;

    const Matpel = [
      { label: "Bahasa Indonesia", value: "1" },
      { label: "Matematika", value: "2" },
      { label: "Pendidikan Kewarganegaraan", value: "3" },
      { label: "Pendidikan Jasmani Olahraga Kesehatan", value: "4" },
    ];

    const Pengajar = [
      { label: "Renang", value: "1" },
      { label: "Badminton", value: "2" },
      { label: "Basket", value: "3" },
    ];
    const hour = [
      { label: "00", value: "00" },
      { label: "01", value: "01" },
      { label: "02", value: "02" },
      { label: "03", value: "03" },
      { label: "04", value: "04" },
      { label: "05", value: "05" },
      { label: "06", value: "06" },
      { label: "07", value: "07" },
      { label: "08", value: "08" },
      { label: "09", value: "09" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" },
      { label: "13", value: "13" },
      { label: "14", value: "14" },
      { label: "15", value: "15" },
      { label: "16", value: "16" },
      { label: "17", value: "17" },
      { label: "18", value: "18" },
      { label: "19", value: "19" },
      { label: "20", value: "20" },
      { label: "21", value: "21" },
      { label: "22", value: "22" },
      { label: "23", value: "23" },
    ];
    const minute = [
      { label: "00", value: "00" },
      { label: "01", value: "01" },
      { label: "02", value: "02" },
      { label: "03", value: "03" },
      { label: "04", value: "04" },
      { label: "05", value: "05" },
      { label: "06", value: "06" },
      { label: "07", value: "07" },
      { label: "08", value: "08" },
      { label: "09", value: "09" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" },
      { label: "13", value: "13" },
      { label: "14", value: "14" },
      { label: "15", value: "15" },
      { label: "16", value: "16" },
      { label: "17", value: "17" },
      { label: "18", value: "18" },
      { label: "19", value: "19" },
      { label: "20", value: "20" },
      { label: "21", value: "21" },
      { label: "22", value: "22" },
      { label: "23", value: "23" },
      { label: "24", value: "24" },
      { label: "25", value: "25" },
      { label: "26", value: "26" },
      { label: "27", value: "27" },
      { label: "28", value: "28" },
      { label: "29", value: "29" },
      { label: "30", value: "30" },
      { label: "31", value: "31" },
      { label: "32", value: "32" },
      { label: "33", value: "33" },
      { label: "34", value: "34" },
      { label: "35", value: "35" },
      { label: "36", value: "36" },
      { label: "37", value: "37" },
      { label: "38", value: "38" },
      { label: "39", value: "39" },
      { label: "40", value: "40" },
      { label: "41", value: "41" },
      { label: "42", value: "42" },
      { label: "43", value: "43" },
      { label: "44", value: "44" },
      { label: "45", value: "45" },
      { label: "46", value: "46" },
      { label: "47", value: "47" },
      { label: "48", value: "48" },
      { label: "49", value: "49" },
      { label: "50", value: "50" },
      { label: "51", value: "51" },
      { label: "52", value: "52" },
      { label: "53", value: "53" },
      { label: "54", value: "54" },
      { label: "55", value: "55" },
      { label: "56", value: "56" },
      { label: "57", value: "57" },
      { label: "58", value: "58" },
      { label: "59", value: "59" },
    ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>
              Tanggal
            </th>
            <th className="manage-budget__row table" style={{ width: "25%" }}>
              Kegiatan
            </th>
            <th className="manage-budget__row table" style={{ width: "20%" }}>
              Pengajar
            </th>
            <th className="manage-budget__row table" style={{ width: "15%" }}>
              Start (hh:mm)
            </th>
            <th className="manage-budget__row table" style={{ width: "15%" }}>
              End (hh:mm)
            </th>
            <th className="manage-budget__row table" style={{ width: "2%" }}>
              Hapus
            </th>
          </tr>
        </thead>
        <tbody>
          {map(list, (data, idx) => (
            <tr key={`plafon_journal_data__${idx}`}>
              <td>{idx + 1}</td>
              <td>
                <Input2
                  type='date'
                  value={data.date}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name='pelajaran'
                  name='date'
                  onChange={onFormChange}
                />
                {/* <Input2
                  type="date"
                  inputArray
                  arrayPosition={idx}
                  fieldName="pelajaran"
                  name="date"
                  value={data.date}
                  onChange={onFormChange}
                /> */}
              </td>
              <td>
                <Input
                  type="text"
                  value={data.activities_name}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="pelajaran"
                  name="activities_name"
                  onChange={onFormChange}
                />
              </td>
              <td className="nominal">
                <Select
                  value={data.teacher_id}
                  placeholder="Pilih Pengajar"
                  data={param.teacher}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="pelajaran"
                  name="teacher_id"
                  onChange={onFormChange}
                />
              </td>
              <td>
                <Select
                  style={{ width: "80%" }}
                  placeholder="Jam"
                  value={padStr(data.start_hour)}
                  data={hour}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="pelajaran"
                  name="start_hour"
                  onChange={onFormChange}
                />
                <p>:</p>
                <Select
                  style={{ width: "80%" }}
                  placeholder="Menit"
                  value={padStr(data.start_minute)}
                  data={minute}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="pelajaran"
                  name="start_minute"
                  onChange={onFormChange}
                />
              </td>
              <td className="nominal">
                <Select
                  style={{ width: "80%" }}
                  placeholder="Jam"
                  value={padStr(data.end_hour)}
                  data={hour}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="pelajaran"
                  name="end_hour"
                  onChange={onFormChange}
                />
                <p>:</p>
                <Select
                  style={{ width: "80%" }}
                  placeholder="Menit"
                  value={padStr(data.end_minute)}
                  data={minute}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="pelajaran"
                  name="end_minute"
                  onChange={onFormChange}
                />
              </td>

              <td>
                <Button
                  onClick={() => this._onDeleteList(idx)}
                  icon="icon-trash-o"
                />
              </td>
            </tr>
          ))}
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={7}>
              <div>
                <Button icon="icon-plus" onClick={this._onAddList} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

JadwalKelasTable.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,
};
JadwalKelasTable.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
  plafond_name: [],
};
