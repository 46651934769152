import { connect } from 'react-redux';
import ClosingJournal from '../../../../views/Finance/Bookkeeping/ClosingJournal/ClosingJournal.bookkeeping.view';
import { getClosingJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetClosingJournal: payload => dispatch(getClosingJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(ClosingJournal);
