import { connect } from 'react-redux';
import GeneralJournal from '../../../../views/Finance/Bookkeeping/GeneralJournal/GeneralJournal.bookkeeping.view';
import { getGeneralJournal, postJournalPosting, postJournalUnposting } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetGeneralJournal: payload => dispatch(getGeneralJournal(payload)),
    handlePostJournalPosting: payload => dispatch(postJournalPosting(payload)),
    handlePostJournalUnposting: payload => dispatch(postJournalUnposting(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralJournal);
