import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class Ulangan extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickDaftarNilai = this.onClickDaftarNilai.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickAspekPerkembangan = this.onClickAspekPerkembangan.bind(this);
    this.onClickIndikatorSikap = this.onClickIndikatorSikap.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickCatatanAnekdot = this.onClickCatatanAnekdot.bind(this);
  }

  onClickCatatanAnekdot() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/catatan-anekdot');
  }

  onClickDaftarNilai() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickAspekPerkembangan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-kompetensi-dasar');
  }

  onClickIndikatorSikap() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }


  render() {
    return (
      <div className="finance">
        {/* <div className="finance__list">
          <button onClick={this.onClickIndikatorSikap}>
            <img src={budgeting} alt="psikafek" />
            <h3>Pengisian Indikator Sikap</h3>
          </button>
        </div> */}
        <div className="finance__list">
          <button onClick={this.onClickNilaiKognitif}>
            <img src={budgeting} alt="NilaiKognitif" />
            <h3>Pengisian Nilai</h3>
          </button>
        </div>
        {/* <div className="finance__list">
          <button onClick={this.onClickAspekPerkembangan}>
            <img src={budgeting} alt="AspekPerkembangan" />
            <h3>Pengisian Aspek Perkembangan & Kompetensi Dasar</h3>
          </button>
        </div> */}
        {/* <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={budgeting} alt="observasiharian" />
            <h3>Pengisian Daftar Nilai</h3>
          </button>
        </div> */}
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilai}>
            <img src={budgeting} alt="DaftarNilai" />
            <h3>Daftar Nilai</h3>
          </button>
        </div>
        {/* <div className="finance__list">
          <button onClick={this.onClickCatatanAnekdot}>
            <img src={budgeting} alt="CatatanAnekdot" />
            <h3>Catatan Anekdot</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
Ulangan.propTypes = {
  history: PropTypes.object.isRequired,
};
