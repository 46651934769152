import { connect } from 'react-redux';
import DataSiswa from '../../../views/Student/DataSiswa/DataSiswa.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},

  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSiswa);


