import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';

export default class Inventaris extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickBudgeting = this.onClickBudgeting.bind(this);
    this.onClickBookkeping = this.onClickBookkeping.bind(this);
  }

  onClickBudgeting() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas');
  }

  onClickBookkeping() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan');
  }

  render() {
    return (
      <div className="finance">
        
        <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Tanah</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Gedung</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Kendaraan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Barang</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Lokasi</h3>
          </button>
        </div>
      
      </div>
    );
  }
}
Inventaris.propTypes = {
  history: PropTypes.object.isRequired,
};
