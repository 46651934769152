import { connect } from 'react-redux';
import Result from '../../views/Admission/Result.admission.view';
import { getResults } from '../../states/thunks/admission.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetResults: () => dispatch(getResults()),
  };
}

export default connect(null, mapDispatchToProps)(Result);
