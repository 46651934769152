import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';

export default class TabelInputSikap extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name,nis, periode, rincian
    } = this.props;

    const tipePlafon = [
      { label: '---', value: '---' },
      { label: 'Rutin', value: 'rutin' },
      { label: 'Kegiatan', value: 'kegiatan' },
      { label: 'Swadaya', value: 'swadaya' },
      { label: 'Dana Pemerintah', value: 'dana pemerintah' },
      { label: 'Biaya Pengembangan Pemerintah', value: 'biaya pengembangan pemerintah' },
    ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Aspek</th>
            <th>Predikat</th>
            <th>Deskripsi Sikap</th>
            <th style={{ width: "5%" }}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td className="nominal">
                <Select
                    value={data.plafond_id}
                    placeholder="Pilih Tipe Sikap"
                    data={nis}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='nis'
                    name='nis'
                    onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                 
                </td>
                <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='plafon'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={onChange}
                    value={data.no_invoice||''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='plafon'
                    data-input-type="text"
                    name="full_name"
                    onChange={onChange}
                    value={data.full_name||''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
               
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={13}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

TabelInputSikap.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,

};
TabelInputSikap.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  plafond_name: [],
};
