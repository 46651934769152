import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function TableControl(props) {


  const { onFormChange, form } = props;
  const psiko = [
    { label: 'Gunakan Nilai Skala 1 s/d 5', value: '1'},
    { label: 'Gunakan Nilai Basis 10 atau 100', value: '2'},
  ]
  const simpan_raport = [
    { label: 'Tidak', value: '1'},
    { label: 'Ya, Guru dapat simpan raport', value: '2'},
  ]
  const nilai_kognitif = [
    { label: 'Gunakan Semua ( UH - KO - Pengasan - UM - UU)', value: '1'},
    { label: 'Ulangan Harian - UM - UU saja', value: '2'},
  ]
  const jml = [
    { label: '1 kali', value: '1'},
    { label: '2 kali', value: '2'},
    { label: '3 kali', value: '3'},
  ]
  const penggabungan_nilai = [
    { label: 'Nilai Raport SEKOLAH', value: '1'},
    { label: 'Nilai Raport ASLI', value: '2'},
  ]
  const default_nilai = [
    { label: 'Nilai Raport NASIONAL (Nilai Asli dibulatkan 0 digit)', value: '1'},
    { label: 'Nilai Raport ASLI (Dari Perhitungan Ulangan Pembulatan 2 digit)', value: '2'},
    { label: 'Nilai Raport SEKOLAH (Sesuai kebijakan sekolah pembulatan Nol digit)', value: '3'},
  ]
  const nilai_raport = [
    { label: 'Apa Adanya (Basis 100 dibagi 25)', value: '1'},
    { label: 'Berdasarkan Parameter Range Angka Description 2', value: '2'},
    { label: 'Normal / Basis 100', value: '3'},
  ]
  const nh = [
    { label: 'Standar (Berdasarkan Total Nilai Masing-Masing', value: '1'},
    { label: 'Advanced (Berdasarkan Nilai UH, KO, dan PRK)', value: '2'},
  ]
  const pembulatan = [
    { label: 'Sama Seperti Nilai NASIONAL', value: '1'},
    { label: 'Basis 5 untuk akhiran 3 dan basi 0 untuk akhiran 8', value: '2'},
    { label: 'Tambahkan 2 digit bagi nilai akhir minimal 80', value: '3'},
  ]
  return (
    <div className="kode-matpel__custom-form">
        <div className="param-deskripsi__header-title--column">
            <div className="param-deskripsi__header-title--bold">
            {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
            Table Control
            </div>
            </div>
     
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
          <Select
            name="psychomotor_value"
            label="Nilai Psikomotorik"
            onChange={onFormChange}
            data={psiko}
            placeholder={'Pilihan'}
            value={form.value.psychomotor_value}
            error={form.error.psychomotor_value || ''}
          />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
            <Select
                name="teacher_save_raport"
                label="Guru Dapat Simpan Raport"
                onChange={onFormChange}
                data={simpan_raport}
                placeholder={'Pilihan'}
                value={form.value.teacher_save_raport}
                error={form.error.teacher_save_raport || ''}
            />
          
        </div>
        </div>

        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
       
        <Select
            name="cognitive_value"
            label="Pengisian Nilai Kognitif"
            onChange={onFormChange}
            data={nilai_kognitif}
            value={form.value.cognitive_value || ''}
            error={form.error.cognitive_value || ''}
            placeholder={'Pilihan'}
          />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
        <Select
             
             type='number'
             placeholder={'Pilihan'}
             name="amount_of_daily_test"
             label="Jumlah Ulangan Sehari"
             onChange={onFormChange}
             data={jml}
             value={form.value.amount_of_daily_test || ''}
             error={form.error.amount_of_daily_test || ''}
           />
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        

          <Select
              name="nh_multiple_teacher"
              label="NH Multiple Guru"
              placeholder={'Pilihan'}
              data={nh}
              onChange={onFormChange}
              value={form.value.nh_multiple_teacher || ''}
              error={form.error.nh_multiple_teacher || ''}
            />  
        </div>

        <div className="kode-matpel__custom-form-column__field">
        <Select
              name="merging_value"
              label="Penggabungan Nilai yang Dipakai"
              placeholder={'Pilihan'}
              data={penggabungan_nilai}
              onChange={onFormChange}
              value={form.value.merging_value || ''}
              error={form.error.merging_value || ''}
            />
          </div>
        </div>

        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
       

        <Select
           
           name="default_raport_value"
           label="Default Nilai Raport yang Dipakai"
           onChange={onFormChange}
           data={default_nilai}
           value={form.value.default_raport_value || ''}
           error={form.error.default_raport_value || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="kode-matpel__custom-form-column__field">
            
           <Select
              name="rounding_method"
              label="Metode Pembulatan (Khusus Nilai Raport Sekolah)"
              placeholder={'Pilihan'}
              data={pembulatan}
              onChange={onFormChange}
              value={form.value.rounding_method || ''}
              error={form.error.rounding_method || ''}
            />
          </div>
        </div>  
 

        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
           
           name="raport_value_ki"
           label="Nilai Raport KI 3 / KI 4"
           onChange={onFormChange}
           data={nilai_raport}
           value={form.value.raport_value_ki || ''}
           error={form.error.raport_value_ki || ''}
           placeholder={'Pilihan'}
         />
        </div>
      <div className="manage-registration__custom-form-row__field">
          <Input
              type="number"
              name="max_score"
              label="Nilai Tertinggi"
              onChange={onFormChange}
              value={form.value.max_score}
              error={form.error.max_score}
            />
            <div className="manage-registration__custom-form-row-datadiri">
            <Input
              type="number"
              name="lowest_score"
              label="Nilai Terendah"
              onChange={onFormChange}
              value={form.value.lowest_score}
              error={form.error.lowest_score}
            />
            </div>
          </div>
        </div>  


       

        </div>
        
 
   
  );
}
TableControl.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
TableControl.defaultProps = {
  onFormChange: noop,
};
