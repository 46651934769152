import { connect } from 'react-redux';
import BuatPrestasi from '../../../views/Student/displiner/BuatPrestasi.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {listAchievementTable, deleteAchievementTable, saveAchievementTable, editAchievementTable, getAchievementTable } from '../../../states/thunks/academics.thunk';
import { getAchievementGroupOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAchievementGroupOptions: (payload) => dispatch(getAchievementGroupOptions(payload)),
    handleGetAchievementTable: payload => dispatch(getAchievementTable(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveAchievementTable: (payload, goback) => dispatch(saveAchievementTable(payload, goback)),
    handleListAchievementTable: (payload, goback) => dispatch(listAchievementTable(payload, goback)),
    handleEditAchievementTable: (payload, goback) => dispatch(editAchievementTable(payload, goback)), 
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatPrestasi);


