import { connect } from 'react-redux';
import MasterDataKeu from '../../../../views/Finance/Spp/MasterData/MasterDataKeu.masterdata.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import {getClasses} from '../../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return{
    classes: state.options.classes,
  }
}



function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataKeu);
