import { generateYearList } from '../../../utils/transformer.util';

export const IDENTITAS_ADD_DATAKELAS_FORM_FIELDS = [{
  type: 'text',
  name: 'namasekolah',
  data: generateYearList(),
  label: 'Nama Sekolah',
  
  // full: true,
},
{
  type: 'select',
  name: 'jenis',
  label: 'Jenis Sekolah',
  placeholder: 'Pilih Jenis Sekolah',
  // full: true,
  data: [
    { label: 'Ricci I TK/Pre', value: 'ricci_I_tk/pre' },
    { label: 'Ricci I SD', value: 'ricci_I_sd' },
    { label: 'Ricci I SMP', value: 'ricci_I_sma' },
    { label: 'Ricci I SMA', value: 'ricci_I_sma' },
    { label: 'Ricci II TK/Pre', value: 'ricci_II_tk/pre' },
    { label: 'Ricci II SD', value: 'ricci_II_sd' },
    { label: 'Ricci II SMP', value: 'ricci_II_sma' },
    { label: 'Ricci II SMA', value: 'ricci_II_sma' },
  ],
},
{
  type: 'text',
  name: 'alamat',
  label: 'Alamat',
  
  // full: true,
 
},

{
  type: 'text',
  name: 'telp',
  label: 'Telepon',
  
  // full: true,
},
{
  type: 'text',
  name: 'kecamatan',
  label: 'Kecamatan',
},
{
  type: 'text',
  name: 'kelurahan',
  label: 'Kelurahan',
},
{
  type: 'text',
  name: 'kotamadya',
  label: 'Kotamadya',
},
{
  type: 'text',
  name: 'kodepropinsi',
  label: 'Kode Propinsi',
},
{
  type: 'text',
  name: 'kodepos',
  label: 'Kode Pos',
},
{
  type: 'text',
  name: 'kodesekolah',
  label: 'Kode Sekolah',
},
{
  type: 'text',
  name: 'koderayon',
  label: 'Kode Rayon',
},
{
  type: 'text',
  name: 'kodesubrayon',
  label: 'Kode Sub Rayon',
},
{
  type: 'text',
  name: 'web',
  label: 'Website',
},
{
  type: 'email',
  name: 'email',
  label: 'Email',
},
{
  type: 'text',
  name: 'nds',
  label: 'Nomor NDS',
},
{
  type: 'text',
  name: 'nss',
  label: 'Nomor NSS',
},
{
  type: 'text',
  name: 'npsn',
  label: 'Nomor NPSN',
},
{
  type: 'select',
  name: 'raport',
  label: 'Sistem Pembagian Raport',
  data: [
    { label: 'Semester[6 Bulan]', value: 'ricci_I_tk/pre' },
    { label: 'Ricci I SD', value: 'ricci_I_sd' },
    { label: 'Ricci I SMP', value: 'ricci_I_sma' },
    { label: 'Ricci I SMA', value: 'ricci_I_sma' },
    { label: 'Ricci II TK/Pre', value: 'ricci_II_tk/pre' },
    { label: 'Ricci II SD', value: 'ricci_II_sd' },
    { label: 'Ricci II SMP', value: 'ricci_II_sma' },
    { label: 'Ricci II SMA', value: 'ricci_II_sma' },
  ],
},
{
  type: 'text',
  name: 'nipkepala',
  label: 'NIP Kepala Sekolah',
},
{
  type: 'text',
  name: 'kplsekolah',
  label: 'Nama Kepala Sekolah',
},
{
  type: 'text',
  name: 'nippengawas',
  label: 'NIP Pengawas',
},
{
  type: 'text',
  name: 'pengawas',
  label: 'Nama Pengawas',
},

];

export const IDENTITAS_ADD_DATAKELAS_FORM_FIELDS2 = [{
  type: 'text',
  name: 'kelas',
  label: 'Di mulai Dari Kelas',

},

{
  type: 'text',
  name: 'tahun',
  label: 'Lama Pendidikan (Tahun)',

},
{
  type: 'text',
  name: 'jumlahjam',
  label: 'Jumlah Jam Pelajaran perhari',

},
{
  type: 'select',
  name: 'NIS',
  label: 'Menggunakan NIS/NISN',
  placeholder:'Silahkan Pilih',
  data: [
    { label: 'Semester[6 Bulan]', value: 'ricci_I_tk/pre' },
    { label: 'Ricci I SD', value: 'ricci_I_sd' },
    { label: 'Ricci I SMP', value: 'ricci_I_sma' },
    { label: 'Ricci I SMA', value: 'ricci_I_sma' },
    { label: 'Ricci II TK/Pre', value: 'ricci_II_tk/pre' },
    { label: 'Ricci II SD', value: 'ricci_II_sd' },
    { label: 'Ricci II SMP', value: 'ricci_II_sma' },
    { label: 'Ricci II SMA', value: 'ricci_II_sma' },
  ],


},
{
  type: 'dualInput',
  leftName: 'urutannis',
  leftLabel: 'Urutan Nis Dimulai Dari',
  leftPlaceholder: '',
  rightName: 'karakter',
  rightLabel: 'Sebanyak (Karakter)',
  rightPlaceholder: '',

},
{
  type: 'select',
  name: 'nilaitinggi',
  label: 'Nilai Tertinggi',
  data:[
    {label: '10', value:'10'},
    {label: '9', value: '9'},
    {label: '8', value: '8'},
    {label: '7', value: '7'},
    {label: '6', value: '6'},
    {label: '5', value: '5'},
    {label: '4', value: '4'},
    {label: '3', value: '3'},
    {label: '2', value: '2'},
    {label: '1', value: '1'},
    
  ],

},
{
  type: 'text',
  name: 'nilaikurang',
  label: 'Nilai Kurang Terendah',
},
{
  type: 'select',
  name: 'propinsi',
  label: 'Propinsi Default',
  placeholder: 'Pilihan',
  data:[
    {},
  ],
},
{
  type: 'select',
  name: 'jeniskelamin',
  label: 'Jenis Kelamin',
  placeholder: 'Pilih Jenis Kelamin',
  data:[
    {label:'Pria', value:'pria'},
    {label:'wanita', value:'wanita'},
  ]
},
{
  type: 'select',
  name: 'agama',
  label: 'Agama Default',
  placeholder: 'Pilih Agama',
  data:[
    {},
  ]
},
{
  type: 'select',
  name: 'warganegara',
  label: 'Warga Negara Default',
  placeholder: 'Silahkan Pilih',
  data:[
    {},
  ]
},
{
  type:'text',
  name: 'tempatlahir',
  label: 'Tempat Lahir Default',
  
},

];



export const BUDGET_REQUIRED_FIELD = ['parameter_code'];

export const IDENTITAS_DATAKELAS_TABLE_FIELDS = {
  label: [
    'No',
    'Nama Sekolah',
    'Unit',
    'Alamat Sekolah',
    'Opsi',
    
  ],
  value: [{}, { type: 'string', attribute: 'namasekolah' }, { type: 'string', attribute: 'unit' }, 
              { type: 'string', attribute: 'alamatsekolah'}, { type: 'string', attribute: 'opsi'}],
};

export const FINANCE_PAYMENT_JOURNAL_FORM_FIELDS = [{
  type: 'text',
  inputType: 'number',
  name: 'nominal',
  label: 'Nominal',

}];

export const BUDGET_KELOLA_ALOKASI_PENGELUARAN = [{
  type: 'date',
  name: 'tanggal',
  label: 'Tanggal',
  placeholder: '',
  full : true,
},
{
  type: 'text',
  name: 'no_transaksi',
  label: 'No Transaksi',
  placeholder: '',
  full : true,
},{
  type: 'text',
  name: 'unit',
  label: 'Unit',
  placeholder: '',
  full : true,
},



{
  type: 'select',
  name: 'period',
  data: generateYearList(),
  label: 'Periode',
  placeholder: 'pilih periode',
  full: true,
},
{
  type: 'text',
  inputType: 'number',
  name: 'total',
  label: 'Total',
  full : true,
},
];