import { connect } from 'react-redux';
import LaporanAbsensiKhusus from '../../../views/Personnel/Absensi/LaporanAbsensiKhusus.absensi.view';
import { getAssignment } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAssignment: payload => dispatch(getAssignment(payload)),
  };
}

export default connect(null, mapDispatchToProps)(LaporanAbsensiKhusus);