import React, { PureComponent } from 'react';

export default class StudentGrade extends PureComponent {
  componentDidMount() {

  }

  render() {
    return (
      <div className="student-grade">
        <div className="student-grade__header">
          <h1>III. LAPORAN PENILAIAN HASIL BELAJAR SISWA SMK</h1>
          <h3>NOMOR INDUK SISWA: 123123 / JOHN DOE</h3>
        </div>
        <div className="student-grade__content">
          <div className="student-grade__table-wrapper">
            <table className="student-grade__table">
              <thead>
                <tr>
                  <th className="student-grade__table--number">&nbsp;</th>
                  <th>Tahun Pelajaran</th>
                  <th colSpan={6}>2016 / 2017</th>
                  <th colSpan={6}>... / ...</th>
                  <th colSpan={6}>... / ...</th>
                  <th colSpan={6}>... / ...</th>
                  <th colSpan={2}>STTB</th>
                </tr>
                <tr>
                  <th className="student-grade__table--number">&nbsp;</th>
                  <th>Kelas</th>
                  <th colSpan={6}>XB</th>
                  <th colSpan={6}>-</th>
                  <th colSpan={6}>-</th>
                  <th colSpan={6}>-</th>
                  <th colSpan={2}>TH</th>
                </tr>
                <tr>
                  <th className="student-grade__table--number">&nbsp;</th>
                  <th>Semester / Cawu</th>
                  <th colSpan={2}>1</th>
                  <th colSpan={2}>2</th>
                  <th colSpan={2}>3</th>
                  <th colSpan={2}>1</th>
                  <th colSpan={2}>2</th>
                  <th colSpan={2}>3</th>
                  <th colSpan={2}>1</th>
                  <th colSpan={2}>2</th>
                  <th colSpan={2}>3</th>
                  <th colSpan={2}>1</th>
                  <th colSpan={2}>2</th>
                  <th colSpan={2}>3</th>
                  <th colSpan={2}>NILAI STTB</th>
                </tr>
                <tr>
                  <th>No</th>
                  <th>Mata Pelajaran</th>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                  <td>Angka</td>
                  <td>Huruf</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ekonomi</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>&nbsp;</td>
                  <td>Jumlah</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Peringkat Kelas Ke ... Dari ... Siswa</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Kelakuan: Baik / Cukup / Kurang</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Kerajinan: Baik / Cukup / Kurang</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Kerapihan: Baik / Cukup / Kurang</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Sakit</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Ijin</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Tanpa Keterangan</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>-</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Status Akhir Tahun</td>
                  <td colSpan={6}>
                    <div>
                      <p>Naik Ke Kelas...</p>
                      <p>Tinggal Di</p>
                    </div>
                  </td>
                  <td colSpan={6}>
                    <div>
                      <p>Naik Ke Kelas...</p>
                      <p>Tinggal Di</p>
                    </div>
                  </td>
                  <td colSpan={6}>
                    <div>
                      <p>Naik Ke Kelas...</p>
                      <p>Tinggal Di</p>
                    </div>
                  </td>
                  <td colSpan={6}>
                    <div>
                      <p>Naik Ke Kelas...</p>
                      <p>Tinggal Di</p>
                    </div>
                  </td>
                  <td colSpan={2}>-</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
StudentGrade.propTypes = {};
StudentGrade.defaultProps = {};
