import React, { PureComponent } from 'react';
import ListForm2 from '../../../components/ListForm/ListForm2.componet';
import { commaFormatted } from '../../../utils/transformer.util';
import PayrollTableForm from '../components/PayrollTableForm.component';
import { PERSONNEL_PENGISIAN_PAYROLL_FORM_FIELDS,FORMAL_EDUCATION_FORM, FORMAL_POTONGAN_YPL, FORMAL_POTONGAN_INTERN_SEKOLAH } from '../../../constants/personnel.constant';
import Button from '../../../components/base/Button/Button.component';

export default class ManageSalaryPayroll extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this.state = {
      form: {
        value: {
          potongan_ypl :[],
          potongan_itern_sekolah :[],
        },
        error: {
          potongan_ypl:[],
          potongan_itern_sekolah :[],
        },
      },
    };
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputArray) {
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        newListError[arrayPosition][name] = '';
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  render() {
    const { form } = this.state;
    const {
      pendidikan_formal,
      potongan_ypl,
      potongan_itern_sekolah,
      pendidikan_non_formal,
      pekerjaan_history,
      organisasi,
      keluarga,
    } = form.value;
    const {
      pendidikan_formal: pendidikan_formalErrors,
      potongan_itern_sekolah : potongan_itern_sekolahErrors,
      potongan_ypl: potongan_yplErrors,
      pendidikan_non_formal: pendidikan_non_formalErrors,
      pekerjaan_history: pekerjaan_historyErrors,
      organisasi: organisasiErrors,
      keluarga: keluargaErrors,
    } = form.error;
   // const { form } = this.state;
    return (
      <div className="manage-payroll">
        <h1 className="manage-payroll__title">Pengisian Payroll</h1>
        <form>
          <ListForm2
            form={form}
            formFields={PERSONNEL_PENGISIAN_PAYROLL_FORM_FIELDS}
            onFormChange={this._onFormChange}
          />
           <PayrollTableForm
              title="Potongan YPL"
              listField={FORMAL_POTONGAN_YPL}
              error={potongan_yplErrors}
              list={potongan_ypl}
              fieldName="potongan_ypl"
              onAddList={this._onAddList}
              onChange={this._onFormChange}
            />
          <PayrollTableForm
              title="Potongan Intern Sekolah"
              listField={FORMAL_POTONGAN_INTERN_SEKOLAH}
              error={potongan_itern_sekolahErrors}
              list={potongan_itern_sekolah}
              fieldName="potongan_itern_sekolah"
              onAddList={this._onAddList}
              onChange={this._onFormChange}
            />
          <div className="manage-payroll">
            <Button
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
