import axios from 'axios';
import { transform } from 'lodash';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { errorAlert, successAlert } from '../../utils/alert.util';
import HTTPError from '../../utils/error.util';

import {
  addBudgetDetailFormat, saveBudgetTransferFormat, editBudgetDetailFormat,
  saveManageCashJournalFormat, saveManageBankJournalFormat, managePaymentJournalFormat,
  manageAdjustmentJournalFormat, manageClosingJournalFormat, manageNonKasJournalFormat,
  manageGeneralFormat,
} from '../../utils/middleware.util';
import { commaFormatted, normalizeAmount } from '../../utils/transformer.util';
import language from '../../languages';

const { CancelToken } = axios;
let source;
/*
BUDGET START HERE
*/
export function getBudget(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      const { period = 2020, page = 1 } = payload;
      const requestBody = new FormData();
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      requestBody.append('periode', period);
      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);

      const res = await api.BUDGET(requestBody, '/head/list', { additional: ['lang'] });
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function addBudget(payload = {}, goBack, unit_id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { periode, desc } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', periode);
      requestBody.append('desc', desc);
      requestBody.append('unit_id', unit_id);
      await api.BUDGET(requestBody, '/head/add', { additional: ['lang', 'create_by'] });
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Pendapatan Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteBudget(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET(payload, '/head/delete');
      return res;
    } catch (err) {
      errorAlert({
        title: 'Hapus budget gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBudgetDetail(payload) {
  return async (dispatch) => {
    try {
      let path = '/detail/list';
      let requestBody = {};
      const { account_unique_id, head_unique_id, type = '' } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      if (type === 'RAPBU') {
        requestBody = {
          filters: {
            head: head_unique_id,
          },
        };
        path = '/detail/rapbu';
      } else if (type === 'OUTCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'OUTCOME',
          },
        };
      } else if (type === 'INCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'INCOME',
          },
        };
      } else {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
          },
        };
      }
      const res = await api.BUDGET(requestBody, path);
      if (type === 'RAPBU') {
        return res.data;
      }
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addBudgetDetail(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { head_unique_id, account_unique_id, code } = payload;
      const data = addBudgetDetailFormat(payload);
      const requestBody = {
        head: head_unique_id,
        account: account_unique_id,
        account_type: code,
        data,
      };
      const res = await api.BUDGET(requestBody, '/detail/add');
      successAlert({ title: 'Menambah Anggaran Berhasil' });
      return res.data.data;
    } catch (err) {
      errorAlert({
        title: 'Menambah Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editBudgetDetail(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { head, account, code } = payload;
      const data = editBudgetDetailFormat(payload);
      const requestBody = {
        head,
        account,
        account_type: code,
        data,
      };
      const res = await api.BUDGET(requestBody, '/detail/edit');
      successAlert({ title: 'Update Anggaran Berhasil' });
      return res;
    } catch (err) {
      errorAlert({
        title: 'Update Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteBudgetDetail(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('detail_unique_id', id);
      await api.BUDGET(requestBody, '/detail/delete');
      successAlert({ title: 'Hapus Detail Anggaran Berhasil' });
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Detail Anggaran Gagal wa',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

/*
BUDGET END HERE
*/

/*
NON BUDGET START HERE
*/
export function getNonBudget(payload = {}, unit_id) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);
      const res = await api.NON_BUDGET_POST(requestBody, '/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function addNonBudget(payload, goBack, unit_id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('file_number', payload.file_number);
      requestBody.append('date', payload.date);
      requestBody.append('code_of_account', payload.code_of_account.code);
      requestBody.append('activity', payload.activity);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      requestBody.append('unit_id', unit_id);
      await api.NON_BUDGET_POST(requestBody, '/add');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Non Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editNonBudget(payload, goBack, unit_id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', payload.id);
      requestBody.append('file_number', payload.file_number);
      requestBody.append('date', payload.date);
      requestBody.append('code_of_account', payload.code_of_account.code);
      requestBody.append('activity', payload.activity);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      requestBody.append('unit_id', unit_id);
      await api.NON_BUDGET_POST(requestBody, '/edit');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Edit Non Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteNonBudget(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', id);
      await api.NON_BUDGET_POST(requestBody, '/delete');
    } catch (err) {
      errorAlert({
        title: 'Hapus Non Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
NON BUDGET END HERE
*/

/*
BUDGET TRANSFER START HERE
*/
export function getBudgetTransfer(payload = {}, unit_id) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);
      const res = await api.BUDGET(requestBody, '/relocation/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getBudgetTransferById(id) {
  return async () => {
    try {
      const res = await api.BUDGET({ id }, '/relocation/get');
      return res.data;
    } catch (err) {
      throw err;
    }
  };
}

export function getBudgetCheckList(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const {
        code_of_account, type, head, periode,
      } = payload;
      const res = await api.BUDGET({
        filters: {
          code_of_account,
          type,
          head,
          periode,
        },
      }, '/detail/list');
      return [...res.ganjil, ...res.genap];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveBudgetTransfer(payload, goBack, unit_id) {
  return async (dispatch) => {
    try {
      const formattedPayload = saveBudgetTransferFormat(payload);
      formattedPayload.unit_id = unit_id;

      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET(formattedPayload, '/relocation/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Simpan Transfer Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteBudgetTransfer(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', id);
      await api.BUDGET(requestBody, '/relocation/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Transfer Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
BUDGET TRANSFER END HERE
*/


/*
FUND REQUEST START HERE
*/
export function getFundRequest(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));

      const { page = 1 } = payload;
      const requestBody = new FormData();
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);
      const res = await api.BUDGET_REQUEST(requestBody, '/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getFundRequestAvailableCoa(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST(payload, '/code-of-account')
        .then(result => result)
        .catch();

      dispatch(commonActions.getOptionsSucceeded({
        entity: 'coa',
        data: res.data,
      }));
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getFundRequestById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.BUDGET_REQUEST({ id }, '/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getDetailFund(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { code_of_account, type } = payload;
      const res = await api.BUDGET({
        filters: {
          code_of_account,
          type,
        },
      }, '/detail/list');
      return [...res.data.ganjil, ...res.data.genap];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAvailableBudgetDetails(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST(payload, '/budget-details');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addFundRequest(payload, goBack = null) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formattedPayload = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      formattedPayload.amount = normalizeAmount(payload.amount);
      formattedPayload.unit_id = unit_id;

      const res = await api.BUDGET_REQUEST(formattedPayload, '/add');
      if (goBack === null) {
        return res.data;
      }
      goBack();
    } catch (err) {
      errorAlert({
        title: 'alerts.form.save_failed.title',
        errorMessage: err.message,
        form: 'form.fund_request',
        value: commaFormatted(err.params),
      });
      return err.errors;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function rejectFundRequest(payload, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET_REQUEST(payload, '/reject');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tolak Permintaan Dana Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function submitFundRequest(payload, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET_REQUEST({ id: payload.id }, '/submit');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Pengajuan Permintaan Dana Gagal',
        errorMessage: language.translate[err.message],
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteFundRequest(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST({ id: payload.id }, '/delete');
      return res;
    } catch (err) {
      errorAlert({
        title: 'Hapus Permintaan Dana Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteFundRequestDetail(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST(payload, '/detail/delete');
      return res;
    } catch (err) {
      errorAlert({
        title: 'Hapus Permintaan Dana Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
FUND REQUEST END HERE
*/


/*
REALIZATION START HERE
*/
export function getRealization(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const res = await api.BUDGET({ unit_id }, '/realization');
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getDetailRealization(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { code_of_account, type } = payload;
      const res = await api.BUDGET({
        filters: {
          code_of_account,
          type,
        },
      }, '/detail/list/realization');
      return [...res.data.ganjil, ...res.data.genap];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addRealization(payload, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('file', payload.file[0]);
      requestBody.append('budget_detail_unique_id', payload.selectedBudget.unique_id);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      await api.REALIZATION_POST(requestBody, '/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Realisasi Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editRealization(payload, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', payload.id);
      requestBody.append('file', payload.file[0]);
      requestBody.append('budget_detail_unique_id', payload.selectedBudget.unique_id);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      await api.REALIZATION_POST(requestBody, '/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Edit Realisasi Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteRealization(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', id);
      await api.REALIZATION_POST(requestBody, '/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Realisasi Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
REALIZATION END HERE
*/


/*
UPLOAD RAPBU START HERE
*/
export function uploadRapbuFile(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file, periode, head } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', periode);
      requestBody.append('head', head);
      requestBody.append('file', file);
      await api.BUDGET(requestBody, '/detail/upload');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteRapbuFile(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('unique_id', id);
      await api.BUDGET(requestBody, '/head/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Pendapatan Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
UPLOAD RAPBU END HERE
*/

/*
PAYMENT JOURNAL START HERE
*/
export function getPaymentJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/pembayaran/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getPaymentJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/pembayaran/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function managePaymentJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(managePaymentJournalFormat(payload), '/pembayaran/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Pembayaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
PAYMENT JOURNAL END HERE
*/

/*
ADJUSTMENT JOURNAL START HERE
*/
export function getAdjustmentJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, '/adjustment/list');
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getAdjustmentJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/adjustment/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageAdjustmentJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.FINANCE_JOURNAL_POST(manageAdjustmentJournalFormat({ submitted_by: user.name, unit_id, ...payload }), '/adjustment/save');
      return res.data;
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Penyesuaian Gagal',
        errorMessage: language.translate[err.message] || err.message,
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
    return false;
  };
}
/*
ADJUSTMENT JOURNAL END HERE
*/

/*
CLOSING JOURNAL START HERE
*/
export function getClosingJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/penutup/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getClosingJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/penutup/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageClosingJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(manageClosingJournalFormat(payload), '/penutup/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Penutup Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
CLOSING JOURNAL END HERE
*/

/*
NON KAS JOURNAL START HERE
*/
export function getNonKasJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/non-kas/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getNonKasJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/non-kas/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageNonKasJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(manageNonKasJournalFormat(payload), '/non-kas/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Non Kas Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
NON KAS JOURNAL END HERE
*/

/*
GENERAL JOURNAL START HERE
*/
export function getGeneralJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/umum/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getGeneralJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/umum/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageGeneralJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(manageGeneralFormat(payload), '/umum/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Umum Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
GENERAL JOURNAL END HERE
*/

export function getJournalList(payload = {}, journal_type = '') {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      const { page = 1 } = payload;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, `/${journal_type}/list`);
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw (err);
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getJournalById(payload = {}, journal_type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, `/${journal_type}/get`);
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveJournal(payload = {}, journal_type = '', goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(payload, `/${journal_type}/save`);
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Gagal',
        errorMessage: language.translate[err.message],
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveAdjustmentJournalCorrection(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.FINANCE_JOURNAL_POST({ ...payload, unit_id }, '/adjustment/save-correction');
      return res;
    } catch (err) {
      errorAlert({
        title: 'Gagal Mengoreksi Jurnal',
        errorMessage: language.translate[err.message],
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteJournal(payload = {}, journal_type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(payload, `/${journal_type}/delete`);
    } catch (err) {
      errorAlert({
        title: 'Hapus Jurnal Gagal',
        errorMessage: language.translate[err.message],
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteJournalItem(payload = {}, journal_type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(payload, `/${journal_type}/detail/delete`);
    } catch (err) {
      errorAlert({
        title: 'Hapus Detail Jurnal Gagal',
        errorMessage: language.translate[err.message],
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCashJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, '/kas/list');
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function postJournalPosting(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/post');
      dispatch(commonActions.postJournalPosting({
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      return err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function postJournalUnposting(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/unpost');
      dispatch(commonActions.postJournalUnposting({
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function postAdjustmentJournal(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/adjustment/post');
      dispatch(commonActions.postJournalPosting({
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      return err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCashJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/kas/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCashJournalPreview(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/kas/preview');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageCashJournal(payload = {}, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      await api.FINANCE_JOURNAL_POST(saveManageCashJournalFormat({ submitted_by: user.name, ...payload }), '/kas/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Kas Gagal',
        errorMessage: language.translate[err.message] || err.message,
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAvailableFundRequests(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_JOURNAL_POST(payload, '/fund-requests');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBankJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, '/bank/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getBankJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/bank/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBankJournalPreview(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/bank/preview');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageBankJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(saveManageBankJournalFormat(payload), '/bank/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Kas Gagal',
        errorMessage: language.translate[err.message] || err.message,
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function journalAcceptReconciliation(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_JOURNAL_POST(payload, '/reconciliation');
      return res.data;
    } catch (err) {
      errorAlert({
        title: 'Rekonsiliasi gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
    return false;
  };
}

export function getOutcome(payload = {}) {
  return async () => {
    try {
      const { limit = 10, offset = 0 } = payload;
      const res = await api.FINANCE_OUTCOME('', {
        params: {
          limit,
          offset,
          sortBy: 'updatedAt',
          orderBy: 'desc',
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getRapbu(payload = {}) {
  return async () => {
    try {
      const { limit = 10, offset = 0 } = payload;
      const res = await api.FINANCE_RAPBU('', {
        params: {
          limit,
          offset,
          sortBy: 'updatedAt',
          orderBy: 'desc',
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function calculateTax(payload = {}) {
  return async () => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    const res = api.TAX_POST(payload, '/calculate', options)
      .then(result => result.result)
      .catch();
    return res;
  };
}

export function getTaxRecipientParam(payload = {}) {
  return async () => {
    try {
      if (source) {
        source();
      }
      const options = {
        cancelToken: new CancelToken(((c) => {
          source = c;
        })),
      };
      const res = api.TAX_POST(payload, '/param/recipient', options)
        .then(result => result.result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getTaxPtkpParam(payload = {}) {
  return async () => {
    try {
      const res = await api.TAX_POST(payload, '/param/ptkp');
      return res.result;
    } catch (err) {
      throw err;
    }
  };
}

export function submitApproval(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET(payload, '/detail/rapbu/submit');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveRecommendations(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET(payload, '/detail/rapbu/save');
      goBack();
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function rejectRapbu(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET(payload, '/detail/rapbu/reject');
      goBack();
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBudgetRecaps(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET(payload, '/recaps');
      return res.result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBeginningBalance(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      payload.unit_id = unit_id;
      const res = await api.FINANCE_STARTING_BALANCE(payload, '/get');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveBeginningBalance(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_STARTING_BALANCE({ data: payload }, '/save');
      successAlert({ title: 'Berhasil menyimpan saldo awal.' });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function postBeginningBalance(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_STARTING_BALANCE({ data: payload }, '/post');
      successAlert({ title: 'Saldo awal berhasil di posting.' });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSourceJournal(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_JOURNAL({ ...payload }, '/source-journal');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
