import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { FileInput, Input, Button, Select } from '../../../../../components/base/index';
import { commaFormatted, normalizeAmount } from '../../../../../utils/transformer.util';



const padStr = (num) => {
  // if (num) 
  // num.length > 0
  // num !==
  if (num !== "" && num !== null && typeof num !== 'undefined') {
    return num.toString();
    // if (num < 10) {
    //   return "0" + num;
    // }
    // return num;
  }
  
  return null;
}

export default class TableTambahStatus extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name,onFormChange
    } = this.props;
    console.log(`render props : ` + JSON.stringify(list))
    // const is_discount = [
    //   { label: 'Y', value: '1'},
    //   { label: 'N', value: '0'},
    // ]

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                <Input
                  type='text'
                  data-input-array
                  data-array-position={idx}
                  arrayPosition={idx}
                  data-field-name='tabelstatus'
                  name="name"
                  onChange={onFormChange}
                  value={data.name}
                // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                {/* <td>
                <Select
                    value={data.is_discount.toString()}
                    placeholder="Pilihan"
                    data={is_discount}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='tabelstatus'
                    name='is_discount'
                    onChange={onFormChange}
                  />
                </td> */}
               
                <td>
                    <div className="table__actions">
                        <Button
                            type="button"
                            title="Hapus"
                            onClick={this._onDeleteList}
                        />
                    </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}

TableTambahStatus.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,

};
TableTambahStatus.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
  plafond_name: [],
};
