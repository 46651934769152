import { connect } from 'react-redux';
import DetailCashJournal from '../../../../views/Finance/Bookkeeping/CashJournal/DetailCashJournal.finance.component';
import { getCashJournalPreview } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCashJournal: payload => dispatch(getCashJournalPreview(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCashJournal);
