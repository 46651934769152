import fixturesConfig from './fixtures.config';
// kalau api dijalan dengan php -S localhost:8080
// maka url menjadi: http://localhost:8080 
// tanpa ada nama api

// kalau api dijialankan dengan xampp (folder htdocs)
// maka url menjadi: http:/localhost/<nama api>
const defaults = {
  ENV: 'prod',
  MOCKAPI: false,
  URL: 'https://dev.api.olifant.kliksekolah.com/api',
  // URL: 'http://localhost/api.olifant/api',
  URL_2: 'https://staff.kliksekolah.com/api',
  URL_3: 'https://tax.kliksekolah.com/api',
  // URL_4: 'https://api.param.kliksekolah.com/',
  URL_4: 'http://localhost:8005',
  //API_BRIDGE: 'http://localhost:8080/api_bridge',
  // API_AKADEMIS: 'https://academics.api.kliksekolah.com',
  // API_AKADEMIS: 'http://localhost:8081/',
  API_AKADEMIS: 'https://academics.api.kliksekolah.com',
  API_LOCAL: 'https://dev.api.academics.kliksekolah.com',
  API_BRIDGE: 'https://api.bridge.kliksekolah.com',
//   API_LOCAL: 'https://academics.api.kliksekolah.com',
  fixtures: fixturesConfig,
};


const setEnv = (envKey, value) => {
  defaults[envKey] = value;
  return defaults;
};

export default {
  setEnv,
  ENV: defaults.ENV,
  MOCKAPI: defaults.MOCKAPI,
  URL: defaults.URL,
  URL_2: defaults.URL_2,
  URL_3: defaults.URL_3,
  URL_4: defaults.URL_4,
  API_BRIDGE: defaults.API_BRIDGE,
  API_AKADEMIS: defaults.API_AKADEMIS,
  API_LOCAL: defaults.API_LOCAL,
  fixtures: defaults.fixtures,
  PASSWORD: defaults.password,
};
