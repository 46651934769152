import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual} from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination, Input, Select } from '../../../components/base/index';
import { PENGISIAN_SIKAP_LIST_TABLE_FIELDS} from '../../../constants/Student/student.constant';
import language from '../../../languages';
import PengisianSikapForm from './components/PengisianSikapForm.component';
import { TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS} from '../../../constants/kurikulum/ulangan.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianPerkembanganAspek extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this._onClickAddKD = this._onClickAddKD.bind(this);

          this.onSubmit = this.onSubmit.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._setForm = this._setForm.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._getTeacherSubjectsOptions= this._getTeacherSubjectsOptions.bind(this);
          this._getTypeExam= this._getTypeExam.bind(this);
          this._onDeleteList = this._onDeleteList.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          this.onChangePage = this.onChangePage.bind(this);

          const { user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id} = school_unit
          this.state = {
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                organizations_id: 12,
                name:'',
              },
              error: {
      
              },
            },
            param:{},
          };
        }
      
        componentDidMount() {
          const { location, getLevels, getClasses, user } = this.props;
          const { school_unit } = user;
          const { levels_id } = school_unit;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
            const { content } = this.state;
            const paramTypes = ['classes','levels', 'classrooms'];
            if (isEdit && id) {
              this.setState({id:id})
              this._setForm(data.id);
            } 
            paramTypes.forEach((type) => {
              // this._getParamOptions(type);
              this._getParamOptionsAcademics(type);
            });
            
            this.handleGetData();
            this._getTypeExam();       
            this._getTeacherSubjectsOptions();
            this._getPeriodsOptions();       

          }


          async onClickDelete(id) {
            const { handledeleteDevAspect } = this.props;
            const res = await handledeleteDevAspect(id);
            if(res){
              this.handleGetData();
            }
          }

          onClickEdit(val) {
            const { history } = this.props;
            history.push('/dashboard/kurikulum/ulangan/pengisian-aspek-perkembangan', { data: val, isEdit: true });
            history.go(0)
          }

       

        async _getPeriodsOptions() {
          const { getPeriodsOptions } = this.props;
          const res = await getPeriodsOptions();
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }

        async _getTypeExam() {
          const { getTypeExam } = this.props;
          const res = await getTypeExam();
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              type_exam: res,
            },
          }));
        }

        async _getTeacherSubjectsOptions(filters={}) {
          const { getTeacherSubjectsOptions } = this.props;
          const res = await getTeacherSubjectsOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              teacher_subjects: res,
            },
          }));
        }


        onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        _onClickAdd() {
            const { history } = this.props;
            history.push('/dashboard/kurikulum/ulangan/pengisian-aspek-perkembangan', { isEdit: true });
          }
    
        _onClickAddKD() {
            const { history } = this.props;
            history.push('/dashboard/ulangan/pengisian-kompetensi-dasar')
        }


       

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics , user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id} = school_unit
          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = 12;
            // filters.levels_id = levels_id;

          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _getParamOptions(type, filters = {}) {
          const { getParamOptions } = this.props;
          if (type === 'classrooms' || type === 'classrooms') {
            filters.organizations_id = 12;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptions(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _setForm(id) {
          const { handleGetDevAspect } = this.props;
          try {
            const payload = await handleGetDevAspect({ id });
            this.setState({
              form: {
                value: {
                  id: payload.id,
                  name: payload.name,
                  
                },
                error: {
                  // details: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }
      
       
        _onFormChange(event) {
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          });
        }



        async onSubmit() {
          const { history, handleSaveDevAspect } = this.props;
          const { form } = this.state;
          const { value } = form;        
          let canSaveA = false;
          const dataForm = form.value;
            await handleSaveDevAspect(value);
            history.push('/dashboard/kurikulum/ulangan/pengisian-aspek-perkembangan');
            history.go(0)
        }


        async onEdit() {
          const { history, handleEditDevAspect } = this.props;
          const { form } = this.state;
          const { value } = form;
          let canSaveA = false;
          let canSaveB = false;
          const dataTabel = value.pelajaran;
          const dataForm = form.value;
          let a;
            await handleEditDevAspect(value);
            history.push('/dashboard/kurikulum/ulangan/pengisian-aspek-perkembangan');
            history.go(0)
            // }
         
        }

        _renderButtons(data) {
          const { user } = this.props;
          const button = {};
          
          return (
            <td>
              <div className="table__actions">
               
              <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                />
                <Button
                  onClick={() => this.onClickDelete(data)}
                  title='Delete'
                />
              </div>
            </td>
          );
        }

        _onDeleteList(idx) {
          this.setState((prevState) => {
            const { indikator_sikap } = prevState.form.value;
            const list = prevState.form.value.indikator_sikap;
            list.splice(idx, 1);
            return {
              ...prevState,
              indikator_sikap: list
            };
          });
        }

        async handleGetData(payload = {}) {
          const { handleListDevAspect, user } = this.props;
          const { units_id, workingUnit, id, user_group, profile } = user;
          let unit_id = units_id;
      
          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }
          console.log(res)
          // if(user_group.id === 2){
            const res = await handleListDevAspect({...payload, organizations_id: 12});
      
            this.setState({
              list: res,
            });
            console.log(this.state.list)
          }

      
        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
            if (name === 'levels_id') {
              this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id: 12 }} );
            } this._onSearchContent()});
        
        }
      
      
        render() {
            const {form, list = {}} = this.state;
            const { handleGetKelas, handleDeleteKelas,coa } = this.props;
            return (
                <div className="add-budget">
            
                <form onSubmit={this.onSubmit}>
                  <h1>Buat Aspek Perkembangan</h1>
                  <hr></hr>
                  {/* <ListForm
                  form={form}
                  formFields={GROUP_PELANGGARAN_FORM_FIELDS}
                  onFormChange={this._onFormChange}
                  /> */}
                    <Input
                    label = "Nama Aspek"
                    name="name"
                    value={form.value.name}
                    error = {form.error.name||''}
                    onChange={this._onFormChange}
                  />
                   <div className="student-list__button">
                   {this.state.id?
                    <Button
                      type="button"
                      title="Update"
                      onClick={this.onEdit}
                    />:
                    <Button
                      type="button"
                      title="Simpan"
                      onClick={this.onSubmit}
                    />}
                  </div>
                 
        
                {/* <div className="budget__content">
                  <div className="budget__search">
                  <ListForm
                  coa={coa}
                  form={form}
                  formFields={SEARCH_FIELDS}
                  onFormChange={this._onFormChange}
                  />
        
                  </div>
                  <ContentTable
                    onSearchContent={handleGetKelas}
                    tableFields={TABLE_PELANGGARAN_TABLE_FIELDS}
                    onClickEdit={this._onClickEdit}
                    onClickDelete={handleDeleteKelas}
                  />
                </div> */}
              </form>
              <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    {
                        map(TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS.label, (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                       ))
                      }
                  </tr>
                </thead>
                <tbody>
                  {
                      map(list.result, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {
                          map(TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS.value, (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                              );
                            }
                            return (
                              <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                {formatData(data, field)}
                              </td>
                            );
                          })
                        }
                          {this._renderButtons(data)}
                        </tr>
                      ))
                    }
                </tbody>
              </table>
            </div>
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
              </p>
              <Pagination
                totalPage={list.totalPage}
                currentPage={list.currentPage}
                onClick={this.onChangePage}
              />
            </div>
              </div>
            );
          }
}
PengisianPerkembanganAspek.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PengisianPerkembanganAspek.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
};
