import { connect } from 'react-redux';
import KonselingSiswa from '../../views/Student/KonselingSiswa.student.view';
import { getStudent } from '../../states/thunks/student.thunk';
import { getClasses, getParamOptionsAcademics, getSubjectOptions } from '../../states/thunks/options.thunk';
import { deleteCounselingRecord,  listCounselingRecord } from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListCounselingRecord: (payload, goback) => dispatch(listCounselingRecord(payload, goback)),
    handleDeleteCounselingRecord: (payload, goback) => dispatch(deleteCounselingRecord(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KonselingSiswa);
