import { connect } from 'react-redux';
import Balance from '../../../views/Finance/Report/Balance.report.view';
import { getBalanceReport } from '../../../states/thunks/report.thunk';

function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBalanceReport: payload => dispatch(getBalanceReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
