import { connect } from 'react-redux';
import Prestasi from '../../../../views/Student/displiner/cetak/Prestasi.student.view';
import { getStudent } from '../../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(Prestasi);
