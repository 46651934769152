import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../../components/base';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { FINANCE_BUDGET_TRANSFER_TABLE_FIELDS } from '../../../../constants/finance/budgeting/budgetTransfer.constant';

export default class BudgetTransfer extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-pengalihan-anggaran', { isEdit: false });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-pengalihan-anggaran', { data: { id: val.id }, isEdit: true });
  }

  render() {
    const { handleGetBudgetTransfer, handleDeleteBudgetTransfer, user } = this.props;
    return (
      <div className="budget-transfer">
        <Button
          title="Tambah Pengalihan Anggaran"
          onClick={this.onClickAdd}
        />
        <div className="budget-transfer__content">
          <ContentTable
            onSearchContent={handleGetBudgetTransfer}
            tableFields={FINANCE_BUDGET_TRANSFER_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickDelete={handleDeleteBudgetTransfer}
            user={user}
          />
        </div>
      </div>
    );
  }
}
BudgetTransfer.propTypes = {
  handleGetBudgetTransfer: PropTypes.func,
  handleDeleteBudgetTransfer: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
BudgetTransfer.defaultProps = {
  handleGetBudgetTransfer: noop,
  handleDeleteBudgetTransfer: noop,
  user: null,
};
