import { connect } from 'react-redux';
import KelasKhusus from '../../../views/Akademi/DataKelas/KelasKhusus.view';
import { addBudget } from '../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KelasKhusus);