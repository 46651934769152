import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import {
  isEmpty, dropRight,
  map, noop, find, findIndex, cloneDeep, includes,
} from 'lodash';
import {
  commaFormatted, normalizeAmount,
  dateFormat,
} from '../../../../utils/transformer.util';
import {
  CashJournalForm,
  BankJournalForm,
} from './components';

const MySwal = withReactContent(Swal);

export default class Journals extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { journal_type } = location.state;

    this.journalType = journal_type;

    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getData = this._getData.bind(this);
    this._renderForm = this._renderForm.bind(this);
    this._initializeState = this._initializeState.bind(this);

    this.state = {
      counter: 0,
      form: {
        value: {},
        error: {},
      },
      availableFundRequests: {},
      selectedFundRequests: {},
      showTaxForm: false,
      taxType: null,
      summary: {},
    };
  }

  componentDidMount() {
    this._getData();
  }

  async _getData() {
    const { handleGetJournal, location } = this.props;
    const { isEdit = false, data, journal_type = '' } = location.state;
    const id = (data) ? data.id : null;

    if (isEdit) {
      const res = await handleGetJournal({ id }, journal_type);
      this._setForm(res);
    }
  }

  _setForm(res) {
    this.setState({
      ...res,
    });
  }

  _initializeState(name, value) {
    this.setState(prevState => ({
      ...prevState,
      counter: 0,
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
          reconciliation: [],
          standard: [],
        },
        error: {},
      },
      showTaxForm: false,
      taxType: null,
    }));
  }

  _onFormChange(event) {
    const {
      name, value, dataset, checked, type,
    } = event.target;
    const {
      inputType = 'text',
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;

    if (name === 'tipe' || name === 'type' || name === 'pos') {
      this._initializeState(name, value);
    }

    this.setState((prevState) => {
      const { form } = prevState;
      const taxConfig = {
        showTaxForm: false,
        taxType: null,
      };
      const taxableCoa = [
        '515', '516', '517',
      ];
      const newForm = cloneDeep(form);
      const { value } = newForm;
      let coa = null;
      let item = {};
      let itemList = [];

      let formattedValue = (inputType === 'number') ? normalizeAmount(value) : value;
      if (type === 'checkbox') {
        formattedValue = checked;
      }

      if (inputArray) {
        itemList = value[fieldName];
        item = itemList[arrayPosition];
        item[name] = formattedValue;

        coa = item.code_of_account;

        if (includes(taxableCoa, coa.substr(0, 3))) {
          if (name === 'code_of_account' && !item.tax) {
            item = {
              ...item,
              tax: {
                ...item.tax,
                fields: {},
              },
            };
          }
          if (name === 'nominal') {
            item.tax.fields.gross_income_per_month = formattedValue;
          }
        } else {
          item = {
            ...item,
            tax: null,
          };
        }
      }

      return {
        ...prevState,
        form: {
          value: {
            ...form.value,
            ...(
              inputArray ? { [fieldName]: itemList }
                : { [name]: formattedValue }),
          },
          error: {},
        },
        selectedCoa: (name === 'code_of_account') ? formattedValue.code : prevState.selectedCoa,
        ...taxConfig,
      };
    }, () => {
      if (name === 'nominal') {
        this._calculateTax(arrayPosition);
        this._calculateSummary();
      }
      if (name === 'code_of_account') {
        this._onSelectCoa(value);
      }
    });
  }

  _getForm() {
    const { location } = this.props;
    const { journal_type } = location.state;

    switch (journal_type) {
      case 'KAS': {

      }
      case 'BANK': {

      }
    }
  }

  _renderForm() {

  }

  render() {
    return (
      <div className="manage-journal">
        <form onSubmit={this._onSubmit}>
          {this._renderForm()}
        </form>
      </div>
    );
  }
}

Journals.propTypes = {
  handleGetJournal: PropTypes.func.isRequired,
  handleSaveJournal: PropTypes.func.isRequired,
  handleDeleteJournalItem: PropTypes.func.isRequired,

  handleGetJournalCoaOptions: PropTypes.func,
  handleGetAvailableFundRequests: PropTypes.func,
  handleGetBankAccountOptions: PropTypes.func,

  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  handleCalculateTax: PropTypes.func,
  handleGetTaxPtkpParam: PropTypes.func,
  handleGetTaxRecipientParam: PropTypes.func,
  handleGetUnitOptions: PropTypes.func,
};

Journals.defaultProps = {
  handleGetTaxPtkpParam: noop,
  handleGetTaxRecipientParam: noop,
  handleCalculateTax: noop,
  handleGetUnitOptions: noop,
  handleGetCoaOptions: noop,
  handleGetJournalCoaOptions: noop,
  handleGetBankAccountOptions: noop,
  handleGetAvailableFundRequests: noop,
  handleGetJournalCoaOptions: noop,
};
