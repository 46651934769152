import { GET_COA_REQUESTED, GET_COA_SUCCEEDED, GET_COA_FAILED } from '../actions/common.action';

const coaState = {
  loading: true,
  list: [],
  error: '',
};

export default function coa(state = coaState, action) {
  switch (action.type) {
    case GET_COA_REQUESTED: {
      return coaState;
    }
    case GET_COA_SUCCEEDED: {
      return {
        loading: false,
        list: action.payload,
        error: '',
      };
    }
    case GET_COA_FAILED: {
      return {
        loading: false,
        list: [],
        error: '',
      };
    }
    default: {
      return state;
    }
  }
}
