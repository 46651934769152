import React, { PureComponent } from 'react';
import BookContent from '../../../components/BookContent/BookContent.component';

export default class StudentBook extends PureComponent {
  componentDidMount() {

  }

  render() {
    return (
      <div className="student-book">
        <div>
          <div className="student-book__header">
            <h1>LEMBAR BUKU INDUK SISWA SMK</h1>
            <h3>NOMOR INDUK SISWA / NISN: 123123</h3>
          </div>
          <div className="student-book__content">
            <BookContent />
          </div>
        </div>
      </div>
    );
  }
}
StudentBook.propTypes = {};
StudentBook.defaultProps = {};
