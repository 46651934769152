import { connect } from 'react-redux';
import ManageNonKasJournal from '../../../../views/Finance/Bookkeeping/NonKasJournal/ManageNonKasJournal.bookkeeping.view';
import { getNonKasJournalById, manageNonKasJournal } from '../../../../states/thunks/finance.thunk';
import { getCoaOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetNonKasJournal: payload => dispatch(getNonKasJournalById(payload)),
    handleManageNonKasJournal: (payload, goBack) => dispatch(manageNonKasJournal(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageNonKasJournal);
