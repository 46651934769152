import UploadRPP from '../../../views/Kurikulum/RencanaNilai/UploadRPP.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';
function mapStateToProps(state){
    return{
        classes: state.options.classes,
    }
}
function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (UploadRPP);