import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../components/base';

export default class DataRiwayatPekerjaan extends Component {
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteListRiwayatPekerjaan = this._onDeleteListRiwayatPekerjaan.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }
    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteListRiwayatPekerjaan(idx) {
        const { onDeleteListRiwayatPekerjaan, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteListRiwayatPekerjaan(idx, fieldName);
      }  

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }
     
    render() {
        const {
          list, error, onChange,
          fieldName, pekerjaan_history_name, pekerjaan_history,
        } = this.props;

        return (
            <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Perusahaan</th>
                  <th>Kota</th>
                  <th>Jabatan</th>
                  <th>Tahun Masuk</th>
                  <th>Tahun Keluar</th>
                  <th>Alasan Keluar</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_pekerjaan_history_form__${idx}`}>
                <td>{idx + 1}</td>
                <td className="perusahaan">
                <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pekerjaan_history'
                    data-input-type="text"
                    name="perusahaan"
                    onChange={onChange}
                    value={data.perusahaan}
                  />
                </td>
                <td className="kota">
                <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pekerjaan_history'
                    data-input-type="text"
                    name="kota"
                    onChange={onChange}
                    value={data.kota}
                  />
                </td>
                <td className="jabatan">
                <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pekerjaan_history'
                    data-input-type="text"
                    name="jabatan"
                    onChange={onChange}
                    value={data.jabatan}
                  />
                </td>
                <td className="tahun">
                <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pekerjaan_history'
                    data-input-type="date"
                    name="tahun"
                    onChange={onChange}
                    value={data.tahun}
                  />
                </td>
                <td className="sampai_tahun">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pekerjaan_history'
                    data-input-type="date"
                    name="sampai_tahun"
                    onChange={onChange}
                    value={data.sampai_tahun}
                  />
                </td>
                <td className="keterangan">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pekerjaan_history'
                    data-input-type="text"
                    name="keterangan"
                    onChange={onChange}
                    value={data.keterangan}
                  />
                </td>
                <td>
                  <Button onClick={() => this._onDeleteListRiwayatPekerjaan(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
        }
        <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
        </table>
        );
    }
}

DataRiwayatPekerjaan.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteListRiwayatPekerjaan: PropTypes.func,
    onChange: PropTypes.func,
    pekerjaan_history_name: PropTypes.array,
};

DataRiwayatPekerjaan.defaultProps = {
    onAddList: noop,
    onDeleteListRiwayatPekerjaan: noop,
    onChange: noop,
    pekerjaan_history_name:[],
};