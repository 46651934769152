import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { PERSONAL_PERMANENT_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class Permanent extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/surat/tambah-pengangkatan');
  }

  render() {
    const { handleGetPermanent } = this.props;
    return (
      <div className="permanent">
        <Button
          title="Tambah Surat Pengangkatan"
          onClick={this.onClickAdd}
        />
        <div className="permanent__content">
          <ContentTable
            onSearchContent={handleGetPermanent}
            tableFields={PERSONAL_PERMANENT_TABLE_FIELDS}
          />
        </div>
      </div>
    );
  }
}
Permanent.propTypes = {
  handleGetPermanent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Permanent.defaultProps = {
  handleGetPermanent: noop,
};
