import React, { useContext } from 'react';

export const AuthContext = React.createContext({});
export const CanContext = React.createContext({});

function validAssignment(userGroup, workflow, action) {
  const { name } = userGroup;
  const len = workflow.length;
  const lastWorkflow = workflow[len - 1] || {};
  const firstWorkflow = workflow[0] || {};
  const isMe = lastWorkflow.next_role === name;
  const isOwner = firstWorkflow.prev_role === name;
  const containsMe = workflow.filter(o => o.prev_role === name).length > 0;
  const isDone = lastWorkflow.is_done || false;
  switch (action) {
    case 'edit':
      return ((!isDone && isMe) || workflow === null || len === 0);
    case 'create':
      return (workflow === null || len === 0 || isMe);
    case 'view':
      return true;
    case 'delete':
      return isOwner;
    default:
      return (workflow === null || len === 0 || isMe || !isDone);
  }
}

export function validRule(state, subject, action) {
  const { user } = state;
  const { rules = {} } = user;
  if (rules) {
    if (subject in rules) {
      if (rules[subject].includes(action)) {
        return true;
      }
      return false;
    }
    return false;
  }

  return false;
}

export function CanContextProvider({
  action, subject, children,
}) {
  const { getState } = useContext(AuthContext);
  const state = getState();
  return (
    <CanContext.Provider
      value={{
        subject,
        action,
        children,
      }}
    >
      { validRule(state, subject, action) && children }
    </CanContext.Provider>
  );
}

export default function AuthContextProvider({ children }) {
  return (
    <AuthContext.Provider>
      {children}
    </AuthContext.Provider>
  );
}
