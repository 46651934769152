import React, { PureComponent } from 'react';
import ListForm from '../../../components/ListForm/ListForm.component';
import RequestTableForm from '../components/RequestTableForm.component';
import { commaFormatted } from '../../../utils/transformer.util';
import { PERSONNEL_REQUEST_FORM_FIELDS, PERSONNEL_ITEM_FORM } from '../../../constants/personnel.constant';
import Button from '../../../components/base/Button/Button.component';

export default class ManageRequest extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this.state = {
      form: {
        value: {
          items: [],
        },
        error: {
          items: [],
        },
      },
    };
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputArray) {
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        newListError[arrayPosition][name] = '';
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  render() {
    const { form } = this.state;
    const { items } = form.value;
    const { items: itemErrors } = form.error;
    return (
      <div className="manage-request">
        <form>
          <ListForm
            form={form}
            formFields={PERSONNEL_REQUEST_FORM_FIELDS}
            onFormChange={this._onFormChange}
          >
            <RequestTableForm
              listField={PERSONNEL_ITEM_FORM}
              error={itemErrors}
              list={items}
              fieldName="items"
              onAddList={this._onAddList}
              onChange={this._onFormChange}
            />
          </ListForm>
          <div className="manage-request">
            <Button
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
