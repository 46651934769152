import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../../utils/transformer.util';
import Button from '../../../components/base/Button/Button.component';
import {
  MANAGE_INFORMATION_LETTER,
} from '../../../constants/personnel.constant';

export default class ManageInformationLetter extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { isEdit = false, data } = location.state;
    if (isEdit) {
      this._setForm(data);
    }
  }

  _setForm(payload) {
    this.setState({
      form: {
        value: {
          id: payload.id,
          no_sk: payload.no_sk,
          judul_sk: payload.judul_sk,
          jenis_sk: payload.jenis_sk,
          keterangan: payload.keterangan,
          kota: payload.kota,
          nama_ketua_yayasan: payload.nama_ketua_yayasan,
          nama_yayasan: payload.nama_yayasan,
          diangkat_sebagai: payload.diangkat_sebagai,
          tembusan: payload.tembusan,
          i_pangkat_golongan: payload.i_pangkat_golongan,
          i_jabatan: payload.i_jabatan,
          i_tanggal: payload.i_tanggal,
          i_gaji_pokok: commaFormatted(payload.i_gaji_pokok),
          i_periode: payload.i_periode,
          i_lama_bekerja: payload.i_lama_bekerja,
          i_terbilang: payload.i_terbilang,
          ii_pangkat_golongan: payload.ii_pangkat_golongan,
          ii_jabatan: payload.ii_jabatan,
          ii_tanggal: payload.ii_tanggal,
          ii_gaji_pokok: commaFormatted(payload.ii_gaji_pokok),
          ii_periode: payload.ii_periode,
          ii_lama_bekerja: payload.ii_lama_bekerja,
          ii_terbilang: payload.ii_terbilang,
          create_by: payload.create_by,
        },
        error: {},
      },
    });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text',
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleManageInformationLetter,
      history, location,
    } = this.props;
    const { data, isEdit = false } = location.state;
    handleManageInformationLetter({ nip: data.nip, ...form.value }, history.goBack, isEdit);
  }

  render() {
    const { form } = this.state;
    return (
      <div className="manage-information-letter">
        <div>
          <h1 className="manage-information-letter__title">Surat Keputusan</h1>
        </div>
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={MANAGE_INFORMATION_LETTER}
            onFormChange={this._onFormChange}
          />
          <div>
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
ManageInformationLetter.propTypes = {
  handleManageInformationLetter: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
ManageInformationLetter.defaultProps = {
  handleManageInformationLetter: noop,
};
