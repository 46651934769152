import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Checkbox } from '../../../../../components/base';

export default class TableLaporan extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      showApprovalCol,
      showEdit,
      checkedAll,
      onCheckAll,
      showEditRecommendation,
      showRecommendation,
      workflow,
      user
    } = this.props;
    const { user_group } = user;
    const WorkflowPrev_role = workflow[0]?workflow[0].prev_role:'';
    const lastWorkflow = workflow[workflow.length - 1] || {};
    return (
      // <div className="manage-fund-request__form-table">
      // <table className="table">
      // <thead style={{backgroundColor:"grey", Color:"white"}}>
      //   <tr>
      //     <th width="1%" >No.</th>
      //     <th width="6%"rowSpan={2}>Kode Coa</th>
      //     <th width="10%"rowSpan={2}>Kode Kegiatan</th>
      //     <th width="10%" rowSpan={2}>Program Kegiatan</th>
      //     <th width="8%"rowSpan={2}>Jenis Kegiatan</th>
      //     <th width="10%"rowSpan={2}>PIC Kegiatan</th>
      //     <th width="20%" colSpan={2}>Tgl</th>
      //     <th width="25%"colSpan={3}>RP</th>
      //     <th width="10%"rowSpan={2}>Pembayaran Dana</th>
      //     <th width="8%" rowSpan={2} >Rincian Anggaran</th>
      //     { showEdit && (
      //       <th rowSpan={2} width="4%"/>
      //     )}
      //   </tr>
      //   <tr>
      //     <th width="20%">Butuh Dana</th>
      //     <th>Pelaksanaan</th>
      //     <th width="10%">RAPBS</th>
      //     <th>Pengajuan</th>
      //     <th>Selisih</th>

      //   </tr>
      // </thead>
      <thead>
        <tr>
          <th style={{ width: "5%" }}>No.</th>
          <th className="manage-budget__row table" style={{ width: "10%" }}>Tanggal Invoice</th>
          <th className="manage-budget__row table" style={{ width: "12%" }}>Nis</th>
          <th className="manage-budget__row table" style={{ width: "18%" }}>Nomor Va</th>
          <th className="manage-budget__row table" style={{ width: "12%" }}>Nama Siswa</th>
          <th className="manage-budget__row table" style={{ width: "15%" }}>Status</th>
          <th className="manage-budget__row table" colSpan={2}>Tipe Pembayaran</th>
          <th  style={{ width: "20%" }} >No invoice</th>
          <th className="manage-budget__row table" >Jenis</th>
          <th className="manage-budget__row table" >Periode</th>
          <th  style={{ width: "20%" }} >Nominal</th>
          <th className="manage-budget__row table" >Total tagihan</th>
          <th className="manage-budget__row table" >Payment</th>
          <th className="manage-budget__row table" >Denda</th>
          <th className="manage-budget__row table" >Balance</th>
         
        </tr>
       
      </thead>
      // </table>
    //  </div>
    );
  }
}

TableLaporan.propTypes = {
  showEditRecommendation: PropTypes.bool,
  showRecommendation: PropTypes.bool,
  showApprovalCol: PropTypes.bool,
  showEdit: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onCheckAll: PropTypes.func,
  workflow: PropTypes.array,
  user: PropTypes.array,
};
TableLaporan.defaultProps = {
  showEditRecommendation: false,
  showRecommendation: PropTypes.bool,
  showApprovalCol: false,
  showEdit: false,
  checkedAll: false,
  onCheckAll: noop,
  workflow: [],
  user: [],
};
