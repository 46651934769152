import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../../components/base/index';


export default function IndividualForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="individual__custom-form">

      <div className="individual__custom-form-row">
        <div className="individual__custom-form-row__field">
          <Input
            type='text'
            name="namaSaudara1"
            label="Nomor Siswa/ Tahun Ajaran"
            onChange={onFormChange}
            style={{backgroundColor:'#999'}}
          />
        </div>
        <div className="individual__custom-form-column__field">
            <Input
                type='text'
                name="namaSaudara1"
                label="Nomor Invoice"
                onChange={onFormChange}
                style={{backgroundColor:'#999'}}
                placeholder="100"
            />
        </div>
        </div>

        <div className="individual__custom-form-row">
            <div className="individual__custom-form-row__field">
                <Input
                    type="text"
                    name="namaSaudara1"
                    label="Nama Lengkap Siswa"
                    onChange={onFormChange}
                    data={ '7A', 'kelas7a'}
                    style={{backgroundColor:'#999'}}
                
                    // error={form.error.namaSaudara1 || ''}
                />
            </div>
            <div className="individual__custom-form-column__field">
                <Input
                    type='text'
                    name="namaSaudara1"
                    label="Nama Tertagih"
                    onChange={onFormChange}
                    style={{backgroundColor:'#999'}}
                />
            </div>
        </div>

        <div className="individual__custom-form-row">
            <div className="individual__custom-form-row__field">
                <Textarea
                        type='text'
                        name="namaSaudara1"
                        label="Alamat Tertagih"
                        onChange={onFormChange}
                        style={{backgroundColor:'#999'}}
                    />
            </div>
            <div className="individual__custom-form-row-input__field">
                <Input
                    type='date'
                    name="tgl_invoice"
                    label="Tanggal Invoice"
                    onChange={onFormChange}
                    value={form.value.tgl_invoice}
                    error={form.error.tgl_invoice}
                />
                <div className="individual__custom-form-margin-left">
                    <Input
                        type='date'
                        name="tgl_tempo"
                        label="Tanggal Jatuh Tempo"
                        onChange={onFormChange}
                        value={form.value.tgl_tempo}
                        error={form.error.tgl_tempo}
                    />
                </div>
            </div>
        </div>
        <div className="individual__custom-form-row">
            <div className="individual__custom-form-row__field">
                <Select
                    type="text"
                    name="autodebet"
                    label="Autodebet"
                    onChange={onFormChange}
                    placeholder='Pilihan'
                    data={ '7A', 'kelas7a'}
                    value={form.value.autodebet}
                    error={form.error.autodebet}
                />
            </div>
            <div className="individual__custom-form-column__field">
                <Select
                    type='text'
                    name="mata_uang"
                    label="Mata Uang"
                    data={'7A', 'kelas7a'}
                    placeholder={'Pilihan'}
                    onChange={onFormChange}
                    value={form.value.mata_uang}
                    error={form.error.mata_uang}
                />
            </div>
        </div>
        <div className="individual__custom-form-row">
            <div className="individual__custom-form-row__field">
                <Input
                    type="text"
                    name="keterangan_invoice"
                    label="Keterangan Invoice (Header)"
                    onChange={onFormChange}
                    data={ '7A', 'kelas7a'}
                    value={form.value.keterangan_invoice}
                    error={form.error.keterangan_invoice ||''}
                />
            </div>
        </div>

        </div>
        
 
   
  );
}
IndividualForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
IndividualForm.defaultProps = {
  onFormChange: noop,
};
