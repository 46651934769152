import { connect } from 'react-redux';
import ManageBudgetTransfer from '../../../../views/Finance/Budgeting/BudgetTransfer/ManageBudgetTransfer.finance.view';
import { saveBudgetTransfer, getBudgetCheckList, getBudgetTransferById } from '../../../../states/thunks/finance.thunk';
import { getDetailHead } from '../../../../states/thunks/common.thunk';
import { getRapbuCoa } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getRapbuCoa(payload)),
    handleGetDetailHead: payload => dispatch(getDetailHead(payload)),
    handleGetBudgetCheckList: (payload, unit_id) => dispatch(getBudgetCheckList(payload, unit_id)),
    handleSaveBudgetTransfer: (payload, goBack, unit_id) => dispatch(saveBudgetTransfer(payload, goBack, unit_id)),
    handleGetBudgetTransferById: (payload, goBack) => (
      dispatch(getBudgetTransferById(payload, goBack))),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudgetTransfer);
