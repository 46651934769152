// import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { errorAlert } from '../../utils/alert.util';
import { ADMISSION_RESULT_MOCK } from '../../constants/admission.constant';

export function manageAdmission(payload = {}, push) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { period, desc } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', period);
      requestBody.append('desc', desc);
      // await api.BUDGET(requestBody, '/head/add', { additional: ['lang', 'create_by'] });
      push('/admission/results');
    } catch (err) {
      errorAlert({
        title: 'Registrasi Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getResults() {
  return async () => {
    try {
      return {
        result: ADMISSION_RESULT_MOCK,
        currentPage: 1,
        totalPage: 1,
        total: 1,
      };
    } catch (err) {
      throw err;
    }
  };
}
