import NilaiRaportCetak from '../../../views/Kurikulum/Raport/NilaiRaportCetak.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editLembarObsHarian, saveRaportSiswa, listRaportSubject, getRaportSiswa } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditLembarObsHarian: (payload, goBack) => dispatch(editLembarObsHarian(payload, goBack)),
        handleSaveRaportSiswa: (payload, goback) => dispatch(saveRaportSiswa(payload, goback)),
        handleGetRaportSiswa: payload => dispatch(getRaportSiswa(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleListRaportSubject: (payload, goback) => dispatch(listRaportSubject(payload, goback)),
      
      };
}
export default connect(mapStateToProps, mapDispatchToProps) (NilaiRaportCetak);