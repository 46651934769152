import { generateYearList } from '../../../utils/transformer.util';

export const FINANCE_ADD_BUDGET_FORM_FIELDS = [{
  type: 'select',
  name: 'period',
  data: generateYearList(),
  label: 'Periode',
  placeholder: 'pilih period',
  full: true,
}, {
  type: 'textarea',
  name: 'desc',
  label: 'Deskripsi',
  placeholder: 'Isi deskripsi',
  full: true,
}];

export const BUDGET_REQUIRED_FIELD = ['parameter_code'];

export const FINANCE_BUDGET_TABLE_FIELDS = {
  label: [
    'No',
    'Period',
    'Deskripsi',
    'Status',
    '',
  ],
  value: [{}, { type: 'string', attribute: 'periode' }, { type: 'string', attribute: 'desc', disabled: true }, { type: 'string', attribute: 'status', disabled: true }],
};
