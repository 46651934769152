import { connect } from 'react-redux';
import EmployeeContract from '../../../views/Personnel/Letter/EmployeeContract.letter.view';
import { getEmployeeContract } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetEmployeeContract: payload => dispatch(getEmployeeContract(payload)),
  };
}

export default connect(null, mapDispatchToProps)(EmployeeContract);
