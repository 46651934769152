import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Button } from '../../../components/base/index';

import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import PengisianKD2Form_part1 from './components/PengisianKD2Form_part1.component';
import PengisianKD2Form_part2 from './components/PengisianKD2Form_part2.component';


export default class PengisianKD2 extends Component {
    constructor(props) {
        super(props);
        //deklarasi fungsi
        this._onFormChange = this._onFormChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._setTema = this._setTema.bind(this);
        // deklarasi state
        this.state = {
            form: {
                value: {
                    kd_list: [
                        {

                        }
                    ],
                },
                error: {
                    kd_list: '',
                }
            },
            tema: 1,
            pilihan: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
        };
    }

    _onSubmit() {

    }

    _onSubmit2() {

    }

    _onFormChange(event) {
        const {
            name,
            value,
            dataset,
            checked,
            type,
        } = event.target;
        const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
        } = dataset;
        this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;

            if (inputType === 'number') {
                formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
                if (type === 'checkbox') {
                    formattedValue = checked;
                }
                newList = prevState.form.value[fieldName];
                newListError = prevState.form.error[fieldName];
                newList[arrayPosition][name] = formattedValue;
                if (name === 'code_of_account') {
                    newList[arrayPosition].isCredit = value.type;
                }
                if (!isEmpty(newListError[arrayPosition])) {
                    newListError[arrayPosition][name] = '';
                }
            }
            return {
                form: {
                    value: {
                        ...prevState.form.value,
                        ...(inputArray
                            ? { [fieldName]: newList }
                            : { [name]: formattedValue }),
                    },
                    error: {
                        ...prevState.form.error,
                        ...(inputArray
                            ? { [fieldName]: newListError }
                            : { [name]: '' }),
                    },
                },
            };
        });
    }

    _onClickAdd(data) {
        const { form } = this.state;
        const { kd_list } = form.value;

        kd_list.push({

        });

        this.setState(prevState => ({
            form: {
                value: {
                    ...prevState.form.value,
                    kd_list,
                },
                error: {
                    ...prevState.form.error,
                }
            }
        }));
    }

    _onDeleteList(idx) {
        this.setState((prevState) => {
            const { kd_list } = prevState.form.value;
            const list = prevState.form.value.kd_list;

            list.splice(idx, 1);
            return {
                ...prevState,
                kd_list: list
            };
        });
    }

    _setTema(item){
        this.setState({tema : item})
    }

    render() {
        const { form } = this.state;
        const { kd_list } = form.value;

        return (
            <div className="student-list">
                <PengisianKD2Form_part1

                />
                <hr></hr><br/>
                <span>
                    <h1>Kopetisi Dasar (KD) / Tema Ke : &nbsp;
                    {this.state.pilihan.map(item => {
                        return (<>
                            <button style={{fontSize : 14 }} onClick={e => this._setTema(item)}> {item}
                            </button>&nbsp;
                            </>
                        )
                    })}
                    </h1>
                </span>
                <br />
                <hr></hr><br />
                <PengisianKD2Form_part2
                    tema={this.state.tema}
                    onAddList={this._onClickAdd}
                    list={kd_list}
                    onDeleteList={this._onDeleteList}
                    onChange={this._onFormChange}
                />

                <div className="manage-registration__footer">
                    <div className="manage-registration__button-wrapper">
                        <Button
                            onClick={this._onSubmit}
                            title="Simpan Data"
                        />
                        <Button
                            style={{ backgroundColor: 'red' }}
                            onClick={this._onSubmit2}
                            title="Hapus Data"
                        />
                    </div>
                </div>
            </div >
        );
    }
}
PengisianKD2.propTypes = {
    handleAddBudget: PropTypes.func,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
};
PengisianKD2.defaultProps = {
    handleAddBudget: noop,
    user: null,
};