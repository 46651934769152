import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function KomposisiSiswaForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="komposisi-siswa__custom-form">
      
      
        <div className="komposisi-siswa__custom-form-row">
        <div className="komposisi-siswa__custom-form-row__field">
        <Select
            name="classes_year"
            label="Tahun Ajaran"
            onChange={onFormChange}
            data={ '7A', '2019-2020'}
            placeholder={''}
            value={form.value.classes_year}
            error={form.error.classes_year || ''}
          />
        </div>
        
        <div className="komposisi-siswa__custom-form-column__field">
            
          <Select
           
            name="bulan"
            label="Bulan"
            onChange={onFormChange}
            value={form.value.bulan || ''}
            error={form.error.bulan || ''}
            placeholder={'Pilihan'}
          />
        </div>
        </div>


        <div className="komposisi-siswa__custom-form-row">
         <div className="buat-ekskul__custom-form-data-row">
          <Select
            type="text"
            name="jenis_komposisi"
            label="Jenis Komposisi"
            onChange={onFormChange}
            value={form.value.jenis_komposisi || ''}
            error={form.error.jenis_komposisi || ''}
          />
          </div>
          
          <div className="komposisi-siswa__custom-font">
          <div className="komposisi-siswa__custom-font__field">
            
          Khusus Komposisi Menurut Umur
          </div>
          <div className="komposisi-siswa__custom-checkbox__field">
          <div className="komposisi-siswa__custom-form-row-form">
            Umur Awal  &nbsp;
          <div className="button-ekskul__button-wrapper">
             
               <Input
                 type="text"
                 name="umur_awal"
                 onChange={onFormChange}
                 value={form.value.umur_awal || ''}
                 error={form.error.umur_awal || ''}
                 
              /> 
                </div>
          </div>
            
          {/* <div className="buat-ekskul__custom-checkbox__field">
          <Checkbox
            name="tanggalpelanggaran"
            label="Selasa"
            onChange={onFormChange}
            checked='false'
            // checked='false'
            // value={form.value.tempatLahirSaudara1 || ''}
            // error={form.error.tempatLahirSaudara1 || ''}
          />
          </div> */}
          <div className="komposisi-siswa__custom-checkbox2__field">
           <div className="komposisi-siswa__custom-form-row-form">
            Umur Akhir&nbsp;
            
          <div className="button-ekskul__button-wrapper">
             
               <Input
                 type="text"
                 name="umur_akhir"
                 onChange={onFormChange}
                 value={form.value.umur_akhir || ''}
                 error={form.error.umur_akhir || ''}
                 
              /> 
                </div>
                </div>
          </div>
         
          </div>
           </div>
         </div>
        {/* <div className="komposisi-siswa__custom-form-row">
        <div className="komposisi-siswa__custom-form-column__field">
            
          <Select
           
            name="tahunajaran"
            label="Jenis Komposisi"
            onChange={onFormChange}
            // value={form.value.tempatLahirSaudara1 || ''}
            // error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'Pilihan'}
          />
        </div>
        <div className="komposisi-siswa__custom-form-column-text">
          Banana
          </div>
        <div className="komposisi-siswa__custom-form-row2__field">
        <div className="komposisi-siswa__custom-form-row-text">
            <div className="komposisi-siswa__custom-font">
              &nbsp;Umur Awal &nbsp;
            </div>
            
          <div className="button-ekskul__button-wrapper">
             
          <Input
              type="text"
              name="tahunajaran"
              onChange={onFormChange}
              // value={form.value.tempatLahirSaudara1 || ''}
              // error={form.error.tempatLahirSaudara1 || ''}
              placeholder={'Pilihan'}
            />
          </div>
        </div>
        
          <div className="komposisi-siswa__custom-form-row-text">
            <div className="komposisi-siswa__custom-font">
              &nbsp;Umur Akhir &nbsp;
            </div>
         
          <div className="button-ekskul__button-wrapper">
             
          <Input  
              type="text"
              name="tahunajaran"
              onChange={onFormChange}
              // value={form.value.tempatLahirSaudara1 || ''}
              // error={form.error.tempatLahirSaudara1 || ''}
              placeholder={'Pilihan'}
            />
          </div>
        </div>
        </div>

        </div> */}
        

       
        

        </div>
        
 
   
  );
}
KomposisiSiswaForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
KomposisiSiswaForm.defaultProps = {
  onFormChange: noop,
};
