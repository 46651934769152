import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      
     <div className="sampul-dpn-ktsp__header-content">
        <div id="divPrint" style={{width:800, border: '0px solid black'}}>
          <div id="sampul-dpn-ktsp" className="sampul-dpn-ktsp">
            {
                <div className="sampul-dpn-ktsp__content">
                  <div className="sampul-dpn-ktsp__header">
                    <div className="sampul-dpn-ktsp__header-top">
                      ID Rapor : 
                    </div>
                    <div className="sampul-dpn-ktsp__header-title">
                      <div className="sampul-dpn-ktsp__header-title--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        <p>LAPORAN HASIL BELAJAR PESERTA DIDIK</p>
                        <p>SEKOLAH MENENGAH PERTAMA</p>
                        <p>SMP ABC</p>
                        
                      </div>
                    </div>

                    <div className="sampul-dpn-ktsp__header-title2">
                      <div className="sampul-dpn-ktsp__header-title2--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        <p>Nama Siswa</p>
                        <p>Riri</p>
                      </div>
                    </div>

                    <div className="sampul-dpn-ktsp__header-title">
                      <div className="sampul-dpn-ktsp__header-title--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        <p>No. Induk Siswa</p>
                        <p>7890</p>
                      </div>
                    </div>

                  </div>
                 
                  {/* <div className="sampul-dpn-ktsp__footer">
                    <div className="sampul-dpn-ktsp__footer-bottom">
                      <div>
                        <p>Diserahkan oleh:</p>
                        <p>{journal.submitted_by}</p>
                      </div>
                      <div>
                        <p>Diterima oleh:</p>
                        <p>{journal.accepted_by}</p>
                      </div>
                      <div>
                        <p>Dibukukan oleh:</p>
                        <p>{journal.booked_by}</p>
                      </div>
                      <div>
                        <p>Diketahui oleh:</p>
                        <p>Name 4</p>
                      </div>
                    </div>
                  </div> */}
                </div>
            }
          </div>
      </div>
      </div>
    );
  }

}
export default class SampulDpnKTSP extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
  SampulDpnKTSP.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  SampulDpnKTSP.defaultProps = {
    handleGetCashJournal: noop,
  };
  