import {
  LOAD_JOURNAL_LIST,
  LOAD_JOURNAL_LIST_FAILED,
  LOAD_JOURNAL_LIST_SUCCESS,
  POST_JOURNAL_POSTING,
  POST_JOURNAL_POSTING_FAILED,
  POST_JOURNAL_POSTING_SUCCESS,
} from '../actions/common.action';

const defaultState = {
  result: [],
  currentPage: '',
  totalPage: '',
  total: '',
};

export default function journal(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_JOURNAL_LIST: {
      const {
        data, current_page, total_page, total,
      } = payload;
      return {
        ...state,
        result: data,
        currentPage: current_page,
        totalPage: total_page,
        total,
      };
    }
    case LOAD_JOURNAL_LIST_FAILED: {
      return defaultState;
    }
    case LOAD_JOURNAL_LIST_SUCCESS: {
      return state;
    }
    case POST_JOURNAL_POSTING: {
      return state;
    }
    case POST_JOURNAL_POSTING_FAILED: {
      return state;
    }
    case POST_JOURNAL_POSTING_SUCCESS: {
      return state;
    }
    default: {
      return state;
    }
  }
}
