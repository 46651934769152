import PengisianDaftarNilaiMan from '../../../views/Kurikulum/Ulangan/PengisianDaftarNilaiMan.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editDaftarNilai, saveDaftarNilaiMan, getLembarDaftarNilaiMan } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getTeacherSubjectsOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditDaftarNilai: (payload, goBack) => dispatch(editDaftarNilai(payload, goBack)),
        handleSaveDaftarNilaiMan: (payload, goback) => dispatch(saveDaftarNilaiMan(payload, goback)),
        handleGetDaftarNilaiMan: payload => dispatch(getLembarDaftarNilaiMan(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getTeacherSubjectsOptions: (payload) => dispatch(getTeacherSubjectsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (PengisianDaftarNilaiMan);