import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import { Checkbox } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';

export default function RealizationCheckList(props) {
  const {
    budgets, onChange,
    selectedBudget,
  } = props;
  return (
    <div className="manage-realization__form-table">
      <div className="manage-realization__table-header">
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Code Of Account</th>
              <th>Keterangan</th>
              <th>APBU</th>
              <th>Saldo</th>
              <th>%</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="manage-realization__table-content">
        <table cellPadding="0" cellSpacing="0" border="0">
          <tbody>
            {
              map(budgets, (budget, idx) => (
                <tr key={`manage-realization_transaction__${idx}`}>
                  <td>
                    <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={onChange}
                      checked={!isEmpty(selectedBudget) ? budget.id === selectedBudget.id : false}
                    />
                  </td>
                  <td>
                    {budget.parameter_code.title}
                  </td>
                  <td>
                    {budget.desc}
                  </td>
                  <td>
                    {commaFormatted(budget.total)}
                  </td>
                  <td>
                    {commaFormatted(budget.remains)}
                  </td>
                  <td>
                    {(budget.remains / budget.total) * 100}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
RealizationCheckList.propTypes = {
  budgets: PropTypes.array,
  onChange: PropTypes.func,
  selectedBudget: PropTypes.object,
};
RealizationCheckList.defaultProps = {
  budgets: [],
  onChange: noop,
  selectedBudget: {},
};
