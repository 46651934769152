import CetakRaport from '../../../views/Kurikulum/Ulangan/CetakRaport.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editDaftarNilai, saveDaftarNilai, getLembarDaftarNilai } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditDaftarNilai: (payload, goBack) => dispatch(editDaftarNilai(payload, goBack)),
        handleSaveDaftarNilai: (payload, goback) => dispatch(saveDaftarNilai(payload, goback)),
        handleGetDaftarNilai: payload => dispatch(getLembarDaftarNilai(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (CetakRaport);