import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../utils/transformer.util';
import { UPLOAD_STAFF_FORM_FIELDS } from '../../constants/personnel.constant';
import { Button } from '../../components/base';

export default class UploadStaff extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      files,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputType === 'file') {
        [formattedValue] = files;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleUploadStaff, history } = this.props;
    handleUploadStaff(form.value, history.goBack);
  }

  render() {
    const { form } = this.state;
    return (
      <div className="upload-staff">
        <h1>Upload Staff</h1>
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={UPLOAD_STAFF_FORM_FIELDS}
            onFormChange={this._onFormChange}
          />
          <div className="upload-staff__content">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
UploadStaff.propTypes = {
  handleUploadStaff: PropTypes.func,
  history: PropTypes.object.isRequired,
};
UploadStaff.defaultProps = {
  handleUploadStaff: noop,
};
