import React,  {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class AbsensiRekapPage2 extends Component {
  constructor(props){
    super(props);
    this._onFormChange = this._onFormChange.bind(this);

 this.state ={
     form: {
         values: {
             classes: '',
             keterangan:'',
             kompetensi:'',
             tahunajaran: '',
         },
         error: {
             classes: '',
             keterangan:'',
             kompetensi:'',
             tahunajaran: '',
         }
     }
 }
 }

_onFormChange(e) {
 const { onChange } = this.props;
 let change = {}
 change[e.target.name] = e.target.value
 this.setState(change , () => { console.log(this.state) })

 onChange(e);
}


render(){
  const { onChange, form, classes } = this.props;
  return (
    <div className="isi-bidang__custom-form">
      <div className="isi-bidang__custom-form-column">
        <div className="isi-bidang__custom-form-row__field">
          <p>Tanggal :  </p>
         </div>
      </div>
      <div className="isi-bidang__custom-form-column">
        <div className="isi-bidang__custom-form-row__field">
          <p>Kelas :</p>
         </div>
      </div>
    </div>
  );
}


 
}
AbsensiRekapPage2.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.array,
  form: PropTypes.object.isRequired,
};
AbsensiRekapPage2.defaultProps = {
  onChange: noop,
  classes: [],
};
