import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import AdjustmentJournalForm from './components/AdjustmentJournalForm.component';
import { FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS } from '../../../../constants/finance/finance.constant';
import Button from '../../../../components/base/Button/Button.component';
import { validateAdjustmentJournal } from '../../../../utils/validation.util';
import { errorAlert, actionConfirmation } from '../../../../utils/alert.util';
import { dateFormat, commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';


const calculateTotal = (sourceDetails, corrections) => {
  let total_debit = 0;
  let total_credit = 0;
  corrections.forEach((item) => {
    total_debit += item.debit || 0;
    total_credit += item.credit || 0;
  });

  return {
    total_debit,
    total_credit,
  };
};

const calculateCorrectionAmount = (details, type) => {
  let total = 0;
  details.forEach((detail) => {
    if (type === 'K') {
      total += detail.credit;
    } else {
      total += detail.debit;
    }
  });
  return total;
}

export default class ManageAdjustmentJournal extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);

    this._getSourceJournalOptions = this._getSourceJournalOptions.bind(this);
    this._getSourceJournal = this._getSourceJournal.bind(this);
    this._searchSourceJournal = this._searchSourceJournal.bind(this);
    this._onSaveCorrection = this._onSaveCorrection.bind(this);
    this._setCorrections = this._setCorrections.bind(this);
    this._calculateCorrectionAmount = this._calculateCorrectionAmount.bind(this);
    this._setCoa = this._setCoa.bind(this);
    this.state = {
      form: {
        value: {
          date: dateFormat(),
          source: {
            id: null,
            journal_number: null,
            journal_details: [],
          },
          journal_number: null,
          corrections: [],
          correction_amount: 0,
          total_debit: 0,
          total_credit: 0,
          amount_difference: 0,
          type: '',
          selected_ids: [],
        },
        error: {
          corrections: [],
        },
      },
      original: {},
      coa: {},
    };
  }

  componentDidMount() {
    const { handleGetCoa, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    if (isEdit) {
      this._setForm(data.id);
    }
    this._getSourceJournalOptions();
    this._setCoa();
  }

  componentDidUpdate({ user: oldUser }) {
    const { user } = this.props;
    if (JSON.stringify(user.workingUnit) !== JSON.stringify(oldUser.workingUnit)) {
      this._getSourceJournalOptions();
    }
  }

  async _setCoa() {
    const { handleGetCoa } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { source = {}, type } = value;
    const journalType = source.journal_type ? source.journal_type : null;
    let res = {};

    if (type === null || type === 'CODE') {
      res = await handleGetCoa();
      this.setState({ coa: res });
    } else if (journalType === 'BANK') {
      res = await handleGetCoa({
        account: source.cash_bank_journal.bankAccount,
      });
      this.setState({ coa: res });
    } else {
      res = await handleGetCoa({
        account: '11101',
      });
      this.setState({ coa: res });
    }
  }

  async _setForm(id) {
    const { handleGetAdjustmentJournal } = this.props;
    try {
      const payload = await handleGetAdjustmentJournal({ id });
      const { source, corrections: payloadCorrections } = payload;
      const { journal_details } = source;
      const selected_ids = payload.selected_ids || [];
      let corrections = [];

      if (payloadCorrections.length > 0) {
        corrections = payloadCorrections;
      } else {
        corrections = journal_details.filter(item => selected_ids.includes(item.id));
      }

      selected_ids.forEach((o, i) => {
        selected_ids[i] = o.toString();
      });

      corrections.forEach((o, i) => {
        corrections[i].code_of_account = o.parameter_code;
      });

      this.setState({
        form: {
          value: {
            id: payload.id,
            journal_number: payload.journal_number,
            date: payload.date,
            corrections,
            source: payload.source,
            source_journal_id: payload.source_journal_id,
            selected_ids,
            type: payload.type,
          },
          error: {
            corrections: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getSourceJournalOptions(param = {}) {
    const { keyword = '' } = param;
    const { handleGetSourceJournalOptions } = this.props;
    const res = await handleGetSourceJournalOptions({
      keyword,
    });
    this.setState({ sourceJournals: res }, () => res);
    return res;
    // this.setState(prevState => ({
    //   ...prevState,
    //   sourceJournals: res,
    // }));
  }

  _searchSourceJournal({ keyword }) {
    const { sourceJournals } = this.state;
    return sourceJournals.filter(item => item.title.includes(keyword));
  }

  async _getSourceJournal(id) {
    const { handleGetSourceJournal } = this.props;
    const res = await handleGetSourceJournal({ id });
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          source: res,
          corrections: [],
          selected_ids: [],
          total_debit: 0,
          total_credit: 0,
          amount_difference: 0,
        },
      },
    }), () => {
      this._setCoa();
    });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName, source: sourceId = null,
    } = dataset;
    this.setState((prevState) => {
      const { form } = prevState;
      const { value: formValue } = form;
      const { source, corrections } = formValue;
      const { journal_details } = source;
      const {
        total_debit,
        total_credit,
      } = formValue;
      const newList = formValue[fieldName] || [];
      const newListError = [];
      const newCorrections = [...corrections];
      let total = {
        total_debit,
        total_credit,
      };
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (name === 'selected_ids') {
        formattedValue = formValue[name] || [];
        if (checked) {
          formattedValue.push(value);
        } else {
          formattedValue.splice(formattedValue.indexOf(value), 1);
        }
      } else if (inputArray) {
        if (!newList[arrayPosition]) {
          newList[arrayPosition] = {};
          if (fieldName === 'corrections') {
            newList[arrayPosition].source_journal_details_id = sourceId;
          }
        }
        newList[arrayPosition][name] = formattedValue;
      }

      if (['debit', 'credit', 'selected_ids', 'type'].includes(name)) {
        total = calculateTotal(journal_details, newList);
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
            total_credit: total.total_credit,
            total_debit: total.total_debit,
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      const { form } = this.state;
      const { value: formValue } = form;
      const { source, type } = formValue;
      if (name === 'source_journal_id') {
        this._getSourceJournal(value.id);
      }
      if (name === 'selected_ids') {
        if (type === 'CODE') {
          this._setCorrections(false, value, checked);
        }
        this._calculateCorrectionAmount();
      }
      if (name === 'type') {
        this._setCoa();
        this._setCorrections(true);
      }
    });
  }

  _resetCorrections() {
    const { form } = this.state;
    form.value.corrections = [];
    this.setState({ form });
  }

  _calculateCorrectionAmount() {
    const { form } = this.state;
    const { value } = form;
    const { selected_ids, source } = value;
    const { journal_details, is_credit } = source;
    let total = 0;

    journal_details.forEach((detail) => {
      if (selected_ids.includes(detail.id.toString())) {
        if (is_credit) {
          total += detail.credit;
        } else {
          total += detail.debit;
        }
      }
    });

    this.setState({ correction_amount: total });
  }

  _setCorrections(reset = false, selectedId, checked = true) {
    const { form } = this.state;
    const { value } = form;
    const {
      type,
      corrections = [],
      selected_ids = [],
      source = {},
    } = value;
    const { journal_details = [] } = source;
    let newCorrections = reset ? [] : [...corrections];
    let selectedDetails = [];
    let total = {};

    if (selected_ids.length > 0 && type) {
      if (checked) {
        if (selectedId) {
          selectedDetails = journal_details.filter((detail) => {
            return selectedId.toString() === detail.id.toString();
          });
          selectedDetails = selectedDetails.map((item) => {
            return {
              credit: type === 'CODE' ? item.credit : 0,
              debit: type === 'CODE' ? item.debit : 0,
              source_journal_details_id: item.id,
              source_journal_id: item.journals_id,
              code_of_account: item.code_of_account,
            }
          });
          newCorrections = [...newCorrections, ...selectedDetails];
        } else {
          selectedDetails = journal_details.filter((detail) => {
            return selected_ids.includes(detail.id.toString());
          }).map((item) => {
            return {
              credit: type === 'CODE' ? item.credit : 0,
              debit: type === 'CODE' ? item.debit : 0,
              source_journal_details_id: item.id,
              source_journal_id: item.journals_id,
              code_of_account: item.code_of_account,
            }
          });
          newCorrections = selectedDetails;
        }
      } else {
        const index = newCorrections.findIndex((item) => {
          const id = item.source_journal_details_id.toString();
          return id === selectedId.toString();
        });
        newCorrections.splice(index, 1);
      }
    } else {
      newCorrections = [];
    }

    form.value.corrections = newCorrections;
    total = calculateTotal(journal_details, form.value.corrections);
    form.value.total_debit = total.total_debit;
    form.value.total_credit = total.total_credit;
    this.setState({ form });
  }

  _onAddList(fieldName) {
    this.setState(prevState => (
      {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [
              ...prevState.form.value[fieldName],
              {},
            ],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [
              ...prevState.form.error[fieldName],
              {},
            ],
          },
        },
      }));
  }

  _onDeleteList(idx, fieldName) {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];
      const listError = prevState.form.error[fieldName];
      list.splice(idx, 1);
      const { corrections = [] } = prevState.form.value;
      const total = calculateTotal(corrections);
      return {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [...list],
            total_debit: total.total_debit,
            total_credit: total.total_credit,
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [...listError],
          },
        },
      };
    });
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  async _onSubmit(isReversal = false) {
    const { form } = this.state;
    const { handleManageAdjustmentJournal } = this.props;
    let error = {};
    let res = {};

    if (!isReversal) {
      error = validateAdjustmentJournal(form.value) || {};
    }
    if (typeof error === 'string') {
      errorAlert({
        title: 'alerts.form.save_failed.title',
        errorMessage: error,
      });
    } else if (typeof error === 'object' && Object.keys(error).length !== 0) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          error,
        },
      }));
    } else if (!form.value.id) {
      const confirm = await actionConfirmation(noop, form, 'Jurnal yang terkoreksi tidak bisa diubah.');
      if (confirm) {
        res = await handleManageAdjustmentJournal({ ...form.value, isReversal });
        this._setForm(res.id);
      }
    } else {
      res = await handleManageAdjustmentJournal({ ...form.value, isReversal });
      this._setForm(res.id);
    }
  }

  async _onSaveCorrection(isReversal = false) {
    const { form } = this.state;
    const { handleSaveCorrection } = this.props;
    let error = {};

    error = validateAdjustmentJournal(form.value) || {};

    if (typeof error === 'string') {
      errorAlert({
        title: 'alerts.form.save_failed.title',
        errorMessage: error,
      });
    } else if (typeof error === 'object' && Object.keys(error).length !== 0) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          error,
        },
      }));
    } else {
      const res = await handleSaveCorrection({ ...form.value });
      this._setForm(res.id);
    }
  }

  render() {
    const {
      form,
      sourceJournals,
      correction_amount = 0,
      coa = [],
    } = this.state;
    const {
      corrections = [],
      source,
      selected_ids,
      type,
      total_credit,
      total_debit,
      id,
    } = form.value;
    const { error } = form;
    const { corrections: detailsErrors } = error;
    const fields = FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS.map(field => Object.assign({}, field));

    fields.forEach((field, index) => {
      if (id && id !== null) {
        if (field.name === 'source_journal_id'
          || field.name === 'date' || field.name === 'type') {
          fields[index].disabled = true;
        }
      } else if (field.name === 'source_journal_id') {
        fields[index].handleSearchContent = this._getSourceJournalOptions;
      }
    });
    return (
      <div className="manage-adjustment-journal">
        <form>
          <ListForm
            form={form}
            coa={coa}
            sourceJournals={sourceJournals}
            formFields={fields}
            onFormChange={this._onFormChange}
            error={error}
          >
            <AdjustmentJournalForm
              coa={coa}
              fieldName="corrections"
              error={detailsErrors}
              source={source}
              corrections={corrections}
              onAddList={this._onAddList}
              onDeleteList={this._onDeleteList}
              onChange={this._onFormChange}
              selectedIds={selected_ids}
              total_debit={total_debit}
              total_credit={total_credit}
              type={type}
              hasId={id}
              onSubmit={this._onSubmit}
            />
          </ListForm>
          <div>
            <h3>Nilai Koreksi: {commaFormatted(correction_amount)}</h3>
          </div>
          <div>
            <h3>Total Debit: {commaFormatted(total_debit)}</h3>
          </div>
          <div>
            <h3>Total Credit: {commaFormatted(total_credit)}</h3>
          </div>
          { (corrections.length > 0 && !id) && (
            <div className="manage-adjustment-journal">
              <Button
                type="button"
                title="Koreksi"
                onClick={() => this._onSaveCorrection()}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
}
ManageAdjustmentJournal.propTypes = {
  handleManageAdjustmentJournal: PropTypes.func,
  handleGetAdjustmentJournal: PropTypes.func,
  handleGetCoa: PropTypes.func.isRequired,
  handleGetSourceJournalOptions: PropTypes.func.isRequired,
  handleGetSourceJournal: PropTypes.func.isRequired,
  handleSaveCorrection: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
};
ManageAdjustmentJournal.defaultProps = {
  handleManageAdjustmentJournal: noop,
  handleGetAdjustmentJournal: noop,
};
