import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    // this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  // async onGetDetails() {
  //   const { handleGetCashJournal, match } = this.props;
  //   const { id } = match.params;
  //   try {
  //     const payload = await handleGetCashJournal({ id });
  //     this.setState({
  //       journal: payload,
  //     });
  //   } catch (err) {
  //     // err action
  //   }
  // }

   
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  
  


  render() {
    const { journal } = this.state;
    const { form } = this.props;
    return (
      <div id="divPrint" style={{width:1000, border: '0px solid black'}}>
      <div >
     
      {/* <div className="report__content2">
        <Select
        name="kelas"
        onChange={this._onFormChange}
        data={ '7A', 'kelas7a'}
        placeholder={'Kelas 7A'}
        />  
        </div> */}
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="cetak-buku-induk" className="cetak-buku-induk">
          {
                <div className="invoice-sum__content">
                            
                            <div className="invoice-sum__header2">
                              Daftar Payment Uang Sekolah Dari Tanggal
                            </div>
                            
                            <div className="komposisi-siswa__custom-form">
                                <div className="invoice-sum__custom-form-row__field">
                                    <div className="invoice-sum__custom-form-row3"> 
                                        <Input
                                            type="text"
                                            name="tahunajaran"
                                            style={{backgroundColor:'grey'}}
                                            label=""
                                            
                                            // value={form.value.tempatLahirSaudara1 || ''}
                                            // error={form.error.tempatLahirSaudara1 || ''}
                                            
                                        />
                                    </div>
                                    <p>S/D</p>
                                    <div className="invoice-sum__custom-form-row4">
                                        <Input
                                            type="text"
                                            name="tahunajaran"
                                            style={{backgroundColor:'grey'}}
                                            label=""
                                            // onChange={onFormChange}
                                            // value={form.value.tempatLahirSaudara1 || ''}
                                            // error={form.error.tempatLahirSaudara1 || ''}
                                            
                                        />
                                     </div>
                                </div>
                      
                             
                            </div>     
                                <div className="invoice-sum__body">
                                <table className="invoice-sum__table">
                                    <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Tanggal</th>
                                        <th>Nama Siswa</th>
                                        <th>No.Invoice</th>
                                        <th>Invoice Detail</th>
                                        <th>Total Invoice</th>
                                        <th>No Receipt</th>
                                        <th>AMOUNT</th>
                                        <th>Credit Note</th>
                                        <th>AMOUNT</th>
                                        <th>Balance</th>
                                        <th>Ket</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                  {/* {
                                  map(journal.details, (list, idx) => (
                                    <tr key={`journal_detail_${idx}`}>
                                      <td>{idx + 1}</td>
                                      <td>{list.code_of_account}</td>
                                      <td>{list.parameter_code.title}</td>
                                      <td>{list.description}</td>
                                      <td className="nominal">{commaFormatted(list.amount)}</td>
                                    </tr>
                                  ))
                                } */}
                                <tr>
                                    <td>1</td>
                                    <td>1 Jan  2019</td>
                                    <td>7a<br></br>
                                    Nama lengkap<br></br>
                                    12345</td>
                                    <td> <Input
                                          label="Uang SPP"
                                          name="uang_spp"
                                          onChange={this._onFormChange}
                                          disabled
                                          // value={form.value.uang_spp}
                                          
                                        /><br></br>
                                        <Input
                                         label="Uang Jemputan"
                                         onChange={this._onFormChange}
                                         disabled
                                          // value={form.value.uang_spp}
                                        /><br></br>
                                         <Input
                                         label="Uang Ekskul"
                                         onChange={this._onFormChange}
                                         disabled
                                        /></td>
                                    <td>120000</td>
                                    <td>Paid</td>
                                    <td>120000</td>
                                    <td>Paid</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  
                                </tr>
            
                             
                               
                               
                                </tbody>
                                {/* <tfoot>
                                  <tr>
                                    <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                                    <td colSpan="8" className="nominal"></td>
                                  </tr>
                                </tfoot> */}
                              </table>
                            </div>
             
                          </div>
             
           
          }
        </div>
      </div>
      </div>
    );
  }

}
export default class InvoicePayment extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                        
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
  InvoicePayment.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  InvoicePayment.defaultProps = {
    handleGetCashJournal: noop,
  };
  