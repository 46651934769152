import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { formatData,  normalizeAmount } from '../../../../utils/transformer.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { Input, Select, Textarea, Button } from '../../../../components/base/index';
import {Link} from 'react-router-dom';

export default class MasterDataKeu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
          filters: {
            from: '',
            to: '',
            code_group: '',
          },
          report: [],
        };
        this._onClickAdd =  this._onClickAdd.bind(this);
        this._onChange = this._onChange.bind(this);
        this.onFormChange=this.onFormChange.bind(this);
        this._onFormChange=this._onFormChange.bind(this);
        this.state= {
        
          form: {
            value: {},
            error: {},
          },
        };
      }


  
  componentDidMount(){
    const{handleGetClasses} = this.props;
    handleGetClasses();
  }
  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/data-keuangan');
  }

  

  _onChange(event) {
    const { target } = event;
    const { value, name } = target;

    this.setState((prevState) => {
      const { filters } = prevState;
      return {
        ...prevState,
        filters: {
          ...filters,
          [name]: value,
        },
      };
    });
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }
  
  render() {
    const {filters, form} = this.state;
    const {value, error} = form;
    // const { from, to, code_group } = filters;
    const { handleGetKelas, classes = {} } = this.props;
    return (
      
       
        <div className="budget__content">
            <div className="komposisi-siswa__custom-form-row">
                <div className="komposisi-siswa__custom-form-row__field">
                    <Select
                      name="classes_year"
                      label="Tahun Ajaran"
                      placeholder="Pilihan"
                      
                      onChange={this._onFormChange}
                    />
                </div>
                <div className="komposisi-siswa__custom-form-column__field">
                    <Select
                      name="classes"
                      label="Kelas"
                      data={classes.list}
                      placeholder="Pilihan"
                      value={value.classes}
                      onChange={this.onFormChange}
                    />
                </div>
          </div>
            <div className="add-upload-rapbu">
            <Button
              title="Cari"
              onClick={this._onClickAdd}
            />
          </div>
          

         
        </div>
    );
  }
}
MasterDataKeu.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
MasterDataKeu.defaultProps = {
  handleGetClasses: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
