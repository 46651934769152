import { connect } from 'react-redux';
import DetailBankJournal from '../../../../views/Finance/Bookkeeping/BankJournal/DetailBankJournal.finance.component';
import { getBankJournalPreview } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetBankJournal: payload => dispatch(getBankJournalPreview(payload)),
  };
}

export default connect(null, mapDispatchToProps)(DetailBankJournal);
