import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import { Link } from 'react-router-dom';


export default function PsikomotorikTable(props) {
  const { onFormChange, form } = props;
  return (
    <div className="tbm__content">
                <div className="tbm__body">
                  <table className="tbm__table">
                    <thead>
                      <tr>
                        <th rowSpan={2}>No/NIS</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={3}>UH / Praktikum</th>
                        <th colSpan={2}>Tugas</th>
                        <th rowSpan={2}>Nilai Akhir</th>
                        <th rowSpan={2}>Comment</th>
                      </tr>
                      <tr>
                        <th>UH 1 Judul Materi</th>
                        <th>UH 2 Judul Materi</th>
                        <th>UH 3 Judul Materi</th>


                        <th>Tugas 1</th>
                        <th>Tugas 2</th>

                       

                      </tr>
                     
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    {/* <tr>
                        <td>1</td>
                        <td>aa</td>
                        <td>122</td>
                        <td><Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ 'Hadir', 'hadir'}
                            placeholder={'Hadir'}
                           
           
                        // error={form.error.namaSaudara1 || ''}
                            /></td>
                       

                    </tr> */}
                     <tr>
                     <td>344444</td>
                     <td >Banana</td>
                      <td><Link to={'/dashboard/kurikulum/laporan/daftar-nilai-praktek'}>90</Link></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      
                    </tr>
                   

                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
PsikomotorikTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PsikomotorikTable.defaultProps = {
  onFormChange: noop,
};
