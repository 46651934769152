export const ADD_UPLOAD_FORM_FIELDS = [{
  type: 'select',
  name: 'period',
  label: 'Periode',
  placeholder: 'pilih period',
  full: true,
}, {
  type: 'textarea',
  name: 'desc',
  label: 'Deskripsi',
  placeholder: 'Isi deskripsi',
  full: true,
}];

export const UPLOAD_REQUIRED_FIELD = ['parameter_code'];

export const UPLOAD_TABLE_FIELDS = {
  label: [
    'No',
    'Period',
    'Deskripsi',
    '',
  ],
  value: [{}, { type: 'string', attribute: 'periode' }, { type: 'string', attribute: 'desc' }],
};
