import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../../utils/transformer.util';
import { Input, Select, Button } from '../../../../components/base/index';
import IndividualForm from './components/IndividualForm.component';
import ProsesInvoiceTbl from './components/ProsesInvoiceTbl.component';
import { LIST_CREATE_INOVICE_LIST_TABLE_FIELDS } from '../../../../constants/Akademi/Akademi.constant';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class ProsesInvoice extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this.handleGetData = this.handleGetData.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onDeleteList1 = this._onDeleteList1.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickAdd1 = this._onClickAdd1.bind(this);
        this._onChangeFilter = this._onChangeFilter.bind(this);
        this._getStatusJenisSPP = this._getStatusJenisSPP.bind(this);
        this._getParamOptions = this._getParamOptions.bind(this);
        this._getStudentOptions = this._getStudentOptions.bind(this);
        this._onSearchContent = this._onSearchContent.bind(this);
        this.onClickButton = this.onClickButton.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);


        this.state= {
          content: initialContent,
          listAmount: 5,
          list: [],
          filters: {},
          form: {
            value: {
              

              tabelinvoices: [
                {
                  users_id:'',
                  academics_year:'',
                  created_at: '',
                  payments_due_date: '',
                  payments_id:'',
                  no_va:'',
                  periode:'',
                  total_payments: '',
                  discount:'',
                  organizations_id: '1'
                }
              ],
              jenisspptabel: [
                {
                  invoices_id:'',
                  payments_id:'',
                  periode:'',
                  nominal:'',
                }
                
              ],
            },
            error: {
              tabelinvoices: '',
              jenisspptabel:'',
    
            },
          },
          param:{},
        };
      
      }

      componentDidMount() {
        const { location, getLevels, getClasses, user } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
          const { content } = this.state;
          const paramTypes = ['classes','levels', 'classrooms'];

          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          } else
          if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
            this.handleGetData();
        }

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
            if (name === 'levels_id') {
              this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id: 12 }} );
            } this._onSearchContent()});
          }

          async handleGetData(payload = {}) {
            const { handleListJenisSPPInvoice,  user } = this.props;
            const { units_id, workingUnit, id, user_group, profile } = user;
            let unit_id = units_id;
        
            if (unit_id === null && workingUnit && workingUnit.id) {
              unit_id = workingUnit.id;
            }
        
            // if(user_group.id === 2){
              const res = await handleListJenisSPPInvoice({...payload, organizations_id:12});
        
            this.setState({
              list: res,
            });
            // }
        
          }

          onClickButton() {
            // const filteredList = filter(list, o => (
            //   // console.log(o)
            //   o.code === data.code && data.code_tema === o.code_tema
            // ));
            // console.log(data)
            // console.log(filteredList)
        
            MySwal.fire({
              size: 20000,
              // title: <h3>Keterangan Anggaran</h3>,
              width: '1000px',
              height: '1000px',
              footer: '',
              html: this.renderModalContent(),
            });
          }

      async _onSearchContent(params = {}) {
        const { filters, form } = this.state;
        const { value } = form;
          try {
            const { handleListStudentInvoice } = this.props;
            const result = await handleListStudentInvoice({ ...params, organizations_id: 12, filters, classrooms_id: value.classrooms_id });
            const { classes = [], data = [] } = result;
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    tabelinvoices: result,
                  }
                }
              }));
              
            // }
          } catch (err) {
            // if (this.isMount) {
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    tabelinvoices: [],
                  }
                }
              }));
              
          }
          // }
      }
    
    
      async _getStatusJenisSPP() {
        const { getStatusJenisSPP } = this.props;
        const res = await getStatusJenisSPP();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            status_jenisspp: res,
          },
        }));
       
      }

      async _getStudentOptions(filters={}) {
        const { getStudentOptions } = this.props;
        const res = await getStudentOptions(filters);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            student: res,
          },
        }));
        
      }

      async _getStudentUserIDOptions(filters={}) {
        const { getStudentUserIDOptions } = this.props;
        const res = await getStudentUserIDOptions(filters);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            student_userid: res,
          },
        }));
        
       
      }

      async _getParamOptions(type, filters={}) {
        const { getParamOptions } = this.props;
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics } = this.props;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = 12;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }

      async _onClickSubmit() {
        const { history, handleSaveCreateInvoices } = this.props;
        const { form } = this.state;
        const { value } = form;
        let canSaveA = false;
        const dataTabel = value.tabelinvoices;
        let a;
        for(a=0; a<dataTabel.length; a++){
          if(dataTabel[a].users_id && dataTabel[a].academics_year && dataTabel[a].payments_due_date){
            canSaveA = true;
          } else{
            canSaveA = false;
            break;
          }
        }
      
        if (canSaveA == false){
          alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
        }  else {
          await handleSaveCreateInvoices(value);
          history.push('/dashboard/keuangan/spp/create-invoice');
        }
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/keuangan/spp/create-invoice/generate-invoice');
      }

      _renderButtons() {
        const { user, location } = this.props;
        const { data = {} } = location.state
        const { user_groups_id, user_group } = user;
        const { workflow = [] } = data;
        const lastWorkflow = workflow[workflow.length - 1] || {};
        const firstWorkflow = workflow[0] || {};
        const WorkflowPrev_role = workflow[0]?workflow[0].prev_role:'';
        const { permissions = [] } = user_group;
        
       
          return [
            <div key="button_save">
              <Button
                type="submit"
                title="Save"
              />
            </div>,
            <div key="button_submit">
              <Button
                type="button"
                onClick={this._onSubmitApproval}
                title={(user_group.name == 'Hrd' || user_group.name =='Kepala Sekolah' || user_group.name =='Opsdik'
                        || user_group.name =='Sekretariat') ? 'Submit' : 'Approve'}
              />
            </div>,
          ];
      
      }

      _onClickAdd(fieldName) {
        const { form } = this.state;
        const { tabelinvoices } = form.value;
    
        const {plafond_name} = form.value;
        // const {code_plafond} = form.value;
    
        const {code_plafond} = form.value;
    
        // const {code_class} = form.value;
    
        tabelinvoices.push({
          users_id:'',
          academics_year:'',
          created_at: '',
          payments_due_date: '',
          payments_id:'',
          periode:'',
          total_payments: '',
        });

        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              tabelinvoices,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
      }
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { tabelinvoices } = prevState.form.value;
          const list = prevState.form.value.tabelinvoices;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            tabelinvoices: list
          };
        });
      }


      _onClickAdd1(fieldName) {
        const { form } = this.state;
        const { jenisspptabel } = form.value;
    
        const {plafond_name} = form.value;
        // const {code_plafond} = form.value;
    
        const {code_plafond} = form.value;
    
        // const {code_class} = form.value;
    
        jenisspptabel.push({
          invoices_id:'',
          payments_id:'',
          periode:'',
          nominal:'',
        });

        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              jenisspptabel,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
      }

      _onDeleteList1(idx) {
        this.setState((prevState) => {
          const { jenisspptabel } = prevState.form.value;
          const list = prevState.form.value.jenisspptabel;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            jenisspptabel: list
          };
        });
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        }, () => {
          if (name === 'levels_id') {
            this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: 12 });
          }
          if (name === 'classes') {
            this._getStudentOptions({ filters: { classes_id: value }} );
          }
        });
      }

      renderHeaderModal() {
        const { selected, checkedAll } = this.state;
        return (
          <thead>
            <tr>
                <th  className="manage-budget__row table" style={{ width: "20%" }}>Jenis SPP</th>
                <th  className="manage-budget__row table" style={{ width: "20%" }}>Nominal</th>
                <th  className="manage-budget__row table" style={{ width: "20%" }}>Discount</th>
                <th  className="manage-budget__row table" style={{ width: "20%" }}>Total</th>
            
              </tr>
          </thead>
    
        );
      }

      renderModalContent() {
        const {
          error, onChange,
          fieldName, plafond_name, nis,  rincian, 
          onFormChange
        } = this.props;
        const { list, form, param } = this.state;
    
        const periode = [
          { label: 'Januari', value: '1' },
          { label: 'Februari', value: '2' },
          { label: 'Maret', value: '3' },
          { label: 'April', value: '12' },
          { label: 'Mei', value: '5' },
          { label: 'Juni', value: '6' },
          { label: 'Juli', value: '7'},
          { label: 'Agustus', value: '8' },
          { label: 'September', value: '9' },
          { label: 'Oktober', value: '10' },
          { label: 'November', value: '11' },
          { label: 'Desember', value: '12'},
        ];
        return (
          <div>
            <table className="manage-budget__form-table table">
              {this.renderHeaderModal()}
              {

              map(list, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>
                      {data.name}
                    </td>
                    <td className="nominal">
                    <Input
                        type="number"
                        inputArray
                        arrayPosition={idx}
                        fieldName='jenisspptabel'
                        name='nominal'
                        onChange={onFormChange}
                        value={data.nominal}
                      />
                    </td>
                    
                    <td>
                      <Input
                          type="number"
                          name='dicount'
                          onChange={onFormChange}
                          value={data.discount}
                          inputArray
                          arrayPosition={idx}
                          fieldName='jenisspptabel'
                      />
                    </td>
                 
                    <td className="nominal">
                    
                     
                    </td>
                  </tr>
                  
                ))
    
                
              }
            </table>
          </div>
        );
      }
      
    
      render() {
        const {form, param, filters} = this.state;
        const { value, error } = form;
        const {
          classrooms_id = '',
          date='',
          period='',
          levels_id='',
        } = filters;

        const { classes ={}, location, plafond = {}, levels = {}} = this.props;
        const { tabelinvoices, jenisspptabel } = form.value;
        const { isEdit } = location.state;
        const {
          reconciliation,
          standard,
          tipe,
          type,
          is_posted,
          is_reconciliation
        } = form.value;
        const academics_year = [
          { label: '2019', value: '2019'},
          { label: '2020', value: '2020'},
          { label: '2021', value: '2021'},
        ]
        return (
          <div className="student-list">
               <div className="budget__title">
                  <h1>Create Invoice</h1>
                  <hr></hr>
                </div>
                   <div className="nilai-kognitif__custom-form">
                    
                      <div className="nilai-kognitif__custom-form-row">
                        <div className="nilai-kognitif__custom-form-row__field">
                        <Select
                            type="text"
                            name="levels_id"
                            label="Unit"
                            placeholder="Pilih Unit"
                            data={param.levels}
                            value={form.value.levels_id}
                            onChange={this._onFormChange}
                            error={form.error.levels_id || ''}
                       />
                        </div>
                        <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                            placeholder="Pilih Kelas"
                            label = "Kelas"
                            name="classrooms_id"
                            value={classrooms_id}
                            data={param.classrooms}
                            onChange={this._onChangeFilter}
                      />
       
                      </div>
                      </div>
                     
                    </div>
                    {
            isEdit
          }
         <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(LIST_CREATE_INOVICE_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
          {
              map(tabelinvoices, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map(LIST_CREATE_INOVICE_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{(idx + 1)}</td>
                      );
                    }
                    if (field.attribute === 'status') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}>
                  <Button variant="primary"  onClick={() => this.onClickButton()} icon="icon-plus"/>

                       </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }
                    if (field.attribute === 'academics_year') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}>
                      <Select
                        placeholder="Pilihan"
                        name="academics_year"
                        data={academics_year}
                        data-input-array={true}
                        data-array-position={idx}
                        data-field-name='tabelinvoices'
                        onChange={this._onFormChange}
                        value={list.academics_year}
                      />  </td>
                      );
                    }

                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(list, field)}
                      </td>
                    );
                  })
                  }
                </tr>
              ))
            }
        </tbody>
          </table>
         <div className="add-budget">
            <Button
              type="submit"
              title="SIMPAN"
              onClick={this.onSubmit}
            />
          </div>
          </div>
                      
                     
          </div>
        );
      }
}
ProsesInvoice.propTypes = {
  getParamOptions: PropTypes.func,
  getStudentOptions: PropTypes.func,
  handleGetClasses: PropTypes.func,   
  handleGetStudent: PropTypes.func,
  getStatusJenisSPP: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ProsesInvoice.defaultProps = {
  handleGetClasses: noop,  
  handleGetStudent: noop,
  getStatusJenisSPP: noop,
  getStudentOptions: noop,
   getParamOptions: noop,
};
