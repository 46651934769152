import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../components/base/index';
import { Link } from 'react-router-dom';


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
        <div >
       
        
            {
               <div className="cetak-displiner__content">
                  
              
               <div className="cetak-displiner__body">
                 <table className="cetak-displiner__table">
                   <thead>
                     <tr>
                      <th>No</th>
                      <th>No Barcode/No Induk</th>
                      <th>Judul Buku</th>
                      <th>Pengarang</th>
                      <th>Penerbit/Tahun<br></br>Tanggal Masuk Buku</th>
                      <th>Summary</th>
                      <th>Kelas</th>
                     </tr>
                   </thead>
                   <tbody>
                     {/* {
                     map(journal.details, (list, idx) => (
                       <tr key={`journal_detail_${idx}`}>
                         <td>{idx + 1}</td>
                         <td>{list.code_of_account}</td>
                         <td>{list.parameter_code.title}</td>
                         <td>{list.description}</td>
                         <td className="nominal">{commaFormatted(list.amount)}</td>
                       </tr>
                     ))
                   } */}
                   </tbody>
                   {/* <tfoot>
                     <tr>
                       <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                       <td className="nominal">{commaFormatted(journal.total)}</td>
                     </tr>
                     <tr>
                       <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                       <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                     </tr>
                     <tr>
                       <td colSpan="4" className="nominal">TOTAL NETTO</td>
                       <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                     </tr>
                   </tfoot> */}
                 </table>
               </div>
               {/* <div className="detail-cash-journal__footer">
                 <div className="detail-cash-journal__footer-bottom">
                   <div>
                     <p>Diserahkan oleh:</p>
                     <p>{journal.submitted_by}</p>
                   </div>
                   <div>
                     <p>Diterima oleh:</p>
                     <p>{journal.accepted_by}</p>
                   </div>
                   <div>
                     <p>Dibukukan oleh:</p>
                     <p>{journal.booked_by}</p>
                   </div>
                   <div>
                     <p>Diketahui oleh:</p>
                     <p>Name 4</p>
                   </div>
                 </div>
               </div> */}
             </div>
                

                
              
            }
          
        </div>
  );
     }
}
ComponentToPrint.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ComponentToPrint.defaultProps = {
  onFormChange: noop,
};
