import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEqual, isEmpty } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import { formatData } from '../../../../utils/transformer.util';
import language from '../../../../languages';
import { FINANCE_BUDGET_TABLE_FIELDS } from '../../../../constants/finance/budgeting/budget.constant';

export default class Budget extends Component {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickDetail = this.onClickDetail.bind(this);
    this.onClickDetail2 = this.onClickDetail2.bind(this);
    this.onClickDetail3 = this.onClickDetail3.bind(this);
    this.onClickDetail4 = this.onClickDetail4.bind(this);
    this.onClickDetail5 = this.onClickDetail5.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.getData = this.getData.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      list: [],
      listAmount: 5,
    };
    // console.log(this.state.list) di sini isi awalnya kosong
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.getData();
    }
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-anggaran');
  }

  onClickEdit(val){
    const { history } = this.props;
    // console.log(val)
    history.push('/dashboard/keuangan/penganggaran/edit-anggaran', { data: val });
  }

  onClickDetail(val) {
    const { history, user } = this.props;
    const { unique_id, workflow } = val;
    const { user_group } = user;
    let lastWorkflow;
    let nextRole = 'Keuangan Sekolah';
    let nextRole2 = 'Keuangan Pusat';

    if(workflow.length > 0) {
      lastWorkflow = workflow[workflow.length -1];
      nextRole = lastWorkflow.next_role;
      nextRole2 = lastWorkflow.next_role;
    }
    // console.log(lastWorkflow.next_role) 

    if((user_group.name === 'Keuangan Sekolah'
      || user_group.name === 'Bendahara Perwakilan'
      || user_group.id > 12
      || user_group.name === 'Keuangan Pusat'
      || user_group.name === 'Kepala Sekolah')) {
        if(lastWorkflow && user_group.name !== nextRole||  user_group.name !== 'Keuangan Pusat' ) {
          // if(lastWorkflow && user_group.name !== nextRole) {

          // if (!lastWorkflow.is_done && user_group && user_group.name === lastWorkflow.next_role || 
          //   !lastWorkflow.is_done && user_group && user_group.name === "Keuangan Pusat" && lastWorkflow.next_role === "Keuangan Sekolah")
          // console.log(lastWorkflow && user_group.name !== nextRole)
        history.push('/dashboard/keuangan/penganggaran/rapbu', {
          code: '',
          type: 'RAPBU',
          account_unique_id: '',
          head_unique_id: unique_id,
        });
        history.go(0)
      }  
      else { 
        history.push('/dashboard/keuangan/penganggaran/tipe-anggaran', { data: val });
        history.go(0)
      }
    } else {
      history.push('/dashboard/keuangan/penganggaran/rapbu', {
        type: 'RAPBU',
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
      });
      history.go(0)
    }

  }

  onClickDetail2(val) {
    const { history, user } = this.props;
    const { unique_id, workflow } = val;
    const { user_group } = user;
    let lastWorkflow;
    let nextRole = 'Keuangan Sekolah';
    let nextRole2 = 'Keuangan Pusat';

    if(workflow.length > 0) {
      lastWorkflow = workflow[workflow.length -1];
      nextRole = lastWorkflow.next_role;
      nextRole2 = lastWorkflow.next_role;
    }

    if((user_group.name === 'Keuangan Sekolah'
      || user_group.name === 'Bendahara Perwakilan'
      || user_group.name === 'Keuangan Pusat' 
      || user_group.id > 12
      || user_group.name === 'Kepala Sekolah')) {
        if(lastWorkflow && user_group.name !== nextRole||  user_group.name === 'Keuangan Pusat' || user_group.id > 12 || user_group.name === 'Keuangan Sekolah'
        || user_group.name === 'Bendahara Perwakilan' ||  user_group.name === 'Kepala Sekolah') {
          // if(lastWorkflow && user_group.name !== nextRole) {
          history.push('/dashboard/keuangan/penganggaran/tipe-anggaran', { data: val });
          
      }  
      else { 
        

        history.push('/dashboard/keuangan/penganggaran/rapbu', {
          code: '',
          type: 'RAPBU',
          account_unique_id: '',
          head_unique_id: unique_id,
        });
        history.go(0)
      }
    } else {
      history.push('/dashboard/keuangan/penganggaran/rapbu', {
        type: 'RAPBU',
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
      });
      history.go(0)
    }
  }

  onClickDetail3(val) {
    const { history, user } = this.props;
    const { unique_id, workflow } = val;
    const { user_group } = user;
    let lastWorkflow;
    let nextRole = 'Keuangan Sekolah';
    let nextRole2 = 'Keuangan Pusat';

    if(workflow.length > 0) {
      lastWorkflow = workflow[workflow.length -1];
      nextRole = lastWorkflow.next_role;
      nextRole2 = lastWorkflow.next_role;
    }

    if((user_group.name === 'Keuangan Sekolah'
      || user_group.name === 'Bendahara Perwakilan'
      || user_group.name === 'Keuangan Pusat'
      || user_group.name === 'Kepala Sekolah'
      ||  user_group.id > 12
    

      )) {
        if(lastWorkflow && user_group.name === nextRole||  user_group.name === 'Keuangan Pusat' ) {
     
          history.push('/dashboard/keuangan/penganggaran/tipe-anggaran', { data: val });
          // history.go(0)
          // console.log(user_group.id)
      } 
      
      
      else { 
        

        history.push('/dashboard/keuangan/penganggaran/rapbu', {
          code: '',
          type: 'RAPBU',
          account_unique_id: '',
          head_unique_id: unique_id,
        });
        history.go(0)
      }
    } else {
      history.push('/dashboard/keuangan/penganggaran/rapbu', {
        type: 'RAPBU',
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
      });
      history.go(0)
    }
  }

  onClickDetail4(val) {
    const { history, user } = this.props;
    const { unique_id, workflow } = val;
    const { user_group } = user;
    let lastWorkflow;
    let nextRole = 'Keuangan Sekolah';
    let nextRole2 = 'Keuangan Pusat';

    if(workflow.length > 0) {
      lastWorkflow = workflow[workflow.length -1];
      nextRole = lastWorkflow.next_role;
      nextRole2 = lastWorkflow.next_role;
    }

    if((user_group.name === 'Keuangan Sekolah'|| user_group.name === 'Keuangan Pusat'
      || user_group.name === 'Kepala Sekolah')) {
      
    history.push('/dashboard/keuangan/penganggaran/rapbu', {
      type: 'RAPBU',
      code: '',
      account_unique_id: '',
      head_unique_id: unique_id,
    });
}
    
    } 
    

  onClickDetail5(val) {
    const { history, user } = this.props;
    const { unique_id, workflow } = val;
    const { user_group } = user;
    let lastWorkflow;
    let nextRole = 'Keuangan Sekolah';


    if((user_group.name === 'Keuangan Sekolah'|| user_group.name === 'Keuangan Pusat'
      || user_group.id > 12)) {
       
      history.push('/dashboard/keuangan/penganggaran/rapby', {
        type: 'RAPBY',
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
      });
    }
  }


  onClickView(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/apbu', {
      account_unique_id: val.account.unique_id,
      head_unique_id: val.unique_id,
    });
  }

  async onClickDelete(val) {
    const { handleDeleteBudget } = this.props;

    const res = await handleDeleteBudget({
      head_unique_id: val.unique_id,
    });

    if (res) {
      this.getData();
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.getData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async getData(payload = {}) {
    const { handleGetBudget, user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    let unit_id = prm_school_units_id;

    if (workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // console.log(user)
    const res = await handleGetBudget({
      ...payload,
      unit_id,
    });

    // console.log(unit_id)

    this.setState({
      list: res,
    });

  }

  renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { workflow } = data;
    const lastWorkflow = workflow[workflow.length -1];
    // console.log(user_group.name)
    // console.log(data.prm_school_units_id);
    if (!lastWorkflow || lastWorkflow === null) {
      return (
        <td>
          <div className="table__actions">
          {(user_group.name === 'Keuangan Sekolah'&& data.prm_school_units_id < 9 || user_group.name === 'Bendahara Perwakilan' || user_group.name === 'Keuangan Pusat' || user_group.name === 'Admin' || user_group.name === 'Super Admin')
              && (
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
            )
          }
 
          {(user_group.name !== 'Keuangan Pusat')
              && ( 
                <Button
                onClick={() => this.onClickDetail2(data)}
                title="Detail"

              />
            )
          }

        {( user_group.name === 'Keuangan Pusat')
              && (
                <Button
                onClick={() => this.onClickDetail3(data)}
                title="Detail"

              />
            )
          }



          {(user_group.name === 'Keuangan Sekolah' && data.prm_school_units_id < 9 || user_group.name === 'Bendahara Perwakilan' || user_group.name === 'Keuangan Pusat' || user_group.name === 'Admin' || user_group.name === 'Super Admin')
              && (
              <Button
                onClick={() => this.onClickDelete(data)}
                title="Hapus"
              />
              )
            }
          </div>
        </td>
      );
    }
    // console.log(lastWorkflow)
    // console.log(lastWorkflow.prev_role)
    // console.log(user_group.name)
    // console.log(lastWorkflow.next_role) 
      if (!lastWorkflow.is_done && user_group && user_group.name === lastWorkflow.next_role || 
        
        !lastWorkflow.is_done && user_group && user_group.name === "Keuangan Pusat" && lastWorkflow.next_role === "Keuangan Sekolah") {
        return (
          <td>
            <div className="table__actions">
            {(user_group.name === 'Keuangan Sekolah'&& data.prm_school_units_id < 9 || user_group.name === 'Bendahara Perwakilan' 
            || user_group.name === 'Keuangan Pusat' || user_group.name === 'Admin' || user_group.name === 'Super Admin')
                && (
              <Button
                onClick={() => this.onClickEdit(data)}
                title="Edit"
              />
              )
            }
              <Button
                onClick={() => this.onClickDetail2(data)}
                title="Detail"
              />
            </div>
          </td>
        );
    } 


    if (lastWorkflow.is_done) { 
      // ini yg di setujui ini approve

      // console.log(lastWorkflow.is_done) 
      // console.log(lastWorkflow.prev_role) 
      // console.log(lastWorkflow.next_role) 
      // console.log(data.prm_school_units_id) 
      // console.log(data)
      return (
     
        <td>
          <div className="table__actions">
            {(   user_group.id > 12 || user_group.name === 'Keuangan Pusat' && data.prm_school_units_id > 9 ||  user_group.name === 'Keuangan Sekolah' && data.prm_school_units_id > 9 )
            &&(<Button
              onClick={() => this.onClickDetail5(data)}
              title="Lihat RAPBY" 

            />)}

        {(    user_group.name === 'Kepala Sekolah' || user_group.name === 'Keuangan Pusat' && data.prm_school_units_id < 9  ||  user_group.name === 'Keuangan Sekolah' && data.prm_school_units_id < 9 ) 
            &&(<Button
              onClick={() => this.onClickDetail4(data)}
              title="Lihat RAPBS" 

            />)}
          </div>
        </td>
      );
    } 
   
    else{
      // console.log(lastWorkflow) 
      return (
        <td>
          <div className="table__actions">
          {( 
        //     lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role=== "Hrd"  
        //  || lastWorkflow.next_role ==="Hrd" || lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role=== "Pengadaan"  
        //  || lastWorkflow.next_role ==="Pengadaan" || lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role=== "Litbang"  
        //  || lastWorkflow.next_role ==="Litbang" || lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role=== "IT"  
        //  || lastWorkflow.next_role ==="IT" || lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role=== "Opsdik"  
        //  || lastWorkflow.next_role ==="Opsdik" || lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role=== "Sekretariat"  
        //  || lastWorkflow.next_role ==="Sekretariat" || user_group.id > 12 || user_group.name === 'Keuangan Pusat' && data.prm_school_units_id > 9 || user_group.name === 'Keuangan Sekolah' && data.prm_school_units_id > 9
        user_group.id >12 || user_group.name === 'Keuangan Pusat' && data.prm_school_units_id > 9 || user_group.name === 'Keuangan Sekolah' && data.prm_school_units_id > 9
        || lastWorkflow.next_role ==="Keuangan Sekolah"  && lastWorkflow.prev_role === "Keuangan Pusat"
        )
                && (
              <Button
              onClick={() => this.onClickDetail5(data)}
              title="Lihat RAPBY"
              />
              )
              
            } 
            
           

          {(user_group.id<13 && data.prm_school_units_id < 9 || user_group.name === 'Keuangan Pusat' && lastWorkflow.prev_role === "Keuangan Sekolah" && lastWorkflow.next_role === "Kepala Sekolah")
          // ( user_group.name !== 'Hrd' && user_group.name !== 'Keuangan Pusat' || user_group.name !== 'Pengadaan' && user_group.name !== 'Keuangan Pusat'
          // || user_group.name !== 'Litbang' && user_group.name !== 'Keuangan Pusat'  || user_group.name !== 'IT' && user_group.name !== 'Keuangan Pusat'
          // || user_group.name !== 'Opsdik' && user_group.name !== 'Keuangan Pusat' || user_group.name !== 'Sekretariat' && user_group.name !== 'Keuangan Pusat') 
                && (
                  <Button
                  onClick={() => this.onClickDetail(data)}
                  title="Lihat RAPBS"
                  
                />
              )
            }

          </div>
        </td>
      );
    }
  }

  render() {
    const { list } = this.state;
    const { user } = this.props;
    const { user_group, workingUnit = {}, school_unit = {} } = user;
    const activeUnit =
      Object.values(workingUnit).length > 0 ? workingUnit : school_unit;
    const { id: activeUnitId } = activeUnit;
  // console.log(activeUnitId)
    return (
      <div className="budget">
        { (user_group.name === 'Keuangan Sekolah'
          || user_group.name === 'Admin'
          || user_group.name === 'Keuangan Pusat') &&
          (<Button
            title="Tambah Anggaran"
            onClick={this.onClickAdd}
          />)
        }
        <div className="budget__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(FINANCE_BUDGET_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))
                  }
              </tr>
            </thead>
            <tbody>
            
              {
              // (school_unit.id === 14 && activeUnitId != 14 && school_unit.id === 14 && activeUnitId != 1 || school_unit.id === 2 && activeUnitId != 14 && school_unit.id === 2 && activeUnitId != 2 )?
              // '':
                  map(list.result, (data, idx) => (
                    
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(FINANCE_BUDGET_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      }
                      )
                    }
                      {this.renderButtons(data)}
                    </tr>
                  ))
                  
                }
                
                { isEmpty(list.result) && (
                    <tr>
                      <td colSpan="4">
                        <i>Tidak ada data ditemukan</i>
                      </td>
                    </tr>
                  )
                
                }
                 {/* { console.log(list.result)} */}
               
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
Budget.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
Budget.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};