import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import JadwalSiswaForm from './components/JadwalSiswaForm.component';
import IsiSiswaTable from './components/IsiSiswaTable.component';

export default class TambahJadwalKaryawan extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._getParamOptions = this._getParamOptions.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this.state= {
          form: {
            value: {
                // levels: 1,
                siswa: [
                    {
                      Matpel: '',
                      Kelas:'',
                       //divisi: '',
                      nominal: 0,
                    }                                      
                  ],
            },
            error: {
                siswa: '',
            },
          },
          param: {},
        };
      }
    
      componentDidMount() {
        console.log(this.props)
        const { location } = this.props;
        const { state = {} } = location;
        const { id } = state.data || '';
        const paramTypes = ['units', 'golongan', 'jabatan', 'teachers', 'subjects'];
        paramTypes.forEach((type) => {
          this._getParamOptions(type);
        });
        if (id) {
          this.setState({id:id})
          this._handleLoadData(id)
        }
      }
      componentDidUpdate(prevProps) {
        const { user } = this.props;
        const { workingUnit } = user;
        const { user: prevUser } = prevProps;
        const { workingUnit: prevWorkingUnit } = prevUser;
    
        if (!isEqual(workingUnit, prevWorkingUnit)) {
          this.handleGetData();
        }
      }
      async _handleLoadData(id) {
        const { getData } = this.props;
        console.log(this.props.location.state.data)
        this.setState({
          form:{
            value : this.props.location.state.data,
            error : []
          }
        }, () => console.log(this.state));
      }
      getData = async(id) => {
        const { handlelistScheduleLecturer } = this.props;
        const result = await handlelistScheduleLecturer(id);
        this.setState({
          form:{
            value: result[id],
            error: []
          }
        });
      }
      _onClickAdd(data) {
        const { form } = this.state;
        const { siswa } = form.value;
        
        siswa.push({
          Kelas: '',
          Matpel:'',
          // divisi: '',
          nominal: 0,
          //period:'',
        });
        
        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              siswa,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
        console.log(siswa)
      }

      async _getParamOptions(type, filters={}) {
        const { getParamOptions } = this.props;
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
    
      async onSubmit(){
        console.log(this.state)
        const { history, handlesaveScheduleLecturer } = this.props;
        const { form } = this.state;
        const { value } = form;
        const res = await handlesaveScheduleLecturer(value);
        history.push('/dashboard/kurikulum/jadwal/jadwal-karyawan');
      }
      async onEdit() {
        const { handleeditScheduleLecturer,history } = this.props;
        const { form } = this.state;
        const { value } = form;
        const res = await handleeditScheduleLecturer(value);
        history.push('/dashboard/kurikulum/jadwal/jadwal-karyawan');
      }
      _onClickSubmit() {
        const { history } = this.props;
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-k13');
        //history.push('/dashboard/kurikulum/laporan/analisa-nilai-siswa');
        //history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { siswa } = prevState.form.value;
          const list = prevState.form.value.siswa;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            siswa: list
          };
        });
      }
    
      render() {
        const {form, param} = this.state;
        const { siswa } = form.value;
        const { handleGetKelas} = this.props;
        // console.log(this.props.location.state.id);
        // console.log(this.props.location.id);
        // console.log(this.props.id);
        return (
          <div className="student-list">
             <form>
             <h1>Jadwal Karyawan</h1>
              <JadwalSiswaForm
              form={form}
              onChange={this._onFormChange}
              param={param}
              />
              <br></br>
                <IsiSiswaTable
                list={siswa}
                onAddList={this._onClickAdd}
                onChange={this._onFormChange}
                onDeleteList={this._onDeleteList}
                />     
                <div className="manage-registration__footer">
                  {this.state.id?
                    <Button
                      type="button"
                      title="Update"
                      onClick={this.onEdit}
                    />:
                    <Button
                      type="button"
                      title="Simpan"
                      onClick={this.onSubmit}
                    />
                  }
                </div>
              </form>
          </div>
        );
      }
}
TambahJadwalKaryawan.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahJadwalKaryawan.defaultProps = {
  getParamOptions: noop,  
  handleGetStudent: noop,
};
