import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { Link } from 'react-router-dom';
import { formatData } from '../../../utils/transformer.util';
import language from '../../../languages';
import { Pagination, Button } from '../../../components/base';
import { ABSEN_REKAP_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class AbsensiRekapTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: initialContent,
      listAmount: 10,
      list: [],
    };
    this.onChangePage = this.onChangePage.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  
  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }
  // _onFormChange(event) {
  //   const { onChange } = this.props;
  //   onChange(event);
  // }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  render() {
    
    const {
      listAmount,
      content, list = {}
    } = this.state;
    const {
      paging,
      currentPage,
      totalPage,
      tableFields,
      onClickEdit,
      onClickView,
      showActions,
      customThead,
      customAction,
      showView,
      showEdit,
      showDelete,
      onChange,
      form,
    } = this.props;
    const loadingProps = { show: content.loading };
    return (
      <div className="fund-request">

        
        
        
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(ABSEN_REKAP_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(ABSEN_REKAP_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
}
}
AbsensiRekapTable.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  paging: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
};
AbsensiRekapTable.defaultProps = {
  onChange: noop,
  paging: true,
  currentPage: 1,
  totalPage: 1,
};
