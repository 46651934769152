import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Input } from '../../components/base';


export default class ManagePassword extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      form: {
        value: {
          old_pwd: '',
          new_pwd: '',
          new_pwd_confirm: '',
        }
      },
      error: {}
    };
  }

  onChange(event) {
    const { target } = event;
    const { value, name } = target;
    const { form, error } = this.state;
    const { new_pwd, new_pwd_confirm } = form.value;
    let isValid = true;
    let newError = { ...error };

    if(name === 'new_pwd') {
      isValid = this.validatePasswordLength(value);
      if(isValid === true) {
        delete newError[name];
        if(new_pwd_confirm > 0) {
          isValid = this.validatePasswordMatch(value, new_pwd_confirm);
          if(isValid !== true) {
            newError.new_pwd_confirm = isValid;
          } else {
            delete newError.new_pwd_confirm
          }
        }
      } else {
        newError[name] = isValid;
      }
    } else if(name === 'new_pwd_confirm') {
      isValid = this.validatePasswordMatch(new_pwd, value);
      if(isValid !== true) {
        newError[name] = isValid;
      } else {
        delete newError[name];
      }
    }

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value
        }
      },
      error: { ...newError }
    }));
  }

  validatePasswordMatch(new_pwd, new_pwd_confirm) {
    if(new_pwd.toString() === new_pwd_confirm.toString()) {
      return true;
    }
    return 'Password tidak sesuai';
  }

  validatePasswordLength(new_pwd) {
    if(new_pwd.length >= 8 && new_pwd.match("^(?=.*[a-z])") && new_pwd.match("^(?=.*[A-Z])")
    && new_pwd.match("^(?=.*[!@#])") && new_pwd.match("^(?=.*[0-9])")) {
      return true;
    }
    return <div>
    <p>Password Minimal 8 karakter, harus ada huruf kapital, huruf kecil, angka dan 1 spesial karakter (!, @, #)</p>
    {new_pwd.match("^(?=.*[a-z])")?'':<p>✖ Huruf <b>Kecil</b></p>}
    {new_pwd.match("^(?=.*[A-Z])")?'':<p >✖ Huruf <b>Kapital</b></p>}
    {new_pwd.match("^(?=.*[!@#])")?'':<p >✖ Spesial Karakter <b>!, @, #</b></p>}
    {new_pwd.match("^(?=.*[0-9])")?'':<p >✖ <b>Angka</b></p>}
    {new_pwd.length >= 8?'':<p>✖ Panjang minimal password harus <b>8 karakter</b></p>}
    </div>
  }

  onSubmit(event) {
    const { history, handleChangePassword } = this.props;
    const { error, form } = this.state;
   
    event.preventDefault();
    if(Object.keys(error).length === 0) {;
      handleChangePassword(form.value, () => {
        history.push('/login');
      });
    }
  }
  render() {
    const { form, error } = this.state;
    return (
      <div className="manage-password">
        <h1>Ganti Kata Sandi</h1>
        <form>
          <Input
            type="password"
            label="Kata Sandi Lama"
            name="old_pwd"
            value={form.value.old_pwd}
            onChange={this.onChange}
          />
          <Input
            type="password"
            label="Kata Sandi Baru"
            name="new_pwd"
            value={form.value.new_pwd}
            error={error ? error.new_pwd : null}
            title="Password Minimal 8 karakter, harus ada huruf kapital, huruf kecil, angka dan 1 spesial karakter (!, @, #)" 
            onChange={this.onChange}
          />
          <Input
            type="password"
            label="Ulangi Kata Sandi Baru"
            name="new_pwd_confirm"
            value={form.value.new_pwd_confirm}
            error={error ? error.new_pwd_confirm : null}
            onChange={this.onChange}
          />
          <Button
            type="submit"
            title="Submit"
            onClick={this.onSubmit}
          />
          



        </form>
      </div>
    )
  }
}

ManagePassword.propTypes = {
  handleChangePassword: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManagePassword.defaultProps = {
  handleGetBudgetDetail: noop,
  user: null,
  history: null,
};
