import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      
     <div >
       
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="divPrint" style={{width:800, border: '0px solid black'}}>
       
                <div className="identitas-ktsp__content">
                  <div className="identitas-ktsp__header">
                    <div className="identitas-ktsp__custom-form-row-square">
                      <div className="identitas-ktsp__box--square2">
                        Image
                      </div>   
                      <div className="identitas-ktsp__header-title">
                        <div className="identitas-ktsp__header-title--column">
                          {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                          IDENTITAS SISWA
                        </div>
                        <div className="identitas-ktsp__header-title--column">
                          {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                          Nomor ID Raport : 20 2 280311 008 - 7980
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
                     
                <table className="identitas-ktsp__table">
                  <tr>
                      <td></td>
                      <td>1. Nama Siswa</td>
                      <td>: Gange</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>2. No. Induk Siswa</td>
                      <td>: 0210</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>3. Tempat dan Tanggal Lahir</td>
                      <td>: SMP KATOLIK RICCI</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>4. Jenis Kelamin</td>
                      <td>: Laki-laki</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>5. Agama</td>
                      <td>: </td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>6. Status Anak</td>
                      <td>: Anak Ke dan 1 bersaudara</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>7. Status Dalam Keluarga</td>
                      <td>: Anak Kandung</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>8. Alamat Siswa</td>
                      <td>: JL APA AJA</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp;&nbsp;Telepon</td>
                      <td>:-</td>
                  </tr>

                  <tr>
                      <td></td>
                      <td>9. Diterima disekolah ini</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; a. Dikelas</td>
                      <td>:VII</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; b. Semester</td>
                      <td>:1</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; c. Tanggal</td>
                      <td>:18-11-2019</td>
                  </tr>

                  <tr>
                      <td></td>
                      <td>10. Sekolah Asal</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; a. Nama Sekolah</td>
                      <td>:VII</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; b. Alamat</td>
                      <td>:1</td>
                  </tr>

                  <tr>
                      <td></td>
                      <td>11. Surat Tanda Lulus (STL)</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; a. Tahun</td>
                      <td>:VII</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; b. Nomor</td>
                      <td>:1</td>
                  </tr>
                  

                  <tr>
                      <td></td>
                      <td>12. Orang Tua</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; a. Nama Ayah</td>
                      <td>:VII</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; b. Nama Ibu</td>
                      <td>:1</td>
                  </tr>

                  <tr>
                      <td></td>
                      <td>13. Alamat Orang Tua</td>
                      <td>: </td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; Telepon</td>
                      <td>:VII</td>
                  </tr>

                  <tr>
                      <td></td>
                      <td>14. Pekerjaan Orangtua</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; a. Ayah</td>
                      <td>:VII</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; b. Ibu</td>
                      <td>:1</td>
                  </tr>
                  
                  <tr>
                      <td></td>
                      <td>15. Nama Wali</td>
                      <td>: </td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>16. Alamat Wali</td>
                      <td>: </td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>&nbsp; Telepon</td>
                      <td>:VII</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>17. Pekerjaan Wali</td>
                      <td>: </td>
                  </tr>
              </table>
                    
              <div className="identitas-ktsp__custom-form-row-square2"> 
                <div className="identitas-ktsp__box2--square2">
                  Image
                </div>   
                <div className="identitas-ktsp__footer-title">
                  <div className="identitas-ktsp__footer-title--column">
                    {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                    Jakarta,
                  </div>
                  <div className="identitas-ktsp__footer-title--column">
                    {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                   Kepala SMP
                  </div>
                  <div className="identitas-ktsp__footer-title--column2">
                  {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                  (M. SUWARNO, S.Pd.,MM)
                  </div>
                </div>
              </div>
     
        </div>
      </div>
    );
  }

}
export default class IdentitasPortraitKTSP extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
IdentitasPortraitKTSP.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  IdentitasPortraitKTSP.defaultProps = {
    handleGetCashJournal: noop,
  };
  