import { connect } from 'react-redux';
import PermohonanPindahUnit from '../../../views/Akademi/DataKelas/PermohonanPindahUnit.view';
import { getCodeOfAccount } from '../../../states/thunks/common.thunk';
import { getUnitsOptions, getParamOptionsAcademics, getNewStudentOptions } from '../../../states/thunks/options.thunk';
import { listPermohonanPindahUnitSiswa, savePindahUnits, getClassDiv, getClassData, rejectSwitchUnit, approveSwitchUnit } from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUnitsOptions: (payload) => dispatch(getUnitsOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetClassDiv: payload => dispatch(getClassDiv(payload)),
    handleGetClassData: payload => dispatch(getClassData(payload)),
    handlelistPermohonanPindahUnitSiswa: (payload, goback) => dispatch(listPermohonanPindahUnitSiswa(payload, goback)),
    handlesavePindahUnits: (payload, goback) => dispatch(savePindahUnits(payload, goback)),
    getNewStudentOptions: (payload) => dispatch(getNewStudentOptions(payload)),
    handleRejectSwitchUnit: (payload, goback) => dispatch(rejectSwitchUnit(payload, goback)),
    handleApproveSwitchUnit: (payload, goback) => dispatch(approveSwitchUnit(payload, goback)),
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermohonanPindahUnit);
