import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { Link } from 'react-router-dom';

export default function BuatKodeMatpelTable(props) {
  const { onFormChange, form } = props;
  return (
    <div className="daftar-ekskul__content">
                <div className="daftar-ekskul__header">
                  <p>Table Range Angka</p><p style={{color:'red'}}>(KURIKULUM 2013)</p>

                </div>
                <div className="daftar-ekskul__body">
                  <table className="daftar-ekskul__table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Range Awal</th>
                        <th>Range Akhir</th>
                        <th>Deskripsi 1</th>
                        <th>Deskripsi 2</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>A</td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /> </td>
                        <td> <Input
                                name="namaSaudara1"
                                onChange={onFormChange}
                            /></td>
                      
                    </tr>

                      <tr>
                        <td>B</td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /> </td>
                        <td> <Input
                                name="namaSaudara1"
                                onChange={onFormChange}
                            /></td>
                      
                    </tr>

                    <tr>
                        <td>C</td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /> </td>
                        <td> <Input
                                name="namaSaudara1"
                                onChange={onFormChange}
                            /></td>
                      
                    </tr>

                    <tr>
                        <td>D</td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /> </td>
                        <td> <Input
                                name="namaSaudara1"
                                onChange={onFormChange}
                            /></td>
                      
                    </tr>

                    <tr>
                        <td>E</td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /></td>
                        <td> <Input
                              name="namaSaudara1"
                              onChange={onFormChange}
                            
                            /> </td>
                        <td> <Input
                                name="namaSaudara1"
                                onChange={onFormChange}
                            /></td>
                      
                    </tr>
                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
BuatKodeMatpelTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatKodeMatpelTable.defaultProps = {
  onFormChange: noop,
};
