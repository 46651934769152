import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      
     <div >
       
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="divPrint" style={{width:800, border: '0px solid black', size: 'landscape'}}>
        <div>
            {
                <div className="cetak-8355__content">
                  <div className="cetak-8355__header">
                      <div className="cetak-8355__header-title">
                      <div className="cetak-8355__header-title--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        <p>DAFTAR NAMA PESERTA DIDIKSEKOLAH MENENGAH PERTAMA NEGERI/SWASTA</p>
                        <p>DINAS PENDIDIKAN MENENGAH DAN TINGGI PROPINSI DAERAH KHUSUS IBUKOTA JAKARTA</p>
                        <p>Tahun Ajaran: 2018-2019</p>
                        
                      </div>
                      </div>
                      <div className="cetak-8355__header-title2">
                      <div className="cetak-8355__header-title2--left">

                      <p>Nama Sekolah    : SMP Katolik Ricci</p>
                      <p>Alamat Sekolah  :</p>
                      <p>Kotamadya       :</p>
                      </div>
                     
                      
                      <div className="cetak-8355__header-title2--right">
                        
                      <p>No. NSS    : SMP Katolik Ricci</p>
                      <p>Lampiran   :</p>
                      <p>Kelas      :</p>
                      </div>
                      </div>
                    
  
                  </div>
                  <div className="cetak-8355__body">
                    <table className="cetak-8355__table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>NIS/NISN</th>
                          <th>Nama Siswa</th>
                          <th>L/P</th>
                          <th>Tempat & Tanggal Lahir</th>
                          <th>Agama</th>
                          <th>Nama Orang Tua/Wali</th>
                          <th>Pekerjaan</th>
                          <th>Telepon</th>
                          <th>STTB/STL/Thn</th>

                        </tr>
                      </thead>
                      <tbody>
                      <tr >
                            <td>1</td>
                            <td>32012333</td>
                            <td>AJODIYA SANGUN</td>
                            <td>L</td>
                            <td>YOGYAKARTA</td>
                            <td>RUDI SYAH</td>
                            <td>KARYAWAN</td>
                            <td>JALAN PRAMUKA</td>
                            <td>08199990000</td>
                            <td>-</td>
                          </tr>
                      </tbody>
                      {/* <tbody>
                        {
                        map(journal.details, (list, idx) => (
                          <tr key={`journal_detail_${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{list.code_of_account}</td>
                            <td>{list.parameter_code.title}</td>
                            <td>{list.description}</td>
                            <td className="nominal">{commaFormatted(list.amount)}</td>
                          </tr>
                        ))
                      }
                      </tbody> */}
                      {/* <tfoot>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                          <td className="nominal">{commaFormatted(journal.total)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                          <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL NETTO</td>
                          <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                  {/* <div className="cetak-8355__footer">
                    <div className="cetak-8355__footer-bottom">
                      <div>
                        <p>Diserahkan oleh:</p>
                        <p>{journal.submitted_by}</p>
                      </div>
                      <div>
                        <p>Diterima oleh:</p>
                        <p>{journal.accepted_by}</p>
                      </div>
                      <div>
                        <p>Dibukukan oleh:</p>
                        <p>{journal.booked_by}</p>
                      </div>
                      <div>
                        <p>Diketahui oleh:</p>
                        <p>Name 4</p>
                      </div>
                    </div>
                  </div> */}
                </div>
             
            }
          </div>
      </div>
      </div>
    );
  }

}
export default class CetakLaporan8355 extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                        <h1>Laporan 8355</h1>
                          <hr></hr>
                          <div className="report__content2">
                            <Select
                            name="kelas"
                            onChange={this._onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Kelas 7A'}
                            />  
                            </div>
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
  CetakLaporan8355.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  CetakLaporan8355.defaultProps = {
    handleGetCashJournal: noop,
  };
  