import { connect } from 'react-redux';
import AdjustmentJournal from '../../../../views/Finance/Bookkeeping/AdjustmentJournal/AdjustmentJournal.bookkeeping.view';
import { getAdjustmentJournal, postAdjustmentJournal, postJournalUnposting } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
    handlePostJournalPosting: payload => dispatch(postAdjustmentJournal(payload)),
    handlePostJournalUnposting: payload => dispatch(postJournalUnposting(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentJournal);
