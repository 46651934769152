import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Button, Select } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { SEMESTER } from '../../../../../variables/common.variable';
import { sumExpectedSemesterAmount } from '../../../../../utils/transformer.util';

export default class RapbuRow extends Component {
  constructor(props) {
    super(props);
    this._onDeleteRow = this._onDeleteRow.bind(this);
  }

  _onDeleteRow() {
    const { idx, onDeleteRow } = this.props;
    onDeleteRow(idx);
  }

  render() {
    const {
      idx,
      onChange,
      data,
      error,
      coa,
    } = this.props;
    return (
      <tr>
        <td>
          {idx + 1}
        </td>
        <td>
          <SelectCoa
            noMargin
            inputArray
            arrayPosition={idx}
            name="code"
            coa={coa}
            onClick={onChange}
            value={data.code || {}}
            error={error.code || ''}
            placeholder="Pilih Kode"
            rightIcon="icon-search"
          />
        </td>
        <td>
          <Input
            noMargin
            data-input-rapbu
            data-array-position={idx}
            name="title"
            onChange={onChange}
            value={data.title || ''}
            error={error.title || ''}
          />
        </td>
        <td>
          <Select
            noMargin
            data-input-rapbu
            data-array-position={idx}
            name="semester"
            placeholder="Pilih Semester"
            data={SEMESTER}
            onChange={onChange}
            value={data.semester || ''}
            error={error.semester || ''}
          />
        </td>
        <td>
          <Input
            noMargin
            data-input-rapbu
            data-array-position={idx}
            data-input-type="number"
            name="quantity"
            onChange={onChange}
            value={data.quantity || ''}
            error={error.quantity || ''}
          />
        </td>
        <td>
          <Input
            noMargin
            data-input-rapbu
            data-array-position={idx}
            data-input-type="number"
            name="price"
            onChange={onChange}
            value={data.price || ''}
            error={error.price || ''}
          />
        </td>
        <td>
          <Input
            noMargin
            data-input-rapbu
            data-array-position={idx}
            data-input-type="number"
            name="term"
            onChange={onChange}
            value={data.term || ''}
            error={error.term || ''}
          />
        </td>
        <td>
          <Input
            noMargin
            data-input-rapbu
            data-array-position={idx}
            data-input-type="number"
            name="amount"
            edit={false}
            onChange={onChange}
            value={sumExpectedSemesterAmount(data) || ''}
          />
        </td>
        <td>
          <Input
            noMargin
            data-input-rapbu
            data-array-position={idx}
            name="desc"
            onChange={onChange}
            value={data.desc || ''}
            error={error.desc || ''}
          />
        </td>
        <td>
          <Button
            icon="icon-trash-o"
            onClick={this._onDeleteRow}
          />
        </td>
      </tr>
    );
  }
}
RapbuRow.propTypes = {
  idx: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onDeleteRow: PropTypes.func,
  coa: PropTypes.object.isRequired,
  data: PropTypes.object,
  error: PropTypes.object,
};
RapbuRow.defaultProps = {
  onChange: noop,
  onDeleteRow: noop,
  data: {},
  error: {},
};
