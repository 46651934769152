import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import daftar_siswa_kelas from "../../../assets/img/akademi/data_siswa/daftar_siswa_kelas.png";
import siswa_keluar from "../../../assets/img/akademi/data_siswa/siswa_keluar.png";
import pembatalan_siswa_keluar from "../../../assets/img/akademi/data_siswa/pembatalan_siswa_keluar.png";
import { map } from "lodash";

export default class DataSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickNomorIndukSiswa = this.onClickNomorIndukSiswa.bind(this);
    this.onClickDaftarSiswaKelas = this.onClickDaftarSiswaKelas.bind(this);
    this.onClickCatatSiswaKeluar = this.onClickCatatSiswaKeluar.bind(this);
    this.onClickPembatalanSiswa = this.onClickPembatalanSiswa.bind(this);

    this.state = {
      is_show: false,
    };
  }

  onClickNomorIndukSiswa() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/nis");
  }

  onClickDaftarSiswaKelas() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas");
  }

  onClickCatatSiswaKeluar() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/catat-siswa-keluar");
  }

  onClickPembatalanSiswa() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa/pembatalan-siswa-keluar");
  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const permissions_name = [];

    map(permissions, (list, idx) => {
      permissions_name.push(list.name);
    });

    console.log(permissions_name.indexOf("absensi") > -1);
    // console.log(permissions_name === ["catat_siswa_kelas"]?"Hello":"World");
    return (
      <div className="akademi">
        <div className="akademi__list">
          <button onClick={this.onClickDaftarSiswaKelas}>
            <img src={daftar_siswa_kelas} alt="DataKelas" />
            <h3>Daftar Siswa Kelas</h3>
          </button>
        </div>
        {permissions_name.indexOf("catat_siswa_kelas") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickCatatSiswaKeluar}>
              <img src={siswa_keluar} alt="DataKelas" />
              <h3>Catat Siswa Keluar</h3>
            </button>
          </div>
        )}

        {permissions_name.indexOf("catat_siswa_kelas") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickPembatalanSiswa}>
              <img src={pembatalan_siswa_keluar} alt="DataKelas" />
              <h3>Pembatalan Siswa Keluar</h3>
            </button>
          </div>
        )}
      </div>
    );
  }
}
DataSiswa.propTypes = {
  history: PropTypes.object.isRequired,
};
