import { connect } from 'react-redux';
import KenaikanKelas from '../../../views/Akademi/DataKelas/KenaikanKelas.view';
import { getClasses } from '../../../states/thunks/options.thunk';
import { getLevels, getPeriodsOptions, getStudentOptions, getParamOptionsAcademics, getParamOptions, getClassesOptions, getPrmLevels, getClassroomNextOptions, getClassroomOptions} from '../../../states/thunks/options.thunk';
import {listNextClassStudent , saveNextClass } from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getClassroomNextOptions: (payload, type) => dispatch(getClassroomNextOptions(payload, type)),
    // handleAddBudget: (payload, goBack, unit_id) => dispatch((payload, goBack, unit_id)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleSaveNextClass: (payload, goback) => dispatch(saveNextClass(payload, goback)),
    handleListNextClassStudent: (payload, goback) => dispatch(listNextClassStudent(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getClassroomsOptions: (payload) => dispatch(getClassroomOptions(payload)),
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KenaikanKelas);