import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../components/base';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { STAFF_TABLE_FIELDS } from '../../constants/personnel.constant';

export default class StaffList extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickView = this._onClickView.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    // this._onClickSk = this._onClickSk.bind(this);
    // this.customAction = [{
    //   Component: Button,
    //   props: {
    //     title: 'SK',
    //     className: 'staff-list__sk-button',
    //     onClick: this._onClickSk,
    //   },
    // }];
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/kelola-staff', { data: '', isEdit: false });
  }
  _onClickView(val) {
    const { history } = this.props;
    history.push('/dashboard/personalia/kelola-staff', { data: val, isEdit: true });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/personalia/kelola-staff', { data: val, isEdit: true });
  }

  _onClickDelete(id, data) {
    const { handleDeleteStaff } = this.props;
    return handleDeleteStaff({ id, nip: data.nip });
  }

  // _onClickSk(val) {
  //   const { history } = this.props;
  //   history.push(`/dashboard/personalia/surat-keputusan/${val.nip}`, { data: val });
  // }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/personalia/upload-staff');
  }

  render() {
    const { handleGetStaff } = this.props;
    return (
      <div className="staff-list">
        <div className="staff-list__header">
          <div className="staff-list__button">
            <Button
              title="Buat Pendaftaran"
              onClick={this._onClickAdd}
            />
          </div>
        </div>
        <div className="result__content">
          {/* <ContentTable
            onSearchContent={handleGetStaff}
            customAction={this.customAction}
            tableFields={STAFF_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            onClickView={this._onClickView}
            onClickDelete={this._onClickDelete}
          /> */}
        </div>
      </div>
    );
  }
}
StaffList.propTypes = {
  handleGetStaff: PropTypes.func,
  handleDeleteStaff: PropTypes.func,
  history: PropTypes.object.isRequired,
};
StaffList.defaultProps = {
  handleGetStaff: noop,
  handleDeleteStaff: noop,
};
