import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import absensi_harian from "../../../assets/img/akademi/absensi_siswa/absensi_harian.png";
import absensi_rekap from "../../../assets/img/akademi/absensi_siswa/absensi_rekap.png";
import isi_perbidang_studi from "../../../assets/img/akademi/absensi_siswa/isi_perbidang_studi.png";
import laporan_absen_bidang_study from "../../../assets/img/akademi/absensi_siswa/laporan_absen_bidang_study.png";
import { map } from "lodash";

export default class AbsensiSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAbsensiHarian = this.onClickAbsensiHarian.bind(this);
    this.onClickAbsensiRekap = this.onClickAbsensiRekap.bind(this);
    this.onClickIsiPerbidang = this.onClickIsiPerbidang.bind(this);
    this.onClickLaporanAbsen = this.onClickLaporanAbsen.bind(this);
  }

  onClickAbsensiHarian() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-harian");
  }

  onClickAbsensiRekap() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap");
  }

  onClickIsiPerbidang() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study");
  }

  onClickLaporanAbsen() {
    const { history } = this.props;
    history.push(
      "/dashboard/kesiswaan/absensi-siswa/laporan-absen-bidang-study"
    );
  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const permissions_name = [];

    map(permissions, (list, idx) => {
      permissions_name.push(list.name);
    });

    return (
      <div className="akademi">
        {permissions_name.indexOf("absensi_harian") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickAbsensiHarian}>
              <img src={absensi_harian} alt="AbsensiHarian" />
              <h3>Absensi Harian</h3>
            </button>
          </div>
        )}
        {permissions_name.indexOf("absensi_rekap") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickAbsensiRekap}>
              <img src={absensi_rekap} alt="AbsensiRekap" />
              <h3>Absensi Rekap</h3>
            </button>
          </div>
        )}
        {permissions_name.indexOf("isi_per_bidang_study") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickIsiPerbidang}>
              <img src={isi_perbidang_studi} alt="IsiPerbidang" />
              <h3>Isi Perbidang Study</h3>
            </button>
          </div>
        )}

        {permissions_name.indexOf("laporan_absen_bidang_study") > -1 ==
          true && (
          <div className="akademi__list">
            <button onClick={this.onClickLaporanAbsen}>
              <img src={laporan_absen_bidang_study} alt="LaporanAbsen" />
              <h3>Laporan Absen Bidang Study</h3>
            </button>
          </div>
        )}
      </div>
    );
  }
}
AbsensiSiswa.propTypes = {
  history: PropTypes.object.isRequired,
};
