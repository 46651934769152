import { connect } from 'react-redux';
import InvoiceSummary from '../../../../views/Finance/Spp/LaporanKeu/InvoiceSummary.laporankeu.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(InvoiceSummary);
