import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEmpty, isEqual  } from 'lodash';
import { commaFormatted, formatData } from '../../../../utils/transformer.util';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { JADWAL_MATPEL_LIST_TABLE_FIELDS } from '../../../../constants/Student/student.constant';
import { Link } from 'react-router-dom';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JadwalMatpelTable1 extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.state ={
          page: 1,
          content: initialContent,
          unchecked: {},
          filters:{},
          list:[],
            form: {
                value: {},
                error: {},
               
    
            },
            param:'',
        };
      }
      
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }
render(){
  const { onChange, form, classes  } = this.props;
  const {content,list, param, filters = {} } = this.state;
  console.log(content)
  return (
    <div id="divPrint" style={{width:800, border: '0px solid black'}}>
      <div className="fund-request__content">
        <table className="table">
                    <thead>
                      <tr>
                        {
                            map(JADWAL_MATPEL_LIST_TABLE_FIELDS.label, (field, idx) => (
                              <th key={`table_th_${idx}`}>{field}</th>
                            ))
                          }
                      </tr>
                    </thead>
                    <tbody>
                      {
                          map(content.list, (list, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {
                              map(JADWAL_MATPEL_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                                if (fieldIdx === 0) {
                                  return (
                                    <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                                  );
                                }
                                if (field.type === 'link') {
                                  return (
                                    <td key={`table_${fieldIdx}_${idx}`}><Link to={'/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul'}>{list.name}</Link></td>
                                      // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                                  );
                                }
                                return (
                                  <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                    {formatData(list, field)}
                                  </td>
                                );
                              })
                              }
                              {this._renderButtons(list)}
                            </tr>
                          ))
                        }
                    </tbody>
                  </table>
         
         
        </div>
    </div>
  );
}
}
JadwalMatpelTable1.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
JadwalMatpelTable1.defaultProps = {
  onFormChange: noop,
};
