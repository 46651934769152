import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, map, isEqual
} from 'lodash';
import {
  Pagination,
  Loading,
  Button,
  Select,
  SearchSelect,
  Input
} from '../../../components/base';
import language from '../../../languages';
import { formatData } from '../../../utils/transformer.util';



const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

const isPostedOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: '' },
  { value: 0, label: '' },
];

const isCreditOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: '1' },
  { value: 0, label: '2' },
];

export default class AbsensiHarian extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickActionButton = this._onClickActionButton.bind(this);
    this._onClickPostingButton = this._onClickPostingButton.bind(this);
    this.isMount = false;
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.customAction = [{
      Component: Button,
      props: {
        title: 'Preview',
        className: 'list-soal__action-button',
        onClick: this._onClickActionButton,
      },
    }, {
      Component: Button,
      props: {
        title: 'Posting',
        className: 'list-soal__posting-button',
        onClick: this._onClickPostingButton,
      },
    }];

    this.state = {
      content: initialContent,
      listAmount: 5,
      filters: {}
    };
  }

  componentDidMount() {
    const { content } = this.state;

    this.isMount = true;

    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  _onClickAdd() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/kurikulum/soal/list-soal', { isEdit: false, ...state });
  }

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/kurikulum/soal/${link}`);
  }


  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/soal/list-soal', { data: { id: val.id }, isEdit: true });
  }

  _onClickActionButton(val) {
    const { history } = this.props;
    history.push(`/dashboard/kurikulum/soal/list-soal/${val.id}`);
  }

  async _onClickPostingButton(val) {
    const { handlePostJournalPosting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalPosting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }

  async _onClickUnpostButton(val) {
    const { handlePostJournalUnposting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalUnposting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }

  _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleGetCashJournal } = this.props;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleGetCashJournal({ ...params, filters });
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        }
      } catch (err) {
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    if (data.is_posted === 0) {
      if (permissions.includes('create_journal')) {
        return ([
          <Button
            key="button_edit"
            onClick={() => this._onClickEdit(data)}
            title="Edit"
          />,
          <Button
            key="button_post"
            onClick={() => this._onClickPostingButton(data)}
            title="Post"
          />,
        ]);
      }
    } else {
      return ([
        <Button
          key="button_preview"
          onClick={() => this._onClickActionButton(data)}
          title="Preview"
        />,
        <>
          {permissions.includes('unpost_journal') && (
            <Button
              key="button_unpost"
              onClick={() => this._onClickUnpostButton(data)}
              title="Unpost"
            />
          )}
        </>,
        
      ]);
    }
    return null;
  }

  render() {
    
    const {
      listAmount,
      content,
      filters, param, form, onFormChange
    } = this.props;
    const academic_year = [
      { label: '2019', value: '1'},
      { label: '2020', value: '2'},
      { label: '2021', value: '3'},
    ]
    return (
          <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
              
              <div className="absensi-rekap__custom-form-row__field">
                <Input
                    type='date'
                    name="date"
                    label="Tanggal"
                    onChange={onFormChange}
                    value={form.value.date}
                    error={form.error.date || ''}
                  />
              </div>
            </div>
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  label="Tahun Ajaran"
                  name="period"
                  onChange={onFormChange}
                  placeholder="Pilihan"
                  data={academic_year}
                  value={form.value.period}
                  error={form.error.period || ''}
                />
              </div>
            </div>
            <div>
              <br></br>
            <h1>Absensi Siswa Kelas TK A Tahun Ajaran 2018-2019</h1>
        </div>
          </div>
      
    );
  }
}

AbsensiHarian.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  paging: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
};
AbsensiHarian.defaultProps = {
  onFormChange: noop,
  paging: true,
  currentPage: 1,
  totalPage: 1,
};