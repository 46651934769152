import CetakRaportEng from '../../../views/Kurikulum/Ulangan/CetakRaportEng.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getLembarDaftarNilaiEng } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleGetDaftarNilaiEng: payload => dispatch(getLembarDaftarNilaiEng(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (CetakRaportEng);