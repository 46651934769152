import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import { ANALISA_DISPLINER_FORM_FIELDS} from '../../../constants/Student/displiner.constant';
import  NilaiKTSPForm  from './components/NilaiKTSPForm.component';
import  NilaiKTSPTable  from './components/NilaiKTSPTable.component';

export default class DaftarNilaiKTSP extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickEdit = this._onClickEdit.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }

      componentDidMount() {
        const {  handleGetClasses } = this.props;
       handleGetClasses();
      }
    
      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan');
      }
    
      _onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/pendaftaran', { data: val });
      }
    
      
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }

      onSubmit(e) {
        e.preventDefault();
        // const { form} = this.state;
        const { form } = this.state;
        const { handleAddBudget, history, user } = this.props;
        const { workingUnit, prm_school_units_id } = user;
    
        let unit_id = prm_school_units_id;
    
        if (unit_id === null && workingUnit && workingUnit.id) {
          unit_id = workingUnit.id;
        }
        handleAddBudget(form.value, history.goBack, unit_id);
        // handleAddBudget(form.value, plafon, history.goBack, unit_id);
        //handleAddBudget(form.value, history.goBack, unit_id);
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas, handleDeleteKelas,classes={} } = this.props;
        return (
            <div className="add-budget">
        
            <form onSubmit={this.onSubmit}>
              <div className="budget__title">
                <h1>Daftar Nilai Siswa KTSP</h1>
                <hr></hr>
              </div>
              
              <NilaiKTSPForm
              classes={classes}
              form={form}
              onFormChange={this._onFormChange}
              />
            <div className="manage-registration__footer">
             <div className="manage-registration__button-wrapper">
              { <Button
               onClick={this._onClickAdd}
              title="Tambah Komen Otomatis"
            /> 
              }
              {
             <Button
             onClick={this._onClickLaporan}
             title="Cetak Ke Printer"
           />  
           }

            </div>
            </div>
             
            <NilaiKTSPTable
              form={form}
              onFormChange={this._onFormChange}
              />
            <div className="manage-registration__footer">
             <div className="nilai-ktsp__button-wrapper">
              { <Button
               onClick={this._onClickAdd}
              title="Simpan Ke Raport"
            /> 
              }
              {
             <Button
             onClick={this._onClickLaporan}
             title="Edit"
           />  
           }

            {
             <Button
             onClick={this._onClickLaporan}
             title="Download CSV / PDF"
           />  
           }

            </div>
            </div>
           
          </form>
          </div>
        );
      }
}
DaftarNilaiKTSP.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetClasses: PropTypes.func,
};
DaftarNilaiKTSP.defaultProps = {
  handleGetClasses: noop, 
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
