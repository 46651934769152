import LembarObsHarian from '../../../views/Kurikulum/Ulangan/LembarObsHarian.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editLembarObsHarian, saveLembarObsHarian, getLembarObsHarian } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditLembarObsHarian: (payload, goBack) => dispatch(editLembarObsHarian(payload, goBack)),
        handleSaveLembarObsHarian: (payload, goback) => dispatch(saveLembarObsHarian(payload, goback)),
        handleGetLembarObsHarian: payload => dispatch(getLembarObsHarian(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (LembarObsHarian);