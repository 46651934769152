import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../utils/transformer.util';
import Button from '../../../components/base/Button/Button.component';
import { printObservasi } from '../../../utils/print.util';
import ListForm from '../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../constants/student.constant';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import ReactToPrint from "react-to-print";



 class ComponentToPrint extends Component {
   
  constructor(props) {
    super(props);
    this.state = {
      is_show : false,
      journal: {},
    };
    // this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  // async onGetDetails() {
  //   const { handleGetCashJournal, match } = this.props;
  //   const { id } = match.params;
  //   try {
  //     const payload = await handleGetCashJournal({ id });
  //     this.setState({
  //       journal: payload,
  //     });
  //   } catch (err) {
  //     // err action
  //   }
  // }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      <div id="divPrint" style={{width:800, border: '0px solid black'}}>
      <div >
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('cetak-observasi__header',
              'cetak-observasi__table',
              'cetak-observasi__footer');
          }}
        /> */}
        <div id="cetak-observasi" className="cetak-observasi">
          {
              <div className="cetak-observasi__content">
                  <div className="cetak-observasi__header">
                  <div className="cetak-observasi__custom-form-row">
                      
                          <div className="cetak-observasi__box--square2">
                              Image
                          </div>
                          
                      
                      {/* <div className="cetak-observasi__header-title--column"> */}
                      <div className="cetak-observasi__header-title--column">
                      <div className="cetak-observasi__header-title--bold">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        <p>NAMA SEKOLAH</p>
                        <p>TAHUN AKADEMIK 2019-2020</p>
                        </div>
                        <p style={{marginTop:20}}>KELAS:</p>
                        </div>
                     {/* </div> */}
                    
      </div>
      </div>
                     
                      <div className="cetak-observasi__body">
                <table className="cetak-observasi__table">
                  <thead>
                    <tr>
                      <th colSpan={2}> NAMA :  </th>
                      <th>BULAN: &nbsp;
                          MINGGU:
                      </th>
                      <th>NOTE</th>
                      <th>BULAN: &nbsp;
                          MINGGU:
                      </th>
                      <th>NOTE</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    {/* {
                    map(journal.details, (list, idx) => (
                      <tr key={`journal_detail_${idx}`}>
                        <td>{idx + 1}</td>
                        <td>{list.code_of_account}</td>
                        <td>{list.parameter_code.title}</td>
                        <td>{list.description}</td>
                        <td className="nominal">{commaFormatted(list.amount)}</td>
                      </tr>
                    ))
                  } */}
                  <td>24 Nov 1029</td>
                  <td>Bahasa Indonesia</td>
                 
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  
                  </tbody>
                </table>
              </div>
              <div className="cetak-observasi__footer">
              <div className="cetak-observasi__custom-form-column__field">
          
          <Textarea
              type='text'
              name="namaSaudara1"
              label="Catatan Guru"
             
              // error={form.error.namaSaudara1 || ''}
            />
           <p>Guru Kelas: &nbsp;
               Nama Guru
           </p>
           
         
           
          </div>
                </div>


              </div>
              
           
          }
        </div>
      </div>
      </div>
    );
  }
}

export default class CetakObservasi extends Component {

  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                         
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}



  CetakObservasi.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  CetakObservasi.defaultProps = {
    handleGetCashJournal: noop,
  };
  