import { connect } from 'react-redux';
import CetakSlip from '../../../../views/Finance/Spp/LaporanKeu/CetakSlip.laporaninvoice.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import { getClasses } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(CetakSlip);
