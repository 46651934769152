export const FINANCE_ADD_NON_BUDGET_FORM_FIELDS = [{
  type: 'text',
  name: 'file_number',
  label: 'No. Pengajuan',
  placeholder: 'Isi nomor pengajuan',
  full: true,
}, {
  type: 'date',
  name: 'date',
  label: 'Tanggal',
  placeholder: 'Pilih tanggal',
  full: true,
}, {
  type: 'selectCoa',
  name: 'code_of_account',
  label: 'Kode Akun',
  placeholder: 'Isi kode akun',
  full: true,
}, {
  type: 'text',
  name: 'activity',
  label: 'Keterangan Kegiatan',
  placeholder: 'Isi keterangan kegiatan',
  full: true,
}, {
  type: 'textarea',
  name: 'description',
  label: 'Uraian Kegiatan',
  placeholder: 'Isi uraian kegiatan',
  full: true,
}, {
  type: 'text',
  inputType: 'number',
  name: 'amount',
  label: 'Jumlah Pengajuan',
  placeholder: 'Isi jumlah pengajuan',
  full: true,
}, {
  type: 'file',
  name: 'file',
  label: 'Upload File',
  placeholder: 'Pilih file',
  full: true,
}];

export const NON_BUDGET_REQUIRED_FIELD = ['parameter_code'];

export const FINANCE_NON_BUDGET_TABLE_FIELDS = {
  label: [
    'No',
    'No. Pengajuan',
    'Tanggal',
    'Kode Akun',
    'Keterangan Kegiatan',
    'Uraian Kegiatan',
    'Jumlah Pengajuan',
    'File',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'file_number' },
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'code_of_account' },
    { type: 'string', attribute: 'activity' },
    { type: 'string', attribute: 'description' },
    { type: 'number', attribute: 'amount' },
    { type: 'string', attribute: 'desc' },
  ],
};
