import { connect } from 'react-redux';
import ProsesInvoice from '../../../../views/Finance/Spp/CreateInvoice/ProsesInvoice.createinvoice.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import { getClasses, getStatusJenisSPP, getStudentOptions, getParamOptions, getStudentUserIDOptions, getParamOptionsAcademics } from '../../../../states/thunks/options.thunk';
import { saveCreateInvoices, listJenisSPPInvoice, listStudentInvoice } from '../../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getStudentUserIDOptions: (payload) => dispatch(getStudentUserIDOptions(payload)),
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
    getStatusJenisSPP: (payload) => dispatch(getStatusJenisSPP(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleSaveCreateInvoices: (payload, goBack) => dispatch(saveCreateInvoices(payload, goBack)),    
    handleListStudentInvoice: (payload, goback) => dispatch(listStudentInvoice(payload, goback)),
    handleListJenisSPPInvoice: (payload, goback) => dispatch(listJenisSPPInvoice(payload, goback)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProsesInvoice);