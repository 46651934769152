import { combineReducers } from 'redux';
import appConfig from './appConfig.reducer';
import additionalApiPayload from './additionalApiPayload.reducer';
import user from './user.reducer';
import appSetup from './appSetup.reducer';
import loading from './loading.reducer';
import coa from './coa.reducer';
import journal from './journal.reducer';
import options from './options.reducer';

export default combineReducers({
  appConfig,
  additionalApiPayload,
  user,
  appSetup,
  loading,
  coa,
  journal,
  options,
});
