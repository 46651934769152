import LOGIN from '../fixtures/login.json';
import FINANCE_INCOME from '../fixtures/income.json';
import FINANCE_OUTCOME from '../fixtures/outcome.json';
import FINANCE_RAPBU from '../fixtures/rapbu.json';
import FINANCE_CASH_JOURNAL from '../fixtures/cash-journal.json';
import FINANCE_CASH_JOURNAL_BY_ID from '../fixtures/cash-journal-by-id.json';
import FINANCE_BANK_JOURNAL from '../fixtures/bank-journal.json';
import FINANCE_BANK_JOURNAL_BY_ID from '../fixtures/bank-journal-by-id.json';

export default {
  LOGIN,
  FINANCE_INCOME,
  FINANCE_OUTCOME,
  FINANCE_RAPBU,
  FINANCE_CASH_JOURNAL,
  FINANCE_CASH_JOURNAL_BY_ID,
  FINANCE_BANK_JOURNAL,
  FINANCE_BANK_JOURNAL_BY_ID,
};
