import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import JadwalKelasDetailForm from './components/JadwalKelasDetailForm.component';
import JadwalKelasTable from './components/JadwalKelasTable.component';

export default class JadwalKelasDetail extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._getParamOptions = this._getParamOptions.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              pelajaran: [
                {
                  Matpel: '',
                  Kelas:'',
                   //divisi: '',
                  nominal: 0,
                }                                      
              ],
            },
            error: {
              pelajaran: '',
            },
          },
          param: {},
        };
      
      }
    
      componentDidMount() {
        const paramTypes = ['classes', 'levels'];
        paramTypes.forEach((type) => {
          this._getParamOptions(type);
        });
     
      }

      async _getParamOptions(type, filters={}) {
        const { getParamOptions } = this.props;
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
      _onClickAdd(data) {
        const { form } = this.state;
        const { pelajaran } = form.value;
        
        pelajaran.push({
          Kelas: '',
          Matpel:'',
          // divisi: '',
          nominal: 0,
          //period:'',
        });
        
        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              pelajaran,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
        console.log(pelajaran)
      }
    
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { pelajaran } = prevState.form.value;
          const list = prevState.form.value.pelajaran;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            pelajaran: list
          };
        });
      }

      _onClickSubmit() {
        const { history } = this.props;
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-k13');
        //history.push('/dashboard/kurikulum/laporan/analisa-nilai-siswa');
        //history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
  render() {
    const {form, param} = this.state;
    const { handleGetKelas } = this.props;
    const { pelajaran } = form.value;
    return (
      <div className="student-list">
       <form onSubmit={this.onSubmit}>
         <h1>Jadwal per Kelas</h1>
          <JadwalKelasDetailForm
            form={form}
            onFormChange={this._onFormChange}
            param={param}
          />
          <JadwalKelasTable
            list={ pelajaran }
            onAddList={this._onClickAdd}
          />             
        </form>
      </div>
    );
  }
}
JadwalKelasDetail.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
JadwalKelasDetail.defaultProps = {
  getParamOptions: noop,  
  handleGetStudent: noop,
};
