import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../../components/base';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { FINANCE_NON_BUDGET_TABLE_FIELDS } from '../../../../constants/finance/budgeting/nonBudget.constant';

export default class NonBudget extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-non-anggaran', { isEdit: false });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-non-anggaran', { data: val, isEdit: true });
  }

  render() {
    const { handleGetNonBudget, handleDeleteNonBudget, user } = this.props;
    return (
      <div className="non-budget">
        <Button
          title="Tambah Non Anggaran"
          onClick={this.onClickAdd}
        />
        <div className="non-budget__content">
          <ContentTable
            onSearchContent={handleGetNonBudget}
            tableFields={FINANCE_NON_BUDGET_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickDelete={handleDeleteNonBudget}
            user={user}
          />
        </div>
      </div>
    );
  }
}

NonBudget.propTypes = {
  handleGetNonBudget: PropTypes.func,
  handleDeleteNonBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
NonBudget.defaultProps = {
  handleGetNonBudget: noop,
  handleDeleteNonBudget: noop,
  user: null,
};
