import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { Link } from 'react-router-dom';

export default function IsiNilaiEkskulForm(props) {
  const { onFormChange, form } = props;
  return (
    <div className="daftar-ekskul__content">
            <div className="daftar-ekskul__header">
                <p>Siswa Kelas.....Tahun Ajaran 2019-2020</p>
                </div>
                <div className="daftar-ekskul__body">
                  <table className="daftar-ekskul__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Siswa</th>
                        <th>Kelas/NIS</th>
                        <th>Ekskul Nilai</th>
                        <th>Ekskul Komentar</th>
                        <th>Ekskul Sikap</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>1</td>
                        <td><Input
                            type="text"
                            name="namaSaudara1"
                            onChange={onFormChange}
                            value={form.value.namaSaudara1 || ''}
                            error={form.error.namaSaudara1 || ''}
                        /></td>
                        <td></td>
                        <td><Input
                            type="text"
                            name="nilai"
                            onChange={onFormChange}
                            // value={form.value.namaSaudara1 || ''}
                            // error={form.error.namaSaudara1 || ''}
                        /></td>
                         <td><Input
                            type="text"
                            name="komentar"
                            onChange={onFormChange}
                            // value={form.value.namaSaudara1 || ''}
                            // error={form.error.namaSaudara1 || ''}
                        /></td>
                         <td><Input
                            type="text"
                            name="sikap"
                            onChange={onFormChange}
                            // value={form.value.namaSaudara1 || ''}
                            // error={form.error.namaSaudara1 || ''}
                        /></td>
                        

                    </tr>
                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
IsiNilaiEkskulForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
IsiNilaiEkskulForm.defaultProps = {
  onFormChange: noop,
};
