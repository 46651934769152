import IsiBobotNilai from '../../../views/Kurikulum/BobotNilai/IsiBobotNilai.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import {getParamOptionsAcademics, getTypeExam, getTypeFormula, getPeriodsOptions} from '../../../states/thunks/options.thunk';
import { saveBobotNilai, editBobotNilai, getBobotNilai} from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
    return {
      user: state.user || {},
    };
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        getTypeExam: (payload) => dispatch(getTypeExam(payload)),
        getTypeFormula: (payload) => dispatch(getTypeFormula(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleSaveBobotNilai: (payload, goback) => dispatch(saveBobotNilai(payload, goback)),
        handleGetBobotNilai: payload => dispatch(getBobotNilai(payload)),
        handleEditBobotNilai: (payload, goBack) => dispatch(editBobotNilai(payload, goBack)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),

      };
}
export default connect(mapStateToProps, mapDispatchToProps) (IsiBobotNilai);