import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, map, noop,
  findIndex, sumBy,
} from 'lodash';
import { Checkbox, Input } from '../../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../../utils/transformer.util';

export default class BudgetCheckList extends PureComponent {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  _onChange(event) {
    const { onChange } = this.props;
    onChange(event, 'selectedBudget');
  }

  render() {
    const {
      budgets,
      selectedBudget,
      error,
    } = this.props;
    const totalTransfer = sumBy(selectedBudget, budget => normalizeAmount(budget.amount));
    return (
      <div className="manage-budget-transfer__form-table">
        <div className="manage-budget-transfer__table-header">
          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th rowSpan={2}>No.</th>
                <th rowSpan={2}>Kode</th>
                <th rowSpan={2}>Keterangan</th>
                <th colSpan={3}>Perhitungan</th>
                <th rowSpan={2}>Jumlah</th>
                <th rowSpan={2}>Sisa Anggaran</th>
                <th rowSpan={2}>Jumlah Yang Dialihkan</th>
              </tr>
              <tr>
                <th>Kuantitas</th>
                <th>Satuan</th>
                <th>Periode</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="manage-budget-transfer__table-content">
          <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
              {
                map(budgets, (budget, idx) => {
                  const index = findIndex(selectedBudget, b => b.id === budget.id);
                  const isChecked = index > -1;
                  const err = error[index] ? error[index].amount : '';
                  return (
                    <tr key={`manage-budget-transfer_transaction__${idx}`}>
                      <td>
                        <Checkbox
                          noMargin
                          data-array-position={idx}
                          name="selectedBudget"
                          onChange={this._onChange}
                          checked={!isEmpty(selectedBudget) ? isChecked : false}
                        />
                      </td>
                      <td>
                        {budget.parameter_code.title}
                      </td>
                      <td>
                        {budget.desc}
                      </td>
                      <td>
                        {commaFormatted(budget.quantity)}
                      </td>
                      <td>
                        {commaFormatted(budget.price)}
                      </td>
                      <td>
                        {commaFormatted(budget.term)}
                      </td>
                      <td>
                        {commaFormatted(budget.total)}
                      </td>
                      <td>
                        {
                          budget.remains
                            ? commaFormatted(budget.remains)
                            : commaFormatted(budget.total)
                        }
                      </td>
                      <td>
                        <Input
                          noMargin
                          data-input-budget
                          data-array-position={idx}
                          data-input-type="number"
                          disabled={!isChecked}
                          type="text"
                          name="amount"
                          onChange={this._onChange}
                          value={isChecked ? selectedBudget[index].amount : ''}
                          error={isChecked ? err : ''}
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="manage-budget__total-transfer">
          <h1>Dana yang tersedia untuk pengalihan</h1>
          <h1>{commaFormatted(totalTransfer)}</h1>
        </div>
      </div>
    );
  }
}
BudgetCheckList.propTypes = {
  budgets: PropTypes.array,
  error: PropTypes.array,
  onChange: PropTypes.func,
  selectedBudget: PropTypes.array,
};
BudgetCheckList.defaultProps = {
  budgets: [],
  error: [],
  onChange: noop,
  selectedBudget: [],
};
