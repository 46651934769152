import { connect } from 'react-redux';
import ManageAdjustmentJournal from '../../../../views/Finance/Bookkeeping/AdjustmentJournal/ManageAdjustmentJournal.bookkeeping.view';
import {
  getAdjustmentJournalById,
  manageAdjustmentJournal,
  getSourceJournal,
  saveAdjustmentJournalCorrection,
} from '../../../../states/thunks/finance.thunk';
import { getAdjustmentCoaOptions, getJournalSourceOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleSaveCorrection: payload => dispatch(saveAdjustmentJournalCorrection(payload)),
    handleGetCoa: payload => dispatch(getAdjustmentCoaOptions(payload)),
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournalById(payload)),
    handleManageAdjustmentJournal: payload => dispatch(manageAdjustmentJournal(payload)),
    handleGetSourceJournal: payload => dispatch(getSourceJournal(payload)),
    handleGetSourceJournalOptions: payload => dispatch(getJournalSourceOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdjustmentJournal);
