import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import Admission from './Admission.route';
import Login from '../pages/Login.page';
import Logout from '../pages/Logout.page';
import Dashboard from './Dashboard.route';
// import NotFound from '../pages/others/NotFound.page';
import PrivateRoute from '../components/PrivateRoute.component';
import { Loading } from '../components/base';
import { initialSetup } from '../states/thunks/common.thunk';
import { setupUser } from '../states/thunks/auth.thunk';
import { storageKeys, get } from '../utils/storage.util';

class App extends PureComponent {
  async componentDidMount() {
    const {
      handleInitialSetup,
      handleSetupUser,
      history,
      location,
    } = this.props;
    handleInitialSetup();
    await handleSetupUser(history, location.pathname);
  }

  render() {
    const { appSetup, loading } = this.props;
    const token = get(storageKeys.USER_TOKEN);
    const isLoggedIn = !isEmpty(token);

    if (appSetup) {
      return (
        <div>
          <Switch>
            <Route path="/admission" component={Admission} />
            <PrivateRoute {...this.props} isLoggedIn={isLoggedIn} path="/login" component={Login} />
            <PrivateRoute {...this.props} isLoggedIn={isLoggedIn} path="/dashboard" component={Dashboard} />
            <PrivateRoute {...this.props} isLoggedIn={false} path="/logout" component={Logout} />

            {/* <Route name="Not Found" component={NotFound} /> */}
          </Switch>
          <Loading white full loading={loading} />
        </div>
      );
    }
    return (
      <Loading white full alwaysShow />
    );
  }
}

function mapStateToProps(state) {
  return {
    appSetup: state.appSetup,
    loading: state.loading,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleInitialSetup: () => dispatch(initialSetup()),
    handleSetupUser: (replace, pathname) => dispatch(setupUser(replace, pathname)),
  };
}

App.propTypes = {
  appSetup: PropTypes.bool,
  loading: PropTypes.object.isRequired,
  handleInitialSetup: PropTypes.func.isRequired,
  handleSetupUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
App.defaultProps = {
  appSetup: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
