import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from "lodash";

export default class Finance extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickIdentitas = this.onClickIdentitas.bind(this);
    this.onClickTahunAjaran = this.onClickTahunAjaran.bind(this);
    this.onClickJurusan = this.onClickJurusan.bind(this);
    this.onClickKenaikanKelas = this.onClickKenaikanKelas.bind(this);
    this.onClickKelas = this.onClickKelas.bind(this);
    this.onClickKelasKhusus = this.onClickKelasKhusus.bind(this);
    this.onClickLaporanKelas = this.onClickLaporanKelas.bind(this);
    this.onClickPembagianKelasKhusus = this.onClickPembagianKelasKhusus.bind(this);
    this.onClickSiswaPindahKelas = this.onClickSiswaPindahKelas.bind(this);
    this.onClickPindahUnit = this.onClickPindahUnit.bind(this);
    this.onClickDaftarPindahUnit = this.onClickDaftarPindahUnit.bind(this);
    this.onClickPermohonanPindahUnit = this.onClickPermohonanPindahUnit.bind(this);
  }

  onClickIdentitas() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/identitas-unit-sekolah');
  }

  onClickTahunAjaran() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/tahun-ajaran');
  }

  onClickJurusan() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/jurusan-kelas');
  }

  onClickKenaikanKelas() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kenaikan-kelas-siswa');
  }

  onClickKelas(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kelas');
  }

  onClickKelasKhusus(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kelas-khusus');
  }
  onClickLaporanKelas(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/pengaturan-kelas-ajaran-baru');
  }
  onClickPembagianKelasKhusus(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/pembagian-kelas-khusus');
  }
  onClickSiswaPindahKelas(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/siswa-pindah-kelas');
  }
  onClickPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/pindah-unit');
  }
  onClickDaftarPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/daftar-pindah-unit');
  }
  onClickPermohonanPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/permohonan-pindah-unit');
  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const permissions_name = [];

    map(permissions, (list, idx) => {
      permissions_name.push(list.name);
    });
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickIdentitas}>
            <img src={budgeting} alt="identitas" />
            <h3>Identitas Unit Sekolah</h3>
          </button>
        </div> */}
        {permissions_name.indexOf("tahun_ajaran_baru") > -1 == true && (
        <div className="akademi__list">
          <button onClick={this.onClickTahunAjaran}>
            <img src={budgeting} alt="jurusan" />
            <h3>Tahun Ajaran Baru</h3>
          </button>
        </div>
        )}
        {permissions_name.indexOf("jurusan_kelas") > -1 == true && (
        <div className="akademi__list">
          <button onClick={this.onClickJurusan}>
            <img src={budgeting} alt="jurusan" />
            <h3>Jurusan Kelas</h3>
          </button>
        </div>
        )}
        {permissions_name.indexOf("kelas") > -1 == true && (
        <div className="akademi__list">
          <button onClick={this.onClickKelas}>
            <img src={budgeting} alt="kelas" />
            <h3>Kelas</h3>
          </button>
        </div>
        )}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPembagianKelas}>
            <img src={budgeting} alt="pembagiankelas" />
            <h3>Pembagian Kelas</h3>
          </button>
        </div> */}
        {permissions_name.indexOf("kenaikan_kelas_siswa") > -1 == true && (
        <div className="akademi__list">
          <button onClick={this.onClickKenaikanKelas}>
            <img src={budgeting} alt="kenaikankelas" />
            <h3>Kenaikan Kelas Siswa</h3>
          </button>
        </div>
        )}
        {/* <div className="akademi__list">
          <button onClick={this.onClickKelasKhusus}>
            <img src={budgeting} alt="kelaskhusus" />
            <h3>Kelas Khusus</h3>
          </button>
        </div> */}
        {permissions_name.indexOf("laporan_kelas_tahun_ajaran_baru") > -1 == true && (
        <div className="akademi__list">
          <button onClick={this.onClickLaporanKelas}>
            <img src={budgeting} alt="pembagiankelaskhusus" />
            <h3>Laporan Kelas Tahun Ajaran Baru</h3>
          </button>
        </div>
        )}
        {permissions_name.indexOf("siswa_pindah_kelas") > -1 == true && (
        <div className="akademi__list">
          <button onClick={this.onClickSiswaPindahKelas}>
            <img src={budgeting} alt="siswapindahkls" />
            <h3>Siswa Pindah Kelas</h3>
          </button>
        </div>
        )}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPindahUnit}>
            <img src={budgeting} alt="pindahunit" />
            <h3>Pindah Unit</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickDaftarPindahUnit}>
            <img src={budgeting} alt="daftarpindahunit" />
            <h3>Daftar Pindah Unit</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPermohonanPindahUnit}>
            <img src={budgeting} alt="permohonanpindahunit" />
            <h3>Permohonan Pindah Unit</h3>
          </button>
        </div> */}
      </div>
    );
  }
}
Finance.propTypes = {
  history: PropTypes.object.isRequired,
};
