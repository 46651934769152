import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';
import DataLembur from'./components/DataLembur.component';
import DataCuti from'./components/DataCuti.component';
import DataIjin from './components/DataIjin.component';
import DataKegiatan from './components/DataKegiatan.component';

export default class LaporanAbsensiKhusus extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/upload');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas} = this.props;
        return (
          <div className="student-list">
               
               <div className="laporan-khusus__custom-form">
                <div className="laporan-khusus__custom-form-column">
                    <div className="laporan-khusus__custom-form-column__field">
                        <Input
                            type="text"
                            readOnly 
                            style={{backgroundColor:'grey'}}
                            name="grouppelanggaran"
                            label="Tahun Ajaran"
                            onChange={this._onFormChange}
                            data={ '7A', '2019-2020'}
                        
                            // error={form.error.namaSaudara1 || ''}
                        />
                        
                        
                        <Input
                            type="text"
                            readOnly 
                            style={{backgroundColor:'grey'}}
                            name="grouppelanggaran"
                            label="Mata Pelajaran"
                            onChange={this._onFormChange}
                            data={ '7A', 'kelas7a'}
                        
                            // error={form.error.namaSaudara1 || ''}
                        />
                        
                        <Input
                            type="text"
                            readOnly 
                            style={{backgroundColor:'grey'}}
                            name="pelanggarankelas"
                            label="Jenis Nilai Ulangan"
                            onChange={this._onFormChange}
                            data={ '7A', 'kelas7a'}
                        
                            // error={form.error.namaSaudara1 || ''}
                        />
                         <Select
                            name="pelanggarankelas"
                            label="Tipe"
                            onChange={this._onFormChange}
                            data={ '7A', 'kelas7a'}
                            // error={form.error.namaSaudara1 || ''}
                        />
                    </div>
                </div>
            </div>
             <DataLembur
              form={form}
              />
              <DataCuti
              form={form}
              />

              <DataIjin
              form={form}
              />

              <DataKegiatan
              form={form}
              />

          </div>
        );
      }
}
LaporanAbsensiKhusus.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanAbsensiKhusus.defaultProps = {
    
  handleGetStudent: noop,
};
