import { connect } from 'react-redux';
import CetakInvoice from '../../../../views/Finance/Spp/LaporanKeu/CetakInvoice.laporankeu.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(CetakInvoice);
