import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
} from 'react-router-dom';
import AdmissionHeader from '../components/Header/AdmissionHeader.component';
import Admission from '../pages/Admission/Admission.page';
import Result from '../pages/Admission/Result.admission.page';
import ManageAdmision from '../pages/Admission/ManageAdmision.page';
import { handleLogout } from '../states/thunks/auth.thunk';

class AdmissionRoute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: window.innerWidth > 900,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onPressHome = this.onPressHome.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
  }

  onPressHome() {
    const { history } = this.props;
    history.replace('/dashboard');
  }

  onClickMenu(path) {
    const { history } = this.props;
    history.push(path);
  }

  toggleSidebar() {
    this.setState(prevState => ({
      showSidebar: !prevState.showSidebar,
    }));
  }

  render() {
    const { match, location } = this.props;
    return (
      <div className="admission-route">
        <AdmissionHeader
          onClickMenu={this.toggleSidebar}
          pathname={location.pathname}
        />
        <div className="admission-route__wrapper">
          <div className="admission-route__main-panel">
            <div className="admission-route__content-wrapper">
              <Route exact path={match.path} component={Admission} />
              <Route exact path={`${match.path}/results`} component={Result} />
              <Route exact path={`${match.path}/buat-pendaftaran`} component={ManageAdmision} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdmissionRoute.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
AdmissionRoute.defaultProps = {};

function mapDispatchToProps(dispatch) {
  return {
    onLogout: push => dispatch(handleLogout(push)),
  };
}

export default connect(null, mapDispatchToProps)(AdmissionRoute);
