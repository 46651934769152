import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop, isEqual } from 'lodash';
import { Button, Pagination } from '../../../components/base';
import { formatData, normalizeAmount } from '../../../utils/transformer.util';
import language from '../../../languages';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { KELASKHUSUS_DATAKELAS_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/kelas.constant';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';

export default class KelasKhusus extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  
    this.state = {
      coa: {
        loading: true,
        list: [],

      },
      form: {
        value: {},
        error: {},
      },
    };
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/buat-data-kelas-khusus');
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tipe-anggaran', { data: val });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  render() {
    //console.log(this.props.handleDeleteBudget);
    const {form} = this.state;
    const { handleGetBudget, handleDeleteBudget} = this.props;
    return (
      <div className="budget">
        <div className="budget__title">
          <h1>Kelas Khusus</h1>
          <hr></hr>
        </div>
        <Button
          title="Buat Data Kelas Khusus"
          onClick={this.onClickAdd}
        />
        <div className="budget__content">
          <div className="budget__search">
          <ListForm
          
          form={form}
          formFields={SEARCH_FIELDS}
          onFormChange={this._onFormChange}
          />
          </div>
         <ContentTable
            onSearchContent={handleGetBudget}
            tableFields={KELASKHUSUS_DATAKELAS_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickDelete={handleDeleteBudget}
          />
        </div>
      </div>
    );
  }
}


// Budget.propTypes = {
//   handleGetBudget: PropTypes.func,
//   handleDeleteBudget: PropTypes.func,
//   user: PropTypes.object,
//   history: PropTypes.object.isRequired,
// };
// Budget.defaultProps = {
//   handleGetBudget: noop,
//   handleDeleteBudget: noop,
//   user: null,
// };

KelasKhusus.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
KelasKhusus.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
