export const STUDENT_ADMISSION_FORM_FIELDS = [{
  type: 'text',
  name: 'name',
  label: 'Nama Siswa',
  placeholder: 'Isi nawa siswa',
  full: true,
}, {
  type: 'textarea',
  name: 'alamat',
  label: 'Alamat',
  placeholder: 'Isi Alamat',
  full: true,
}, {
  type: 'dateOfBirth',
  placeName: 'tempatLahir',
  placeLabel: 'Tempat / Tanggal Lahir',
  placeholder: 'Isi tempat lahir',
  dateName: 'tanggalLahir',
  dateLabel: '\u00A0',
  full: true,
}, {
  type: 'text',
  name: 'hobi',
  label: 'Hobi',
  placeholder: 'Isi hobi',
  full: true,
}, {
  type: 'select',
  name: 'golonganDarah',
  label: 'Golongan Darah',
  placeholder: 'Pilih Golongan Darah',
  data: [
    { label: 'A', value: 'a' },
    { label: 'B', value: 'b' },
    { label: 'O', value: 'o' },
    { label: 'AB', value: 'ab' },
  ],
  full: true,
}, {
  type: 'text',
  name: 'impian',
  label: 'Impian',
  placeholder: 'Isi impian',
  full: true,
}, {
  type: 'text',
  name: 'asalSekolah',
  label: 'Asal Sekolah',
  placeholder: 'Isi asal sekolah',
  full: true,
}, {
  type: 'text',
  name: 'jumlahSaudara',
  label: 'Jumlah Saudara Kandung',
  placeholder: 'Isi jumlah saudara kandung',
  full: true,
}];

export const PARENT_ADMISSION_FORM_FIELDS = [{
  type: 'text',
  name: 'nameAyah',
  label: 'Nama Ayah Kandung',
  placeholder: 'Isi nama ayah kandung',
}, {
  type: 'text',
  name: 'nameIbu',
  label: 'Nama Ibu Kandung',
  placeholder: 'Isi nama ibu kandung',
}, {
  type: 'textarea',
  name: 'alamatAyah',
  label: 'Alamat',
  placeholder: 'Isi Alamat',
}, {
  type: 'textarea',
  name: 'alamatIbu',
  label: 'Alamat',
  placeholder: 'Isi Alamat',
}, {
  type: 'dateOfBirth',
  placeName: 'tempatLahirAyah',
  placeLabel: 'Tempat / Tanggal Lahir',
  placeholder: 'Isi tempat lahir',
  dateName: 'tanggalLahirAyah',
  dateLabel: '\u00A0',
}, {
  type: 'dateOfBirth',
  placeName: 'tempatLahirIbu',
  placeLabel: 'Tempat / Tanggal Lahir',
  placeholder: 'Isi tempat lahir',
  dateName: 'tanggalLahirIbu',
  dateLabel: '\u00A0',
}, {
  type: 'text',
  name: 'hobiAyah',
  label: 'Hobi',
  placeholder: 'Isi hobi',
}, {
  type: 'text',
  name: 'hobiIbu',
  label: 'Hobi',
  placeholder: 'Isi hobi',
}, {
  type: 'select',
  name: 'golonganDarahAyah',
  label: 'Golongan Darah',
  placeholder: 'Pilih Golongan Darah',
  data: [
    { label: 'A', value: 'a' },
    { label: 'B', value: 'b' },
    { label: 'O', value: 'o' },
    { label: 'AB', value: 'ab' },
  ],
}, {
  type: 'select',
  name: 'golonganDarahIbu',
  label: 'Golongan Darah',
  placeholder: 'Pilih Golongan Darah',
  data: [
    { label: 'A', value: 'a' },
    { label: 'B', value: 'b' },
    { label: 'O', value: 'o' },
    { label: 'AB', value: 'ab' },
  ],
}, {
  type: 'text',
  name: 'pekerjaanAyah',
  label: 'Pekerjaan',
  placeholder: 'Isi pekerjaan',
}, {
  type: 'text',
  name: 'pekerjaanIbu',
  label: 'Pekerjaan',
  placeholder: 'Isi pekerjaan',
}, {
  type: 'text',
  name: 'jabatanAyah',
  label: 'Jabatan',
  placeholder: 'Isi jabatan',
}, {
  type: 'text',
  name: 'jabatanIbu',
  label: 'Jabatan',
  placeholder: 'Isi jabatan',
}];

export const ADMISSION_REQUIRED_FIELD = ['parameter_code'];

export const ADMISSION_TABLE_FIELDS = {
  label: [
    'No',
    'Asal Sekolah',
    'Tanggal Lahir',
    'Nama Siswa',
    'Alamat',
    'Kota',
    'Status',
  ],
  value: [
    {},
    { type: 'string', attribute: 'asalSekolah' },
    { type: 'date', attribute: 'tanggalLahir' },
    { type: 'string', attribute: 'namaSiswa' },
    { type: 'string', attribute: 'alamat' },
    { type: 'string', attribute: 'kota' },
    { type: 'string', attribute: 'status' },
  ],
};

export const ADMISSION_RESULT_MOCK = [{
  asalSekolah: 'Tarakanita',
  tanggalLahir: new Date(),
  namaSiswa: 'Nicholas Handy',
  alamat: '67 Elbert Forest Suite 028',
  kota: 'South Delmer',
  status: 'OK',
}, {
  asalSekolah: 'Stella Maris',
  tanggalLahir: new Date(),
  namaSiswa: 'Bayu',
  alamat: '67 Elbert Forest Suite 100',
  kota: 'South Delmer',
  status: 'PEND',
}, {
  asalSekolah: 'Tarakanita',
  tanggalLahir: new Date(),
  namaSiswa: 'Chris',
  alamat: '67 Elbert Forest Suite 200',
  kota: 'South Delmer',
  status: 'OK',
}];
