import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function DocUploadForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="doc-upload__custom-form">
      <div className="doc-upload__custom-form-row">
        <div className="doc-upload__custom-form-row__field">
          <Input
            type="text"
            name="id"
            label="ID"
            onChange={onFormChange}
            // data={ '7A', 'kelas7a'}
            // placeholder={'Kelas 7A'}
            style={{backgroundColor:'#6b6b47'}}
            error={form.error.id || ''}
            value={form.value.id}
          />
        </div>
        
        <div className="doc-upload__custom-form-column__field">
            
          <Input
            type="file"
            name="jenis"
            label="Jenis"
            onChange={onFormChange}
            value={form.value.jenis || ''}
            error={form.error.jenis || ''}
            style={{backgroundColor:'#ffff00'}}
          />
          
       
         
        </div>
        </div>
        <div className="doc-upload__custom-form-row">
        <div className="doc-upload__custom-form-row__field">
          <Select
            name="type"
            label="Document Type"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Pilihan'}
            style={{backgroundColor:'#ffff00'}}
            value={form.value.type || ''}
            error={form.error.type || ''}
          />
        </div>
        
        <div className="doc-upload__custom-form-column__field">
            
         <Input
             
             type='text'
             name="max_size"
             label="Maximum Size"
             onChange={onFormChange}
             style={{backgroundColor:'#6b6b47'}}
             value={form.value.max_size || ''}
             error={form.error.max_size || ''}
             placeholder={'1GB / 1.073.741.824 byte'}
           />
          
       
         
        </div>
        </div>
        <div className="doc-upload__custom-form-row">
        <div className="doc-upload__custom-form-row__field">
          <Input
            type="text"
            name="unix"
            label="Folder / UNIX ID"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'2019, Nama Lengkap Guru, Bindo, Kelas'}
            style={{backgroundColor:'#00ccff'}}
            value={form.value.unix || ''}
            error={form.error.unix || ''}
          />
        </div>
        
        <div className="doc-upload__custom-form-column__field">
        <Input
            name="title"
            label="Document Title"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            style={{backgroundColor:'#ffff00'}}
            value={form.value.title || ''}
            error={form.error.title || ''}
          />
          
       
         
        </div>
        </div>
        <div className="doc-upload__custom-form-row">
        <div className="doc-upload__custom-form-row__field">
          <Textarea
            name="desc"
            label="Description"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            value={form.value.desc || ''}
            error={form.error.desc || ''}
          />
        </div>
        
       
        </div>
        
        </div>
        
 
   
  );
}
DocUploadForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
DocUploadForm.defaultProps = {
  onFormChange: noop,
};
