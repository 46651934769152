import { connect } from 'react-redux';
import BuatDaftarUnitPerpus from '../../../views/Library/TableControlPerpus/BuatDaftarUnitPerpus.library.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(BuatDaftarUnitPerpus);
