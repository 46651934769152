import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../components/base';
import BaseTable from '../../components/BaseTable/BaseTable.component';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { formatData } from '../../utils/transformer.util';
import language from '../../languages';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { KONSELING_SISWA_LIST_TABLE_FIELDS } from '../../constants/kurikulum/kurikulum.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class KonselingSiswa extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._getSubjectOptions= this._getSubjectOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);


    this.state = {
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
  
    const paramTypes = ['levels', 'classes', 'classrooms'];
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }
    this.handleGetData();
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getSubjectOptions();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }
  async onClickDownload(id) {
    const { handleDownloadAnnouncement } = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload)
  }


  async handleGetData(payload = {}) {
    const { handleListCounselingRecord, user } = this.props;
    const { school_unit, workingUnit, id, user_group, organizations_id } = user;
    const { levels_id, units_id } = school_unit;


    // if(user_group.id === 2){
      const res = await handleListCounselingRecord({
        ...payload, 
        user_id: id, 
        organizations_id: 12, 
        // units_id
      });

    this.setState({
      list: res,
    });
    // }
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/buat-catatan-konseling', { isEdit: true });
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/tambah-tempat-konseling', { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/buat-catatan-konseling', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteCounselingRecord } = this.props;
    const res = await handleDeleteCounselingRecord(id);
    if(res){
      this.handleGetData();
    }
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
   
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;

    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Delete'
           />
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  render() {
    const { list = {} } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const dataset = {
      toggle: "modal"
    };
    return (
      <div className="fund-request">
        
        <div className="beginning-balance__button-wrapper">
         <Button
          title="Buat Catatan Konseling"
          onClick={this.onClickAdd}
          data={dataset}
        />
        <Button
          title="Tambah Tempat Konseling"
          onClick={this.onClickAddFolder}
          // data={dataset}
        />

         </div>
         

        
        
        
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(KONSELING_SISWA_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(KONSELING_SISWA_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        {/* <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div> */}
      </div>
    );
  }
}
KonselingSiswa.propTypes = {
  handleListAnnouncement: PropTypes.func,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
KonselingSiswa.defaultProps = {
  handleListAnnouncement: noop,
  handleListAnnouncementStudent:PropTypes.func,
  handleDelete: noop,
  user: null,
};
