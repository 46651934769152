import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  range,
  map,
  noop,
  floor,
} from 'lodash';

export default class Pagination extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.getClickHandler = this.getClickHandler.bind(this);
    this.onClickPage = this.onClickPage.bind(this);
    this.onClickFirst = this.onClickFirst.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickLast = this.onClickLast.bind(this);
  }

  onClickPage(page) {
    const { onClick } = this.props;
    onClick(page);
  }

  onClickFirst() {
    const { onClick } = this.props;
    onClick(1);
  }

  onClickPrev() {
    const { onClick, currentPage } = this.props;
    onClick(currentPage - 1);
  }

  onClickNext() {
    const { onClick, currentPage } = this.props;
    onClick(currentPage + 1);
  }

  onClickLast() {
    const { onClick, totalPage } = this.props;
    onClick(totalPage);
  }

  getClickHandler(page) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, page)) {
      this.clickHandlers[page] = () => this.onClickPage(page);
    }
    return this.clickHandlers[page];
  }

  render() {
    const { totalPage, currentPage, visiblePages } = this.props;
    let pages = [];
    const flag = floor(visiblePages / 2);
    if (totalPage > visiblePages) {
      if (currentPage > totalPage - flag) {
        pages = range(totalPage - visiblePages, totalPage);
      } else if (currentPage > flag) {
        pages = range(currentPage - (flag + 1), currentPage + flag);
      } else {
        pages = range(0, visiblePages);
      }
    } else {
      pages = range(0, totalPage);
    }
    return (
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onMouseUp={this.onClickFirst}
        >
          &laquo;
        </button>
        <button
          disabled={currentPage === 1}
          onClick={this.onClickPrev}
        >
          &lt;
        </button>
        {
          map(pages, page => (
            <button
              key={`pagination_${page}`}
              className={page + 1 === currentPage ? 'active' : ''}
              onClick={this.getClickHandler(page + 1)}
            >
              {page + 1}
            </button>
          ))
        }
        <button
          disabled={currentPage === totalPage}
          onClick={this.onClickNext}
        >
          &gt;
        </button>
        <button
          disabled={currentPage === totalPage}
          onMouseUp={this.onClickLast}
        >
          &raquo;
        </button>
      </div>
    );
  }
}
Pagination.propTypes = {
  onClick: PropTypes.func,
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  visiblePages: PropTypes.number,
};
Pagination.defaultProps = {
  onClick: noop,
  totalPage: 1,
  currentPage: 1,
  visiblePages: 5,
};
