import React, { Component } from 'react';
import { Input, Select } from '../../../../components/base';
import PropTypes from 'prop-types';
export default class IdentitasSekolah2 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this.state = {
      form: {
        value: {

        },
        error: {

        },
      },
    };
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    const change = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => { console.log(this.state); });

    onChange(e);
  }

  render() {
    const {
      form, provinces, idx, cities, districts, sub_districts, onChange, classes, param,
    } = this.props;
    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="dimulaidarikelas"
              label="Di Mulai Dari Kelas"
              onChange={this._onFormChange}
              value={form.value.dimulaidarikelas || ''}
              error={form.error.dimulaidarikelas || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="lamapendidikan"
              label="Lama Pendidikan (Tahun)"
              onChange={this._onFormChange}
              value={form.value.lamapendidikan || ''}
              error={form.error.lamapendidikan || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="jmljampelajaranperhari"
              label="Jumlah Jam Pelajaran per Hari"
              onChange={this._onFormChange}
              value={form.value.jmljampelajaranperhari || ''}
              error={form.error.jmljampelajaranperhari || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="use_nis_nisn"
              label="Menggunakan NIS/NISN"
              placeholder="Pilihan"
              data={param.nisn_options}
              onChange={this._onFormChange}
              value={form.value.use_nis_nisn || ''}
              error={form.error.use_nis_nisn || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <div className="manage-registration__custom-form-row__field">
              <Input
                type="text"
                name="urutannisdimulaidari"
                label="Urutan NIS Dimulai Dari"
                onChange={this._onFormChange}
                value={form.value.urutannisdimulaidari || ''}
                error={form.error.urutannisdimulaidari || ''}
              />
            </div>
            <div className="manage-registration__custom-form-row__field">
              <Input
                type="number"
                name="bykkarakter"
                label="Sebanyak (Karakter)"
                onChange={this._onFormChange}
                value={form.value.jmljampelajaranperhari || ''}
                error={form.error.jmljampelajaranperhari || ''}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="nilaitertinggi"
              label="Nilai Tertinggi"
              placeholder="Pilihan"
              data={param.highest_grade}
              onChange={this._onFormChange}
              value={form.value.nilaitertinggi || ''}
              error={form.error.nilaitertinggi || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="nilaikurangterendah"
              label="Nilai Kurang Terendah"
              onChange={this._onFormChange}
              value={form.value.nilaikurangterendah || ''}
              error={form.error.nilaikurangterendah || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="provinces_id"
              label="Provinsi Default"
              placeholder="Pilihan"
              data={provinces.list}
              onChange={this._onFormChange}
              value={form.value.provinces_id || ''}
              error={form.error.provinces_id || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="jeniskelamin"
              label="Jenis Kelamin"
              placeholder="Pilihan"
              data={param.genders}
              onChange={this._onFormChange}
              value={form.value.jeniskelamin || ''}
              error={form.error.jeniskelamin || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="agamadefault"
              label="Agama Default"
              placeholder="Pilihan"
              data={param.religions}
              onChange={this._onFormChange}
              value={form.value.agamadefault || ''}
              error={form.error.agamadefault || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="warganegaradefault"
              label="Warga Negara Default"
              placeholder="Pilihan"
              data={param.citizenships}
              onChange={this._onFormChange}
              value={form.value.warganegaradefault || ''}
              error={form.error.warganegaradefault || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="tempatlahirdefault"
              label="Tempat Lahir Default"
              onChange={this._onFormChange}
              value={form.value.tempatlahirdefault || ''}
              error={form.error.tempatlahirdefault || ''}
            />
          </div>
        </div>

      </div>
    );
  }
}

IdentitasSekolah2.propTypes = {
  provinces : PropTypes.array,
};

IdentitasSekolah2.defaultProps = {
  provinces: [],
};
