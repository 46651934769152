import { connect } from 'react-redux';
import PayrollList from '../../../views/Personnel/Payroll/PayrollList.payroll.view';
import { getPayrollList } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetPayrollList: payload => dispatch(getPayrollList(payload)),
  };
}

export default connect(null, mapDispatchToProps)(PayrollList);
