import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import { map, noop, filter } from 'lodash';
import { Input, Button, Select, Checkbox, SearchSelect2, SearchSelect, Pagination } from '../../../components/base';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { InputGroup } from 'react-bootstrap';
import language from '../../../languages';
import { ATTENDANCE_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';

const MySwal = withReactContent(Swal);

export default class TabelAbsensiHarian extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this.state = {
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }
  
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  onClickButton(data, list) {
    const filteredList = filter(list, o => (
      // console.log(o)
      o.code === data.code && data.code_tema === o.code_tema
    ));
    // console.log(data)
    // console.log(filteredList)

    MySwal.fire({
      size: 20000,
      // title: <h3>Keterangan Anggaran</h3>,
      width: '1000px',
      height: '1000px',
      footer: '',
      html: this.renderModalContent(filteredList),
    });
  }

  renderHeaderModal() {
    const { selected, checkedAll } = this.state;
    return (
      <thead>
        <tr>
        <th rowSpan={2}>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>No</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Tanggal Absen</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Keterangan</th>
          </tr>
      </thead>
    );
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  renderModalContent(filteredList) {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis, periode, rincian
    } = this.props;
    return (
        
      <div>
          <h1>LIST ABSENSI SISWA</h1>
          <h2 align="left">Nama Siswa : </h2>
          <SearchSelect
                async={false}
                onClick={this._onFormChange}
                placeholder="NIS/NAMA SISWA"
                labelName="label"
                valueName="value"
                rightIcon="icon-search"
              />
        <div>
          <h1>{filteredList[0].name_tema}</h1>
        </div>
        <table className="manage-budget__form-table table">
          {this.renderHeaderModal()}
          {
          map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td><Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={this._onCheckDetail}
                      // value={budget.id}
                      // checked={has(selected, budget.id) ? true : false}
                      ref={this._setRef}
                      
                    /></td>
               
                
                <td className="nominal"></td>
                <td className="nominal"></td>
                <td className="nominal"></td>
                <td className="nominal"></td>
           
           
              </tr>
               
            ))
          }
        </table>
      </div>
    );
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis, periode, rincian, onFormChange
    } = this.props;
console.log(list)
    const tipePlafon = [
      { label: '---', value: '---' },
      { label: 'Rutin', value: 'rutin' },
      { label: 'Kegiatan', value: 'kegiatan' },
      { label: 'Swadaya', value: 'swadaya' },
      { label: 'Dana Pemerintah', value: 'dana pemerintah' },
      { label: 'Biaya Pengembangan Pemerintah', value: 'biaya pengembangan pemerintah' },
    ];

    return (
      <div>
      <div className="fund-request__content">
      <table className="table">
        <thead>
          <tr>
            {
                map(ATTENDANCE_LIST_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
              ))
              }
          </tr>
        </thead>
        <tbody>
          {
              map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map(ATTENDANCE_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                      );
                    }
                    if (field.attribute === 'status') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}><Input
                        type="checkbox"
                        name="status"
                        data-input-array
                        arrayPosition={idx}
                        data-field-name='absensiharian'
                        onChange={this._onFormChange}
                        value={data.status}
                        // ref={this._setRef}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }
                    if (field.attribute === 'description') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}><Input
                        type="text"
                        name="description"
                        data-input-array
                        arrayPosition={idx}
                        data-field-name='absensiharian'
                        onChange={this._onFormChange}
                        value={data.description}
                        error={data.description || ''}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }

                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(data, field)}
                      </td>
                    );
                  })
                  }
                </tr>
              ))
            }
        </tbody>
      </table>
    </div>
    <div className="user-management__table-footer">
  <p className="user-management__page-info">
    {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
  </p>
  <Pagination
    totalPage={list.totalPage}
    currentPage={list.currentPage}
    onClick={this.onChangePage}
  />
</div>
</div>
    );
  }
}

TabelAbsensiHarian.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,
};
TabelAbsensiHarian.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
  plafond_name: [],
};
