import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ReportSalary from '../../../components/ReportCard/ReportSalary.component';
import iconPayroll from '../../../assets/img/finance/pengalihan_dana.png';


export default class SalaryReport extends PureComponent {
  componentDidMount() {
    const { handleGetSalaryReport } = this.props;
    handleGetSalaryReport();
  }

  render() {
    return (
      <div className="salary-report">
          {/* <img src={iconPayroll} alt="icon payroll" /> */}
        <ReportSalary
          header={(
            <div className="salary-report__title">
              <h1>Slip Gaji Karyawan </h1> 
              <hr />
            </div>
          )}
        >

        <table className="salary-report__table">
            <tbody>
            <tr>
                <td></td>
                <td>No G :</td> <hr />
                <td>123456789</td>
                <td>Nama Pegawai</td>
            </tr>

            <tr>
                <td></td>
                <td>Slip No</td> <hr />
                <td>01202019</td>
            </tr>

            <tr>
                <td></td>
                <td>Jabatan</td> <hr />
                <td>Guru Wari Kelas</td>
            </tr>

            <tr>
                <td></td>
                <td>Nama Unit</td> <hr />
                <td>SMP Pintar Indonesia</td>
            </tr>
            </tbody>
        </table>

        <table className="salary-report__table">
            <thead>
              <tr>
                <th colSpan={3}>PENGHASILAN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>Jumlah Pengasilan</td>
                <td>0</td>
              </tr>
            </tbody>
        </table>

        <table className="salary-report__table">
            <thead>
              <tr>
                <th colSpan={6}>POTONGAN YPL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>PPh 21</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Angsuran</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Titipan</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Lain-lain</td>
                <td>0</td>
              </tr>
            </tbody>
            <tfoot>
            <tr>
                <td>&nbsp;</td>
                <td>Total</td>
                <td>0</td>
              </tr>
            </tfoot>
        </table>   

        <table className="salary-report__table">
            <thead>
              <tr>
                <th colSpan={6}>POTONGAN INTERN SEKOLAH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>Pot. CU</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Pot. Koperasi</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Tabungan</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Sosial</td>
                <td>0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Lain-lain</td>
                <td>0</td>
              </tr>
            </tbody>
            <tfoot>
            <tr>
                <td>&nbsp;</td>
                <td>Total</td>
                <td>0</td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td>Penerimaan Bersih</td>
                <td>0</td>
              </tr>
            </tfoot>
        </table>   

     </ReportSalary>
      </div>
    );
  }
}
SalaryReport.propTypes = {
  handleGetSalaryReport: PropTypes.func,
};
SalaryReport.defaultProps = {
  handleGetSalaryReport: noop,
};
