import { connect } from 'react-redux';
import PengisianPerkembanganAspek from '../../../views/Kurikulum/Ulangan/PengisianPerkembanganAspek.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getSubjectOptions,getTeacherSubjectsOptions, getParamOptionsAcademics, getTypeExam, getPeriodsOptions } from '../../../states/thunks/options.thunk';
import { saveDevAspect, editDevAspect,editAttitudeIndicator,getDevAspect, deleteDevAspect, listDevAspect} from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleSaveDevAspect: (payload, goback) => dispatch(saveDevAspect(payload, goback)),
    getTypeExam: (payload) => dispatch(getTypeExam(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getTeacherSubjectsOptions: (payload) => dispatch(getTeacherSubjectsOptions(payload)),
    handleEditDevAspect: (payload, goBack) => dispatch(editDevAspect(payload, goBack)),
    handleGetDevAspect: payload => dispatch(getDevAspect(payload)),
    handleEditAttitudeIndicator: (payload, goBack) => dispatch(editAttitudeIndicator(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    handleListDevAspect: (payload) => dispatch(listDevAspect(payload)),
    handledeleteDevAspect: (payload, goback) => dispatch(deleteDevAspect(payload, goback)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PengisianPerkembanganAspek);


