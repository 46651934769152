import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function LaporanRPPForm(props) {


  const { onFormChange, form,classes } = props;
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Select
            name="classes_year"
            label="Tahun Ajaran"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Kelas 7A'}
            value={form.value.classes_year || ''}
            error={form.error.classes_year || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
          <Select
            
            name="jenis"
            label="Jenis"
            onChange={onFormChange}
            value={form.value.jenis || ''}
            error={form.error.jenis || ''}
            placeholder={'2019 - 2020'}
          />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
              name="classes"
              label="Kelas"
              onChange={onFormChange}
              data={classes.list}
              placeholder={'Pilihan'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
        <Select
             name="periode"
             label="Periode"
             onChange={onFormChange}
             value={form.value.periode || ''}
             error={form.error.periode || ''}
           />
          
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
              name="matpel"
              label="Mata Pelajaran"
              onChange={onFormChange}
              data={ '7A', 'kelas7a'}
              placeholder={'Pilihan'}
              value={form.value.matpel || ''}
              error={form.error.matpel || ''}
            />
        </div>
        
       
        </div>
        
        </div>
        
 
   
  );
}
LaporanRPPForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LaporanRPPForm.defaultProps = {
  onFormChange: noop,
};
