import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { Select } from '../../../../../components/base';
import { generateYearList } from '../../../../../utils/transformer.util';

export default function RealizationForm(props) {
  const { form, onFormChange, coa } = props;
  return (
    <div>
      <div className="manage-realization__row-form">
        <div className="manage-realization__form-content">
          <Select
            name="periode"
            label="periode"
            data={generateYearList()}
            onChange={onFormChange}
            placeholder="Pilih periode"
            value={form.value.periode || ''}
            error={form.error.periode || ''}
          />
        </div>
        <div className="manage-realization__form-content">
          <SelectCoa
            name="code_of_account"
            label="Code Of Account"
            coa={coa}
            value={form.value.code_of_account}
            error={form.error.code_of_account}
            onClick={onFormChange}
            placeholder="Pilih Kode"
            rightIcon="icon-search"
          />
        </div>
      </div>
    </div>
  );
}
RealizationForm.propTypes = {
  coa: PropTypes.object.isRequired,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
RealizationForm.defaultProps = {
  onFormChange: noop,
};
