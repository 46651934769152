import { connect } from 'react-redux';
import BuatGroupPelanggaran from '../../../views/Student/displiner/BuatGroupPelanggaran.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {listStudentAttendance, listViolationsGroup, deleteViolationsGroup, saveViolationsGroup, editViolationsGroup, getViolationsGroup } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetViolationsGroup: payload => dispatch(getViolationsGroup(payload)),
    handleSaveViolationsGroup: (payload, goback) => dispatch(saveViolationsGroup(payload, goback)),
    handleListViolationsGroup: (payload, goback) => dispatch(listViolationsGroup(payload, goback)),
    handleEditViolationsGroup: (payload, goback) => dispatch(editViolationsGroup(payload, goback)), 
    handleDeleteViolationsGroup: (payload, goback) => dispatch(deleteViolationsGroup(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatGroupPelanggaran);


