import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop } from "lodash";
import { Input, Button, Select } from "../../../../components/base/index";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";

export default class EkskulMatpel extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList2, fieldName } = this.props;
    onAddList2(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList2, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList2(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list,
      error,
      onChange,
      param,
      fieldName,
      plafond_name,
      onFormChange,
      classrooms,
    } = this.props;

    const Matpel = [
      { label: "Bahasa Indonesia", value: "bindo" },
      { label: "Matematika", value: "mtk" },
      { label: "Pendidikan Kewarganegaraan", value: "pkn" },
      { label: "Pendidikan Jasmani Olahraga Kesehatan", value: "penjaskes" },
    ];

    const EKSKUL = [
      { label: "Renang", value: "1" },
      { label: "Badminton", value: "2" },
      { label: "Basket", value: "3" },
    ];

    return (
      <div>
        <p>Ekskul</p>
        <table className="manage-rapbs-plafon__form-table table">
          <thead>
            <tr>
              <th>No.</th>
              <th style={{width:'30%'}}>Kelas</th>
              <th style={{width:'55%'}}>Ekstrakulikuler</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                <Select
                    value={data.classrooms_id}
                    placeholder="Kelas"
                    data={param.classrooms}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name="ekskul"
                    name="classrooms_id"
                    onChange={onFormChange}
                  />
                </td>
                {/* <td>
                  <Select
                    value={data.levels_id}
                    placeholder="Pilih Unit"
                    data={param.levels}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='ekskul'
                    // disabled
                    name="levels_id"
                    onChange={onFormChange}
                  />

                </td> */}
                <td className="nominal">
                <Select
                    value={data.ekskul_id}
                    placeholder="Pilih Ekskul"
                    data={param.ekskul}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name="ekskul"
                    name="ekskul_id"
                    onChange={onFormChange}
                  />
                 
                  {/* {console.log(data)} */}
                </td>
                <td>
                  <Button
                    onClick={() => this._onDeleteList(idx)}
                    icon="icon-trash-o"
                  />
                </td>
              </tr>
            ))}
            <tr className="manage-rapbs-plafon__add-more">
              <td colSpan={7}>
                <div>
                  <Button icon="icon-plus" onClick={this._onAddList} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

EkskulMatpel.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  classrooms: PropTypes.array,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,
};
EkskulMatpel.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onFormChange: noop,
  onChange: noop,
  plafond_name: [],
  classrooms: [],
};
