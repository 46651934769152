export const storageKeys = {
  LANGUAGE: 'language',
  INITIAL_SETUP: 'initialSetup',
  USER_TOKEN: 'userToken',
};

export const set = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const get = key => JSON.parse(localStorage.getItem(key));

export const remove = key => localStorage.removeItem(key);
