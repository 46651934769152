import { SET_USER, SET_WORKING_UNIT, RESET_USER } from '../actions/user.action';

const userInitialState = {
  _id: '',
  username: '',
  shops: [],
  selectedShop: {},
  workingUnit: {},
};

export default function user(state = userInitialState, action) {
  switch (action.type) {
    case SET_USER: {
      return action.payload;
    }
    case SET_WORKING_UNIT: {
      return {
        ...state,
        workingUnit: action.payload,
      };
    }
    case RESET_USER: {
      return {};
    }
    default: {
      return state;
    }
  }
}
