import { connect } from 'react-redux';
import TablePelanggaran from '../../../views/Student/displiner/TablePelanggaran.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {listViolationsTable, deleteViolationsTable } from '../../../states/thunks/academics.thunk';
import { getViolationsOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getViolationsOptions: (payload) => dispatch(getViolationsOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListViolationsTable: (payload, goback) => dispatch(listViolationsTable(payload, goback)),
    handleDeleteViolationsTable: (payload, goback) => dispatch(deleteViolationsTable(payload, goback)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(TablePelanggaran);


