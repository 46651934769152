import { connect } from 'react-redux';
import TambahPengumuman from '../../../views/Kurikulum/Pengumuman/TambahPengumuman.kurikulum.view';
import { getParamOptions, getStudentOptions, getFolderOptions, getClasses, getLevels, getParamOptionsAcademics, getTeacherOptions } from '../../../states/thunks/options.thunk';
import { saveAnnouncement, listAnnouncement, editAnnouncement, uploadAnnouncementFile, downloadAnnouncementFile, getAnnouncement } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      user: state.user || {},
      classes: state.options.classes,
      levels: state.options.levels,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      handleGetAnnouncement: payload => dispatch(getAnnouncement(payload)),
      getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
      getTeacherOptions: (payload) => dispatch(getTeacherOptions(payload)),
      getFolderOptions: (payload) => dispatch(getFolderOptions(payload)),
      getClasses: (payload) => dispatch(getClasses(payload)),
      getLevels: (payload) => dispatch(getLevels(payload)),
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      handleSaveAnnouncement: (payload, goback) => dispatch(saveAnnouncement(payload, goback)),
      handleListAnnouncement: (payload, goback) => dispatch(listAnnouncement(payload, goback)),
      handleEditAnnouncement: (payload, goback) => dispatch(editAnnouncement(payload, goback)), 
      handleUploadAnnouncement: (payload, goback) => dispatch(uploadAnnouncementFile(payload, goback)),
      handleDownloadAnnouncement: (payload, goback) => dispatch(downloadAnnouncementFile(payload, goback)),
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(TambahPengumuman);