import { connect } from 'react-redux';
import DataBuku from '../../../views/Library/CatatBuku/DataBuku.library.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(DataBuku);
