import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import ReportCard from '../../../components/ReportCard/ReportCard.component';
import { commaFormatted, dateFormat } from '../../../utils/transformer.util';

export default class BigBookReport extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      coa: {
        from: null,
        to: null,
      },
      from: dateFormat(date, 'DD-MM-YYYY'),
      to: dateFormat(date, 'DD-MM-YYYY'),
      report: {},
      accordion: {
        active: [],
      },
      units: [],
      reportType: 'konsolidasi',
      selectedUnit: '',
      selectedPerwakilan: '',
      selectedPos: '',
    };

    this.reportTypes = [
      {
        value: 'konsolidasi', label: 'Konsolidasi',
      },
      {
        value: 'unit', label: 'Per Unit',
      },
    ];
    this.pos = [
      {
        id: 'ypl', title: 'YPL',
      }, {
        id: 'bos', title: 'BOS',
      }, {
        id: 'intern', title: 'Internal',
      },
    ];

    this._onSearch = this._onSearch.bind(this);
    this._getReport = this._getReport.bind(this);
    this._onSearchCoa = this._onSearchCoa.bind(this);
    this._getUnitOptions = this._getUnitOptions.bind(this);
  }

  componentDidMount() {
    const { handleGetCoa, handleGetPerwakilan, handleGetUnit } = this.props;
    handleGetCoa();
    handleGetPerwakilan();
    handleGetUnit();
    this._getReport();
  }

  _onSearchCoa(val) {
    const { handleGetCoa } = this.props;
    handleGetCoa(val);
  }

  _onSearch(val) {
    const {
      from = '',
      to = '',
      coa = {},
      perwakilanId = null,
      unitId = null,
    } = val;
    this.setState(prevState => ({
      ...prevState,
      from,
      to,
      coa: {
        from: (coa.from) ? coa.from.code : null,
        to: (coa.to) ? coa.to.code : null,
      },
      perwakilan_id: perwakilanId ? perwakilanId.id : null,
      unit_id: unitId ? unitId.id : null,
    }), () => {
      this._getReport();
    });
  }

  async _getReport() {
    const { handleGetGeneralLedgerReport, user } = this.props;
    const {
      coa = {},
      from,
      to,
      perwakilan_id = null,
      unit_id = null,
    } = this.state;
    let unitId = unit_id;
    if (perwakilan_id === null && unit_id === null) {
      unitId = user.prm_school_units_id;
    }
    if (coa.from !== null && coa.to !== null) {
      const res = await handleGetGeneralLedgerReport({
        unitId,
        perwakilan_id,
        from,
        to,
        coaFrom: coa.from || null,
        coaTo: coa.to || null,
      });

      this.setState(prevState => ({
        ...prevState,
        report: res,
      }));
    }
    return true;
  }

  async _getUnitOptions(e) {
    const { handleGetUnit } = this.props;
    const { value, name } = e.target;
    const filters = {
      perwakilan_id: null,
    };
    if (name === 'perwakilan') {
      filters.perwakilan_id = value.id;
    } else if (name === 'reportType' && value === 'unit') {
      filters.perwakilan_id = null;
    }

    const res = await handleGetUnit(filters);
    this.setState({
      units: res,
    });
  }

  render() {
    const {
      report,
    } = this.state;
    const {
      starting_balance,
      journals,
      coa: coaList,
    } = report;
    const {
      coa, perwakilan, user, unit,
    } = this.props;
    const { user_group } = user;
    const isPusat = [8, 9, 10, 13, 14, 15].includes(user_group.id);
    const isPerwakilan = [2, 6, 11, 12].includes(user_group.id);
    let role = 'unit';
    if (isPusat) {
      role = 'pusat';
    } else if (isPerwakilan) {
      role = 'perwakilan';
    }
    return (
      <div className="big-book-report">
        <ReportCard
          coa={coa}
          onSearch={this._onSearch}
          onSearchCoa={this._onSearchCoa}
          coaRangeSelector
          paging={false}
          header={(
            <div className="big-book-report__title">
              <h1>Laporan Buku Besar</h1>
            </div>
          )}
          reportTypes={this.reportTypes}
          pos={this.pos}
          perwakilan={isPusat ? perwakilan.list : null}
          units={unit.list}
          role={role}
          onChangeReportType={this._getUnitOptions}
          onChangePerwakilan={this._getUnitOptions}
        >
          {
              map(coaList, (reportItem) => {
                const initial_balance = starting_balance[reportItem.code];
                const filteredJournals =
                  journals.filter(o => o.code_of_account === reportItem.code.toString());
                let finalBalance = initial_balance || 0;
                let totalDebit = 0;
                let totalCredit = 0;

                return (
                  <div className="big-book-report__content" key={`big-book-report-content__${reportItem.code}`}>
                    <div className="big-book-report__header">
                      <div className="big-book-report__info">
                        <p>No. Akun</p>
                        <p>: {reportItem.code} </p>
                      </div>
                      <div className="big-book-report__info">
                        <p>Nama Akun</p>
                        <p>: {reportItem.title}</p>
                      </div>
                      <div className="big-book-report__info">
                        <p>Akun D/K</p>
                        <p>: {reportItem.type}</p>
                      </div>
                    </div>
                    <table className="big-book-report__table">
                      <thead>
                        <tr>
                          <th>Tanggal</th>
                          <th>Nomor Jurnal</th>
                          <th>Uraian</th>
                          <th className="nominal" width="25%">Saldo Awal</th>
                          <th className="nominal" width="25%">Debit</th>
                          <th className="nominal" width="25%">Kredit</th>
                          <th className="nominal">Saldo Akhir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          map(filteredJournals, (journal, journalIndex) => {
                            const initialBalance = parseFloat(finalBalance) || 0;
                            const debit = parseFloat(journal.debit) || 0;
                            const credit = parseFloat(journal.credit) || 0;
                            if (reportItem.type === 'D') {
                              finalBalance = initialBalance + debit - credit;
                            } else {
                              finalBalance = initialBalance - debit + credit;
                            }
                            totalDebit += debit;
                            totalCredit += credit;
                            return (
                              <tr key={`big-book-report-content__${reportItem.code}__${journal.journal_number}__${journalIndex}`}>
                                <td>{dateFormat(journal.date, 'DD-MM-YYYY')}</td>
                                <td>{journal.journal_number}</td>
                                <td>{journal.description}</td>
                                <td className="nominal">{journalIndex === 0 ? commaFormatted(initialBalance) : ''}</td>
                                <td className="nominal">{commaFormatted(debit)}</td>
                                <td className="nominal">{commaFormatted(credit)}</td>
                                <td className="nominal">{commaFormatted(finalBalance)}</td>
                              </tr>
                            );
                          })
                        }
                        <tr className="total">
                          <td colSpan={3} />
                          <td className="nominal">{commaFormatted(initial_balance)}</td>
                          <td className="nominal">{commaFormatted(totalDebit)}</td>
                          <td className="nominal">{commaFormatted(totalCredit)}</td>
                          <td className="nominal">{commaFormatted(finalBalance)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })
            }
        </ReportCard>
      </div>
    );
  }
}
BigBookReport.propTypes = {
  handleGetPerwakilan: PropTypes.func,
  handleGetCoa: PropTypes.func,
  handleGetGeneralLedgerReport: PropTypes.func,
  handleGetUnit: PropTypes.func,
  coa: PropTypes.object,
  perwakilan: PropTypes.object,
  user: PropTypes.object,
  unit: PropTypes.object,
};
BigBookReport.defaultProps = {
  handleGetPerwakilan: noop,
  handleGetCoa: noop,
  handleGetGeneralLedgerReport: noop,
  handleGetUnit: noop,
  coa: null,
  perwakilan: {},
  user: {},
  unit: {},
};
