import { connect } from 'react-redux';
import JenisSPP from '../../../../views/Finance/Spp/JenisSPP/JenisSPP.JenisSPP.view';
import { listJenisSPP, deleteJenisSPP, saveJenisSPP, editJenisSPP, getJenisSPP } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetJenisSPP: payload => dispatch(getJenisSPP(payload)),
    handleSaveJenisSPP: (payload, goback) => dispatch(saveJenisSPP(payload, goback)),
    handleListJenisSPP: (payload, goback) => dispatch(listJenisSPP(payload, goback)),
    handleEditJenisSPP: (payload, goback) => dispatch(editJenisSPP(payload, goback)), 
    handleDeleteJenisSPP: (payload, goback) => dispatch(deleteJenisSPP(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JenisSPP);
