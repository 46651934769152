import { connect } from 'react-redux';
import TableKegiatan from '../../../views/Personnel/Absensi/TableKegiatan.absensi.view';
import { getAssignment } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAssignment: payload => dispatch(getAssignment(payload)),
  };
}

export default connect(null, mapDispatchToProps)(TableKegiatan);