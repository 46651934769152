import React from 'react';
import { map, toUpper } from 'lodash';
import { STUDENT_BOOK_MOCK } from '../../constants/student.constant';
import { ALPHABET } from '../../variables/common.variable';

export default function BookContent() {
  return (
    <div className="book-content">
      <div className="book-content__wrapper-left">
        {
          map(STUDENT_BOOK_MOCK, (student, idx) => {
            const { full = false } = student;
            const fullClassname = full ? 'book-content__content--full' : 'book-content__content';
            return (
              <div key={`book_content_student__${idx}`} className="book-content__content-wrapper">
                <div>
                  <h1>{toUpper(ALPHABET[idx])}. {student.title}</h1>
                </div>
                <div>
                  <div className="book-content__student">
                    {
                      map(student.data, (data, dataIdx) => (
                        <div className={fullClassname} key={`book_content_student_data__${idx}__${dataIdx}`}>
                          <div className="book-content__detail-wrapper">
                            <div className="book-content__detail">
                              <div>
                                <p>{dataIdx + 1}. {data.label}</p>
                              </div>
                              <div>
                                <p>: {data.value}</p>
                              </div>
                            </div>
                            {
                              data.details && map(data.details, (detail, detailIdx) => (
                                <div className="book-content__detail" key={`book_content_student_data_detail__${idx}__${dataIdx}_${detailIdx}`}>
                                  <div>
                                    <p>
                                      &nbsp;&nbsp;&nbsp;&nbsp;{ALPHABET[detailIdx]}. {detail.label}
                                    </p>
                                  </div>
                                  <div>
                                    <p>: {detail.value}</p>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="book-content__wrapper-right">
        <h1>FOTO</h1>
      </div>
    </div>
  );
}
