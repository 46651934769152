import { connect } from 'react-redux';
import PaymentJournal from '../../../../views/Finance/Bookkeeping/PaymentJournal/PaymentJournal.finance.view';
import { getPaymentJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetPaymentJournal: payload => dispatch(getPaymentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(PaymentJournal);
