import { connect } from 'react-redux';
import Kelas from '../../../views/Akademi/DataKelas/Kelas.view';
import { listClassData, deleteClassData } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListClassData: (payload, goback) => dispatch(listClassData(payload, goback)),
    handleDeleteClassData: (payload, goback) => dispatch(deleteClassData(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Kelas);