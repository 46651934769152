import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, find } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../../../utils/transformer.util';
import { FINANCE_ADD_BUDGET_FORM_FIELDS } from '../../../../constants/finance/budgeting/budget.constant';
import { Button, Select, Textarea } from '../../../../components/base';

export default class AddBudget extends Component {
  constructor(props) {
    super(props);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getPeriodeOptions = this.getPeriodeOptions.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
      periode: [],
      existingPeriodes: [],
    };
  }

  componentDidMount() {
    this.getPeriodeOptions();
  }

  async getPeriodeOptions() {
    const { handleGetPeriodeOptions, handleGetExistingPeriodeOptions } = this.props;
    const periodes = await handleGetPeriodeOptions();
    const existingPeriodes = await handleGetExistingPeriodeOptions();

    this.setState(prevState => {
      if(periodes && existingPeriodes) {
        periodes.forEach((periode, index) => {
          const exists = find(existingPeriodes, (existing) => {
            return existing.value === periode.value;
          });

          periodes[index]['disabled'] = false;

          if(exists) {
            //periodes[index]['disabled'] = true;
          }

        });
      }

      return {
        ...prevState,
        periode: periodes,
        existingPeriodes: existingPeriodes,
      };
    });
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleAddBudget, history, user } = this.props;
    const { workingUnit, prm_school_units_id } = user;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    handleAddBudget(form.value, history.goBack, unit_id);
  }

  render() {
    const { form, periode } = this.state;
    return (
      <div className="add-budget">
        <h1>Buat Anggaran</h1>
        <form onSubmit={this.onSubmit}>
          <Select
            name="periode"
            label="Periode"
            data={periode}
            onChange={this.onFormChange}
            placeholder="Pilih Periode"
            value={form.value['periode'] || ''}
            error={form.error['periode'] || ''}
          />
          <Textarea
            name="desc"
            label="Deskripsi"
            placeholder="Isi Deskripsi"
            onChange={this.onFormChange}
            value={form.value['desc'] || ''}
            error={form.error['desc'] || ''}
            rows={2}
          />
          <div className="add-budget">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
AddBudget.propTypes = {
  handleAddBudget: PropTypes.func,
  handleGetPeriodeOptions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
AddBudget.defaultProps = {
  handleAddBudget: noop,
  user: null,
};
