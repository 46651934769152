import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop } from "lodash";
import ListForm from "../../../components/ListForm/ListForm.component";

import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { BUATDATA_DATAKELASKHUSUS_FORM_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import { Button } from "../../../components/base";
import BuatDaftarEkskulForm from "../components/BuatDaftarEkskulForm.component";
import { errorAlert } from "../../../utils/alert.util";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class BuatDaftarEkskul extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getEkskulOptions = this._getEkskulOptions.bind(this);
    this._getEkskulDayOptions = this._getEkskulDayOptions.bind(this);
    //this.deletepelajaran = this.deletepelajaran.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);

    this.state = {
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          students_id: "",
          daftar_ekskul: [
            {
              ekskul_id: "",
              ekskul_day: "",
              status: "",
            },
          ],
        },
        error: {
          daftar_ekskul: "",
        },
      },
      param: {
        ekskul_day: []
      },
    };
  }

  componentDidMount() {
    const { location, handleGetClasses } = this.props;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, id } = state;
    const paramTypes = ["classes", "levels", "classrooms"];
    if (isEdit && id) {
      this._setForm(id);
    }
    // this._getEkskulDayOptions();
    paramTypes.forEach((type) => {
      this._getParamOptions(type);
    });
    this._getEkskulOptions();
    if (isEmpty(content.list)) {
      //  this._onSearchContent({ page: 1 });
    }
  }

  async _getEkskulOptions() {
    const { getEkskulOptions } = this.props;
    const res = await getEkskulOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        ekskul: res,
      },
    }));
  }

  async _getEkskulDayOptions(filters = {}) {
    const { param } = this.state;
    const { getEkskulDayOptions } = this.props;

    const res = await getEkskulDayOptions(filters);

    let newListDay = param.ekskul_day;
    newListDay[filters.idx] = res;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        ekskul_day: newListDay,
      },
    }));
  }

  async onEdit() {
    const { history, handleEditListStudentEkskul } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataTabel = value.daftar_ekskul;
    let a;
    for (a = 0; a < dataTabel.length; a++) {
      if (
        dataTabel[a].ekskul_id &&
        !isEmpty(dataTabel[a].ekskul_day) &&
        dataTabel[a].status
      ) {
        canSaveA = true;
      } else {
        canSaveA = false;
        break;
      }
    }
    if (canSaveA == false) {
      errorAlert({
        title: "Tabel belum lengkap",
        errorMessage:
          "Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.",
      });
    } else {
      await handleEditListStudentEkskul(value);
      history.push("/dashboard/kurikulum/mata-pelajaran/daftar-guru");
    }
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListlistStudentEkskul } = this.props;
          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = await handleListlistStudentEkskul({
            ...params,
            organizations_id: 4,
            filters,
          });
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }
  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 4;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetListStudentEkskul } = this.props;
    const payload = await handleGetListStudentEkskul({ id });
    try {
      this.setState({
        form: {
          value: {
            students_id: id,
            daftar_ekskul: map(payload.daftar_ekskul, (detail) => ({
              ekskul_id: detail.ekskul_id,
              ekskul_day: detail.ekskul_day,
              status: detail.status,
            })),
          },
          error: {
            daftar_ekskul: [],
            ekskul: [],
          },
        },
      });
    } catch (err) {
      throw new Error(err);
      // err action
    } finally {
      map(payload.daftar_ekskul, (detail, idx) => {
        this._getEkskulDayOptions({ filters: { ekskul_id: detail.ekskul_id }, idx: idx })
      })
    }
  }

  async onSubmit() {
    const { history, handleSaveListStudentEkskul } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.daftar_ekskul;
    let a;
    for (a = 0; a < dataTabel.length; a++) {
      if (
        dataTabel[a].ekskul_id &&
        !isEmpty(dataTabel[a].ekskul_day) &&
        dataTabel[a].status
      ) {
        canSaveA = true;
      } else {
        canSaveA = false;
        break;
      }
    }
    // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classes){
    //   canSaveB = true;
    // } else {
    //   canSaveB = false;
    // }
    if (canSaveA == false) {
      errorAlert({
        title: "Tabel belum lengkap",
        errorMessage:
          "Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.",
      });
      // alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
      // } else if (canSaveB == false){
      //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    } else {
      await handleSaveListStudentEkskul(value);
      history.push(
        "/dashboard/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul"
      );
    }
  }

  // on formchange untuk form
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { form } = this.state;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (name === "ekskul_id") {
            let newListEkskul = form.value.daftar_ekskul;
            newListEkskul[arrayPosition] = {...newListEkskul[arrayPosition], ekskul_day: []};
            this.setState((prevState) => ({
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  daftar_ekskul : newListEkskul,
                }
              }
            }));
          }

          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "ekskul_id") {
          this._getEkskulDayOptions({ filters: { ekskul_id: value }, idx : arrayPosition });
        }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { daftar_ekskul } = form.value;

    daftar_ekskul.push({
      ekskul_id: "",
      ekskul_day: "",
      status: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          daftar_ekskul,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { daftar_ekskul } = prevState.form.value;
      const list = prevState.form.value.daftar_ekskul;
      const listday = prevState.param.ekskul_day;

      list.splice(idx, 1);
      listday.splice(idx, 1);
      return {
        ...prevState,
        daftar_ekskul: list,
        param: {
          ...prevState.param,
          ekskul_day: listday,
        },
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newpelajaran = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newpelajaran = prevState.form.value.daftar_ekskul;
        newpelajaran[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { daftar_ekskul: newpelajaran }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  deletepelajaran(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ people: array });
    }
  }

  render() {
    const { form, param } = this.state;
    const { daftar_ekskul } = form.value;
    
    return (
      <div className="add-budget">
        <h1>Buat Data Peserta Ekskul</h1>
        <hr></hr>

        {/* <p>Pembagian Kelas Ekskul Untuk {form.value.nis} - {form.value.name} 2019 - 2020</p> */}

        <BuatDaftarEkskulForm
          form={form}
          onAddList={this._onClickAdd}
          list={daftar_ekskul}
          onDeleteList={this._onDeleteList}
          //deletepelajaran={this._deletepelajaran}
          onFormChange={this._onFormChange}
          param={param}
          //items={this.state.items} _handleDelete={this.delete.bind(this)}
        />
        <div className="student-list__button2">
          {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
          )}
        </div>
      </div>
    );
  }

  //   render() {
  //     return (
  //         <ul>
  //             {this.props.items.map(item => (
  //                 <li key={item.id}>{item.text}<button onClick={this._handleDelete.bind(this, item.id)}>Delete</button></li>
  //             ))}
  //         </ul>
  //     );
  // }
}
BuatDaftarEkskul.propTypes = {
  handleAddBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
BuatDaftarEkskul.defaultProps = {
  handleAddBudget: noop,
  user: null,
};
