import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';


export default class LaporanAbsensiKelasForm extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                nis:'',
                tanggal:'',
                model8355: '',
                classes_year:'',
                status_siswa:'',
            },
            error: {
                classes: '',
                nis:'',
                tanggal:'',
                model8355: '',
                classes_year:'',
                status_siswa:'',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes, levels } = this.props;
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
          <Select
              name="bentuk"
              label="Bentuk"
              onChange={this._onFormChange}
              placeholder={'Pilihan'}
              value={form.value.bentuk}
              error={form.error.bentuk || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="classes_year"
              label="Tahun Ajaran"
              onChange={this._onFormChange}
              value={form.value.classes_year}
              error={form.error.classes_year || ''}
              placeholder={"Pilihan"}
            />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
            <Select
              name="rinci_perbulan"
              label="Rinci Per Bulan"
              onChange={this._onFormChange}
              placeholder={'Pilihan'}
              value={form.value.rinci_perbulan}
              error={form.error.rinci_perbulan || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="classes"
              label="Tingkat Kelas"
              onChange={this._onFormChange}
              data={ classes.list}
              placeholder={'Semua Kelas'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Input
            type="date"
            name="from"
            label="Dari Tanggal"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.from}
            error={form.error.from || ''}
          />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
          <Input
            type="date"
            name="to"
            label="Sampai Tanggal"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.to}
            error={form.error.to || ''}
          />
          </div>
      </div>
      
     
  </div>
          
          
          
   
     
    );
}
  
}
LaporanAbsensiKelasForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
LaporanAbsensiKelasForm.defaultProps = {
  onChange: noop,
  classes: [],
};
