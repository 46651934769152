import { connect } from 'react-redux';
import TablePrestasi from '../../../views/Student/displiner/TablePrestasi.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {listAchievementTable, deleteAchievementTable } from '../../../states/thunks/academics.thunk';
import { getAchievementOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAchievementOptions: (payload) => dispatch(getAchievementOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListAchievementTable: (payload, goback) => dispatch(listAchievementTable(payload, goback)),
    handleDeleteAchievementTable: (payload, goback) => dispatch(deleteAchievementTable(payload, goback)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(TablePrestasi);


