import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { PERSONAL_DISCONTINUANCE_DESCRIPTION_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class DiscontinuanceDescription extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/surat/tambah-keterangan-pemberhentian');
  }

  render() {
    const { handleGetDiscontinuanceDescription } = this.props;
    return (
      <div className="discontinuance-description">
        <Button
          title="Tambah Surat Keterangan Pemberhentian"
          onClick={this.onClickAdd}
        />
        <div className="discontinuance-description__content">
          <ContentTable
            onSearchContent={handleGetDiscontinuanceDescription}
            tableFields={PERSONAL_DISCONTINUANCE_DESCRIPTION_TABLE_FIELDS}
          />
        </div>
      </div>
    );
  }
}
DiscontinuanceDescription.propTypes = {
  handleGetDiscontinuanceDescription: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DiscontinuanceDescription.defaultProps = {
  handleGetDiscontinuanceDescription: noop,
};
