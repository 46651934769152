import { connect } from 'react-redux';
import DailyAndMonthly from '../../../views/Finance/Report/DailyAndMonthly.report.view';
import { getMonthlyReport } from '../../../states/thunks/report.thunk';
import { getCodeGroupOptions } from '../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    code_group: state.options.code_group,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetMonthlyReport: payload => dispatch(getMonthlyReport(payload)),
    handleGetCodeGroupOptions: payload => dispatch(getCodeGroupOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyAndMonthly);
