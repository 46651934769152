import { connect } from 'react-redux';
import Klapper from '../../../views/Student/report/Klapper.student.view';
import { getKlapper } from '../../../states/thunks/student.thunk';
import {getClassesOptions, getParamOptionsAcademics, getStudentOptions, getClassroomOptions, getNewStudentOptions, getPrmClassesOptions, getPeriodsOptions } from '../../../states/thunks/options.thunk';
import { listKlapper } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetKlapper: () => dispatch(getKlapper()),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListKlapper: payload => dispatch(listKlapper(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Klapper);
