import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budget from '../../../assets/img/finance/anggaran.png';
import nonBudget from '../../../assets/img/finance/pengalihan_dana.png';
import budgetRequest from '../../../assets/img/finance/permohonan_dana.png';
import realization from '../../../assets/img/finance/realisasi.png';

export default class Budgeting extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/penganggaran/${link}`);
  }

  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }

  render() {
    return (
      <div className="bookkeeping">
        <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('anggaran')}>
            <img src={budget} alt="budget" />
            <h3>Anggaran</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.getClickHandler('permohonan-dana')}>
            <img src={budgetRequest} alt="budget request" />
            <h3>Permohonan Dana</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.getClickHandler('realisasi')}>
            <img src={realization} alt="realization" />
            <h3>Realisasi</h3>
          </button>
        </div>
      </div>
    );
  }
}
Budgeting.propTypes = {
  history: PropTypes.object.isRequired,
};
