import LembarCatatanAnekdot from '../../../views/Kurikulum/Ulangan/LembarCatatanAnekdot.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editLembarObsHarian, getLembarObsHarian, listTemaCatatanAnekdot } from '../../../states/thunks/academics.thunk';
import { getPeriodsOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';


function mapStateToProps(state){
    return{
        classes: state.options.classes,
        result: state.result,
        user: state.user || {},
        levels: state.options.levels,
    }
}

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditLembarObsHarian: (payload, goBack) => dispatch(editLembarObsHarian(payload, goBack)),
        handleGetLembarObsHarian: payload => dispatch(getLembarObsHarian(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        handleListTemaCatatanAnekdot: (payload) => dispatch(listTemaCatatanAnekdot(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (LembarCatatanAnekdot);