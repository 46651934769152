import { connect } from 'react-redux';
import ManagePaymentJournal from '../../../../views/Finance/Bookkeeping/PaymentJournal/ManagePaymentJournal.finance.view';
import { getPaymentJournalById, managePaymentJournal } from '../../../../states/thunks/finance.thunk';
import { getCoa } from '../../../../states/thunks/common.thunk';

function mapStateToProps(state) {
  return {
    coa: state.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoa(payload)),
    handleGetPaymentJournal: payload => dispatch(getPaymentJournalById(payload)),
    handleManagePaymentJournal: (payload, goBack) => (
      dispatch(managePaymentJournal(payload, goBack))
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePaymentJournal);
