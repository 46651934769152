import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_HARIAN } from "../../../constants/student.constant";
import { ABSENSI_HARIAN_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { Button } from "../../../components/base/index";
import { SEARCH_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import { TABLE_EKSKUL_TABLE_FIELDS } from "../../../constants/Student/ekskul.constant";
import BuatEkskulForm from "../components/BuatEkskulForm.component";
import { errorAlert } from '../../../utils/alert.util';

export default class BuatJenisEkskul extends Component {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickAddGroup = this._onClickAddGroup.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getPrmClassesOptions = this._getPrmClassesOptions.bind(this);
    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      coa: {
        loading: true,
        list: [],
      },
      form: {
        value: {
          organizations_id: organizations_id,
          ekskul_fee: 0,
        },
        error: {},
        param: {},
      },
    };
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/ekstrakulikuler/buat-jenis-ekskul", {
      data: "",
    });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran", { data: val });
  }

  _onClickAddGroup() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/displiner/list-group-pelanggaran");
  }
  componentDidMount() {
    const {
      location,
      handleGetClasses,
      handleGetLevels,
      getPrmClassesOptions,
    } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = location.state;
    const { id } = state.data || "";
    const paramTypes = ["levels", "classes"];
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    paramTypes.forEach((type) => {
      this._getParamOptions(type);
    });

    handleGetClasses();
    handleGetLevels();
    this._getPrmClassesOptions();
  }
  async _getPrmClassesOptions(filters = {}) {
    const { getPrmClassesOptions } = this.props;
    const res = await getPrmClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_classes: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetJenisEkskul } = this.props;
    try {
      const payload = await handleGetJenisEkskul({ id });

      this.setState({
        form: {
          value: {
            id: payload.id,
            code_ekskul: payload.code_ekskul,
            name: payload.name,
            output_raport_ekskul: payload.output_raport_ekskul,
            day: map(payload.day, (item) => item.value),
            class_total: payload.class_total,
            homeroom_teacher: payload.homeroom_teacher,
            ekskul_fee: payload.ekskul_fee,
            quota: payload.quota,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (type === "checkbox" && name === "day") {
        formattedValue = prevState.form.value.day || [];
        if (checked) {
          formattedValue.push(value);
        } else {
          formattedValue.splice(formattedValue.indexOf(value), 1);
        }
      }

      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }
  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list,
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveJenisEkskul } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;

    if ( dataForm.code_ekskul && dataForm.name && dataForm.output_raport_ekskul && dataForm.class_total && !isEmpty(dataForm.day) && dataForm.quota ) {
      canSaveA = true;
    } else {
      canSaveA = false;
    }

    if (canSaveA == false) {
      errorAlert({
        title: "Harap lengkapi data form",
        errorMessage:
          "Bagian yang harus diisi ditandai dengan tanda bintang (*).",
      });
    } else {
      await handleSaveJenisEkskul(value);
      history.push(
        "/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler"
      );
    }
  }

  async onEdit() {
    const { history, handleEditJenisEkskul } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;

    if ( dataForm.code_ekskul && dataForm.name && dataForm.output_raport_ekskul && dataForm.class_total && !isEmpty(dataForm.day) && dataForm.quota ) {
      canSaveA = true;
    } else {
      canSaveA = false;
    }

    if (canSaveA == false) {
      errorAlert({
        title: "Harap lengkapi data form",
        errorMessage:
          "Bagian yang harus diisi ditandai dengan tanda bintang (*).",
      });
    } else {
      const res = await handleEditJenisEkskul(value);
      history.push(
        "/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler"
      );
    }
  }

  render() {
    const { form, param } = this.state;
    const { plafon } = form.value;
    const { classes = {}, levels = {}, prm_classes = {} } = this.props;
    return (
      <div className="add-budget">
        <h1>Buat Jenis Ekskul</h1>
        <hr></hr>
        <BuatEkskulForm form={form} onFormChange={this._onFormChange} />
        <div className="add-budget">
          <br></br>
          {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
          )}
        </div>

        {/* <div className="budget__content">
              <div className="budget__search">
              <ListForm
              coa={coa}
              form={form}
              formFields={SEARCH_FIELDS}
              onFormChange={this._onFormChange}
              />
    
              </div>
              <ContentTable
                onSearchContent={handleGetKelas}
                tableFields={TABLE_PELANGGARAN_TABLE_FIELDS}
                onClickEdit={this._onClickEdit}
                onClickDelete={handleDeleteKelas}
              />
            </div> */}
      </div>
    );
  }
}
BuatJenisEkskul.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetClasses: PropTypes.func,
  handleGetLevels: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  handleGetClassData: PropTypes.func,
  history: PropTypes.object.isRequired,
  getPrmClassesOptions: PropTypes.func,
};
BuatJenisEkskul.defaultProps = {
  handleGetClasses: noop,
  handleGetLevels: noop,
  getParamOptions: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
  getPrmClassesOptions: noop,
  handleGetClassData: noop,
};
