import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  noop, isEmpty, filter,
  findIndex,
} from 'lodash';
import BudgetSourceForm from './components/BudgetSourceForm.component';
import BudgetCheckList from './components/BudgetCheckList.component';
import RapbuForm from './components/RapbuForm.component';
import { commaFormatted, editBudgetTransfer } from '../../../../utils/transformer.util';
import { Button } from '../../../../components/base';
import { validateBudgetTransfer } from '../../../../utils/validation.util';
import { errorAlert } from '../../../../utils/alert.util';

export default class ManageBudgetTransfer extends Component {
  constructor(props) {
    super(props);
    this._setForm = this._setForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddRow = this._onAddRow.bind(this);
    this._onDeleteRow = this._onDeleteRow.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getCoaOptions = this._getCoaOptions.bind(this);
    this.state = {
      form: {
        value: {
          rapbuSource: [],
        },
        error: {
          rapbuSource: [],
        },
      },
      budgets: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { data = {} } = location.state;

    if (!isEmpty(data)) {
      this._setForm(data);
    }
  }

  _getCoaOptions(keyword = null, head = null) {
    const { handleGetCoa } = this.props;
    const { form } = this.state;
    const { value } = form;

    handleGetCoa({
      head: (!isEmpty(head)) ? head.id : value.head.id,
      keyword,
    });
  }

  async _setForm(data) {
    const { handleGetBudgetCheckList } = this.props;
    const { handleGetBudgetTransferById } = this.props;
    const res = await handleGetBudgetTransferById(data.id);
    const budgets = await handleGetBudgetCheckList({
      code_of_account: Number(res.account),
      type: 'account',
      head: res.head.unique_id,
    });
    this.setState({
      form: {
        value: editBudgetTransfer(res),
        error: {
          rapbuSource: [],
          selectedBudget: [],
        },
      },
      budgets,
    });
  }

  async _onFormChange(event, fieldName) {
    const { form } = this.state;
    const { head } = form.value;
    const {
      name,
      value,
      dataset,
      type,
      checked,
    } = event.target;
    const {
      inputType = 'text', arrayPosition = 0,
      inputBudget = false, inputRapbu = false, inputArray = false,
    } = dataset;
    const { handleGetBudgetCheckList } = this.props;
    if (name === 'head') {
      this._getCoaOptions(null, value);
    }
    if (name === 'code_of_account') {
      const res = await handleGetBudgetCheckList({
        code_of_account: Number(value.code),
        type: 'account',
        head: head.id,
      });
      this.setState(prevState => ({
        form: {
          value: {
            ...prevState.form.value,
            selectedBudget: [],
          },
          error: {
            ...prevState.form.error,
            selectedBudget: [],
          },
        },
        budgets: !isEmpty(value.title) ? res : [],
      }));
    }
    this.setState((prevState) => {
      let newValue = [];
      let newError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (type === 'checkbox') {
        if (checked) {
          formattedValue = prevState.form.value.selectedBudget || [];
          formattedValue.push(prevState.budgets[arrayPosition]);
        } else {
          const newList = prevState.form.value.selectedBudget || [];
          const index = findIndex(newList, list => list.id === prevState.budgets[arrayPosition].id);
          newList.splice(index, 1);
          formattedValue = [...newList];
        }
      }
      if (inputBudget) {
        newValue = prevState.form.value[fieldName];
        newError = prevState.form.error[fieldName];
        const index = findIndex(newValue, list => (
          list.id === prevState.budgets[arrayPosition].id
        ));
        newValue[index][name] = formattedValue;
        if (!isEmpty(newError[index])) {
          newError[index][name] = '';
        }
      }

      if (inputRapbu || inputArray) {
        newValue = prevState.form.value[fieldName];
        newError = prevState.form.error[fieldName];
        newValue[arrayPosition][name] = formattedValue;
        if (!isEmpty(newError[arrayPosition])) {
          newError[arrayPosition][name] = '';
        }
      }

      const error = {
        ...prevState.form.error,
        totalTransferError: '',
        ...(inputBudget || inputRapbu || inputArray
          ? { [fieldName]: newError }
          : { [name]: '' }),
      };

      if (fieldName) {
        error[fieldName] = filter(error[fieldName], (err) => {
          if (err) {
            return err.length;
          }
          return {};
        });
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputBudget || inputRapbu || inputArray
              ? { [fieldName]: newValue }
              : { [name]: formattedValue }),
          },
          error,
        },
      };
    });
  }

  _onAddRow(field) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [field]: [
            ...prevState.form.value[field],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [field]: [
            ...prevState.form.error[field],
            {},
          ],
        },
      },
    }));
  }

  _onDeleteRow(field, idx) {
    this.setState((prevState) => {
      const list = prevState.form.value[field];
      const listError = prevState.form.error[field];
      list.splice(idx, 1);
      listError.splice(idx, 1);
      return {
        form: {
          value: {
            ...prevState.form.value,
            [field]: [...list],
          },
          error: {
            ...prevState.form.error,
            [field]: [...listError],
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleSaveBudgetTransfer,
      history,
    } = this.props;
    const error = validateBudgetTransfer(form.value);
    const selectedBudgetErr = filter(error.selectedBudget, value => Object
      .keys(value).length !== 0);
    if (isEmpty(selectedBudgetErr)) {
      error.selectedBudget = filter(error.selectedBudget, value => Object.keys(value).length !== 0);
    }
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (!isEmpty(error.semesterOne) || !isEmpty(error.semesterTwo)) {
      errorAlert({
        title: 'Data Ada Yang Salah',
      });
    } else {
      handleSaveBudgetTransfer(form.value, history.goBack);
    }
  }

  render() {
    const { form, budgets } = this.state;
    const { rapbuSource } = form.value;
    const { rapbuSource: rapbuSourceErrors, totalTransferError } = form.error;
    const { location, coa, handleGetDetailHead } = this.props;
    const { isEdit = false } = location.state;
    return (
      <div className="manage-budget-transfer">
        <h1>
          {
            isEdit ? 'Edit Pengalihan Anggaran' : 'Buat Pengalihan Anggaran'
          }
        </h1>
        <form onSubmit={this._onSubmit}>
          <BudgetSourceForm
            form={form}
            coa={coa}
            isEdit={isEdit}
            onFormChange={this._onFormChange}
            handleSearchHead={handleGetDetailHead}
            onSearchCoa={this._getCoaOptions}
          />
          {
            !isEmpty(budgets) && (
              <>
                <BudgetCheckList
                  error={form.error.selectedBudget}
                  budgets={budgets}
                  onChange={this._onFormChange}
                  selectedBudget={form.value.selectedBudget}
                  amount={form.value.amount}
                />
                <RapbuForm
                  coa={coa}
                  list={rapbuSource}
                  error={rapbuSourceErrors}
                  totalTransferError={totalTransferError}
                  onAddRow={this._onAddRow}
                  onDeleteRow={this._onDeleteRow}
                  onChange={this._onFormChange}
                />
                <div className="manage-fund-request">
                  <Button
                    type="submit"
                    title="Simpan"
                  />
                </div>
              </>
            )
          }
        </form>
      </div>
    );
  }
}
ManageBudgetTransfer.propTypes = {
  handleGetCoa: PropTypes.func,
  handleGetDetailHead: PropTypes.func,
  handleGetBudgetCheckList: PropTypes.func,
  handleSaveBudgetTransfer: PropTypes.func,
  handleGetBudgetTransferById: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
};
ManageBudgetTransfer.defaultProps = {
  handleGetCoa: noop,
  handleGetDetailHead: noop,
  handleGetBudgetCheckList: noop,
  handleSaveBudgetTransfer: noop,
  handleGetBudgetTransferById: noop,
};
