import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input, Select, Textarea } from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";

export default function LembarHarianForm2Man(props) {
  const { onFormChange, form } = props;
  const choseone = [
    { label: "BB - Belum Berkembang", value: "belum_berkembang" },
    { label: "MB - Mulai Berkembang", value: "mulai_berkembang" },
    { label: "BSH - Berkembang Sesuai Harapan", value: "berkembang_sesuai_harapan" },
    { label: "BSB - Berkembang Sangat Baik", value: "berkembang_sangat_baik" },
  ];
  // const pengisian = [
  //   { label: 'Sikap', value: 'sikap'},
  //   { label: 'Kognitif', value: 'kognitif'},
  // ]
  return (
    <div className="lembar-harian2__custom-form">
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p><b>️生词 KOSAKATA</b></p>
          </div>
          <Textarea
            type="text"
            name="obs_result_vocab"
            value={form.value.obs_result_vocab}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
              style={{backgroundColor: 'yellow'}}

          />
        </div>
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p><b>发音 PELAFALAN</b></p>
          </div>
          <Textarea
            type="text"
            name="obs_result_pronunciation"
            value={form.value.obs_result_pronunciation}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
              style={{backgroundColor: 'yellow'}}

          />
        </div>
      </div>
      
      <br/>
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p><b>听力 MENDENGARKAN</b></p>
          </div>
          <Textarea
            type="text"
            name="obs_result_listen"
            value={form.value.obs_result_listen}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
              style={{backgroundColor: 'yellow'}}

          />
        </div>
        <div className="lembar-harian2__custom-form-width__field">
          <div className="cetak-buku-induk__title">
            <p><b>写字 MENULIS</b></p>
          </div>
          <Textarea
            type="text"
            name="obs_result_write"
            value={form.value.obs_result_write}
            label="Hasil Observasi "
            onChange={onFormChange}
            rows={5}
              style={{backgroundColor: 'yellow'}}

          />
        </div>
      </div>

      
      {/* <div className="lembar-harian2__title">
        <h1>Catatan Guru</h1>
      </div>
      <Textarea
        type="text"
        name="teacher_notes"
        value={form.value.teacher_notes}
        onChange={onFormChange}
      /> */}
    </div>
  );
}
LembarHarianForm2Man.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LembarHarianForm2Man.defaultProps = {
  onFormChange: noop,
};
