import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import JadwalKelasForm from './components/JadwalKelasForm.component';
import JadwalKelasTable from './components/JadwalKelasTable.component';

export default class TambahJadwalKelas extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getTeacherOptions = this._getTeacherOptions.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    
    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      form: {
        value: {
          name: '',
          description:'',
          publish_date: '',
          tgl_selesai: '',
          levels: '',
          classrooms: [],
          students:[],
          teachers:[],
          organizations_id: 12,
          pelajaran: [
            {
              date: '',
              activities_name: '',
              teacher_id: '',
              start_hour: '',
              start_minute: '',
              end_hour: '',
              end_minute: '',
            }
          ],
        },
        error: {
          pelajaran: '',
        },
      },
      param: {},
    };
  }
  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit = false, data} = location.state;
    const { id } = state.data || '';
   
    const paramTypes = ['levels', 'classrooms'];
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getStudentOptions();
    this._getTeacherOptions();
    // if(id) {
    //   this.setState({id:id})
    //   this._handleLoadData(id)
    // }
  }

  async _setForm(id) {
    const { handleGetSchedule, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetSchedule({ id });
      console.log(payload)
      const {classrooms, levels} = payload;
      this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: levels, organizations_id: 12 }} );
      this._getStudentOptions({ filters: { classrooms_id: classrooms.map(item => item.value)}} );
      this.setState({
        form: {
          value: {
            id: payload.id,
            name: payload.name,
            description: payload.description,
            publish_date: payload.publish_date,
            tgl_selesai: payload.tgl_selesai,
            levels: payload.levels,
            classrooms: payload.classrooms,
            students: payload.students,
            teachers: payload.teachers,
            upload: payload.upload,
            pelajaran: map(payload.pelajaran, detail => ({
              date: detail.date,
              activities_name: detail.activities_name,
              teacher_id: detail.teacher_id,
              start_hour: detail.start_hour,
              start_minute: detail.start_minute,
              end_hour: detail.end_hour,
              end_minute: detail.end_minute,
            })),
          },
          error: {
            pelajaran: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  // async _handleLoadData(id){
  //   const { getData } = this.props;
  //   this.setState({
  //     form:{
  //       value: this.props.location.state.data,
  //       error: []
  //     }
  //   },() => console.log(this.state)); 
  // }
  // getData = async(id) => {
  //   const { handleListSchedule } = this.props;
  //   const result = await handleListSchedule(id);
  //   this.setState({
  //     form:{
  //       value: result[id],
  //       error: []
  //     }
  //   });
  // }
  componentDidUpdate(prevProps, prevState) {
    const { form: prevForm } = prevState;
    const { value: prevValue } = prevForm;
    const { form } = this.state;
    const { value } = form;
   
    const paramType = ['levels', 'classrooms', 'teachers', 'subjects'];
    // paramType.forEach((type) => {
    //   let filter = '';
    //   let paramName = '';
    //   if (prevValue[type] !== value[type]) {
    //     if (type === 'levels') {
    //       filter = 'levels_id';
    //       paramName = 'levels';
    //     // if (type === 'units') {
    //     //   filter = 'units_id';
    //     //   paramName = 'classrooms'
    //     // } else if (type === 'levels') {
    //     //   filter = 'levels_id';
    //     //   paramName = 'classrooms';
    //     } else if (type === 'classrooms') {
    //       filter = 'classrooms_id';
    //       paramName = 'classrooms';
    //     } 
      
    //      else if (type === 'teachers') {
    //       filter = 'teachers_id';
    //       paramName= 'teachers';
    //     } else {
    //       filter = 'subjects_id';
    //       paramName= 'subjects';
    //     }
    //     this.getParamOptionsAcademics(paramName, { filters: { [filter]: value[type] } });
    //   }
    // });
  }

  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
   
  }

  async _getTeacherOptions() {
    const { getTeacherOptions } = this.props;
    const res = await getTeacherOptions();
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher: res,
      },
    }));
   
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics } = this.props;
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { pelajaran } = form.value;
    pelajaran.push({
      date: '',
      activities_name: '',
      teacher_id: '',
      start_hour: '',
      start_minute:'',
      end_hour: '',
      end_minute: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          pelajaran,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  async onSubmit() {
    const { history, handleSaveSchedule } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    // for(a=0; a<dataTabel.length; a++){
    //   if(dataTabel[a].activities_name && dataTabel[a].date && dataTabel[a].end_hour && dataTabel[a].end_minute && dataTabel[a].start_hour && dataTabel[a].start_minute && dataTabel[a].teacher_id){
    //     canSaveA = true;
    //   } else{
    //     canSaveA = false;
    //     break;
    //   }
    // }
    // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms){
    //   canSaveB = true; 
    // } else {
    //   canSaveB = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
    // } else if (canSaveB == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
      await handleSaveSchedule(value);
      history.push('/dashboard/kurikulum/jadwal/jadwal-per-kelas');
    // }
  }
  async onEdit() {
    const { history, handleEditSchedule } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    // for(a=0; a<dataTabel.length; a++){
    //   if(dataTabel[a].activities_name && dataTabel[a].date && dataTabel[a].end_hour!=="" && dataTabel[a].end_minute!=="" && dataTabel[a].start_hour!=="" && dataTabel[a].start_minute!=="" && dataTabel[a].teacher_id){
    //     canSaveA = true;
    //   } else{
    //     canSaveA = false;
    //     break;
    //   }
    // }
    // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms && dataForm.students){
    //   canSaveB = true;
    // } else {
    //   canSaveB = false;
    // }
   
    // if (canSaveA == false){
    //   alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
    // }
    // else if (canSaveB == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
      await handleEditSchedule(value);
      history.push('/dashboard/kurikulum/jadwal/jadwal-per-kelas');
    // }
   
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { pelajaran } = prevState.form.value;
      const list = prevState.form.value.pelajaran;
      list.splice(idx, 1);
      return {
        ...prevState,
        pelajaran: list
      };
    });
  }
  _onClickSubmit() {
    const { history } = this.props;
    //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
    //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-k13');
    //history.push('/dashboard/kurikulum/laporan/analisa-nilai-siswa');
    //history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
  }

  onRemoveFile() {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            upload: '',
            file:'',
          }
        }
      }
    });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;          
      if(type==="file"){
        formattedValue = files[0]
      }
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels') {
        this._getParamOptionsAcademics( 'classrooms', { levels_id: value, organizations_id: 12 } );
      }
      if (name === 'classrooms') {
        this._getStudentOptions({ filters: { classrooms_id: value.map(item => item.value) }} );
      }
    });
  }


  render() {
    const { form, param, file, name, publish_date, tgl_selesai, levels, classrooms } = this.state;
    const { handleGetKelas, handleUploadScheduleFile, location } = this.props;
    const { pelajaran } = form.value;
    const { isEdit = true, data } = location.state;
    console.log(this.state)
    return (
      <div className="student-list">
        <form>
          <h1>Jadwal Per Kelas & Karyawan</h1>
          <JadwalKelasForm
            form={form}
            onFormChange={this._onFormChange}
            onRemoveFile={this.onRemoveFile}
            param={param}
            handleUploadScheduleFile={handleUploadScheduleFile}
          />
          <br></br>
          <JadwalKelasTable
            list={pelajaran}
            param={param}
            onAddList={this._onClickAdd}
            onFormChange={this._onFormChange}
            onDeleteList={this._onDeleteList}
          />
          <div className="manage-registration__footer">
            {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
            }
          </div>
        </form>
      </div>
    );
  }
}
TambahJadwalKelas.propTypes = {
  getParamOptionsAcademics: PropTypes.func,
  handleGetSchedule: PropTypes.func,
  getStudentOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahJadwalKelas.defaultProps = {
  getStudentOptions: noop,
  getParamOptionsAcademics: noop,
  handleGetStudent: noop,
  handleGetSchedule: noop,
};
