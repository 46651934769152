import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { Input, Select, Button } from '../../../../components/base/index';
import IndividualForm from './components/IndividualForm.component';
import IndividualTable from './components/IndividualTable.component';

export default class KelompokKls extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
      componentDidMount() {
        const { handleGetCodeOfAccount, location, handleGetClasses } = this.props;
       handleGetClasses();
    
     
      }
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/keuangan/spp/create-invoice/proses-invoice');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { value, error } = form;
        const { classes ={}} = this.props;
        return (
          <div className="student-list">
               <div className="budget__title">
                  <h1>B. Create Invoice Kelompok/Kelas</h1>
                  <hr></hr>
                </div>
                   <div className="nilai-kognitif__custom-form">
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Select
                          name="jenis"
                          label="Jenis Invoice"
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                          // data={classes.list}
                          //value={value.jenis}
                          placeholder={'Pilihan'}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                          name="buat_invoice"
                          label="Buat Invoice Periode"
                          onChange={this._onFormChange}
                          // value={value.buat_invoice}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Pilihan'}
                        />
                      </div>
                      </div>

                      <div className="nilai-kognitif__custom-form-row">
                        <div className="nilai-kognitif__custom-form-row__field">
                          <Select
                            name="jenis_matauang"
                            label="Jenis Mata Uang"
                            onChange={this._onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
                            // value={value.buat_invoice}
                            // error={form.error.namaSaudara1 || ''}
                          />
                        </div>
                        <div className="nilai-kognitif__custom-form-column__field">
                          <Select
                              name="classes_year"
                              label="Tahun Ajaran"
                              placeholder="2019-2020"
                              onChange={this._onFormChange}
                              value={value.classes_year || ''}
                              error={form.error.classes_year || ''}
                            />
                      </div>
                      </div>
                      <div className="nilai-kognitif__custom-form-row">
                        <div className="nilai-kognitif__custom-form-row__field">
                          <Select
                            name="classes"
                            label="Kelas"
                            onChange={this._onFormChange}
                            data={ classes.list}
                            placeholder={'Pilihan'}
                            value={value.classes}
                            error={error.classes || ''}
                          />
                        </div>
                        <div className="nilai-kognitif__custom-form-column__field">
                          <Select
                              type='text'
                              name="statuu"
                              label="Status Invoice"
                              placeholder={'Pilihan'}
                              onChange={this._onFormChange}
                              value={form.value.tempatLahirSaudara1 || ''}
                              error={form.error.tempatLahirSaudara1 || ''}
                            />
                      </div>
                      </div>
                      <div className="nilai-kognitif__custom-form-row">
                        <div className="nilai-kognitif__custom-form-row__field">
                          <Select
                            name="rincian"
                            label="Rincian INV"
                            onChange={this._onFormChange}
                            data={ '7A', 'kelas7a'}
                            placeholder={'Pilihan'}
                            value={form.value.tempatLahirSaudara1 || ''}
                            error={form.error.tempatLahirSaudara1 || ''}
                          />
                        </div>
                        <div className="nilai-kognitif__custom-form-column__field">
                          <Input
                              type='date'
                              name="tanggal_create"
                              label="Tanggal Create INV"
                              onChange={this._onFormChange}
                              value={form.value.tanggal_create || ''}
                              error={form.error.tanggal_create || ''}
                            />
                      </div>
                      </div>
                    </div>

                      <div className="manage-registration__footer">
                          <Button
                          onClick={this._onSubmit}
                          title="Proses Invoice"
                          />
                      </div>
                     
          </div>
        );
      }
}
KelompokKls.propTypes = {
  handleGetClasses: PropTypes.func, 
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
KelompokKls.defaultProps = {
  handleGetClasses: noop,
  handleGetStudent: noop,
};
