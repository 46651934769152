import { connect } from 'react-redux';
import TambahIdentitas from '../../../views/Akademi/DataKelas/TambahIdentitas.view';
import { getParamOptions, getSubDistricts2 } from '../../../states/thunks/options.thunk';
import { getProvinces, getCities, getDistricts, getSubDistricts} from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    provinces: state.options.provinces,
    cities: state.options.cities,
    districts: state.options.districts,
    sub_districts: state.options.sub_districts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetProvinces: payload => dispatch(getProvinces(payload)),
    handleGetCities: payload => dispatch(getCities(payload)),
    handleGetDistricts: payload => dispatch(getDistricts(payload)),
    handleGetSubDistricts: payload => dispatch(getSubDistricts(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahIdentitas);
