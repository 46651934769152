import { connect } from 'react-redux';
import Kelola from '../../../views/Akademi/DataKelas/Kelola.view';
import { addBudget } from '../../../states/thunks/finance.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
    handleGetClasses: payload => dispatch(getClasses(payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Kelola);