import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import rootReducer from './reducers';

const enhancers = [];
const { devToolsExtension } = window;
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const enhancer = compose(applyMiddleware(thunk, promise), ...enhancers);

const persistConfig = {
  key: 'root',
  storage,
};


function initStore() {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, {}, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}

export default initStore;
