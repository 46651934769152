import { connect } from 'react-redux';
import CashJournalReport from '../../../views/Finance/Report/CashJournal.report.view';
import { getCashJournalReport } from '../../../states/thunks/report.thunk';

function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCashJournalReport: payload => dispatch(getCashJournalReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CashJournalReport);
