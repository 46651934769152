import { connect } from 'react-redux';
import ManageBudget from '../../../../views/Finance/Budgeting/Budget/ManageBudget.finance.view';
import { getCoa, getCoaOptions, uploadFile, downloadFile } from '../../../../states/thunks/common.thunk';
import {
  getBudgetDetail, addBudgetDetail, editBudgetDetail, deleteBudgetDetail,
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    coa: state.coa,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetCoa: payload => dispatch(getCoa(payload)),
    handleGetBudgetDetail: payload => dispatch(getBudgetDetail(payload)),
    handleAddBudgetDetail: (payload, status) => dispatch(addBudgetDetail(payload, status)),
    handleEditBudgetDetail: (payload, status) => dispatch(editBudgetDetail(payload, status)),
    handleDeleteBudgetDetail: (payload, status) => dispatch(deleteBudgetDetail(payload, status)),
    handleFileUpload: (file) => dispatch(uploadFile(file)),
    handleFileDownload: (filename) => dispatch(downloadFile(filename)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudget);
