import { connect } from 'react-redux';
import HistoryKonseling from '../../views/Student/HistoryKonseling.student.view';
import { getStudent } from '../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(HistoryKonseling);
