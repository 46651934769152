import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select, Checkbox } from '../../../../components/base';

export default class PengisianKDForm_part2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        
    }

    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteList(idx) {
        const { onDeleteList, fieldName } = this.props;
        onDeleteList(idx, fieldName);
    }

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }

    render(){
        const {
            list, error, onChange,
            fieldName,
        } = this.props;

        return (
            <div>
                <h1 align="center" >PENGISIAN KOMPENTENSI DASAR (KD) / TEMA KE - {this.props.tema} </h1>
                <h2>Materi KD / Tema</h2>
                <div className="nilai-kognitif__custom-form-row__field">
                    <Input
                        type='text'
                        name="namaSaudara1"
                        style={{backgroundColor: 'yellow'}}
                        onChange={this._onFormChange}
                    />
                </div>
                <h2>Indikator KD / Tema</h2>
                <table className="pengisian-kd__form-table">
                    <thead>
                        <tr>
                            <th>KD</th>
                            <th>Bukti Belajar</th>
                            <th>KKM KD</th>
                            <th>Bobot</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            map(list, (data, idx) => (
                                <tr>
                                <td style={{backgroundColor: 'yellow'}}></td>
                                <td style={{backgroundColor: 'yellow'}}></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <Button onClick={() => this._onDeleteList(idx)}>Delete</Button>
                                </td>
                            </tr>
                            ))
                        }
                            <tr className="manage-rapbs-plafon__add-more">
                                <td colSpan={7}>
                                <div>
                                    <Button
                                        icon="icon-plus"
                                        onClick={this._onAddList}
                                    />
                                </div>
                                </td>
                            </tr>
                    </tbody>
                </table>

                <table className="pengisian-kd__form-table ">
                    <thead>
                        <tr>
                            <th rowSpan={'2'}>Bentuk Penilaian</th>
                            <th rowSpan={'2'}>Ada</th>
                            <th colSpan={'2'}>Tanggal Penilaian</th>
                        </tr>
                        <tr>
                            <th>Kelas</th>
                            <th>Kelas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td align={'center'}>Ulangan / Quiz</td>
                        <td align={'center'}>
                            <Checkbox
                                name="ulangan/kuis"
                                onChange={this._onFormChange}
                                checked='false'
                            />
                        </td>
                        <td></td>
                        <td></td>
                    </tbody>
                </table>
            </div>
        );
    }
}