import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../components/base';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { ADMISSION_TABLE_FIELDS } from '../../constants/admission.constant';

export default class Result extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/admission/buat-pendaftaran', { name: '' });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/admission/buat-pendaftaran', { data: val });
  }

  render() {
    const { handleGetResults, handleDeleteAdmission } = this.props;
    return (
      <div className="result">
        <Button
          title="Buat Pendaftaran"
          onClick={this.onClickAdd}
        />
        <div className="result__content">
          <ContentTable
            showActions={false}
            onSearchContent={handleGetResults}
            tableFields={ADMISSION_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickDelete={handleDeleteAdmission}
          />
        </div>
      </div>
    );
  }
}
Result.propTypes = {
  handleGetResults: PropTypes.func,
  handleDeleteAdmission: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Result.defaultProps = {
  handleGetResults: noop,
  handleDeleteAdmission: noop,
};
