export const FINANCE_ADD_FUND_REQUEST_FORM_FIELDS = [{
  type: 'text',
  name: 'tanggal',
  label: 'Tanggal',
  placeholder: 'Pilih tanggal',
  full: true,
}, {
  type: 'text',
  name: 'code',
  label: 'Kode Akun',
  placeholder: 'Isi kode akun',
  full: true,
}];

export const FUND_REQUEST_REQUIRED_FIELD = ['selectedBudget', 'amount'];

export const FINANCE_FUND_REQUEST_TABLE_FIELDS = {
  label: [
    'No',
    'No. Permohonan',
    'Tanggal',
    'Periode',
    'Jumlah Permohonan',
    'Status',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'nomor_permohonan' },
    { type: 'date', attribute: 'created_at' },
    { type: 'string', attribute: 'periode' },
    { type: 'number', attribute: 'amount' },
  ],
};
