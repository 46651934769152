import { connect } from 'react-redux';
import PembagianKelasEkskul from '../../../views/Student/ekskul/PembagianKelasEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getStudentOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { listClassEkskul, deleteClassEKskul } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
  return{
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    handleListClassEkskul: (payload, goBack) => dispatch(listClassEkskul(payload, goBack)),
    handleDeleteClassEKskul: (payload, goback) => dispatch(deleteClassEKskul(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PembagianKelasEkskul);


