import { connect } from 'react-redux';
import DaftarPindahUnit from '../../../views/Akademi/DataKelas/DaftarPindahUnit.view';
import { getCodeOfAccount } from '../../../states/thunks/common.thunk';
import { getUnitsOptions, getParamOptionsAcademics, getNewStudentOptions } from '../../../states/thunks/options.thunk';
import { listDaftarPindahUnitSiswa, savePindahUnits, getClassDiv, getClassData, deleteSwitchUnit } from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUnitsOptions: (payload) => dispatch(getUnitsOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetClassDiv: payload => dispatch(getClassDiv(payload)),
    handleGetClassData: payload => dispatch(getClassData(payload)),
    handlelistDaftarPindahUnitSiswa: (payload, goback) => dispatch(listDaftarPindahUnitSiswa(payload, goback)),
    handlesavePindahUnits: (payload, goback) => dispatch(savePindahUnits(payload, goback)),
    getNewStudentOptions: (payload) => dispatch(getNewStudentOptions(payload)),
    handleDeleteSwitchUnit: (payload, goback) => dispatch(deleteSwitchUnit(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPindahUnit);
