import { connect } from 'react-redux';
import DiscontinuanceDescription from '../../../views/Personnel/Letter/DiscontinuanceDescription.letter.view';
import { getDiscontinuanceDescription } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetDiscontinuanceDescription: payload => dispatch(getDiscontinuanceDescription(payload)),
  };
}

export default connect(null, mapDispatchToProps)(DiscontinuanceDescription);
