import { connect } from 'react-redux';
import KelolaPembagianKls from '../../../views/Akademi/DataKelas/KelolaPembagianKls.view';
import { getClasses, getPeriodsOptions, getLevels, getParamOptions, getClassesOptions, getParamOptionsAcademics, getStudentOptions, getClassroomOptions, getNewStudentOptions, getPrmClassesOptions } from '../../../states/thunks/options.thunk';
import { listClassDivStudent, saveClassDiv, getClassDiv,editClassDiv,getClassData   } from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getNewStudentOptions: (payload) => dispatch(getNewStudentOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetClasses: (payload) => dispatch(getClasses(payload)),
    getClassroomsOptions: (payload) => dispatch(getClassroomOptions(payload)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleGetClassDiv: payload => dispatch(getClassDiv(payload)),
    handleGetClassData: payload => dispatch(getClassData(payload)),
    handleListClassDivStudent: (payload, goback) => dispatch(listClassDivStudent(payload, goback)),
    handleSaveClassDiv: (payload, goback) => dispatch(saveClassDiv(payload, goback)),
    handleEditClassDiv: (payload, goback) => dispatch(editClassDiv(payload, goback)), 
    getPrmClassesOptions: (payload) => dispatch(getPrmClassesOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KelolaPembagianKls);