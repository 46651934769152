import { createAction } from 'redux-actions';

export const SET_USER = 'SET_USER';
export const setUser = createAction(SET_USER);

export const SET_WORKING_UNIT = 'SET_WORKING_UNIT';
export const setWorkingUnit = createAction(SET_WORKING_UNIT);

export const RESET_USER = 'RESET_USER';
export const resetUser = createAction(RESET_USER);
