import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../components/base';

export default class MataPelajaran extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName,
    } = this.props;

    const Matpel = [
      { label: 'Bahasa Indonesia', value: 'bindo' },
      { label: 'Matematika', value: 'mtk' },
      { label: 'Pendidikan Kewarganegaraan', value: 'pkn' },
      { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: 'penjaskes' },
      
    ];

    const Kelas = [
      { label: 'Kelas 1', value: 'kelas1'},
      { label: 'kelas 2', value: 'kelas2'},
      { label: 'kelas 3', value: 'kelas3'},
    ]

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Pelajaran Kelas</th>
            <th>Mata Pelajaran</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                  {
                    <Select
                      value={data.Kelas}
                      data={Kelas}
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name='pelajaran'
                      name='Kelas'
                      onChange={this._onFormChange}
                    />
                  }
                </td>
                {/* <td>
                  {
                    <Select
                      value={data.tipeDivisi}
                      data={tipeDivisi}
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name='plafon'
                      name='tipeDivisi'
                      onChange={this._onFormChange}
                    />
                  }
                </td> */}
                <td>
                {
                    <Select
                      value={data.Matpel}
                      data={Matpel}
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name='matpel'
                      name='Matpel'
                      onChange={this._onFormChange}
                    />
                  }
                </td>
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={7}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

MataPelajaran.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  
};
MataPelajaran.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};