import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonEn from './languages/locales/en/common.json';
import apiErrorsEn from './languages/locales/en/api_errors.json';
import commonId from './languages/locales/id/common.json';
import apiErrorsId from './languages/locales/id/api_errors.json';


const resources = {
  en: {
    translation: commonEn,
    apiErrors: apiErrorsEn,
  },
  id: {
    translation: commonId,
    apiErrors: apiErrorsId,
  },
};


i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'id',
    debug: true,
    lng: 'id',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
