import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map, noop } from 'lodash';
import { commaFormatted } from '../../../utils/transformer.util';

export default function Select(props) {
  const {
    label, value, onChange, data, placeholder, error, center, noMargin,
    theme,
    leftIcon,
    rightIcon,
    classNames,
    isNumber,
    otherValue,
    isOther,
    otherName,
    ...others
  } = props;
  const selectClassnames = classnames([
    'select',
    noMargin && 'select__no-margin',
  ]);
  const labelClassnames = classnames([
    'select__label',
    error && 'select__label--error',
  ]);
  const containerClassnames = classnames([
    'select__container',
  ]);
  const inputClassnames = classnames([
    'select__input',
    !value && 'select__placeholder',
    center && 'select__input--center',
  ]);
  const otherInputClassnames = classnames([
    `input__text input__text__${theme}`,
    leftIcon && `input__text__${theme}--icon-left`,
    rightIcon && `input__text__${theme}--icon-right`,
    (theme === 'default' && leftIcon && rightIcon) && 'input__text--no-border',
    classNames,
  ]);
  const formattedValue = isNumber ? commaFormatted(otherValue) : otherValue;
  return (
    <div className={selectClassnames}>
      {label && <label className={labelClassnames}>{label}</label>}
      <div className={containerClassnames}>
        <select
          className={inputClassnames}
          value={value}
          onChange={onChange}
          {...others}
        >
          {
            placeholder
            && <option value="">{placeholder}</option>
          }
          {
            map(data, (list, idx) => (
              <option
                key={`select_option_${idx}`}
                value={list.value}
              >
                {list.label}
              </option>
            ))
          }
        </select>
      </div>
      {error && <p className="select__err">{error}</p>}
      {
        (isOther && value === 'other') && (
          <input
            type="text"
            name={otherName}
            className={otherInputClassnames}
            value={formattedValue}
            onChange={onChange}
            autoComplete="new-password"
          />
        )
      }
    </div>
  );
}
Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array,
  isNumber: PropTypes.bool,
  isOther: PropTypes.bool,
  center: PropTypes.bool,
  noMargin: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  theme: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  classNames: PropTypes.string,
  otherValue: PropTypes.string,
  otherName: PropTypes.string,
};
Select.defaultProps = {
  center: false,
  noMargin: false,
  isNumber: false,
  isOther: false,
  value: '',
  onChange: noop,
  data: [{ label: '', value: '' }],
  label: '',
  placeholder: '',
  error: '',
  theme: 'default',
  leftIcon: '',
  rightIcon: '',
  classNames: '',
  otherValue: '',
  otherName: '',
};
