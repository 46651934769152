import { connect } from 'react-redux';
import BankJournalReport from '../../../views/Finance/Report/BankJournal.report.view';
import { getBankAccountOptions } from '../../../states/thunks/options.thunk';
import { getBankJournalReport } from '../../../states/thunks/report.thunk';

function mapStateToProps(state) {
  return {
    user: state.user || {},
    bank_account: state.options.bank_account || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBankJournalReport: payload => dispatch(getBankJournalReport(payload)),
    handleGetBankAccountOptions: payload => dispatch(getBankAccountOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BankJournalReport);
