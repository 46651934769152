import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Input, Button } from '../../../../components/base';
import { formatData,  normalizeAmount } from '../../../../utils/transformer.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { KELAS_DATAKELAS_TABLE_FIELDS } from '../../../../constants/Akademi/DataKelas/kelas.constant';
import { SEARCH_FIELDS} from '../../../../constants/Akademi/DataKelas/kelas.constant';
import {TAMBAH_UANG_SEKOLAH_FORM}   from '../../../../constants/finance/spp/tambah-uang-sekolah.constant';
import {Link} from 'react-router-dom';

export default class DataKeu extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickSettingan = this._onClickSettingan.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  
    this.state= {
      coa: {
        loading: true,
        list: [],

      },
      form: {
        value: {},
        error: {},
      },
    };
  
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/tambah-uang-sekolah', { data: '' });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/settingan-uang-sekolah', { data: val });
  }

  _onClickSettingan() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/settingan-uang-sekolah');
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }


  render() {
    const {form} = this.state;
    const { handleGetKelas, handleDeleteKelas,coa } = this.props;
    return (
      
       
        <div className="budget__content">
           <div className="upload-nilai__custom-header">
           Data Keuangan Kelas       
          </div>
          <div className="upload-nilai__body">
            <table className="upload-nilai__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th className="test" >Nama Siswa</th>
                        <th className="test">Uang Pangkal & Uang Sekolah</th>
                        <th  className="test" >Periode Pembayaran</th>
                        <th>Alamat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td rowSpan={3}>1</td>
                        <td rowSpan={3}>Nama Siswa<br></br>
                        No NIS<br></br>
                        Nama Tertagih<br></br>
                        <Input
                          type="text"
                          name="namaSaudara1"
                          style={{backgroundColor:'#999'}}
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}/>
                        </td>
                        <td ><Input
                               type="number"
                               name="up"
                               label="UP"
                               style={{backgroundColor:'#2293C1'}}
                               onChange={this._onFormChange}
                               value={form.value.up || ''}
                               error={form.error.up || ''}
                              />
                              </td>

                        <td><Input
                              type="text"
                              label="No. PSB"
                              name="psb"
                              onChange={this._onFormChange}
                              value={form.value.psb || ''}
                              error={form.error.psb || ''}
                              /> 
                        </td>
                        <td rowSpan={3}></td>
                        
                    </tr>
                    <tr>
                      <td ><Input
                            type="number"
                            name="us"
                            label="US"
                            onChange={this._onFormChange}
                            value={form.value.us || ''}
                            error={form.error.us || ''}
                            style={{backgroundColor:'#2293C1'}}
                          /></td>
                           <td><Input
                            type="text"
                            name="keu"
                            label="No.Keu/VA"
                            onChange={this._onFormChange}
                            value={form.value.keu ||''}
                            error={form.error.keu || ''}
                          /></td>
                          
                    </tr>
                    <tr>
                      <td ><Input
                            type="number"
                            name="l"
                            label="L"
                            onChange={this._onFormChange}
                            value={form.value.l}
                            error={form.error.l ||''}
                            style={{backgroundColor:'#2293C1'}}
                          /><Link to={`/dashboard/keuangan/spp/data-keuangan/detail`}>Detail</Link></td>
                           <td><Input
                            type="text"
                            onChange={this._onFormChange}
                            value={form.value.rekening}
                            error={form.error.rekening}
                            name="rekening"
                            label="No.Rekening"
                          /></td>
                        
                    </tr>
                   
                    </tbody>
                  </table>
                  </div>
                  <div className="add-upload-rapbu">
                    <Button
                    title="Simpan Data"
                    onClick={this._onClickAdd}
            />
          </div>
        </div>
    );
  }
}
DataKeu.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DataKeu.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
