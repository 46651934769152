import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { IDENTITAS_ADD_DATAKELAS_FORM_FIELDS } from '../../../constants/Akademi/DataKelas/identitas.constant';
import { Button } from '../../../components/base';
import IdentitasSekolah1 from './components/IdentitasSekolah1.component';
import IdentitasSekolah2 from './components/IdentitasSekolah2.component';

export default class TambahIdentitas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this.state = {
      page: 1,
      form: {
        value: {},
        error: {
          plafon: '',

        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const {handleGetProvinces, handleGetCities, handleGetDistricts, handleGetSubDistricts} = this.props;
    const paramTypes = ['unit_types', 'nisn_options', 'report_periods', 'highest_grade', 'citizenships', 'religions', 'genders'];
    paramTypes.forEach((type) => {
      this._getParamOptions(type);
    });
    handleGetProvinces();
    handleGetCities();
    handleGetDistricts();
    handleGetSubDistricts();
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/buat-identitas-sekolah2');
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;

    const {handleGetProvinces, handleGetCities, handleGetDistricts, handleGetSubDistricts} = this.props;
    let keyword = null;
    if(name === 'provinces_id') {
      handleGetCities({ filters: { province_id: event.target.value}})
    }
    if(name === 'cities_id') {
      handleGetDistricts({ filters: { city_id: event.target.value}})
    }
    if(name === 'districts_id' ) {
      handleGetSubDistricts({
         filters: { district_id: event.target.value },
         keyword
        })
    }

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  }

  async _getParamOptions(type, filters={}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const { param, form, page } = this.state;
    const {provinces ={}, cities = {}, districts = {}, sub_districts = {}} = this.props;
    return (
      <div className="add-budget">
        <form onSubmit={this.onSubmit}>
          <h1>Identitas Sekolah</h1>
          {
            (page === 1) ? (
              <IdentitasSekolah1
                form={form}
                onChange={this._onFormChange}
                param={param}
                sub_districts={sub_districts}
                districts={districts}
                cities={cities}
                provinces={provinces}
              />
            ) : (
              ''
            )
          }
          {
            (page === 2) ? (
              <IdentitasSekolah2
                form={form}
                onChange={this._onFormChange}
                param={param}
                provinces={provinces}
              />
            ) : (
              ''
            )
          }
          <div className="manage-registration__footer">
            <div className="manage-registration__button-wrapper">
              {
                page > 1 && (
                  <Button
                    onClick={this._onClickBack}
                    title="Kembali"
                  />
                )
              }
              {
                page === 2 ? (
                  <Button
                    onClick={this._onSubmit}
                    title="Simpan"
                  />
                ) : (
                  <Button
                    onClick={this._onClickNext}
                    title="Lanjut"
                  />
                )
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}
TambahIdentitas.propTypes = {
  getOptionsParam: PropTypes.func,
  handleGetProvinces: PropTypes.func,
  handleGetCities: PropTypes.func,
  handleGetDistricts: PropTypes.func,
  handleGetSubDistricts: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
TambahIdentitas.defaultProps = {
  getOptionsParam: noop,
  handleGetProvinces: noop,
  handleGetCities: noop,
  handleGetDistricts: noop,
  handleGetSubDistricts: noop,
  user: null,
};