import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import RaportKBKdetailForm from './components/RaportKBKdetailForm.component';
import RaportKBKdetailTable from './components/RaportKBKdetailTable.component';

export default class RaportKBKdetail extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        //history.push('/dashboard/kurikulum/raport/cetak/laporan-hasil-belajar-ki2')
        // history.push('/dashboard/kurikulum/raport/cetak/sampul-dpn-ktsp');
        // history.push('/dashboard/kurikulum/raport/cetak/sampul-dpn-k13');
        // history.push('/dashboard/kurikulum/raport/cetak/sampul-identitas-ktsp');
        // history.push('/dashboard/kurikulum/raport/cetak/sampul-identitas-k13');
        // history.push('/dashboard/kurikulum/raport/cetak/identitas-siswa-portrait-ktsp');
        // history.push('/dashboard/kurikulum/raport/cetak/identitas-siswa-portrait-k13');
        // history.push('/dashboard/kurikulum/raport/cetak/laporan-hasil-belajar-ktsp');
      
       //history.push('/dashboard/kurikulum/raport/cetak/raport-pts');
      history.push('/dashboard/kurikulum/raport/cetak/input-raport-narasi');
      }



      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/raport/legger-per-periode');
      }

    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas} = this.props;
        return (
          <div className="student-list">
             <div className="student-list__header">
              <div className="student-list__button">
                <Button
                  title="Cetak Ke Printer"
                  onClick={this._onClickSubmit}
                />
              </div>
              <div className="student-list__button">
                <Button
                  title="Generate PDF"
                  onClick={this._onClickAddGroup}
                />
              </div>
            </div>

              <RaportKBKdetailForm
              form={form}
              onFormChange={this._onFormChange}
              />

                <div className="manage-registration__footer">
                    <Button
                    onClick={this._onClickSubmit}
                    title="Simpan Data"
                    />
                </div>

                <RaportKBKdetailTable
              form={form}
              onFormChange={this._onFormChange}
              />

                <div className="manage-registration__footer">
                    <Button
                    onClick={this._onClickSubmit}
                    title="Simpan Data"
                    />
                </div>
          </div>
        );
      }
}
RaportKBKdetail.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
RaportKBKdetail.defaultProps = {
    
  handleGetStudent: noop,
};
