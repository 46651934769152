import { connect } from 'react-redux';
import UploadAbsensiHarian from '../../../views/Student/absensi/UploadAbsensiHarian.student.view';
import { addBudget } from '../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadAbsensiHarian);