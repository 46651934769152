import { connect } from 'react-redux';
import TambahTempatKonseling from '../../views/Student/TambahTempatKonseling.student.view';
import { getStudent } from '../../states/thunks/student.thunk';
import { getClasses, getLevels } from '../../states/thunks/options.thunk';
import {getParamOptions} from '../../states/thunks/options.thunk';
import {listStudentAttendance, listCounselingPlace, deleteCounselingPlace, saveCounselingPlace, editCounselingPlace, getCounselingPlace } from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleGetCounselingPlace: payload => dispatch(getCounselingPlace(payload)),
    handleSaveCounselingPlace: (payload, goback) => dispatch(saveCounselingPlace(payload, goback)),
    handleListCounselingPlace: (payload, goback) => dispatch(listCounselingPlace(payload, goback)),
    handleEditCounselingPlace: (payload, goback) => dispatch(editCounselingPlace(payload, goback)), 
    handleDeleteCounselingPlace: (payload, goback) => dispatch(deleteCounselingPlace(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahTempatKonseling);


