import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../components/base';
import { Link } from 'react-router-dom';
export default class EstimasiTable extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName,
    } = this.props;

    const Matpel = [
      { label: 'Bahasa Indonesia', value: 'bindo' },
      { label: 'Matematika', value: 'mtk' },
      { label: 'Pendidikan Kewarganegaraan', value: 'pkn' },
      { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: 'penjaskes' },
      
    ];

    const Kelas = [
      { label: 'Kelas 1', value: 'kelas1'},
      { label: 'kelas 2', value: 'kelas2'},
      { label: 'kelas 3', value: 'kelas3'},
    ]

    return (
        <div className="table-range__header">
    
      <table className="estimasi__form-table table">
        <thead>
          <tr>
            <th>Syarat</th>
            <th>Keterangan</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td><Link to={`/dashboard/kurikulum/analisa/syarat-ke/${idx+1}`}>Syarat {idx + 1}</Link></td>
                <td>
                  {
                    // <Select
                    //   value={data.Kelas}
                    //   data={Kelas}
                    //   data-input-array
                    //   arrayPosition={idx}
                    //   data-array-position={idx}
                    //   data-field-name='pelajaran'
                    //   name='Kelas'
                    //   onChange={this._onFormChange}
                    // />
                  }
                </td>
                
                
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                 
                </td>
              </tr>
            ))
          }
          <tr>
            <td colSpan={3}>
                  <div className="estimasi__add-more">
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }
}

EstimasiTable.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  
};
EstimasiTable.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};