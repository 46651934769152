import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';


export default class LaporanDisplinerForm extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                nis:'',
                tanggal:'',
                model8355: '',
                classes_year:'',
                status_siswa:'',
            },
            error: {
                classes: '',
                nis:'',
                tanggal:'',
                model8355: '',
                classes_year:'',
                status_siswa:'',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes, levels, param, onFormChange} = this.props;
    const { value } = form;
    const Period = [
      { label: '2019', value: '1' },
      { label: '2020', value: '2' },
      { label: '2021', value: '3' },
    ];
    const Cetak = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ];
    const Bentuk = [
      { label: 'A', value: '1' },
      { label: 'B', value: '2' },
      { label: 'C', value: '3' },
    ];
    const TingkatKelas = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ];

    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
            <Select
              name="cetak"
              label="Cetak"
              onChange={onFormChange}
              data={Cetak}
              value={form.value.cetak}
              error={form.error.cetak || ''}
              placeholder={'Pilihan'}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
            type="text"
            name="period"
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            onChange={onFormChange}
            data={Period}
            value={form.value.period}
            error={form.error.period || ''}
          />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
            <Select
              name="bentuk"
              label="Bentuk"
              onChange={onFormChange}
              data={Bentuk}
              placeholder={'Pilihan'}
              value={form.value.bentuk}
              error={form.error.bentuk || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="classes"
              label="Tingkat Kelas"
              onChange={onFormChange}
              data={TingkatKelas}
              placeholder={'Semua Kelas'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Input
            type="date"
            name="from"
            label="Dari Tanggal"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.from}
            error={form.error.from || ''}
          />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
          <Input
            type="date"
            name="to"
            label="Sampai Tanggal"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.to}
            error={form.error.to || ''}
          />
          </div>
      </div>
      
     
  </div>
          
          
          
   
     
    );
}
  
}
LaporanDisplinerForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
LaporanDisplinerForm.defaultProps = {
  onChange: noop,
  classes: [],
};
