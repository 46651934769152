import { connect } from 'react-redux';
import TahunAjaran from '../../../views/Akademi/DataKelas/TahunAjaran.view';
import { savePeriods, listPeriods, editPeriods, getPeriods } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPeriods: payload => dispatch(getPeriods(payload)),
    handleSavePeriods: (payload, goback) => dispatch(savePeriods(payload, goback)),
    handleListPeriods: (payload, goback) => dispatch(listPeriods(payload, goback)),
    handleEditPeriods: (payload, goback) => dispatch(editPeriods(payload, goback)), 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TahunAjaran);