import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../../../components/base/index';

const padStr = (num) => {
  // if (num) 
  // num.length > 0
  // num !==
  if (num !== "" && num !== null && typeof num !== 'undefined') {
    return num.toString();
    // if (num < 10) {
    //   return "0" + num;
    // }
    // return num;
  }
  
  return null;
}

export default class TambahJenisForm extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                nis:'',
                tanggal:'',
                levels: '',
            },
            error: {
                classes: '',
                nis:'',
                tanggal:'',
                levels: '',
            }
        }
    }
    }


   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes, levels, param } = this.props;
    
    const is_discount = [
      { label: 'N', value: '0'},
      { label: 'Y', value: '1'},
    ]
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
            <Input
              type="text"
              name="code"
              label="Kode Jenis SPP"
              onChange={this._onFormChange}
              value={form.value.code}
              error={form.error.code || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="name"
              label="Nama Jenis SPP"
              onChange={this._onFormChange}
              value={form.value.name}
              error={form.error.name || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
            <Select
              name="status_id"
              label="Status"
              onChange={this._onFormChange}
              data={param.prm_status}
              placeholder={"Pilih Status"}
              value={padStr(form.value.status_id)}
              error={form.error.status_id || ''}
            />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
            
          <Select
                    type="text"
                    name="levels_id"
                    label="Unit"
                    placeholder="Pilih Unit"
                    data={param.levels}
                    value={form.value.levels_id}
                    onChange={this._onFormChange}
                    error={form.error.levels_id || ''}
                  />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
             <Select
                    label="is Discount"
                    placeholder="Pilihan"
                    name='is_discount'
                    data={is_discount}
                    onChange={this._onFormChange}
                    value={form.value.is_discount}
                    error={form.error.is_discount || ''}
                    
                  />
                  </div> 
         
          <div className="absensi-rekap__custom-form-column__field">
            
          
          </div>
      </div>
     
  </div>
          
          
          
   
     
    );
}
  
}
TambahJenisForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
  levels: PropTypes.array,
};
TambahJenisForm.defaultProps = {
  onChange: noop,
  classes: [],
  levels: [],
};
