import { connect } from 'react-redux';
import Mengikuti from '../../../views/Student/absensi/Mengikuti.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(Mengikuti);


