import React, { PureComponent } from 'react';

export default class StudentKbk extends PureComponent {
  componentDidMount() {

  }

  render() {
    return (
      <div className="student-kbk">
        <div className="student-kbk__content">
          <table className="student-kbk__table">
            <thead>
              <tr>
                <th className="student-kbk__table--number" rowSpan={2}>no</th>
                <th rowSpan={2}>Pelajaran</th>
                <th colSpan={3}>Semester I</th>
                <th colSpan={3}>Semester II</th>
              </tr>
              <tr>
                <th>KOGN</th>
                <th>PRAK</th>
                <th>AFEK</th>
                <th>KOGN</th>
                <th>PRAK</th>
                <th>AFEK</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Fisika</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Ekonomi</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}>EKSKUL</td>
                <td colSpan={3}>-</td>
                <td colSpan={3}>-</td>
              </tr>
              <tr>
                <td colSpan={2}>ABSEN</td>
                <td colSpan={3}>S:0 I:0 A:0</td>
                <td colSpan={3}>S:0 I:0 A:0</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}
StudentKbk.propTypes = {};
StudentKbk.defaultProps = {};
