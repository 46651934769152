import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, isEqual,
} from 'lodash';
import { Input, Button, Select } from '../../../components/base';

export default class DailyAndMonthlyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        from: '',
        to: '',
        code_group: '',
      },
      report: [],
    };
    this._getReport = this._getReport.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    const { handleGetCodeGroupOptions } = this.props;
    handleGetCodeGroupOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters: prevFilters } = prevState;
    const { filters } = this.state;

    if (!isEqual(prevFilters, filters)) {
      this._getReport();
    }
  }

  _onChange(event) {
    const { target } = event;
    const { value, name } = target;

    this.setState((prevState) => {
      const { filters } = prevState;
      return {
        ...prevState,
        filters: {
          ...filters,
          [name]: value,
        },
      };
    });
  }

  async _getReport() {
    const { filters } = this.state;
    const { from, to, code_group } = filters;
    const { handleGetMonthlyReport } = this.props;
    const dateFrom = new Date(from);
    const dateTo = new Date(to);
    const res = await handleGetMonthlyReport({
      from: `${dateFrom.getFullYear()}${(dateFrom.getMonth() + 1).toString().padStart(2, 0)}`,
      to: `${dateTo.getFullYear()}${(dateTo.getMonth() + 1).toString().padStart(2, 0)}`,
      code_group,
    });
    this.setState({ report: res });
  }

  render() {
    const { filters, report } = this.state;
    const { from, to, code_group } = filters;
    const { code_group: code_group_options } = this.props;
    return (
      <div className="daily-and-monthly-report">
        <div className="daily-and-monthly-report__form">
          <Input
            type="month"
            label="Dari"
            value={from}
            name="from"
            onChange={this._onChange}
          />
          <Input
            type="month"
            label="Sampai"
            value={to}
            name="to"
            onChange={this._onChange}
          />
          <Select
            label="Kode Akun"
            name="code_group"
            value={code_group}
            onChange={this._onChange}
            data={code_group_options.list}
            placeholder="Pilih kode akun"
          />
          <Button
            title="Cari"
            onClick={this._getReport}
          />
        </div>
        {
          !isEmpty(report) && (
          <table className="daily-and-monthly-report__table">
            <thead>
              <tr>
                <th>No</th>
                <th>Kode Akun</th>
                <th>Nama Akun</th>
                <th>Debit</th>
                <th>Kredit</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody>
              {report.map((item, index) => (
                <tr key={`monthly-report__row-${index + 1}`}>
                  <td>
                    {index + 1}
                  </td>
                  <td>{item.code_of_account.code}</td>
                  <td>{item.code_of_account.title}</td>
                  <td className="nominal">{item.debit}</td>
                  <td className="nominal">{item.credit}</td>
                  <td className="nominal">{0}</td>
                </tr>
              ))}
            </tbody>
          </table>
          )
        }
      </div>
    );
  }
}
DailyAndMonthlyReport.propTypes = {
  handleGetMonthlyReport: PropTypes.func,
  handleGetCodeGroupOptions: PropTypes.func,
  code_group: PropTypes.object,
};
DailyAndMonthlyReport.defaultProps = {
  handleGetMonthlyReport: noop,
  handleGetCodeGroupOptions: noop,
  code_group: null,
};
