import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class IsiPerBidangPage1 extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
            },
            error: {
                classes: '',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes } = this.props;
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
            <Select
              name="classes"
              label="Kelas"
              onChange={this._onFormChange}
              data={ classes.list}
              placeholder={'Pilihan'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="classes_year"
              label="Tahun Ajaran"
              onChange={this._onFormChange}
              data={ '7A', 'kelas7a'}
              placeholder={'Pilihan'}
              value={form.value.classes_year}
              error={form.error.classes_year || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">   
            <Input
                type='date'
                name="tanggal"
                label="Tanggal"
                onChange={this._onFormChange}
                value={form.value.tanggal || ''}
                error={form.error.tanggal || ''}
              />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
            <Select
            name="periode"
            label="Periode"
            onChange={this._onFormChange}
            placeholder="Pilihan"
            value={form.value.periode || ''}
            error={form.error.periode || ''}
            />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
                    <div className="absensi-rekap__custom-form-row__field">
                  
                    <Select
                      label="Mata Pelajaran"
                      name="matapelajaran"
                      onChange={this._onFormChange}
                      placeholder="Pilihan"
                      value={form.value.matapelajaran || ''}
                      error={form.error.matapelajaran || ''}
          />
                    </div>  
                  
                    <div className="absensi-rekap__custom-form-column__field">
  </div>
          
          </div>
          </div>
          
   
     
    );
}
  
}
IsiPerBidangPage1.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
IsiPerBidangPage1.defaultProps = {
  onChange: noop,
  classes: [],
};
