

  export const KENAIKANKELAS_DATAKELAS_FORM_FIELDS = [{
      type:'select',
      name:'tahunajar',
      label: 'Tahun Ajaran',
      full: true,
      data:[
          {label:'2019-2020', value:'tahun1'},
          {label:'2018-2019', value: 'tahun2'},
      ],

  },
  {
      type:'select',
      name:'kelas',
      label: 'Kelas',
      full: true,
      data:[
          {label:'kelas 1', value:'kelas1'},
          {label:'kelas 2', value: 'kelas2'},
      ],

    
  },{
    type:'select',
    name:'unit',
    label: 'Unit',
    full: true,
    data:[
        {label:'SD', value:'sd'},
        {label:'SMP', value: 'smp'},
        {label:'SMA', value: 'sma'},
    ],


  }];


export const KELOLA_KENAIKANKELAS_DATAKELAS_FORM_FIELDS = [{
    type:'select',
    name:'siswa',
    label: 'Siswa Kelas',
    full: true,
    data:[
        {label:'All Kelas', value:'all'},
        
    ],

},
{
    type:'select',
    name:'kenaikan',
    label: 'Akan naik ke kelas',
    placeholder: 'Pilih Kelas',
    full: true,
    data:[
        {label:'kelas 1', value:'kelas1'},
        {label:'kelas 2', value: 'kelas2'},
    ],

  
}];

export const KELOLA_KENAIKANKELAS_DATAKELAS_TABLE_FIELDS = {
    label: [
      'No',
      'Nama',
      'NIS',
      'Kelas',
      'Pilih Kelas',
      'Status',
     
    ],
    value: [{}, { type: 'string', attribute: 'nama' }, { type: 'string', attribute: 'nis' }, 
                { type: 'string', attribute: 'kelas'},{ type: 'string', attribute: 'pilih' },
                { type: 'string', attribute: 'status' }],
  };


  export const KELAS_DATAKELAS_TABLE_FIELDS = {

    label: [
        'No',
        'Nama Kelas',
        'Wali Kelas',
        'Unit',
        'Daya Tampung',
        'Action',
    ],
    value: [{}, { type: 'string', attribute: 'name' }, 
                { type: 'string', attribute: 'homeroom_teacher' }, 
                { type: 'string', attribute: 'levels'},
                { type: 'string', attribute: 'capacity' }],
  };

  export const UPLOAD_KELAS_FORM_FIELDS = [{
    type: 'file',
    name: 'file',
    label: 'File Name',
    placeholder: 'Pilih file',
    inputType: 'file',
    full: true,
  }];

  export const BUATDATA_DATAKELAS_TABLE_FIELDS = [{

    type:'select',
    name:'kelas',
    label: 'Kelas',
    placeholder: 'Pilih Kelas',
    
    data:[
        {label:'kelas 1', value:'kelas1'},
        {label:'kelas 2', value: 'kelas2'},
    ],
  },
{

    type: 'text',
    name: 'namakls',
    label: 'Nama Kelas',

},
{
    type: 'text',
    name: 'dayatampung',
    label: 'Daya Tampung',
},
{
    type: 'select',
    name: 'jurusan',
    label: 'Jurusan',

    data:[
        {label:'umum', value:'umum',}
    ],

},{
    type: 'text',
    name: 'walikls',
    label: 'Wali Kelas',
},
{
    type: 'select',
    name:'sistem',
    label:'Sistem Sekolah',
    data:[
        {label: 'Semester [6 Bulan]', value: 'sms6'},
    ],

},

{
    type: 'text',
    name: 'walikls2',
    label: 'Wali Kelas Kedua (Optional)',

},
{
    type: 'select',
    name: 'unit',
    label: 'Unit',
    data: [
        {label:'TK', value:'tk'},
    ]

},
{
    type: 'text',
    name: 'namakls2',
    label: 'Nama Kelas Lain',
    

},


];

export const KELASKHUSUS_DATAKELAS_TABLE_FIELDS = {

    
    label: [
        'No',
        'Nama Kelas',
        'Wali Kelas',
        'Unit',
        'Daya Tampung',
        'Action',
    ],
    value: [{}, { type: 'string', attribute: 'namakls' }, { type: 'string', attribute: 'walikls' }, 
                { type: 'string', attribute: 'unit'},{ type: 'string', attribute: 'tampung' },
                { type: 'string', attribute: 'action' }],
  };


  export const BUATDATA_DATAKELASKHUSUS_FORM_FIELDS = [{

    type: 'text',
    name: 'namakls',
    label: 'Nama Kelas',
    
  },

{
    type: 'text',
    name: 'dayatampung',
    label: 'Daya Tampung',
},
{
    type: 'text',
    name: 'guru',
    label: 'Nama Guru',
},
{
    type: 'select',
    name:'sistem',
    label:'Sistem Sekolah',
    data:[
        {label: 'Semester [6 Bulan]', value: 'sms6'},
    ],

},
{

    type: 'select',
    name: 'unit',
    label: 'Mata Pelajaran di Kelas tersebut, Untuk Unit',
    full: true,
    data:[
        {label:'SD', value: 'sd'},
    ]
}

];


export const PEMBAGIAN_KELAS_FORM_FIELDS = [{

    type: 'date',
    name: 'tgl',
    label: 'Tanggal Transaksi',
    
  },
  {
      type: 'select',
      name: 'jmlkls',
      label: 'Jumlah Kelas',
      data:[
        {label: '1A', value: '1A'},
        {label: '2A', value: '2A'},

      ]

  },

{
    type: 'text',
    name: 'dayatampung',
    label: 'Daya Tampung',
},
{
    type: 'text',
    name: 'jmlsiswa',
    label: 'Jumlah Siswa Terisi',
},


];

export const PEMBAGIAN_KELAS_TABLE_FIELDS = {
    label: [
    'No',
    'NIS',
    'Nama Siswa',
    'Nama Kelas',
    'Wali Kelas',
    
],
value: [{}, 
    { type: 'string', attribute: 'nis' }, 
    { type: 'string', attribute: 'name' }, 
    { type: 'string', attribute: 'classrooms_name'},
    { type: 'string', attribute: 'homeroom_teacher' }],
            

};

export const KELOLA_PEMBAGIAN_KELASKHUSUS_FORM_FIELDS =[{

    type: 'select',
    name: 'kelas',
    label:'Kelas',

    data:[
        {label:'1A', value:'1a'},
        {label:'2A', value:'2a'},
    ]
},

{
    type: 'text',
    name: 'walikls',
    label: 'Wali Kelas',

}];

export const PEMBAGIAN_KELASKHUSUS_TABLE_FIELDS ={

    
    label: [
        'No',
        'NIS',
        'Nama Siswa',
        'L/P',
        'Moving Kelas',
        
    ],
    value: [{}, { type: 'string', attribute: 'nis' }, { type: 'string', attribute: 'namasiswa' }, 
                { type: 'string', attribute: 'l/p'},{ type: 'string', attribute: 'movingkls' }],
}



export const PINDAHKELAS_FORM_FIELDS =[{

    type: 'selectCoa',
    name: 'code_of_account',
    label:'NIS Siswa',
    full:true,

},

{
    type: 'select',
    name: 'tahunajaran',
    label: 'Tahun Ajaran',
    full:true,

    data:[
        {label:'2019-2020', value: 'tahun1'},
        {label:'2020-2021', value: 'tahun2'},
    ]

},
{
    type: 'select',
    name: 'unit',
    label: 'Unit',
    full:true,

    data:[
        {label:'TK', value: 'tk'},
        {label:'SD', value: 'sd'},
    ]

}];


export const PINDAHKELAS_TABLE_FIELDS ={

    
    label: [
        'No',
        'NIS',
        'Nama Siswa',
        'Asal Kelas',
        'Pindah ke Kelas',
        'Unit',
        'Nama Wali Kelas',
        'Option',

        
    ],
    value: [{}, { type: 'string', attribute: 'nis' }, { type: 'string', attribute: 'namasiswa' }, 
                { type: 'string', attribute: 'unit' }, { type: 'string', attribute: 'posisikls'},
                { type: 'string', attribute: 'pindahkls' }, { type: 'string', attribute: 'action' }],
}


export const KELOLA_PINDAHKELAS_FORM_FIELDS =[{

    type: 'text',
    name: 'nis',
    label:'NIS Siswa',
    
},

{
    type: 'text',
    name: 'namasiswa',
    label: 'Nama Siswa',


},
{
    type: 'text',
    name: 'posisikls',
    label: 'Posisi Kelas',


},

{
    type: 'select',
    name: 'pindah',
    label: 'Pindah Kelas',
    placeholder: 'Pilih Kelas',

    data:[
        {label:'2019-2020', value: 'tahun1'},
        {label:'2020-2021', value: 'tahun2'},
    ]

},
{
    type: 'text',
    name: 'walikls',
    label: 'Wali Kelas',


},

{
    type: 'select',
    name: 'unit',
    label: 'Unit',
    placeholder: 'Choose One',

    data:[
        {label:'TK', value: 'tk'},
        {label:'SD', value: 'sd'},
    ]

}];

export const SEARCH_FIELDS =[{

    type: 'selectCoa',
    name: 'code_of_account',
   
    
}]

export const PEMBAGIAN_KELASKHUSUS_FORM_FIELDS =[
{
    type: 'select',
    name: 'tahunajaran',
    label: 'Tahun Ajaran (Kelas Khusus)',
    full:true,

    data:[
        {label:'2019-2020', value: 'tahun1'},
        {label:'2020-2021', value: 'tahun2'},
    ]

},
{

    type: 'select',
    name: 'kelas',
    label:'Kelas',
    full: true,

    data:[
        {label:'1A', value:'1a'},
        {label:'2A', value:'2a'},
    ]
},
{
    type: 'select',
    name: 'unit',
    label: 'Unit',
    full:true,

    data:[
        {label:'TK', value: 'tk'},
        {label:'SD', value: 'sd'},
    ]

}];
