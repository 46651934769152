import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Pagination } from '../../../components/base';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import FormTambahSoal from './components/FormTambahSoal.component';
import TabelTambahSoal from './components/TabelTambahSoal.component';

export default class TambahSoal extends Component {
  constructor(props) {
    super(props);
    this._onClickGenerate = this._onClickGenerate.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.state = {
      form: {
        value: {
          organization_id: '',
          periode: '',
          tanggal: '',
          number: '',
          upload: '',
          tabeltambahsoal: [
            {
              judul: '',
              date: '',
              keterangan: '',
              mata_pelajaran: '',
              periode: '',
              upload: '',
            }
          ],
        },
        error: {
            tabeltambahsoal: '',
        },
      },
      param: {},
    };
  }
  componentDidMount() {
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { tabeltambahsoal } = form.value;
    tabeltambahsoal.push({
        judul: '',
        date:'',
        keterangan:'',
        mata_pelajaran: '',
        periode: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabeltambahsoal,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
    
  }
 
  async onSubmit() {
    
  }
  async onEdit() {
  }
  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { tabeltambahsoal } = prevState.form.value;
      const list = prevState.form.value.tabeltambahsoal;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabeltambahsoal: list
      };
    });
  }
  _onClickGenerate() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/kurikulum/soal/buat-soal/generate-soal');
  }
  _onClickSubmit() {
    const { history } = this.props;
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName, 
    } = dataset;
    this.setState((prevState) => {
      let newList = []
      let newListError = [];
      let formattedValue = value;
      console.log("pos : " + arrayPosition + " name : " + name + " val : " +formattedValue + "dataset : " + JSON.stringify(dataset))
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if(type==="file"){
        formattedValue = files[0];
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        console.log("nl " + fieldName)
        // newList[arrayPosition] = {};
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value, // value: { oldvalues, overrideoldvalue }
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
}
render() {
    const { form } = this.state;
    const { tabeltambahsoal } = form.value;
    console.log("aa" + JSON.stringify(tabeltambahsoal))
    return (
      <div className="student-list">
        <form>
          <h1>Buat Soal</h1>
          <FormTambahSoal
            form={form}
            onChange={this._onFormChange}
          />
          <TabelTambahSoal
            list={tabeltambahsoal}
            onAddList={this._onClickAdd}
            onChange={this._onFormChange}
            onDeleteList={this._onDeleteList}
          />
          <div className="student-list__header">
          <div className="student-list_button">
            <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
            />
          </div>
          <div className="student-list__button">
            <Button
                type="button"
                title="Generate"
                onClick={this._onClickGenerate}
            />
          </div>
          
          <div className="student-list__button">
            <Button
                type="button"
                title="Kembali"
                onClick={this.onSubmit}
            />
          </div>
          </div>
        </form>
      </div>
    );
  }
}
TabelTambahSoal.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TabelTambahSoal.defaultProps = {
  getParamOptions: noop,
  handleGetStudent: noop,
};