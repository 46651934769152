import { connect } from 'react-redux';
import Budget from '../../../../views/Finance/Budgeting/Budget/Budget.finance.view';
import { getBudget, deleteBudget } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudget: payload => dispatch(getBudget(payload)),
    handleDeleteBudget: (payload, goBack) => dispatch(deleteBudget(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
