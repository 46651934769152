import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import { map, noop, filter } from 'lodash';
import { Input, Button, Select, Checkbox, SearchSelect } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { commaFormatted, normalizeAmount } from '../../../../../utils/transformer.util';
import { InputGroup } from 'react-bootstrap';
import { param } from 'jquery';

const MySwal = withReactContent(Swal);

export default class ProsesInvoiceTbl extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onDeleteList1 = this._onDeleteList1.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this._onAddListJenisSPP = this._onAddListJenisSPP.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }
  
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onAddListJenisSPP() {
    const { onAddList1, fieldName } = this.props;
    onAddList1(fieldName);
  }

  onClickButton() {
    // const filteredList = filter(list, o => (
    //   // console.log(o)
    //   o.code === data.code && data.code_tema === o.code_tema
    // ));
    // console.log(data)
    // console.log(filteredList)

    MySwal.fire({
      size: 20000,
      // title: <h3>Keterangan Anggaran</h3>,
      width: '1000px',
      height: '1000px',
      footer: '',
      html: this.renderModalContent(),
    });
  }

  renderHeaderModal() {
    const { selected, checkedAll } = this.state;
    return (
      <thead>
        <tr>
        {/* <th rowSpan={2}>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th> */}
              <th  className="manage-budget__row table" style={{ width: "20%" }}>Jenis SPP</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Nominal</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Periode</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Total</th>
            <th  className="manage-budget__row table" style={{ width: "5%" }}>&nbsp;</th>
          </tr>
      </thead>

    );
  }

 

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onDeleteList1(idx) {
    const { onDeleteList1, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList1(idx, fieldName);
  }

  _onFormChange(event) {
    const {
      name,
      value,
      type,
      dataset,
      checked = false,
    } = event.target;
    const { fieldName } = dataset;
    this.setState((prevState) => {
      const { form } = prevState;
      const { value: prevValue } = form;
      const selectedCoa = {...prevState.selectedCoa};
      let formattedValue = value;
      if (type === 'checkbox') {
        formattedValue = checked;
      }

      if ( fieldName === 'coa') {
        selectedCoa[name] = value;
        formattedValue = { ...prevValue[fieldName] };
        formattedValue[name] = value;
      }

      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            ...(fieldName
              ? { [fieldName]: formattedValue }
              : { [name]: formattedValue }
            ),
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
        selectedCoa,
      };
    }, () => {
      if (fieldName === 'coa') {
        this._onSearchCoa('', name);
      }
    });
  }

  renderModalContent() {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis,  rincian, 
      onFormChange, param
    } = this.props;

    const periode = [
      { label: 'Januari', value: '1' },
      { label: 'Februari', value: '2' },
      { label: 'Maret', value: '3' },
      { label: 'April', value: '4' },
      { label: 'Mei', value: '5' },
      { label: 'Juni', value: '6' },
      { label: 'Juli', value: '7'},
      { label: 'Agustus', value: '8' },
      { label: 'September', value: '9' },
      { label: 'Oktober', value: '10' },
      { label: 'November', value: '11' },
      { label: 'Desember', value: '12'},
    ];
    return (
      <div>
        {/* <div>
          <h1>{filteredList[0].name_tema}</h1>
        </div> */}
        <table className="manage-budget__form-table table">
          {this.renderHeaderModal()}
          {
          map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                {/* <td><Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={this._onCheckDetail}
                      // value={budget.id}
                      // checked={has(selected, budget.id) ? true : false}
                      ref={this._setRef}
                      
                    /></td> */}
                <td>
                <Select
                    value={data.status_jenisspp}
                    placeholder="Pilih Pilihan"
                    data={param.status_jenisspp}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='jenisspptabel'
                    name='status_jenisspp'
                    onChange={onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td className="nominal">
                <Input
                    type="number"
                    inputArray
                    arrayPosition={idx}
                    fieldName='jenisspptabel'
                    name='nominal'
                    isNumber= {true}
                    value={data.nominal}
                    onChange={onFormChange}
                  />
                </td>
                
                <td className="nominal">
                <Select
                    value={data.periode}
                    placeholder="Pilih Pilihan"
                    data={periode}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='jenisspptabel'
                    name='periode'
                    onChange={onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                 
                </td>
                <td className="nominal">
                
                 
                </td>
                <td>
                  <Button onClick={() => this._onDeleteList1(idx)} icon="icon-trash-o" />
                </td>
              </tr>
              
            ))

            
          }
            <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={5}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddListJenisSPP}
                />
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis, periode, onFormChange, param
    } = this.props;
    const { form } = this.state;
    const {value} = form;
    

    return (
      <table className="table">
        <thead>
          <tr>
            <th>No.</th>
            {/* <th className="manage-budget__row table" style={{ width: "5%" }}>Nis</th> */}
            <th className="manage-budget__row table" style={{ width: "30%" }}>Nama Siswa</th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>Tahun Akademik</th>
            {/* <th className="manage-budget__row table" style={{ width: "10%" }}>Nomor invoice</th> */}
            <th className="manage-budget__row table" style={{ width: "10%" }}>Jenis SPP</th>
            <th  className="manage-budget__row table" style={{ width: "10%" }}>Akumulasi Periode</th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>Discount</th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>Total</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                {/* <td className="nominal">
                  <Select
                    value={data.plafond_id}
                    placeholder="Pilihan"
                    data={nis}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='nis'
                    name='nis'
                    onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td> */}
                <td className="form-group-member">
                  <SearchSelect
                    noMargin
                    async={false}
                    name="users_id"
                    list={param.student}
                    fieldName='tabelinvoices'
                    inputArray
                    arrayPosition={idx}
                    onClick={onFormChange}
                    placeholder="Pilih Nama Siswa"
                    value={data.users_id}
                    // labelName="label"
                    // valueName="value"
                    rightIcon="icon-search"
                    />
                </td>
                <td >
                 
                   <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabelinvoices'
                    data-input-type="text"
                    name="academics_year"
                    onChange={onFormChange}
                    value={data.academics_year || ''}
                  />
                </td>
                <td className="nominal">
                  
                  <Input
                    type="date"
                    inputArray
                    data-array-position={idx}
                    fieldName='tabelinvoices'
                    name='payments_due_date'
                    value={data.payments_due_date}
                    onChange={onFormChange}
                  />
                  
                </td>
                {/* <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='plafon'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={this._onFormChange}
                    value={data.no_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td> */}
                <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabelinvoices'
                    data-input-type="text"
                    name="no_va"
                    onChange={onFormChange}
                    value={data.no_va || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
               
                <td className="nominal">
                <Button variant="primary"  onClick={() => this.onClickButton()} >Tambah Jenis</Button>
                </td>
                <td className="nominal">
                <Select
                    value={data.plafond_id}
                    placeholder="Pilih Pilihan"
                    data={nis}
                    data-input-array
                    arrayPosition={idx}
                    data-field-name='tabelinvoices'
                    data-array-position={idx}
                    data-field-name='nis'
                    name='nis'
                    onChange={onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                 
                </td>
                <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabelinvoices'
                    data-input-type="number"
                    name="discount"
                    isNumber= {true}
                    onChange={onFormChange}
                    value={data.discount || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td></td>
               
               
               
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={13}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

ProsesInvoiceTbl.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,

};
ProsesInvoiceTbl.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
  plafond_name: [],
};
