import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';
import TableDataPegawai from './components/TableDataPegawai.component';

export default class LaporanAbsensiPegawai extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/upload');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas} = this.props;
        return (
          <div className="student-list">
               
                   <div className="laporan-pegawai__custom-form">
                    <div className="laporan-pegawai__custom-form-row">
                      <div className="laporan-pegawai__custom-form-row__field">
                        <Input
                          type="date"
                          name="namaSaudara1"
                          label="Pilih Bulan & Tahun"
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                          placeholder={'Kelas 7A'}
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                        
                      </div>
                      <p className="laporan-pegawai__custom-p">S/D</p>
                        <div className="laporan-pegawai__custom-form-column2__field">
                          <Select
                            name="tahunajaran"
                            label=""
                            onChange={this._onFormChange}
                            value={form.value.tempatLahirSaudara1 || ''}
                            error={form.error.tempatLahirSaudara1 || ''}
                            placeholder={'Semua'}
                          />
                        </div>

                        <div className="laporan-pegawai__custom-form-column__field">
                          <Select
                            name="tahunajaran"
                            label="Status"
                            onChange={this._onFormChange}
                            value={form.value.tempatLahirSaudara1 || ''}
                            error={form.error.tempatLahirSaudara1 || ''}
                            placeholder={'Semua'}
                          />
                        </div>
                        <div className="laporan-pegawai__custom-form-column__field">
                          <Select
                            name="tahunajaran"
                            label="Tipe"
                            onChange={this._onFormChange}
                            value={form.value.tempatLahirSaudara1 || ''}
                            error={form.error.tempatLahirSaudara1 || ''}
                            placeholder={'Semua'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="laporan-pegawai__body">
                    <table className="laporan-pegawai__table">
                        <thead>
                          <tr>
                            <th rowSpan={3}>NIK</th>
                            <th rowSpan={3}>Unit</th>
                            <th rowSpan={3}>Nama Pegawai</th>
                            <th colSpan={11} className="tanggal">Tanggal Absensi</th>
                          </tr>
                          <tr>
                            <th colSpan={11} className="bulan">Januari</th>
                          </tr>
                          <tr>
                            <th className="angka">1</th> 
                            <th className="angka">2</th>
                            <th className="angka">3</th>
                            <th className="angka">4</th>
                            <th className="angka">5</th>
                            <th className="angka">6</th>
                            <th className="angka">7</th>
                            <th className="angka">8</th>
                            <th className="angka">9</th>
                            <th className="angka">10</th>
                            <th className="angka">11</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {
                          map(journal.details, (list, idx) => (
                            <tr key={`journal_detail_${idx}`}>
                              <td>{idx + 1}</td>
                              <td>{list.code_of_account}</td>
                              <td>{list.parameter_code.title}</td>
                              <td>{list.description}</td>
                              <td className="nominal">{commaFormatted(list.amount)}</td>
                            </tr>
                          ))
                        } */}
                          <tr>
                              <td rowSpan={2}>889999900000888</td>
                              <td rowSpan={2}>Yayasan</td>
                              <td rowSpan={2}>Nama Pegawai</td>
                              <td>06.00</td>
                              <td  className="text">06.30</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                          <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                  <TableDataPegawai
                    form={form}
                 />

          </div>
        );
      }
}
LaporanAbsensiPegawai.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanAbsensiPegawai.defaultProps = {
    
  handleGetStudent: noop,
};
