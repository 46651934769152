import { connect } from 'react-redux';
import Pengumuman from '../../../views/Kurikulum/Pengumuman/Pengumuman.kurikulum.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { saveAnnouncement, listAnnouncement, deleteAnnouncement, uploadAnnouncementFile, 
          downloadAnnouncementFile, listAnnouncementStudent } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      result: state.result,
      user: state.user || {},
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      handleSaveAnnouncement: (payload, goback) => dispatch(saveAnnouncement(payload, goback)),
      handleListAnnouncement: (payload, goback) => dispatch(listAnnouncement(payload, goback)),
      handleListAnnouncementStudent: (payload, goback) => dispatch(listAnnouncementStudent(payload, goback)),
      handleDeleteAnnouncement: (payload, goback) => dispatch(deleteAnnouncement(payload, goback)),
      handleUploadAnnouncement: (payload, goback) => dispatch(uploadAnnouncementFile(payload, goback)),
      handleDownloadAnnouncement: (payload, goback) => dispatch(downloadAnnouncementFile(payload, goback)),
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Pengumuman);