import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budget from '../../../assets/img/finance/anggaran.png';
import iconPayroll from '../../../assets/img/finance/pengalihan_dana.png';
import budgetRequest from '../../../assets/img/finance/permohonan_dana.png';
import realization from '../../../assets/img/finance/realisasi.png';

export default class DataPayroll extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/personalia/data-payroll/${link}`);
  }

  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }

  render() {
    return (
      <div className="bookkeeping">
        <div className="finance__list">
          <button onClick={this.getClickHandler('payroll')}>
            <img src={iconPayroll} alt="icon payroll" />
            <h3>Payroll</h3>
          </button>
        </div>
      </div>
    );
  }
}
DataPayroll.propTypes = {
  history: PropTypes.object.isRequired,
};
