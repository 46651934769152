import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';


export default class laporanPersensiForm extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                nis:'',
                tanggal:'',
                model8355: '',
                classes_year:'',
                status_siswa:'',
            },
            error: {
                classes: '',
                nis:'',
                tanggal:'',
                model8355: '',
                classes_year:'',
                status_siswa:'',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes, levels } = this.props;
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Select
              name="bentuk"
              label="Bentuk"
              onChange={this._onFormChange}
              placeholder={'Pilihan'}
              value={form.value.bentuk}
              error={form.error.bentuk || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="classes_year"
              label="Tahun Ajaran"
              onChange={this._onFormChange}
              value={form.value.classes_year}
              placeholder={"Pilihan"}
              error={form.error.classes_year || ''}
            />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
            <Select
              name="break_tingkat"
              label="Break Tiap Tingkat"
              onChange={this._onFormChange}
              placeholder={'Pilihan'}
              value={form.value.break_tingkat}
              error={form.error.break_tingkat || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="pembulatan"
              label="Pembulatan Hasil Persentase"
              onChange={this._onFormChange}
              //data={ classes.list}
              placeholder={'Semua Kelas'}
              value={form.value.pembulatan}
              error={form.error.pembulatan || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Input
            type="date"
            name="from"
            label="Dari Tanggal"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.from}
            error={form.error.from || ''}
          />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
          <Input
            type="date"
            name="to"
            label="Sampai Tanggal"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.to}
            error={form.error.to || ''}
          />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Input
            type="text"
            name="jml_hari"
            label="Jumlah Hari Efektif"
            onChange={this._onFormChange}
            //data={levels.list}
            value={form.value.jml_hari}
            error={form.error.jml_hari || ''}
          />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
          
          </div>
      </div>
      
     
  </div>
          
          
          
   
     
    );
}
  
}
laporanPersensiForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  
};
laporanPersensiForm.defaultProps = {
  onChange: noop,
};
