import { connect } from 'react-redux';
import JadwalKelas from '../../../views/Kurikulum/Jadwal/JadwalKelas.kurikulum.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { getSchedule, saveSchedule, listSchedule, viewSchedule, editSchedule, 
        deleteSchedule, uploadScheduleFile, downloadScheduleFile, listScheduleStudents} from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetSchedule: payload => dispatch(getSchedule(payload)),
    handleSaveSchedule: (payload, goBack) => dispatch(saveSchedule(payload, goBack)),
    handleListSchedule: (payload, goBack) => dispatch(listSchedule(payload, goBack)),
    handleListScheduleStudents: (payload, goBack) => dispatch(listScheduleStudents(payload, goBack)),
    handleViewSchedule: (payload, goBack) => dispatch(viewSchedule(payload, goBack)),
    handleEditSchedule: (payload, goBack) => dispatch(editSchedule(payload, goBack)),
    handleDeleteSchedule: (payload, goBack) => dispatch(deleteSchedule(payload, goBack)),
    handleUploadScheduleFile: (payload, goBack) => dispatch(uploadScheduleFile(payload, goBack)),
    handleDownloadSchedule: (payload, goBack) => dispatch(downloadScheduleFile(payload, goBack)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(JadwalKelas);