import { connect } from 'react-redux';
import DataKeu from '../../../../views/Finance/Spp/MasterData/DataKeu.masterdata.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(DataKeu);
