import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function NilaiKognitifForm(props) {


  const { onFormChange, form, classes } = props;
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Select
           
           name="tahunajaran"
           label="Tahun Ajaran"
           onChange={onFormChange}
          //  value={form.value.tempatLahirSaudara1 || ''}
          //  error={form.error.tempatLahirSaudara1 || ''}
           placeholder={'2019 - 2020'}
         />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
          <Select
           
            name="Unit"
            label="Unit"
            onChange={onFormChange}
            // value={form.value.tempatLahirSaudara1 || ''}
            // error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">

        <Select
           
           name="Semester"
           label="Semester"
           onChange={onFormChange}
          //  value={form.value.tempatLahirSaudara1 || ''}
          //  error={form.error.tempatLahirSaudara1 || ''}
           placeholder={'Pilihan'}
         />
        
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
        <Select
            name="classes"
            label="Kelas"
            onChange={onFormChange}
            // data={classes.list}
            // value={form.value.classes}
            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
          />
          
       
         
        </div>
        </div>

        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
            name="namaSaudara1"
            label="Mata Pelajaran"
            onChange={onFormChange}
            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
          <div className="nilai-kognitif__custom-form-row__field">
          
          <Select
             
             name="Menu"
             label="Menu"
             onChange={onFormChange}
            //  value={form.value.tanggalLahirSaudara1 || ''}
            //  error={form.error.tanggalLahirSaudara1 || ''}
             placeholder={'Pilihan'}
           />
          
         </div>
         
        </div>
        </div>

        <div className="nilai-kognitif__custom-form-row">
     
      
        </div>
        </div>
        
 
   
  );
}
NilaiKognitifForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
NilaiKognitifForm.defaultProps = {
  onFormChange: noop,
  classes :[],
};
