import { connect } from 'react-redux';
import DaftarSiswaKls from '../../../views/Student/report/DaftarSiswaKls.student.view';
import { manageRegistration } from '../../../states/thunks/student.thunk';
import {getParamOptions, getParamOptionsAcademics, getClassesOptions, getPeriodsOptions} from '../../../states/thunks/options.thunk';
import { listStudentClass, studentCount} from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},

  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleStudentCount: (payload) => dispatch(studentCount(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentClass: (payload, goback) => dispatch(listStudentClass(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaftarSiswaKls);
