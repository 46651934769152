import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../../utils/transformer.util';
import Button from '../../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../../utils/print.util';
import ListForm from '../../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../../constants/student.constant';
import { Input, Select } from '../../../../../components/base/index';
import { GENDER } from '../../../../../variables/common.variable';
import ReactToPrint from "react-to-print";


export default class ComponentToPrint extends Component {
    constructor(props) {
      super(props);
      this.state = {
        journal: {},
      };
      // this.onGetDetails = this.onGetDetails.bind(this);
      this._onFormChange = this._onFormChange.bind(this);
    }
  
    // componentDidMount() {
    //   this.onGetDetails();
    // }
  
    // async onGetDetails() {
    //   const { handleGetCashJournal, match } = this.props;
    //   const { id } = match.params;
    //   try {
    //     const payload = await handleGetCashJournal({ id });
    //     this.setState({
    //       journal: payload,
    //     });
    //   } catch (err) {
    //     // err action
    //   }
    // }
  
    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }
    
  
  
    render() {
      const { journal } = this.state;
      return (
        <div id="divPrint" style={{width:1000, border: '0px solid black'}}>
          <div >
       
          {/* <div className="report__content2">
            <Select
            name="kelas"
            onChange={this._onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Kelas 7A'}
            />  
            </div> */}
            {/* <Button
              title="Cetak Ke Printer"
              onClick={() => {
                onClickPrint('detail-cash-journal__header',
                  'detail-cash-journal__table',
                  'detail-cash-journal__footer');
              }}
            /> */}
          <div id="cetak-buku-induk" className="cetak-buku-induk">
            {
                  <div className="invoice-sum__content">
                    <div className="invoice-sum__header2">
                      Daftar Payment Uang Sekolah Dari Tanggal
                    </div>
                    
                    <div className="komposisi-siswa__custom-form">
                        <div className="invoice-sum__custom-form-row__field">
                            <div className="invoice-sum__custom-form-row3"> 
                                <Input
                                    type="text"
                                    name="tahunajaran"
                                    style={{backgroundColor:'grey'}}
                                    label=""
                                    
                                    // value={form.value.tempatLahirSaudara1 || ''}
                                    // error={form.error.tempatLahirSaudara1 || ''}
                                />
                            </div>
                            <p>S/D</p>
                            <div className="invoice-sum__custom-form-row4">
                                <Input
                                    type="text"
                                    name="tahunajaran"
                                    style={{backgroundColor:'grey'}}
                                    label=""
                                    // onChange={onFormChange}
                                    // value={form.value.tempatLahirSaudara1 || ''}
                                    // error={form.error.tempatLahirSaudara1 || ''}
                                    
                                />
                            </div>
                        </div>
                    </div>     
                    <div className="invoice-sum__body">
                      <table className="invoice-sum__table">
                          <thead>
                          <tr>
                              <th>No</th>
                              <th>Tanggal</th>
                              <th>Nama Siswa</th>
                              <th>No.Invoice</th>
                              <th>Invoice Detail</th>
                              <th>Total Invoice</th>
                              <th>No Receipt</th>
                              <th>AMOUNT</th>
                              <th>Credit Note</th>
                              <th>AMOUNT</th>
                              <th>Balance</th>
                              <th>Ket</th>
                          </tr>
                          </thead>
                          <tbody>
                        {/* {
                        map(journal.details, (list, idx) => (
                          <tr key={`journal_detail_${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{list.code_of_account}</td>
                            <td>{list.parameter_code.title}</td>
                            <td>{list.description}</td>
                            <td className="nominal">{commaFormatted(list.amount)}</td>
                          </tr>
                        ))
                      } */}
                      <tr>
                          <td>1</td>
                          <td>1 Jan  2019</td>
                          <td>7a<br></br>
                          Nama lengkap<br></br>
                          12345</td>
                          <td> <Input
                                label="Uang SPP"
                                name="namaSaudara1"
                              //   onChange={onFormChange}
                              /><br></br>
                              <Input
                                label="Uang Jemputan"
                              //  onChange={onFormChange}
                              /><br></br>
                                <Input
                                label="Uang Ekskul"
                              //  onChange={onFormChange}
                              /></td>
                          <td>120000</td>
                          <td>Paid</td>
                          <td>120000</td>
                          <td>Paid</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No Paid</td>
                        
                      </tr>

                    
                      
                      
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                          <td colSpan="8" className="nominal"></td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
               
                </div>
            }
          </div>
        </div>
      </div>
      );
    }
  
  }

  ComponentToPrint.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  ComponentToPrint.defaultProps = {
    handleGetCashJournal: noop,
  };