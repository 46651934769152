import { connect } from 'react-redux';
import DataKeuDetail from '../../../../views/Finance/Spp/MasterData/DataKeuDetail.masterdata.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(DataKeuDetail);
