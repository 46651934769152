import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import ReactToPrint from "react-to-print";
import ReportCard from '../../../components/ReportCard/ReportCard.component';
import { commaFormatted, dateFormat } from '../../../utils/transformer.util';
import { Button } from '../../../components/base';

export default class BankJournalReport extends Component {
  constructor(props) {
    super(props);

    const date = new Date();
    this.state = {
      from: dateFormat(date, 'YYYY-MM-DD'),
      to: dateFormat(date, 'YYYY-MM-DD'),
      bank_account: null,
      report: [],
      summary: {},
    };
    this._getReport = this._getReport.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  componentDidMount() {
    const { handleGetBankAccountOptions } = this.props;
    this._getReport();
    handleGetBankAccountOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { from, to } = this.state;
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (from !== prevState.from || to !== prevState.to || workingUnit !== prevWorkingUnit) {
      this._getReport();
    }
  }

  async _getReport() {
    const { handleGetBankJournalReport } = this.props;
    const { from, to, bank_account } = this.state;
    const res = await handleGetBankJournalReport({
      from: dateFormat(from, 'YYYY-MM-DD'),
      to: dateFormat(to, 'YYYY-MM-DD'),
      bank_account,
    });
    this.setState(prevState => ({
      ...prevState,
      report: res.list,
      saldo_awal: res.saldo_awal,
      summary: res.summary,
    }));
  }

  _onSearch(val) {
    const { from, to, bank_account } = val;
    const { from: stateFrom, to: stateTo } = this.state;
    this.setState({
      from: (from) ? from : stateFrom,
      to: (to) ? to : stateTo,
      bank_account: (bank_account) ? bank_account.id : null,
    }, () => {
      this._getReport();
    });
  }

  render() {
    const { report, saldo_awal, summary = {} } = this.state;
    const { user, bank_account } = this.props;
    const { school_unit, workingUnit } = user;
    const activeUnit = school_unit || workingUnit;
    const componentRef = React.createRef();

    if (!school_unit) {
      if (!workingUnit) {
        return (
          <div className="cash-journal-report">
            <h3><i>Mohon pilih unit</i></h3>
          </div>
        )
    }
    }

    return (
      <div className="cash-journal-report">
        <ReportCard
          paging={false}
          header={(
            <div className="cash-journal-report__title">
              <h1>Pelaporan Jurnal Bank Harian</h1>
              <h1>{activeUnit.name}</h1>
            </div>
          )}
          onSearch={this._onSearch}
          bankAccountOptions={bank_account}
          bankAccountSelector
        >
          <ReactToPrint
            trigger={() => (
              <div className="print">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          />
          <table className="cash-journal-report__table" ref={componentRef}>
            <thead>
              <tr>
                <th rowSpan={2}>No</th>
                <th rowSpan={2} width="10%">No. Jurnal/Bukti </th>
                <th rowSpan={2} width="10%">Tanggal</th>
                <th rowSpan={2} width="7%">Kode Akun</th>
                <th rowSpan={2}>Keterangan</th>
                <th rowSpan={2}>Uraian</th>
                <th colSpan={2}>Kas</th>
                <th rowSpan={2}>Saldo</th>
              </tr>
              <tr>
                <th>Masuk</th>
                <th>Keluar</th>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>Saldo Awal</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th className="nominal">{commaFormatted(saldo_awal)}</th>
              </tr>
            </thead>
            <tbody>
              {
                map(report, (list, idx) => ([
                  <tr key={`cash_journal_report_${idx}`}>
                    <td rowSpan={2}>{idx + 1}</td>
                    <td rowSpan={2}>{list.journal_number}</td>
                    <td rowSpan={2}>{list.date}</td>
                  </tr>,
                  <tr key={`cash_journal_detail_report_${idx}`}>
                    <td>{list.parameter_code ? list.parameter_code.code : '-'}</td>
                    <td>{list.parameter_code ? list.parameter_code.title : '-'}</td>
                    <td>{list.description}</td>
                    <td className="nominal">{commaFormatted(list.credit)}</td>
                    <td className="nominal">{commaFormatted(list.debit)}</td>
                    <td className="nominal">{commaFormatted(list.balance)}</td>
                  </tr>,
                ]))
              }
              {
                !report && (
                  <tr>
                    <td colSpan="9" className="center"><i>Belum ada data</i></td>
                  </tr>
                )
              }
            </tbody>
            <tfoot>
              <tr className="total">
                <td colSpan="6" className="align-center">Jumlah</td>
                <td className="nominal">{commaFormatted(summary.total_credit)}</td>
                <td className="nominal">{commaFormatted(summary.total_debit)}</td>
                <td className="nominal">{commaFormatted(summary.balance)}</td>
              </tr>
            </tfoot>
          </table>
        </ReportCard>
      </div>
    );
  }
}
BankJournalReport.propTypes = {
  user: PropTypes.object,
  handleGetBankJournalReport: PropTypes.func,
  handleGetBankAccountOptions: PropTypes.func,
  bank_account: PropTypes.object,
};
BankJournalReport.defaultProps = {
  user: {},
  bank_account: {},
  handleGetBankJournalReport: noop,
  handleGetBankAccountOptions: noop,
};
