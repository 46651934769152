import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function LembarMingguanForm (props) {


  const { onFormChange, form, data, param } = props;
  const semester = [
    { label: 'Ganjil', value: 'ganjil'},
    { label: 'Genap', value: 'genap'},
  ]
  const jenis = [
    { label: 'Akademis', value: 'akademis'},
    { label: 'Non Akademis', value: 'nonakademis'},
  ]
  const period = [
    { label: '2019', value: '2019'},
    { label: '2020', value: '2020'},
    { label: '2021', value: '2021'},

  
  ]
console.log(data)
  return (
    <div className="nilai-kognitif__custom-form">
<div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian__custom-form-row__field">
            <div className="lembar-harian__box--square2">
                Image
            </div>
            
        </div>
        
        <div className="lembar-harian2__custom-form-column__field">
        <Input
            name="nis"
            label="NIS"
            value={data?data.nis:''}
            // data={data.nis}
            onChange={onFormChange}
            error={form.error.nis || ''}
          />
           <Input
            name="name"
            label="Nama Anak"
            value={data?data.name:''}
            onChange={onFormChange}
            error={form.error.name || ''}
          />
            <Input
            type='date'
            name="assessment_date"
            label="Tgl Penilaian"
            onChange={onFormChange}
            value={form.value.assessment_date}
            error={form.error.assessment_date || ''}
          />
           <Select
            name="academics_type"
            label="Jenis"
            placeholder={'Pilihan'}
            data={jenis}
            onChange={onFormChange}
            value={form.value.academics_type}
            error={form.error.academics_type || ''}
          />
           <div className="manage-registration__custom-form-row-obs-harian__field">
        <Select
            name="semester"
            label="Semester"
            placeholder={'Pilihan'}
            onChange={onFormChange}
            data={semester}
            value={form.value.semester}
            error={form.error.semester || ''}
          />
           <div className="manage-registration__custom-form-row-obs">
             <Select
              type="number"
              name="period"
              label="Tahun Ajaran/Period"
              onChange={onFormChange}
              placeholder={'Pilihan'}
              data={param.periods}
              value={form.value.period}
              error={form.error.period || ''}
            />
            </div>
           </div>
        
        </div>
        </div>
        </div>
        
 
   
  );
}
LembarMingguanForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LembarMingguanForm.defaultProps = {
  onFormChange: noop,
};
