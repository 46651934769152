import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../../components/base/index";
import language from "../../../languages";
import SearchNisCatat from "../../Student/components/SearchNisCatat.component";
import { DAFTAR_PINDAH_UNITS_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class DaftarPindahUnit extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onClickAddGrafik = this.onClickAddGrafik.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getUnitsOptions = this._getUnitsOptions.bind(this);
    this._getNewStudentOptions = this._getNewStudentOptions.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id, name } = school_unit;

    this.state = {
      content: initialContent,
      listAmount: 5,
      list: [],
      filters: {},
      form: {
        value: {
          units_from: name,
          levels_id,
          organizations_id,
          pindah_units: [
            {
              students_id: "",
              period: "",
              classrooms_id: "",
              date: "",
            },
          ],
        },
        error: {
          pindah_units: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetClasses,
      handleGetLevels,
      user,
    } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const paramTypes = ["classes", "levels"];
    const { content } = this.state;
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    // handleGetClasses();
    // handleGetLevels();
    this._getUnitsOptions();
    this._getNewStudentOptions();
  }

  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getNewStudentOptions(filters = {}) {
    const { getNewStudentOptions } = this.props;
    const res = await getNewStudentOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getUnitsOptions(filters = {}) {
    const { getUnitsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getUnitsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }
  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _getClassroomsOptions() {
    const { getClassroomsOptions } = this.props;
    const { form } = this.state;
    const { classes_id, levels_id, periods_id, classrooms_id } = form.value;
    const res = await getClassroomsOptions({
      classes_id: classes_id,
      levels_id: levels_id,
      periods_id: periods_id,
    });

    const selectedClassroom = res.find((item) => item.value == classrooms_id);

    console.log(selectedClassroom, res, classrooms_id);
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          capacity: selectedClassroom
            ? selectedClassroom.attributes.capacity
            : "",
        },
      },
      param: {
        ...prevState.param,
        classrooms: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetClassDiv, user } = this.props;
    try {
      const { organizations_id } = user;
      const payload = await handleGetClassDiv({ id });
      const { classrooms, levels_id, classes_id } = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] } });
      this._getParamOptionsAcademics("classrooms", {
        classes_id: classes_id,
        organizations_id,
      });

      this.setState({
        form: {
          value: {
            id: payload.id,
            periods_id: payload.periods_id,
            classrooms_id: payload.classrooms_id,
            levels_id: payload.levels_id,
            classes_id: payload.classes_id,
            pindah_units: map(payload.pindah_units, (detail) => ({
              name: detail.name,
              homeroom_teacher: detail.homeroom_teacher,
              capacity: detail.capacity,
              classrooms_id: detail.classrooms_id,
              nis: detail.nis,
              students_id: detail.students_id,
            })),
          },
          error: {
            pindah_units: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  async onSubmit() {
    const { history, handlesavePindahUnits } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handlesavePindahUnits(value);
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas");
    // }
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    try {
      const { handlelistDaftarPindahUnitSiswa, user } = this.props;
      const { organizations_id, school_unit } = user;
      const { units_id } = school_unit;
      const result = await handlelistDaftarPindahUnitSiswa({
        ...params,
        organizations_id,
        units_id,
        filters,
      });
      const { classes = [], data = [] } = result;
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pindah_units: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pindah_units: [],
          },
        },
      }));
    }
    // }
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  // on formchange untuk tabel
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id" || name === "periods_id") {
          this._getClassroomsOptions();
        }
        if (name === "classrooms_id") {
          const param = this.state.param.classrooms;
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                capacity: param.find((i) => i.value == value).attributes
                  .capacity,
              },
            },
          }));
        }
      }
    );
  }

  onClickAddGrafik() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap/grafik", {
      isEdit: true,
    });
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-laporan-8355");
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/administrasi/data-kelas/pindah-unit", {
      data: val,
      isEdit: true,
    });
  }

  async onClickDelete(id) {
    const { handleDeleteSwitchUnit } = this.props;
    const res = await handleDeleteSwitchUnit(id);
    if(res){
      this._onSearchContent();
    }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    if (data.status === "pending") {
      return (
        <td>
          <div className="table__actions">
            <Button onClick={() => this.onClickEdit(data)} title="Edit" />
            <Button onClick={() => this.onClickDelete(data)} title="Delete" />
          </div>
        </td>
      );
    } else {
      return (
        <td>
          <div className="table__actions">
          </div>
        </td>
      );
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
    } = this.state;
    const { classes_id = "", students_id = "", classrooms_id2 = "" } = filters;
    const { classes = {}, levels = {}, tema = {}, user } = this.props;
    const { value } = form;
    const { pindah_units } = form.value;
    const { school_unit } = user;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    return (
      <div className="absensi-rekap__custom-form">
        <h1>Daftar Pindah Unit {school_unit.name}</h1>
        <div></div>

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(DAFTAR_PINDAH_UNITS_LIST_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(pindah_units, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    DAFTAR_PINDAH_UNITS_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "checkbox") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              type="checkbox"
                              name="checkbox"
                              data-input-array={true}
                              data-array-position={idx}
                              data-field-name="pindah_units"
                              onChange={this._onFormChange}
                              checked={list.checkbox}
                              value={field.value}
                            />{" "}
                          </td>
                        );
                      }

                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(data, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>
    );
  }
}
DaftarPindahUnit.propTypes = {
  handleGetLevels: PropTypes.func,
  handleGetClasses: PropTypes.func,
  history: PropTypes.object.isRequired,
  getStudentOptions: PropTypes.func,
};
DaftarPindahUnit.defaultProps = {
  handleGetLevels: noop,
  handleGetClasses: noop,
  getStudentOptions: noop,
};
