import { connect } from 'react-redux';
import TeacherContract from '../../../views/Personnel/Letter/TeacherContract.letter.view';
import { getTeacherContract } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetTeacherContract: payload => dispatch(getTeacherContract(payload)),
  };
}

export default connect(null, mapDispatchToProps)(TeacherContract);
