import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Button, Select, Input } from "../../../../components/base/index";
import { validateRequiredFields } from '../../../../utils/validation.util';

export default class CreateInvoiceRutin extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this.getPrmPayments = this.getPrmPayments.bind(this);

    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      form: {
        value: {
          organizations_id: 12,
          total: "",
          currents_year:"",
          tabelpayments: [
            {
              payments_id: "",
              nominal: "",
            },
          ],
        },
        error: {
          tabelpayments:[]
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (isEdit && id) {
      this._setForm(id, data);
    }
    this.getYearsOfEducationsOptions();
    this.getPrmPayments();
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  _onAddList(data) {
    const { form } = this.state;
    const { tabelpayments } = form.value;

    tabelpayments.push({
      payments_id: "",
      nominal: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelpayments,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.tabelpayments;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelpayments: list,
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveInvoiceRutin } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['currents_year']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSaveInvoiceRutin(value);
      history.push("/dashboard/keuangan/spp/create-invoice");
    }
  }

  async _setForm(id, data = {}) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { form } = this.state;
    
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }
    
    try {
      this.setState({
        form: {
          value: {
            users_id: data.users_id,
            no_va: data.no_va,
            name: data.name,
            address: data.address,
            academics_year: data.entrance_year,
            id: form.value.id,
            students_id: form.value.students_id,
            organizations_id: 12,
            currents_year: form.value.currents_year ? form.value.currents_year : year,
            tabelpayments: map(form.value.tabelpayments, detail => ({
              payments_id: detail.payments_id,
              nominal:detail.nominal,
            })),
          },
          error: {
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async getYearsOfEducationsOptions() {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async getPrmPayments() {
    const { getPrmPayments } = this.props;
    const res = await getPrmPayments();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }


  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { form, param } = this.state;
    const { tabelpayments } = form.value;

    form.value.total = 0;
    {map(tabelpayments, (data, idx) => (
      form.value.total+=Number(form.value.tabelpayments[idx].nominal)
    ))}

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Rutin </h1>
          <hr></hr>
        </div>
        <form>
          <div className="result__content">
            <div className="nilai-kognitif__custom-form">
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="nis"
                    label="NIS"
                    value={data ? data.nis : ""}
                    onChange={this._onFormChange}
                    error={form.error.nis || ""}
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Input
                    name="name"
                    label="Nama"
                    value={data ? data.name : ""}
                    onChange={this._onFormChange}
                    error={form.error.name || ""}
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="entrance_year"
                    label="Tahun Masuk"
                    value={data ? data.entrance_year : ""}
                    onChange={this._onFormChange}
                    error={form.error.entrance_year || ""}
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Select
                    name="currents_year"
                    label="Tahun Ajaran *"
                    onChange={this._onFormChange}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={form.value.currents_year || ""}
                    error={form.error.currents_year}
                    isRequired
                  />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">Data</th>
              </tr>
            </thead>
            <tbody>
              {map(tabelpayments, (data, idx) => (
                <tr key={`plafon_journal_data__${idx}`}>
                  <td>
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                        <Select
                          label="Nama Pembayaran"
                          name="payments_id"
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          data={param.prm_payments}
                          value={data.payments_id}
                          // error={form.error.payments_id}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                        />
                      </div>
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          type="number"
                          name="nominal"
                          label="Nominal"
                          onChange={this._onFormChange}
                          value={data.nominal}
                          // error={form.error.nominal}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                        />
                      </div>
                    </div>

                  </td>
                  <td>
                    <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o"></Button>
                  </td>
                  
                </tr>
                
              ))}
            </tbody>
            <tfoot>
              <tr className="manage-rapbs-plafon__add-more">
                <td colSpan={2}>
                  <div>
                    <Button icon="icon-plus" onClick={this._onAddList}></Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>                    
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                      </div>
                      
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          name="total"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.total}
                          isNumber
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
          <br></br>
          <div className="student-list__header">
            <div className="student-list__button">
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CreateInvoiceRutin.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CreateInvoiceRutin.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
