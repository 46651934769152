import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ReportCard from '../../../components/ReportCard/ReportCard.component';

export default class MonthlyReport extends PureComponent {
  componentDidMount() {
    const { handleGetMonthlyReport } = this.props;
    handleGetMonthlyReport();
  }

  render() {
    const { user } = this.props;
    const { school_unit } = user;
    
    return (
      <div className="monthly-report">
        <ReportCard
          header={(
            <div className="monthly-report__title">
              <h1>Rekap Transaksi Bulanan</h1>
              <h1>{school_unit.name}</h1>
              </div>
          )}
        >
          <table className="monthly-report__table">
            <thead>
              <tr>
                <th rowSpan={2}>No</th>
                <th rowSpan={2}>Kode Akun</th>
                <th rowSpan={2}>Keterangan</th>
                <th colSpan={12}>Bulan</th>
                <th rowSpan={2}>Jumlah</th>
              </tr>
              <tr>
                <th>Januari</th>
                <th>Febuari</th>
                <th>Maret</th>
                <th>April</th>
                <th>Mei</th>
                <th>Juni</th>
                <th>Juli</th>
                <th>Agustus</th>
                <th>September</th>
                <th>Oktober</th>
                <th>November</th>
                <th>Desember</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>I</td>
                <td>&nbsp;</td>
                <td>Pendapatan</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>4-101</td>
                <td>Pendapatan Pendaftaran</td>
                <td>2,500,000</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>2,500,000</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Jumlah Pendapatan</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>2,500,000</td>
              </tr>

              <tr>
                <td>II</td>
                <td>&nbsp;</td>
                <td>Beban Operasional</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>5-204</td>
                <td>Perlengkapan Interior Kantor</td>
                <td>2,100,000</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>2,100,000</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Jumlah Beban Operasional</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>2,100,000</td>
              </tr>

              <tr>
                <td>III</td>
                <td>&nbsp;</td>
                <td>Biaya Investasi</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>1-102</td>
                <td>Rekening Bank</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Jumlah Biaya Investasi</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Estimasi Laba Rugi (I - II)</td>
                <td>400,000</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>400,000</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Saldo (I - (II + III)</td>
                <td>400,000</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>- 0</td>
                <td>400,000</td>
              </tr>
            </tfoot>
          </table>
        </ReportCard>
      </div>
    );
  }
}
MonthlyReport.propTypes = {
  handleGetMonthlyReport: PropTypes.func,
};
MonthlyReport.defaultProps = {
  handleGetMonthlyReport: noop,
};
