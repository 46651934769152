import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import RealizationForm from './components/RealizationForm.component';
import RealizationCheckList from './components/RealizationCheckList.component';
import { commaFormatted } from '../../../../utils/transformer.util';
import { FINANCE_ADD_REALIZATION_FORM_FIELDS } from '../../../../constants/finance/budgeting/realization.constant';
import { Button } from '../../../../components/base';
import { validateRequiredFields } from '../../../../utils/validation.util';

export default class ManageRealization extends PureComponent {
  constructor(props) {
    super(props);
    this._setForm = this._setForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
      budgets: [],
    };
  }

  componentDidMount() {
    const { handleGetCoa, location } = this.props;
    const { data = {} } = location.state;
    handleGetCoa();
    if (!isEmpty(data)) {
      this._setForm(data);
    }
  }

  async _setForm(data) {
    const { handleGetDetailRealization } = this.props;
    const res = await handleGetDetailRealization({
      code_of_account: 41101, type: 'account',
    });
    this.setState({ budgets: res });
    this.setState({
      form: {
        value: {
          amount: commaFormatted(data.amount),
          code_of_account: {
            code: 41101,
            title: 'Dana Pengembangan Pendidikan',
          },
          description: data.description,
          file: [],
          periode: 2020,
          selectedBudget: data.budget_detail,
        },
        error: {},
      },
    });
  }

  async _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      type,
      files,
      checked,
    } = event.target;
    const { inputType = 'text', arrayPosition = 0 } = dataset;
    const { handleGetDetailRealization } = this.props;
    if (name === 'code_of_account') {
      const res = await handleGetDetailRealization({
        code_of_account: value.code, type: value.type,
      });
      this.setState({ budgets: res });
    }
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (type === 'file') {
        formattedValue = files;
      }
      if (type === 'checkbox') {
        if (checked) {
          formattedValue = prevState.budgets[arrayPosition];
        } else {
          formattedValue = {};
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleAddRealization, handleEditRealization,
      history, location,
    } = this.props;
    const error = validateRequiredFields(form.value, ['amount']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      const { isEdit = false, data = {} } = location.state;
      if (isEdit) {
        handleEditRealization({ id: data.id, ...form.value }, history.goBack);
      } else {
        handleAddRealization(form.value, history.goBack);
      }
    }
  }

  render() {
    const { coa, location } = this.props;
    const { isEdit = false } = location.state;
    const { form, budgets } = this.state;
    return (
      <div className="manage-realization">
        <h1>
          {
            isEdit ? 'Edit Realisasi' : 'Buat Realisasi'
          }
        </h1>
        <form onSubmit={this._onSubmit}>
          <RealizationForm
            form={form}
            coa={coa}
            onFormChange={this._onFormChange}
          />
          {
            !isEmpty(budgets) && (
              <>
                <RealizationCheckList
                  error={form.error}
                  budgets={budgets}
                  onChange={this._onFormChange}
                  selectedBudget={form.value.selectedBudget}
                />
                <ListForm
                  form={form}
                  formFields={FINANCE_ADD_REALIZATION_FORM_FIELDS}
                  onFormChange={this._onFormChange}
                />
                <div className="manage-realization">
                  <Button
                    type="submit"
                    title="Simpan"
                  />
                </div>
              </>
            )
          }
        </form>
      </div>
    );
  }
}
ManageRealization.propTypes = {
  handleAddRealization: PropTypes.func,
  handleEditRealization: PropTypes.func,
  handleGetCoa: PropTypes.func,
  handleGetDetailRealization: PropTypes.func,
  coa: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
ManageRealization.defaultProps = {
  handleAddRealization: noop,
  handleEditRealization: noop,
  handleGetCoa: noop,
  handleGetDetailRealization: noop,
};
