import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Button,
  Select,
  Input,
} from "../../../../components/base";
import {
  PEMBAYARAN_RUTIN_TABLE_FIELD_LIST,
  PEMBAYARAN_NONRUTIN_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import { errorAlert } from "../../../../utils/alert.util";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Pembayaran extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChecked = this._onChecked.bind(this);
    this._onUnchecked = this._onUnchecked.bind(this);
    this._onUncheckedAll = this._onUncheckedAll.bind(this);
    this.onClickBayar = this.onClickBayar.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.detailPayments = this.detailPayments.bind(this);
    this.cancelPayments = this.cancelPayments.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getYearsOfEducationsOptions =
      this._getYearsOfEducationsOptions.bind(this);

    const { user } = this.props;
    const { school_unit, profile } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          detail_checked: [],
          total: "",
          classes: [],
          levels_id,
          periods: "",
          listinvoice: [],
          listinvoice2: [
            {
              paymentdetails: []
            }
          ],
          classes_id: "",
          no_va: profile.no_va,
          name: user.name,
          last_name: profile.last_name,
          phone: profile.phone,
          email: profile.email,
          pg_code: "",
          payload: [],
          cek_payment: "",
        },
        error: {
          classes: "",
          levels: "",
          listinvoice: "",
          listinvoice2: "",
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getYearsOfEducationsOptions();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _onFormChange(event) {
    const { form } = this.state
    const { cek_payment } = form.value
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arrayList,
      paymentId,
    } = dataset;
    console.log(dataset)
    console.log(checked)
    if ((fieldName === "listinvoicenon" || fieldName === "listinvoice") && checked == true) {
      await this._onUncheckedAll()
    }
    if (fieldName === "listinvoicenon" && paymentId !== cek_payment){
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            cek_payment: paymentId,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }
    this.setState(
      (prevState) => {

        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {          
          if(fieldName === "listinvoicenon"){
            newList = prevState.form.value.listinvoice2[arrayList].paymentdetails;
            newListError = prevState.form.error[fieldName] || [];
            newList[arrayPosition][name] = formattedValue;
          }else {
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
          }
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }

        if (fieldName === "listinvoicenon") {
          if (type === "checkbox") {
            formattedValue = checked ? "true" : "";
            if (formattedValue == "true") {
              for(let i=0; i<=arrayPosition; i++) {
                this._onChecked(prevState.form.value.listinvoice2[arrayList].paymentdetails[i]);
              }
            } else {
              this._onUnchecked(arrayPosition);
            }
          }
        } else {
          if (type === "checkbox") {
            formattedValue = checked ? "true" : "";
            if (formattedValue == "true") {
              for(let i=0; i<=arrayPosition; i++) {
                this._onChecked(prevState.form.value[fieldName][i]);
              }
            } else {
              this._onUnchecked(arrayPosition);
            }
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  _onChecked(data) {
    const { form } = this.state;
    const { detail_checked } = form.value;

    detail_checked.push({
      invoices_id: data.invoices_id,
      temps_id: data.temps_id,
      month: data.month,
      nominal: data.nominal,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          detail_checked,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onUnchecked(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(idx, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  _onUncheckedAll() {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(0, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  openModalRincian() {
    MySwal.fire({
      title: <p>Rincian Pembayaran</p>,
      width: "350px",
      html: this.renderModalContent(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalMethod() {
    MySwal.fire({
      title: <p>Pilih Metode Pembayaran</p>,
      width: "350px",
      html: this.renderModalContentMethod(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalDetail() {
    MySwal.fire({
      width: "450px",
      html: this.renderModalContentDetail(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalShowDetail(data) {
    MySwal.fire({
      width: "450px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: '<h2>Tutup</h2>',
    });
  }

  // fungsi bayar tanpa checklist
  async onClickBayar(val) {
    const { form } = this.state
    
    await this._onUncheckedAll();
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          cek_payment: val.payments_id,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));

    await this._onChecked(val)
    this.openModalRincian();
  }

  // fungsi bayar dengan checklist
  async onSubmit() {
    const { form } = this.state;
    const { detail_checked } = form.value;

    if(isEmpty(detail_checked)){
      errorAlert({
        title: 'Tagihan belum di pilih.',
        errorMessage: 'Mohon dipilih terlebih dahulu'
      });
    }else{
      this.openModalRincian();
    }
  }

  // fungsi setelah bank di pilih
  async onSendPayment() {
    const { form } = this.state;
    const { pg_code } = form.value;

    if(isEmpty(pg_code)){
      errorAlert({
        title: 'Metode pembayaran belum di pilih.',
        errorMessage: 'Mohon dipilih terlebih dahulu'
      });
    }else{
      await this.detailPayments(); // simpan pembayaran
      this.openModalDetail(); // buka modal detail
    }
  }

  // fungsi show detail setelah simpan detail pembayaran
  async onShowDetail(data) {
    this.openModalShowDetail(data); // buka modal detail
  }
  
  // fungsi simpan detail pembayaran
  async detailPayments() {
    const { handleDetailPayments } = this.props
    const { form } = this.state;
    const { value } = form;
    const payload = await handleDetailPayments(value);

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          payload: payload,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  
  // fungsi batalkan detail pembayaran
  async cancelPayments(data) {
    const { history, handleCancelPayments } = this.props
    const { form } = this.state;
    const { value } = form;
    await handleCancelPayments(data);

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          payload: [],
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    history.go(0)
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    const { period, jenis } = filters;
    this._onUncheckedAll();
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListInvoiceRutin, handleListInvoiceNonRutin, handleListPaymentGateway } = this.props;
          const { user } = this.props;
          const { profile } = user;
          const { no_va } = profile;
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (month < 7) {
            year -= 1;
          }
          let result = {};
          let result2 = {};

          if (jenis === undefined || jenis === "rutin") {
            result = await handleListInvoiceRutin({
              ...params,
              temps_id: no_va,
              currents_year: period ? period : year,
              filters,
            });
          } else if (jenis === "nonrutin") {
            result2 = await handleListInvoiceNonRutin({
              ...params,
              temps_id: no_va,
              filters,
            });
          }
          const result3 = await handleListPaymentGateway({
            ...params,
            filters,
          });
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listinvoice: result,
                listinvoice2: result2,
                listbank: result3,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listinvoice: [],
                listinvoice2: [],
              },
            },
          }));
        }
      }
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getYearsOfEducationsOptions() {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _renderButtons(data) {

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickBayar(data)} title="Bayar" />
        </div>
      </td>
    );
    
  }

  _renderDetailButtons(data) {
    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onShowDetail(data)} title="Lihat Detail" />
        </div>
      </td>
    );
  }

  renderModalContent() {
    const { error, onChange, fieldName, plafond_name, nis, rincian } =
      this.props;
    const { list, form, param, filters } = this.state;
    const { jenis } = filters;
    const { detail_checked, total } = form.value;

    
    var cicilan = 0;
    var last = ""

    return (
      <div>
        <hr></hr>
        <br></br>
        <table
          className="manage-rapbs-plafon__form-table table-modal"
          style={{ marginBottom: "5px" }}
        >
          <thead>
            <tr>
              <th style={{ border: "none" }}>Jumlah</th>
              <th style={{ border: "none", textAlign: "right" }}>
                Rp. {commaFormatted(total)}
              </th>
            </tr>
          </thead>
        </table>
        <table className="manage-rapbs-plafon__form-table table-modal">
          <thead>
            <tr>
              <th colSpan="2">Detail Pembayaran</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <table className="cetak-buku-induk2__table-test">
                  {map(detail_checked, (list, idx) => {
                    if (jenis === "nonrutin") {
                      last = list.invoices_id.substr(list.invoices_id.length-2, list.invoices_id.length)
                      if (last === "00") {
                        cicilan = 0
                        list.month = "DP"
                      } else {
                        cicilan++
                        list.month = "Cicilan " + cicilan
                      }
                    }
                    return (
                      <tr key={`budget_row_${idx}`}>
                        <td style={{ textAlign: "left" }}>
                          {list.month}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          Rp. {commaFormatted(list.nominal)}
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="table__actions">
          <Button
            onClick={() => this.openModalMethod()}
            title="Lanjut"
          />
        </div>
      </div>
    );
  }

  renderModalContentMethod() {
    const { form } = this.state;
    const { listbank } = form.value;

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          pg_code: "",
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));

    return (
      <div>
        <hr></hr>
        <br></br>
        
        <form class="form" style={{backgroundColor: "#ccc", padding:"15px"}}>  
          {map(listbank.payment_channel, (list, idx) => (
            <div class="inputGroup">
              <input 
                id={"pg_code"+idx} 
                name="pg_code" 
                type="radio" 
                value={list.pg_code} 
                onChange={this._onFormChange}
                isRequired
              />
              
              <label for={"pg_code"+idx}>
                {list.pg_name}
              </label>
            </div>
          ))}
        </form>
        <br></br>
        <div className="table__actions">
          <Button
            onClick={() => this.openModalRincian()}
            title="Kembali"
          />
          <Button
            onClick={() => this.onSendPayment()}
            title="Lanjut"
          />
        </div>
      </div>
    );
  }

  renderModalContentDetail() {
    const { user, history } = this.props;
    const { form } = this.state;
    const { payload, total, pg_code } = form.value;
    const { organization } = user;

    var logo

    if (pg_code === "707" || pg_code === 707){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_707_logo.png"
    } else if (pg_code === "702" || pg_code === 702){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_702_logo.png"
    } else if (pg_code === "801" || pg_code === 801){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_801_logo.png"
    } else if (pg_code === "800" || pg_code === 800){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_800_logo.png"
    } else if (pg_code === "825" || pg_code === 825){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_825_logo.png"
    } else if (pg_code === "708" || pg_code === 708){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_708_logo.png"
    } else if (pg_code === "802" || pg_code === 802){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_802_logo.png"
    } else if (pg_code === "408" || pg_code === 408){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_408_logo.png"
    } else if (pg_code === "402" || pg_code === 402){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_402_logo.png"
    } else if (pg_code === "818" || pg_code === 818){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_818_logo.png"
    }

    return (
      <div>
        <br></br>
        <form class="form">  
          <img className="home__logo3" style={{width:"70px"}} src={organization.logo} />
          <h1>{organization.name}</h1>
          <br></br>
          <h2 style={{color:"#fbaf41"}}>Detail Transaksi Pemesanan</h2>
          <br></br>
          <div style={{textAlign:"left"}}>
            <p>Pembayaran Via: </p>  
            <img style={{ maxHeight:"120px"}} src={logo}></img>
          </div>
          <br></br>
          <table className="modal-pembayaran__table-test">
            <tbody>
              <tr>
                <td style={{ textAlign: "left" }}>No Virtual Account</td>
                <td style={{ textAlign: "right" }}>{payload.trx_id}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>No Order</td>
                <td style={{ textAlign: "right" }}>{payload.bill_no}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Total Pembayaran</td>
                <td style={{ textAlign: "right" }}>Rp. {commaFormatted(total)}</td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <div className="add-budget">
            <Button onClick={() => history.go(0)}>Tutup</Button>
          </div>
        </form>
        <br></br>
      </div>
    );
  }

  renderModalContentShowDetail(data) {
    const { user } = this.props;
    const { organization } = user;
    const { form } = this.state

    var logo

    if (data.pg_code === "707" || data.pg_code === 707){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_707_logo.png"
    } else if (data.pg_code === "702" || data.pg_code === 702){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_702_logo.png"
    } else if (data.pg_code === "801" || data.pg_code === 801){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_801_logo.png"
    } else if (data.pg_code === "800" || data.pg_code === 800){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_800_logo.png"
    } else if (data.pg_code === "825" || data.pg_code === 825){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_825_logo.png"
    } else if (data.pg_code === "708" || data.pg_code === 708){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_708_logo.png"
    } else if (data.pg_code === "802" || data.pg_code === 802){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_802_logo.png"
    } else if (data.pg_code === "408" || data.pg_code === 408){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_408_logo.png"
    } else if (data.pg_code === "402" || data.pg_code === 402){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_402_logo.png"
    } else if (data.pg_code === "818" || data.pg_code === 818){
      logo = "https://debit-sandbox.faspay.co.id/__assets/img-newtpl/faspay_818_logo.png"
    }

    return (
      <div>
        <br></br>
        <form class="form">  
          <img className="home__logo3" style={{width:"70px"}} src={organization.logo} />
          <h1>{organization.name}</h1>
          <br></br>
          <h2 style={{color:"#fbaf41"}}>Detail Transaksi Pemesanan</h2>
          <br></br>
          <div style={{textAlign:"left"}}>
            <p>Pembayaran Via: </p>
            <img style={{ maxHeight:"120px"}} src={logo}></img>
          </div>
          <br></br>
          <table className="modal-pembayaran__table-test">
            <tbody>
              <tr>
                <td style={{ textAlign: "left" }}>No Virtual Account</td>
                <td style={{ textAlign: "right" }}>{data.trx_id}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>No Order</td>
                <td style={{ textAlign: "right" }}>{data.faspay_id}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Total Pembayaran</td>
                <td style={{ textAlign: "right" }}>Rp. {commaFormatted(data.total)}</td>
              </tr>
            </tbody>
          </table>
          <br></br>
        </form>
        <br></br>
          <div>
            <Button onClick={() => this.cancelPayments(data)}>Batalkan Pembayaran</Button>
          </div>
        <br></br>
      </div>
    );
  }
  
  render() {
    const { param, form, filters } = this.state;
    const { listinvoice, listinvoice2, detail_checked, cek_payment } = form.value;
    const { period = "", jenis } = filters;
    const { user } = this.props;
    const { profile } = user;

    const prm_jenis = [
      { label: 'Rutin', value: 'rutin'},
      { label: 'Tidak Rutin', value: 'nonrutin'},
    ];

    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }

    form.value.total = 0;
    {
      map(detail_checked,(data, idx) =>
        (form.value.total += Number(form.value.detail_checked[idx].nominal))
      );
    }

    var cicilan = 0;
    var last = ""

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Pembayaran </h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Input
                name="name"
                label="Nama"
                value={user.name}
                onChange={this._onFormChange}
                error={form.error.name || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Input
                name="nis"
                label="NIS"
                value={profile.nis}
                onChange={this._onFormChange}
                error={form.error.nis || ""}
              />
            </div>
          </div>

          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                type="text"
                name="classrooms_id"
                label="Kelas"
                placeholder="Pilih Kelas"
                data={param.classrooms}
                onChange={this._onChangeFilter}
                value={profile.classrooms_id}
                disabled
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                name="jenis"
                label="Jenis Pembayaran"
                onChange={this._onChangeFilter}
                data={prm_jenis}
                placeholder="Pilihan"
                value={jenis}
              />
            </div>
          </div>

          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              {(jenis === undefined || jenis === "rutin") && (
                <>
                  <Select
                    name="period"
                    label="Tahun Ajaran"
                    onChange={this._onChangeFilter}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={period ? period : year}
                  />
                </>
              )}
            </div>
            <div className="absensi-rekap__custom-form-column__field">
            </div>
          </div>

        </div>
        <br></br>
        {(() => {
          if (jenis === undefined || jenis === "rutin"){
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">Rutin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <table className="table">
                        <thead>
                          <tr>
                            {map(
                              PEMBAYARAN_RUTIN_TABLE_FIELD_LIST.label,
                              (field, idx) => (
                                <th
                                  key={`table_th_${idx}`}
                                  style={{
                                    background: "#909090",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                  }}
                                >
                                  {field}
                                </th>
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {map(listinvoice, (data, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {map(
                                PEMBAYARAN_RUTIN_TABLE_FIELD_LIST.value,
                                (field, fieldIdx) => {
                                  if (field.attribute === "status") {
                                    if (data.payments_date !== null || data.faspay_id !== null) { 
                                      return <td></td>;
                                    } 
                                    else {
                                      return (
                                        <td key={`table_${fieldIdx}_${idx}`}>
                                          <Input
                                            style={{ height: "unset" }}
                                            type="checkbox"
                                            name="status"
                                            data-input-array={true}
                                            data-array-position={idx}
                                            data-field-name="listinvoice"
                                            onChange={this._onFormChange}
                                            // checked={data.status ? list.status : ""}
                                            // value={data.status ? list.status : field.value}
                                            checked={detail_checked[idx] ? true : ""}
                                            value={data.status}
                                          />
                                        </td>
                                      );
                                    }
                                  }
                                  return (
                                    <td
                                      className={
                                        field.type === "number" ? "nominal" : ""
                                      }
                                      key={`table_${fieldIdx}_${idx}`}
                                    >
                                      {formatData(data, field)}
                                    </td>
                                  );
                                }
                              )}
                              {(() => {
                                if (data.payments_date !== null){
                                  return <td style={{color:"green", textAlign:"center"}}>LUNAS</td>;
                                } else if (data.faspay_id !== null) {
                                  return this._renderDetailButtons(data);
                                } else {
                                  return <td></td>;
                                }
                              })()}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="manage-rapbs-plafon__add-more">
                    <th style={{border: "unset"}}>
                      Total: &emsp; Rp. {commaFormatted(form.value.total)}
                    </th>
                    <td>
                      <Button onClick={this.onSubmit}>Bayar</Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            )
          } else if (jenis === "nonrutin") {
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">Tidak Rutin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      {map(listinvoice2, (datalist, idxlist) => (
                        <div>
                          <h4 style={{textAlign: "center"}}>{datalist.payment_name}</h4>
                          <table className="table" style={{marginBottom: "5px"}}>
                            <thead>
                              <tr>
                                {map(
                                  PEMBAYARAN_NONRUTIN_TABLE_FIELD_LIST.label,
                                  (field, idx) => (
                                    <th
                                      key={`table_th_${idx}`}
                                      style={{
                                        background: "#909090",
                                        color: "#FFFFFF",
                                        textAlign: "center",
                                      }}
                                    >
                                      {field}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {map(datalist.paymentdetails, (data, idx) => ( 
                                <tr key={`budget_row_${idx}`}>
                                  {map(
                                    PEMBAYARAN_NONRUTIN_TABLE_FIELD_LIST.value,
                                    (field, fieldIdx) => {
                                      if (field.attribute === "status") {
                                        if (data.payments_date !== null || data.faspay_id !== null) { 
                                          return <td></td>;
                                        } else {
                                          return (
                                            <td key={`table_${fieldIdx}_${idx}`}>
                                              <Input
                                                style={{ height: "unset" }}
                                                type="checkbox"
                                                name="status"
                                                data-input-array={true}
                                                data-array-position={idx}
                                                data-field-name="listinvoicenon"
                                                data-array-list={idxlist}
                                                data-payment-id={datalist.payments_id}
                                                onChange={this._onFormChange}
                                                // checked={data.status ? list.status : ""}
                                                // value={data.status ? list.status : field.value}
                                                checked={(cek_payment == datalist.payments_id && detail_checked[idx]) ? true : ""}
                                                value={data.status}
                                              />
                                            </td>
                                          );
                                        }
                                      }
                                      if (field.attribute === "month") {
                                        last = data.invoices_id.substr(data.invoices_id.length-2, data.invoices_id.length)
                                        if (last === "00") {
                                          cicilan = 0
                                          return <td>DP</td>
                                        } else {
                                          cicilan++
                                          return <td>Cicilan {cicilan}</td>
                                        }
                                      }

                                      return (
                                        <td
                                          className={
                                            field.type === "number" ? "nominal" : ""
                                          }
                                          key={`table_${fieldIdx}_${idx}`}
                                        >
                                          {formatData(data, field)}
                                        </td>
                                      );
                                    }
                                  )}
                                  {(() => {
                                    if (data.payments_date !== null){
                                      return <td style={{color:"green", textAlign:"center"}}>LUNAS</td>;
                                    } else if (data.faspay_id !== null) {
                                      return this._renderDetailButtons(data);
                                    } else {
                                      return <td></td>
                                    }
                                  })()}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <br></br>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="manage-rapbs-plafon__add-more">
                    <th style={{border: "unset"}}>
                      Total: &emsp; Rp. {commaFormatted(form.value.total)}
                    </th>
                    <td>
                      <Button onClick={this.onSubmit}>Bayar</Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            )
          }
        })()}
        <br></br>
      </div>
    );
  }
}
Pembayaran.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Pembayaran.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
