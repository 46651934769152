import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import { ABSENSI_REKAP_PAGE2 } from "../../../constants/student.constant";
import { ABSENSI_REKAP_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
  Input2,
} from "../../../components/base/index";
import language from "../../../languages";
import AbsensiRekapForm from "../components/AbsensiRekapForm.component";
import AbsensiRekapTable from "../components/AbsensiRekapTable.component";
import AbsensiRekapPage2 from "../components/AbsensiRekapPage2.component";
import { ABSEN_REKAP_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class AbsensiRekap extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onClickAddGrafik = this.onClickAddGrafik.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    //this.deletePlafon = this.deletePlafon.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getMonthAcademicsOptions = this._getMonthAcademicsOptions.bind(this);
    this._studentCount = this._studentCount.bind(this);

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          absensiharian: [],
          classrooms: [],
          levels: [],
          classrooms_id: "",
        },
        error: {
          absensiharian: "",
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
      total_siswa: "",
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetclassrooms,
      handleGetLevels,
    } = this.props;
    const paramTypes = ["classrooms", "levels", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
      this._getStudentOptions();
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    handleGetclassrooms();
    handleGetLevels();
    this._studentCount();
    this._getMonthAcademicsOptions();
    this._getPeriodsOptions();

    // const { data = {} } = location.state;

    // this._searchCoa();

    // if (!isEmpty(data)) {
    //   this._setForm(data);
    // }
  }

  // _searchCoa(keyword = null) {
  //   const { handleGetCodeOfAccount } = this.props;

  //   handleGetCodeOfAccount({
  //     keyword,
  //     classrooms: [50000],
  //   });
  // }

  // on formchange untuk form
  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _studentCount(payload = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { handleStudentCount, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await handleStudentCount({
      ...payload,
      organizations_id: organizations_id,
      units_id: school_unit.id,
      filters,
    });
    // filters.organizations_id = organizations_id;
    // filters.classrooms_id = school_unit.id;

    this.setState((prevState) => ({
      ...prevState,
      total_siswa: res,
    }));
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }
  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getMonthAcademicsOptions(filters = {}) {
    const { getMonthAcademicsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getMonthAcademicsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        get_month: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    if (!isEmpty(filters.students_id) && filters.students_id.value === "") {
      delete filters.students_id;
    }
    if (filters.classrooms_id === "") {
      delete filters.classrooms_id;
    }
    if (filters.period === "") {
      delete filters.period;
    }
    if (filters.status_id === "") {
      delete filters.status_id;
    }
    if (filters.date === "") {
      delete filters.date;
    }
    if (filters.date_to === "") {
      delete filters.date_to;
    }
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListStudentRekapAttendance, user } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;

          const result = await handleListStudentRekapAttendance({
            ...params,
            organizations_id: organizations_id,
            // units_id: school_unit.id,
            filters,
          });
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: result,
              },
            },
          }));
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
        if (name === "classrooms_id") {
          this._studentCount({ filters: { classrooms_id: [value] } });
        }
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  // on formchange untuk tabel
  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (name === "levels_id") {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              classrooms_id: "",
            }
          }));
        }

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
          this._onSearchContent();
        }
        if (name === "classrooms_id") {
          this._getParamOptions("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
        }
        // if (name === 'classrooms') {
        //   this._getStudentOptions({ filters: { classrooms_id: value.map(item => item.value)}} );
        // }
      }
    );
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push(
      "/dashboard/kesiswaan/absensi-siswa/absensi-rekap/cetak-absensi-rekap"
    );
  }

  onClickAddGrafik() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap/grafik", {
      isEdit: true,
    });
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-laporan-8355");
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-harian", {
      date: val.date,
      classrooms_id: val.classrooms_id,
      period: val.period,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />

          {/* </div> */}
          {/* } */}
        </div>
      </td>
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    // const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
    } = this.state;
    const { absensiharian } = form.value;
    const {
      classrooms_id = "",
      students_id = "",
      period = "",
      date = "",
      date_to = "",
      status_id = "",
    } = filters;
    const { classrooms = {}, levels = {} } = this.props;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const status = [
      { label: "Hadir", value: "HADIR" },
      { label: "Sakit", value: "SAKIT" },
      { label: "Izin", value: "IZIN" },
      { label: "Alpa", value: "ALPA" },
      { label: "Terlambat", value: "TERLAMBAT" },
    ];
    // console.log(form.value.jml_hari);
    return (
      <div className="manage-registration">
        <form onSubmit={this.onSubmit}>
          <div className="budget__title">
            <h1>Absensi Rekap</h1>
          </div>

          <div className="kode-matpel__custom-form">
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-column__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="students_id"
                  list={param.student}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Nama Siswa"
                  value={filters.students_id}
                  // labelName="label"
                  // valueName="value"
                  rightIcon="icon-search"
                  label="Nama Siswa"
                />
              </div>
            </div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <Select
                  type="text"
                  name="levels_id"
                  label="Unit"
                  placeholder="Pilih Unit"
                  data={param.levels}
                  // disabled
                  value={form.value.levels_id}
                  onChange={this._onFormChange}
                  error={form.error.levels_id || ""}
                />
              </div>

              <div className="kode-matpel__custom-form-column__field">
                <Select
                  placeholder="Pilih Kelas"
                  label="Kelas"
                  name="classrooms_id"
                  value={classrooms_id}
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                />
              </div>
            </div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  // data={ levels.list}
                  value={period}
                  placeholder={"Pilihan"}
                  data={param.periods}
                  // error={form.error.namaSaudara1 || ''}
                />
              </div>

              <div className="kode-matpel__custom-form-column__field">
                <Select
                  label="Keterangan"
                  name="status_id"
                  onChange={this._onChangeFilter}
                  placeholder="Pilihan"
                  data={status}
                  value={status_id}
                />
              </div>
            </div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <div className="data-pelanggaran__custom-form-row__field">
                  <Input2
                    type="date"
                    name="date"
                    label="Tanggal"
                    onChange={this._onChangeFilter}
                    value={date}
                  />
                  <div className="data-pelanggaran__custom-form-row__field"></div>
                  <Input2
                    type="date"
                    name="date_to"
                    label="Sampai Dengan"
                    onChange={this._onChangeFilter}
                    value={date_to}
                    //  placeholder={'2019 - 2020'}
                  />
                </div>
              </div>

              <div className="kode-matpel__custom-form-column__field">
                <Input
                  type="text"
                  name="jml_hari"
                  label="Jumlah Hari Efektif"
                  onChange={this._onFormChange}
                  value={form.value.jml_hari || ""}
                  error={form.error.jml_hari || ""}
                  //  placeholder={'2019 - 2020'}
                />
              </div>
            </div>
          </div>
          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    {map(ABSEN_REKAP_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {map(absensiharian, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        ABSEN_REKAP_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <a
                                  href={"https://" + list.hyperlink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {list.hyperlink}
                                </a>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          if (field.attribute == "percent") {
                            let total_hari_efektif = (
                              (list.total /
                                (total_siswa * form.value.jml_hari)) *
                              100
                            ).toFixed(2);
                            let percent = total_hari_efektif * 100;

                            return <td>{percent ? percent + "%" : 0}</td>;
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtons(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
AbsensiRekap.propTypes = {
  handleGetLevels: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
  getStudentOptions: PropTypes.func,
};
AbsensiRekap.defaultProps = {
  handleGetLevels: noop,
  handleGetclassrooms: noop,
  getStudentOptions: noop,
};
