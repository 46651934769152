import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map} from 'lodash';
import StudentReportFilter from '../../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { Input, Select, Button } from '../../../../components/base/index';
import IndividualForm from './components/IndividualForm.component';
import ProsesInvoiceTbl from './components/ProsesInvoiceTbl.component';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class GenerateInvoice extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this.renderExcel = this.renderExcel.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.state= {
          counter:0,
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              plafon: [],
            },
            error: {
              plafon: '',
    
            },
          },
        };
      
      }

      componentDidMount() {
        const { handleGetCodeOfAccount, location, handleGetClasses } = this.props;
       handleGetClasses();
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/keuangan/spp/create-invoice/generate-invoice');
      }

      _renderButtons() {
        const { user, location } = this.props;
        const { data = {} } = location.state
        const { user_groups_id, user_group } = user;
        const { workflow = [] } = data;
        const lastWorkflow = workflow[workflow.length - 1] || {};
        const firstWorkflow = workflow[0] || {};
        const WorkflowPrev_role = workflow[0]?workflow[0].prev_role:'';
        const { permissions = [] } = user_group;
        
       
          return [
            <div key="button_save">
              <Button
                type="submit"
                title="Save"
              />
            </div>,
            <div key="button_submit">
              <Button
                type="button"
                onClick={this._onSubmitApproval}
                title={(user_group.name == 'Hrd' || user_group.name =='Kepala Sekolah' || user_group.name =='Opsdik'
                        || user_group.name =='Sekretariat') ? 'Submit' : 'Approve'}
              />
            </div>,
          ];
      
      }

      renderExcel(list) {
        let excelTbl = [];
        map(list, (item, idx_header_tema) => {
            // console.log(data.divisi)
            let items = Object.create({});
            // items.no_virtual = item.no_virtual;
            // items.key2 = item.key2;
            // items.key3 = item.key3;
            // items.currency = item.currency;
            // items.billinfo_1 = item.billinfo_1;
            // items.billinfo_2 = item.billinfo_2;
            // items.billinfo_3 = item.billinfo_3;
            // items.billinfo_4 = item.billinfo_4;
            // items.billinfo_5 = item.billinfo_5;
            // items.billinfo_6 = item.billinfo_6;
            // items.billinfo_7 = item.billinfo_7;
            // items.billinfo_8 = item.billinfo_8;
            // items.billinfo_9 = item.billinfo_9;
            // items.billinfo_10 = item.billinfo_10;
            // items.billinfo_11 = item.billinfo_11;
            // items.billinfo_12 = item.billinfo_12;
            // items.billinfo_13 = item.billinfo_13;
            // items.billinfo_14 = item.billinfo_14;
            // items.billinfo_15 = item.billinfo_15;
            // items.billinfo_16 = item.billinfo_16;
            // items.billinfo_17 = item.billinfo_17;
            // items.billinfo_18 = item.billinfo_18;
            // items.billinfo_19 = item.billinfo_19;
            // items.billinfo_20 = item.billinfo_20;
            // items.billinfo_21 = item.billinfo_21;
            // items.billinfo_22 = item.billinfo_22;
            // items.billinfo_23 = item.billinfo_23;
            // items.billinfo_24 = item.billinfo_24;
            // items.billinfo_25 = item.billinfo_25;
            // items.periode_open = item.periode_open;
            // items.periode_close = item.periode_close;
            // items.subbill_1 = item.subbill_1;
            // items.subbill_2 = item.subbill_2;
            // items.subbill_3 = item.subbill_3;
            // items.subbill_4 = item.subbill_4;
            // items.subbill_5 = item.subbill_5;
            // items.subbill_6 = item.subbill_6;
            // items.subbill_7 = item.subbill_7;
            // items.subbill_8 = item.subbill_8;
            // items.subbill_9 = item.subbill_9;
            // items.subbill_10 = item.subbill_10;
            // items.subbill_11 = item.subbill_11;
            // items.subbill_12 = item.subbill_12;
            // items.subbill_13 = item.subbill_13;
            // items.subbill_14 = item.subbill_14;
            // items.subbill_15 = item.subbill_15;
            // items.subbill_16 = item.subbill_16;
            // items.subbill_17 = item.subbill_17;
            // items.subbill_18 = item.subbill_18;
            // items.subbill_19 = item.subbill_19;
            // items.subbill_20 = item.subbill_20;
            // items.subbill_21 = item.subbill_21;
            // items.subbill_22 = item.subbill_22;
            // items.subbill_23 = item.subbill_23;
            // items.subbill_24 = item.subbill_24;
            // items.subbill_25 = item.subbill_25;
            // items.end_record = item.end_record;
           
            // excelTbl.push(items);
        
        
  
              });
              let rows=[];
              rows.push([
        
                  
                <ExcelFile element={<Button  style={{height:30, width:250}}>Excel</Button>}>
            
                <ExcelSheet data={excelTbl} name="excel">
                    {/* <ExcelColumn label="Kode" value="code2"/> */}
                    <ExcelColumn label="No Virtual" value="no_virtual"/>
                    <ExcelColumn label="Key 2" style ={{backgroundColor:'blue'}} value="key2"/>
                    <ExcelColumn label="Key 3" value="key3"/>
                    <ExcelColumn label="Currency" value="currency"/>
                    <ExcelColumn label="Bill Info 1" value="billinfo_1"/>
                    <ExcelColumn label="Bill Info 2" value="billinfo_2"/>
                    <ExcelColumn label="Bill Info 3" value="billinfo_3"/>
                    <ExcelColumn label="Bill Info 4" value="billinfo_4"/>
                    <ExcelColumn label="Bill Info 5" value="billinfo_5"/>
                    <ExcelColumn label="Bill Info 6" value="billinfo_6"/>
                    <ExcelColumn label="Bill Info 7" value="billinfo_7"/>
                    <ExcelColumn label="Bill Info 8" value="billinfo_8"/>
                    <ExcelColumn label="Bill Info 9" value="billinfo_9"/>
                    <ExcelColumn label="Bill Info 10" value="billinfo_10"/>
                    <ExcelColumn label="Bill Info 11" value="billinfo_11"/>
                    <ExcelColumn label="Bill Info 12" value="billinfo_12"/>
                    <ExcelColumn label="Bill Info 13" value="billinfo_13"/>
                    <ExcelColumn label="Bill Info 14" value="billinfo_14"/>
                    <ExcelColumn label="Bill Info 15" value="billinfo_15"/>
                    <ExcelColumn label="Bill Info 16" value="billinfo_16"/>
                    <ExcelColumn label="Bill Info 17" value="billinfo_17"/>
                    <ExcelColumn label="Bill Info 18" value="billinfo_18"/>
                    <ExcelColumn label="Bill Info 19" value="billinfo_19"/>
                    <ExcelColumn label="Bill Info 20" value="billinfo_20"/>
                    <ExcelColumn label="Bill Info 21" value="billinfo_21"/>
                    <ExcelColumn label="Bill Info 22" value="billinfo_22"/>
                    <ExcelColumn label="Bill Info 23" value="billinfo_23"/>
                    <ExcelColumn label="Bill Info 24" value="billinfo_24"/>
                    <ExcelColumn label="Bill Info 25" value="billinfo_25"/>
                    <ExcelColumn label="Periode open" value="periode_open"/>
                    <ExcelColumn label="Periode close" value="periode_close"/>
                    <ExcelColumn label="SubBill 1" value="subbill_1"/>
                    <ExcelColumn label="SubBill 2" value="subbill_2"/>
                    <ExcelColumn label="SubBill 3" value="subbill_3"/>
                    <ExcelColumn label="SubBill 4" value="subbill_4"/>
                    <ExcelColumn label="SubBill 5" value="subbill_5"/>
                    <ExcelColumn label="SubBill 6" value="subbill_6"/>
                    <ExcelColumn label="SubBill 7" value="subbill_7"/>
                    <ExcelColumn label="SubBill 8" value="subbill_8"/>
                    <ExcelColumn label="SubBill 9" value="subbill_9"/>
                    <ExcelColumn label="SubBill 10" value="subbill_10"/>
                    <ExcelColumn label="SubBill 11" value="subbill_11"/>
                    <ExcelColumn label="SubBill 12" value="subbill_12"/>
                    <ExcelColumn label="SubBill 13" value="subbill_13"/>
                    <ExcelColumn label="SubBill 14" value="subbill_14"/>
                    <ExcelColumn label="SubBill 15" value="subbill_15"/>
                    <ExcelColumn label="SubBill 16" value="subbill_16"/>
                    <ExcelColumn label="SubBill 17" value="subbill_17"/>
                    <ExcelColumn label="SubBill 18" value="subbill_18"/>
                    <ExcelColumn label="SubBill 19" value="subbill_19"/>
                    <ExcelColumn label="SubBill 20" value="subbill_20"/>
                    <ExcelColumn label="SubBill 21" value="subbill_21"/>
                    <ExcelColumn label="SubBill 22" value="subbill_22"/>
                    <ExcelColumn label="SubBill 23" value="subbill_23"/>
                    <ExcelColumn label="SubBill 24" value="subbill_24"/>
                    <ExcelColumn label="SubBill 25" value="subbill_25"/>
                    <ExcelColumn label="End Record" value="end_record"/>
                </ExcelSheet>
                </ExcelFile>
                
              ]);
              return rows;
            }

renderHeader() {
    const {
        user,
    } = this.props;
  

    return (
        <thead>
        <tr>
            <th className="manage-budget__row table" style={{ width: "5%" }}>No Virtual</th>
            <th className="manage-budget__row table" style={{ width: "5%" }}>Key 2</th>
            <th className="manage-budget__row table" style={{ width: "5%" }}>Key 3</th>
            <th className="manage-budget__row table" style={{ width: "5%" }}>Currency</th>
            <th className="manage-budget__row table" >Bill Info 1</th>
            {/* <th className="manage-budget__row table" >Bill Info 2</th>
            <th className="manage-budget__row table" >Bill Info 3</th>
            <th className="manage-budget__row table" >Bill Info 4</th>
            <th className="manage-budget__row table" >Bill Info 5</th>
            <th className="manage-budget__row table" >Bill Info 6</th>
            <th className="manage-budget__row table" >Bill Info 7</th>
            <th className="manage-budget__row table" >Bill Info 8</th>
            <th className="manage-budget__row table" >Bill Info 9</th>
            <th className="manage-budget__row table" >Bill Info 10</th>
            <th className="manage-budget__row table" >Bill Info 11</th>
            <th className="manage-budget__row table" >Bill Info 12</th>
            <th className="manage-budget__row table" >Bill Info 13</th>
            <th className="manage-budget__row table" >Bill Info 14</th>
            <th className="manage-budget__row table" >Bill Info 15</th>
            <th className="manage-budget__row table" >Bill Info 16</th>
            <th className="manage-budget__row table" >Bill Info 17</th>
            <th className="manage-budget__row table" >Bill Info 18</th>
            <th className="manage-budget__row table" >Bill Info 19</th>
            <th className="manage-budget__row table" >Bill Info 20</th>
            <th className="manage-budget__row table" >Bill Info 21</th>
            <th className="manage-budget__row table" >Bill Info 22</th>
            <th className="manage-budget__row table" >Bill Info 23</th>
            <th className="manage-budget__row table" >Bill Info 24</th>
            <th className="manage-budget__row table" >Bill Info 25</th> */}
            <th className="manage-budget__row table" >Periode open</th>
            <th className="manage-budget__row table" >Periode close</th>
            <th className="manage-budget__row table" >SubBill 1</th>
            {/* <th className="manage-budget__row table" >SubBill 2</th>
            <th className="manage-budget__row table" >SubBill 3</th>
            <th className="manage-budget__row table" >SubBill 4</th>
            <th className="manage-budget__row table" >SubBill 5</th>
            <th className="manage-budget__row table" >SubBill 6</th>
            <th className="manage-budget__row table" >SubBill 7</th>
            <th className="manage-budget__row table" >SubBill 8</th>
            <th className="manage-budget__row table" >SubBill 9</th>
            <th className="manage-budget__row table" >SubBill 10</th>
            <th className="manage-budget__row table" >SubBill 11</th>
            <th className="manage-budget__row table" >SubBill 12</th>
            <th className="manage-budget__row table" >SubBill 13</th>
            <th className="manage-budget__row table" >SubBill 14</th>
            <th className="manage-budget__row table" >SubBill 15</th>
            <th className="manage-budget__row table" >SubBill 16</th>
            <th className="manage-budget__row table" >SubBill 17</th>
            <th className="manage-budget__row table" >SubBill 18</th>
            <th className="manage-budget__row table" >SubBill 19</th>
            <th className="manage-budget__row table" >SubBill 20</th>
            <th className="manage-budget__row table" >SubBill 21</th>
            <th className="manage-budget__row table" >SubBill 22</th>
            <th className="manage-budget__row table" >SubBill 23</th>
            <th className="manage-budget__row table" >SubBill 24</th>
            <th className="manage-budget__row table" >SubBill 25</th> */}
            <th className="manage-budget__row table" >End Record</th>
        </tr>
        </thead>

    )
    }
        
      _onClickAdd(fieldName) {
        const { form } = this.state;
        const { plafon } = form.value;
    
        const {plafond_name} = form.value;
        // const {code_plafond} = form.value;
    
        const {code_plafond} = form.value;
    
        // const {code_class} = form.value;
    
        plafon.push({
          // code_class :'',
    
          // code_plafond : ',',
          plafond_name:',',
    
          code_plafond : ',',
    
        
        // plafon.push({
          tipePlafon: '',
          // divisi: '',
    
          nominal: 0,
          //period:'',
        });

        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              plafon,
              plafond_name,
              // code_plafond,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
      }
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { plafon } = prevState.form.value;
          const list = prevState.form.value.plafon;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            plafon: list
          };
        });
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
   
      render() {
        const {form} = this.state;
        const { value, error } = form;
        const list = this.props;
        const { classes ={}, location, plafond = {}} = this.props;
    
        const {
          reconciliation,
          standard,
          tipe,
          type,
          is_posted,
          is_reconciliation
        } = form.value;
        return (
            <div>
            {this.renderExcel(list)}
            <br></br>
            <table className="table">
                {this.renderHeader()}
            </table>
            </div>
        );
      }
}
GenerateInvoice.propTypes = {
  handleGetClasses: PropTypes.func,   
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
GenerateInvoice.defaultProps = {
  handleGetClasses: noop,  
  handleGetStudent: noop,
};
