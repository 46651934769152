import { connect } from 'react-redux';
import Kurikulum from '../../views/Kurikulum/Kurikulum.view';
function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      levels: state.options.levels,
      user: state.user || {},
  
    }
  }
export default connect(mapStateToProps, null)(Kurikulum);
