import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class RencanaNilai extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickRencanaNilai = this.onClickRencanaNilai.bind(this);
    this.onClickPengisianKD = this.onClickPengisianKD.bind(this);
    this.onClickLaporanRPP = this.onClickLaporanRPP.bind(this);
  }

  onClickRencanaNilai() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/upload-rpp');
  }

  onClickPengisianKD() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/pengisian-kd');
  }

  onClickLaporanRPP() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/laporan-rpp-rpk');
  }

 

  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickRencanaNilai}>
            <img src={budgeting} alt="kode" />
            <h3>Upload RPP</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPengisianKD}>
            <img src={budgeting} alt="pengisiankd" />
            <h3>Pengisian KD</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickLaporanRPP}>
            <img src={budgeting} alt="rpprpk" />
            <h3>Laporan RPP RPK</h3>
          </button>
        </div>
        {/* <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={budgeting} alt="observasiharian" />
            <h3>Pengisian Observasi Harian</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickMingguan}>
            <img src={budgeting} alt="observasimingguan" />
            <h3>Pengisian Observasi Mingguan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilaiReport}>
            <img src={budgeting} alt="daftarnilaireport"/>
            <h3>Daftar Nilai / Report</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
RencanaNilai.propTypes = {
  history: PropTypes.object.isRequired,
};
