import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function NilaiK13Form(props) {
  const { onFormChange, form,classes } = props;
  return (
    <div className="nilai-ktsp__custom-form">
      <div className="nilai-ktsp__custom-form-column">
        <div className="nilai-ktsp__custom-form-row__field">
        <div className="nilai-ktsp__custom-form-row2__field">
        <Select
            name="mapel"
            label="Mata Pelajaran"
            onChange={onFormChange}
            data={ '01', 'kelas7a'}
            value={form.value.mapel}
            error={form.error.mapel || ''}
          />
        </div>
        
           
           

        <div className="nilai-ktsp__custom-font__field">
            <p>Bobot</p>
         </div>
         </div>
         
        
         <div className="nilai-ktsp__custom-form-row">
         <Select
            name="periode"
            label="Periode"
            onChange={onFormChange}
            data={ 'Semester 1', 'sms1'}
            value={form.value.periode}
            error={form.error.periode || ''}
          />
          
          
          <div className="nilai-ktsp__custom-form-row__field"></div>
          <Input
            type="text"
            name="uh"
            label="UH"
            disabled
            onChange={onFormChange}
            style={{backgroundColor:'#999'}}
            value={form.value.uh || ''}
            error={form.error.uh || ''}
          />
         </div>
         <div className="nilai-ktsp__custom-form-row">
         <Select
            name="classes"
            label="Kelas"
            onChange={onFormChange}
            data={classes.list}
            value={form.value.classes || ''}
            error={form.error.classes || ''}
          />
          
          
          <div className="nilai-ktsp__custom-form-row__field"></div>
          <Input
            type="text"
            name="pts"
            label="PTS"
            disabled
            onChange={onFormChange}
            style={{backgroundColor:'#999'}}
            value={form.value.pts || ''}
            error={form.error.pts || ''}
          />
         </div>
          <div className="nilai-ktsp__custom-form-row">
          <Input
            type="text"
            name="kkm"
            label="KKM"
            onChange={onFormChange}
            disabled
            style={{backgroundColor:'#999'}}
            value={form.value.kkm || ''}
            error={form.error.kkm || ''}
          />
          
          
          <div className="nilai-ktsp__custom-form-row__field"></div>
          <Input
            type="text"
            name="pas"
            label="PAS"
            disabled
            style={{backgroundColor:'#999'}}
            onChange={onFormChange}
            value={form.value.pas || ''}
            error={form.error.pas || ''}
          />
         </div>
        
           
      </div>
    </div>
  );
}
NilaiK13Form.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
NilaiK13Form.defaultProps = {
  onFormChange: noop,
  classes: [],
};
