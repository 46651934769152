import CatatanAnekdotEdit from "../../../views/Kurikulum/Ulangan/CatatanAnekdotEdit.view";
import { connect } from "react-redux";
import { getStudent } from "../../../states/thunks/student.thunk";
import { editLembarObsHarian, saveCatatanAnekdot, getCatatanAnekdot } from "../../../states/thunks/academics.thunk";
import { getPeriodsOptions, getPrmSubTema, getKDMaterial, getPrmTema } from "../../../states/thunks/options.thunk";

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
    levels: state.options.levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
    handleEditLembarObsHarian: (payload, goBack) => dispatch(editLembarObsHarian(payload, goBack)),
    handleSaveCatatanAnekdot: (payload, goback) => dispatch(saveCatatanAnekdot(payload, goback)),
    handleGetCatatanAnekdot: (payload) => dispatch(getCatatanAnekdot(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmSubTema: (payload) => dispatch(getPrmSubTema(payload)),
    getKDMaterial: (payload) => dispatch(getKDMaterial(payload)),
    getPrmTema: (payload) => dispatch(getPrmTema(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CatatanAnekdotEdit);
