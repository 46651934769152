import { connect } from 'react-redux';
import InformationLetter from '../../../views/Personnel/Letter/InformationLetter.letter.view';
import { getInformationLetter, deleteInformationLetter } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetInformationLetter: payload => dispatch(getInformationLetter(payload)),
    handleDeleteInformationLetter: payload => dispatch(deleteInformationLetter(payload)),
  };
}

export default connect(null, mapDispatchToProps)(InformationLetter);
