import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { Button, Pagination } from "../../../components/base/index";
import PengisianDataPelanggaran from "../components/PengisianDataPelanggaran.component";
import { FOLDER_CATAT_PELANGGARAN_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CatatPelanggaran extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getViolationsOptions = this._getViolationsOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);

    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      page: 1,
      list: [],
      listAmount: 5,
      form: {
        value: {
          organizations_id: organizations_id,
        },
        error: {
          classrooms: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    const paramTypes = ["levels", "classes", "classrooms"];
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    this.handleGetData();
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getSubjectOptions({ filters: { units_id: [school_unit.id] } });
    this._getViolationsOptions();
    this._getPeriodsOptions();
    this._getStudentOptions();
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getSubjectOptions(filters);
    filters.units_id = school_unit.id;

    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    // filters.levels_id = levels_id;

    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getViolationsOptions() {
    const { getViolationsOptions } = this.props;
    const res = await getViolationsOptions();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_violations: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this.handleGetData();
    }
  }

  async handleGetData(payload = {}) {
    const { handleListViolations, user } = this.props;
    const { school_unit, workingUnit, organizations_id } = user;

    const res = await handleListViolations({
      ...payload,
      // units_id : school_unit.id,
      organizations_id: organizations_id,
    });

    this.setState({
      list: res,
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetViolations } = this.props;
    try {
      const payload = await handleGetViolations({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            period: payload.period,
            date: payload.date,
            classrooms_id: payload.classrooms_id,
            hour: payload.hour,
            minute: payload.minute,
            nis: payload.nis,
            name: payload.name,
            type_violation: payload.type_violation,
            subject_id: payload.subject_id,
            order_of_violation: payload.order_of_violation,
            score: payload.score,
            description: payload.description,
            penalty: payload.penalty,
            students_id: payload.students_id,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (name === "levels_id") {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classes_id: "",
                classrooms_id: "",
                students_id: "",
              }
            }
          }));
        }

        if (name === "classes_id") {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                students_id: "",
              }
            }
          }));
        }

        if (name === "classrooms_id") {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                students_id: "",
              }
            }
          }));
        }

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
          this._getParamOptionsAcademics("classrooms", { organizations_id: organizations_id });
          this._getSubjectOptions({ filters: {units_id: [school_unit.id]} } );
          this._getStudentOptions();
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", { classes_id: value, organizations_id: organizations_id });
          this._getSubjectOptions({ filters: {classes_id: value} } );
          this._getStudentOptions();
        }
        if (name === "classrooms_id") {
          this._getStudentOptions({
            filters: { classrooms_id: [value], organizations_id: organizations_id },
          });
        }
      }
    );
  }

  async onSubmit() {
    const { history, handleSaveViolations } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {

    const error = validateRequiredFields(form.value, ["period", "classrooms_id", "students_id", "type_violation", "subject_id", "description"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      const res = await handleSaveViolations(value);
      if (res) {
        this.setState((prevState) => ({
          ...prevState,
          isEdit: false,
          form: {
            ...prevState.form,
            value: {
              organizations_id : value.organizations_id,
            },
          },
        }));
        this.handleGetData();
      }
      // history.push("/dashboard/kesiswaan/displiner/catat-pelanggaran");
      // history.go(0);
    }
    // }
  }
  async onEdit() {
    const { history, handleEditViolations } = this.props;
    const { form } = this.state;
    const { value } = form;
    
    const error = validateRequiredFields(form.value, ["period", "classrooms_id", "students_id", "type_violation", "subject_id", "description"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      const res = await handleEditViolations(value);
      if (res) {
        this.setState((prevState) => ({
          ...prevState,
          isEdit: false,
          form: {
            ...prevState.form,
            value: {
              organizations_id : value.organizations_id,
            },
          },
        }));
        this.handleGetData();
      }
      // history.push("/dashboard/kesiswaan/displiner/catat-pelanggaran");
      // history.go(0);
    }
  }
  onClickEdit(val) {
    if (val) {
      this.setState({ 
        id: val.id,
        isEdit: true,
      });
      this._setForm(val.id);
    }
    // const { history } = this.props;
    // history.push("/dashboard/kesiswaan/displiner/catat-pelanggaran", {
    //   data: val,
    //   isEdit: true,
    // });
    // history.go(0);
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
        </div>
      </td>
    );
  }

  render() {
    const { form, page, list, content, param } = this.state;
    const { classrooms = {} } = this.props;
    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Catat Pelanggaran</h1>
        </div>

        <div className="upload-student__content">
          {
            <PengisianDataPelanggaran
              form={form}
              param={param}
              classrooms={classrooms}
              onFormChange={this._onFormChange}
            />
          }

          <div className="manage-registration">
            <div className="accident__content">
              {this.state.id ? (
                <Button type="button" title="Update" onClick={this.onEdit} />
              ) : (
                <Button type="button" title="Simpan" onClick={this.onSubmit} />
              )}
            </div>
          </div>

          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(
                    FOLDER_CATAT_PELANGGARAN_TABLE_FIELDS.label,
                    (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {map(list.result, (data, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(
                      FOLDER_CATAT_PELANGGARAN_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.type === "link") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <a
                                href={"https://" + data.hyperlink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.hyperlink}
                              </a>
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(data, field)}
                          </td>
                        );
                      }
                    )}
                    {this._renderButtons(data)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {!isEmpty(list.result) && (
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
              </p>
              <Pagination
                totalPage={list.totalPage}
                currentPage={list.currentPage}
                onClick={this.onChangePage}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
CatatPelanggaran.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CatatPelanggaran.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
};
