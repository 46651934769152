import { connect } from 'react-redux';
import EntryDataIndividu from '../../../views/Library/EntryData/EntryDataIndividu.library.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(EntryDataIndividu);
