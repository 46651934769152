import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function AnalisaNilaiForm(props) {
  const { onFormChange, form, classes } = props;
  return (
    <div className="nilai-ktsp__custom-form">
      <div className="nilai-ktsp__custom-form-column">
        <div className="nilai-ktsp__custom-form-row__field">
        <div className="nilai-ktsp__custom-form-row2__field">
        <Select
            name="mapel"
            label="Mata Pelajaran"
            onChange={onFormChange}
            data={ '01', 'kelas7a'}
            value={form.value.mapel}
            error={form.error.mapel || ''}
          />
        </div>
        
           
           

        <div className="nilai-ktsp__custom-font__field">
            <p>Keterangan</p>
         </div>
         </div>
         
        
         <div className="nilai-ktsp__custom-form-row">
         <Select
            name="periode"
            label="Periode"
            onChange={onFormChange}
            data={ 'Semester 1', 'sms1'}
            value={form.value.periode}
            error={form.error.periode || ''}
          />
          
          
          <div className="nilai-ktsp__custom-form-row__field"></div>
          <Input
            type="text"
            name="daya_serap"
            label="Daya Serap"
            onChange={onFormChange}
            disabled
            style={{backgroundColor:'#999'}}
            value={form.value.daya_serap || ''}
            error={form.error.daya_serap || ''}
          />
         </div>
         <div className="nilai-ktsp__custom-form-row">
         <Select
            name="classes"
            label="Kelas"
            onChange={onFormChange}
            data={classes.list}
            value={form.value.classes || ''}
            error={form.error.classes || ''}
          />
          
          
          <div className="nilai-ktsp__custom-form-row__field"></div>
          <Input
            type="text"
            name="keuntungan"
            label="Ketuntasan Belajar"
            onChange={onFormChange}
            disabled
            style={{backgroundColor:'#999'}}
            value={form.value.keuntungan || ''}
            error={form.error.keuntungan || ''}
          />
         </div>
          <div className="nilai-ktsp__custom-form-row3__field">
          <Input
            type="text"
            name="kkm"
            label="KKM"
            onChange={onFormChange}
            disabled
            style={{backgroundColor:'#999'}}
            value={form.value.kkm || ''}
            error={form.error.kkm || ''}
          />
         </div>
        
           
      </div>
    </div>
  );
}
AnalisaNilaiForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
AnalisaNilaiForm.defaultProps = {
  onFormChange: noop,
  classes: [],
};
