import { connect } from 'react-redux';
import NonKasJournal from '../../../../views/Finance/Bookkeeping/NonKasJournal/NonKasJournal.bookkeeping.view';
import { getNonKasJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetNonKasJournal: payload => dispatch(getNonKasJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(NonKasJournal);
