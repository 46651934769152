import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import jenis_ekstrakulikuler from '../../../assets/img/akademi/ekstrakulikuler/jenis_ekstrakulikuler.png';
import daftar_peserta_ekskul from '../../../assets/img/akademi/ekstrakulikuler/daftar_peserta_ekskul.png';
import pembagian_kelas_ekskul from '../../../assets/img/akademi/ekstrakulikuler/pembagian_kelas_ekskul.png';
import absensi_ekskul from '../../../assets/img/akademi/ekstrakulikuler/absensi_ekskul.png';
import pengisian_nilai_ekskul from '../../../assets/img/akademi/ekstrakulikuler/pengisian_nilai_ekskul.png';

export default class Ekskul extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPembagianKelasEkskul = this.onClickPembagianKelasEkskul.bind(this);
    this.onClickJenisEkskul = this.onClickJenisEkskul.bind(this);
    this.onClickDaftarPesertaEkskul= this.onClickDaftarPesertaEkskul.bind(this);
    this.onClickAbsensiEkskul= this.onClickAbsensiEkskul.bind(this);
    this.onClickPengisianNilaiEkskul = this.onClickPengisianNilaiEkskul.bind(this);
  }
  onClickPembagianKelasEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul');
  }
  onClickJenisEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler');
  }

  onClickDaftarPesertaEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul');
  }

  onClickAbsensiEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul');
  }

  onClickPengisianNilaiEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul');
  }


  render() {
    return (
      <div className="akademi">
          <div className="akademi__list">
          <button onClick={this.onClickJenisEkskul}>
            <img src={jenis_ekstrakulikuler} alt="JenisEkskul" />
            <h3>Jenis Ekstrakulikuler</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickDaftarPesertaEkskul}>
            <img src={daftar_peserta_ekskul} alt="DaftarPesertaEkskul" />
            <h3>Daftar Peserta Ekskul</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPembagianKelasEkskul}>
            <img src={pembagian_kelas_ekskul} alt="PembagianKelasEkskul" />
            <h3>Pembagian Kelas Ekskul</h3>
          </button>
        </div>
      
        <div className="akademi__list">
          <button onClick={this.onClickAbsensiEkskul}>
            <img src={absensi_ekskul} alt="AbsensiEkskul" />
            <h3>Absensi Ekskul</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPengisianNilaiEkskul}>
            <img src={pengisian_nilai_ekskul} alt="PengisianNilaiEkskul" />
            <h3>Pengisian Nilai Ekskul</h3>
          </button>
        </div>
        
      
      </div>
    );
  }
}
Ekskul.propTypes = {
  history: PropTypes.object.isRequired,
};
