import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_HARIAN } from "../../../constants/student.constant";
import { ABSENSI_HARIAN_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { Button, Input, Select } from "../../../components/base/index";
import { ANALISA_DISPLINER_FORM_FIELDS } from "../../../constants/Student/displiner.constant";
import KodeMatpelTable from "./components/KodeMatpelTable.component";
import JadwalMatpelTable1 from "./components/JadwalMatpelTable1.component";
import ReactToPrint from "react-to-print";
import { JADWAL_MATPEL_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JadwalMatpel extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickCetak = this._onClickCetak.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          classrooms: [],
          levels: [],
          classrooms_id: "",
          listjadwal: [],
        },
        error: {
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/mata-pelajaran/isi-data-atau-edit-mata-pelajaran",
      { isEdit: true }
    );
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/mata-pelajaran/isi-data-atau-edit-mata-pelajaran",
      { data: val, isEdit: true }
    );
  }

  _onClickCetak() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/mata-pelajaran/cetak/jadwal-mata-pelajaran"
    );
  }
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: 12,
          });
        }
      }
    );
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetclassrooms,
      handleGetLevels,
    } = this.props;
    const paramTypes = ["classrooms", "levels", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 12;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListScheduleSubject, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;

          const result = await handleListScheduleSubject({
            ...params,
            organizations_id: 12,
            // units_id,
            filters,
          });
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listjadwal: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listjadwal: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  async onClickDelete(id) {
    const { handleDeleteScheduleSubject } = this.props;
    const res = await handleDeleteScheduleSubject(id);
    if (res) {
      this._onSearchContent();
    }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          <Button onClick={() => this.onClickDelete(data)} title="Delete" />
        </div>
      </td>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    // const { form} = this.state;
    const { form } = this.state;
    const { handleAddBudget, history, user } = this.props;
    const { workingUnit, prm_school_units_id } = user;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    handleAddBudget(form.value, history.goBack, unit_id);
    // handleAddBudget(form.value, plafon, history.goBack, unit_id);
    //handleAddBudget(form.value, history.goBack, unit_id);
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: 12 },
          });
        }
        this._onSearchContent();
      }
    );
  }

  render() {
    const { form, content, param, filters } = this.state;
    const { value } = form;
    const { listjadwal } = form.value;

    const { classrooms_id = "" } = filters;
    const { handleGetKelas, handleDeleteKelas, coa, classrooms } = this.props;
    const componentRef = React.createRef();
    return (
      <div className="add-budget">
        <div className="student-list__header">
          <div className="student-list__button">
          <Select
            type="text"
            name="levels_id"
            label="Unit"
            placeholder="Pilih Unit"
            data={param.levels}
            // disabled
            value={form.value.levels_id}
            onChange={this._onFormChange}
            error={form.error.levels_id || ""}
          />

          </div>
          <div className="student-list__button">
          <Select
            placeholder="Pilih Kelas"
            label="Kelas"
            name="classrooms_id"
            value={classrooms_id}
            data={param.classrooms}
            onChange={this._onChangeFilter}
          />
            
          </div>
        </div>
        <div className="student-list__button2">
          <Button
            style={{ backgroundColor: "darkgreen" }}
            title="Isi Data"
            onClick={this._onClickAdd}
          />
        </div>
        <div className="student-list__button2">
          <Button title="Cetak Data" onClick={this._onClickCetak} />
          {/* <ReactToPrint
            trigger={() => (
              <div className="form-group-member">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          /> */}

          <div className="fund-request__content">
            <table className="table" ref={componentRef}>
              <thead>
                <tr>
                  {map(JADWAL_MATPEL_LIST_TABLE_FIELDS.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(listjadwal, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(
                      JADWAL_MATPEL_LIST_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.type === "link") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <Link
                                to={
                                  "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                                }
                              >
                                {list.name}
                              </Link>
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(list, field)}
                          </td>
                        );
                      }
                    )}
                    {this._renderButtons(list)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
JadwalMatpel.propTypes = {
  handleListScheduleSubject: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
JadwalMatpel.defaultProps = {
  handleListScheduleSubject: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
