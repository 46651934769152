import { connect } from 'react-redux';
import TambahJenisSPP from '../../../../views/Finance/Spp/JenisSPP/TambahJenisSPP.JenisSPP.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import { getParamOptions, getParamOptionsAcademics, getStatusOptions , getClassesOptions} from '../../../../states/thunks/options.thunk';
import {  saveJenisSPP, getJenisSPP, editJenisSPP } from '../../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveJenisSPP: (payload, goback) => dispatch(saveJenisSPP(payload, goback)),
    handleGetJenisSPP: payload => dispatch(getJenisSPP(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getStatusOptions: (payload) => dispatch(getStatusOptions(payload)),
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleEditJenisSPP: (payload, goback) => dispatch(editJenisSPP(payload, goback)), 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahJenisSPP);
