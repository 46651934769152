import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { Multiselect } from 'multiselect-react-dropdown';

export default class JadwalSiswaForm extends Component {
  constructor(props) {
    super(props);
    
    this._onFormChange = this._onFormChange.bind(this);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  render(){
    const { onFormChange, form,param } = this.props;
    return (
      <div className="buat-pelanggaran__custom-form">
        <div className="buat-pelanggaran__custom-form-column">
          <div className="buat-pelanggaran__custom-form-column__field">
            <Input
              type="file"
              name="upload"
              label="Upload File"
              onChange={this._onFormChange} 
              value={form.value.upload}
              error={form.error.upload || ''}
              />
            <Input
              type="text"
              name="name"
              label="Judul"
              onChange={this._onFormChange} 
              value={form.value.name}
              error={form.error.name || ''}
            />
              <Textarea
              type="text"
              name="description"
              label="Deskripsi"
              onChange={this._onFormChange} 
              value={form.value.description}
              error={form.error.description || ''}
            />
            <Input
              type="date"
              name="tgl_mulai"
              label="Tanggal Mulai"
              onChange={this._onFormChange} 
              value={form.value.tgl_mulai}
              error={form.error.tgl_mulai || ''}
            />
            <Input
              type="date"
              name="tgl_selesai"
              label="Tanggal Selesai"
              onChange={this._onFormChange} 
              value={form.value.tgl_selesai}
              error={form.error.tgl_selesai || ''}
            />
  
            <Select
              name="units"
              label="Pilih Unit"
              placeholder={"Pilihan"}
              data={param.units}
              onChange={this._onFormChange} 
              value={form.value.units}
              error={form.error.units || ''}
            />
            <Select
              name="golongan"
              label="Golongan"
              placeholder="Pilih Golongan"
              data={param.golongan}
              onChange={this._onFormChange} 
              value={form.value.golongan}
              error={form.error.golongan || ''}
            />
            <Select
              name="jabatan"
              label="Jabatan"
              placeholder="Pilih Jabatan"
              data={param.jabatan}
              onChange={this._onFormChange} 
              value={form.value.jabatan}
              error={form.error.jabatan || ''}
            />
             <p>Guru</p>
            <Multiselect
             style={{width:"50%"}}
             options={param.teachers}
             displayValue="label" // Property name to display in the dropdown options
            //  showCheckbox={true}
             onChange={this._onFormChange} 
             />
          </div>
        </div>
      </div>
    );
  }
 
}
JadwalSiswaForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
JadwalSiswaForm.defaultProps = {
  onFormChange: noop,
};
