import { connect } from 'react-redux';
import UploadBuku from '../../views/Library/UploadBuku.library.view';
import { getStudent } from '../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(UploadBuku);
