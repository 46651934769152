import { connect } from 'react-redux';
import LaporanSPP from '../../../../views/Finance/Spp/LaporanKeu/LaporanSPP.laporankeu.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import { getPeriodsOptions } from '../../../../states/thunks/options.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(null, mapDispatchToProps)(LaporanSPP);