import { connect } from 'react-redux';
import CreateInvoiceTidakRutin from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoiceTidakRutin.view';
import { getStudent } from '../../../../states/thunks/student.thunk';
import { saveInvoiceNonRutin } from '../../../../states/thunks/academics.thunk'
import { getYearsOfEducationsOptions, getPrmPayments } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch){
  return{
    handleGetStudent: () => dispatch(getStudent()),
    handleSaveInvoiceNonRutin: (payload, goback) => dispatch(saveInvoiceNonRutin(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPayments: (payload) => dispatch(getPrmPayments(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps) (CreateInvoiceTidakRutin);