export const FINANCE_ADD_UPLOAD_RAPBU_FORM_FIELDS = [{
  type: 'file',
  name: 'file',
  label: 'File',
  placeholder: 'Pilih file',
  inputType: 'file',
  full: true,
}];

export const UPLOAD_RAPBU_REQUIRED_FIELD = ['parameter_code'];

export const FINANCE_UPLOAD_RAPBU_TABLE_FIELDS = {
  label: [
    'No',
    'Judul',
    'Ekstensi',
    'Diupload Oleh',
    'Waktu Upload',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'title' },
    { type: 'string', attribute: 'ext' },
    { type: 'string', attribute: 'uploaded_by' },
    { type: 'date', attribute: 'created_at' },
  ],
};
