import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function SyaratForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="syarat__custom-form">
        <div className="syarat__custom-title">
        Kondisi Pertama
        </div>
      <div className="syarat__custom-form-row">
        <div className="syarat__custom-form-row__field">
          <Select
            name="namaSaudara1"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Kelas 7A'}
           
            // error={form.error.namaSaudara1 || ''}
          />
        </div>
        
        <div className="syarat__custom-form-column__field">
            
          <Select
           
            name="tahunajaran"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'2019 - 2020'}
          />
          
        </div>
        <div className="syarat__custom-form-column__field">
            
          <Select
           
            name="tahunajaran"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'2019 - 2020'}
          />
          
       
         
        </div>
        <div className="syarat__custom-form-column__field">
            
          <Input
            type="text"
            name="tahunajaran"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'2019 - 2020'}
          />
         
        </div>
        </div>

        <div className="syarat__custom-title">
            Untuk Mata Pelajaran
        </div>
            <div className="syarat__body">
                <table className="syarat__table">
                    <tbody>
                        <tr>
                            <td></td>
                            <td> <div className="raport-kbk__custom-form-row-check">
                                    <div className="button-ekskul__button-wrapper">
                                    
                                        <Input
                                        type="checkbox"
                                        name="senin"
                                        onChange={onFormChange}
                                        //  checked='true'
                                        // checked='false'
                                        
                                    /> 
                                </div>
                                &nbsp;<p>Pendidikan Pancasila dan Kewarganegaraan </p> &nbsp;
                                </div>

                            </td>
                            <td> <div className="raport-kbk__custom-form-row-check">
                                    <div className="button-ekskul__button-wrapper">
                                    
                                        <Input
                                        type="checkbox"
                                        name="senin"
                                        onChange={onFormChange}
                                        //  checked='true'
                                        // checked='false'
                                        
                                    /> 
                                </div>
                                &nbsp;<p>Pendidikan Agama </p> &nbsp;
                                </div>

                            </td>
                            <td> <div className="raport-kbk__custom-form-row-check">
                                    <div className="button-ekskul__button-wrapper">
                                    
                                        <Input
                                        type="checkbox"
                                        name="senin"
                                        onChange={onFormChange}
                                        //  checked='true'
                                        // checked='false'
                                        
                                    /> 
                                </div>
                                &nbsp;<p>Pendidikan Jasmani, Olahraga, dan Kesehatan </p> &nbsp;
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                            <div className="raport-kbk__custom-form-row-check">
                                    <div className="button-ekskul__button-wrapper">
                                    
                                        <Input
                                        type="checkbox"
                                        name="senin"
                                        onChange={onFormChange}
                                        //  checked='true'
                                        // checked='false'
                                        
                                    /> 
                                </div>
                                &nbsp;<p>Mapel 2 </p> &nbsp;
                                </div>
                            </td>
                            <td>
                            <div className="raport-kbk__custom-form-row-check">
                                    <div className="button-ekskul__button-wrapper">
                                    
                                        <Input
                                        type="checkbox"
                                        name="senin"
                                        onChange={onFormChange}
                                        //  checked='true'
                                        // checked='false'
                                        
                                    /> 
                                </div>
                                &nbsp;<p>Mapel 3 </p> &nbsp;
                                </div>
                            </td>
                            <td>
                            <div className="syarat__custom-form-row-check">
                                    <div className="button-ekskul__button-wrapper">
                                    
                                        <Input
                                        type="checkbox"
                                        name="senin"
                                        onChange={onFormChange}
                                        //  checked='true'
                                        // checked='false'
                                        
                                    /> 
                                </div>
                                &nbsp;<p>Semua Mata Pelajaran </p> &nbsp;
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="syarat__custom-form-row">
                <div className="syarat__custom-form-row__field">
                    <Select
                    name="tingkatkelas"
                    label="Untuk Tingkat Kelas"
                    onChange={onFormChange}
                    value={form.value.tempatLahirSaudara1 || ''}
                    error={form.error.tempatLahirSaudara1 || ''}
                    placeholder="Pilihan"
                    />
                        
                </div>
                <div className="syarat__custom-form-column__field">
            
                    <Select
                    
                        name="tahunajaran"
                        label="Untuk Periode"
                        onChange={onFormChange}
                        value={form.value.tempatLahirSaudara1 || ''}
                        error={form.error.tempatLahirSaudara1 || ''}
                        placeholder={'semester 1'}
                    />
                    
                </div>
            </div>



        </div>
        
 
   
  );
}
SyaratForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
SyaratForm.defaultProps = {
  onFormChange: noop,
};
