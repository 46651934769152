import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class AbsensiHarianPage2 extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                nis:'',
                tanggal:'',
                levels: '',
            },
            error: {
                classes: '',
                nis:'',
                tanggal:'',
                levels: '',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes, levels } = this.props;
    return (
      <div className="absensi-harian__custom-form">
           <div className="absensi-harian__custom-form-row">
                      <p>Tahun ajaran (2019-2020) Kelas</p>
                    <div className="absensi-harian__custom-form-row-kls__field">
                    
                      <Input
                         name="classes"
                         onChange={this._onFormChange}
                         data={ classes.list}
                         disabled
                         value={form.value.classes}
                         error={form.error.classes || ''}
                      />
                      </div>
                    <div className="absensi-harian__custom-form-column__field">
                      <Input
                        type='date'
                        name="tanggal"
                        disabled
                        onChange={this._onFormChange}
                        value={form.value.tanggal || ''}
                        error={form.error.tanggal || ''}
                      />
                    </div>
                </div>
  </div>
          
          
          
   
     
    );
}
  
}
AbsensiHarianPage2.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
  levels: PropTypes.array,
};
AbsensiHarianPage2.defaultProps = {
  onChange: noop,
  classes: [],
  levels: [],
};
