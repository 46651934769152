import { connect } from 'react-redux';
import BuatJenisEkskul from '../../../views/Student/ekskul/BuatJenisEkskul.student.view';
import { getStudent, getStudentOptions } from '../../../states/thunks/student.thunk';
import { saveJenisEkskul, getJenisEkskul, editJenisEkskul } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    classes:state.options.classes,
    levels: state.options.levels,
    prm_classes: state.options.prm_classes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
    handleSaveJenisEkskul: (payload, goback) => dispatch(saveJenisEkskul(payload, goback)),
    handleEditJenisEkskul: (payload, goback) => dispatch(editJenisEkskul(payload, goback)), 
    handleGetJenisEkskul:  (payload, goback) => dispatch(getJenisEkskul(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatJenisEkskul);


