import { connect } from 'react-redux';
import PengisianSikap from '../../../views/Kurikulum/Ulangan/PengisianSikap.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getSubjectOptions,getTeacherSubjectsOptions, getParamOptionsAcademics, getTypeExam, getPeriodsOptions, getClassesOptions } from '../../../states/thunks/options.thunk';
import { listStudentCognitiveValue, saveCognitiveValue, editNilaiKognitif, getNilaiKognitif,editAttitudeIndicator,getAttitudeIndicator,saveAttitudeIndicator} from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleSaveCognitiveValue: (payload, goback) => dispatch(saveCognitiveValue(payload, goback)),
    getTypeExam: (payload) => dispatch(getTypeExam(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentCognitiveValue: (payload, goback) => dispatch(listStudentCognitiveValue(payload, goback)),
    getTeacherSubjectsOptions: (payload) => dispatch(getTeacherSubjectsOptions(payload)),
    handleGetNilaiKognitif: payload => dispatch(getNilaiKognitif(payload)),
    handleEditNilaiKognitif: (payload, goBack) => dispatch(editNilaiKognitif(payload, goBack)),
    handleSaveAttitudeIndicator: (payload, goback) => dispatch(saveAttitudeIndicator(payload, goback)),
    handleGetAttitudeIndicator: payload => dispatch(getAttitudeIndicator(payload)),
    handleEditAttitudeIndicator: (payload, goBack) => dispatch(editAttitudeIndicator(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PengisianSikap);


