import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../../components/base/index';


export default class CatatSiswaKeluarForm2 extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                nis:'',
                studentname: '',
                tanggal:'',
                statusmutasi: '',
                nosuratpindah: '',
                tatusabsen:'',
            },
            error: {
                classes: '',
                nis:'',
                studentname: '',
                tanggal:'',
                statusmutasi: '',
                nosuratpindah: '',
                statusabsen:'',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes, levels } = this.props;
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          
          <Input
              type="text"
              name="nis"
              label="NIS/Siswa"
              onChange={this._onFormChange}
              disabled
              value={form.value.nis || ''}
              error={form.error.nis || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Input
              type="text"
              name="studentname"
              label="Nama Siswa"
              disabled
              onChange={this._onFormChange}
              value={form.value.studentname || ''}
              error={form.error.studentname || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Input
              name="classes"
              label="Posisi Kelas"
              disabled
              onChange={this._onFormChange}
              data={ classes.list}
              placeholder={'Pilihan'}
              value={this.state.classes}
              // error={form.error.namaSaudara1 || ''}
            />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
            
          <Select
              name="classes"
              label="Pindah Kelas"
              onChange={this._onFormChange}
              data={ classes.list}
              placeholder={'Pilihan'}
              value={this.state.classes}
              // error={form.error.namaSaudara1 || ''}
            />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
            type="text"
              name="walikelas"
              label="Wali Kelas"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.walikelas || ''}
              error={form.error.walikelas || ''}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            
          <Select
              name="levels"
              label="Unit"
              onChange={this._onFormChange}
              data={ levels.list}
              placeholder={'Pilihan'}
              value={form.value.levels}
              error={form.error.levels || ''}
            />
            </div>
      </div>
      
  </div>
          
          
          
   
     
    );
}
  
}
CatatSiswaKeluarForm2.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
  levels: PropTypes.array,
};
CatatSiswaKeluarForm2.defaultProps = {
  onChange: noop,
  classes: [],
  levels: [],
};
