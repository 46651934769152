import language from '../languages';

export const HOME_INFO = [{
  label: language.translate.HOME__INFO__LABEL_1,
  value: 'name',
}, {
  label: language.translate.HOME__INFO__LABEL_2,
  value: language.translate.HOME__INFO__VALUE_2,
}, {
  label: language.translate.HOME__INFO__LABEL_3,
  value: language.translate.HOME__INFO__VALUE_3,
}, {
  label: language.translate.HOME__INFO__LABEL_4,
  value: language.translate.HOME__INFO__VALUE_4,
}, {
  label: language.translate.HOME__INFO__LABEL_5,
  value: language.translate.HOME__INFO__VALUE_5,
}, {
  label: language.translate.HOME__INFO__LABEL_6,
  value: language.translate.HOME__INFO__VALUE_6,
}];

export const others = '';
