import { connect } from 'react-redux';
import Discontinuance from '../../../views/Personnel/Letter/Discontinuance.letter.view';
import { getDiscontinuance } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetDiscontinuance: payload => dispatch(getDiscontinuance(payload)),
  };
}

export default connect(null, mapDispatchToProps)(Discontinuance);
