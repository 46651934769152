import { connect } from 'react-redux';
import JobApplication from '../../../views/Personnel/Letter/JobApplication.letter.view';
import { getJobApplication } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetJobApplication: payload => dispatch(getJobApplication(payload)),
  };
}

export default connect(null, mapDispatchToProps)(JobApplication);
