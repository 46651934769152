import { connect } from 'react-redux';
import DaftarSiswaKlsUnit from '../../../views/Student/report/DaftarSiswaKlsUnit.student.view';
import { manageRegistration } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
  };
}

export default connect(null, mapDispatchToProps)(DaftarSiswaKlsUnit);
