import { connect } from 'react-redux';
import PengisianNilaiEkskul from '../../../views/Student/ekskul/PengisianNilaiEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getStudentOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import {  listGradeEkskul, deleteGradeEKskul} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
  return{
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListGradeEkskul: (payload, goBack) => dispatch(listGradeEkskul(payload, goBack)),
    handleDeleteGradeEKskul: (payload, goback) => dispatch(deleteGradeEKskul(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PengisianNilaiEkskul);


