import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import { Link } from 'react-router-dom';

export default class DataCuti extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }

  _onDeleteList() {
    const { onDeleteList, fieldName } = this.props;
    
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name,onFormChange, form
    } = this.props;

  
    return (
        <div className="daftar-ekskul__content">
                    <div className="daftar-ekskul__header">
                      
    
                    </div>
                    <div className="nilai-kognitif__custom-form">
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Input
                          type="text"
                          readOnly
                          name="namaSaudara1"
                          label="Sisa Cuti"
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                          placeholder={'Kelas 7A'}
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                        
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Input
                          type="text"
                          readOnly
                          name="tahunajaran"
                          label="Cuti yang Terpakai"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Semua'}
                        />
                      </div>

                      <div className="nilai-kognitif__custom-form-column__field">
                        <Input
                          type="text"
                          readOnly
                          name="tahunajaran"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Semua'}
                        />
                      </div>
                      
                      </div>
                    </div>
                    <div className="laporan-khusus__body">
                      <table className="laporan-khusus__table">
                        <thead>
                          <tr>
                            <th>Nama Pegawai</th>
                            <th>Tanggal Mulai Cuti</th>
                            <th>Tanggal Selesai Cuti</th>
                            <th>Keterangan</th>
                            <th>Status</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {/* {
                          map(journal.details, (list, idx) => (
                            <tr key={`journal_detail_${idx}`}>
                              <td>{idx + 1}</td>
                              <td>{list.code_of_account}</td>
                              <td>{list.parameter_code.title}</td>
                              <td>{list.description}</td>
                              <td className="nominal">{commaFormatted(list.amount)}</td>
                            </tr>
                          ))
                        } */}
                        <tr>
                            <td>1</td>
                            <td>aa</td>
                            <td>122</td>
                            <td></td>
                            <td className="status">Di Setujui</td>
                        </tr>
                       
                        </tbody>
                        {/* <tfoot>
                          <tr>
                            <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                            <td colSpan="8" className="nominal"></td>
                          </tr>
                        </tfoot> */}
                      </table>
                    </div>
                  </div>
      );
  }


 
}
DataCuti.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
DataCuti.defaultProps = {
  onFormChange: noop,
};
