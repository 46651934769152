import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class AbsensiPegawai extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickApproval = this.onClickApproval.bind(this);
    this.onClickLaporanAbsensi = this.onClickLaporanAbsensi.bind(this);
    this.onClickAbsensiKhusus = this.onClickAbsensiKhusus.bind(this);
    this.onClickAbsensiPegawai = this.onClickAbsensiPegawai.bind(this);
    this.onClickIjinPegawai = this.onClickIjinPegawai.bind(this);
    this.onClickKegiatanPegawai = this.onClickKegiatanPegawai.bind(this);
    this.onClickLemburPegawai = this.onClickLemburPegawai.bind(this);
    this.onClickTablePelanggan = this.onClickTablePelanggan.bind(this);
  }

  onClickApproval() {
    const { history } = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/approval');
  }

  onClickLaporanAbsensi() {
    const { history } = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/laporan-absensi-pegawai');
  }

  onClickAbsensiKhusus() {
    const {history} = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/laporan-absensi-khusus-pegawai')
  }

  onClickAbsensiPegawai(){
    const {history} = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/laporan-absensi-pegawai-detail');
  }

  onClickCutiPegawai(){
    const {history} =this.props;
    history.push('/dashboard/personalia/absensi-pegawai/pengajuan-cuti');
  }

  onClickIjinPegawai() {
    const {history} = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/pengajuan-ijin-pulang-awal');
  }

  onClickKegiatanPegawai() {
    const {history} =this.props;
    history.push('/dashboard/personalia/absensi-pegawai/pengajuan-kegiatan-pegawai')
  }

  onClickLemburPegawai(){
    const {history} = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/pengajuan-lembur-pegawai');
  }

  onClickTablePelanggan(){
    const{history} = this.props;
    history.push('/dashboard/personalia/absensi-pegawai/table-kegiatan-pegawai');
  }


  render() {
    return (
      <div className="akademi">
        <div className="akademi__list">
          <button onClick={this.onClickApproval}>
            <img src={budgeting} alt="Approval" />
            <h3>Approval</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporanAbsensi}>
            <img src={bookkeeping} alt="pembukuan" />
            <h3>Laporan Absensi Pegawai</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickAbsensiKhusus}>
            <img src={bookkeeping} alt="absensi khusus"/>
            <h3>Laporan Absensi Khusus Pegawai</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickAbsensiPegawai}>
            <img src={bookkeeping} alt="absensi pegawai"/>
            <h3>Laporan Absensi Pegawai Detail</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickCutiPegawai}>
            <img src={bookkeeping} alt="Cuti Pegawai"/>
            <h3>Pengajuan Cuti Pegawai</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickIjinPegawai}>
            <img src={bookkeeping} alt="Ijin Pegawai" />
            <h3>Pengajuan Ijin Pulang Awal</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickKegiatanPegawai}>
            <img src={bookkeeping} alt="Kegiatan Pegawai" />
            <h3>Pengajuan Kegiatan Pegawai</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLemburPegawai}>
            <img src={bookkeeping} alt="Lembur Pegawai" />
            <h3>Pengajuan Lembur Pegawai</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickTablePelanggan}>
            <img src={bookkeeping} alt="Table Pelanggan" />
            <h3>Table Kegiatan Pegawai</h3>
          </button>
        </div>
      </div>
    );
  }
}
AbsensiPegawai.propTypes = {
  history: PropTypes.object.isRequired,
};
