import { connect } from 'react-redux';
import ManagePassword from '../../views/Settings/ManagePassword.view';
import { changePassword } from '../../states/thunks/auth.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleChangePassword: (payload, callback) => dispatch(changePassword(payload, callback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePassword);
