import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Select } from '../base';
import { generateYearList } from '../../utils/transformer.util';

export default function PersonalReportFilter(props) {
  const {
    onSearch, onChange,
    year, error,
  } = props;
  return (
    <div className="filter">
      <div className="filter__student">
        <Select
          name="year"
          label="Tahun Ajaran"
          placeholder="Pilih tahun ajaran"
          data={generateYearList()}
          onChange={onChange}
          value={year}
          error={error}
        />
      </div>
      <Button
        title="Cari"
        onClick={onSearch}
      />
    </div>
  );
}
PersonalReportFilter.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  year: PropTypes.string,
  error: PropTypes.string,
};
PersonalReportFilter.defaultProps = {
  onChange: noop,
  onSearch: noop,
  year: '',
  error: '',
};
