import React from 'react';
import PropTypes from 'prop-types';
import { noop, isEmpty } from 'lodash';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { Select, Input, SearchSelect } from '../../../../../components/base';
import { generateYearList } from '../../../../../utils/transformer.util';

export default function BudgetSourceForm(props) {
  const {
    form, onFormChange,
    coa, isEdit, handleSearchHead, onSearchCoa,
  } = props;
  return (
    <div>
      <div className="manage-budget-transfer__row-form">
        <div className="manage-budget-transfer__form-content">
          <Select
            disabled={isEdit}
            name="periode"
            label="Periode"
            data={generateYearList()}
            onChange={onFormChange}
            placeholder="Pilih periode"
            value={form.value.periode || ''}
            error={form.error.periode || ''}
          />
        </div>
        <div className="manage-budget-transfer__form-content">
          <Input
            disabled={isEdit}
            name="description"
            label="Deskripsi"
            onChange={onFormChange}
            value={form.value.description || ''}
          />
        </div>
      </div>
      {
        !isEmpty(form.value.periode) && (
          <div className="manage-budget-transfer__row-form">
            <div className="manage-budget-transfer__form-content">
              <SearchSelect
                async
                disabled={isEdit}
                onSearch={handleSearchHead}
                name="head"
                label="Head"
                value={form.value.head}
                error={form.error.head}
                onClick={onFormChange}
                placeholder="Pilih Head"
                rightIcon="icon-search"
                labelName="title"
              />
            </div>
            <div className="manage-budget-transfer__form-content">
              <SelectCoa
                name="code_of_account"
                label="Code Of Account"
                coa={coa}
                value={form.value.code_of_account}
                error={form.error.code_of_account}
                onClick={onFormChange}
                onSearchCoa={onSearchCoa}
                placeholder="Pilih Kode"
                rightIcon="icon-search"
              />
            </div>
          </div>
        )
      }
    </div>
  );
}
BudgetSourceForm.propTypes = {
  isEdit: PropTypes.bool,
  coa: PropTypes.object.isRequired,
  onFormChange: PropTypes.func,
  onSearchCoa: PropTypes.func,
  handleSearchHead: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};
BudgetSourceForm.defaultProps = {
  isEdit: false,
  onSearchCoa: noop,
  onFormChange: noop,
};
