import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../../components/base/index';
import { Link } from 'react-router-dom';


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div id="divPrint" style={{width:800, border: '0px solid black'}}>
   <div className="cetak-slip-kwitansi__header-content">  
        <div className="cetak-slip-kwitansi__content">
            <div className="cetak-slip-kwitansi__header">
                <div className="cetak-slip-kwitansi__custom-form-row-square">
                    <div className="cetak-slip-kwitansi__box--square2">
                        Image
                    </div>
                    <div className="cetak-slip-kwitansi__header-title">
                        <div className="cetak-slip-kwitansi__header-title--column">
                            {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                            SMP SPIN INTERNASIONAL
                        </div>
                        <div className="cetak-slip-kwitansi__header-title--column-text">
                            {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                            Jl.Kebon Jeruk Raya No 17 ,Jakarta
                        </div>
                        <div className="cetak-slip-kwitansi__header-title--column-text">
                            {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                            Telp. 021-0000222211
                        </div> 
                    </div>
                </div>
            </div>
            <div className="cetak-slip-kwitansi__header-title-bukti">
                <div className="cetak-slip-kwitansi__header-title-bukti--column">
                    <hr className="cetak-slip-kwitansi__custom-hr"></hr>
                    BUKTI PEMBAYARAN
                    <hr className="cetak-slip-kwitansi__custom-hr"></hr>
                </div>
            </div>
        </div>  
        <div className="cetak-slip-kwitansi__header-title3">
            <div className="cetak-slip-kwitansi__header-title3--left">
                <table className="cetak-slip-kwitansi__table-test">
                    <tr> 
                        <td></td>    
                        <td>NO BUKTI </td>
                        <td>  : Gange</td> 
                    </tr> 
                    <tr>
                        <td></td>
                        <td>TANGGAL </td>
                        <td>  : SMP Katolik Ricci</td> 
                        
                    </tr>
                </table> 
            </div>
            <div className="cetak-slip-kwitansi__header-title3--right">
                <table className="cetak-slip-kwitansi__table-test">
                    <tr> 
                        <td></td>    
                        <td>NIS </td>
                        <td>  : SMP Katolik Ricci</td> 
                    </tr> 
                    <tr>
                        <td></td>
                        <td>NAMA SISWA</td>
                        <td>  : SMP Katolik Ricci</td> 
                    </tr>
                    <tr>
                        <td></td>
                        <td>KELAS</td>
                        <td>  : SMP Katolik Ricci</td> 
                    </tr>
                </table>
            </div>
         </div>
         <div className="cetak-slip-kwitansi__body">
             <table className="cetak-slip-kwitansi__table">
                 <thead>
                    <tr>
                        <th>NO</th>
                        <th>JENIS PEMBAYARAN</th>
                        <th>PERIODE</th>
                        <th>JUMLAH (RP.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1.</td>
                        <td>Uang SPP</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2.</td>
                        <td>Uang Jemputan</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td className="nominal" colSpan={3}>TOTAL</td>
                        <td></td>
                    </tr>
                </tfoot>
             </table>
         </div>
        <div className="cetak-slip-kwitansi__header-title2">
            <div className="cetak-slip-kwitansi__header-title2--column">
                <div className="cetak-slip-kwitansi__header-title2--bold">
                    Terbilang
                </div>
           
                <div className="cetak-slip-kwitansi__header-title2--row">
                    <div className="cetak-slip-kwitansi__header-title2--row">
                            Lima puluh juta
                    </div>
                   
                </div>
            </div>
        </div>

        <div className="cetak-slip-kwitansi__custom-form-row">
            <div className="cetak-slip-kwitansi__custom-form-row__field">
                <div className="cetak-slip-kwitansi__custom-form-column3">
                    <p>Jakarta,</p>
                        <div className="cetak-slip-kwitansi__custom-form-column">
                            <p>Yang Menerima</p>
                        </div>
                        <div className="cetak-slip-kwitansi__custom-form-column3__field2">
                            <p>(&emsp; &emsp; &emsp; &emsp; &emsp;)</p>
                        </div>
                </div>
            </div>
         </div>
    </div>  
</div>
  );
     }
}
ComponentToPrint.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ComponentToPrint.defaultProps = {
  onFormChange: noop,
};
