import EstimasiKenaikanKls from '../../../views/Kurikulum/Analisa/EstimasiKenaikanKls.kurikulum.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
    };
}
export default connect(null, mapDispatchToProps) (EstimasiKenaikanKls);