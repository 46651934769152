import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import { Link } from 'react-router-dom';


export default function NilaiK13Table(props) {
  const { onFormChange, form } = props;
  return (
    <div className="tbm__content">
                <div className="tbm__body">
                  <table className="tbm__table">
                    <thead>
                      <tr>
                        <th rowSpan={2}>No/NIS</th>
                        <th rowSpan={2}>Nama</th>
                        <th rowSpan={2}>Guru</th>
                        <th colSpan={5}>KD/Tema (Judul)</th>
                        <th style={{backgroundColor:'#ffff00'}} rowSpan={2}>NKD</th>
                        <th colSpan={5}>KD/Tema (Judul)</th>
                        <th style={{backgroundColor:'#ffff00'}} rowSpan={2}>NKD</th>
                        <th colSpan={4}>Tugas</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>KD 3.1</th>
                        <th>KD 3.2</th>
                        <th>KD 3.3</th>
                        <th>KD 3.4</th>
                        <th>KD 3.5</th>

                        <th>KD 3.6</th>
                        <th>KD 3.7</th>
                        <th>KD 3.8</th>
                        <th>KD 3.3</th>
                        <th>KD 3.9</th>

                        <th>Tugas 1</th>
                        <th>Tugas 2</th>
                        <th>Tugas 3</th>
                        <th>Tugas 4</th>

                        <th>PTS 1.0</th>
                        <th>PAS 1.0</th>
                        <th>NR A</th>
                        <th>NR N</th>
                        <th>Comment/Deskripsi</th>

                      </tr>
                     
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    {/* <tr>
                        <td>1</td>
                        <td>aa</td>
                        <td>122</td>
                        <td><Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ 'Hadir', 'hadir'}
                            placeholder={'Hadir'}
                           
           
                        // error={form.error.namaSaudara1 || ''}
                            /></td>
                       

                    </tr> */}
                     <tr>
                     <td colSpan="1" rowSpan="2" >344444</td>
                     <td colSpan="1" rowSpan="2"><Link to={'/dashboard/kurikulum/laporan/komentar-manual'}>Banana</Link></td>
                     <td colSpan="1" rowSpan="2">Guru Mapel</td>
                      <td><Link to={'/dashboard/kurikulum/laporan/daftar-nilai-siswa'}>90</Link></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{backgroundColor:'#ffff00'}}></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{backgroundColor:'#ffff00'}}></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                    <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td  style={{backgroundColor:'#ffff00'}}></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td  style={{backgroundColor:'#ffff00'}}></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
NilaiK13Table.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
NilaiK13Table.defaultProps = {
  onFormChange: noop,
};
