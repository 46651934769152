import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../../components/base/index';
import { Link } from 'react-router-dom';

export default class InvoiceSummaryForm extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div className="invoice-sum__content">
                
                <div className="invoice-sum__header2">
                  Daftar Payment Uang Sekolah Dari Tanggal
                </div>
                
                <div className="komposisi-siswa__custom-form">
                    <div className="invoice-sum__custom-form-row__field">
                        <div className="invoice-sum__custom-form-row3"> 
                            <Input
                                type="text"
                                name="tahunajaran"
                                style={{backgroundColor:'grey'}}
                                label=""
                                onChange={onFormChange}
                                value={form.value.tempatLahirSaudara1 || ''}
                                error={form.error.tempatLahirSaudara1 || ''}
                                
                            />
                        </div>
                        <p>S/D</p>
                        <div className="invoice-sum__custom-form-row4">
                            <Input
                                type="text"
                                name="tahunajaran"
                                style={{backgroundColor:'grey'}}
                                label=""
                                onChange={onFormChange}
                                value={form.value.tempatLahirSaudara1 || ''}
                                error={form.error.tempatLahirSaudara1 || ''}
                                
                            />
                         </div>
                    </div>
          
                 
                </div>     
                    <div className="invoice-sum__body">
                    <table className="invoice-sum__table">
                        <thead>
                        <tr>
                            <th>NO</th>
                            <th>Tanggal</th>
                            <th>No.Invoice</th>
                            <th>Nama Siswa</th>
                            <th>Uang Sekolah Detail</th>
                            <th>Total</th>
                            <th>Ket</th>
                        </tr>
                        </thead>
                        <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>1</td>
                        <td>1 Jan  2019</td>
                        <td>7a<br></br>
                        Nama lengkap<br></br>
                        12345</td>
                        <td>aaas3333333</td>
                        <td> <Input
                              label="Uang SPP"
                              name="namaSaudara1"
                              onChange={onFormChange}
                            /><br></br>
                            <Input
                             label="Uang Jemputan"
                             onChange={onFormChange}
                            /><br></br>
                             <Input
                             label="Uang Ekskul"
                             onChange={onFormChange}
                            /></td>
                        <td>120000</td>
                        <td>Paid</td>
                      
                    </tr>

                 
                   
                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
 
              </div>
  );
     }
}
InvoiceSummaryForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
InvoiceSummaryForm.defaultProps = {
  onFormChange: noop,
};
