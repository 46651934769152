import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { PERSONAL_INFORMATION_LETTER_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class InformationLetter extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
    const { location } = props;
    const { nip } = location.state.data;
    this.initialParam = { nip };
  }

  _onClickAdd() {
    const { history, location } = this.props;
    const { data } = location.state;
    history.push(`/dashboard/personalia/tambah-surat-keputusan/${data.nip}`, { data: { nip: data.nip }, isEdit: false });
  }

  _onClickEdit(data) {
    const { history } = this.props;
    history.push(`/dashboard/personalia/tambah-surat-keputusan/${data.id}`, { data, isEdit: true });
  }

  _onClickDelete(id) {
    const { handleDeleteInformationLetter, location } = this.props;
    const { data } = location.state;
    return handleDeleteInformationLetter({ nip: data.nip, id });
  }

  render() {
    const { handleGetInformationLetter } = this.props;
    return (
      <div className="information-letter">
        <Button
          title="Tambah Surat Keputusan"
          onClick={this._onClickAdd}
        />
        <div className="information-letter__content">
          <ContentTable
            initialParams={this.initialParam}
            onSearchContent={handleGetInformationLetter}
            tableFields={PERSONAL_INFORMATION_LETTER_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            onClickDelete={this._onClickDelete}
          />
        </div>
      </div>
    );
  }
}
InformationLetter.propTypes = {
  handleGetInformationLetter: PropTypes.func,
  handleDeleteInformationLetter: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
InformationLetter.defaultProps = {
  handleGetInformationLetter: noop,
  handleDeleteInformationLetter: noop,
};
