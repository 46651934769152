import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";
import { formatData } from '../../../../utils/transformer.util';
import { CETAK_ABSEN_REKAP_LIST_TABLE_FIELDS } from '../../../../constants/kurikulum/kurikulum.constant';


class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const {
        listAmount,
        content, list = {}
      } = this.state;
    const { journal } = this.state;
    return (
      
      <div id="divPrint" style={{width:800, border: '0px solid black', size: 'landscape'}}>
        <div>
            {
                <div className="cetak-8355__content">
                  <div className="cetak-8355__header">
                  <p>Tanggal : </p>
                  <p>Kelas : </p>
                      <div className="cetak-8355__header-title">
                      <table className="table">
            <thead>
              <tr>
                {
                    map(CETAK_ABSEN_REKAP_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(CETAK_ABSEN_REKAP_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
                      </div>
                   
  
                  </div>
                  
                </div>
            }
          </div>
      </div>
      
    );
  }

}
export default class CetakAbsensiRekap extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      

                        <div className="divContainter">
                            
                          <div className="report__content2">
                           
                            </div>
                          <div>
                          <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>
                        </div>
    );
  }
}
  CetakAbsensiRekap.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  CetakAbsensiRekap.defaultProps = {
    handleGetCashJournal: noop,
  };
  