import { connect } from 'react-redux';
import TambahSoal from '../../../views/Kurikulum/Soal/TambahSoal.kurikulum.view';
// import { saveAnnouncementFolder, listAnnouncementFolder, editAnnouncementFolder, deleteAnnouncementFolder, getAnnouncementFolder } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      user: state.user || {},
    };
  }

  function mapDispatchToProps(dispatch) {
    // return {
    //   handleGetAnnouncementFolder: payload => dispatch(getAnnouncementFolder(payload)),
    //   handleSaveAnnouncementFolder: (payload, goback) => dispatch(saveAnnouncementFolder(payload, goback)),
    //   handleListAnnouncementFolder: (payload, goback) => dispatch(listAnnouncementFolder(payload, goback)),
    //   handleEditAnnouncementFolder: (payload, goback) => dispatch(editAnnouncementFolder(payload, goback)), 
    //   handleDeleteAnnouncementFolder: (payload, goback) => dispatch(deleteAnnouncementFolder(payload, goback)),
    // };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(TambahSoal);