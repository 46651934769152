import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty} from 'lodash';
import { Input, Select, Textarea, SelectMultiple, Button, Checkbox } from '../../../../components/base/index';

export default class TableRangeNon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*"
      }
    };
    this._onAddListRange = this._onAddListRange.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  _onAddListRange() {
    const { onAddListRange, fieldName } = this.props;
    onAddListRange(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteListRange, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteListRange(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name, inputArray, fieldName, arrayPosition) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name,
      value,
      dataset: {
        inputArray,
        fieldName,
        arrayPosition,
      },
    }});
  }

  _onRemoveMulti(selectedList, removedItem) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name: removedItem,
      value: selectedList,
      dataset: {},
    }});
  }

  render() {
    const {
      list, error, onChange,param,
      fieldName, form, onFormChange
    } = this.props;
    const { value } = form;
    const Status = [
      { label: 'Bobot Rata - Rata NH', value: 'NH' },
      { label: 'Bobot Akhir dengan Ulangan MID', value: 'MID' },
      { label: 'Bobot Ulangan Semester', value: 'Semester' },
    ];

    return (
      <div className="table-range__content">
      <div className="table-range__header">
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
         <tr>
            <th>NO</th>
            <th>ID</th>
            <th>Range Awal</th>
            <th>Range Akhir</th>
            <th>Deskripsi 1</th>
            <th>Deskripsi 2</th>
            <th>Opsi</th>
          </tr>
         
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                <Input
                    type='text'
                    value={data.id_range}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='rangenilai'
                    name='id_range'
                    onChange={onFormChange}
               />
                </td>
                <td>
                <Input
                    type='number'
                    value={data.range_awal}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='rangenilai'
                    name='range_awal'
                    onChange={onFormChange}
               />
                </td>
                <td>
                <Input
                    type='number'
                    value={data.range_akhir}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='rangenilai'
                    name='range_akhir'
                    onChange={onFormChange}
               />
                </td>
                <td>
                <Input
                    type='text'
                    value={data.desc1}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='rangenilai'
                    name='desc1'
                    onChange={onFormChange}
               />
                </td>
                <td>
                <Input
                    type='text'
                    value={data.desc2}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='rangenilai'
                    name='desc2'
                    onChange={onFormChange}
               />

                </td>
                <td>
                  <Button onClick={() => this._onDeleteList(idx)}>Delete</Button> 
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={10}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddListRange}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

        
                </div>
                  </div>
    );
  }
}

TableRangeNon.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
TableRangeNon.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
};