import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { Link } from 'react-router-dom';

export default function PengisianEkskulForm(props) {
  const { onFormChange, form } = props;
  return (
    <div className="daftar-ekskul__content">
                
                <div className="daftar-ekskul__body">
                  <table className="daftar-ekskul__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Siswa</th>
                        <th>NIS</th>
                        <th>Nama Kelas Ekskul()</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    {/* <tr>
                        <td>1</td>
                        <td>aa</td>
                        <td>122</td>
                        <td><Select
                            name="workflow"
                            onChange={onFormChange}
                            data={ 'Hadir', 'hadir'}
                            placeholder={'Hadir'}
                           
           
                        // error={form.error.namaSaudara1 || ''}
                            /></td>
                       

                    </tr> */}
                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
PengisianEkskulForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianEkskulForm.defaultProps = {
  onFormChange: noop,
};
