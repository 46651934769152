import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { Link } from 'react-router-dom';

export default function RaportKBKdetailTable(props) {
  const { onFormChange, form } = props;
  return (
    <div className="daftar-ekskul__content">
                <div className="daftar-ekskul__header">
                  <p>Untuk Raport narasi / observasi tidak muncul kolom dibawah</p>
                </div>
                <div className="daftar-ekskul__body">
                  <table className="daftar-ekskul__table">
                    {/* <thead>
                      <tr>
                        <th>ID</th>
                        <th>Range Awal</th>
                        <th>Range Akhir</th>
                        <th>Deskripsi 1</th>
                        <th>Deskripsi 2</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>
                        <div className="raport-kbk__custom-form-row-check">
           
                            <div className="button-ekskul__button-wrapper">
                            
                                <Input
                                type="checkbox"
                                name="senin"
                                onChange={onFormChange}
                                //  checked='true'
                                // checked='false'
                                
                            /> 
                        </div>
                        &nbsp;<p>Dibawah KKM Merah </p> &nbsp;
                        </div></td>
                        <td> </td>
                        <td></td>
                      
                    </tr>

                    <tr>
                        <td> <div className="raport-kbk__custom-form-row-check">
           
                                <div className="button-ekskul__button-wrapper">
                                
                                    <Input
                                    type="checkbox"
                                    name="senin"
                                    onChange={onFormChange}
                                    //  checked='true'
                                    // checked='false'
                                    
                                /> 
                            </div>
                            &nbsp;<p>Dibawah KKM Merah </p> &nbsp;
                            </div></td>
                        <td> </td>
                        <td></td>
                      
                    </tr>
                    <tr>
                        <td> </td>
                        <td><div className="raport-kbk__custom-form-row-check">
           
                                <div className="button-ekskul__button-wrapper">
                                
                                    <Input
                                    type="checkbox"
                                    name="senin"
                                    onChange={onFormChange}
                                    //  checked='true'
                                    // checked='false'
                                    
                                /> 
                            </div>
                            &nbsp;<p>Dibawah KKM Merah </p> &nbsp;
                            </div> </td>
                        <td><div className="raport-kbk__custom-form-row-check">
           
                                <div className="button-ekskul__button-wrapper">
                                
                                    <Input
                                    type="checkbox"
                                    name="senin"
                                    onChange={onFormChange}
                                    //  checked='true'
                                    // checked='false'
                                    
                                /> 
                            </div>
                            &nbsp;<p>Dibawah KKM Merah </p> &nbsp;
                            </div></td>
                      
                    </tr>

            
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
RaportKBKdetailTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
RaportKBKdetailTable.defaultProps = {
  onFormChange: noop,
};
