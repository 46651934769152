import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Button } from '../../../components/base';
import { formatData,  normalizeAmount } from '../../../utils/transformer.util';
import { KELAS_DATAKELAS_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/kelas.constant';
import KlasifikasiTable from '../components/KlasifikasiTable.component';
import Top10DetailTable from '../components/Top10DetailTable.component';
import Top10SiswaPinjam from '../components/Top10SiswaPinjam.component';
import Top10SiswaKunjung from '../components/Top10SiswaKunjung.component';
import Top10GuruPinjam from '../components/Top10GuruPinjam.component';
import Top10GuruKunjung from '../components/Top10GuruKunjung.component';

export default class Top10AnalysisDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onClickSettingan = this._onClickSettingan.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  
    this.state= {
      coa: {
        loading: true,
        list: [],

      },
      form: {
        value: {},
        error: {},
      },
    };
  
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/buat-daftar-unit-perpus');
  }

  _onEdit() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/settingan-uang-sekolah');
  }

  _onClickSettingan() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/settingan-uang-sekolah');
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }


  render() {
    const {form} = this.state;
    const { handleGetKelas, handleDeleteKelas,coa } = this.props;
    return (
      <div className="student-list">
        
        <div className="budget__title">
            <h1>Top 10 Analysis</h1>
            <hr></hr>
        </div>

        <div className="budget__content">
            <Top10DetailTable
                form={form}
                onClickEdit = {this._onEdit}
                onFormChange={this._onFormChange}
            />
        </div>
        <div className="budget__content">
            <Top10SiswaPinjam
                form={form}
                onClickEdit = {this._onEdit}
                onFormChange={this._onFormChange}
            />
        </div>
        <div className="budget__content">
            <Top10GuruPinjam
                form={form}
                onClickEdit = {this._onEdit}
                onFormChange={this._onFormChange}
            />
        </div>
        <div className="budget__content">
            <Top10SiswaKunjung
                form={form}
                onClickEdit = {this._onEdit}
                onFormChange={this._onFormChange}
            />
        </div>
        <div className="budget__content">
            <Top10GuruKunjung
                form={form}
                onClickEdit = {this._onEdit}
                onFormChange={this._onFormChange}
            />
        </div>
    </div>
    );
  }
}
Top10AnalysisDetail.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Top10AnalysisDetail.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
