import { connect } from 'react-redux';
import CatatPelanggaran from '../../../views/Student/displiner/CatatPelanggaran.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses,getParamOptions, getSubjectOptions, getParamOptionsAcademics, getViolationsOptions, getPeriodsOptions, getStudentOptions, getClassesOptions} from '../../../states/thunks/options.thunk';
import { getViolations, saveViolations, listViolations, editViolations} from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getViolationsOptions: (payload) => dispatch(getViolationsOptions(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleGetViolations: payload => dispatch(getViolations(payload)),
    handleSaveViolations: (payload, goBack) => dispatch(saveViolations(payload, goBack)),    
    handleListViolations: (payload, goBack) => dispatch(listViolations(payload, goBack)),
    handleEditViolations: (payload, goBack) => dispatch(editViolations(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatPelanggaran);


