import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

export default class FileInput extends Component {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.state = {
      file: {}
    }
  }

  componentDidUpdate(prevProps) {
    const { filename } = this.props;

    if(filename === '' && filename !== prevProps.filename) {
      this.input.value = null;
    }
  }

  setRef(input) {
    this.input = input;
  }

  onChange(event) {
    const { onChange } = this.props;
    const thisEvent = { ...event };

    this.setState({
      file: event.target.files[0],
    }, () => {
      onChange(thisEvent);
    });
  }

  onRemove(event) {
    const { onRemoveFile } = this.props;
    const thisEvent = { ...event };
    this.setState({
      file: {}
    }, () => {
      onRemoveFile(thisEvent);
    });
  }

  onClickDownload(event) {
    const { target } = event;
    const { filename } = target.dataset;
    const { onDownloadFile } = this.props;

    onDownloadFile(filename);

  }

  renderInput() {
    const {
      name,
      fileName,
      displayName,
      edit,
      placeholder,
      type,
      theme,
      disabled,
      classNames,
      leftIcon,
      rightIcon,
      noMargin,
      inputType,
      ...others
    } = this.props;
    const inputClassnames = classnames([
      `input__file input__file__${theme}`,
      leftIcon && `input__file__${theme}--icon-left`,
      rightIcon && `input__file__${theme}--icon-right`,
      (theme === 'default' && leftIcon && rightIcon) && 'input__file--no-border',
      disabled && 'input__file--disabled',
      classNames,
    ]);
    const { file } = this.state;
    const removeIcon = edit ? (
      <a key="remove_file" className="input_file__remove" onClick={this.onRemove}>
        <i className="icon-remove"></i>
      </a>
    ) : '';
    let element = '';

    if (edit) {
      if(file.name) {
        element = [
          <p className="input__preview editable">
            {file.name}
          </p>,

        ]
      } else {
        if (fileName && displayName) {
          element = [
            <p className="input__preview editable">
              <a onClick={this.onClickDownload} data-filename={fileName} target="_blank">{displayName}</a>
            </p>,

          ];
        } else {
          element = [
            <input
                ref={this.setRef}
                type={type}
                placeholder={placeholder}
                className={inputClassnames}
                onChange={this.onChange}
                name={name}
                data-input-type="file"
            />,
          ];
        }
      }
    } else {
      if (fileName && displayName) {
        element = [
          <p key="input__preview" className="input__preview">
            <a onClick={this.onClickDownload} data-filename={fileName} target="_blank">{displayName}</a>
          </p>,
        ];
      } else {
        element = [
          <p key="input__preview" className="input__preview"></p>,
        ];
      }
    }

    return [
      <div className="file_input">
        {element}
      </div>,
      removeIcon
    ]

  }
  render() {
    const {
      theme,
      type,
      label,
      placeholder,
      onChange,
      error,
      classNames,
      leftIcon,
      rightIcon,
      disabled,
      noMargin,
      edit,
      fileName,
      displayName,
      ...others
    } = this.props;
    const wrapperClassnames = classnames([
      'input',
      noMargin && 'input__no-margin',
    ]);
    const labelClassnames = classnames([
      'input__label',
      error && 'input__label--error',
    ]);
    const containerClassnames = classnames([
      'input__container',
      disabled && 'input__disabled',
      error && 'input__container--error',
    ]);
    const inputClassnames = classnames([
      `input__file input__file__${theme}`,
      leftIcon && `input__file__${theme}--icon-left`,
      rightIcon && `input__file__${theme}--icon-right`,
      (theme === 'default' && leftIcon && rightIcon) && 'input__file--no-border',
      disabled && 'input__file--disabled',
      classNames,
    ]);
    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          {this.renderInput()}
          {
            (leftIcon && edit)
            && (
              <span className={`input__icon-left input__icon-left__${theme}`}>
                {
                  typeof leftIcon === 'string'
                    ? <i className={leftIcon} />
                    : leftIcon
                }
              </span>
            )
          }
          {
            (rightIcon && edit)
            && (
              <span className={`input__icon-right input__icon-right__${theme}`}>
                {
                  typeof rightIcon === 'string'
                    ? <i className={rightIcon} />
                    : rightIcon
                }
              </span>
            )
          }
        </div>
        {error && <p className="input__err">{error}</p>}
      </div>
    );
  }
}
FileInput.propTypes = {
  edit: PropTypes.bool,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  onChange: PropTypes.func,
  displayName: PropTypes.string,
  fileName: PropTypes.string,
  onRemoveFile: PropTypes.func,
  onDownloadFile: PropTypes.func,
  name: PropTypes.string,
};
FileInput.defaultProps = {
  edit: true,
  disabled: false,
  noMargin: false,
  theme: 'default',
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  leftIcon: '',
  rightIcon: '',
  onChange: noop,
  displayName: '',
  fileName: '',
  onRemoveFile: noop,
  onDownloadFile: noop,
  name: '',
};
