import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { PERSONAL_PAYROLLLIST_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class Payroll extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/data-payroll/tambah-payroll');
    // personalia/data-payroll/tambah-payroll`}
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/personalia/data-payroll/edit-payroll', { data: val, isEdit: true });
  }

  render() {
    const { handleGetPayroll, handleDeletePayroll } = this.props;
    return (
      <div className="payroll">
        <Button
          title="Buat Payroll"
          onClick={this.onClickAdd}
        />
        <div className="payroll__content">
          <ContentTable
            onSearchContent={handleGetPayroll}
            tableFields={PERSONAL_PAYROLLLIST_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickDelete={handleDeletePayroll}
          />
        </div>
      </div>
    );
  }
}
Payroll.propTypes = {
  handleGetPayroll: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleDeletePayroll: PropTypes.func,
};
Payroll.defaultProps = {
  handleGetPayroll: noop,
  handleDeletePayroll: noop,
};
