import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function PengisianPsikForm(props) {


  const { onFormChange, form, classes } = props;
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          {/* <Select
            name="jenis"
            label="Jenis Ulangan"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Kelas 7A'}
            value={form.value.jenis || ''}
            error={form.error.jenis || ''}
          /> */}

        <Select
  
           name="classes_year"
           label="Tahun Ajaran"
           onChange={onFormChange}
           value={form.value.classes_year || ''}
           error={form.error.classes_year || ''}
           placeholder={'2019 - 2020'}
         />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
          <Select
                placeholder="Pilih Unit"
                label = "Unit"
                name="levels_id"
                // value={levels_id}
                // data={param.levels}
                // onChange={this._onChangeFilter}
              />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
            name="model"
            label="Model"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Praktek/Keterampilan/Kl 4'}
            value={form.value.model || ''}
            error={form.error.model || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
         
            <Select
                placeholder="Pilih Kelas"
                label = "Kelas"
                name="classrooms_id"
                // value={classrooms_id}
                // data={param.classrooms}
                // onChange={this._onChangeFilter}
              />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
            name="classes"
            label="Kelas"
            onChange={onFormChange}
            data={ classes.list}
            value={form.value.classes}
            placeholder={'Pilihan'}
            error={form.error.classes || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
          <Select
           
            name="komponen"
            label="Komponen"
            onChange={onFormChange}
            value={form.value.komponen || ''}
            error={form.error.komponen || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
        <Select
           
           name="mapel"
           label="Mata Pelajaran"
           onChange={onFormChange}
           value={form.value.mapel || ''}
           error={form.error.mapel || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="nilai-kognitif__custom-form-column__field">
            
            <Input
             
              type='date'
              name="tgl"
              label="Tanggal Penilaian"
              onChange={onFormChange}
              value={form.value.tgl || ''}
              error={form.error.tgl || ''}
            />
            
         
           
          </div>
      
        </div>
        </div>
        
 
   
  );
}
PengisianPsikForm.propTypes = {
  classes: PropTypes.array,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianPsikForm.defaultProps = {
  onFormChange: noop,
  classes: [],
};
