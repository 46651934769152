import PengisianDaftarNilaiEng from '../../../views/Kurikulum/Ulangan/PengisianDaftarNilaiEng.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editDaftarNilai, saveDaftarNilaiEng, getLembarDaftarNilaiEng } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getTeacherSubjectsOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditDaftarNilai: (payload, goBack) => dispatch(editDaftarNilai(payload, goBack)),
        handleSaveDaftarNilaiEng: (payload, goback) => dispatch(saveDaftarNilaiEng(payload, goback)),
        handleGetDaftarNilaiEng: payload => dispatch(getLembarDaftarNilaiEng(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getTeacherSubjectsOptions: (payload) => dispatch(getTeacherSubjectsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (PengisianDaftarNilaiEng);