import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../components/base/index';
import { Link } from 'react-router-dom';

export default class Top10Form extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div className="data-buku__content">
        <div className="komposisi-siswa__custom-form">
            <div className="data-buku__custom-form-row__field">
                <div className="data-buku__custom-form-row11"> 
                    <Input
                        type="date"
                        name="tahunajaran"
                        label="Dari Tanggal"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                  
                </div>
                <p className="laporan-pegawai__custom-p">S/D</p>
                <div className="data-buku__custom-form-row15">
                    <Input
                        type="date"
                        name="tahunajaran"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                </div>
                
                <div className="data-buku__custom-form-column__field">
                    <Select
                    name="tahunajaran"
                    label="Unit Sekolah"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Select
                    type="number"
                    name="namaSaudara1"
                    label="Bentuk"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                
            </div> 
            
        </div>      
     </div>
  );
     }
}
Top10Form.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
Top10Form.defaultProps = {
  onFormChange: noop,
};
