import { connect } from 'react-redux';
import UploadStudent from '../../views/Student/UploadStudent.student.view';
import { uploadStudent } from '../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleUploadStudent: (payload, goBack) => dispatch(uploadStudent(payload, goBack)),
  };
}

export default connect(null, mapDispatchToProps)(UploadStudent);
