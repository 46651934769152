import { SET_LOADING } from '../actions/common.action';

export default function loading(state = { show: false, message: '' }, action) {
  switch (action.type) {
    case SET_LOADING: {
      const { show = false, message = '' } = action.payload;
      return {
        show,
        message,
      };
    }
    default: {
      return state;
    }
  }
}
