import { connect } from 'react-redux';
import Retire from '../../../views/Personnel/Letter/Retire.letter.view';
import { getRetire } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetRetire: payload => dispatch(getRetire(payload)),
  };
}

export default connect(null, mapDispatchToProps)(Retire);
