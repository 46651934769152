import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { Link } from 'react-router-dom';

export default function LaporanAbsensiTable(props) {
  const { onFormChange, form } = props;
  return (
    <div className="laporan-absensi__content">
                <div className="laporan-absensi__header">
                  <div className="laporan-absensi__header-top">
                    <div className="laporan-absensi__header-top--left">
                      <p>Laporan Absensi Tanggal 2019-11-24 s/d 2019-11-25</p>
                      
                    </div>
                  </div>

                </div>
                <div className="laporan-absensi__body">
                  <table className="laporan-absensi__table">
                    <thead>
                      <tr>
                        <th> Tanggal </th>
                        <th>Mata Pelajaran - Materi</th>
                        <th>Total</th>
                        <th>Sakit</th>
                        <th>Ijin</th>
                        <th>Alpha</th>
                        <th>Hadir</th>
                        <th>M</th>
                        <th>TBM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <td>24 Nov 1029</td>
                    <td>Bahasa Indonesia</td>
                    <td><Link to={''}>43</Link></td>
                    <td><Link to={'/dashboard/kesiswaan/ekstrakulikuler/buat-daftar-ekskul'}>0</Link></td>
                    <td><Link to={'/dashboard/kesiswaan/ekstrakulikuler/buat-daftar-ekskul'}>0</Link></td>
                    <td><Link to={'/dashboard/kesiswaan/ekstrakulikuler/buat-daftar-ekskul'}>0</Link></td>
                    <td><Link to={'/dashboard/kesiswaan/ekstrakulikuler/buat-daftar-ekskul'}>43</Link></td>
                    <td><Link to={'/dashboard/kesiswaan/absensi-siswa/Mengikuti'}>42</Link></td>
                    <td><Link to={'/dashboard/kesiswaan/absensi-siswa/TBM'}>1</Link></td>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
  );
}
LaporanAbsensiTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LaporanAbsensiTable.defaultProps = {
  onFormChange: noop,
};
