import { connect } from 'react-redux';
import AbsensiEkskul from '../../../views/Student/ekskul/AbsensiEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions , getStudentOptions, getParamOptionsAcademics} from '../../../states/thunks/options.thunk';
import {  listAbsenEkskul} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    handleListAbsenEkskul: (payload, goBack) => dispatch(listAbsenEkskul(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsensiEkskul);


