import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { errorAlert, successAlert } from '../../../../utils/alert.util';
import PropTypes from 'prop-types';
import {
  isEmpty, dropRight, keys,
  map, noop, find, findIndex, cloneDeep,
} from 'lodash';
import BankJournalForm from './components/BankJournalForm.component';
import { commaFormatted, normalizeAmount, dateFormat } from '../../../../utils/transformer.util';
import {
  FINANCE_CASH_JOURNAL_FORM_FIELDS,
  FINANCE_CASH_JOURNAL_RECONCILIATION_FORM,
  FINANCE_CASH_JOURNAL_STANDARD_FORM,
} from '../../../../constants/finance/finance.constant';
import Button from '../../../../components/base/Button/Button.component';
import BankJournalFormHeader from './components/BankJournalFormHeader.component';
import CashJournalFundRequest from '../CashJournal/components/CashJournalFundRequest.component';

const MySwal = withReactContent(Swal);

const calculateTotal = (list = null) => {
  let total = 0;
  if (!isEmpty(list)) {
    for (let key in list) {
      let item = list[key];

      if(list[key] && list[key].hasOwnProperty('amount')) {
        item = list[key]['amount'];
      }
      if (item) {
        total += parseFloat(item);
      }
    };
  }
  return total;
};

export default class ManageBankJournal extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getCoaOptions = this._getCoaOptions.bind(this);
    this._calculateSummary = this._calculateSummary.bind(this);
    this._showModal = this._showModal.bind(this);
    this._onCloseModal = this._onCloseModal.bind(this);
    this._renderFundRequests = this._renderFundRequests.bind(this);
    this._onSelectCoa = this._onSelectCoa.bind(this);
    this._onCheckFundRequest = this._onCheckFundRequest.bind(this);
    this._setSelectedFundRequest = this._setSelectedFundRequest.bind(this);
    this._handleDeleteJournalDetail = this._handleDeleteJournalDetail.bind(this);

    this.taxRecipientsParam = [];

    this.state = {
      counter: 0,
      form: {
        value: {
          date: dateFormat(new Date(), 'YYYY-MM-DD'),
          type: '',
          pos: '',
          reconciliation: [],
          standard: [],
          is_posted: false,
        },
        error: {
          reconciliation: [],
          standard: [],
        },
      },
      showTaxForm: false,
      taxType: null,
      summary: {
        totalGross: 0,
        totalNett: 0,
        totalTax: 0,
      },
    };
  }

  componentDidMount() {
    const { location, handleGetBankAccount } = this.props;
    const { isEdit = false, data } = location.state;

    if (isEdit) {
      this._setForm(data.id);
    }

    this._getCoaOptions();
    handleGetBankAccount();
  }

  async _onSelectCoa(coa, index) {
    const { form } = this.state;
    const {
      tipe = '',
      type,
      pos,
      date,
    } = form.value;
    const selectedCoa = coa.code || null;
    const { handleGetAvailableFundRequests } = this.props;
    if (tipe.toString() === '1' && type === 'BANK_KELUAR' && pos === 'ypl') {
      const fundRequests = await handleGetAvailableFundRequests({
        coa: selectedCoa,
        date: dateFormat(date, 'YYYY-MM-DD'),
      });
      this.setState(prevState => ({
        ...prevState,
        availableFundRequests: {
          [selectedCoa]: { ...fundRequests },
        },
      }), () => {
        if (!isEmpty(fundRequests)) {
          this._showModal(selectedCoa, index);
        }
      });
    }
  }

  _showModal(selectedCoa, index) {
    if (selectedCoa) {
      MySwal.fire({
        title: <p>Pilih Permohonan Dana</p>,
        html: this._renderFundRequests(selectedCoa, index),
      });
    }
    return false;
  }

  _onCloseModal(detailState, index) {
    const { form, counter } = this.state;
    const { value } = form;
    const { standard: standardForm } = value;
    const newStandardForm = cloneDeep(standardForm);
    const { selected } = detailState;
    let newIndex = index;
    const selectedLength = keys(selected).length - 1;

    if ((selectedLength + counter) > 10) {
      errorAlert({
        title: 'Tambah item jurnal gagal',
        message: 'JOURNAL_ITEM_EXCEED_LIMIT',
      });
      return false;
    }

    newStandardForm[newIndex].budget_details = [];

    if (selected) {
      Object.keys(selected).forEach((key) => {
        const newItem = cloneDeep(newStandardForm[index]);
        newItem.budget_details = [];
        if (index !== newIndex) {
          newItem.budget_details.push(key);
          newStandardForm.push(newItem);
        } else {
          newStandardForm[index].budget_details = [];
          newStandardForm[index].budget_details.push(key);
        }
        newIndex += 1;
      });
    }
    // if (unchecked) {
    //   for(let key in unchecked) {
    //     delete newStandardForm[index]['budget_details'][key];
    //   }
    // }

    this.setState(prevState => ({
      ...prevState,
      counter: selectedLength + counter,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          standard: [
            ...newStandardForm,
          ],
        },
      },
    }));

    return true;
  }

  _renderFundRequests(selectedCoa, index) {
    const {
      availableFundRequests = {}, selectedFundRequests = {},
    } = this.state;
    const fundRequests = { ...availableFundRequests[selectedCoa] } || {};

    return (
      <CashJournalFundRequest
        fundRequests={fundRequests}
        selectedFundRequests={selectedFundRequests}
        onChange={detailState => this._onCloseModal(detailState, index)}
        selectedCoa={selectedCoa}
      />
    );
  }

  _onCheckFundRequest(event) {
    const { target } = event;
    const { value, checked } = target;

    this._setSelectedFundRequest(checked, value);
  }

  _setSelectedFundRequest(checked = false, detailId = null) {
    const { form } = this.state;
    const { details = {}, recommendations = [] } = form.value;
    const newState = { ...this.state };
    const newRecommendations = [...recommendations];

    if (!checked) {
      delete newState.checkedDetails[detailId];
      delete newRecommendations[detailId];
    } else {
      const detail = find(details, (o, key) => parseInt(key, 0) === parseInt(detailId, 0));
      newState.checkedDetails[detailId] = detail;
      newRecommendations[detailId] = detail.amount;
    }
    newState.form.value.recommendations = newRecommendations;

    this.setState({
      ...newState,
    });
  }

  _calculateSummary() {
    const { form } = this.state;
    const { value } = form;
    const { standard } = value;

    let totalGross = 0;
    let totalNett = 0;

    if (standard && standard.length > 0) {
      standard.forEach((item) => {
        const { nominal } = item;
        totalGross += Number(normalizeAmount(nominal));
        totalNett = totalGross;
      });
    }

    this.setState(prevState => ({
      ...prevState,
      summary: {
        totalGross,
        totalNett,
      },
    }));
  }

  async _getCoaOptions(keyword = '') {
    const { form } = this.state;
    const { value } = form;
    const {
      tipe: tipeForm,
      bank_account = {},
      date,
      pos = '',
    } = value;
    const { handleGetCoaOptions, handleGetJournalCoaOptions } = this.props;
    const excludes = {
      groups: (typeof tipeForm !== 'undefined' && tipeForm.toString() === '2') ? [] : [12900],
      codes: [11101],
    };

    if (value.type === 'BANK_MASUK') {
      if (typeof tipeForm !== 'undefined' && tipeForm.toString() === '1' && typeof pos !== 'undefined') {
        excludes.groups = excludes.groups.concat([11100]);
        handleGetJournalCoaOptions({
          keyword,
          is_credit: true,
          pos: value.pos,
          bank_account: bank_account.id || null,
          journal_type: 'BANK',
          date: dateFormat(date, 'YYYY-MM-DD'),
        });
      } else if (typeof tipeForm !== 'undefined' && tipeForm.toString() === '2' && typeof pos !== 'undefined') {
        handleGetCoaOptions({
          keyword,
          is_credit: true,
          journal_type: 'BANK',
          bank_account,
          date: dateFormat(date, 'YYYY-MM-DD'),
        });
      }
    } else if (typeof tipeForm !== 'undefined' && tipeForm.toString() === '1') {
      handleGetJournalCoaOptions({
        keyword,
        classes: [50000, 10000, 20000, 30000],
        excludes,
        bank_account,
        journal_type: 'BANK',
        pos: value.pos,
        date: dateFormat(date, 'YYYY-MM-DD'),
      });
    } else if (tipeForm === '2') {
      handleGetCoaOptions({
        keyword,
        groups: [12900],
        bank_account,
        excludes,
        journal_type: 'BANK',
        date: dateFormat(date, 'YYYY-MM-DD'),
      });
    }
  }

  async _setForm(id) {
    const { handleGetBankJournal } = this.props;
    try {
      const payload = await handleGetBankJournal({ id });
      const { tipe = '' } = payload;
      this.setState(prevState => ({
        counter: (tipe !== null && tipe.toString() === '1') ? payload.details.standard.length : payload.details.reconciliation.length,
        form: {
          value: {
            id: payload.id,
            date: payload.date,
            type: payload.is_credit ? 'BANK_MASUK' : 'BANK_KELUAR',
            pos: payload.pos,
            number: payload.number,
            submitted_by: payload.submitted_by,
            received_by: payload.received_by,
            is_posted: payload.is_posted || false,
            bank_account: payload.bank_account || {},
            unit: payload.unit_id || {},
            standard: map(payload.details.standard, list => ({
              code_of_account: list.parameter_code,
              description: list.description,
              nominal: Number(list.nominal, 0),
              budget_details: list.budget_details || [],
              tax: list.tax,
            })),
            reconciliation: map(payload.details.reconciliation, list => ({
              code_of_account: list.parameter_code,
              nominal: Number(list.nominal, 0),
              description: list.description,
            })),
            tipe: tipe ? tipe.toString() : '',
            is_reconciliation: payload.is_reconciliation || false,
          },
          error: {
            reconciliation: [],
            standard: [],
          },
        },
        showTaxForm: prevState.showTaxForm,
      }), () => {
      });
    } catch (err) {
      // err action
    }
  }

  _showTaxForm(newValue, arrayPosition) {
    const { form } = this.state;
    const { value } = form;
    const { tipe, standard } = value;
    let showTaxForm = false;
    let taxType = null;

    if (tipe === '2') {
      return false;
    }

    if (!isEmpty(standard)) {
      standard.forEach((item, index) => {
        if (!isEmpty(item)) {
          const { code_of_account } = item;
          const { code } = code_of_account;

          if (arrayPosition === index) {
            if (code.toString().startsWith('515') || code.toString().startsWith('516')) {
              showTaxForm = true;
              taxType = '515';
            } else if (code.toString().startsWith('517')) {
              showTaxForm = true;
              taxType = '517';
            }
          }
        }
      });

      if (showTaxForm === false) {
        if (newValue.code.toString().startsWith('515') || newValue.code.toString().startsWith('516')) {
          showTaxForm = true;
          taxType = '515';
        } else if (newValue.code.toString().startsWith('517')) {
          showTaxForm = true;
          taxType = '517';
        }
      }
    }

    return {
      showTaxForm,
      taxType,
    };
  }

  async _calculateTax(idx) {
    const { handleCalculateTax } = this.props;
    const { form } = this.state;
    const { value: formValue } = form;
    const { standard } = formValue;

    if (standard && !isEmpty(standard) && standard[idx].tax) {
      const newList = standard;
      const item = standard[idx];
      const taxValues = item.tax;

      if (taxValues.recipient && taxValues.tax && taxValues.fields) {
        const res = await handleCalculateTax({
          data: {
            ...taxValues,
          },
        });

        if (res) {
          newList[idx].tax.tax_deduction = res[0].tax_deduction;

          this.setState(prevState => ({
            ...prevState,
            form: {
              value: {
                ...prevState.form.value,
                standard: [...newList],
              },
              error: {
                ...prevState.form.error,
              },
            },
          }));
        }
      }
    }

    return 0;
  }

  async _onTaxFormChange(name, value, idx) {
    this.setState((prevState) => {
      const {
        showTaxForm, taxType, counter, form,
      } = prevState;

      const { value: formValues } = form;
      const newList = [...formValues.standard];
      const newItem = { ...newList[idx] };
      const { code_of_account } = newItem;
      const { code } = code_of_account;
      const newTax = { ...newItem.tax };

      if (name === 'tax' || name === 'recipient' || name === 'tax_type') {
        newTax[name] = value;
      } else {
        newTax.fields[name] = value;
      }

      if (!code.toString().startsWith('515')) {
        delete newTax.ptkp;
        delete newTax.fields.working_day;
      }

      newList[idx].tax = newTax;

      return {
        counter,
        form: {
          value: {
            ...formValues,
            standard: newList,
          },
          error: {
            ...prevState.form.error,
          },
        },
        showTaxForm,
        taxType,
      };
    }, () => {
      this._calculateSummary();
    });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      const { form } = prevState;
      const { value: formValue } = form;
      const { pos } = formValue;
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (name === 'tipe' || name === 'type' || name === 'pos') {
        let newPos = pos;
        if ((name === 'tipe' && value === '2')) {
          newPos = null;
        }
        if (name === 'pos') {
          newPos = value;
        }
        return {
          counter: 0,
          form: {
            value: {
              ...form.value,
              pos: newPos,
              [name]: formattedValue,
              reconciliation: [],
              standard: [],
            },
            error: {
              ...form.error,
              [name]: '',
              reconciliation: [],
              standard: [],
            },
          },
          showTaxForm: false,
          taxType: null,
        };
      }

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = form.value[fieldName];
        newListError = form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;

        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }

        newList[arrayPosition] = {
          ...newList[arrayPosition],
        };
      }

      return {
        ...prevState,
        form: {
          value: {
            ...form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
        selectedCoa: (name === 'code_of_account') ? value.code : prevState.selectedCoa,
      };
    }, () => {
      if (name === 'nominal') {
        this._calculateSummary();
      }
      if (name === 'code_of_account') {
        this._onSelectCoa(value, arrayPosition);
      }
      if (name === 'tipe' || name === 'type' || name === 'pos' || name === 'date' || name === 'bank_account') {
        this._getCoaOptions();
      }
    });
  }

  _onAddList(fieldName) {
    const { counter } = this.state;
    if (counter < 10) {
      this.setState(prevState => ({
        counter: counter + 1,
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [
              ...prevState.form.value[fieldName],
              {
                budget_details: [],
                tax: {},
              },
            ],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [
              ...prevState.form.error[fieldName],
              {},
            ],
          },
        },
      }));
    }
  }

  _onClearForm() {
    this.setState({
      counter: 0,
      form: {
        value: {},
        error: {},
      },
      showTaxForm: false,
      taxType: null,
    });
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleManageBankJournal,
      history,
    } = this.props;
    await handleManageBankJournal(form.value, history.goBack);
  }

  _handleDeleteJournalDetail(idx, id, fieldName) {
    const { handleDeleteJournalDetail } = this.props;
    const { form, counter } = this.state;
    const { value } = form;
    const newList = [...value[fieldName]];

    newList.splice(idx, 1);

    if (id) {
      handleDeleteJournalDetail({
        id,
      });
    }

    this.setState(prevState => ({
      ...prevState,
      counter: counter - 1,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          [fieldName]: newList,
        }
      }
    }));
  }

  render() {
    const {
      form, showTaxForm, taxType, summary,
    } = this.state;
    const { value = {} } = form;
    const { bank_account = {} } = value;
    const { totalGross, totalTax, totalNett } = summary;
    const {
      coa = {list:[]}, location, handleGetTaxPtkpParam, handleGetTaxRecipientParam,
      handleGetUnitOptions, handleGetBankAccount, user,
    } = this.props;
    const { list = [] } = coa;
    const filteredCoa = {
      list: list.filter((item) => {
        if (!bank_account.id) {
          return true;
        }
        return item.code.toString() !== bank_account.id.toString();
      }),
    };
    const { isEdit = true } = location.state;
    const {
      reconciliation,
      standard,
      tipe,
      type,
      is_posted,
      is_reconciliation = false,
    } = form.value;
    const {
      reconciliation: reconciliationErrors,
      standard: standardErrors,
    } = form.error;
    return (
      <div className="manage-cash-journal">
        <form onSubmit={this._onSubmit}>
          <BankJournalFormHeader
            form={form}
            coa={coa}
            onFormChange={this._onFormChange}
            isDebit={type === 'BANK_KELUAR'}
            isReconciliation={is_reconciliation}
            showEdit={isEdit}
            onSearchUnit={handleGetUnitOptions}
            onSearchBankAccount={handleGetBankAccount}
            user={user}
          />
          {
            type && (tipe === '1' || !isEmpty(standard)) && (
              <div>
                <BankJournalForm
                  showTax={false}
                  isPosted={is_posted}
                  title="Standard"
                  listField={FINANCE_CASH_JOURNAL_STANDARD_FORM}
                  error={standardErrors}
                  list={standard}
                  coa={filteredCoa}
                  fieldName="standard"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                  onSearchCoa={this._getCoaOptions}
                  showTaxForm={showTaxForm}
                  onTaxFormChange={this._onTaxFormChange}
                  taxType={taxType}
                  isReconciliation={is_reconciliation}
                  getTaxRecipientParam={handleGetTaxRecipientParam}
                  getTaxPtkpParam={handleGetTaxPtkpParam}
                  onDeleteJournalDetail={this._handleDeleteJournalDetail}
                  tipe={tipe}
                />
              </div>
            )
          }
          {
            type && (tipe === '2' || !isEmpty(reconciliation)) && (
              <div>
                <BankJournalForm
                  showTax={false}
                  isPosted={is_posted}
                  coa={coa}
                  title="Rekonsiliasi"
                  listField={FINANCE_CASH_JOURNAL_RECONCILIATION_FORM}
                  error={reconciliationErrors}
                  list={reconciliation}
                  fieldName="reconciliation"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                  onSearchCoa={this._getCoaOptions}
                  onDeleteJournalDetail={this._handleDeleteJournalDetail}
                  tipe={tipe}
                  isReconciliation={is_reconciliation}
                />
              </div>
            )
          }
          <div className="manage-cash-journal__footer">
            <div>
              <h2>Total Bruto: Rp {commaFormatted(totalGross)}</h2>
              <h2>Total Neto: Rp {commaFormatted(totalNett)}</h2>
            </div>
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}

ManageBankJournal.propTypes = {
  handleGetAvailableFundRequests: PropTypes.func,
  handleGetTaxPtkpParam: PropTypes.func,
  handleGetTaxRecipientParam: PropTypes.func,
  handleCalculateTax: PropTypes.func,
  handleGetCoaOptions: PropTypes.func,
  handleGetJournalCoaOptions: PropTypes.func,
  handleManageBankJournal: PropTypes.func,
  handleGetBankJournal: PropTypes.func,
  handleGetUnitOptions: PropTypes.func,
  handleGetBankAccount: PropTypes.func,
  handleDeleteJournalDetail: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
  user: PropTypes.object,
};
ManageBankJournal.defaultProps = {
  handleGetAvailableFundRequests: noop,
  handleGetTaxPtkpParam: noop,
  handleGetTaxRecipientParam: noop,
  handleCalculateTax: noop,
  handleGetCoaOptions: noop,
  handleGetJournalCoaOptions: noop,
  handleManageBankJournal: noop,
  handleGetBankAccount: noop,
  handleGetBankJournal: noop,
  handleGetUnitOptions: noop,
  handleDeleteJournalDetail: noop,
  user: {},
};
