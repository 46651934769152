import { connect } from 'react-redux';

import MataPelajaran from '../../../views/Kurikulum/MataPelajaran/MataPelajaran.view';
function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      levels: state.options.levels,
      user: state.user || {},
  
    }
  }
export default connect(mapStateToProps, null)(MataPelajaran);
