import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat, normalizeAmount } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";
import TabelInputSikap from '../components/TabelInputSikap.component';
import TabelInputPengetahuan from '../components/TabelInputPengetahuan.component';
import TabelInputKeterampilan from '../components/TabelInputKeterampilan.component';


function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

export default class InputRaportNarasi extends Component {
  constructor(props) {
    super(props);
    this._onClickSubmit = this._onClickSubmit.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickAddPengetahuan = this._onClickAddPengetahuan.bind(this);
    this._onClickAddKeterampilan = this._onClickAddKeterampilan.bind(this);
    this.state = {
        counter:0,
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              plafon: [],
            },
            error: {
              plafon: '',
    
            },
          },
    };
  }

  _onClickSubmit() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/raport/cetak/raport-narasi');
  }

  _onSubmit() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/create-invoice/generate-invoice');
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

 
  _onClickAdd(fieldName) {
    const { form } = this.state;
    const { plafon } = form.value;

    const {plafond_name} = form.value;
    // const {code_plafond} = form.value;

    const {code_plafond} = form.value;

    // const {code_class} = form.value;

    plafon.push({
      // code_class :'',

      // code_plafond : ',',
      plafond_name:',',

      code_plafond : ',',

    
    // plafon.push({
      tipePlafon: '',
      // divisi: '',

      nominal: 0,
      //period:'',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
          plafond_name,
          // code_plafond,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onClickAddPengetahuan(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    const {plafond_name} = form.value;
    // const {code_plafond} = form.value;

    const {code_plafond} = form.value;

    // const {code_class} = form.value;

    plafon.push({
      // code_class :'',

      // code_plafond : ',',
      plafond_name:',',

      code_plafond : ',',

    
    // plafon.push({
      tipePlafon: '',
      // divisi: '',

      nominal: 0,
      //period:'',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
          plafond_name,
          // code_plafond,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onClickAddKeterampilan(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    const {plafond_name} = form.value;
    // const {code_plafond} = form.value;

    const {code_plafond} = form.value;

    // const {code_class} = form.value;

    plafon.push({
      // code_class :'',

      // code_plafond : ',',
      plafond_name:',',

      code_plafond : ',',

    
    // plafon.push({
      tipePlafon: '',
      // divisi: '',

      nominal: 0,
      //period:'',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
          plafond_name,
          // code_plafond,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }


  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list
      };
    });
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  render() {
    const {form} = this.state;
    const { value, error } = form;
    
    const { classes ={}, location, plafond = {}} = this.props;
    // const { isEdit } = location.state;
    const {
      reconciliation,
      standard,
      tipe,
      type,
      is_posted,
      is_reconciliation
    } = form.value;
    return (
      
     <div>
       
        <Button
          title="Cetak Ke Printer"
          onClick={this._onClickSubmit}
        />
       
              <div>
                <div className="laporan-ki4__content">
                  <div className="laporan-ki4__header">
                      <div className="laporan-ki4__custom-form-row-square">
                          <div className="laporan-ki4__box--square2">
                              Image
                          </div>
                          <div className="laporan-ki4__header-title-input">
                            <div className="laporan-ki4__header-title-input--column">
                                {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                LAPORAN HASIL BELAJAR SISWA
                            </div>
                            <div className="laporan-ki4__header-title-input--column">
                                {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                SEKOLAH MENENGAH PERTAMA
                            </div>        
                          </div>
                        </div>
                    </div>
                </div>  
                    <div className="laporan-ki4__header-title3">
                        <div className="laporan-ki4__header-title3--left">
                            <table className="laporan-ki4__table-test">
                                <tr> 
                                    <td></td>    
                                    <td>Nama Siswa </td>
                                    <td>  : Gange</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>Nomor Induk </td>
                                    <td>  : SMP Katolik Ricci</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>No.ID.Reg</td>
                                    <td>  : SMP Katolik Ricci</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Nama Sekolah</td>
                                    <td>  : SMP Katolik Ricci</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Alamat Sekolah</td>
                                    <td>  : SMP Katolik Ricci</td> 
                                    
                                </tr>
                                
                            </table> 
                        </div>
                        
                        <div className="laporan-ki4__header-title3--right">
                            <table className="laporan-ki4__table-test">
                                <tr> 
                                    <td></td>    
                                    <td>Tahun Ajaran </td>
                                    <td>  : SMP Katolik Ricci</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>Periode</td>
                                    <td>  : SMP Katolik Ricci</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Kelas / No.Absen</td>
                                    <td>  : SMP Katolik Ricci</td> 
                                        
                                </tr>
                            </table>
                        </div>
                     </div>
                     <div className="laporan-ki4__header-title-table">
                        A. Sikap
                    </div>
                     <div>
                        <TabelInputSikap
                        plafond_name={plafond.list}
                        onAddList={this._onClickAdd}
                        list={value.plafon}
                        onDeleteList={this._onDeleteList}
                        onChange={this._onFormChange}
                            
                        />
                    </div>
                    <div className="laporan-ki4__header-title-table">
                        B. Pengetahuan
                    </div>
                     <div>
                        <TabelInputPengetahuan
                        plafond_name={plafond.list}
                        onAddList={this._onClickAddPengetahuan}
                        list={value.plafon}
                        onDeleteList={this._onDeleteList}
                        onChange={this._onFormChange}
                            
                        />
                    </div>
                    <div className="laporan-ki4__header-title-table">
                        C. Ketereampilan
                    </div>
                     <div>
                        <TabelInputPengetahuan
                        plafond_name={plafond.list}
                        onAddList={this._onClickAddKeterampilan}
                        list={value.plafon}
                        onDeleteList={this._onDeleteList}
                        onChange={this._onFormChange}
                            
                        />
                    </div>


{/* 
                     <div className="laporan-ki4__header-title-table">
                        A. Sikap
                    </div>

                     <div className="laporan-ki4__body">
                         <table className="laporan-ki4__table">
                             <thead>
                                <tr>
                                    <th>NO</th>
                                    <th>INDIKATOR</th>
                                    <th>NILAI</th>
                                    <th>CATATAN</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>NAMA MATA PELAJARAN</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                              </tbody>
                         </table>
                     </div>

                     
                    <div className="laporan-ki4__header-title2">
                        <div className="laporan-ki4__header-title2--column">
                            <div className="laporan-ki4__header-title2--bold">
                                Kesimpulan
                            </div>
                       
                            <div className="laporan-ki4__header-title2--row">
                                <div className="laporan-ki4__header-title2--row">
                                        Dinyatakan &nbsp;
                                </div>
                                <div className="laporan-ki4__header-title2--bold">
                                    NAIK/TIDAK NAIK
                                </div>
                                <div className="laporan-ki4__header-title2--row">
                                    &nbsp;ke kelas VII / Program Studi:Ilmu Alam atau Ilmu Sosial atau Ilmu Bahasa
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="laporan-ki4__custom-form-row">
                        <div className="laporan-ki4__custom-form-row__field">

                            <div className="laporan-ki4__custom-form-column">
                                <p>Mengetahui</p>
                                <div className="laporan-ki4__custom-form-column">
                                    <p>Orang Tua Wali</p>
                                </div>
                                <div className="laporan-ki4__custom-form-column__field">
                                    <p>.....................................</p>
                                </div>
                            </div>

                            <div className="laporan-ki4__custom-form-column3">
                                <p>Jakarta,</p>
                                    <div className="laporan-ki4__custom-form-column">
                                        <p>Wali Kelas</p>
                                    </div>
                                        <div className="laporan-ki4__custom-form-column3__field2">
                                            <p>(&emsp; &emsp; &emsp; &emsp; &emsp;)</p>
                                        </div>
                            </div>

                           
                        </div>
                     </div> */}
                   
               
             
                     </div>
    
      </div>
    );
  }

}
InputRaportNarasi.propTypes = {
    handleGetStudent: PropTypes.func,
    history: PropTypes.object.isRequired,
  };
  InputRaportNarasi.defaultProps = {
    handleGetStudent: noop,
  };
  