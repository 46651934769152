import { connect } from 'react-redux';
import Identitas from '../../../views/Akademi/DataKelas/Identitas.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Identitas);
