import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { Link } from 'react-router-dom';

export default function LeggerPeriodeForm(props) {
  const { onFormChange, form } = props;
  return (
    <div className="legger-periode__content">
                <div className="legger-periode__body">
                  <table className="legger-periode__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Lengkap</th>
                        <th>NIS</th>
                        <th>NISN</th>
                        <th colSpan={2}>Mata Pelajaran 1</th>
                        <th>JML</th>
                        <th>RATA-RATA</th>
                        <th>Peringkat</th>
                        <th>T</th>
                        <th>S</th>
                        <th>I</th>
                        <th>A</th>
                        <th>?</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>80</td>
                        <td>B</td>
                        <td></td>
                        <td></td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td colSpan={5} className="nominal">JUMLAH</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="nominal">RATA-RATA</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="nominal">D.SERAP(%)</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="nominal">KETUNTASAN (%)</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="nominal">TERTINGGI</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="nominal">TERENDAH</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    <tr>
                        <td colSpan={5} className="nominal">N.KURANG</td>
                        <td colSpan={9} className="nominal"></td>
                    </tr>
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
LeggerPeriodeForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LeggerPeriodeForm.defaultProps = {
  onFormChange: noop,
};
