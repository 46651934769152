import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty, isEqual } from 'lodash';
import { Button, Input, Checkbox } from '../../../../../components/base';
import BudgetField from './BudgetField.component';
import language from '../../../../../languages';
import { calculateAllSemester, commaFormatted } from '../../../../../utils/transformer.util';

const userGroupPusat = [
  'Korektor Pusat',
  'Accounting Pusat',
  'Manager Keuangan',
  'Bendahara',
];

export default class BudgetRapbu extends Component {
  constructor(props) {
    super(props);
    const { user, workflow } = props;
    const { user_group } = user;
    const { next_role, is_done } = workflow;

    this.setRef = this.setRef.bind(this);
    this.setHeaderRef = this.setHeaderRef.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.renderPrevRecommendation = this.renderPrevRecommendation.bind(this);

    this.pos = [
      'ypl', 'committee', 'intern', 'bos',
    ];

    this.checkboxRefs = {
      'ypl': {},
      'committee': {},
      'intern': {},
      'bos': {},
    };

    this.checkboxAllRefs = {
      'ypl': React.createRef(),
      'committee': React.createRef(),
      'intern': React.createRef(),
      'bos': React.createRef(),
    };

    this.state = {
      'checkedAll' : {
        'ypl': false,
        'committee': false,
        'intern': false,
        'bos': false,
      },
      'hasApbu': false,
      'isBendahara': false,
      'isManagerKeuangan': false,
      'isDone': false,
      'isPusat': false,
      'canEdit': false,
      'hasCheckbox': false,
    };
  }

  componentDidMount() {
    const { user, workflow } = this.props;
    const { user_group } = user;
    const userGroup = user_group.name;
    const { next_role, is_done } = workflow;

    this.setState(prevState => ({
      ...prevState,
      'hasApbu': (userGroup === 'Bendahara' || userGroup === 'Manager Keuangan') ,
      'isDone': is_done || false,
      'isPusat': user_group && userGroupPusat.includes(userGroup),
      'canEdit': userGroupPusat.includes(userGroup) && userGroup === next_role && !is_done && userGroup !== 'Bendahara',
      'hasCheckbox': userGroupPusat.includes(userGroup) && !is_done,
    }));
  }

  componentDidUpdate(prevProps) {
    const { user, workflow } = this.props;
    const { user_group } = user;
    const userGroup = user_group.name;
    const { next_role, is_done } = workflow;
    if(!isEqual(prevProps.workflow, workflow) || !isEqual(prevProps.user, user))
    {
      this.setState(prevState => ({
        ...prevState,
        'hasApbu': (userGroup === 'Bendahara' || userGroup === 'Manager Keuangan') ,
        'hasRekomendasi': (userGroup === 'Manager Keuangan' || userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat'),
        'isDone': is_done || false,
        'isPusat': user_group && userGroupPusat.includes(userGroup),
        'canEdit': userGroupPusat.includes(userGroup) && userGroup === next_role && !is_done && userGroup !== 'Bendahara',
        'hasCheckbox': userGroupPusat.includes(userGroup) && !is_done && userGroup !== 'Bendahara',
      }));
    }
  }

  setRef(el) {
    if(el === null || !el) {
      return;
    }
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
    this.checkboxRefs[pos][referenceid] = input;
  }

  setHeaderRef(el) {
    if(el === null || !el) {
      return;
    }
    const { input } = el;
    const { dataset } = input;
    const { pos } = dataset;
    this.checkboxAllRefs[pos] = input;
  }

  onCheckAll(event) {
    const { onCheckAll } = this.props;
    const { checkedAll } = this.state;
    const { target } = event;
    const { dataset } = target;
    const { pos } = dataset;
    const checkboxPos = this.checkboxRefs[pos];

    for (let referenceId in checkboxPos) {
      checkboxPos[referenceId].checked = target.checked;
    }

    onCheckAll(checkboxPos, target.checked)
  }

  onCheck(event) {
    const { onSelectBudgetDetail, recommendations, user } = this.props;
    const { user_group } = user;
    const { isBendahara } = this.state;
    const { target } = event;
    const { dataset } = target;
    const { pos } = dataset;
    let prevRecommendation = null;

    /*if (user_group.name === 'Manager Keuangan') {
      if(recommendations && recommendations[10] && recommendations[10][pos]) {
        prevRecommendation = recommendations[10][pos][target.value];
      }
    }*/

	if (user_group.name === 'Manager Keuangan') {
      if(recommendations && recommendations[10] && recommendations[10][pos]) {
        prevRecommendation = recommendations[10][pos][target.value];
      }
	  else if (recommendations && recommendations[14] && recommendations[14][pos]) {
        prevRecommendation = recommendations[14][pos][target.value];
	  }
    }

    onSelectBudgetDetail(target, prevRecommendation);
  }

  onClickDownload(event) {
    const { handleFileDownload } = this.props;
    const { target } = event;
    const { filename } = target.dataset;

    handleFileDownload(filename);
  }

  renderPrevRecommendation(recommendations = {}, userGroup, type, id) {
    const { hasApbu } = this.state;
    let recommendation = (recommendations[10] || recommendations[14]);
    if(userGroup === 'Bendahara') {
        recommendation = recommendations[8];
    }

    if(hasApbu) {
      if(recommendation) {
        const value = (recommendation[type]) ? recommendation[type][id] : 0;

        return (
            <p>{commaFormatted(value)}</p>
        );
      }
    }
    return false;
  }

  renderRows(list) {
    const { recommendations, user, workflow,
      onChangeRecommendation, onSelectBudgetDetail, rapbuSummary } = this.props;
    const { persentase } = rapbuSummary;
    const { is_done = false, next_role } = workflow;
    const { user_group, user_groups_id } = user;
    const recommendation = recommendations[14] || recommendations[user_group.id] || {};
    const { ypl = null, komite = null, intern = null, bos = null }  =
      recommendation;
    const { canEdit, isPusat, hasApbu, hasCheckbox } = this.state;
    const self = this;

    let rows = [];

    map(list, (data, idx) => {
      const { code_of_account: coa } = data;
      const draft = data.budget_detail_draft || {};
      const cols = [
        <td key={`rapbu_row_${data.id}__coa`}>{data.code_of_account}</td>,
        <td key={`rapbu_row_${data.id}__coa_description`}>{data.parameter_code ? data.parameter_code.title : ''}</td>,
        <td key={`rappbu_row_${data.id}__desc`} className="border_double">{data.desc}</td>
      ];
      let posType = 'pendapatan';

      if (coa.toString().startsWith('1')) {
        posType = 'inventaris';
      } else if(coa.toString().startsWith('5')) {
        posType = 'pengeluaran';
      }

      this.pos.forEach((pos) => {
        let differClass = '';
        const isChecked = (recommendation
          && recommendation[pos]
          && recommendation[pos][data.id]
          && recommendation[pos][data.id] !== "0") ? true : false;

        if ((user_group.name === 'Korektor Pusat' || user_group.name === 'Manager Keuangan' || user_group.name === 'Accounting Pusat') && canEdit) {
          cols.push([
            <td key={`rapbu_row_${pos}_${data.id}__checkbox`} className="border_double border_double__left">
              <Checkbox
                noMargin
                checked={isChecked}
                name="select_budget_detail"
                data-referenceid={data.id}
                data-pos={pos}
                data-rapbu={data[pos]}
                data-postype={posType}
                data-sourcevalue={data[pos]}
                value={data.id}
                ref={this.setRef}
                onChange={this.onCheck}
              />
            </td>
          ]);
        }

        //Rapbu
        cols.push([
          <td key={`rapbu_row_${pos}_${data.id}__rapbu`} className="nominal border_double border_double__left">{commaFormatted(data[pos])}</td>
        ]);

        //rekomendasi
        if(isPusat) {
          if(user_group.name === 'Korektor Pusat' || user_group.name === 'Accounting Pusat') {
            if(recommendation && recommendation[pos] && recommendation[pos][data.id]) {
              if(recommendation[pos][data.id] !== data[pos]) {
                differClass = 'differ';
              }
            }
            cols.push([
              <td key={`rapbu_row_${pos}_${data.id}__rekomendasi`} className={`nominal ${differClass}`}>
                <Input
                  type="text"
                  isNumber
                  noMargin
                  name={pos}
                  edit={canEdit}
                  data-rapbu={data[pos]}
                  data-referenceid={data.id}
                  data-postype={posType}
                  onChange={onChangeRecommendation}
                  value={ (recommendation && recommendation[pos]) ? recommendation[pos][data.id] : 0}
                />
              </td>,
            ]);
          } else if(user_group.name === 'Manager Keuangan') {
            if(recommendation && recommendation[pos] && recommendation[pos][data.id]) {
              if(recommendation[pos][data.id] !== data[pos]) {
                differClass = 'differ';
              }
            }
            cols.push([
              <td key={`rapbu_row_${pos}_${data.id}__rekomendasi`} className="nominal">
                {self.renderPrevRecommendation(recommendations, user_group.name, pos, data.id)}
              </td>,
              <td key={`rapbu_row_${pos}_${data.id}__apbu`} className={`nominal ${differClass}`}>
                <Input
                  type="text"
                  isNumber
                  noMargin
                  name={pos}
                  edit={canEdit}
                  data-referenceid={data.id}
                  data-rapbu={data[pos]}
                  data-postype={posType}
                  onChange={onChangeRecommendation}
                  value={ (recommendation && recommendation[pos]) ? recommendation[pos][data.id] : 0}
                />
              </td>,
              (pos === 'ypl' && <td key={`rapbu_row_${pos}_${data.id}__percentage`} className="border_double border_double__right persentase">
                {persentase && persentase[pos] && persentase[pos][data.id] ? persentase[pos][data.id] : 0} %
              </td>)
            ]);
          } else if (user_group.name === 'Bendahara') {
            cols.push([
              <td key={`rapbu_row_${pos}_${data.id}__apbu`} className="nominal">
                {self.renderPrevRecommendation(recommendations, user_group.name, pos, data.id)}
              </td>,
              (pos === 'ypl' && <td key={`rapbu_row_${pos}_${data.id}__percentage`} className="border_double border_double__right persentase">
                {
                  persentase && persentase[pos] && persentase[pos][data.id] ? persentase[pos][data.id] : 0
                } %
              </td>)
            ]);
          }
        }
      });

      cols.push([
        <td key={`rapbu_row_${data.id}__file`} className="border_double file">
          {data.file && (
          <a onClick={this.onClickDownload} data-filename={data.file.name}>
            {data.file.display_name}
          </a>)}
        </td>,
        <td key={`rapbu_row_${data.id}__total`} className="nominal">{commaFormatted(data.total)}</td>
      ]);

      rows.push([
        <tr key={`rapbu_col_${data.id}`}>
          {cols}
        </tr>
      ]);
    });

    return rows;
  }

  renderSubHeader() {
    const { isPusat, hasCheckbox, hasApbu, canEdit } = this.state;
    const { user } = this.props;
    const { user_group } = user;

    //RAPBU | Rekomendasi
    //RAPBU | Rekomendasi | APBU
    //RAPBU | APBU

    const label1 = 'RAPBU';
    const label2 = 'Rekomendasi';
    const label3 = 'APBU';

    const subHeaders = [];

    subHeaders.push([
      <th key='rapbu_kode_akun'>
        Kode
      </th>,
      <th key='rapbu_keterangan_akun' className="border_doube border_double_right">
        Uraian
      </th>,
    ])

    this.pos.forEach((item) => {
      if((user_group.name === 'Korektor Pusat' || user_group.name === 'Manager Keuangan' || user_group.name === 'Accounting Pusat') && canEdit) {
        subHeaders.push([
          <th key={`checkAll_${item}`} className="border_double border_double__left">
            <Checkbox
              name="check_all"
              data-pos={item}
              onChange={this.onCheckAll}
              ref={this.setHeaderRef}
            />
          </th>
        ]);
      }

      subHeaders.push([
        <th key={`rapbu_${item}`} className="border_double border_double__left">RAPBU</th>,
      ]);

      if (isPusat) {
        if ((user_group.name === 'Korektor Pusat' || user_group.name === 'Accounting Pusat')) {
          subHeaders.push([
            <th key={`rapbu_${item}_rekomendasi`} className="border_double border_double__right">Rekomendasi</th>,
          ]);
        }

        if (user_group.name === 'Manager Keuangan') {
          subHeaders.push([
            <th key={`rapbu_${item}_rekomendasi`} className="border_double border_double__right">Rekomendasi</th>,
            <th key={`apbu_${item}`}>APBU</th>,
            (item === 'ypl' && <th key={`apbu_percentage_${item}`} className="border_doube_border_double__right">%</th>)
          ]);
        }

        if (user_group.name === 'Bendahara') {
          subHeaders.push([
            <th key={`apbu_${item}`}>APBU</th>,
            (item === 'ypl' && <th key={`apbu_percentage_${item}`} className="border_doube_border_double__right">%</th>),
          ]);
        }
      }
    });

    if(isEmpty(subHeaders)) {
      return false;
    }

    return (
      <tr className="sub_header_row">
        {subHeaders}
      </tr>
    );
  }

  renderHeader() {
    const {
      hasApbu, isPusat, canEdit
    } = this.state;
    const { user, workflow } = this.props;
    const { user_group } = user;

    let posColspan = 1;
    let yplColspan = 1;
    if (isPusat) {
      if (user_group.name === 'Korektor Pusat' || user_group.name === 'Accounting Pusat') {
        if(canEdit) {
          posColspan = 3;
	  yplColspan = 3;
        } else {
          posColspan = 2;
	  yplColspan = 2;
        }
      }
      if (user_group.name === 'Manager Keuangan') {
        if(canEdit) {
          posColspan = 4;
          yplColspan = 5;
        } else {
          posColspan = 3;
          yplColspan = 4;
        }
      }
      if (user_group.name === 'Bendahara') {

        posColspan = 2;
        yplColspan = 3;
      }
    }
    return (
      <thead>
        <tr>
          <th className="manage-budget__row" colSpan={2}>Akun</th>
          <th rowSpan={2} className="border_double">Keterangan</th>
          <th colSpan={yplColspan} className="border_double border_double__left">YPL</th>
          <th colSpan={posColspan} className="border_double border_double__left">Komite</th>
          <th colSpan={posColspan} className="border_double border_double__left">Intern</th>
          <th colSpan={posColspan} className="border_double">BOS</th>
          <th rowSpan={2} className="border_double">File</th>
          <th rowSpan={2} colSpan={1}>Jumlah</th>
        </tr>
        {this.renderSubHeader()}
      </thead>
    );
  }

  renderSummary(label, posType, data) {
    const { workflow, user } = this.props;
    const { next_role } = workflow;
    const { user_group } = user;
    const userGroup = user_group.name;
    const { hasApbu, isPusat, isDone, canEdit } = this.state;
    const hasCheckbox = isPusat && !isDone && next_role === user_group.name ;
    const summaryRow = [];
    const pos = ['ypl', 'komite', 'intern', 'bos'];
    let inRedClass = '';

    summaryRow.push([
      <td key={`rapbu_summary_row__label_${posType}`} className="total" colSpan={3}>{label}</td>
    ]);

    pos.forEach((item) => {
      inRedClass = '';
      let totalRekomendasiInRed =
        (data[`total_${posType}_rekomendasi_${item}`] < 0) ? 'red' : '';
      let totalApbuInRed =
        (data[`total_${posType}_apbu_${item}`] < 0) ? 'red' : '';


      if ((userGroup === 'Manager Keuangan' || userGroup === 'Korektor Pusat' || userGroup === 'Accounting Pusat') && canEdit) {
        summaryRow.push([
          <td key={`rapbu_summary_row__checkbox__${item}_${posType}`}
            className="border_double border_double__left"></td>
        ]);
      }

      if(data[`total_${posType}_${item}`] < 0) {
        inRedClass = 'red';
      }

      summaryRow.push([
        <td key={`rapbu_summary_row__${item}_${posType}`}
          className={`border_double border_double__left nominal ${inRedClass}`}
        >
          {commaFormatted(data[`total_${posType}_${item}`])}
        </td>
      ]);


      if (isPusat) {
        if ((user_group.name === 'Korektor Pusat' || user_group.name === 'Accounting Pusat')) {
          summaryRow.push([
            <td
              key={`rapbu_${item}_rekomendasi`}
              className={`border_double border_double__right nominal ${totalRekomendasiInRed}`}>
              {commaFormatted(data[`total_${posType}_rekomendasi_${item}`])}
            </td>,
          ]);
        }

        if (user_group.name === 'Manager Keuangan') {
          summaryRow.push([
            <td
              key={`rapbu_summary_row__${item}_rekomendasi`}
              className={`border_double border_double__right nominal ${totalRekomendasiInRed}`}>
              {commaFormatted(data[`total_${posType}_rekomendasi_${item}`])}
            </td>,
            <td
              key={`rapbu_summary__row${item}_apbu`}
              className={`border_double border_double__right nominal ${totalApbuInRed}`}>
              {commaFormatted(data[`total_${posType}_apbu_${item}`])}
            </td>,
            (item === 'ypl' &&
            <td
              key={`rapbu_summary__row${item}_percentage`}
              className="border_double border_double__right">
            </td>)
          ]);
        }

        if (user_group.name === 'Bendahara') {
          summaryRow.push([
            <td
              key={`rapbu_summary__row${item}_apbu`}
              className="nominal">
              {commaFormatted(data[`total_${posType}_apbu_${item}`])}
            </td>,
            (item === 'ypl' &&
            <td key={`rapbu_summary__row${item}_percentage`} className="border_double border_double__right"></td>),

          ]);
        }
      }
      //
    });

    if(data[`total_${posType}`] < 0) {
      inRedClass = 'red';
    } else {
      inRedClass = '';
    }
    summaryRow.push([
      <td key={`rapbu_summary__row_file_${posType}`} className="border_double"></td>,
      <td key={`rapbu_summary_row__total_${posType}`} className={`nominal ${inRedClass}`}>
        {commaFormatted(data[`total_${posType}`])}
      </td>
    ])

    return (
      <tr key={`rapbu_summary_row__${posType}`} className="summary total">
        {summaryRow}
      </tr>
    );
  }

  render() {
    const {
      rapbuSummary, onChangeRecommendation, recommendations,
      workflow, user,
    } = this.props;
    const { next_role } = workflow;
    const { user_group } = user;
    const { semesterOne, semesterTwo, inventaris } = rapbuSummary;
    const {
      hasApbu, isPusat, hasCheckbox, canEdit
    } = this.state;

    let tableColSpan = 9;
    const summaryRowColSpan = 3;


    if (isPusat) {
      if (user_group.name === 'Korektor Pusat' || user_group.name === 'Accounting Pusat') {
        if(canEdit) {
          tableColSpan = 21;
        } else {
          tableColSpan = 17;
        }
      }
      if (user_group.name === 'Manager Keuangan') {
        if(canEdit) {
          tableColSpan = 25;
        } else {
          tableColSpan = 21;
        }
      }
      if (user_group.name === 'Bendahara') {
        tableColSpan = 17;
      }
    }


    return (
      <div className="manage-budget__table-wrapper">
        <table className="manage-budget__form-table table">
          {this.renderHeader()}
          <tbody>
            <tr className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pendapatan</h4>
                </div>
              </td>
            </tr>
            <tr  className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester II Tahun Ajaran Berjalan</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterTwo.pendapatan)}
            <tr className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester I Tahun Ajaran Berikutnya</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterOne.pendapatan)}
            <tr  className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pengeluaran</h4>
                </div>
              </td>
            </tr>
            <tr  className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester II Tahun Ajaran Berjalan</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterTwo.pengeluaran)}
            <tr  className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester I Tahun Ajaran Berikutnya</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterOne.pengeluaran)}
            <tr  className="sub_header">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Inventaris</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(inventaris)}
            {this.renderSummary('Total Pendapatan', 'pendapatan', rapbuSummary)}
            {this.renderSummary('Total Pengeluaran', 'pengeluaran', rapbuSummary)}
            {this.renderSummary('Jumlah Inventaris', 'inventaris', rapbuSummary)}
            {this.renderSummary('Estimasi Laba/Rugi', 'estimasi', rapbuSummary)}
            {this.renderSummary('Saldo', 'saldo', rapbuSummary)}
          </tbody>
        </table>
      </div>
    );
  }
}
BudgetRapbu.propTypes = {
  onChangeRecommendation: PropTypes.func,
  onSelectBudgetDetail: PropTypes.func,
  onCheckAll: PropTypes.func,
  handleFileDownload: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  workflow: PropTypes.object,
};
BudgetRapbu.defaultProps = {
  onChangeRecommendation: noop,
  onSelectBudgetDetail: noop,
  onCheckAll: noop,
  handleFileDownload: noop,
  user: null,
  recommendations: {},
  workflow: null,
};
