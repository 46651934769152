import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Input,
  Select,
} from "../../../components/base/index";
import { PENGISIAN_SIKAP_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";
import PengisianSikapForm from "./components/PengisianSikapForm.component";
import { TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS } from "../../../constants/kurikulum/ulangan.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class AspekPerkembangan extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickAddKD = this._onClickAddKD.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._getTeacherSubjectsOptions =
      this._getTeacherSubjectsOptions.bind(this);
    this._getTypeExam = this._getTypeExam.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: 12,
          students_id: "",
          indikator_sikap: [
            {
              pengisian_sikap: "",
              detail_nilai: "",
              poin: "",
            },
          ],
        },
        error: {
          indikator_sikap: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "levels", "classrooms"];

    // console.log(location);
    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    // if (isEdit && id) {
    //   this.setState({id:id})
    //   // this._setForm(data.id);
    // } else
    // this.handleGetData();
    // this.isMount = true;

    this._getSubjectOptions({ filters: { units_id: [school_unit.id] } });
    this._getTypeExam();
    this._getTeacherSubjectsOptions();
    this._getPeriodsOptions();
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    const { form } = this.state;
    const { subject_id } = form.value;
    const res = await getSubjectOptions(filters);
    const selectedSubject = res.find((item) => item.value == subject_id);
    filters.units_id = school_unit.id;
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          kkm: selectedSubject ? selectedSubject.attributes.kkm : "",
        },
      },
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getTypeExam() {
    const { getTypeExam } = this.props;
    const res = await getTypeExam();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        type_exam: res,
      },
    }));
  }

  async _getTeacherSubjectsOptions(filters = {}) {
    const { getTeacherSubjectsOptions } = this.props;
    const res = await getTeacherSubjectsOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher_subjects: res,
      },
    }));
  }

  async handleGetData(payload = {}) {
    const { handleListAchievement, user } = this.props;
    const { units_id, workingUnit } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleListAchievement({ ...payload });

    this.setState({
      list: res,
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/pengisian-aspek-perkembangan", {
      isEdit: true,
    });
  }

  _onClickAddKD() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/pengisian-kompetensi-dasar");
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 12;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetAttitudeIndicator } = this.props;
    try {
      const payload = await handleGetAttitudeIndicator({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            period: payload.period,
            date: payload.date,
            classrooms_id: payload.classrooms_id,
            hour: payload.hour,
            minute: payload.minute,
            nis: payload.nis,
            name: payload.name,
            type_achievement: payload.type_achievement,
            subject_id: payload.subject_id,
            order_of_achievement: payload.order_of_achievement,
            score: payload.score,
            description: payload.description,
            penalty: payload.penalty,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveAttitudeIndicator } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveAttitudeIndicator(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif");
    history.go(0);
  }

  async onEdit() {
    const { history, handleEditAttitudeIndicator } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditAttitudeIndicator(value);
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif"
    );
    // }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          {/* <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                /> */}
        </div>
      </td>
    );
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { indikator_sikap } = prevState.form.value;
      const list = prevState.form.value.indikator_sikap;
      list.splice(idx, 1);
      return {
        ...prevState,
        indikator_sikap: list,
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: 12 },
          });
        }
        this._onSearchContent();
      }
    );
  }

  render() {
    const { form, content = {}, filters, param } = this.state;
    const { handleGetKelas, handleDeleteKelas, coa } = this.props;
    const { name_violations = "" } = filters;
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Buat Pelanggaran</h1>
          <hr></hr>
        </div>
        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Buat Aspek Perkembangan"
              onClick={this._onClickAdd}
            />
          </div>
          <div className="student-list__button">
            <Button
              title="Buat Kompetensi Dasar"
              onClick={this._onClickAddKD}
            />
          </div>
        </div>
        <div className="manage-registration">
          <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row"></div>
          </div>
        </div>
        <div className="budget__content">
          {/* <ContentTable
                    onSearchContent={handleGetKelas}
                    tableFields={TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS}
                    onClickEdit={this._onClickEdit}
                    onClickDelete={handleDeleteKelas}
                  /> */}
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(
                    TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS.label,
                    (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {map(content.list, (data, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(
                      TABLE_PERKEMBANGAN_ASPEK_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.type === "link") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <a
                                href={"https://" + data.hyperlink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.hyperlink}
                              </a>
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(data, field)}
                          </td>
                        );
                      }
                    )}
                    {this._renderButtons(data)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="user-management__table-footer">
            <p className="user-management__page-info">
              {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
            </p>
            <Pagination
              totalPage={content.totalPage}
              currentPage={content.currentPage}
              onClick={this.onChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
AspekPerkembangan.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
AspekPerkembangan.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
};
