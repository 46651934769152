import { connect } from 'react-redux';
import LaporanDispliner from '../../../views/Student/report/LaporanDispliner.student.view';
import { manageRegistration } from '../../../states/thunks/student.thunk';
import { getClasses,  getLevels, getStudentOptions } from '../../../states/thunks/options.thunk';
import {getParamOptions, getSubjectOptions, getPeriodsOptions} from '../../../states/thunks/options.thunk';
import { getStudent } from '../../../states/thunks/student.thunk';
import { listReportDispliner, listReportDisplinerViolations, listReportDisplinerAchievement, listReportDisplinerRecordDaily} from '../../../states/thunks/academics.thunk'
function mapStateToProps(state) {
  return {
    result: state.result,
    classes: state.options.classes,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
    handleListReportDispliner: (payload, goBack) => dispatch(listReportDispliner(payload, goBack)),
    handleGetStudent: () => dispatch(getStudent()),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleListAchievement: (payload, goBack) => dispatch(listReportDisplinerAchievement(payload, goBack)),
    handleListViolations: (payload, goBack) => dispatch(listReportDisplinerViolations(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    handleListReportDisplinerRecordDaily: (payload, goBack) => dispatch(listReportDisplinerRecordDaily(payload, goBack)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LaporanDispliner);
