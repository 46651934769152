import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import { normalizeAmount, formatData } from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  SearchSelect,
} from "../../../components/base/index";
import language from "../../../languages";
import { PEMBAGIAN_KELAS_EKSKUL_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const padStr = (num) => {
  if (num !== "" && num !== null && typeof num !== "undefined") {
    return num.toString().padStart(2, "0");
  }

  return null;
};
const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PembagianKelasEkskul extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onCetak = this.onCetak.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          organizations_id: organizations_id,
          levels_id,
        },
        error: {},
      },
      filtered: false,
      param: "",
    };
  }

  componentDidMount() {
    const { location, handleGetclassrooms } = this.props;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const paramTypes = ["classrooms", "levels", "classrooms"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getStudentOptions();
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    if (!isEmpty(filters.students_id) && filters.students_id.value === "") {
      delete filters.students_id;
    }
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListClassEkskul, user } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;

          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = await handleListClassEkskul({
            ...params,
            organizations_id: organizations_id,
            // units_id,
            filters,
          });
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }
  // on formchange untuk form

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/ekstrakulikuler/buat-kelas-ekskul", {
      data: val,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />

          {/* </div> */}
          {/* } */}
        </div>
      </td>
    );
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/ekstrakulikuler/buat-kelas-ekskul", {
      isEdit: true,
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  onCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/ekstrakulikuler/buat-kelas-ekskul");
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  render() {
    const { form, page, content, param, filters } = this.state;
    const { value } = form;
    const { plafon } = form.value;
    const {
      classrooms_id = "",
      academic = "",
      periods = "",
      order = "",
    } = filters;
    const {
      handleGetKelas,
      handleDeleteKelas,
      onFormChange,
      classrooms = {},
      location,
    } = this.props;
    const { state = {} } = location;
    const dataset = {
      toggle: "modal",
    };

    return (
      <div className="manage-registration">
        <div className="beginning-balance__button-wrapper">
          <Button
            title="Tambah Kelas Ekskul"
            onClick={this.onClickAdd}
            data={dataset}
          />
        </div>
        <div className="absensi-rekap__custom-form">
          <br></br>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field"></div>
            <div className="absensi-rekap__custom-form-column__field">
              <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Nama Siswa"
                value={filters.students_id}
                // labelName="label"
                // valueName="value"
                rightIcon="icon-search"
                label="Nama Siswa"
              />
            </div>
          </div>
        </div>

        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(
                    PEMBAGIAN_KELAS_EKSKUL_TABLE_FIELDS.label,
                    (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {map(content.list, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(
                      PEMBAGIAN_KELAS_EKSKUL_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.type === "link") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <Link
                                to={
                                  "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                                }
                              >
                                {list.name}
                              </Link>
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(list, field)}
                          </td>
                        );
                      }
                    )}
                    {this._renderButtons(list)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {!isEmpty(content.list) && (
          <div className="user-management__table-footer">
            <p className="user-management__page-info">
              {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
            </p>
            <Pagination
              totalPage={content.totalPage}
              currentPage={content.currentPage}
              onClick={this._onChangePage}
            />
          </div>
        )}
      </div>
    );
  }
}
PembagianKelasEkskul.propTypes = {
  handleGetclassrooms: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PembagianKelasEkskul.defaultProps = {
  handleGetclassrooms: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
