import { connect } from 'react-redux';
import Jadwal from '../../../views/Kurikulum/Jadwal/Jadwal.kurikulum.view';

//React component: state, props
//state: exists in own component 
//props: passed down from parent
//
//<Page>
//  <View type="academics" page={10}>
//      <Form type={this.props.academics} onSubmit={alert(123);}>

// class Form extends Component {
//   constructor(props)  {
//      const { type, onSubmit } = props;
//      this.state = {
//          total: 0,
//          satuan: 0,
//          quantity: 0, 
//      }
//  }
//}

//  <App>
//  state in App is controlled and stored by Redux
//

function mapStateToProps(state) {
    return {};
}
  
  function mapDispatchToProps(dispatch) {
    return {
      
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Jadwal);