import React, { useEffect, useState } from 'react';

export const AlertContext = React.createContext();

export function AlertContextProvider() {
  const [alert, setAlert] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => setVisible(true), [alert]);

  const showAlert = (message, type) => {
    switch (type) {
      case 'success':
        setAlert({
          icon: 'icon-ok',
          className: 'alert-warning',
          message: 'message',
        });
        break;
      case 'error':
        setAlert({
          icon: 'icon-attention-circled',
          className: 'alert-warning',
          message: 'message',
        });
        break;
      default:
        setAlert({
          icon: 'icon-attention',
          className: 'alert-warning',
          message: 'message',
        });
        break;
    }
  };

  return (
    <AlertContext.Provider value={showAlert}>
      { visible && (
        <div className={`alert ${alert.className}`} role="alert">
          <span className={`icon ${alert.icon}`}>{alert.message}</span>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setVisible(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </AlertContext.Provider>
  );
}

export default function Alert(props) {
  const { message, type, show = false } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  if (visible) {
    switch (type) {
      case 'success':
        return (
          <div className="alert alert-success" role="alert">
            <span className="icon icon-ok">{message}</span>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
      case 'error':
        return (
          <div className="alert alert-danger" role="alert">
            <span className="icon icon-attention-circled">{message}</span>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
      default:
        return (
          <div className="alert alert-warning" role="alert">
            <span className="icon icon-attention">{message}</span>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
    }
  }
  return false;
}
