import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import { LAPORAN_ABSENSI_KELAS} from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import LaporanAbsensiKelasForm from './components/LaporanAbsensiKelasForm.component';

export default class LaporanAbsensiKelas extends PureComponent {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this.state = {
        form: {
          value: {},
          error: {},
        },
      };
    }
    componentDidMount() {
      const { handleGetCodeOfAccount, location, handleGetClasses} = this.props;
     handleGetClasses();
  
   
    }
  
    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        files,
      } = event.target;
      const { inputType = 'text' } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        if (inputType === 'number') {
          formattedValue = commaFormatted(value);
        }
        if (inputType === 'file') {
          [formattedValue] = files;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              [name]: formattedValue,
            },
            error: {
              ...prevState.form.error,
              [name]: '',
            },
          },
        };
      });
    }
  
    _onClickNext() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/cetak-laporan-absensi-kelas');
      }
  
    render() {
      const { form } = this.state;
      const { classes } = this.props;
      return (
        <div className="student-list">
          <form onSubmit={this._onSubmit}>
            
            <LaporanAbsensiKelasForm
            form={form}
            classes={classes}
            onChange={this._onFormChange}
            />
            <div className="upload-student__content">
              <Button
                 onClick={this._onClickNext}
                title="Submit"
              />
            </div>
          </form>
        </div>
      );
    }
  }
LaporanAbsensiKelas.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
  handleGetClasses: PropTypes.func,
};
LaporanAbsensiKelas.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
  handleGetClasses: noop,
};
