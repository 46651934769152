import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';


export default function JadwalKelasDetailForm(props) {
  const { onFormChange, form,param } = props;
  return (
    <div className="buat-pelanggaran__custom-form">
    <div className="jadwal-detail__header-title3">
        <div className="jadwal-detail__header-title3--left">
            <table className="jadwal-detail__table-test">
                <tr> 
                    <td></td>    
                    <td>Judul </td>
                    <td>  : Gange</td> 
                </tr> 
                <tr>
                    <td></td>
                    <td>Tanggal Mulai</td>
                    <td>  : SMP Katolik Ricci</td> 
                    
                </tr>
                <tr>
                    <td></td>
                    <td>Tanggal Selesai</td>
                    <td>  : SMP Katolik Ricci</td> 
                    
                </tr>
                <tr>
                    <td></td>
                    <td>Unit</td>
                    <td>  : SMP Katolik Ricci</td> 
                    
                </tr>
                <tr>
                    <td></td>
                    <td>Kelas</td>
                    <td>  : SMP Katolik Ricci</td> 
                    
                </tr>
                
            </table> 
        </div>
        
        <div className="jadwal-detail__header-title3--right">
            <table className="jadwal-detail__table-test">
            <tr>
                    <td></td>
                    <td>Inisiator</td>
                    <td>  : SMP Katolik Ricci</td> 
                        
                </tr>
                <tr> 
                    <td></td>    
                    <td>Tanggal Kegiatan </td>
                    <td>  : SMP Katolik Ricci</td> 
                </tr> 
                <tr>
                    <td></td>
                    <td>Kegiatan</td>
                    <td>  : SMP Katolik Ricci</td> 
                    
                </tr>
                <tr>
                    <td></td>
                    <td>Pengajar</td>
                    <td>  : SMP Katolik Ricci</td> 
                        
                </tr>
                <tr>
                    <td></td>
                    <td>Jam Mulai</td>
                    <td>  : SMP Katolik Ricci</td> 
                        
                </tr>
                <tr>
                    <td></td>
                    <td>Jam Berakhir</td>
                    <td>  : SMP Katolik Ricci</td> 
                        
                </tr>
            </table>
        </div>
        </div>

    </div>
  );
}
JadwalKelasDetailForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
JadwalKelasDetailForm.defaultProps = {
  onFormChange: noop,
};
