import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';
import TableDataPegawai from './components/TableDataPegawai.component';

export default class PengajuanCuti extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/upload');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas} = this.props;
        return (
        <div className="student-list">
            <div className="budget__title">
                <h1>Pengajuan Cuti Pegawai</h1>
                <hr></hr>
            </div>
            <div className="komposisi-siswa__custom-form-row">
                <div className="komposisi-siswa__custom-form-row__field">
                    <Input
                    type="text"
                    label="Nomor Induk Pegawai"
                    name="nip"
                    onchange={this._onFormChange}
                    />
                </div>
                <div className="komposisi-siswa__custom-form-column__field">
                    <Input
                    type="text"
                    label="Nama Pegawai"
                    name="namapegawai"
                    onChange={this._onFormChange}
                    />
                </div>
            </div>
            <div className="manage-registration__footer">
                <Button
                onClick={this._onSubmit}
                title="Cari"
                />
            </div>



            <div className="laporan-pegawai__body">
                <div className="komposisi-siswa__custom-form-row">
                    <div className="komposisi-siswa__custom-form-row__field">
                        <Input
                        type="text"
                        label="Nama Pegawai"
                        name="namapegawai"
                        readOnly
                        style={{backgroundColor:'grey'}}  
                        placeholder={'Nama Lengkappegawai/NIK'}
                        />
                    </div>
                    <div className="laporan-keu2__custom-form-row2__field">
                        <div className="laporan-keu2__custom-form-row5"> 
                            <Input
                                type="date"
                                name="tahunajaran"
                                label="Tanggal Cuti"
                                onChange={this._onFormChange}
                                // value={form.value.tempatLahirSaudara1 || ''}
                                // error={form.error.tempatLahirSaudara1 || ''}
                                // placeholder={'Pilihan'}
                            />
                        </div>
                        <p className="laporan-pegawai__custom-p">S/D</p>
                        <div className="laporan-pegawai__custom-form-row6">
                            <Input
                                type="date"
                                name="tahunajaran"
                                onChange={this._onFormChange}
                                // value={form.value.tempatLahirSaudara1 || ''}
                                // error={form.error.tempatLahirSaudara1 || ''}
                                // placeholder={'Pilihan'}
                            />
                        </div>
                    </div> 
                </div>
                <div className="komposisi-siswa__custom-form-row__field">
                    <Input
                    type="text"
                    name="alasan"
                    label="Alasan Cuti Pegawai"
                    onChange={this._onFormChange}
                    />
                </div>
                <div className="manage-registration__footer">
                    <Button
                    onClick={this._onSubmit}
                    title="Simpan Data"
                    />
                </div>
            </div>

          


        </div>
        );
      }
}
PengajuanCuti.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PengajuanCuti.defaultProps = {
    
  handleGetStudent: noop,
};
