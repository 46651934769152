import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';

export default function PengisianForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
            name="namaSaudara1"
            label="Unit Perpustakaan"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Kelas 7A'}
           
            // error={form.error.namaSaudara1 || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
          <Select
           
            name="tahunajaran"
            label="Jenis"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'2019 - 2020'}
          />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
          <Select
            name="namaSaudara1"
            label="Unit Kelas"
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Praktek/Keterampilan/Kl 4'}
           
            // error={form.error.namaSaudara1 || ''}
          />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
          <Input
           
            name="tahunajaran"
            label="Nomor Barcode / Nomor Induk"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>
       
        
        </div>
        
 
   
  );
}
PengisianForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianForm.defaultProps = {
  onFormChange: noop,
};
