import { connect } from 'react-redux';
import Home from '../views/Home/Home.view';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(Home);
