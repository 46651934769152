import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map,noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../../components/base/index';
import { Link } from 'react-router-dom';

export default class IndividualTable extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._checkboxTD = this._checkboxTD.bind(this);
        this._checkboxTR = this._checkboxTR.bind(this);
    }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

    _checkboxTD(){
        let td=[]
        let idx=0;

        for(let i =0; i<3; i++){
            idx++;
            td.push( 
            <td><div className="individual__custom-form-row-check">
                            <div className="button-ekskul__button-wrapper">
                                <Input
                                type="checkbox"
                                name="senin"
                                onChange={this._onFormChange}
                                //  checked='true'
                                // checked='false'
                            /> 
                            </div>
                            &nbsp;<p>{idx} </p> &nbsp;
                        </div></td>)
        }
        return td
    }

    _checkboxTR(){
        let tr =[];
        for(let i =0; i<4 ; i++){
            tr.push(<tr>
                    <td></td>
                        {this._checkboxTD()}
            </tr>)
        }
        return tr
    }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div className="individual__custom-form">
               
                <div className="individual__body">
                  <table className="individual__table">
                    <thead>
                      <tr>
                        <th>Jenis Invoice</th>
                        <th>Nominal</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>Uang SPP</td>
                        <td> <Textarea
                              name="namaSaudara1"
                              onChange={onFormChange}
                              style={{backgroundColor:'#2293C1'}}
                            
                            /></td>
                       
                        <td><table className="individual__table-mini">

                                    {this._checkboxTR()}
                                   

                            </table>
                        </td>
                      
                    </tr>
                    <tr>
                        <td>Uang Seragam</td>
                        <td> <Textarea
                              name="namaSaudara1"
                              onChange={onFormChange}
                              style={{backgroundColor:'#2293C1'}}
                            
                            /></td>
                       
                        <td><table className="individual__table-mini">

                                    {this._checkboxTR()}
                                   

                            </table>
                        </td>
                      
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td colSpan={2}><Input
                                        name="namaSaudara1"
                                        onChange={onFormChange}
                                        style={{backgroundColor:'#2293C1'}}
                                        />
                        </td>
                    </tr>

                   
                   
                   
                   
                    
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
     }
}
IndividualTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
IndividualTable.defaultProps = {
  onFormChange: noop,
};
