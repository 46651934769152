import React, { PureComponent } from 'react';
import ListForm from '../../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../../utils/transformer.util';
import { PERSONNEL_PERMANENT_FORM_FIELDS } from '../../../constants/personnel.constant';
import Button from '../../../components/base/Button/Button.component';

export default class ManagePermanent extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: formattedValue,
          },
        },
      };
    });
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  render() {
    const { form } = this.state;
    return (
      <div className="manage-permanent">
        <h1 className="manage-permanent__title">SURAT PENGANGKATAN KARYAWAN</h1>
        <form>
          <ListForm
            form={form}
            formFields={PERSONNEL_PERMANENT_FORM_FIELDS}
            onFormChange={this._onFormChange}
          />
          <div className="manage-permanent">
            <Button
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
