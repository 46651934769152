import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import { Link } from 'react-router-dom';
import { Pagination } from '../../../../components/base';



const initialContent = {
    loading: true,
    list: [],
    currentPage: 1,
    total: 1,
    totalPage: 1,
    error: false,
  };

export default class KelolaPembagianKlsKhususTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: initialContent,
            listAmount: 10,
          };
       
        this.onChangePage = this.onChangePage.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }

      onChangePage(page) {
        const { listAmount, keywords } = this.state;
        const offset = listAmount * (page - 1);
        this.onSearchContent({
          limit: listAmount,
          keywords,
          offset,
          page,
        });
      }
 
  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {

     const {
      content
    } = this.state;
    const {
      paging,
      currentPage,
      totalPage,form
    } = this.props;
    return (
        <div className="daftar-ekskul__content2">
                  
                    <div className="daftar-ekskul__body">
                      <table className="daftar-ekskul__table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>NIS</th>
                            <th>Nama Siswa</th>
                            <th>L/P</th>
                            <th>Pindah Kelas</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {/* {
                          map(journal.details, (list, idx) => (
                            <tr key={`journal_detail_${idx}`}>
                              <td>{idx + 1}</td>
                              <td>{list.code_of_account}</td>
                              <td>{list.parameter_code.title}</td>
                              <td>{list.description}</td>
                              <td className="nominal">{commaFormatted(list.amount)}</td>
                            </tr>
                          ))
                        } */}
                        <tr>
                            <td>1222222</td>
                            <td>aa</td>
                            <td>122</td>
                            <td>L</td>
                            <td>  <Select
                                    type="number"
                                    name="score"
                                    onChange={this._onFormChange}
                                    value={form.value.score || ''}
                                    error={form.error.score || ''}
                                /></td>
                        </tr>
                       
                        </tbody>
                        {/* <tfoot>
                          <tr>
                            <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                            <td colSpan="8" className="nominal"></td>
                          </tr>
                        </tfoot> */}
                      </table>
                    </div>
                    {
                  paging && (
                    <div className="report-card__footer">
                      <p className="user-management__page-info">
                        {`Halaman ${currentPage} dari ${totalPage}`}
                      </p>
                      <Pagination
                          totalPage={content.totalPage}
                          currentPage={content.currentPage}
                          onClick={this.onChangePage}
                        />
                    </div>
                  )
                 }
                  </div>
      );
  }


 
}
KelolaPembagianKlsKhususTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  paging: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
};
KelolaPembagianKlsKhususTable.defaultProps = {
  onFormChange: noop,
  paging: true,
  currentPage: 1,
  totalPage: 1,
};
