import { connect } from 'react-redux';
import UploadStaff from '../../views/Personnel/UploadStaff.personnel.view';
import { uploadStaff } from '../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleUploadStaff: (payload, goBack) => dispatch(uploadStaff(payload, goBack)),
  };
}

export default connect(null, mapDispatchToProps)(UploadStaff);
