import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Input } from '../../../../components/base';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { FINANCE_CLOSING_JOURNAL_TABLE_FIELDS } from '../../../../constants/finance/bookeeping.constant';

export default class ClosingJournal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-penutup', { isEdit: false });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-penutup', { data: { id: val.id }, isEdit: true });
  }

  _onFormChange(event) {
    const {
      name,
      value,
    } = event.target;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: '',
        },
      },
    }));
  }

  render() {
    const { form } = this.state;
    const { handleGetClosingJournal } = this.props;
    return (
      <div className="closing-journal">
        <div className="closing-journal__add-button">
          <Button
            title="Tambah Jurnal Penutup"
            onClick={this._onClickAdd}
          />
        </div>
        <div className="closing-journal__date">
          <Input
            type="date"
            name="date"
            value={form.value.date}
            onChange={this._onFormChange}
          />
        </div>
        <div className="closing-journal__content">
          <ContentTable
            onSearchContent={handleGetClosingJournal}
            tableFields={FINANCE_CLOSING_JOURNAL_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            showDelete={false}
            customThead={(
              <thead>
                <tr>
                  <th rowSpan={2}>No</th>
                  <th rowSpan={2}>Tanggal</th>
                  <th colSpan={2}>Akun</th>
                  <th rowSpan={2}>Keterangan</th>
                  <th rowSpan={2}>Debet</th>
                  <th rowSpan={2}>Kredit</th>
                  <th rowSpan={2}>&nbsp;</th>
                </tr>
                <tr>
                  <th>Kode</th>
                  <th>Nama</th>
                </tr>
              </thead>
            )}
          />
        </div>
      </div>
    );
  }
}
ClosingJournal.propTypes = {
  handleGetClosingJournal: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ClosingJournal.defaultProps = {
  handleGetClosingJournal: noop,
};
