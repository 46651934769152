import { connect } from 'react-redux';
import AnalisaPrestasi from '../../../../views/Student/displiner/cetak/AnalisaPrestasi.student.view';
import { getStudent } from '../../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(AnalisaPrestasi);


