import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { normalizeAmount } from "../../../../utils/transformer.util";
import { Button, Select, Input } from "../../../../components/base/index";
import { validateRequiredFields } from '../../../../utils/validation.util';

export default class CreateInvoiceTidakRutin extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this.getPrmPayments = this.getPrmPayments.bind(this);

    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      form: {
        value: {
          organizations_id: 12,
          currents_year:"",
          ttl: "",
          payments_id: "",
          nominal: "",
          discount: "",
          ttl_diskon: "",
          total: "",
          dp: "",
          period: "",
          ttl: "",
        },
        error: {
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (isEdit && id) {
      this._setForm(id, data);
    }
    this.getYearsOfEducationsOptions();
    this.getPrmPayments();
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { form } = this.state;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (name === "discount"){
        if(value > 100){
          formattedValue = ''
        }
      }
      if (name === "dp"){
        if(value > form.value.nominal-form.value.ttl_diskon){
          formattedValue = ''
        }
      }

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveInvoiceNonRutin } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['currents_year', 'payments_id', 'nominal', 'period', 'discount']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleSaveInvoiceNonRutin(value);
      history.push("/dashboard/keuangan/spp/create-invoice");
    }
  }

  async _setForm(id, data = {}) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { form } = this.state;
    
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }

    try {
      this.setState({
        form: {
          value: {
            users_id: data.users_id,
            no_va: data.no_va,
            name: data.name,
            address: data.address,
            academics_year: data.entrance_year,
            id: form.value.id,
            students_id: form.value.students_id,
            organizations_id: 12,
            currents_year: form.value.currents_year ? form.value.currents_year : year,
            payments_id: form.value.payments_id,
            nominal:form.value.nominal,
            discount:form.value.discount ? form.value.discount : "0",
            ttl_diskon:form.value.ttl_diskon ? form.value.ttl_diskon : "0",
            total:form.value.total ? form.value.total : "0",
            dp:form.value.dp ? form.value.dp : "0",
            period:form.value.period ? form.value.period : "",
            ttl:form.value.ttl,
          },
          error: {
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async getYearsOfEducationsOptions() {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async getPrmPayments() {
    const { getPrmPayments } = this.props;
    const res = await getPrmPayments();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }


  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { id } = state.data || "";
    const { form, param } = this.state;

    const prm_period = [
      { label: '1', value: '1'},
      { label: '2', value: '2'},
      { label: '3', value: '3'},
      { label: '12', value: '12'},
      { label: '5', value: '5'},
      { label: '6', value: '6'},
      { label: '7', value: '7'},
      { label: '8', value: '8'},
      { label: '9', value: '9'},
      { label: '10', value: '10'},
      { label: '11', value: '11'},
      { label: '12', value: '12'},
    ];
    form.value.ttl_diskon = Math.ceil(Number(form.value.nominal)*Number(form.value.discount)/100);
    form.value.ttl = Number(form.value.nominal)-Number(form.value.ttl_diskon)-Number(form.value.dp);
    if(form.value.period != "") {
      form.value.total = Math.ceil(form.value.ttl/Number(form.value.period));
    }

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Tidak Rutin </h1>
          <hr></hr>
        </div>
        <form>
          <div className="result__content">
            <div className="nilai-kognitif__custom-form">
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="nis"
                    label="NIS"
                    value={data ? data.nis : ""}
                    onChange={this._onFormChange}
                    error={form.error.nis || ""}
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Input
                    name="name"
                    label="Nama"
                    value={data ? data.name : ""}
                    onChange={this._onFormChange}
                    error={form.error.name || ""}
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="entrance_year"
                    label="Tahun Masuk"
                    value={data ? data.entrance_year : ""}
                    onChange={this._onFormChange}
                    error={form.error.entrance_year || ""}
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Select
                    name="currents_year"
                    label="Tahun Ajaran *"
                    onChange={this._onFormChange}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={form.value.currents_year || ""}
                    error={form.error.currents_year}
                    isRequired
                  />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">Data</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field">
                      <Select
                        label="Nama Pembayaran *"
                        name="payments_id"
                        onChange={this._onFormChange}
                        placeholder="Pilihan"
                        data={param.prm_payments}
                        value={form.value.payments_id}
                        error={form.error.payments_id}
                        isRequired
                      />
                    </div>
                    <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                      <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                      <Input
                        type="number"
                        name="nominal"
                        label="Nominal *"
                        onChange={this._onFormChange}
                        value={form.value.nominal}
                        error={form.error.nominal}
                        isRequired
                      />
                    </div>
                  </div>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field">
                      <Input
                        type="number"
                        name="discount"
                        label="Diskon (%)"
                        onChange={this._onFormChange}
                        value={form.value.discount}
                        error={form.error.discount}
                        isRequired
                      />
                    </div>
                    <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                      <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                      <Input
                        name="ttl_diskon"
                        label="Total (Disc)"
                        onChange={this._onFormChange}
                        value={form.value.ttl_diskon}
                        isNumber
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field" style={{flexDirection: "unset"}}>
                    </div>
                    <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                      <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                      <Input
                        type="number"
                        name="dp"
                        label="Uang Muka (DP)"
                        onChange={this._onFormChange}
                        value={form.value.dp}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field">
                      <Select
                        name="period"
                        label="Period (Bulan) *"
                        onChange={this._onFormChange}
                        placeholder="Pilihan"
                        data={prm_period}
                        value={form.value.period}
                        error={form.error.period}
                        isRequired
                      />
                    </div>
                    <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                      <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                      <Input
                        name="total"
                        label="Total Bayar per Period"
                        onChange={this._onFormChange}
                        value={form.value.total}
                        isNumber
                        readOnly
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div>                    
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                      </div>
                      
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          name="ttl"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.ttl}
                          isNumber
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="student-list__header">
            <div className="student-list__button">
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CreateInvoiceTidakRutin.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CreateInvoiceTidakRutin.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
