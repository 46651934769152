import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import {
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Select,
  Input,
} from "../../../components/base/index";
import language from "../../../languages";
import { BUAT_NILAI_EKSKUL_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";
import { errorAlert } from "../../../utils/alert.util";

const padStr = (num) => {
  if (num !== "" && num !== null && typeof num !== "undefined") {
    return num.toString().padStart(2, "0");
  }

  return null;
};
const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class BuatNilaiEkskul extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onCetak = this.onCetak.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getTeacherEkskulOptions = this._getTeacherEkskulOptions.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    const { user } = this.props;
    const { organizations_id, school_unit } = user;

    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          organizations_id: organizations_id,
          pengisian_nilaiekskul: [
            {
              students_id: "",
              classrooms_id: "",
              levels_id: "",
              ekskul_id: "",
              description: "",
              grades: "",
              attitude_ekskul: "",
              academic_year: "",
              period: "",
              teacher_id: "",
            },
          ],
        },
        error: {},
      },
      filtered: false,
      param: "",
    };
  }

  componentDidMount() {
    const { location, handleGetclassrooms } = this.props;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const paramTypes = ["classrooms", "levels", "classrooms"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    } else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getTeacherEkskulOptions();
    this._getPeriodsOptions();
  }

  async _getTeacherEkskulOptions(filters = {}) {
    const { getTeacherEkskulOptions } = this.props;
    const res = await getTeacherEkskulOptions((filters = {}));
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher_ekskul: res,
      },
    }));
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!id && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  // on formchange untuk form

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (name === "levels_id") {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
              }
            }
          }));
        }

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
          this._onSearchContent();
        }
        if (name === "classrooms_id") {
          this._onSearchContent();
        }

        // if (name === 'ekskul_id') {
        //   this._getTeacherEkskulOptions({ filters: { ekskul_id: value }} );
        // }
      }
    );
  }
  async onEdit() {
    const { history, handleEditEkskulGrade } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataForm = form.value;
    const dataTabel = value.pengisian_nilaiekskul;
    let a;
    if(dataForm.academic_year && dataForm.levels_id && dataForm.period && dataForm.teacher_id){
      canSaveA = true;
    } else {
      canSaveA = false;
    }

    for (a = 0; a < dataTabel.length; a++) {
      if ( dataTabel[a].grades && dataTabel[a].attitude_ekskul && dataTabel[a].description ) {
        canSaveB = true;
      // } else {
      //   canSaveB = false;
      //   break;
      }
    }
    if (canSaveA == false){
      errorAlert({
        title: "Harap lengkapi data form",
        errorMessage: "Bagian yang harus diisi ditandai dengan tanda bintang (*).",
      });
    } else if (canSaveB == false) {
      errorAlert({
        title: "Tabel belum lengkap",
        errorMessage: "Harap isi tabel secara lengkap.",
      });
    } else {
      const res = await handleEditEkskulGrade(value);
      history.push("/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul");
    }
  }

  async onSubmit() {
    const { history, handleSaveEkskulGrade } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataForm = form.value;
    const dataTabel = value.pengisian_nilaiekskul;
    let a;
    if(dataForm.academic_year && dataForm.levels_id && dataForm.period && dataForm.teacher_id){
      canSaveA = true;
    } else {
      canSaveA = false;
    }

    for (a = 0; a < dataTabel.length; a++) {
      if ( dataTabel[a].grades && dataTabel[a].attitude_ekskul && dataTabel[a].description ) {
        canSaveB = true;
      // } else {
      //   canSaveB = false;
      //   break;
      }
    }
    if (canSaveA == false){
      errorAlert({
        title: "Harap lengkapi data form",
        errorMessage: "Bagian yang harus diisi ditandai dengan tanda bintang (*).",
      });
    } else if (canSaveB == false) {
      errorAlert({
        title: "Tabel belum lengkap",
        errorMessage: "Harap isi tabel secara lengkap.",
      });
    } else {
      await handleSaveEkskulGrade(value);
      history.push("/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul");
    }
  }

  async _setForm(id) {
    const { handleGetEkskulGrade } = this.props;
    try {
      const payload = await handleGetEkskulGrade({ id });

      // console.log(payload)
      this.setState({
        // filters: {
        //   classrooms_id: payload.classrooms_id,
        //   period: payload.period,
        //   date: payload.date,
        // },
        form: {
          value: {
            id: payload.id,
            academic_year: payload.academic_year,
            period: payload.period,
            levels_id: payload.levels_id,
            teacher_id: payload.teacher_id,
            pengisian_nilaiekskul: map(
              payload.pengisian_nilaiekskul,
              (detail) => ({
                classrooms_name: detail.classrooms_name,
                classrooms_id: detail.classrooms_id,
                student_name: detail.student_name,
                nis: detail.nis,
                grades: detail.grades,
                attitude_ekskul: detail.attitude_ekskul,
                ekskul_id: detail.ekskul_id,
                name: detail.name,
                students_id: detail.students_id,
                description: detail.description,
              })
            ),
          },
          error: {
            pengisian_nilaiekskul: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _onSearchContent(params = {}) {
    const { form } = this.state;
    const { value } = form;
    if (value.classrooms_id === "") {
      delete value.classrooms_id;
    }
    try {
      const { handleListGradeStudentEkskul, user } = this.props;
      const { school_unit, workingUnit, id, user_group, organizations_id } =
        user;

      const result = await handleListGradeStudentEkskul({
        ...params,
        organizations_id: organizations_id,
        // units_id: school_unit.id,
        classrooms_id: value.classrooms_id,
      });
      // if (this.isMount) {
      if (result) {
        result.forEach((item) => {
          if (!item.description) {
            item.description = "";
          }
        });
      }
      console.log(result);
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pengisian_nilaiekskul: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pengisian_nilaiekskul: [],
          },
        },
      }));
    }
    // }
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    console.log(plafon);
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    console.log(name);
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  onCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-buku-induk");
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  render() {
    const { form, page, content, param, filters } = this.state;
    const { value } = form;
    const { plafon } = form.value;
    const { classrooms_id = "", academic = "", order = "" } = filters;
    const {
      handleGetKelas,
      handleDeleteKelas,
      onFormChange,
      classrooms = {},
      location,
    } = this.props;
    const { state = {} } = location;
    const { pengisian_nilaiekskul } = form.value;

    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const periods = [
      { label: "Genap", value: "genap" },
      { label: "Ganjil", value: "ganjil" },
    ];

    return (
      <div className="manage-registration">
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                label="Tahun Ajaran *"
                name="academic_year"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                data={param.periods}
                value={form.value.academic_year}
                error={form.error.academic_year || ""}
                isRequired
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                type="text"
                name="levels_id"
                label="Unit *"
                placeholder="Pilih Unit"
                data={param.levels}
                // disabled
                value={form.value.levels_id}
                onChange={this._onFormChange}
                error={form.error.levels_id || ""}
                isRequired
                disabled = {this.state.id ? true : false}
              />
            </div>
          </div>

          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="period"
                label="Periode *"
                placeholder="Pilih Periode"
                onChange={this._onFormChange}
                data={periods}
                value={form.value.period}
                error={form.error.period || ""}
                isRequired
              />
            </div>

            <div className="absensi-rekap__custom-form-column__field">
              <Select
                type="text"
                name="teacher_id"
                label="Nama Guru Ekskul *"
                placeholder="Pilihan"
                data={param.teacher_ekskul}
                onChange={this._onFormChange}
                value={form.value.teacher_id}
                error={form.error.teacher_id || ""}
                isRequired
              />
            </div>
          </div>
          {!(this.state.id) && (
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  placeholder="Pilih Kelas"
                  label="Kelas"
                  name="classrooms_id"
                  value={form.value.classrooms_id}
                  data={param.classrooms}
                  onChange={this._onFormChange}
                />
              </div>

              <div className="absensi-rekap__custom-form-column__field"></div>
            </div>
          )}
        </div>
        <br />

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(BUAT_NILAI_EKSKUL_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(pengisian_nilaiekskul, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    BUAT_NILAI_EKSKUL_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "grades") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              type="number"
                              name="grades"
                              data-input-array={true}
                              data-array-position={idx}
                              arrayPosition={idx}
                              data-field-name="pengisian_nilaiekskul"
                              onChange={this._onFormChange}
                              value={list.grades}
                            />{" "}
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }

                      if (field.attribute === "attitude_ekskul") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              name="attitude_ekskul"
                              data-input-array={true}
                              data-array-position={idx}
                              arrayPosition={idx}
                              data-field-name="pengisian_nilaiekskul"
                              onChange={this._onFormChange}
                              value={list.attitude_ekskul}
                            />{" "}
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }

                      if (field.attribute === "description") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              type="text"
                              name="description"
                              data-input-array={true}
                              data-array-position={idx}
                              data-field-name="pengisian_nilaiekskul"
                              onChange={this._onFormChange}
                              value={list.description}
                            />{" "}
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }

                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {!isEmpty(pengisian_nilaiekskul) && (
          <div className="user-management__table-footer">
            <p className="user-management__page-info">
              {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
            </p>
            <Pagination
              totalPage={content.totalPage}
              currentPage={content.currentPage}
              onClick={this._onChangePage}
            />
          </div>
        )}
        <br></br>
        <div className="student-list__button">
          {this.state.id ? (
            <Button type="button" title="Update" onClick={this.onEdit} />
          ) : (
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
          )}
        </div>
      </div>
    );
  }
}
BuatNilaiEkskul.propTypes = {
  handleGetclassrooms: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
BuatNilaiEkskul.defaultProps = {
  handleGetclassrooms: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
