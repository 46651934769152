import React from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import Path from './Path.component';

export default function Breadcrumb(props) {
  const { pathname, onClick } = props;
  const listPath = pathname.split('/');
  listPath.shift();
  return (
    <nav className="breadcrumb">
      <ol className="breadcrumb__content">
        {
          map(listPath, (path, index) => (
            <Path
              key={`breadcrumb_${index}`}
              index={index}
              path={path}
              onClick={onClick}
            />
          ))
        }
      </ol>
    </nav>
  );
}
Breadcrumb.propTypes = {
  onClick: PropTypes.func,
  pathname: PropTypes.string.isRequired,
};
Breadcrumb.defaultProps = {
  onClick: noop,
};
