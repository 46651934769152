import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function RaportKBKdetailForm(props) {

  const { onFormChange, form } = props;
  return (
    <div className="raport-kbk-detail__custom-form">
      
        <div className="raport-kbk-detail__custom-form-row2">
        <div className="raport-kbk-detail__custom-form-row__field">
          
        <div className="param-deskripsi__custom-form-row">
        <p>Data </p>&nbsp;&nbsp;<p style={{color:'red'}}>Kelas 10 </p> &nbsp;&nbsp; <p>Periode </p> &nbsp;&nbsp; <p style={{color:'blue'}}>Semester 1 </p>
        &nbsp;&nbsp;<p>Siap Dicetak Dengan Bentuk</p>
        </div>
        </div>
        <div className="raport-kbk-detail__custom-form-column-2__field">
            
          <Select
           
            name="tahunajaran"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>

        <div className="raport-kbk-detail__custom-form-row">
        <div className="raport-kbk-detail__custom-form-row__field">
          <Select
            name="model"
            label="Model Properties"
            onChange={onFormChange}
            data={ '7A', '2019-2020'}
            placeholder={''}
            value={form.value.model || ''}
            error={form.error.model || ''}
          />
        </div>
        
        <div className="raport-kbk-detail__custom-form-column3__field">
            
          <Select
           
            name="tahunajaran"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>

        <div className="raport-kbk-detail__custom-form-row">
        <div className="raport-kbk-detail__custom-form-row__field">
          <Select
            name="text_value"
            label="Text Value"
            onChange={onFormChange}
            data={ '7A', '2019-2020'}
            placeholder={''}
            value={form.value.text_value || ''}
            error={form.error.text_value || ''}
          />
        </div>
        
        <div className="raport-kbk-detail__custom-form-column__field">
            
          <Input
            type="file"
            name="upload"
            label="Upload Logo"
            onChange={onFormChange}
            value={form.value.upload || ''}
            error={form.error.upload || ''}
            placeholder={'Pilihan'}
          />
          
          <div className="raport-kbk-detail__custom-form-row2__field">
        <div className="raport-kbk-detail__custom-form-row3"> 
          <Select
            name="height"
            label="JPG Height"
            onChange={onFormChange}
            value={form.value.height || ''}
            error={form.error.height || ''}
            placeholder={'Pilihan'}
          />
          </div>
          <div className="raport-kbk-detail__custom-form-row4">
           <Select
            name="width"
            label="JPG Width"
            onChange={onFormChange}
            value={form.value.width || ''}
            error={form.error.width || ''}
            placeholder={'Pilihan'}
          />
       </div>
         
        </div>
         
        </div>
        </div>

       


        </div>
        
 
   
  );
}
RaportKBKdetailForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
RaportKBKdetailForm.defaultProps = {
  onFormChange: noop,
};
