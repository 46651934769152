import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map  } from 'lodash';
import { commaFormatted } from '../../../../../utils/transformer.util';

export default class BudgetApbu extends Component {
  renderRows(list) {
    const { rapbuSummary } = this.props;
    const { persentase } = rapbuSummary;
    let rows = [];

    map(list, (data, idx) => {
      const detail = data.budget_detail || {};
      rows.push([
        <tr key={`budget_rapbu_rows_${idx}`}>
          <td>{data.code_of_account}</td>
          <td className="border_double border_double__right">{data.parameter_code.title}</td>
          <td className="border_double border_double__right">{data.desc}</td>
          <td className="nominal">{commaFormatted(data.ypl)}</td>
          <td className="nominal">{commaFormatted(detail.ypl)}</td>
          <td className="nominal border_double border_double__right persentase">
          {
            persentase && persentase['ypl'] && persentase['ypl'][data.id] ? persentase['ypl'][data.id] : 0
          } %
          </td>
          <td className="nominal">{commaFormatted(data.committee)}</td>
          <td className="nominal border_double border_double__right">{commaFormatted(detail.committee)}</td>
          <td className="nominal">{commaFormatted(data.intern)}</td>
          <td className="nominal border_double border_double__right">{commaFormatted(detail.intern)}</td>
          <td className="nominal">{commaFormatted(data.bos)}</td>
          <td className="nominal border_double border_double__right">{commaFormatted(detail.bos)}</td>
          <td className="nominal">{commaFormatted(detail.total)}</td>
        </tr>
      ]);
    });

    return rows;
  }
  renderHeader() {
    return (
      <thead>
        <tr>
          <th className="manage-budget__row border_double" colSpan={2}>Akun</th>
          <th rowSpan={2} className="border_double">Keterangan</th>
          <th colSpan={3} className="border_double">YPL</th>
          <th colSpan={2} className="border_double">Komite</th>
          <th colSpan={2} className="border_double">Intern</th>
          <th colSpan={2} className="border_double">BOS</th>
          <th rowSpan={2} className="border_double">Jumlah</th>
        </tr>
        <tr>
          <th>Kode</th>
          <th className="border_double border_double__right">Keterangan</th>
          <th>RAPBU</th>
          <th>APBU</th>
          <th className="border_double border_double__right">%</th>
          <th>RAPBU</th>
          <th className="border_double border_double__right">APBU</th>
          <th>RAPBU</th>
          <th className="border_double border_double__right">APBU</th>
          <th>RAPBU</th>
          <th className="border_double border_double__right">APBU</th>
        </tr>
      </thead>
    );
  }

  renderSummary(label, posType, data) {
    const summaryRow = [];
    const pos = [
      'ypl', 'komite', 'intern', 'bos'
    ];

    summaryRow.push([
      <td
        key={`border`}
        className="total border_double border_double__right" colSpan={3}>{label}</td>
    ]);

    pos.forEach((item) => {
      summaryRow.push([
        <td key={`total_rapbu_${posType}_${item}`}
            className={`nominal ${data[`total_rapbu_${posType}_${item}`] < 0 ? 'red' : ''}`}>
          {commaFormatted(data[`total_rapbu_${posType}_${item}`])}
        </td>,
        <td key={`total_${posType}_${item}`}
          className={`nominal ${data[`total_${posType}_${item}`] < 0 ? 'red' : ''}`}>
          {commaFormatted(data[`total_${posType}_${item}`])}
        </td>,
        (item === 'ypl'  &&
        <td key={`border_${posType}_${item}`}
          className="nominal border_double border_double__right persentase"></td>),
      ]);
    });

    summaryRow.push([
      <td  key={`total_${posType}`}
        className={`nominal ${data[`total_${posType}`] < 0 ? 'red' : ''}`}>
        {commaFormatted(data[`total_${posType}`])}
      </td>
    ])

    return (
      <tr className="summary total">
        {summaryRow}
      </tr>
    );
  }

  render() {
    const { rapbuSummary } = this.props;
    const { semesterTwo, semesterOne, inventaris } = rapbuSummary;
    const tableColSpan = 16;
    const summaryRowColSpan = 2;

    return (
      <div className="manage-budget__table-wrapper">
        <table className="manage-budget__form-table table">
          {this.renderHeader()}
          <tbody>
            <tr className="sub_header_1">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pendapatan</h4>
                </div>
              </td>
            </tr>
            <tr  className="sub_header_2">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester II Tahun Ajaran Berjalan</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterTwo.pendapatan)}
            <tr className="sub_header_2">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester I Tahun Ajaran Berikutnya</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterOne.pendapatan)}
            <tr  className="sub_header_1">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Pengeluaran</h4>
                </div>
              </td>
            </tr>
            <tr  className="sub_header_2">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester II Tahun Ajaran Berjalan</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterTwo.pengeluaran)}
            <tr  className="sub_header_2">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Semester I Tahun Ajaran Berikutnya</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(semesterOne.pengeluaran)}
            <tr  className="sub_header_1">
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h4>Inventaris</h4>
                </div>
              </td>
            </tr>
            {this.renderRows(inventaris)}
            {this.renderSummary('Total Pendapatan', 'pendapatan', rapbuSummary)}
            {this.renderSummary('Total Pengeluaran', 'pengeluaran', rapbuSummary)}
            {this.renderSummary('Jumlah Inventaris', 'inventaris', rapbuSummary)}
            {this.renderSummary('Estimasi Laba/Rugi', 'estimasi', rapbuSummary)}
            {this.renderSummary('Saldo', 'saldo', rapbuSummary)}
          </tbody>
        </table>
      </div>
    );
  }
}
BudgetApbu.propTypes = {
  rapbuSummary: PropTypes.object.isRequired,
};
