import { connect } from 'react-redux';
import ManageInformationLetter from '../../../views/Personnel/Letter/ManageInformationLetter.letter.view';
import { manageInformationLetter } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleManageInformationLetter: (payload, goBack, isEdit = false) => (
      dispatch(manageInformationLetter(payload, goBack, isEdit))
    ),
  };
}

export default connect(null, mapDispatchToProps)(ManageInformationLetter);
