import { connect } from 'react-redux';
import TambahKelasKhusus from '../../../views/Akademi/DataKelas/TambahKelasKhusus.view';
import { addBudget } from '../../../states/thunks/finance.thunk';
import { getLevels } from '../../../states/thunks/options.thunk';
function mapStateToProps(state) {
  return {
    user: state.user,
    levels: state.options.levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahKelasKhusus);