import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import {
  Input,
  Button,
  Select,
  Checkbox,
  SelectMultiple,
} from "../../../components/base";

export default class BuatDaftarEkskulForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*",
      },
    };
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name, inputArray, fieldName, arrayPosition) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name,
        value,
        dataset: {
          inputArray,
          fieldName,
          arrayPosition,
        },
      },
    });
  }
  _onRemoveMulti(
    selectedList,
    removedItem,
    inputArray,
    fieldName,
    arrayPosition
  ) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {
          inputArray,
          fieldName,
          arrayPosition,
        },
      },
    });
  }

  render() {
    const { list, error, onChange, param, fieldName, form, onFormChange } =
      this.props;
    const { value } = form;

    const Status = [
      { label: "Wajib", value: "wajib" },
      { label: "Pilihan", value: "pilihan" },
    ];
    
    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2}>Ekskul</th>
            <th rowspan={2}>Hari</th>
            <th rowSpan={2}>Status</th>
            <th rowSpan={2}>Opsi</th>
          </tr>
          {/* <tr>
          <th>Senin</th>
          <th>Selasa</th>
          <th>Rabu</th>
          <th>Kamis</th>
          <th>Jumat</th>
          <th>Sabtu</th>
          </tr> */}
        </thead>
        <tbody>
          {map(list, (data, idx) => (
            <tr key={`plafon_journal_data__${idx}`}>
              <td>{idx + 1}</td>
              <td>
                {
                  <Select
                    value={data.ekskul_id}
                    data={param.ekskul}
                    placeholder="Pilihan"
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name="daftar_ekskul"
                    name="ekskul_id"
                    onChange={onFormChange}
                  />
                }
              </td>
              {/* <td>
                <Input
                    type="checkbox"
                    name="day"
                    data-input-array
                    data-array-position={idx}
                    arrayPosition={idx}
                    data-field-name='daftar_ekskul'
                    onChange={onFormChange}
                    value={data.day}
                    error={data.day || ''}
                  />
                </td>
                 */}
              {/* <td>
               <Input
                     type="checkbox"
                     name="day"
                     data-input-array
                     data-array-position={idx}
                     arrayPosition={idx}
                     data-field-name='daftar_ekskul'
                     onChange={onFormChange}
                     value={data.day}
                    error={data.day || ''}
                  />
               </td>
               <td>
               <Input
                     type="checkbox"
                     name="day"
                     data-input-array
                     data-array-position={idx}
                     arrayPosition={idx}
                     data-field-name='daftar_ekskul'
                     onChange={onFormChange}
                     value={data.day}
                    error={data.day || ''}
                  />
               </td>
               <td>
               <Input
                    type="checkbox"
                    name="day"
                    data-input-array
                    data-array-position={idx}
                    arrayPosition={idx}
                    data-field-name='daftar_ekskul'
                    onChange={onFormChange}
                    value={data.day}
                    error={data.day || ''}
                  />
               </td>
               <td>
               <Input
                   type="checkbox"
                   name="day"
                   data-input-array
                   data-array-position={idx}
                   arrayPosition={idx}
                   data-field-name='daftar_ekskul'
                   onChange={onFormChange}
                   value={data.day}
                   error={data.day || ''}
                  />
               </td>
               <td>
               <Input
                    type="checkbox"
                    name="day"
                    data-input-array
                    data-array-position={idx}
                    arrayPosition={idx}
                    data-field-name='daftar_ekskul'
                    onChange={onFormChange}
                    value={data.day}
                    error={data.day || ''}
                  />
               </td> */}

              {/* <p>Tipe Mata Pelajaran</p>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field"> */}
              {/* <Select
            type="text"
            name="tipe_matpel"
            label="Tipe Mata Pelajaran"
            data={tipe_matpel}
            placeholder="Pilihan"
            onChange={onFormChange}
            value={form.value.tipe_matpel }
            error={form.error.tipe_matpel || ''}
          /> */}

              <SelectMultiple
                name="ekskul_day"
                style={{ width: "50%" }}
                options={param.ekskul_day[idx]}
                label="Hari"
                disable	={data.ekskul_id?false:true}
                displayValue="label" // Property name to display in the dropdown options
                showCheckbox={true}
                inputArray
                fieldName="daftar_ekskul"
                array_position={idx}
                values={data.ekskul_day}
                //  selectAllText="Select All"
                //  ref={this.selectAllItems()}
                // selectedValues={form.value.students}
                // value={form.value.students}
                onRemove={(value) =>
                  this._onRemoveMulti(
                    value,
                    "ekskul_day",
                    true,
                    "daftar_ekskul",
                    idx
                  )
                }
                // error={form.error.students || ''}
                onSelect={(value) =>
                  this._onChangeMulti(
                    value,
                    "ekskul_day",
                    true,
                    "daftar_ekskul",
                    idx
                  )
                }
              />

              <td>
                <Select
                  value={data.status}
                  data={Status}
                  placeholder="Pilihan"
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="daftar_ekskul"
                  name="status"
                  onChange={onFormChange}
                />
              </td>
              <td>
                <Button onClick={() => this._onDeleteList(idx)}>Hapus</Button>
              </td>
            </tr>
          ))}
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={10}>
              <div>
                <Button icon="icon-plus" onClick={this._onAddList} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

BuatDaftarEkskulForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatDaftarEkskulForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
};
