import {
  forEach, result, isEmpty, findLastKey,
} from 'lodash';
import i18n from '../i18n';
import env from '../configs/env.config';
import { mockResponses } from '../configs/api.config';

import { filterObjectProperties } from './transformer.util';

export function addDefaultPayloadInterceptor(store) {
  return (config) => {
    if (config.method === 'get') {
      return config;
    }
    const reduxState = store.getState();
    const extraPayload = {
      ...reduxState.additionalApiPayload,
      lang: reduxState.appConfig.lang,
      create_by: reduxState.user.email,
    };
    if (config.data instanceof FormData) {
      forEach(filterObjectProperties(extraPayload, config.additional || []), (value, key) => {
        config.data.append(key, value);
      });
    } else {
      config.data = { // eslint-disable-line
        ...filterObjectProperties(extraPayload, config.additional || []),
        ...config.data,
      };
    }
    return config;
  };
}

// Interceptor that checks the status of the response
export function getStatusValidatorInterceptor() {
  return (response) => {
    const { status, data, headers } = response;
    if (status >= 200 && status < 300) {
      return response.data;
    }
    if (status === 422) {
      const error = { message: '', errors: {} };
      const { errors, message } = data;
      let errorObj = {};
      if (typeof errors === 'undefined' || !errors || isEmpty(errors)) {
        error.message = message;
      } else {
        const errorMessage = errors[findLastKey(errors, o => o[0] && o[0] !== '')];
        const { 0: lastMessage } = errorMessage;
        error.message = lastMessage;
        Object.keys(errors).forEach((field) => {
          const fieldArr = field.split('.');
          errorObj = {
            ...errorObj,
            ...fieldArr.reverse().reduce((res, key) => ({ [key]: res }), i18n.t(errors[field])),
          };
        });
      }

      const errorResponse = {
        status,
        headers,
        message: error.message,
        errors: errorObj || {},
      };

      throw errorResponse;
    }
    if (status === 401) {
      window.location.href = '/login';
    }
    throw response.data || {};
  };
}

function mockAdapter(config) {
  return new Promise((resolve) => {
    const mockData = result(mockResponses, config.endpoint, {});
    const response = {
      data: mockData.response,
      status: mockData.status || 200,
      statusText: 'OK - Mocked request',
      headers: { mock: true },
      config,
    };
    setTimeout(() => resolve(response), 1000);
  });
}

// Interceptor that sets mock response
export function mockInterceptor(config) {
  if (env.MOCKAPI) {
    console.log('SETTING MOCK for endpoint', config.endpoint); // eslint-disable-line no-console
    config.adapter = mockAdapter; // eslint-disable-line
  }
  return config;
}
