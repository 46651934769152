import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';

export default function KomentarManualForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="komentar__custom-form">
    <div className="komentar__header-title3">
      <div className="komentar__custom-form-column">
        <div className="komentar__custom-form-column__field">
          <Input
            type="text"
            name="nis"
            label="Nama/NIS"
            onChange={onFormChange}
            value={form.value.nis}
            error={form.error.nis || ''}
          />

            <Input
                type="text"
                name="guru"
                label="Guru"
                onChange={onFormChange}
                value={form.value.guru}
                error={form.error.guru || ''}
            />
            <Textarea
                type="text"
                name="komentar"
                style={{backgroundColor:'#ffff00'}}
                label="Komentar/Deskripsi"
                onChange={onFormChange}
                value={form.value.komentar}
                error={form.error.komentar || ''}
            />

        </div>
        </div>

        <div className="komentar__box--square2">
            Image
         </div>
        </div>

        </div>
        
 
   
  );
}
KomentarManualForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
KomentarManualForm.defaultProps = {
  onFormChange: noop,
};
