import { connect } from 'react-redux';
import JadwalKaryawan from '../../../views/Kurikulum/Jadwal/JadwalKaryawan.kurikulum.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { saveScheduleLecturer, listScheduleLecturer, deleteScheduleLecturer } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      handlesaveScheduleLecturer: (payload, goback) => dispatch(saveScheduleLecturer(payload, goback)),
      handlelistScheduleLecturer: (payload, goback) => dispatch(listScheduleLecturer(payload, goback)),
      // handleviewScheduleLecturer: (payload, goback) => dispatch(viewScheduleLecturer(payload, goback)),
      // handleeditScheduleLecturer: (payload, goback) => dispatch(editScheduleLecturer(payload, goback)),
      handledeleteScheduleLecturer: (payload, goback) => dispatch(deleteScheduleLecturer(payload, goback)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(JadwalKaryawan);