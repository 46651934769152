import React, { Component } from 'react';
import { Input, Select } from '../../../../components/base';
import PropTypes from 'prop-types';
export default class IdentitasSekolah1 extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    const change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    onChange(e);
  }

  render() {
    const {
      form,
      provinces,
      idx,
      cities,
      districts,
      sub_districts,
      onChange,
      classes,
      param,
    } = this.props;
    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="namasekolah"
              label="Nama Sekolah"
              onChange={this._onFormChange}
              value={form.value.namasekolah || ''}
              error={form.error.namasekolah || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="jenisekolah"
              label="Jenis Sekolah"
              placeholder="Pilihan"
              data={param.unit_types}
              onChange={this._onFormChange}
              value={form.value.jenisekolah || ''}
              error={form.error.jenisekolah || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Input
              type="text"
              name="telpsekolah"
              label="Telepon"
              onChange={this._onFormChange}
              value={form.value.telpsekolah || ''}
              error={form.error.telpsekolah || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            
             <Input
              type="text"
              name="alamatsekolah"
              label="Alamat "
              onChange={this._onFormChange}
              value={form.value.alamatsekolah || ''}
              error={form.error.alamatsekolah || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Select
              name="provinces_id"
              label="Provinsi"
              placeholder="Pilihan"
              onChange={this._onFormChange}
              data={provinces.list}
              value={form.value.provinces_id || ''}
              error={form.error.provinces_id || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
          
          
          </div>
        </div>
        {form.value.provinces_id?
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
        
          </div>
          <div className="manage-registration__custom-form-column-city__field">
          <Select
              name="cities_id"
              label="Kotamadya "
              placeholder="Pilihan"
              onChange={this._onFormChange}
              data={cities.list}
              value={form.value.cities_id || ''}
              error={form.error.cities_id || ''}
            />
          
          </div>
        </div>
         :''}
         {form.value.cities_id?
         
        
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Select
             name="districts_id"
             label="Kecamatan"
             onChange={this._onFormChange}
             data={districts.list}
             placeholder="Pilihan"
             value={form.value.districts_id || ''}
             error={form.error.districts_id || ''}
           />
         
          </div>
          <div className="manage-registration__custom-form-row__field">
         
          </div>
        </div>
        :''}
        {form.value.districts_id?
         <div className="manage-registration__custom-form-row">
         <div className="manage-registration__custom-form-row__field">
         
        
         </div>
         <div className="manage-registration__custom-form-column-city__field">
         <Select
               name="sub_districts"
               label="Kelurahan"
               onChange={this._onFormChange}
               data={sub_districts.list}
               placeholder="Pilihan"
               value={form.value.sub_districts || ''}
               error={form.error.sub_districts || ''}
             />
         </div>
       </div>
        :''}
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="kodepos"
              label="Kode Pos "
              onChange={this._onFormChange}
              value={form.value.kodepos || ''}
              error={form.error.kodepos || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="kodesekolah"
              label="Kode Sekolah"
              onChange={this._onFormChange}
              value={form.value.kodesekolah || ''}
              error={form.error.kodesekolah || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="koderayon"
              label="Kode Rayon"
              onChange={this._onFormChange}
              value={form.value.koderayon || ''}
              error={form.error.koderayon || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="kodesubrayon"
              label="Kode Sub Rayon"
              onChange={this._onFormChange}
              value={form.value.kodesubrayon || ''}
              error={form.error.kodesubrayon || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="website"
              label="Website"
              onChange={this._onFormChange}
              value={form.value.website || ''}
              error={form.error.website || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="email"
              label="Email"
              onChange={this._onFormChange}
              value={form.value.email || ''}
              error={form.error.email || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nomornds"
              label="Nomor NDS"
              onChange={this._onFormChange}
              value={form.value.nomornds || ''}
              error={form.error.nomornds || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nomornss"
              label="Nomor NSS"
              onChange={this._onFormChange}
              value={form.value.nomornss || ''}
              error={form.error.nomornss || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nomornpsn"
              label="Nomor NPSN"
              onChange={this._onFormChange}
              value={form.value.nomornpsn || ''}
              error={form.error.nomornpsn || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="sistempembagianraport"
              label="Sistem Pembagian Raport"
              placeholder="Pilihan"
              data={param.report_periods}
              onChange={this._onFormChange}
              value={form.value.sistempembagianraport || ''}
              error={form.error.sistempembagianraport || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nipkepalasekolah"
              label="NIP Kepala Sekolah"
              onChange={this._onFormChange}
              value={form.value.nipkepalasekolah || ''}
              error={form.error.nipkepalasekolah || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="namakepalasekolah"
              label="Nama Kepala Sekolah"
              onChange={this._onFormChange}
              value={form.value.namakepalasekolah || ''}
              error={form.error.namakepalasekolah || ''}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nippengawas"
              label="NIP Pengawas"
              onChange={this._onFormChange}
              value={form.value.nippengawas || ''}
              error={form.error.nippengawas || ''}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="namapengawas"
              label="Nama Pengawas"
              onChange={this._onFormChange}
              value={form.value.namapengawas || ''}
              error={form.error.namapengawas || ''}
            />
          </div>
        </div>
      </div>
    );
  }
}

IdentitasSekolah1.propTypes = {
  provinces : PropTypes.array,
  districts: PropTypes.array,
  sub_districts: PropTypes.array,
  cities: PropTypes.array,
};

IdentitasSekolah1.defaultProps = {
  provinces: [],
  cities_id: [],
  districts_id: [],
  sub_districts: [],
};
