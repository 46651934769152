import { connect } from 'react-redux';
import ManageNonBudget from '../../../../views/Finance/Budgeting/NonBudget/ManageNonBudget.finance.view';
import { getCodeOfAccount } from '../../../../states/thunks/common.thunk';
import { getCoaOptions } from '../../../../states/thunks/options.thunk';
import { addNonBudget, editNonBudget } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    coa: state.options.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCodeOfAccount: code => dispatch(getCoaOptions(code)),
    handleAddNonBudget: (payload, goBack, unit_id) => dispatch(addNonBudget(payload, goBack, unit_id)),
    handleEditNonBudget: (payload, goBack, unit_id) => dispatch(editNonBudget(payload, goBack, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageNonBudget);
