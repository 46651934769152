import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

export default function Menu(props) {
  const {
    onClick,
    title,
    leftIcon,
    leftIconImage,
    rightIcon,
    style,
    className,
    userPermission,
    permission,
    attributes,
  } = props;
  const leftIconClassnames = classnames([
    'menu__left-icon',
    className,
  ]);
  if(typeof permission !== 'undefined') {
    if(!userPermission.includes(permission)) {
      return false;
    }
  }
  return (
    <button
      onClick={onClick}
      className="menu"
      style={style}
    >
      <div className="menu__left">
        {leftIcon && <span className={leftIconClassnames}><i className={leftIcon} /></span>}
        {leftIconImage && <span className={leftIconClassnames}><img src={leftIconImage} alt="menu" /></span>}
        <p className="menu__title">{title}</p>
      </div>
      {rightIcon && <span className={rightIcon} />}
    </button>
  );
}
Menu.propTypes = {
  onClick: PropTypes.func,
  leftIconImage: PropTypes.string,
  leftIcon: PropTypes.string,
  title: PropTypes.string,
  rightIcon: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  userPermission: PropTypes.array,
};
Menu.defaultProps = {
  onClick: noop,
  leftIconImage: '',
  leftIcon: '',
  title: '',
  rightIcon: '',
  className: '',
  style: {},
  userPermission: [],
};
