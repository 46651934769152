import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import { STUDENT_UNIT_2_FORM_FIELDS } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination, Select } from '../../../components/base/index';
import { STUDENT_TABLE_UNIT_FIELDS} from '../../../constants/student.constant';
import language from '../../../languages';
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class StudentList extends PureComponent {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this.state = {
        page: 1,
        selected: {},
        content: initialContent,
        unchecked: {},
        filters:{},
        list:[],
        form: {
          value: {
            classes: [],
            levels: [],
            classes_id: '',
          },
          error: {
            classes: '',
            levels: '',
          },
        },
        param:{},
        filtered: false,
      };
     
    }
  
    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        files,
      } = event.target;
      const { inputType = 'text' } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        if (inputType === 'number') {
          formattedValue = commaFormatted(value);
        }
        if (inputType === 'file') {
          [formattedValue] = files;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              [name]: formattedValue,
            },
            error: {
              ...prevState.form.error,
              [name]: '',
            },
          },
        };
      });
    }
  
    _onClickNext() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit');
      }
    
  
      render() {

        const {param, form,
            page, list = {},
            selected, checkedAll, content, 
            filters, listAmount } = this.state;
        const { classes = {}, levels = {} } = this.props;
        const presence_status = [
                { label: 'Mengikuti', value: 'mengikuti'},
                { label: 'Tidak Mengikuti', value: 'tidak'},
              ]
        const status = [
          { label: 'Hadir', value: 'HADIR'},
          { label: 'Sakit', value: 'SAKIT'},
          { label: 'Izin', value: 'IZIN'},
          { label: 'Alpha', value: 'ALPHA'},
    
        ]
        const academic_year = [
          { label: '2019', value: '1'},
          { label: '2020', value: '2'},
          { label: '2021', value: '3'},
        ]
        const componentRef = React.createRef();
        return (
          <div className="manage-registration">
              <ReactToPrint
            trigger={() => (
              <div className="form-group-member">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          />
              <div className="manage-registration">
                <div className="fund-request__content">
                  <table className="cash-journal-report__table" ref={componentRef}>
                    <thead>
                      <tr>
                        {
                            map(STUDENT_TABLE_UNIT_FIELDS.label, (field, idx) => (
                              <th key={`table_th_${idx}`}>{field}</th>
                            ))
                          }
                      </tr>
                    </thead>
                    <tbody>
                      {
                          map(content.list, (list, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {
                              map(STUDENT_TABLE_UNIT_FIELDS.value, (field, fieldIdx) => {
                                if (fieldIdx === 0) {
                                  return (
                                    <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                                  );
                                }
                                if (field.type === 'link') {
                                  return (
                                    <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+list.hyperlink} target="_blank" rel="noopener noreferrer">{list.hyperlink}</a></td>
                                      // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                                  );
                                }
                                return (
                                  <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                    {formatData(list, field)}
                                  </td>
                                );
                              })
                              }
                              {/* {this._renderButtons(list)} */}
                            </tr>
                          ))
                        }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="user-management__table-footer">
                <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                </p>
                <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this._onChangePage}
                />
            </div>
        </div>
            );
          }
  }
StudentList.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
StudentList.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
