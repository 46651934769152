import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Pagination } from '../../../components/base';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import FormGenerateSoal from './components/FormGenerateSoal.kurikulum.component';
import { Input, Select, Textarea } from '../../../components/base/index';
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};


export default class GenerateSoal extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.state = {
      form: {
        value: {
          organization_id: '',
          periode: '',
          tanggal: '',
          number: '',
          upload: '',
          tabeltambahsoal: [
            {
              judul: '',
              date: '',
              keterangan: '',
              mata_pelajaran: '',
              periode: '',
              upload: '',
            }
          ],
        },
        error: {
            tabeltambahsoal: '',
        },
      },
      param: {},
    };
  }
  componentDidMount() {
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { tabeltambahsoal } = form.value;
    tabeltambahsoal.push({
        judul: '',
        date:'',
        keterangan:'',
        mata_pelajaran: '',
        periode: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabeltambahsoal,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  async onSubmit() {
    
  }
  async onEdit() {
  }
  async _onClickPostingButton(val) {
    const { handlePostJournalPosting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalPosting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }
  async _onClickUnpostButton(val) {
    const { handlePostJournalUnposting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalUnposting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }

  _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleGetCashJournal } = this.props;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleGetCashJournal({ ...params, filters });
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        }
      } catch (err) {
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    });
  }
  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { tabeltambahsoal } = prevState.form.value;
      const list = prevState.form.value.tabeltambahsoal;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabeltambahsoal: list
      };
    });
  }
  _onClickSubmit() {
    const { history } = this.props;
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName, 
    } = dataset;
    this.setState((prevState) => {
      let newList = []
      let newListError = [];
      let formattedValue = value;
      console.log("pos : " + arrayPosition + " name : " + name + " val : " +formattedValue + "dataset : " + JSON.stringify(dataset))
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if(type==="file"){
        formattedValue = files[0];
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        console.log("nl " + fieldName)
        // newList[arrayPosition] = {};
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value, // value: { oldvalues, overrideoldvalue }
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
}
render() {
  const {
    content
  } = this.state;
    const { form } = this.state;
    const { tabeltambahsoal } = form.value;
    console.log("aa" + JSON.stringify(tabeltambahsoal))
    return (
      <div className="student-list">
        <form>
          <h1 style={{textAlign:"center"}}>Judul Soal</h1>
          <hr></hr>
          <h2>Mata Pelajaran : Matematika</h2>
          <h3>Pertanyaan</h3>
          <br></br>
          <p>&nbsp;&nbsp;&nbsp;1.8730 dibaca...</p>
          <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="radio"
                        name="number_mass"
                        label="Delapan ribu tiga ratus tujuh puluh"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>
                <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="radio"
                        name="number_mass"
                        label="Delapan ribu tiga ratus tujuh puluh nol"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>
                <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="radio"
                        name="number_mass"
                        label="Delapan ribu tiga tujuh puluh"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>
                <br></br>
                <p>&nbsp;&nbsp;&nbsp;2. 1500, 3000, 4500,...</p>
                <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="radio"
                        name="number"
                        label="6.000"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>
                <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="radio"
                        name="number"
                        label="6.500"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>
                <div className="absensi-rekap__custom-form-column__field">
                    <Input
                        type="radio"
                        name="number"
                        label="7.000"
                        onChange={this._onFormChange}
                        value={form.value.number_mass}
                    />
                </div>

          <div className="student-list__header">
          <div className="student-list_button">
            <Button
                type="button"
                title="Download"
                onClick={this.onSubmit}
            />
          </div>
          <div className="student-list__button">
            <Button
                type="button"
                title="Cetak"
                onClick={this._onClickAdd}
            />
          </div>
          
          <div className="student-list__button">
            <Button
                type="button"
                title="Kembali"
                onClick={this.onSubmit}
            />
          </div>
          </div>
          <div className="user-management__table-footer">
              {/* <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`} 
              </p> */}
              {/* <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this._onChangePage}
              /> */}
            </div>
          
        </form>
      </div>
    );
  }
}
GenerateSoal.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
GenerateSoal.defaultProps = {
  getParamOptions: noop,
  handleGetStudent: noop,
};