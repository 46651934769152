import { connect } from 'react-redux';
import JurusanKelas from '../../../views/Akademi/DataKelas/JurusanKelas.view';
import { addBudget } from '../../../states/thunks/finance.thunk';
import { saveMajor, listMajor, editMajor, deleteMajor, getMajor } from '../../../states/thunks/academics.thunk';
import {  getMajorGroupOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMajorGroupOptions: (payload) => dispatch(getMajorGroupOptions(payload)),
    handleGetMajor: payload => dispatch(getMajor(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveMajor: (payload, goback) => dispatch(saveMajor(payload, goback)),
    handleListMajor: (payload, goback) => dispatch(listMajor(payload, goback)),
    handleEditMajor: (payload, goback) => dispatch(editMajor(payload, goback)), 
    handleDeleteMajor: (payload, goback) => dispatch(deleteMajor(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JurusanKelas);