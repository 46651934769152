import { connect } from 'react-redux';
import AbsensiSiswa from '../../../views/Student/absensi/AbsensiSiswa.student.view';
function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      levels: state.options.levels,
      user: state.user || {},
  
    }
  }
export default connect(mapStateToProps, null)(AbsensiSiswa);