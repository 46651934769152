import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty, map, noop } from 'lodash';
import Menu from '../Menu/Menu.component';

export default class CollapsibleMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.clickHandlers = {};
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
  }

  onClickMenu(path, attributes = {}) {
    const { onClickMenu } = this.props;
    this.setState(prevState => ({
      show: !prevState.show,
    }));
    if (path) {
      onClickMenu(path, attributes);
    }
  }

  getClickHandler(title, path, attributes = {}) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, title)) {
      this.clickHandlers[title] = () => this.onClickMenu(path, attributes);
    }
    return this.clickHandlers[title];
  }

  toggleMenu() {
    this.setState(prevState => ({
      show: !prevState.show,
    }));
  }

  render() {
    const { show } = this.state;
    const {
      title, iconImage, icon, menu, padding, className,
      path, onClickMenu, userPermission, permission,
      attributes,
    } = this.props;
    const menuStyles = {
      paddingLeft: padding,
    };
    const listMenuClassnames = classnames([
      'collapsible-menu__list',
      show && 'collapsible-menu__list--show',
    ]);
    return (
      <div className="collapsible-menu">
        <Menu
          onClick={this.getClickHandler(title, path, attributes)}
          title={title}
          leftIconImage={iconImage}
          leftIcon={icon}
          rightIcon={!isEmpty(menu) ? 'icon-plus' : ''}
          style={menuStyles}
          className={className}
          permission={permission}
          userPermission={userPermission}
          attributes={attributes}
        />
        <div className={listMenuClassnames}>
          <ul className="collapsible-menu__child-container">
            {
              !isEmpty(menu)
              && map(menu, (list, index) => (
                <CollapsibleMenu
                  key={`menu_children__${index}_${list.title}`}
                  padding={list.padding}
                  title={list.title}
                  iconImage={list.iconImage}
                  icon={list.icon}
                  menu={list.menu}
                  className={list.className}
                  path={list.path}
                  onClickMenu={onClickMenu}
                  permission={list.permission}
                  userPermission={userPermission}
                  attributes={list.attributes}
                />
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}
CollapsibleMenu.propTypes = {
  menu: PropTypes.array,
  onClickMenu: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  padding: PropTypes.number,
  iconImage: PropTypes.string,
  icon: PropTypes.string,
  userPermission: PropTypes.array,
  permission: PropTypes.string,
  attributes: PropTypes.object,
};
CollapsibleMenu.defaultProps = {
  menu: [],
  onClickMenu: noop,
  path: '',
  title: '',
  className: '',
  iconImage: '',
  icon: '',
  padding: 0,
  userPermission: [],
  permission: undefined,
  attributes: {},
};
