import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Logout extends PureComponent {
  componentWillMount() {
    const { handleLogout } = this.props;
    handleLogout();
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};
