import { connect } from 'react-redux';
import KelolaPindahKelas from '../../../views/Akademi/DataKelas/KelolaPindahKelas.view';
import { addBudget } from '../../../states/thunks/finance.thunk';
import { getClasses,getLevels } from '../../../states/thunks/options.thunk';
function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: (payload) => dispatch(getClasses(payload)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KelolaPindahKelas);