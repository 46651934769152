import { connect } from 'react-redux';
import PengembalianBuku from '../../../views/Library/PengembalianBuku/PengembalianBuku.library.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(PengembalianBuku);
