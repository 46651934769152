export const FINANCE_ADD_REALIZATION_FORM_FIELDS = [{
  type: 'file',
  name: 'file',
  label: 'Upload File Bukti Transaksi',
  placeholder: 'Pilih file',
}, {
  type: 'text',
  inputType: 'number',
  name: 'amount',
  label: 'Jumlah Realisasi',
  placeholder: 'Isi jumlah realisasi',
}, {
  type: 'textarea',
  name: 'description',
  label: 'Keterangan',
  placeholder: 'Isi keterangan',
  full: true,
}];

export const REALIZATION_REQUIRED_FIELD = ['parameter_code'];

export const FINANCE_REALIZATION_TABLE_FIELDS = {
  label: [
    'No',
    'Code Of Account',
    'Tanggal Input',
    'Keterangan',
    'Jumlah Realisasi',
    'Terhadap APBU',
    'Action',
  ],
  value: [
    {},
    { type: 'string', attribute: 'no_pengajuan' },
    { type: 'date', attribute: 'created_at' },
    { type: 'string', attribute: 'description' },
    { type: 'string', attribute: 'amount' },
    { type: 'string', attribute: 'desc' },
  ],
};
