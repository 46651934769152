import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual, isEmpty, capitalize } from 'lodash';
import { Button, Pagination } from '../../components/base';
import BaseTable from '../../components/BaseTable/BaseTable.component';
import { formatData, normalizeAmount, commaFormatted } from '../../utils/transformer.util';
import language from '../../languages';
import { Select, FileInput } from '../../components/base/index';
import { LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS } from '../../constants/Student/student.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ListPendaftaranSiswa extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.onClickClassDiv = this.onClickClassDiv.bind(this);
    this.getYearsOfEducationsOptions= this.getYearsOfEducationsOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._onClickImport = this._onClickImport.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, va_code } = school_unit;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          periods_id: '',
          organizations_id: organizations_id,
          units_id: school_unit.id,
          levels_id,
          va_code,
          levels: [],
          classrooms_id: '',
        },
        error: {
          classrooms: '',
          levels: '',
        },
      },
      param:{},
      filtered: false,
    };
  }

  componentDidMount() {
    this._initializeData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _initializeData() {
    const { user } = this.props;
    const { content} = this.state;
    const { school_unit } = user;
    const paramTypes = ['classrooms','levels', 'classrooms'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    await this._getStudentOptions( {units_id: [school_unit.id] });
    await this.getYearsOfEducationsOptions();  
    await this._getPeriodsOptions();

    if (isEmpty(content.list)) {
      await this._onSearchContent({ page: 1 });
    }
    
  }

  async onClickDownload(id) {
    const { handleDownloadAnnouncement } = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload)
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleUploadExcelStudents, history } = this.props;
    
    const res = handleUploadExcelStudents(form.value);
    if (res) {
      console.log(res)
      window.scrollTo(0, 0);
      this.setState(({
        page: 1,
      }));
      this.onRemoveFile();
      this._onSearchContent();
    }
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    const {
      students_id = '',
      entrance_year = '',
    } = filters;
    // filters.entrance_year=new Date().getFullYear();

    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListManageRegis, getYearsOfEducationsOptions, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;

        // const period = await getYearsOfEducationsOptions(filters);
        // var years_now = new Date().getFullYear();
        // var month = new Date().getMonth() + 1;
        // if (month < 7) {
        //   years_now -= 1;
        // }
    
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleListManageRegis({ ...params, filters: {entrance_year: entrance_year}, 
          organizations_id: organizations_id, units_id: school_unit.id });
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        // }
      } catch (err) {
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      // }
    });
  }

  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            entrance_year: data.value,
          },
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              entrance_year: data.value,
            },
          },
        }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id } = user;
    filters.organizations_id = organizations_id;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              periods_id: data.value,
            }
          }
        }))
      }
    });
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: organizations_id });
      }
      // if (name === 'classes_id') {
      //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id: organizations_id });
      // }
      if (name === 'classrooms_id' && !inputArray ) {
        this._setClassrooms(value)
      }
      if (name === 'levels_id') {
        this._getClassesOptions({ filters: { levels_id: [value] }} );
      }
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  async handleGetData(payload = {}) {
    const { handleListManageRegis,user } = this.props;
    const { units_id, workingUnit, id, user_group, profile, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
      const res = await handleListManageRegis({...payload, user_id: id, organizations_id: organizations_id});

    this.setState({
      list: res,
    });
    // }

    // if(user_group.id === 1){
    //   const res = await handleListAnnouncementStudent({...payload, user_id: id, students_id: profile.id });

    //   this.setState({
    //     list: res,
    //   });
    // }
    
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters={}) {
    const { getStudentOptions, user } = this.props;
    const { school_unit } = user;
    const res = await getStudentOptions(filters);
    filters.units_id = school_unit.id;
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran');
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/tambah-folder', { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { data: val, isEdit: true });
  }
  onClickClassDiv(val) {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kelola-pembagian-kelas', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteAnnouncement } = this.props;
    const res = await handleDeleteAnnouncement(id);
    if(res){
      this.handleGetData();
    }
  }
  
  _onClickImport(data, idx) {
    window.scrollTo(0, 0);
    this.setState(({
      page: 2,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState(({
      page: 1,
    }));
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async onUpload(event) {
    const {
      name,
      value,
      dataset,
      files,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputType === 'file') {
        formattedValue = files[0];

      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          
           <Button
             onClick={() => this.onClickClassDiv(data)}
             title='Pembagian Kelas'
           />
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  onRemoveFile(event) {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            file: "",
          },
        },
      };
    });
  }

  render() {
    const { content, param, filters, form, page } = this.state;
    const { user } = this.props;
    const { user_group } = user;

    const {
      entrance_year = '',
    } = filters;
    const entrance = [
      // { label: '2017', value: '2017'},
      // { label: '2018', value: '2018'},
      // { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
      { label: '2022', value: '2022'}
    ]
    const dataset = {
      toggle: "modal"
    };
    // var years_now = new Date().getFullYear();
    // var month = new Date().getMonth() + 1;
    // console.log(month)
    // if (month < 7) {
    //   years_now -= 1;
    // }

    // map(param.periods, (data, idx) => {
    //   var year = data.label.substring(0, 4);
    //   if (year == years_now) {
    //     years_now =  data.value;
    //   }
    // });

    return (
      <div className="fund-request">
        
        <div className="beginning-balance__button-wrapper">
          {/* {page == 1 && (
            <Button onClick={this._onClickImport} title="Import Siswa" />
          )}
          {page == 2 && (
            <Button onClick={this._onClickBack} title="Kembali" />
          )} */}
          <Button
            title="Tambah Siswa"
            onClick={this.onClickAdd}
            data={dataset}
          />
        </div>
        <br></br>
        <div className="isi-bidang__custom-form">
          <div className="isi-bidang__custom-form-column">
            <div className="isi-bidang__custom-form-row__field">
            <Select
                name="entrance_year"
                label="Tahun Ajaran"
                placeholder={'Pilihan'}
                onChange={this._onChangeFilter}
                data={param.periods}
                // value={entrance_year?entrance_year:years_now}
                value={entrance_year}
                disabled
              />
              
              <div className="isi-bidang__custom-form-row__field"></div>
              {/* <SearchSelect
                  noMargin
                  async={false}
                  name="students_id"
                  list={param.student}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Nama Siswa"
                  value={filters.students_id}
                  // labelName="label"
                  // valueName="value"
                  rightIcon="icon-search"
                  label= "Nama Siswa"
                  /> */}
            </div>
          
              
          </div>
        </div>
        {page == 1 && (
          <>
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    {
                        map(LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS.label, (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                      ))
                      }
                  </tr>
                </thead>
                <tbody>
                  {
                      map(content.list, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {
                          map(LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td style={{textAlign:'center'}}key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                              );
                            }
                            if (field.attribute === "nisn"){
                              return (
                                <td style={{textAlign:'center'}}>{data.nisn}</td>
                              );
                            }
                            if (field.attribute === "no_va"){
                              return (
                                <td style={{textAlign:'center'}}>{data.no_va}</td>
                              );
                            }
                            if (field.type === 'link') {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                                  // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                              );
                            }
                            if (field.attribute === "name") {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                                  {capitalize(data.name)}
                                </td>
                              );
                            }
                            return (
                              <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                {formatData(data, field)}
                              </td>
                            );
                          })
                          }
                          {this._renderButtons(data)}
                        </tr>
                      ))
                    }
                </tbody>
              </table>
            </div>
            {!isEmpty(content.list) && (
              <div className="user-management__table-footer">
                <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                </p>
                <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this.onChangePage}
                />
              </div>
            )}
          </>
        )}
        {page == 2 && (
          <form onSubmit={this.onSubmit}>
            <br></br>
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <FileInput
                  data-input-type="file"
                  noMargin
                  type="file"
                  name="file"
                  label="Upload File Excel"
                  onChange={this.onUpload}
                  onRemoveFile={this.onRemoveFile}
                  // fileName={value.file ? value.file : ""}
                  // displayName={
                  //   value.file ? value.file : "default"
                  // }
                />
              </div>
              <div className="manage-registration__custom-form-row__field"></div>
            </div>
            <br></br>
            <div className="add-upload-rapbu">
              <Button
                type="submit"
                title="Simpan"
              />
            </div>
          </form>
        )}
      </div>
    );
  }
}
ListPendaftaranSiswa.propTypes = {
  handleListAnnouncement: PropTypes.func,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  onRemoveFile: PropTypes.func,
  onFormChange: PropTypes.func,

};
ListPendaftaranSiswa.defaultProps = {
  handleListAnnouncement: noop,
  handleListAnnouncementStudent:PropTypes.func,
  handleDelete: noop,
  user: null,
  onFormChange: noop,
  onRemoveFile: noop,
};
