import { connect } from 'react-redux';
import AnalisaDispliner from '../../../views/Student/displiner/AnalisaDispliner.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(AnalisaDispliner);


