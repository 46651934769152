import { connect } from 'react-redux';
import Journals from '../../../../views/Finance/Bookkeeping/Journals/Journals.bookkeeping.view';
import {
  getJournalById, saveJournal, deleteJournalItem,
  getAvailableFundRequests, getTaxRecipientParam,
  calculateTax, getTaxPtkpParam,
} from '../../../../states/thunks/finance.thunk';
import {
  getJournalCoaOptions, getCoaOptions, getUnitOptions, getBankAccountOptions,
} from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    user: state.user || {},
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetJournal: payload => dispatch(getJournalById(payload)),
    handleSaveJournal: (payload, goBack) => dispatch(saveJournal(payload, goBack)),
    handleDeleteJournalItem: payload => dispatch(deleteJournalItem(payload)),

    handleGetJournalCoaOptions: payload => dispatch(getJournalCoaOptions(payload)),
    handleGetAvailableFundRequests: payload => dispatch(getAvailableFundRequests(payload)),
    handleGetBankAccountOptions: payload => dispatch(getBankAccountOptions(payload)),

    handleGetTaxPtkpParam: payload => dispatch(getTaxPtkpParam(payload)),
    handleGetTaxRecipientParam: payload => dispatch(getTaxRecipientParam(payload)),
    handleCalculateTax: payload => dispatch(calculateTax(payload)),
    handleGetUnitOptions: payload => dispatch(getUnitOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Journals);
