import React, {PureComponent, Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {Button, Input, Select, Textarea } from '../../../components/base/index';
import { Link } from 'react-router-dom';

export default class TableAsalBukuForm extends Component {
    constructor(props) {
        super(props);
        this.clickHandlers = {};
        this._onSubmit = this._onSubmit.bind(this);
         this._onClickEdit = this._onClickEdit.bind(this);
      }

    //   async onClick(val, action, customAction = noop, idx = null) {
    //     const { onClickEdit, onClickDelete } = this.props;
    //     if (action === 'edit') {
    //       onClickEdit(val);
    //     } else if (action === 'delete') {
    //       const willDelete = await deleteConfirmation();
    //       if (willDelete) {
    //         onClickDelete(val.id, val);
    //       }
    //     } else {
    //       customAction(val, idx);
    //     }
    //   }
    
      getClickHandler(val, action, customAction = noop, idx = null) {
        this.clickHandlers[`${val.id}_${action}`] = () => this.onClick(val, action, customAction, idx);
        return this.clickHandlers[`${val.id}_${action}`];
      }

      _onClickEdit() {
        const { onClickEdit, fieldName } = this.props;
        onClickEdit(fieldName);
        
      }

      _onSubmit() {
        const { history } = this.props;
        // history.push('/dashboard/keuangan/spp/laporan-keuangan/invoice-summary-report');
        // history.push('/dashboard/keuangan/spp/laporan-keuangan/invoice-payment-report');
        history.push('/dashboard/keuangan/spp/laporan-invoice-persiswa/detail');
      }
    
      render() {
        const {
          fields,
          data,
          currentPage,
          listAmount,
          showActions,
          customThead,
          customAction,
          showEdit,
          showDelete,
        } = this.props;

        return (
            <div className="perpus__content">
                        <div className="perpus__body">
                          <table className="perpus__table">
                            <thead>
                             
                              <tr>
                                  <th>NO</th>
                                  <th>Daftar Buku</th>
                                  <th>OPSI</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {
                              map(journal.details, (list, idx) => (
                                <tr key={`journal_detail_${idx}`}>
                                  <td>{idx + 1}</td>
                                  <td>{list.code_of_account}</td>
                                  <td>{list.parameter_code.title}</td>
                                  <td>{list.description}</td>
                                  <td className="nominal">{commaFormatted(list.amount)}</td>
                                </tr>
                              ))
                            } */}
                            <tr>
                                <td>1</td>
                                <td></td>
                                <td>
                                <div className="table__actions">
                              
                                 
                                
                                  <Button
                                    style={{backgroundColor:'red'}}
                                    // onClick={this.getClickHandler(list, 'delete')}
                                    title="Hapus"
                                  />
                                
                              
                              </div>
                                </td>
                            </tr>
                            
        
                           
                            </tbody>
                            {/* <tfoot>
                              <tr>
                                <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                                <td colSpan="8" className="nominal"></td>
                              </tr>
                            </tfoot> */}
                          </table>
                        </div>
                      </div>
          );
        
    }
}

 
TableAsalBukuForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};
TableAsalBukuForm.defaultProps = {
  onFormChange: noop,
  onClickEdit: noop,
};
