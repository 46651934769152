import { connect } from 'react-redux';
import ManageBankJournal from '../../../../views/Finance/Bookkeeping/BankJournal/ManageBankJournal.finance.view';
import { getBankJournalById, manageBankJournal, getAvailableFundRequests,
deleteJournalItem } from '../../../../states/thunks/finance.thunk';
import { getCoa } from '../../../../states/thunks/common.thunk';
import {
  getBankAccountOptions, getUnitOptions, getJournalCoaOptions, getCoaOptions,
} from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    bank_account: state.options.bank_account,
    user: state.user || {},
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleDeleteJournalDetail: payload => dispatch(deleteJournalItem(payload)),
    handleGetAvailableFundRequests: payload => dispatch(getAvailableFundRequests(payload)),
    handleGetBankAccount: payload => dispatch(getBankAccountOptions(payload)),
    handleGetJournalCoaOptions: payload => dispatch(getJournalCoaOptions(payload)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetBankJournal: payload => dispatch(getBankJournalById(payload)),
    handleManageBankJournal: (payload, goBack) => dispatch(manageBankJournal(payload, goBack)),
    handleGetUnitOptions: payload => dispatch(getUnitOptions({ show_pusat: true, ...payload })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBankJournal);
