import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { isEmpty, noop } from 'lodash';
import { Button } from '../../../components/base/index';


import PengisianKDForm from './components/PengisianKDForm.component'

export default class PengisianKD extends Component {
    constructor(props){
        super(props);
        //deklarasi fungsi
        this._onFormChange = this._onFormChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        // deklarasi state
        this.state= {
            
            form: {
              value: {},
              error: {},
            },
        };
    }
    componentDidMount(){
        const{ handleGetClasses} = this.props;
        handleGetClasses();
      }
    _onSubmit(){
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/pengisian-kd/tema');
    }

    _onFormChange(event) {
        const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          });
    }

    render(){
        const {form} = this.state;
        const { classes = {}} = this.props;
        return(
            <div className="student-list">
                <div className="budget__title">
                    <h1>B. Pengisian KD / Tema</h1>
                    <hr></hr>
                </div>
                <PengisianKDForm
                    classes={classes}
                    form = {form}
                    onFormChange = {this._onFormChange}
                />
                <div className="manage-registration__footer">
                    <Button
                        onClick={this._onSubmit}
                        title="Cari Data"
                    />
                </div>
            </div>
        );
    }
}

PengisianKD.propTypes = {
    
    handleGetStudent: PropTypes.func,
    history: PropTypes.object.isRequired,
    handleGetClasses: PropTypes.func,
  };
  PengisianKD.defaultProps = {
    handleGetClasses: noop,  
    handleGetStudent: noop,
  };
  