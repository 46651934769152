import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import klapper from '../../../assets/img/akademi/laporan_kesiswaan/klapper.png';
import laporan_8355 from '../../../assets/img/akademi/laporan_kesiswaan/laporan_8355.png';
import laporan_displiner from '../../../assets/img/akademi/laporan_kesiswaan/laporan_displiner.png';
import laporan_konseling from '../../../assets/img/akademi/laporan_kesiswaan/laporan_konseling.png';

export default class Report extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickNIS = this.onClickNIS.bind(this);
    this.onClickDaftarSiswa = this.onClickDaftarSiswa.bind(this);
    this.onClickKlapper = this.onClickKlapper.bind(this);
    this.onClickLaporan8355 = this.onClickLaporan8355.bind(this);
    this.onClickLaporanDispliner = this.onClickLaporanDispliner.bind(this);
    this.onClickLaporanKonseling = this.onClickLaporanKonseling.bind(this);
    this.onClickLaporanAbsensiKls = this.onClickLaporanAbsensiKls.bind(this);
    this.onClickLaporanPersensi = this.onClickLaporanPersensi.bind(this);
    this.onClickSearchSiswa = this.onClickSearchSiswa.bind(this);
  }

  onClickNIS() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/nis');
  }

  onClickDaftarSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas');
  }

  onClickKlapper() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/klapper')
  }

  onClickLaporan8355() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-8355')
  }

  onClickLaporanDispliner() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-displiner')
  }

  onClickLaporanKonseling() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-konseling')
  }

  onClickLaporanAbsensiKls() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-absensi-kelas')
  }
  onClickLaporanPersensi() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-persensi')
  }
  onClickSearchSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/search-siswa')
  }


  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickNIS}>
            <img src={budgeting} alt="DataKelas" />
            <h3>Nomor Induk Siswa</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickDaftarSiswa}>
            <img src={budgeting} alt="DaftarSiswa" />
            <h3>Daftar Siswa Kelas</h3>
          </button>
        </div> */}
        <div className="akademi__list">
          <button onClick={this.onClickKlapper}>
            <img src={klapper} alt="klapper" />
            <h3>Klapper</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporan8355}>
            <img src={laporan_8355} alt="laporan8355" />
            <h3>Laporan 8355</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporanDispliner}>
            <img src={laporan_displiner} alt="laporandispliner" />
            <h3>Laporan Displiner</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporanKonseling}>
            <img src={laporan_konseling} alt="laporankonseling" />
            <h3>Laporan Konseling</h3>
          </button>
        </div>
        {/* <div className="akademi__list">
          <button onClick={this.onClickLaporanAbsensiKls}>
            <img src={budgeting} alt="laporanabsensi" />
            <h3>Laporan Absensi Kelas</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickLaporanPersensi}>
            <img src={budgeting} alt="laporanpersensi" />
            <h3>Laporan Persensi</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickSearchSiswa}>
            <img src={budgeting} alt="searchsiswa" />
            <h3>Search Siswa</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
Report.propTypes = {
  history: PropTypes.object.isRequired,
};
