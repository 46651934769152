import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import pendapatan from '../../../../assets/img/finance/pendapatan.png';
import pengeluaran from '../../../../assets/img/finance/pengeluaran.png';
import rapbu from '../../../../assets/img/finance/rapbu.png';

export default class BudgetType extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(type, code) {
    const { location, history } = this.props;
    const { data } = location.state;
    const { account = [], unique_id, periode } = data;
    if (type === 'RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/rapbu', {
        type,
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
        prevPath: location.pathname,
        data
      });
    } else if (type === 'UPLOAD_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/upload-rapbu', {
        head: unique_id,
        periode,
        prevPath: location.pathname,
        data
      });
    } else {
      const selectedData = find(account, ['account_type', code]);
      history.push('/dashboard/keuangan/penganggaran/kelola-anggaran', {
        type,
        code,
        account_unique_id: selectedData.unique_id,
        head_unique_id: selectedData.head,
        prevPath: location.pathname,
        data
      });
    }
  }

  getClickHandler(type, code) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, type)) {
      this.clickHandlers[type] = () => this.onClick(type, code);
    }
    return this.clickHandlers[type];
  }

  render() {
    const { location, user } = this.props;
    const { user_group } = user;
    const { canEdit = true } = location.state.data;
    return (
      <div className="bookkeeping">
        {
          ((user_group.name === 'Keuangan Sekolah' || user_group.name === 'Bendahara Perwakilan' || user_group.name === 'Bendahara Pusat') && canEdit) &&
          [<div className="bookkeeping__list" key="bookeeping_list">
            <button onClick={this.getClickHandler('INCOME', 40000)}>
              <img src={pendapatan} alt="budget" />
              <h3>Pendapatan</h3>
            </button>
          </div>,
          <div className="finance__list" key="finance_list">
            <button onClick={this.getClickHandler('OUTCOME', 50000)}>
              <img src={pengeluaran} alt="pengeluaran" />
              <h3>Pengeluaran</h3>
            </button>
          </div>,
          ]
        }
        <div className="finance__list">
          <button onClick={this.getClickHandler('RAPBU')}>
            <img src={rapbu} alt="RAPBU" />
            <h3>RAPBU</h3>
          </button>
        </div>
        { ((user_group.name === 'Keuangan Sekolah' || user_group.name === 'Bendahara Perwakilan' || user_group.name === 'Bendahara Pusat') && canEdit) &&
          (<div className="finance__list">
            <button onClick={this.getClickHandler('UPLOAD_RAPBU')}>
              <img src={rapbu} alt="RAPBU" />
              <h3>Upload RAPBU</h3>
            </button>
          </div>)
        }
      </div>
    );
  }
}
BudgetType.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
