import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';
import PeminjamanBukuTable from '../components/PeminjamanBukuTable.component';

export default class PeminjamanBuku extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
      
        this.state = {
          form: {
            value: {
              pelajaran: [
                {
                  Matpel: '',
                  Kelas:'',
                   //divisi: '',
                  nominal: 0,
                }                                      
              ],
            },
            error: {
              pelajaran: '',
    
            },
          },
          
        };
      
      }


      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { pelajaran } = prevState.form.value;
          const list = prevState.form.value.pelajaran;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            pelajaran: list
          };
        });
      }

      _onClickAdd(data) {
        const { form } = this.state;
        const { pelajaran } = form.value;
        
        pelajaran.push({
          Kelas: '',
          Matpel:'',
          // divisi: '',
          nominal: 0,
          //period:'',
        });
        
        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              pelajaran,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
        console.log(pelajaran)
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/perpustakaan/data-perpustakaan/laporan-pengunjung/detail');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas} = this.props;
        const {pelajaran} = form.value
        return (
          <div>
          <div className="student-list">
               
                   <div className="nilai-kognitif__custom-form">
                   
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Input
                          name="namaSaudara1"
                          label="NIS/NIK"
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                         
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                          name="tahunajaran"
                          label="Unit"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Pilihan'}
                        />
                      </div>
                    </div>
                   
                </div>

                <div className="manage-registration__footer">
                    <Button
                    onClick={this._onSubmit}
                    title="Cari"
                    />
                </div>
                     
          </div>
          <div className="data-buku__header-content">
               
                   <div className="nilai-kognitif__custom-form">
                   
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Input
                        readOnly
                          name="namaSaudara1"
                          label="NIS/NIK"
                          style={{backgroundColor:'grey'}}
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                         
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Input
                        readOnly
                          name="tahunajaran"
                          label="Nama"
                          onChange={this._onFormChange}
                          style={{backgroundColor:'grey'}}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Pilihan'}
                        />
                      </div>
                    </div>
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Input
                        readOnly
                          name="namaSaudara1"
                          label="Kelas"
                          style={{backgroundColor:'grey'}}
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                         
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Input
                          type="date"
                          name="tahunajaran"
                          label="Tanggal Pinjam"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Pilihan'}
                        />
                      </div>
                    </div>
                   
                </div>
                <div className="data-buku__header-content">
                <PeminjamanBukuTable
                  onAddList={this._onClickAdd}
                  list={pelajaran}
                  onDeleteList={this._onDeleteList}
                  //deletepelajaran={this._deletepelajaran}
                  onChange={this._onFormChange}
                  //items={this.state.items} _handleDelete={this.delete.bind(this)}
                />
                </div>
                <div className="manage-registration__footer">
             <div className="manage-registration__button-wrapper">
              { <Button
               onClick={this._onSubmit}
              title="Cek Data"
            /> 
              }
              {
             <Button
             onClick={this._onClickCetak}
             title="Simpan Data"
           />  
           }

            </div>
            </div>

                
          </div>
        </div>
        );
      }
}
PeminjamanBuku.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PeminjamanBuku.defaultProps = {
    
  handleGetStudent: noop,
};
