import { connect } from 'react-redux';
import BeginningBalance from '../../../views/Finance/Bookkeeping/BeginningBalance.bookkeeping.view';
import { getBeginningBalance, saveBeginningBalance, postBeginningBalance } from '../../../states/thunks/finance.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBeginningBalance: payload => dispatch(getBeginningBalance(payload)),
    handleSaveBeginningBalance: payload => dispatch(saveBeginningBalance(payload)),
    handlePostBeginningBalance: payload => dispatch(postBeginningBalance(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BeginningBalance);
