import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination } from '../../../components/base/index';
import language from '../../../languages';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import { TABLE_LIST_PELANGGARAN_TABLE_FIELDS} from '../../../constants/Student/displiner.constant';
import  BuatPelanggaranForm  from '../components/BuatPelanggaranForm.component';

export default class BuatPelanggaran extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickEdit = this._onClickEdit.bind(this);
        this._onClickAddGroup = this._onClickAddGroup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._getViolationsGroupOptions= this._getViolationsGroupOptions.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
        
        const { user } = this.props;
        const { organizations_id } = user;
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              organizations_id: organizations_id
            },
            error: {},
          },
          param : {},
        };
      
      }
    
      componentDidMount() {
        const { location } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
        const paramTypes = ['levels', 'classes', 'classrooms'];
        if (isEdit && id) {
          this.setState({id:id})
          this._setForm(data.id);
        }
        paramTypes.forEach((type) => {
          this._getParamOptionsAcademics(type);
        });
        this._getViolationsGroupOptions();
      }

      async _setForm(id) {
        const { handleGetViolationsTable } = this.props;
        try {
          const payload = await handleGetViolationsTable({ id });
          this.setState({
            form: {
              value: {
                id: payload.id,
                name: payload.name,
                score: payload.score,
                sort: payload.sort,
                violations_group_id: payload.violations_group_id,
                violations_in_class: payload.violations_in_class,
                consequence_workflow: payload.consequence_workflow,
              },
              error: {
                // details: [],
              },
            },
          });
        } catch (err) {
          // err action
        }
      }

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics, user } = this.props;
        const { organizations_id } = user;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }


      async _getViolationsGroupOptions() {
        const { getViolationsGroupOptions } = this.props;
        const res = await getViolationsGroupOptions();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            prm_violations_group: res,
          },
        }));
       
      }

      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-pelanggaran',{isEdit: true });
      }
    
      _onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/pendaftaran', { data: val });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group', { isEdit: true });
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
      async onSubmit() {
        const { history, handleSaveViolationsTable } = this.props;
        const { form } = this.state;
        const { value } = form;        
        let canSaveA = false;
        const dataForm = form.value;
        // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
        //   canSaveA = true;
        // } else {
        //   canSaveA = false;
        // }
        // if (canSaveA == false){
        //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        // } else {
          
        const error = validateRequiredFields(form.value, ["name", "violations_group_id"]);
        this.setState((prevState) => ({
          form: {
            value: prevState.form.value,
            error,
          },
        }));

        if (isEmpty(error)) { 
          await handleSaveViolationsTable(value);
          history.push('/dashboard/kesiswaan/displiner/table-pelanggaran');
        }
        // }
      }

      async onEdit() {
        const { history, handleEditViolationsTable } = this.props;
        const { form } = this.state;
        const { value } = form;
          
        const error = validateRequiredFields(form.value, ["name", "violations_group_id"]);
        this.setState((prevState) => ({
          form: {
            value: prevState.form.value,
            error,
          },
        }));

        if (isEmpty(error)) {
          const res = await handleEditViolationsTable(value);        
          history.push('/dashboard/kesiswaan/displiner/table-pelanggaran');
        }
      }
    
      render() {
        const {form, list ={}, param} = this.state;
        const { handleGetKelas, handleDeleteKelas,coa } = this.props;
        return (
            <div className="add-budget">
        
            <form onSubmit={this.onSubmit}>
              <h1>Buat Jenis Pelanggaran</h1>
              <hr></hr>
              <BuatPelanggaranForm
                param = {param}
                form={form}
                onFormChange={this._onFormChange}
               
              />
              <div className="add-budget">
              {this.state.id?
                <Button
                  type="button"
                  title="Update"
                  onClick={this.onEdit}
                />:
                <Button
                  type="button"
                  title="Simpan"
                  onClick={this.onSubmit}
                />}
              </div>
             
    
            {/* <div className="budget__content">
              <div className="budget__search">
              <ListForm
              coa={coa}
              form={form}
              formFields={SEARCH_FIELDS}
              onFormChange={this._onFormChange}
              />
    
              </div>
              <ContentTable
                onSearchContent={handleGetKelas}
                tableFields={TABLE_PELANGGARAN_TABLE_FIELDS}
                onClickEdit={this._onClickEdit}
                onClickDelete={handleDeleteKelas}
              />
            </div> */}
          </form>
        
          </div>
        );
      }
}
BuatPelanggaran.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
BuatPelanggaran.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
