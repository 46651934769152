import { connect } from 'react-redux';
import Top10AnalysisDetail from '../../../views/Library/Top10Analysis/Top10AnalysisDetail.library.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(Top10AnalysisDetail);
