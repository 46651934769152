import { connect } from 'react-redux';
import TambahJadwalKaryawan from '../../../views/Kurikulum/Jadwal/TambahJadwalKaryawan.kurikulum.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { saveScheduleLecturer, listScheduleLecturer, editScheduleLecturer } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      handlesaveScheduleLecturer: (payload, goback) => dispatch(saveScheduleLecturer(payload, goback)),
      handlelistScheduleLecturer: (payload, goback) => dispatch(listScheduleLecturer(payload, goback)),
      handleeditScheduleLecturer: (payload, goback) => dispatch(editScheduleLecturer(payload, goback)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(TambahJadwalKaryawan);