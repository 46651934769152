import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../components/base';

export default class DataPendidikanForm extends Component {
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }
    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteList(idx) {
        const { onDeleteList, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteList(idx, fieldName);
      }  

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }
     
    render() {
        const {
          list, error, onChange,
          fieldName, pendidikan_formal_name,
        } = this.props;

        return (
            <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Level</th>
                  <th>Nama Sekolah</th>
                  <th>Kota</th>
                  <th>Jurusan</th>
                  <th>Tahun Masuk</th>
                  <th>Tahun Lulus</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_pendidikan_form__${idx}`}>
                <td>{idx + 1}</td>
                <td>
                  <Select
                    value={data.level}
                    placeholder="Pilih Tipe Jenjang Pendidikan"
                    data={pendidikan_formal_name}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='pendidikan_formal'
                    name='level'
                    onChange={this._onFormChange}
                  />
                </td>
                <td className="nama_sekolah">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pendidikan_formal'
                    data-input-type="text"
                    name="nama_sekolah"
                    onChange={onChange}
                    value={data.nama_sekolah}
                  />
                </td>
                <td className="kota">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pendidikan_formal'
                    data-input-type="text"
                    name="kota"
                    onChange={onChange}
                    value={data.kota}

                  />
                </td>
                <td className="jurusan">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pendidikan_formal'
                    data-input-type="text"
                    name="jurusan"
                    onChange={onChange}
                    value={data.jurusan}

                    
                  />
                </td>
                <td className="dari_tahun">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pendidikan_formal'
                    data-field-type="text"
                    name="dari_tahun"
                    onChange={onChange}
                    value={data.dari_tahun}

                  />
                </td>
                <td className="sampai_tahun">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='pendidikan_formal'
                    data-field-type="text"
                    name="sampai_tahun"
                    onChange={onChange}
                    value={data.sampai_tahun}

                  />
                </td>
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
        }
        <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
        </table>
        );
    }
}

DataPendidikanForm.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteList: PropTypes.func,
    onChange: PropTypes.func,
    pendidikan_formal_name: PropTypes.array,
};

DataPendidikanForm.defaultProps = {
    onAddList: noop,
    onDeleteList: noop,
    onChange: noop,
    pendidikan_formal_name:[],
};