import { connect } from 'react-redux';
import ListPendaftaranSiswa from '../../views/Student/ListPendaftaranSiswa.student.view';
import { getParamOptionsAcademics, getStudentOptions, getYearsOfEducationsOptions, getPeriodsOptions } from '../../states/thunks/options.thunk';
import { saveAnnouncement, listAnnouncement, deleteAnnouncement, uploadAnnouncementFile, 
          downloadAnnouncementFile, listManageRegis, uploadExcelStudents } from '../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      result: state.result,
      user: state.user || {},
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
      getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
      getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
      handleSaveAnnouncement: (payload, goback) => dispatch(saveAnnouncement(payload, goback)),
      handleListAnnouncement: (payload, goback) => dispatch(listAnnouncement(payload, goback)),
      handleListManageRegis: (payload, goback) => dispatch(listManageRegis(payload, goback)),
      handleDeleteAnnouncement: (payload, goback) => dispatch(deleteAnnouncement(payload, goback)),
      handleUploadAnnouncement: (payload, goback) => dispatch(uploadAnnouncementFile(payload, goback)),
      handleDownloadAnnouncement: (payload, goback) => dispatch(downloadAnnouncementFile(payload, goback)),
      handleUploadExcelStudents: (payload, goBack) => dispatch(uploadExcelStudents(payload, goBack)),
    
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(ListPendaftaranSiswa);