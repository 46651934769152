import { SET_ADDITIONAL_API_PAYLOAD_PARAMETER } from '../actions/common.action';

const defaultPayload = {};

export default function additionalApiPayload(state = defaultPayload, action) {
  switch (action.type) {
    case SET_ADDITIONAL_API_PAYLOAD_PARAMETER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
