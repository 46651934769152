import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      
     <div className="sampul-identitas-ktsp__header-content">
       
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="divPrint" style={{width:800, border: '0px solid black'}}>
       
                <div className="sampul-identitas-ktsp__content">
                  <div className="sampul-identitas-ktsp__header">
                    <div className="sampul-identitas-ktsp__header-title">
                      <div className="sampul-identitas-ktsp__header-title--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        LAPORAN
                      </div>
                      
                      <div className="sampul-identitas-ktsp__header-title--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        HASIL BELAJAR PESERTA DIDIK
                      </div>
                      <div className="sampul-identitas-ktsp__header-title--column">
                        {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                        SEKOLAH MENENGAH PERTAMA
                      </div>
                    </div>
                  </div>
                </div>
                     
                <table className="sampul-identitas-ktsp__table">
                  <tr>
                      <td></td>
                      <td>Nama Siswa</td>
                      <td>: Gange</td>
                  </tr>
                        
                  <tr>
                      <td></td>
                      <td>Nama Sekolah</td>
                      <td>: SMP KATOLIK RICCI</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>NSS</td>
                      <td>: 222222</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>NDS</td>
                      <td>: 222222</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>NPSN</td>
                      <td>: 222222</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Alamat Sekolah</td>
                      <td>: JL APA AJA</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td></td>
                      <td>Kode Pos</td>
                      <td>Telepon</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Kelurahan</td>
                      <td>: Gange</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Kecamatan</td>
                      <td>: Gange</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Kabupaten / Kota</td>
                      <td>: Gange</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Propinsi</td>
                      <td>: Gange</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Website</td>
                      <td>: Gange</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Email</td>
                      <td>: Gange</td>
                  </tr>
              </table>
        </div>
      </div>
    );
  }

}
export default class SampulIdentitasK13 extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
  SampulIdentitasK13.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  SampulIdentitasK13.defaultProps = {
    handleGetCashJournal: noop,
  };
  