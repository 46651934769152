import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../../components/base';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { FINANCE_UPLOAD_RAPBU_TABLE_FIELDS } from '../../../../constants/finance/budgeting/uploadRapbu.constant';

export default class UploadRapbu extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
  }

  onClickAdd() {
    const { history, location } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-upload-rapbu', {
      ...location.state
    });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/ubah-upload-rapbu', { data: val });
  }

  render() {
    const { handleGetUploadRapbu, handleDeleteUploadRapbu } = this.props;
    return (
      <div className="upload-rapbu">
        <Button
          title="Upload RAPBU"
          onClick={this.onClickAdd}
        />
        <div className="upload-rapbu__content">
          <ContentTable
            onSearchContent={handleGetUploadRapbu}
            tableFields={FINANCE_UPLOAD_RAPBU_TABLE_FIELDS}
            onClickEdit={this.onClickEdit}
            onClickDelete={handleDeleteUploadRapbu}
          />
        </div>
      </div>
    );
  }
}
UploadRapbu.propTypes = {
  handleGetUploadRapbu: PropTypes.func,
  handleDeleteUploadRapbu: PropTypes.func,
  history: PropTypes.object.isRequired,
};
UploadRapbu.defaultProps = {
  handleGetUploadRapbu: noop,
  handleDeleteUploadRapbu: noop,
};
