import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map,noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../../components/base/index';
import { Link } from 'react-router-dom';

export default class InvoiceNoPaid extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._checkboxTD = this._checkboxTD.bind(this);
        this._checkboxTR = this._checkboxTR.bind(this);
    }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

    _checkboxTD(){
        let td=[]
        let idx=0;

        for(let i =0; i<3; i++){
            idx++;
            td.push( 
                    <td><div className="individual__custom-form-row-check">
                          <div className="button-ekskul__button-wrapper">
                              <Input
                              type="checkbox"
                              name="senin"
                              onChange={this._onFormChange}
                              //  checked='true'
                              // checked='false'
                          /> 
                          </div>
                          &nbsp;<p>{idx} </p> &nbsp;
                        </div></td>)
        }
        return td
    }

    _checkboxTR(){
        let tr =[];
        for(let i =0; i<4 ; i++){
            tr.push(<tr>
                    <td></td>
                      {this._checkboxTD()}
                    </tr>)
        }
        return tr
    }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
          <div className="individual__custom-form">
            <div className="upload-nilai__custom-header">
            Invoice yang Belum Dilunasi/No Paid       
            </div>
            <div className="individual__body">
              <table className="individual__table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>No Invoice</th>
                    <th>Tanggal Invoice</th>
                    <th>Nominal</th>
                    <th>Keteragan</th>
                    <th>Jenis Invoice</th>
                    <th>Pembayaran/Rincian</th>
                  </tr>
                </thead>
                <tbody>
                  <td>No</td>
                  <td>No Invoice</td>
                  <td>Tanggal Invoice</td>
                  <td style={{color:'red'}}>20.000</td>
                  <td>Keteragan</td>
                  <td>Jenis Invoice</td>
                  <td> <Input
                          label="Uang SPP"
                          name="uang_spp"
                          onChange={onFormChange}
                          value={form.value.uang_spp}
                          /><br></br>
                          <Input
                              label="Uang Jemputan"
                              name="uang_jemputan"
                              onChange={onFormChange}
                              value={form.value.uang_jemputan}
                          /><br></br></td>
                </tbody>
              </table>
            </div>
          </div>
        );
     }
}
InvoiceNoPaid.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
InvoiceNoPaid.defaultProps = {
  onFormChange: noop,
};
