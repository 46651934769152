import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import budgeting from "../../assets/img/finance/anggaran.png";
import bookkeeping from "../../assets/img/bookkeeping/jurnal_bank.png";
import absensi_siswa from "../../assets/img/akademi/absensi_siswa.png";
import list_pendaftaran from "../../assets/img/akademi/list_pendaftaran.png";
import bimbingan_konseling from "../../assets/img/akademi/bimbingan_konseling.png";
import data_siswa from "../../assets/img/akademi/data_siswa.png";
import displiner from "../../assets/img/akademi/displiner.png";
import ekstrakulikuler from "../../assets/img/akademi/ekstrakulikuler.png";
import laporan_kesiswaan from "../../assets/img/akademi/laporan_kesiswaan.png";
import { map } from "lodash";

export default class Student extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPendaftaran = this.onClickPendaftaran.bind(this);
    this.onClickDataSiswa = this.onClickDataSiswa.bind(this);
    this.onClickLaporanKesiswaan = this.onClickLaporanKesiswaan.bind(this);
    this.onClickAbsensiSiswa = this.onClickAbsensiSiswa.bind(this);
    this.onClickDispliner = this.onClickDispliner.bind(this);
    this.onClickEkstrakulikuler = this.onClickEkstrakulikuler.bind(this);
    this.onClickBimbinganKonseling = this.onClickBimbinganKonseling.bind(this);
  }

  onClickPendaftaran() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/list-pendaftaran-siswa");
  }

  onClickDataSiswa() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/data-siswa");
  }

  onClickLaporanKesiswaan() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan");
  }

  onClickAbsensiSiswa() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa");
  }

  onClickDispliner() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/displiner");
  }

  onClickEkstrakulikuler() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/ekstrakulikuler");
  }

  onClickBimbinganKonseling() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa");
  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const permissions_name = [];

    map(permissions, (list, idx) => {
      permissions_name.push(list.name);
    });

    return (
      <div className="akademi">
        {permissions_name.indexOf("list_pendaftaran_siswa") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickPendaftaran}>
              <img src={list_pendaftaran} alt="DataKelas" />
              <h3>List Pendaftaran Siswa</h3>
            </button>
          </div>
        )}
        <div className="akademi__list">
          <button onClick={this.onClickDataSiswa}>
            <img src={data_siswa} alt="DataKelas" />
            <h3>Data Siswa</h3>
          </button>
        </div>
        {permissions_name.indexOf("absensi_harian") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickAbsensiSiswa}>
              <img src={absensi_siswa} alt="AbsensiSiswa" />
              <h3>Absensi Siswa</h3>
            </button>
          </div>
        )}
        {permissions_name.indexOf("tabel_pelanggaran") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickDispliner}>
              <img src={displiner} alt="displiner" />
              <h3>Displiner</h3>
            </button>
          </div>
        )}
        {permissions_name.indexOf("jenis_ekstrakurikuler") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickEkstrakulikuler}>
              <img src={ekstrakulikuler} alt="ekstrakulikuler" />
              <h3>Ekstrakulikuler</h3>
            </button>
          </div>
        )}
        {permissions_name.indexOf("bimbingan_konseling") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickBimbinganKonseling}>
              <img src={bimbingan_konseling} alt="bimbingankonseling" />
              <h3>Bimbingan Konseling</h3>
            </button>
          </div>
        )}
        {permissions_name.indexOf("klapper") > -1 == true && (
          <div className="akademi__list">
            <button onClick={this.onClickLaporanKesiswaan}>
              <img src={laporan_kesiswaan} alt="LaporanKesiswaan" />
              <h3>Laporan Kesiswaan</h3>
            </button>
          </div>
        )}
      </div>
    );
  }
}
Student.propTypes = {
  history: PropTypes.object.isRequired,
};
