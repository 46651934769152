import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop, isEqual } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import {  JURUSANKELAS_ADD_DATAJURUSAN_FORM_FIELDS  } from '../../../constants/Akademi/DataKelas/jurusankelas.constant';
import { Button, Pagination } from '../../../components/base';
import { Input, Select, Input2 } from '../../../components/base/index';
import { formatData } from '../../../utils/transformer.util';
import { LIST_TAMBAH_TAHUN_AJARAN_LIST_TABLE_FIELDS } from '../../../constants/Akademi/Akademi.constant';
import language from '../../../languages';
import { validateRequiredFields } from "../../../../src/utils/validation.util";

export default class TahunAjaran extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._setForm = this._setForm.bind(this);
    const initialContent = {
      loading: true,
      list: [],
      currentPage: 1,
      total: 1,
      totalPage: 1,
      error: false,
    };
    const {user} = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit
    this.state = {
      form: {
        value: {
          organizations_id: organizations_id,
          units_id: school_unit.id,
          start_date:"",
          end_date:"",
          plafon: [
            {
              
              tipePlafon: '',
               //divisi: '',
              nominal: 0,
            }                                      
          ],
        },
        error: {
          plafon: '',
          start_date: '',
          end_date: '',
        },
      },
      
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
   
    const paramTypes = ['classrooms', 'levels', 'classes'];
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }

    
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this.handleGetData();
    }
  }
  
  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  // on formchange untuk form
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
         
          <Button
            onClick={() => this.onClickEdit(data)}
            title='Edit'
          />
        </div>
      </td>
    );
  }

  onClickEdit(val) {
    if (val) {
      this.setState({ 
        isEdit: true,
        id:val.id
      });
      this._setForm(val.id);
    }
    // const { history } = this.props;
    // history.push('/dashboard/pengaturan/tahun-ajaran', { data: val, isEdit: true });
    // history.go(0)
  }

  async _setForm(id) {
    const { handleGetPeriods } = this.props;
    try {
      const payload = await handleGetPeriods({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            name_period: payload.name_period,
            start_date:payload.start_date,
            end_date:payload.end_date,
            
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;
    
    plafon.push({
      tipePlafon: '',
      // divisi: '',
      nominal: 0,
      //period:'',
    });
    
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }


  async handleGetData(payload = {}) {
    const { handleListPeriods, user } = this.props;
    const { school_unit, workingUnit, id, user_group, organizations_id } = user;
    const { levels_id } = school_unit;
    const { form } = this.state;

    const res = await handleListPeriods({...payload, organizations_id: organizations_id});

    this.setState({
      list: res,
    });
  }

  async onSubmit() {
    const { history, handleSavePeriods, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { form, list } = this.state;
    const { value } = form;        
    let canSaveA = false;
    const { dataForm, start_date, end_date } = form.value;
    const error = validateRequiredFields(form.value, ["start_date", "end_date"]);

    if(isEmpty(error)){
      let start_year = new Date(start_date).getFullYear();
      let end_year = new Date(end_date).getFullYear();
      let cek_year = start_year+1; 
      if(start_year == end_year || end_year!=cek_year) {
        error.name_period = "Format Tahun Ajaran salah."
      } else if(!isEmpty(list.result)) {
        map(list.result, (data, idx) => {
          if(start_year == data.name_period.substring(0, 4)) {
            error.name_period = "Tahun ajaran sudah ada."
          }
        })
      }
      if(start_year < 2021){
        error.name_period = "Tahun Ajaran tidak boleh kurang dari 2021."
      }
    }
    
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
    if (isEmpty(error)) {
      const res = await handleSavePeriods(value);
      if (res) {
        this.setState((prevState) => ({
          ...prevState,
          isEdit: false,
          form: {
            ...prevState.form,
            value: {
              organizations_id: organizations_id,
              units_id: school_unit.id,
            },
          },
        }));
        this.handleGetData();
      }
      // history.push('/dashboard/pengaturan/tahun-ajaran');
      // history.go(0)
    }
  }

  async onEdit() {
    const { history, handleEditPeriods, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { form, list, id } = this.state;
    const { value } = form;
    const { start_date, end_date } = form.value;
    const error = validateRequiredFields(form.value, ["start_date", "end_date"]);

    if(isEmpty(error)){
      let start_year = new Date(start_date).getFullYear();
      let end_year = new Date(end_date).getFullYear();
      let cek_year = start_year+1; 
      if(start_year == end_year || end_year!=cek_year) {
        error.name_period = "Format Tahun Ajaran salah."
      } else if(!isEmpty(list.result)) {
        var periods = list.result.filter(x => x.id != id)
        map(periods, (data, idx) => {
          if(start_year == data.name_period.substring(0, 4)) {
            error.name_period = "Tahun ajaran sudah ada."
          }
        })
      }
    }

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      const res = await handleEditPeriods(value);
      if (res) {
        this.setState((prevState) => ({
          ...prevState,
          isEdit: false,
          form: {
            ...prevState.form,
            value: {
              organizations_id: organizations_id,
              units_id: school_unit.id,
            },
          },
        }));
        this.handleGetData();
      }
      // history.push('/dashboard/pengaturan/tahun-ajaran');
      // history.go(0)
    }
  }

  deletePlafon(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({people: array});
    }
  }

  render() {
    const { list = {} } = this.state;
    const { form } = this.state;
    const {plafon} = form.value;
    
    var start_year = form.value.start_date ? new Date(form.value.start_date).getFullYear() : "";
    var end_year = form.value.end_date ? new Date(form.value.end_date).getFullYear() : "";
    form.value.name_period = start_year+"-"+end_year;

    return (
      <div className="add-budget">
          <Input2
            type="date"
            name="start_date"
            label="Mulai *"
            onChange={this._onFormChange}
            value={form.value.start_date}
            error={form.error.start_date ||''}
            isRequired
          />
          
          <Input2
            type="date"
            name="end_date"
            label="Selesai *"
            onChange={this._onFormChange}
            value={form.value.end_date}
            error={form.error.end_date ||''}
            isRequired
          />

          <Input
            type="text"
            name="name_period"
            label="Tahun Ajaran"
            onChange={this._onFormChange}
            value={form.value.name_period}
            error={form.error.name_period ||''}
            readOnly
          />
          {/* <IdentitasPlafon
            onAddList={this._onClickAdd}
            list={plafon}
            onDeleteList={this._onDeleteList}
            //deletePlafon={this._deletePlafon}
            onChange={this._onFormChange}
            //items={this.state.items} _handleDelete={this.delete.bind(this)}
          /> */}
          <div className="add-budget">
          {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
          </div>
          <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(LIST_TAMBAH_TAHUN_AJARAN_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(LIST_TAMBAH_TAHUN_AJARAN_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
      
    );
  }

    //   render() {
    //     return (
    //         <ul>
    //             {this.props.items.map(item => (
    //                 <li key={item.id}>{item.text}<button onClick={this._handleDelete.bind(this, item.id)}>Delete</button></li>
    //             ))}
    //         </ul>
    //     );
    // } 
}
TahunAjaran.propTypes = {
  handleAddBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
TahunAjaran.defaultProps = {
  handleAddBudget: noop,
  user: null,
};
