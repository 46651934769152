import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class ReportFinance extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickCashJournal = this.onClickCashJournal.bind(this);
    this.onClickBankJournal = this.onClickBankJournal.bind(this);
    this.onClickBigBook = this.onClickBigBook.bind(this);
    this.onClickAktifasi = this.onClickAktifasi.bind(this);
    this.onClickNeraca = this.onClickNeraca.bind(this);
  }

  onClickCashJournal() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/laporan/jurnal-kas');
  }

  onClickBankJournal() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/laporan/jurnal-bank');
  }
  onClickBigBook() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/laporan/buku-besar');
  }

  onClickAktifasi() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/laporan/laba-rugi');
  }
  onClickNeraca() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/laporan/neraca');
  }
  

  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickCashJournal}>
            <img src={budgeting} alt="Penganggaran" />
            <h3>Kas Harian</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBankJournal}>
            <img src={bookkeeping} alt="pembukuan" />
            <h3>Bank Harian</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBigBook}>
            <img src={bookkeeping} alt="pembukuan" />
            <h3>Buku Besar</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickAktifasi}>
            <img src={bookkeeping} alt="pembukuan" />
            <h3>Aktivasi Keuangan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickNeraca}>
            <img src={bookkeeping} alt="pembukuan" />
            <h3>Posisi Keuangan</h3>
          </button>
        </div>
      </div>
    );
  }
}
ReportFinance.propTypes = {
  history: PropTypes.object.isRequired,
};
