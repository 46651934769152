import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { Link } from 'react-router-dom';

export default function LeggerRekapForm(props) {
  const { onFormChange, form } = props;
  return (
    <div className="legger-periode__content">
                <div className="legger-periode__body">
                  <table className="legger-periode__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Lengkap</th>
                        <th>NIS</th>
                        <th>NISN</th>
                        <th>Semester</th>
                        <th colSpan={2}>Mata Pelajaran 1</th>
                        <th>JML</th>
                        <th>Rata-Rata</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td rowSpan={2}>1</td>
                        <td rowSpan={2}></td>
                        <td rowSpan={2}></td>
                        <td rowSpan={2}></td>
                        <td >1 (Ganjil)</td>
                        <td>80</td>
                        <td>B</td>
                        <td></td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>2 (Ganjil)</td>
                        <td>75</td>
                        <td>C</td>
                        <td></td>
                        <td>1</td>
                    </tr>

                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
              </div>
  );
}
LeggerRekapForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LeggerRekapForm.defaultProps = {
  onFormChange: noop,
};
