import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from "lodash";

export default class MataPelajaran extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickKodeMatpel = this.onClickKodeMatpel.bind(this);
    this.onClickDaftarGuru = this.onClickDaftarGuru.bind(this);
    this.onClickJadwalMatpel = this.onClickJadwalMatpel.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickPsik = this.onClickPsik.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickMingguan = this.onClickMingguan.bind(this);
    this.onClickDaftarNilaiReport = this.onClickDaftarNilaiReport.bind(this);
  }

  onClickKodeMatpel() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran');
  }

  onClickDaftarGuru() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/daftar-guru');
  }

  onClickJadwalMatpel() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickPsik() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-psik-afek');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }

  onClickMingguan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-mingguan');
  }

  onClickDaftarNilaiReport() {
    const {history} = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai/report');
  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const permissions_name = [];

    map(permissions, (list, idx) => {
      permissions_name.push(list.name);
    });
    return (
      <div className="finance">
        {permissions_name.indexOf("kode_mata_pelajaran") > -1 == true && (
        <div className="finance__list">
          <button onClick={this.onClickKodeMatpel}>
            <img src={budgeting} alt="kode" />
            <h3>Kode Mata Pelajaran</h3>
          </button>
        </div>
        )}
        {permissions_name.indexOf("jadwal_mata_pelajaran") > -1 == true && (
        <div className="finance__list">
          <button onClick={this.onClickJadwalMatpel}>
            <img src={budgeting} alt="kode" />
            <h3>Jadwal Mata Pelajaran</h3>
          </button>
        </div>
        )}
        {permissions_name.indexOf("daftar_guru") > -1 == true && (
        <div className="finance__list">
          <button onClick={this.onClickDaftarGuru}>
            <img src={budgeting} alt="kode" />
            <h3>Daftar Guru</h3>
          </button>
        </div>
        )}
        {/* <div className="finance__list">
          <button onClick={this.onClickNilaiKognitif}>
            <img src={budgeting} alt="NilaiKognitif" />
            <h3>Pengisian Nilai Kognitif</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPsik}>
            <img src={budgeting} alt="psikafek" />
            <h3>Pengisian Psik & Afek</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={budgeting} alt="observasiharian" />
            <h3>Pengisian Observasi Harian</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickMingguan}>
            <img src={budgeting} alt="observasimingguan" />
            <h3>Pengisian Observasi Mingguan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilaiReport}>
            <img src={budgeting} alt="daftarnilaireport"/>
            <h3>Daftar Nilai / Report</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
MataPelajaran.propTypes = {
  history: PropTypes.object.isRequired,
};
