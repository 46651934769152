import BuatGroupMatpel from '../../../views/Kurikulum/MataPelajaran/BuatGroupMatpel.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getMajorOptions, getSubjectTypeOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import {listStudentAttendance, listSubjectGroup, deleteSubjectGroup, saveSubjectGroup, editSubjectGroup, getSubjectGroup } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      result: state.result,
      classes: state.options.classes,
      user: state.user,
    }
  }

function mapDispatchToProps(dispatch){
    return{
    handleGetSubjectGroup: payload => dispatch(getSubjectGroup(payload)),
    handleSaveSubjectGroup: (payload, goback) => dispatch(saveSubjectGroup(payload, goback)),
    handleListSubjectGroup: (payload, goback) => dispatch(listSubjectGroup(payload, goback)),
    handleEditSubjectGroup: (payload, goback) => dispatch(editSubjectGroup(payload, goback)), 
    handleDeleteSubjectGroup: (payload, goback) => dispatch(deleteSubjectGroup(payload, goback)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (BuatGroupMatpel);