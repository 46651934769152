import NilaiKognitif from '../../../views/Kurikulum/Ulangan/NilaiKognitif.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptionsAcademics, getSubjectOptions, getLevels, getPeriodsOptions, getClassesOptions } from '../../../states/thunks/options.thunk';
import { listNilaiKognitif, listPsikAfek } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
    return{
        classes: state.options.classes,
        result: state.result,
        user: state.user || {},
        levels: state.options.levels,
    }
    
}


function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        handleGetLevels: payload => dispatch(getLevels(payload)),
        getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleListNilaiKognitif: (payload, goback) => dispatch(listNilaiKognitif(payload, goback)),
        handleListPsikAfek: (payload, goback) => dispatch(listPsikAfek(payload, goback)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps) (NilaiKognitif);