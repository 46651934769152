import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Pagination } from '../../../components/base';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import FormPengaturanSoal from './components/FormPengaturanSoal.component';
import TabelPengaturanSoal from './components/TabelPengaturanSoal.component';

export default class PengaturanSoal extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.state = {
      form: {
        value: {
          organization_id: '',
          periode: '',
          tanggal: '',
          number: '',
          upload: '',
          tabelpengaturansoal: [
            {
              judul: '',
              date: '',
              keterangan: '',
              mata_pelajaran: '',
              periode: '',
              upload: '',
            }
          ],
        },
        error: {
            tabelpengaturansoal: '',
        },
      },
      param: {},
    };
  }
  componentDidMount() {
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { tabelpengaturansoal } = form.value;
    tabelpengaturansoal.push({
        judul: '',
        date:'',
        keterangan:'',
        mata_pelajaran: '',
        periode: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelpengaturansoal,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  async onSubmit() {
    
  }
  async onEdit() {
  }
  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { tabelpengaturansoal } = prevState.form.value;
      const list = prevState.form.value.tabelpengaturansoal;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelpengaturansoal: list
      };
    });
  }
  _onClickSubmit() {
    const { history } = this.props;
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;          
      if(type==="file"){
        formattedValue = files[0]
      }
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
render() {
    const { form } = this.state;
    const { tabelpengaturansoal } = form.value;
    console.log("aa" + JSON.stringify(tabelpengaturansoal))
    return (
      <div className="student-list">
        <form>
          <h1>Pengaturan Soal</h1>
          <FormPengaturanSoal
            form={form}
            onChange={this._onFormChange}
          />
          <TabelPengaturanSoal
            list={tabelpengaturansoal}
            onAddList={this._onClickAdd}
            onFormChange={this._onFormChange}
            onDeleteList={this._onDeleteList}
          />
          <div className="student-list__header">
          <div className="student-list__button">
            <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
            />
          </div>
          <div className="student-list__button">
            <Button
                type="button"
                title="Kembali"
                onClick={this.onSubmit}
            />
          </div>
          </div>
         
        </form>
      </div>
      
    );
  }
}
PengaturanSoal.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PengaturanSoal.defaultProps = {
  getParamOptions: noop,
  handleGetStudent: noop,
};