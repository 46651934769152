import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, SearchSelect } from '../../../../components/base/index';


export default class StudentNumberPage1 extends Component {
   constructor(props){
       super(props);
      //  onFormChange = onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
            },
            error: {
            }
        }
    }
    }

  //  _onFormChange(e) {
  //   const { onChange } = this.props;
  //   let change = {}
  //   change[e.target.name] = e.target.value
  //   this.setState(change , () => { console.log(this.state) })

  //   onChange(e);
  // }

render(){
    const { onChange, form, classes, levels, param, onFormChange, data } = this.props;
    const academic_year = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ]
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={onFormChange}
                placeholder="Pilih Nama Siswa"
                value={form.value.students_id}
                // labelName="label"
                // valueName="value"
                rightIcon="icon-search"
                label= "Nama Siswa"
                />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="period"
              label="Tahun Ajaran"
              placeholder={'Pilihan'}
              data={academic_year}
              onChange={onFormChange}
              value={form.value.period}
              error={form.error.period || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Select
            name="levels"
            label="Unit"
            onChange={onFormChange}
            data={levels.list}
            placeholder="Pilihan"
            value={form.value.levels}
            error={form.error.levels || ''}
          />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
         
           <Select
              name="classes"
              label="Tingkat Kelas"
              onChange={onFormChange}
              data={param.classes}
              placeholder={'Pilihan'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Select
            name="classes"
            label="Kelas"
            onChange={onFormChange}
            data={param.classrooms}
            placeholder={'Pilihan'}
            value={form.value.classes}
            error={form.error.classes || ''}
          />
         
          </div>
          <div className="absensi-rekap__custom-form-column__field">
          
        
        </div>
      </div>
  </div>
          
          
          
   
     
    );
}
  
}
StudentNumberPage1.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
  levels: PropTypes.array,
};
StudentNumberPage1.defaultProps = {
  onChange: noop,
  classes: [],
  levels: [],
};
