export default class HTTPError extends Error {
  constructor(code, message, extras) {
    super(message);
    if (arguments.length >= 3 && extras) {
      Object.assign(this, extras);
    }
    this.name = 'HttpError';
    this.statusCode = code;
  }
}
