import { connect } from 'react-redux';
import Anekdot_SubTema from '../../../views/Kurikulum/Ulangan/Anekdot_SubTema.view';
import { getPrmTema}  from '../../../states/thunks/options.thunk';
import { listSubTema, deleteSubTema, saveSubTema, editSubTema, getSubTema } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetSubTema: payload => dispatch(getSubTema(payload)),
    handleSaveSubTema: (payload, goback) => dispatch(saveSubTema(payload, goback)),
    handleListSubTema: (payload, goback) => dispatch(listSubTema(payload, goback)),
    handleEditSubTema: (payload, goback) => dispatch(editSubTema(payload, goback)), 
    handleDeleteSubTema: (payload, goback) => dispatch(deleteSubTema(payload, goback)),
    getPrmTema: (payload) => dispatch(getPrmTema(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Anekdot_SubTema);


