import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class DaftarPesertaEkskulPage1 extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classes: '',
                classes_year: '',
                bentuk: '',
                urutan: '',
            },
            error: {
                classes: '',
                classes_year: '',
                urutan: '',
                bentuk: '',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classes } = this.props;
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Input
              name="classes_year"
              label="TahunAjaran"
              onChange={this._onFormChange}
              value={form.value.classes_year}
              error={form.error.classes_year || ''}
            />
           
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="bentuk"
              label="Bentuk"
              onChange={this._onFormChange}
            //   data={ classes.list}
              placeholder={'Pilihan'}
              value={form.value.bentuk}
              error={form.error.bentuk || ''}
            />
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          <Select
              name="classes"
              label="Tingkat/Kelas"
              onChange={this._onFormChange}
              data={ classes.list}
              placeholder={'Semua Kelas'}
              value={form.value.classes}
              error={form.error.classes || ''}
            />
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
            
          <Select
              name="urutan"
              label="Urutan"
              onChange={this._onFormChange}
            //   data={ classes.list}
              placeholder={'Pilihan'}
              value={form.value.urutan}
              error={form.error.urutan || ''}
            />
          </div>
      </div>
      
  </div>
          
          
          
   
     
    );
}
  
}
DaftarPesertaEkskulPage1.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
};
DaftarPesertaEkskulPage1.defaultProps = {
  onChange: noop,
  classes: [],
};
