export const STATUS_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Name',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'name' },
    ],
  };