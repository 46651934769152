import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../../components/base/index';
import { Link } from 'react-router-dom';

export default class LaporanInvoiceForm extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div className="laporan-keu__content">
        <div className="komposisi-siswa__custom-form">
            <div className="laporan-keu__custom-form-row">
                <div className="laporan-keu__custom-form-row__field">
                    <Select
                    name="jenis_invoice"
                    label="Jenis Invoice"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    placeholder={''}
                    value={form.value.jenis_invoice || ''}
                    error={form.error.jenis_invoice || ''}
                    />
                </div>
                <div className="laporan-keu__custom-form-column__field">
                    <Select
                    name="tahunajaran"
                    label="Jenis MU"
                    onChange={onFormChange}
                    // value={form.value.tempatLahirSaudara1 || ''}
                    // error={form.error.tempatLahirSaudara1 || ''}
                    placeholder={'Pilihan'}
                    />
                </div>
            </div>

            <div className="laporan-keu__custom-form-row">
                <div className="laporan-keu__custom-form-row__field">
                    <Input
                     type="text"
                     label="Nama Siswa/NIS"
                     onChange={onFormChange}   
                    />
                </div>
                <div className="laporan-keu__custom-form-column__field">
                    <Select
                     name="tahunajaran"
                     label="Tahun Ajaran"
                     onChange={onFormChange}
                     placeholder={'Pilihan'}
                    />
                </div>
            </div>
        </div>      
     </div>
  );
     }
}
LaporanInvoiceForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LaporanInvoiceForm.defaultProps = {
  onFormChange: noop,
};
