import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import { STUDENT_UNIT_2_FORM_FIELDS } from '../../../constants/student.constant';
import {  DAFTAR_NILAI_FORM_FIELDS} from '../../../constants/kurikulum/ulangan.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import  LembarMingguanForm from './components/LembarMingguanForm.component';
import  DaftarNilaiPraktekForm from './components/DaftarNilaiPraktekForm.component';
import  NilaiAfekTable from './components/NilaiAfekTable.component';
import NilaiKognitifForm from './components/NilaiKognitifForm.component';
import LembarMingguanForm2 from './components/LembarMingguanForm2.component';

export default class LembarObsMingguan extends PureComponent {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this._setForm = this._setForm.bind(this);
      this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
      const { user } = this.props;
      const { organizations_id } = user;
      
      this.state = {

        form: {
          value: {
            organizations_id: 12,
           },
          error: {},
        },
        param: {},
      };
    }

    componentDidMount() {
      const { location, getLevels, getclassrooms, user } = this.props;
      const { state = {} } = location;
      const {isEdit =  false, data} = state;
      const { id } = state.data || '';
      const paramTypes = ['classrooms', 'levels'];
      // console.log(state)
      if (isEdit && id) {
        // this.setState({students_id:id})
        // this.setState(prevState => ({
        //   form: {
        //     value: {
        //       ...prevState.form.value,
        //       students_id:id,
        //       organizations_id: 4,
        //     },
        //     error: {
        //       ...prevState.form.error,
        //     }
        //   }
        // }));
        this._setForm(id, data);
      }
      console.log(this.state)
      paramTypes.forEach((type) => {  
        // this._getParamOptionsAcademics(type);
      });
      // this._getStudentOptions();
      // this._getFolderOptions();
      
      // this._getclassrooms();
      // getclassrooms();
      // getLevels();
      // if (id) {
      //   this.setState({id:id})
      //   this._handleLoadData(id)
      // }
      
      this._getPeriodsOptions();       

    }

    async _getPeriodsOptions() {
      const { getPeriodsOptions } = this.props;
      const res = await getPeriodsOptions();
      this.setState(prevState => ({
        ...prevState,
        param: {
          ...prevState.param,
          periods: res,
        },
      }));
      
    }

    async onEdit() {
      const { history, handleEditLembarObsMingguan } = this.props;
      const { form } = this.state;
      const { value } = form;
      let canSaveA = false;
      let canSaveB = false;
      const dataTabel = value.pelajaran;
      const dataForm = form.value;
      let a;
        await handleEditLembarObsMingguan(value);
        history.push('/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-mingguan');
      // }
     
    }

    async _setForm(id, data = {}) {
      const { handleGetLembarObsMingguan, user } = this.props;
      const { organizations_id } = user;
      try {
        const payload = await handleGetLembarObsMingguan({ id });
        console.log(id)
        this.setState({
          form: {
            value: {
              id: payload.id,
              students_id :payload.students_id,
              assessment_date :payload.assessment_date,
              academics_type :payload.academics_type,
              semester :payload.semester,
              content_type :payload.content_type,
              indicator_value :payload.indicator_value,
              observation_result :payload.observation_result,
              expectation :payload.expectation,
              teacher_notes :payload.teacher_notes,
              indicator_value_kognitif :payload.indicator_value_kognitif,
              observation_result_kognitif :payload.observation_result_kognitif,
              expectation_kognitif :payload.expectation_kognitif,
              organizations_id: 12,
              period: payload.period
             
             
              
            },
            error: {
              // details: [],
            },
          },
        });
      } catch (err) {
        // err action
      }
    }

    async onSubmit() {
      const { history, handleSaveLembarObsMingguan } = this.props;
      const { form } = this.state;
      const { value } = form;        
      let canSaveA = false;
      const dataForm = form.value;
        await handleSaveLembarObsMingguan(value);
        history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
        history.go(0)
    }
  
    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        files,
      } = event.target;
      const { inputType = 'text' } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        if (inputType === 'number') {
          formattedValue = commaFormatted(value);
        }
        if (inputType === 'file') {
          [formattedValue] = files;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              [name]: formattedValue,
            },
            error: {
              ...prevState.form.error,
              [name]: '',
            },
          },
        };
      });
    }

    _onClickNext() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit');
      }
    
  
    render() {
      const { location, getLevels, getclassrooms, user } = this.props;      
      const { state = {} } = location;
      const {isEdit =  false, data} = state;
      const { id } = state.data || '';
      const { form, param } = this.state;
      console.log(data)
      return (
        
        <div className="student-list">
          <div className="budget__title">
          <h1>Pengisian Lembar Observasi </h1>
          <hr></hr>
          </div>
          <form onSubmit={this._onSubmit}>

            <div className="result__content">
            <LembarMingguanForm
                form={form}
                data={data}
                param={param}
                onFormChange={this._onFormChange}
            />
            </div>
            <br></br>
            <br></br>
            
            
          <LembarMingguanForm2
              form={form}
              onFormChange={this._onFormChange}
            />
        
        <div className="manage-registration__footer">
        {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
            }
                </div>
                </form>
        </div>

      );
    }
  }
  LembarObsMingguan.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
LembarObsMingguan.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
