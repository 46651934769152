import LaporanRPPdetail from '../../../views/Kurikulum/RencanaNilai/LaporanRPPdetail.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
    };
}

export default connect(null, mapDispatchToProps) (LaporanRPPdetail);