import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import { Button, Pagination, Input, Select } from "../../../../components/base";
import {
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import language from "../../../../languages";
import { JENIS_SPP_FORM_TABLE } from "../../../../constants/kurikulum/kurikulum.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JenisSPP extends PureComponent {
  constructor(props) {
    super(props);
    this._renderButtons = this._renderButtons.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onFormChange = this._onFormChange.bind(this);

    const { user } = this.props;
    const { organizations_id } = user;

    this.state = {
      page: 1,
      list: [],
      listAmount: 5,
      form: {
        value: {
          organizations_id: 12,
        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }

    this.handleGetData();
  }

  async handleGetData(payload = {}) {
    const { handleListJenisSPP, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
    const res = await handleListJenisSPP({ ...payload, organizations_id: 12 });

    this.setState({
      list: res,
    });
  }

  async _setForm(id) {
    const { handleGetJenisSPP } = this.props;
    try {
      const payload = await handleGetJenisSPP({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            coa: payload.coa,
            is_discount: payload.is_discount,
            name: payload.name,
            nick_name: payload.nick_name,
          },
          error: {
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _renderButtons(data) {
    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          <Button onClick={() => this.onClickDelete(data)} title="Delete" />
        </div>
      </td>
    );
  }

  async onClickSubmit() {
    const { history, handleSaveJenisSPP } = this.props;
    const { form } = this.state;
    const { value } = form;
    await handleSaveJenisSPP(value);
    history.push("/dashboard/keuangan/spp/nama-pembayaran");
    history.go(0);
  }

  async onClickUpdate() {
    const { history, handleEditJenisSPP } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditJenisSPP(value);
    history.push("/dashboard/keuangan/spp/nama-pembayaran");
    history.go(0);
  }

  async onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/keuangan/spp/nama-pembayaran", {
      data: val,
      isEdit: true,
    });
    history.go(0);
  }

  async onClickDelete(id) {
    const { handleDeleteJenisSPP } = this.props;
    const res = await handleDeleteJenisSPP(id);
    if (res) {
      this.handleGetData();
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  render() {
    const { form, list = {} } = this.state;
    const is_discount = [
      { label: 'N', value: '0'},
      { label: 'Y', value: '1'},
    ]
    return (
      <div className="add-budget">
        <div className="budget__title">
          <h1>Nama Pembayaran </h1>
          <hr></hr>
        </div>
        <form onClickSubmit={this.onClickSubmit}>
          <div className="kode-matpel__custom-form">
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <Input
                  type="number"
                  name="coa"
                  label="Kode"
                  onChange={this._onFormChange}
                  value={form.value.coa}
                  error={form.error.coa || ""}
                />
              </div>

              <div className="kode-matpel__custom-form-column__field">
                <div className="data-pelanggaran__custom-form-row__field" style={{width: "130%"}}>
                  <Input
                    // style={{ width: "180%"}}
                    type="text"
                    name="name"
                    label="Nama Pembayaran"
                    onChange={this._onFormChange}
                    value={form.value.name}
                    error={form.error.name || ''}
                  />
                  <div className="data-pelanggaran__custom-form-row__field"></div>
                  <Input
                    style={{ width: "50%"}}
                    type="text"
                    name="nick_name"
                    label="Nama Singkat"
                    onChange={this._onFormChange}
                    value={form.value.nick_name}
                    error={form.error.nick_name || ''}
                    maxLength={3}
                  />
                </div>
              </div>
            </div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <Select
                  label="Diskon"
                  placeholder="Pilihan"
                  name='is_discount'
                  data={is_discount}
                  onChange={this._onFormChange}
                  value={form.value.is_discount}
                  error={form.error.is_discount || ''}
                />
              </div>

              <div className="kode-matpel__custom-form-column__field"></div>
            </div>
          </div>
          <div className="student-list__header">
            <div className="student-list__button">
              {this.state.id ? (
                <Button type="button" title="Update" onClick={this.onClickUpdate} />
              ) : (
                <Button
                  type="button"
                  title="Simpan"
                  onClick={this.onClickSubmit}
                />
              )}
            </div>
          </div>
        </form>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(JENIS_SPP_FORM_TABLE.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(JENIS_SPP_FORM_TABLE.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>
                          {idx + 1}
                        </td>
                      );
                    }

                    if (field.attribute === "is_discount") {
                      console.log(list.result[idx].is_discount)
                      return (
                        <td key={`table_${fieldIdx}_${idx}`} style={{textAlign: "center"}}>
                          { list.result[idx].is_discount == "1" ? "Y" : "N" }
                        </td>
                      );
                    }
                    return (
                      <td
                        className={field.type === "number" ? "nominal" : ""}
                        key={`table_${fieldIdx}_${idx}`}
                      >
                        {formatData(data, field)}
                      </td>
                    );
                  })}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
JenisSPP.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
JenisSPP.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
