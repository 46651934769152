import { connect } from 'react-redux';
import CreateInvoice from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoice.view';
import {getParamOptionsAcademics, getClassesOptions, getPeriodsOptions} from '../../../../states/thunks/options.thunk';
import { listStudentInvoice} from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},

  }
}

function mapDispatchToProps(dispatch) {
  return {
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentInvoice: (payload, goback) => dispatch(listStudentInvoice(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
