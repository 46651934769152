import { connect } from 'react-redux';
import PengisianKompetensiDasar from '../../../views/Kurikulum/Ulangan/PengisianKompetensiDasar.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getPrmAspectOptions } from '../../../states/thunks/options.thunk';
import { saveKompetensiDasar, editAttitudeIndicator,getAttitudeIndicator} from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleSaveKompetensiDasar: (payload, goback) => dispatch(saveKompetensiDasar(payload, goback)),
    handleGetAttitudeIndicator: payload => dispatch(getAttitudeIndicator(payload)),
    handleEditAttitudeIndicator: (payload, goBack) => dispatch(editAttitudeIndicator(payload, goBack)),
    getPrmAspectOptions: (payload) => dispatch(getPrmAspectOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PengisianKompetensiDasar);
