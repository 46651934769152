import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import bankJournal from '../../../assets/img/bookkeeping/jurnal_bank.png';
import bankCash from '../../../assets/img/bookkeeping/jurnal_kas.png';

export default class Personalia extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
    this.onClickDataStaff = this.onClickDataStaff.bind(this);
    this.onClickpayroll = this.onClickpayroll.bind(this);
    this.onClickCreateInvoice = this.onClickCreateInvoice.bind(this);
    this.onClickabsensiPegawai = this.onClickabsensiPegawai.bind(this);
  }
  

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/${link}`);
  }


  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }
  onClickDataStaff() {
    const { history } = this.props;
    history.push(`/dashboard/personalia/data-staff`);

  }
  onClickpayroll() {
    const { history } = this.props;
    history.push(`/dashboard/personalia/data-payroll`);

  }

  onClickabsensiPegawai() {
    const { history } = this.props;
    history.push(`/dashboard/personalia/absensi-pegawai`);

  }

  onClickCreateInvoice() {
    const {history} = this.props;
    history.push(`/dashboard/keuangan/spp/create-invoice`);
  }

  render() {
    return (
      <div className="bookkeeping">
        <div className="bookkeeping__list">
          <button onClick={this.onClickDataStaff}>
            <img src={bankJournal} alt="Table Kontrol Keuangan" />
            <h3>Data Staff</h3>
          </button>
        </div>
        
        <div className="bookkeeping__list">
          <button onClick={this.onClickpayroll}>
            <img src={bankJournal} alt="create invoice" />
            <h3>Payroll</h3>
          </button>
        </div>
        <div className="bookkeeping__list">
          <button onCLick={this.onClickabsensiPegawai}>
            <img src={bankJournal} alt="absensiPegawai" />
            <h3>Absensi Pegawai</h3>
          </button>
        </div>
        
        
      </div>
    );
  }
}
Personalia.propTypes = {
  history: PropTypes.object.isRequired,
};
