import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, has, find } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_HARIAN } from "../../../constants/student.constant";
import { ABSENSI_HARIAN_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Input,
  Checkbox,
  Pagination,
  Select,
} from "../../../components/base/index";
import language from "../../../languages";
import { PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { PENGISIAN_OBS_MINGGUAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianObsHarian extends Component {
  constructor(props) {
    super(props);
    this._onClickUpload = this._onClickUpload.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    // this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._setForm = this._setForm.bind(this);
    this._renderButtonsObsHarian = this._renderButtonsObsHarian.bind(this);
    this._renderButtonsObsMingguan = this._renderButtonsObsMingguan.bind(this);
    this._renderButtonsDaftarNilai = this._renderButtonsDaftarNilai.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          organizations_id: 12,
          nilaikognitif: [
            {
              id: "",
              score_students: "",
              students_id: "",
            },
          ],
        },
        error: {
          classes: "",
          levels: "",
          nilaikognitif: "",
        },
      },
      param: {},
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "levels", "classrooms"];
    // console.log(state)

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    } else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getSubjectOptions();
    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetNilaiKognitif } = this.props;
    try {
      const payload = await handleGetNilaiKognitif({ id });
      this.setState({
        filters: {
          classrooms_id: payload.classrooms_id,
          period: payload.period,
          date: payload.date,
        },
        form: {
          value: {
            nilaikognitif: map(payload.nilaikognitif, (detail) => ({
              id: detail.id,
              score_students: detail.score_students,
              students_id: detail.students_id,
            })),
          },
          error: {
            nilaikognitif: [],
          },
        },
      });
    } catch (err) {}
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { menu } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListPengisianObsHarian,
            handleListPengisianObsMingguan,
            handleListDaftarNilaiReport,
            handleListDaftarNilaiReportMan,
            handleListDaftarNilaiReportEng,
            user,
          } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;
          let res = {};
          if (menu === undefined) {
            res = await handleListDaftarNilaiReport({
              ...params,
              organizations_id: 12,
              // units_id,
              filters,
            });
            // res = await handleListPengisianObsHarian({
            //   ...params,
            //   organizations_id: 12,
            //   // units_id,
            //   filters,
            // });
          } else if (menu === "1") {
            res = await handleListPengisianObsHarian({
              ...params,
              organizations_id: 12,
              // units_id,
              filters,
            });
          } else if (menu === "2") {
            res = await handleListPengisianObsMingguan({
              ...params,
              organizations_id: 12,
              // units_id,
              filters,
            });
          } else if (menu === "3") {
            res = await handleListDaftarNilaiReport({
              ...params,
              organizations_id: 12,
              // units_id,
              filters,
            });
          } else if (menu === "12") {
            res = await handleListDaftarNilaiReportEng({
              ...params,
              organizations_id: 12,
              // units_id,
              filters,
            });
          } else if (menu === "5") {
            res = await handleListDaftarNilaiReportMan({
              ...params,
              organizations_id: 12,
              // units_id,
              filters,
            });
          }
          // console.log(res);

          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = res;

          if (result) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  onClickEditObsHarian(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-harian",
      { data: val, isEdit: true }
    );
  }

  onClickEditObsMingguan(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-mingguan",
      { data: val, isEdit: true }
    );
  }

  onClickEditDaftarNilai(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report",
      { data: val, isEdit: true }
    );
  }
  onClickViewDaftarNilai(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/cetak-raport",
      { data: val, isEdit: true }
    );
  }

  onClickEditDaftarNilaiEng(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report-inggris",
      { data: val, isEdit: true }
    );
  }
  onClickViewDaftarNilaiEng(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/cetak-raport-inggris",
      { data: val, isEdit: true }
    );
  }

  onClickEditDaftarNilaiMan(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report-mandarin",
      { data: val, isEdit: true }
    );
  }
  onClickViewDaftarNilaiMan(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/cetak-raport-mandarin",
      { data: val, isEdit: true }
    );
  }

  onClickDelete(idx) {
    this.setState((prevState) => {
      const { nilaikognitif } = prevState.form.value;
      const list = prevState.form.value.nilaikognitif;
      list.splice(idx, 1);
      return {
        ...prevState,
        nilaikognitif: list,
      };
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: 12 },
          });
        }
        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  // async handleGetData(payload = {}) {
  //   const { handleListStudentAttendance, handleListAnnouncementStudent, user } = this.props;
  //   const { units_id, workingUnit, id, user_group, profile } = user;
  //   let unit_id = units_id;

  //   if (unit_id === null && workingUnit && workingUnit.id) {
  //     unit_id = workingUnit.id;
  //   }
  //     const res = await handleListStudentAttendance({...payload, user_id: id, organizations_id: 12});

  //   this.setState(prevState => ({
  //     ...prevState,
  //     form: {
  //       ...prevState.form,
  //       value: {
  //         ...prevState.form.value,
  //         absensiharian: res,
  //       }
  //     }
  //   }));
  // }
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: 12,
          });
        }
        this._onSearchContent();
      }
    );
  }

  _renderButtonsObsHarian(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEditObsHarian(data)}
            title="Edit"
          />
        </div>
      </td>
    );
  }

  _renderButtonsObsMingguan(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEditObsMingguan(data)}
            title="Edit"
          />
        </div>
      </td>
    );
  }

  _renderButtonsDaftarNilai(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEditDaftarNilai(data)}
            title="Edit"
          />
          <Button
            onClick={() => this.onClickViewDaftarNilai(data)}
            title="View"
          />
        </div>
      </td>
    );
  }

  _renderButtonsDaftarNilaiEng(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEditDaftarNilaiEng(data)}
            title="Edit"
          />
          <Button
            onClick={() => this.onClickViewDaftarNilaiEng(data)}
            title="View"
          />
        </div>
      </td>
    );
  }

  _renderButtonsDaftarNilaiMan(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEditDaftarNilaiMan(data)}
            title="Edit"
          />
          <Button
            onClick={() => this.onClickViewDaftarNilaiMan(data)}
            title="View"
          />
        </div>
      </td>
    );
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian");
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      nominal: 0,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/tambah-pengisian-psik-afek", {
      isEdit: true,
    });
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif",
      { isEdit: true }
    );
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  async onSubmit() {
    const { history, handleSaveAttendance } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveAttendance(value);
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap");
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }
  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 12;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
    } = this.state;
    const { absensiharian } = form.value;
    const componentRef = React.createRef();
    const {
      classrooms_id = "",
      semester = "",
      assessment_date = "",
      academics_type = "",
    } = filters;
    const { location } = this.props;
    const loadingProps = { show: content.loading };
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const semester_data = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];
    const jenis = [
      { label: "Akademis", value: "akademis" },
      { label: "Non Akademis", value: "nonakademis" },
    ];
    const menu = [
      // { label: "Observasi Harian", value: "1" },
      // { label: 'Observasi Mingguan', value: '2'},
      { label: "Daftar Nilai/Report", value: "3" },
      { label: "Daftar Nilai/Report (Inggris)", value: "12" },
      { label: "Daftar Nilai/Report (Mandarin)", value: "5" },
    ];

    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              label="Tahun Ajaran"
              name="period"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              data={param.periods}
              value={form.value.period}
              error={form.error.period || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="semester"
              label="Semester"
              placeholder={"Pilihan"}
              onChange={this._onChangeFilter}
              data={semester_data}
              value={semester}
              error={form.error.semester || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="levels_id"
              label="Unit"
              placeholder="Pilih Unit"
              data={param.levels}
              // disabled
              value={form.value.levels_id}
              onChange={this._onFormChange}
              error={form.error.levels_id || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="text"
              name="classrooms_id"
              label="Kelas"
              placeholder="Pilih Kelas"
              data={param.classrooms}
              onChange={this._onChangeFilter}
              value={classrooms_id}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              type="date"
              name="assessment_date"
              label="Tanggal Penilaian"
              onChange={this._onChangeFilter}
              value={assessment_date}
              error={form.error.date || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="text"
              name="academics_type"
              label="Jenis"
              placeholder={"Pilihan"}
              data={jenis}
              onChange={this._onChangeFilter}
              value={academics_type}
              error={form.error.academics_type || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="menu"
              label="Menu"
              onChange={this._onFormChange}
              placeholder={"Pilihan"}
              value={form.value.menu}
              error={form.error.menu || ""}
              data={menu}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
          </div>
        </div>
        {/* {form.value.menu == null && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}> */}
                      {
                      // map(
                      //   PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.value,
                      //   (field, fieldIdx) => {
                      //     if (fieldIdx === 0) {
                      //       return (
                      //         <td key={`table_index_${fieldIdx}_${idx}`}>
                      //           {idx + 1}
                      //         </td>
                      //       );
                      //     }
                      //     if (field.type === "link") {
                      //       return (
                      //         <td key={`table_${fieldIdx}_${idx}`}>
                      //           <Link
                      //             to={
                      //               "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                      //             }
                      //           >
                      //             {list.name}
                      //           </Link>
                      //         </td>
                      //         // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      //       );
                      //     }
                      //     return (
                      //       <td
                      //         className={
                      //           field.type === "number" ? "nominal" : ""
                      //         }
                      //         key={`table_${fieldIdx}_${idx}`}
                      //       >
                      //         {formatData(list, field)}
                      //       </td>
                      //     );
                      //   }
                      // )
                      }
                      {/* {this._renderButtonsObsHarian(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )} */}

        {form.value.menu == null && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {
                    map(
                      DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )
                    }
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsDaftarNilai(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {form.value.menu === "1" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsObsHarian(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {form.value.menu === "2" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      PENGISIAN_OBS_MINGGUAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        PENGISIAN_OBS_MINGGUAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsObsMingguan(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {form.value.menu === "3" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsDaftarNilai(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {form.value.menu === "12" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsDaftarNilaiEng(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {form.value.menu === "5" && (
          <>
            <div className="fund-request__content">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    {map(
                      DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.label,
                      (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {map(
                        DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS.value,
                        (field, fieldIdx) => {
                          if (fieldIdx === 0) {
                            return (
                              <td key={`table_index_${fieldIdx}_${idx}`}>
                                {idx + 1}
                              </td>
                            );
                          }
                          if (field.type === "link") {
                            return (
                              <td key={`table_${fieldIdx}_${idx}`}>
                                <Link
                                  to={
                                    "/dashboard/kurikulum/ulangan/pengisian-observasi-harian"
                                  }
                                >
                                  {list.name}
                                </Link>
                              </td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                            );
                          }
                          return (
                            <td
                              className={
                                field.type === "number" ? "nominal" : ""
                              }
                              key={`table_${fieldIdx}_${idx}`}
                            >
                              {formatData(list, field)}
                            </td>
                          );
                        }
                      )}
                      {this._renderButtonsDaftarNilaiMan(list)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className="user-management__table-footer"></div>
      </div>
    );
  }
}
PengisianObsHarian.propTypes = {
  getParamOptions: PropTypes.func,
  history: PropTypes.object.isRequired,
  // handleGetNilaiKognitif: PropTypes.func,
};
PengisianObsHarian.defaultProps = {
  getParamOptions: noop,
  // handleGetNilaiKognitif: noop,
};
