import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import {
  Input,
  Select,
  Textarea,
  Checkbox,
} from "../../../components/base/index";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { GENDER } from "../../../variables/common.variable";

export default function BuatEkskulForm(props) {
  const { onFormChange, form } = props;
  return (
    <div className="buat-ekskul__custom-form">
      <div className="buat-ekskul__custom-form-column">
        <div className="buat-ekskul__custom-form-row__field">
          <Input
            type="text"
            name="code_ekskul"
            label="Kode Ekskul *"
            onChange={onFormChange}
            value={form.value.code_ekskul}
            error={form.error.code_ekskul || ""}
            isRequired
            style={{width: "470px"}}
          />

          <div className="buat-ekskul__custom-form-row__field"></div>
          <Input
            type="text"
            name="name"
            label="Nama Ekskul *"
            onChange={onFormChange}
            value={form.value.name}
            error={form.error.name || ""}
            isRequired
          />
        </div>

        <div className="buat-ekskul__custom-form-row">
          <div className="buat-ekskul__custom-form-data-row">
            <Input
              type="text"
              name="output_raport_ekskul"
              label="Output Raport Ekskul *"
              onChange={onFormChange}
              value={form.value.output_raport_ekskul}
              error={form.error.output_raport_ekskul || ""}
              isRequired
              style={{width: "470px"}}
            />
          </div>

          <div className="buat-ekskul__custom-font">
            <div className="buat-ekskul__custom-font__field" style={{paddingLeft: "15px"}}>Hari Ekskul *</div>
            <div className="buat-ekskul__custom-checkbox__field">
              <div className="buat-ekskul__custom-form-row">
                &nbsp;
                <div className="button-ekskul__button-wrapper">
                  <Input
                    type="checkbox"
                    name="day"
                    onChange={onFormChange}
                    //  checked='true'
                    checked={
                      form.value.day && form.value.day.indexOf("Senin") > -1
                    }
                    label="Senin"
                    value="Senin"
                    error={form.error.day || ""}
                  />
                </div>
              </div>

              <div className="buat-ekskul__custom-checkbox__field">
                <div className="buat-ekskul__custom-form-row">
                  &nbsp;
                  <div className="button-ekskul__button-wrapper">
                    <Input
                      type="checkbox"
                      onChange={onFormChange}
                      name="day"
                      label="Selasa"
                      //checked='false'
                      // checked='false'
                      checked={
                        form.value.day && form.value.day.indexOf("Selasa") > -1
                      }
                      value="Selasa"
                      error={form.error.day || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="buat-ekskul__custom-checkbox__field">
                <div className="buat-ekskul__custom-form-row">
                  &nbsp;
                  <div className="button-ekskul__button-wrapper">
                    <Input
                      type="checkbox"
                      onChange={onFormChange}
                      name="day"
                      label="Rabu"
                      //checked='false'
                      // checked='false'
                      checked={
                        form.value.day && form.value.day.indexOf("Rabu") > -1
                      }
                      value="Rabu"
                      error={form.error.day || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buat-ekskul__custom-form-row">
          <div className="buat-ekskul__custom-form-data-row">
            <Input
              type="number"
              name="class_total"
              label="Jumlah Kelas *"
              onChange={onFormChange}
              value={form.value.class_total}
              error={form.error.class_total || ""}
              isRequired
              style={{width: "470px"}}
            />
          </div>
          <div className="buat-ekskul__custom-font">
            <div className="buat-ekskul__custom-font__field"></div>
            <div className="buat-ekskul__custom-checkbox__field">
              <div className="buat-ekskul__custom-form-row">
                &nbsp;
                <div className="button-ekskul__button-wrapper">
                  <Input
                    type="checkbox"
                    onChange={onFormChange}
                    name="day"
                    label="Kamis"
                    //checked='false'
                    // checked='false'
                    checked={
                      form.value.day && form.value.day.indexOf("Kamis") > -1
                    }
                    value="Kamis"
                    error={form.error.day || ""}
                  />
                </div>
              </div>

              <div className="buat-ekskul__custom-checkbox__field">
                <div className="buat-ekskul__custom-form-row">
                  &nbsp;
                  <div className="button-ekskul__button-wrapper">
                    <Input
                      type="checkbox"
                      onChange={onFormChange}
                      name="day"
                      label="Jumat"
                      //checked='false'
                      // checked='false'
                      checked={
                        form.value.day && form.value.day.indexOf("Jumat") > -1
                      }
                      value="Jumat"
                      error={form.error.day || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="buat-ekskul__custom-checkbox__field">
                <div className="buat-ekskul__custom-form-row">
                  &nbsp;
                  <div className="button-ekskul__button-wrapper">
                    <Input
                      type="checkbox"
                      onChange={onFormChange}
                      name="day"
                      label="Sabtu"
                      //checked='false'
                      // checked='false'
                      checked={
                        form.value.day && form.value.day.indexOf("Sabtu") > -1
                      }
                      value="Sabtu"
                      error={form.error.day || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buat-ekskul__custom-form-row">
          <Input
            //  isNumber
            name="ekskul_fee"
            label="Biaya Ekskul"
            onChange={onFormChange}
            value={commaFormatted(form.value.ekskul_fee)}
            error={form.error.ekskul_fee || ""}
            style={{width: "470px"}}
          />

          <div className="buat-ekskul__custom-form-row__field"></div>
          <Input
            type="number"
            name="quota"
            label="Quota Kelas *"
            onChange={onFormChange}
            value={form.value.quota}
            error={form.error.quota || ""}
            isRequired
          />
        </div>
      </div>
    </div>
  );
}
BuatEkskulForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatEkskulForm.defaultProps = {
  onFormChange: noop,
};
