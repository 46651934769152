import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../components/base/index';
import { Link } from 'react-router-dom';


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div id="divPrint" style={{width:800, border: '0px solid black'}}>
    <div >
       
          
           <div id="cetak-konseling" className="cetak-konseling">
            {
                <div className="cetak-konseling__content">
                  
                      <div className="cetak-konseling__header-title2">
                      <div className="cetak-konseling__header-title2--left">

                      <p>Jumlah Hari Efektif: </p>
                     
                      </div>
                      </div>
                     
                      
                      
                 
                  <div className="cetak-konseling__body">
                    <table className="cetak-konseling__table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Kelas</th>
                          <th>L</th>
                          <th>P</th>
                          <th>JUMLAH</th>
                          <th>SAKIT</th>
                          <th>IZIN</th>
                          <th>ALPHA</th>
                          <th>Jumlah SIA</th>
                          <th>Jumlah Hadir</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      {/* <tbody>
                        {
                        map(journal.details, (list, idx) => (
                          <tr key={`journal_detail_${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{list.code_of_account}</td>
                            <td>{list.parameter_code.title}</td>
                            <td>{list.description}</td>
                            <td className="nominal">{commaFormatted(list.amount)}</td>
                          </tr>
                        ))
                      }
                      </tbody> */}
                      {/* <tfoot>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                          <td className="nominal">{commaFormatted(journal.total)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                          <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL NETTO</td>
                          <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>



                  <div className="cetak-konseling__header-title2">
                      <div className="cetak-konseling__header-title2--left">
                      <p>REKAPITULASI: </p>
                     
                      </div>
                      </div>
                  <div className="cetak-konseling__body">
                    <table className="cetak-konseling__table">
                      <thead>
                        <tr>
                        <th>No.</th>
                          <th>Kelas</th>
                          <th>L</th>
                          <th>P</th>
                          <th>JUMLAH</th>
                          <th>SAKIT</th>
                          <th>IZIN</th>
                          <th>ALPHA</th>
                          <th>Jumlah SIA</th>
                          <th>Jumlah Hadir</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      {/* <tbody>
                        {
                        map(journal.details, (list, idx) => (
                          <tr key={`journal_detail_${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{list.code_of_account}</td>
                            <td>{list.parameter_code.title}</td>
                            <td>{list.description}</td>
                            <td className="nominal">{commaFormatted(list.amount)}</td>
                          </tr>
                        ))
                      }
                      </tbody> */}
                      {/* <tfoot>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                          <td className="nominal">{commaFormatted(journal.total)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                          <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL NETTO</td>
                          <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                  {/* <div className="cetak-8355__footer">
                    <div className="cetak-8355__footer-bottom">
                      <div>
                        <p>Diserahkan oleh:</p>
                        <p>{journal.submitted_by}</p>
                      </div>
                      <div>
                        <p>Diterima oleh:</p>
                        <p>{journal.accepted_by}</p>
                      </div>
                      <div>
                        <p>Dibukukan oleh:</p>
                        <p>{journal.booked_by}</p>
                      </div>
                      <div>
                        <p>Diketahui oleh:</p>
                        <p>Name 4</p>
                      </div>
                    </div>
                  </div> */}
                </div>
             
            }
          </div>
        </div>
</div>
  );
     }
}
ComponentToPrint.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ComponentToPrint.defaultProps = {
  onFormChange: noop,
};
