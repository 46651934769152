import TambahDaftarGuru from '../../../views/Kurikulum/MataPelajaran/TambahDaftarGuru.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';
import {getParamOptions, getSubjectOptions, getEkskulOptions, getParamOptionsAcademics,
  getClassesOptions} from '../../../states/thunks/options.thunk';
import {saveListTeacher, getListTeacher, editListTeacher} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      user: state.user || {},
    }
  }

function mapDispatchToProps(dispatch){
    return{
        getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
        getEkskulOptions: (payload) => dispatch(getEkskulOptions(payload)),
        handleGetListTeacher: payload => dispatch(getListTeacher(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        handleEditListTeacher: (payload, goBack) => dispatch(editListTeacher(payload, goBack)),
        handleSaveListTeacher: (payload, goBack) => dispatch(saveListTeacher(payload, goBack)),    
        getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (TambahDaftarGuru);