import { connect } from 'react-redux';
import Realization from '../../../../views/Finance/Budgeting/Realization/Realization.finance.view';
import { getBudgetRealization } from '../../../../states/thunks/report.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetRealization: payload => dispatch(getBudgetRealization(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Realization);
