import { connect } from 'react-redux';
import CetakLaporanAbsensiKelas from '../../../../views/Student/report/cetak/CetakLaporanAbsensiKelas.student.view';
import { manageRegistration } from '../../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
  };
}
export default connect(null, mapDispatchToProps)(CetakLaporanAbsensiKelas);
