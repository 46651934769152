import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { PERSONAL_RETIRE_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class Retire extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/surat/tambah-pensiun');
  }

  render() {
    const { handleGetRetire } = this.props;
    return (
      <div className="retire">
        <Button
          title="Tambah Surat Pensiun"
          onClick={this.onClickAdd}
        />
        <div className="retire__content">
          <ContentTable
            onSearchContent={handleGetRetire}
            tableFields={PERSONAL_RETIRE_TABLE_FIELDS}
          />
        </div>
      </div>
    );
  }
}
Retire.propTypes = {
  handleGetRetire: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Retire.defaultProps = {
  handleGetRetire: noop,
};
