import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, noop } from 'lodash';
import { commaFormatted } from '../../../utils/transformer.util';
import ReportCard from '../../../components/ReportCard/ReportCard.component';

export default class ProfitAndLostReport extends PureComponent {
  constructor(props) {
    super(props);

    const date = new Date();
    this.state = {
      from: `${date.getFullYear()}-01-01`,
      to: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      report: {},
    };
    this._getReport = this._getReport.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  componentDidMount() {
    this._getReport();
  }

  componentDidUpdate(prevProps, prevState) {
    const { from, to } = this.state;

    if (from !== prevState.from || to !== prevState.to) {
      this._getReport();
    }
  }

  async _getReport() {
    const { handleGetProfitLossReport } = this.props;
    const { from, to } = this.state;
    const res = await handleGetProfitLossReport({
      from,
      to,
    });
    this.setState({ report: res });
  }

  _onSearch(val) {
    const { from, to } = val;
    this.setState({
      from,
      to,
    });
  }

  _renderPeriode() {
    const { from, to } = this.state;
    const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    const dateFrom = new Date(from);
    const dateTo = new Date(to);

    return `${dateFrom.getDate()} ${monthNames[dateFrom.getMonth()]} ${dateFrom.getFullYear()} - ${dateTo.getDate()} ${monthNames[dateTo.getMonth()]} ${dateTo.getFullYear()}`;
  }

  render() {
    const { report } = this.state;
    const { user } = this.props;
    const { school_unit } = user;

    return (
      <div className="profit-and-lost-report">
        <ReportCard
          onSearch={this._onSearch}
          paging={false}
          header={(
            <div className="profit-and-lost-report__title">
              <h1>Laporan Laba Rugi</h1>
              <h1>{school_unit.name}</h1>
              <h3>Periode Tanggal {this._renderPeriode()}</h3>
            </div>
          )}
          >
          <table className="profit-and-lost-report__table">
            <tbody>
              {
                map(report.pendapatan, (list, idx) => ([
                  <tr key={`balance_category_report_${idx}`}>
                    <td className="balance-report__row-title" colSpan={4}>{list.title}</td>
                  </tr>,
                  map(list.category, (lc, idc) => ([
                    <tr key={`balance_group_report_${idc}`}>
                      <td colSpan={4}>{lc.title}</td>
                    </tr>,
                    map(lc.group, (lg, idg) => ([
                      <tr key={`balance_group_report_${idg}`}>
                        <td rowSpan={lg.account.length + 2}>&nbsp;</td>
                        <td colSpan={3}>{lg.title}</td>
                      </tr>,
                      map(lg.account, (la, ida) => ([
                        <tr key={`balance_group_account_report_${ida}`}>
                          <td>{la.code}</td>
                          <td>{la.title}</td>
                          <td className="nominal">{commaFormatted(la.total)}</td>
                        </tr>,
                      ])),
                      <tr key={`balance_group_report__total_${idg}`} className="profit-and-lost-report__row-black">
                        <td colSpan={2}><b>TOTAL {lg.title}</b></td>
                        <td className="nominal"><b>{commaFormatted(lg.total)}</b></td>
                      </tr>,
                    ])),
                    <tr key={`balance_category_report__total_${idc}`} className="profit-and-lost-report__row-black">
                      <td colSpan={3}><b>TOTAL {lc.title}</b></td>
                      <td className="nominal"><b>{commaFormatted(lc.total)}</b></td>
                    </tr>,
                    <tr key={`balance_category_report__separator_${idc}`}>
                      <td colSpan={4}>&nbsp;</td>
                    </tr>,
                  ])),
                  <tr key={`balance_class_report__total_${idx}`} className="profit-and-lost-report__row-black">
                    <td colSpan={3}><b>TOTAL {list.title}</b></td>
                    <td className="nominal"><b>{commaFormatted(list.total)}</b></td>
                  </tr>,
                  <tr key={`balance_category_report__separator_${idx}`}>
                    <td colSpan={4}>&nbsp;</td>
                  </tr>,
                ]))
              }
              {
                map(report.pengeluaran, (list, idx) => ([
                  <tr key={`balance_category_report_${idx}`}>
                    <td className="balance-report__row-title" colSpan={4}>{list.title}</td>
                  </tr>,
                  map(list.category, (lc, idc) => ([
                    <tr key={`balance_group_report_${idc}`}>
                      <td colSpan={4}>{lc.title}</td>
                    </tr>,
                    map(lc.group, (lg, idg) => ([
                      <tr key={`balance_group_report_${idg}`}>
                        <td rowSpan={lg.account.length + 2}>&nbsp;</td>
                        <td colSpan={3}>{lg.title}</td>
                      </tr>,
                      map(lg.account, (la, ida) => ([
                        <tr key={`balance_group_account_report_${ida}`}>
                          <td>{la.code}</td>
                          <td>{la.title}</td>
                          <td className="nominal">{commaFormatted(la.total)}</td>
                        </tr>,
                      ])),
                      <tr key={`balance_group_report__total_${idg}`} className="profit-and-lost-report__row-black">
                        <td colSpan={2}><b>TOTAL {lg.title}</b></td>
                        <td className="nominal"><b>{commaFormatted(lg.total)}</b></td>
                      </tr>,
                    ])),
                    <tr key={`balance_category_report__total_${idc}`} className="profit-and-lost-report__row-black">
                      <td colSpan={3}><b>TOTAL {lc.title}</b></td>
                      <td className="nominal"><b>{commaFormatted(lc.total)}</b></td>
                    </tr>,
                    <tr key={`balance_category_report__separator_${idc}`}>
                      <td colSpan={4}>&nbsp;</td>
                    </tr>,
                  ])),
                  <tr key={`balance_class_report__total_${idx}`} className="profit-and-lost-report__row-black">
                    <td colSpan={3}><b>TOTAL {list.title}</b></td>
                    <td className="nominal"><b>{commaFormatted(list.total)}</b></td>
                  </tr>,
                  <tr key={`balance_category_report__separator_${idx}`}>
                    <td colSpan={4}>&nbsp;</td>
                  </tr>,
                ]))
              }
              {
                !isEmpty(report) && (
                  <tr className="profit-and-lost-report__row-black">
                    <td colSpan={3}>
                      LABA / RUGI BERSIH
                    </td>
                    <td className="nominal">{commaFormatted(report.laba_rugi)}</td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </ReportCard>
      </div>
    );
  }
}
ProfitAndLostReport.propTypes = {
  user: PropTypes.object,
  handleGetProfitLossReport: PropTypes.func,
};
ProfitAndLostReport.defaultProps = {
  user: {},
  handleGetProfitLossReport: noop,
};
