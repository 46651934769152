import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import {
  Input,
  Select,
  Textarea,
  Button,
  SearchSelect,
} from "../../../../../components/base/index";
import { Link } from "react-router-dom";
import SelectCoa from "../../../../../components/SelectCoa/SelectCoa.component";

export default class LaporanKeuForm extends Component {
  constructor(props) {
    super(props);

    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }
  _onDeleteList() {
    const { onDeleteList, fieldName } = this.props;
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list,
      error,
      onChange,
      fieldName,
      plafond_name,
      onFormChange,
      form,
      param,
    } = this.props;

    return (
      <div className="laporan-keu__content">
        <div className="komposisi-siswa__custom-form">
          <div className="laporan-keu2__custom-form-row__field">
            <div className="laporan-keu2__custom-form-row3">
              <Select
                name="jenis"
                label="Jenis"
                onChange={this._onFormChange}
                // data={ jenis.list}
                placeholder={"Pilihan"}
                value={form.value.jenis}
                error={form.error.jenis || ""}
              />
            </div>
            <div className="laporan-keu2__custom-form-row4">
              <Input
                type="date"
                name="from"
                label="Dari"
                onChange={onFormChange}
                value={form.value.from || ""}
                error={form.error.from || ""}
                // placeholder={'Pilihan'}
              />
            </div>

            <div className="laporan-keu2__custom-form-row2__field">
              <div className="laporan-keu2__custom-form-row5">
                <Input
                  type="date"
                  name="to"
                  label="Sampai"
                  onChange={onFormChange}
                  value={form.value.to || ""}
                  error={form.error.to || ""}
                  // placeholder={'Pilihan'}
                />
              </div>
              <div className="laporan-keu2__custom-form-row6">
                <Select
                  name="tahun_ajaran"
                  label="Tahun Ajaran"
                  onChange={onFormChange}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={form.value.tahun_ajaran || ""}
                  error={form.error.tahun_ajaran || ""}
                />
              </div>
            </div>
          </div>
          <div className="laporan-keu2__custom-form-row__field">
            <div className="laporan-keu2__custom-form-row3">
              <Select
                name="status"
                label="Status"
                onChange={this._onFormChange}
                // data={ status.list}
                placeholder={"Pilihan"}
                value={form.value.status}
                error={form.error.status || ""}
              />
            </div>
            <div className="laporan-keu2__custom-form-row4">
              <SearchSelect
                noMargin
                async={false}
                name="tema"
                //  list={tema.list}
                onChange={this.onSearchTema}
                placeholder="Pilih Nomor Invoice"
                //  value={selectedTema}
                labelName="name"
                valueName="code"
                label="Nomor Invoice"
                rightIcon="icon-search"
              />
            </div>

            <div className="laporan-keu2__custom-form-row2__field">
              <div className="laporan-keu2__custom-form-row5">
                <SearchSelect
                  noMargin
                  async={false}
                  name="tema"
                  //  list={tema.list}
                  onChange={this.onSearchTema}
                  placeholder="Pilihan"
                  //  value={selectedTema}
                  labelName="name"
                  valueName="code"
                  label="Sampai"
                  rightIcon="icon-search"
                />
              </div>
              <div className="laporan-keu2__custom-form-row6">
                <Select
                  name="status"
                  label="Nik/Nama Siswa"
                  onChange={this._onFormChange}
                  // data={ status.list}
                  placeholder={"Pilihan"}
                  value={form.value.status}
                  error={form.error.status || ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LaporanKeuForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LaporanKeuForm.defaultProps = {
  onFormChange: noop,
};
