import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, isEmpty } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../../../utils/transformer.util';
import { FINANCE_ADD_NON_BUDGET_FORM_FIELDS } from '../../../../constants/finance/budgeting/nonBudget.constant';
import { Button } from '../../../../components/base';

export default class AddNonBudget extends Component {
  constructor(props) {
    super(props);
    this._setForm = this._setForm.bind(this);
    this._searchCoa = this._searchCoa.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      coa: {
        loading: true,
        list: [],
      },
      form: {
        value: {},
        error: {},
      },
    };
  }

  async componentDidMount() {
    const { handleGetCodeOfAccount, location } = this.props;
    const { data = {} } = location.state;

    this._searchCoa();

    if (!isEmpty(data)) {
      this._setForm(data);
    }
  }

  _searchCoa(keyword = null) {
    const { handleGetCodeOfAccount } = this.props;

    handleGetCodeOfAccount({
      keyword,
      classes: [50000],
    });
  }

  _setForm(val) {
    this.setState({
      form: {
        value: {
          activity: val.activity,
          amount: commaFormatted(val.amount),
          code_of_account: {
            code: val.code_of_account,
            title: val.code_of_account,
          },
          date: val.date,
          description: val.description,
          file_number: val.file_number,
          id: val.id,
        },
        error: {},
      },
    });
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    const { form } = this.state;
    const {
      handleAddNonBudget, history, location,
      handleEditNonBudget,
    } = this.props;
    const { isEdit = false } = location.state;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    if (isEdit) {
      handleEditNonBudget(form.value, history.goBack, unit_id);
    } else {
      handleAddNonBudget(form.value, history.goBack, unit_id);
    }
  }

  render() {
    const { form } = this.state;
    const { location, coa } = this.props;
    const { isEdit = false } = location.state;
    return (
      <div className="manage-non-budget">
        <h1>
          {
            isEdit ? 'Edit Non Anggaran' : 'Buat Non Anggaran'
          }
        </h1>
        <form onSubmit={this._onSubmit}>
          <ListForm
            coa={coa}
            form={form}
            formFields={FINANCE_ADD_NON_BUDGET_FORM_FIELDS}
            onFormChange={this._onFormChange}
            onSearchCoa={this._searchCoa}
          />
          <div className="manage-non-budget">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
AddNonBudget.propTypes = {
  handleGetCodeOfAccount: PropTypes.func,
  handleAddNonBudget: PropTypes.func,
  handleEditNonBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
};
AddNonBudget.defaultProps = {
  handleGetCodeOfAccount: noop,
  handleAddNonBudget: noop,
  handleEditNonBudget: noop,
  user: null,
};
