import axios from "axios";
import { transform } from "lodash";
import * as api from "../../utils/api.util";
import * as commonActions from "../actions/common.action";
import {
  errorAlert,
  successAlert,
  successAlertBack,
} from "../../utils/alert.util";
import HTTPError from "../../utils/error.util";
import language from "../../languages";
import fileDownload from "js-file-download";
import download from "downloadjs";

export function saveScheduleLecturer(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule-lecturer/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listScheduleLecturer(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule-lecturer/list");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function viewScheduleLecturer(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule-lecturer/view");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function editScheduleLecturer(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule-lecturer/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteScheduleLecturer(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule-lecturer/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveAnnouncement(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      formatted.students = formatted.students.map((item) => item.value);
      formatted.classrooms = formatted.classrooms.map((item) => item.value);
      formatted.teachers = formatted.teachers.map((item) => item.value);
      const res = await api.ACADEMICS(
        { create_user_id: user.id, create_user_name: user.name, ...formatted },
        "/announcement/save"
      );
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listAnnouncement(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/announcement/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listAnnouncementStudent(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/announcement-student/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function viewAnnouncement(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/announcement/view");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function editAnnouncement(payload) {
  return async (dispatch) => {
    try {
      const formatted = { ...payload };
      formatted.students = formatted.students.map((item) => item.value);
      formatted.classrooms = formatted.classrooms.map((item) => item.value);
      formatted.teachers = formatted.teachers.map((item) => item.value);
      const res = await api.ACADEMICS(formatted, "/announcement/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteAnnouncement(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "announcement/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function uploadAnnouncementFile(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file } = payload;
      const requestBody = new FormData();
      requestBody.append("file", file);
      const res = await api.ACADEMICS(requestBody, "/announcement/upload");
      return res.data;
    } catch (err) {
      errorAlert({
        title: "Tambah File Gagal",
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function downloadAnnouncementFile(id, filename) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.GETACADEMICS(`/announcement/download/${id}`, {
        method: "GET",
        responseType: "blob",
      });
      // console.log(res)
      if (res) {
        fileDownload(res, filename);
      }
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAnnouncement(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/announcement/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSchedule(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/schedule/get");
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveSchedule(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const formatted = { ...payload };
      formatted.students = formatted.students.map((item) => item.value);
      formatted.classrooms = formatted.classrooms.map((item) => item.value);
      formatted.teachers = formatted.teachers.map((item) => item.value);
      // console.log(formatted);
      const res = await api.ACADEMICS(
        { create_user_id: user.id, create_user_name: user.name, ...formatted },
        "/schedule/save"
      );

      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listSchedule(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/schedule/list");
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function viewSchedule(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule/view");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function editSchedule(payload) {
  return async (dispatch) => {
    try {
      const formatted = { ...payload };
      formatted.students = formatted.students.map((item) => item.value);
      formatted.classrooms = formatted.classrooms.map((item) => item.value);
      formatted.teachers = formatted.teachers.map((item) => item.value);
      const res = await api.ACADEMICS(formatted, "/schedule/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteSchedule(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/schedule/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function uploadScheduleFile(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file } = payload;
      const requestBody = new FormData();
      requestBody.append("file", file);
      const res = await api.ACADEMICS(requestBody, "/schedule/upload");
      return res.data;
    } catch (err) {
      errorAlert({
        title: "Tambah File Gagal",
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function downloadScheduleFile(id, filename) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.GETACADEMICS(`/schedule/download/${id}`, {
        method: "GET",
        responseType: "blob",
      });
      console.log(res);
      if (res) {
        download(res, filename);
      }
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listScheduleStudents(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/schedule/list-students"
      );
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveAnnouncementFolder(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/announcement-folder/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listAnnouncementFolder(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/announcement-folder/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editAnnouncementFolder(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/announcement-folder/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteAnnouncementFolder(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "announcement-folder/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getAnnouncementFolder(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/announcement-folder/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listStudentAttendance(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/student-attendance/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveAttendance(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/student-attendance/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getAttendance(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { date, period, classrooms_id } = payload;
      const res = await api.ACADEMICS(
        { date, period, classrooms_id },
        "/student-attendance/get"
      );
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editAttendance(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/student-attendance/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveClassesData(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;
      console.log(user.school_unit.id);

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(
        { units_id: user.school_unit.id, organizations_id: 4, ...formatted },
        "/data-kelas/save"
      );
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listClassData(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/data-kelas/list");
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getClassData(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS(
        { id, organizations_id: 4 },
        "/data-kelas/get-classdata"
      );
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editClassData(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/data-kelas/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteClassData(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/data-kelas/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}
export function listStudentRekapAttendance(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/student-attendance/list-rekap"
      );
      dispatch(commonActions.loadJournalList(res));
      // console.log(res)
      return res;
      // return {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listClassDivStudent(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/data-kelas/pembagian-kelas/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveListTeacher(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/teacher-list/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listTeacher(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/teacher-list/list"
      );
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getListTeacher(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/teacher-list/get");
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editListTeacher(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/teacher-list/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteListTeacher(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/teacher-list/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveMajor(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/major/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listMajor(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/major/list");
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getMajor(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/major/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editMajor(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/major/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteMajor(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/major/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

//pelanggaran
export function saveViolations(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/record-violations/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listViolations(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/record-violations/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getViolations(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/record-violations/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editViolations(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/record-violations/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

//prestasi
export function saveAchievement(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/record-achievement/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listAchievement(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/record-achievement/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getAchievement(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/record-achievement/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editAchievement(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/record-achievement/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveReportDispliner(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/report-displiner/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listReportDispliner(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/report-displiner/list"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listReportDisplinerViolations(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/report-displiner/listViolations"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listReportDisplinerAchievement(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/report-displiner/listAchievement"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listReportDisplinerRecordDaily(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/record-student-daily/list"
      );
      dispatch(commonActions.loadJournalList(res));
      return res.data;
    } catch (err) {
      throw err;
    }
  };
}

export function getReportDispliner(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/report-displiner/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editReportDispliner(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/report-displiner/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveRecordDaily(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/record-student-daily/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listRecordDaily(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/record-student-daily/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getRecordDaily(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/record-student-daily/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editRecordDaily(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/record-student-daily/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveSubjectDetail(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      formatted.tipe_matpel = formatted.tipe_matpel.map((item) => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(formatted, "/subject-detail/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listKodeMatpel(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/subject-detail/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getKodeMatpel(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/subject-detail/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function EditKodeMatpel(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      formatted.tipe_matpel = formatted.tipe_matpel.map((item) => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(formatted, "/subject-detail/edit");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteKodeMatpel(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/subject-detail/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listClassStudentEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-class-div/listStudent"
      );
      // console.log
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getJenisEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/ekskul/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editJenisEkskul(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/ekskul/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveJenisEkskul(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/ekskul/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listJenisEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/ekskul/list");
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function deleteJenisEkskul(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/ekskul/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveListStudentEkskul(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };

      formatted.daftar_ekskul = formatted.daftar_ekskul.map((item) =>
        item.ekskul_day.map((items) => items.label)
      );
      console.log(formatted);
      const res = await api.ACADEMICS(payload, "/ekskul-list/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getListStudentEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/ekskul-list/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editListStudentEkskul(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-list/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-list/list"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
      // return {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function saveClassEkskul(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-class-div/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getClassEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/ekskul-class-div/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editClassEkskul(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-class-div/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listClassEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-class-div/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function deleteClassEKskul(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/ekskul-class-div/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveEkskulGrade(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-grades-div/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getEkskulGrade(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/ekskul-grades-div/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editEkskulGrade(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-grades-div/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listGradeStudentEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-grades-div/listStudent"
      );
      // console.log
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listGradeEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-grades-div/list"
      );
      dispatch(commonActions.loadJournalList(res));
      //    console.log(res)
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function deleteGradeEKskul(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/ekskul-grades-div/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveEkskulAbsen(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-absen/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getEkskulAbsen(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/ekskul-absen/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editEkskulAbsen(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/ekskul-absen/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentEkskulAbsen(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-absen/listStudent"
      );
      // console.log
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listAbsenEkskul(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/ekskul-absen/list"
      );
      dispatch(commonActions.loadJournalList(res));
      //    console.log(res)
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveAddAttendanceStudyField(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/absen-bidang-study/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getAddAttendanceStudyField(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { date, subject_id, classrooms_id } = payload;
      const res = await api.ACADEMICS(
        { date, subject_id, classrooms_id },
        "/absen-bidang-study/get"
      );
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editAddAttendanceStudyField(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/absen-bidang-study/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentAddAttendanceStudyField(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/absen-bidang-study/listStudent"
      );
      // console.log
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listStudyField(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/absen-bidang-study/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function deleteStudyField(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/absen-bidang-study/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listReportStudyAttendance(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/laporan-absensi/listStudyAttendance"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
      // return {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listReportStudentAttendance(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/laporan-absensi/listStudentAttendance"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveExitRecord(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      console.log(formatted.students_id.value);
      // formatted.students_id = formatted.students_id.value;

      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "students/exit-records/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listExitRecord(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "students/exit-records/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getExitRecord(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "students/exit-records/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editExitRecord(payload) {
  return async (dispatch) => {
    try {
      const formatted = { ...payload };
      console.log(formatted.students_id.value);
      formatted.students_id = formatted.students_id.value;
      const res = await api.ACADEMICS(payload, "students/exit-records/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function activatedExitRecord(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(
        payload,
        "students/exit-records/activated"
      );
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveCounselingPlace(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/tempat-konseling/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listCounselingPlace(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/tempat-konseling/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editCounselingPlace(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "tempat-konseling/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteCounselingPlace(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "tempat-konseling/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getCounselingPlace(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "tempat-konseling/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveCounselingRecord(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/konseling-siswa/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listCounselingRecord(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/konseling-siswa/list"
      );
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editCounselingRecord(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "konseling-siswa/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteCounselingRecord(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "konseling-siswa/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getCounselingRecord(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "konseling-siswa/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function historyCounselingRecord(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/konseling-siswa/history"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveScheduleSubject(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/schedule-subject/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getScheduleSubject(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/schedule-subject/get");
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editScheduleSubject(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/schedule-subject/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listScheduleSubject(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/schedule-subject/list-web"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
      // return {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function deleteScheduleSubject(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "schedule-subject/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveClassDiv(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(
        payload,
        "/data-kelas/pembagian-kelas/save-ypl"
      );
      // console.log(res);
      return res.data || [];
    } catch (err) {
      errorAlert({
        title: "Daya Tampung Sudah Melebihi Batas",
      });
      throw err;
    }
  };
}

export function getClassDiv(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS(
        { id },
        "/data-kelas/pembagian-kelas/get"
      );
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editClassDiv(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(
        payload,
        "/data-kelas/pembagian-kelas/edit"
      );
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listClassDiv(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/data-kelas/pembagian-kelas/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res.data);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

  export function listStudentClass(payload = {}) {
      return async (dispatch) => {
          try {
              dispatch(commonActions.setLoading({ show: true }));
              const { page = 1 } = payload;
              const res = await api.ACADEMICS({...payload, page}, '/students-list/list');
              dispatch(commonActions.loadJournalList(res)); 
             
              return res;
              // {
              //     result: res.data,
              //     currentPage: res.current_page,
              //     totalPage: res.last_page,
              //     total: res.total,
              //   };
          } catch (err) {
              throw err;
          } finally {
              dispatch(commonActions.setLoading({ show: false }));
          }
      };
  }

export function saveNextClass(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/kenaikan-kelas/save-ypl");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listNextClassStudent(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/kenaikan-kelas/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listLaporan8355(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/laporan-8355/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));

      return res;
      // {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listCounselingReport(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/konseling-siswa/listReport"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
      // {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listCreateInvoices(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/create-invoices/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // console.log(res)
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveCreateInvoices(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      // console.log(formatted);
      const res = await api.ACADEMICS(payload, "/create-invoices/save");

      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentChangeStudent(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/siswa-pindah-kelas/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveStudentChange(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/siswa-pindah-kelas/save");
      successAlert({ title: 'Pindah Kelas Berhasil'})
      // console.log(res);
      return res.data || [];
    } catch (err) {
      errorAlert({
        title: 'Daya Tampung Sudah Melebihi Batas'
      })
      throw err;
    }
  };
}

//SPP//
export function listStatusPayments(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments-status/list"
      );
      dispatch(commonActions.loadJournalList(res));

      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveStatusPayments(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/payments-status/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteStatusPayments(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/payments-status/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getStatusPayments(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/payments-status/get");
      // console.log(res)
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editStatusPayments(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/payments-status/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveJenisSPP(payload) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/payments-jenis-spp/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getJenisSPP(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/payments-jenis-spp/get");

      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editJenisSPP(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "payments-jenis-spp/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteJenisSPP(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "payments-jenis-spp/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listJenisSPP(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments-jenis-spp/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentInvoice(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/create-invoices/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveInvoiceRutin(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/create-invoices/saveRutin");
      return res.data || [];
    } catch (err) {
      errorAlert({
        title: "Tahun Ajaran yang sama sudah ada.",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveInvoiceNonRutin(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/create-invoices/saveNonRutin");
      return res.data || [];
    } catch (err) {
      errorAlert({
        title: "Tahun Ajaran & Nama Pembayaran yang sama sudah ada.",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listJenisSPPInvoice(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/create-invoices/listJenisSPP"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentCognitiveValue(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/nilai-kognitif/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveCognitiveValue(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/nilai-kognitif/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listNilaiKognitif(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/nilai-kognitif/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getNilaiKognitif(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "nilai-kognitif/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editNilaiKognitif(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "nilai-kognitif/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveBobotNilai(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/bobot-nilai/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getBobotNilai(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/bobot-nilai/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editBobotNilai(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/bobot-nilai/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listManageRegis(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "students/profile/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveAttitudeIndicator(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/inidkator-sikap/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getAttitudeIndicator(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/inidkator-sikap/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editAttitudeIndicator(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/inidkator-sikap/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentPsikAfek(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/nilai-psik/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getPsikAfek(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "nilai-psik/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editPsikAfek(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "nilai-psik/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function savePsikAfek(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "nilai-psik/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listPsikAfek(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/nilai-psik/listPsik"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listIndicatorPsikAfek(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/nilai-psik/listIndicator"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveViolationsGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/group-pelanggaran/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listViolationsGroup(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/group-pelanggaran/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editViolationsGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "group-pelanggaran/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteViolationsGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "group-pelanggaran/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getViolationsGroup(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "group-pelanggaran/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveViolationsTable(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/pelanggaran/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listViolationsTable(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/pelanggaran/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editViolationsTable(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "pelanggaran/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteViolationsTable(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "pelanggaran/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getViolationsTable(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "pelanggaran/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

//table prestasi//
export function saveAchievementGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/group-prestasi/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listAchievementGroup(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/group-prestasi/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editAchievementGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "group-prestasi/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteAchievementGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "group-prestasi/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getAchievementGroup(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "group-prestasi/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveAchievementTable(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/prestasi/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listAchievementTable(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/prestasi/list");
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editAchievementTable(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "prestasi/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteAchievementTable(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "prestasi/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getAchievementTable(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "prestasi/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
export function saveSubjectGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/group-matpel/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listSubjectGroup(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/group-matpel/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editSubjectGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "group-matpel/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteSubjectGroup(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "group-matpel/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getSubjectGroup(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "group-matpel/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listPengisianCatatanAnekdot(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/catat-anekdot/listStudents"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];

      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listPengisianObsHarian(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/observasi-harian/listObsHarian"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];

      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listPengisianMingguan(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/observasi-mingguan/listObsMingguan"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listDaftarNilaiReport(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/report-daftar-nilai/listDaftarNilai"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listDaftarNilaiReportMan(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/raport-mandarin/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listDaftarNilaiReportEng(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/raport-english/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function editLembarObsHarian(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/observasi-harian/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveLembarObsHarian(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/observasi-harian/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getLembarObsHarian(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/observasi-harian/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editLembarObsMingguan(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/observasi-mingguan/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveLembarObsMingguan(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/observasi-mingguan/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getLembarObsMingguan(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/observasi-mingguan/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editDaftarNilai(payload) {
  return async (dispatch) => {
    try {
      // const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classrooms = formatted.classrooms.map(item => item.value);
      // formatted.teachers = formatted.teachers.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/report-daftar-nilai/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveDaftarNilai(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/report-daftar-nilai/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getLembarDaftarNilai(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/report-daftar-nilai/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveDaftarNilaiEng(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/raport-english/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getLembarDaftarNilaiEng(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/raport-english/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveDaftarNilaiMan(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/raport-mandarin/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getLembarDaftarNilaiMan(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/raport-mandarin/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

//Nomor induk siswa
export function getStudentNumber(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/student-number/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

//Tahun Ajaran//
export function savePeriods(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/tahun-ajaran/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listPeriods(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/tahun-ajaran/list"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getPeriods(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "/tahun-ajaran/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editPeriods(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/tahun-ajaran/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listKlapper(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/klapper/list");
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return res;
      // return {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listScoreStudents(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/daftar-nilai/list"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listTypeExam(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/daftar-nilai/listTypeExam"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function saveScoreList(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/daftar-nilai/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listRaportSiswa(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/raport-siswa/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];

      return res;
      // return {
      //     result: res,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listRaportSubject(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/raport-siswa/listSubject"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
      // return {
      //     result: res,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function getRaportSiswa(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ ...payload }, "/raport-siswa/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveRaportSiswa(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/raport-siswa/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function studentCount(payload = {}) {
  return async (dispatch) => {
    try {
      // const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload }, "/report/total-siswa");
      // dispatch(commonActions.loadJournalList(res));
      //   console.log('test'+ res);
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listReportLogin(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/report/list-report-loggedin-ricci"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function savePindahUnits(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/pindah-units/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listPindahUnitSiswa(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/pindah-units/listStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];

      return res;
      // return {
      //     result: res,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listDaftarPindahUnitSiswa(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/pindah-units/list-daftar-pindah-unit"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];

      return res;
      // return {
      //     result: res,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listPermohonanPindahUnitSiswa(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/pindah-units/list-permohonan-pindah-unit"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];

      return res;
      // return {
      //     result: res,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function rejectSwitchUnit(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "pindah-units/reject");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function approveSwitchUnit(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "pindah-units/approve");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteSwitchUnit(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "pindah-units/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getSwitchUnit(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS(
        { id, organizations_id: 4 },
        "/pindah-units/get"
      );
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveKompetensiDasar(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/kompetensi-dasar/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listTema(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/tema/list");
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function deleteTema(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "tema/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveTema(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/tema/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function editTema(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "tema/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getTema(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "tema/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listSubTema(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS({ ...payload, page }, "/sub-tema/list");
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function deleteSubTema(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "sub-tema/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function saveSubTema(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "/sub-tema/save");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function editSubTema(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "sub-tema/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getSubTema(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "sub-tema/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listTemaCatatanAnekdot(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/catat-anekdot/listTema"
      );
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function saveCatatanAnekdot(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/catat-anekdot/save");
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getCatatanAnekdot(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id, tema_id, semester } = payload;
      const res = await api.ACADEMICS(
        { id, tema_id, semester },
        "/catat-anekdot/get"
      );
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveDevAspect(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      // formatted.students = formatted.students.map(item => item.value);
      // formatted.classes = formatted.classes.map(item => item.value);
      const res = await api.ACADEMICS(payload, "/kompetensi-dasar/save-aspect");
      // console.log(res);
      return res.data || [];
    } catch (err) {
      throw err;
    }
  };
}

export function getDevAspect(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, "kompetensi-dasar/get");
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editDevAspect(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "kompetensi-dasar/edit");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function deleteDevAspect(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "kompetensi-dasar/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listDevAspect(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/kompetensi-dasar/list"
      );
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function listInvoiceRutin(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/list-invoice-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listInvoiceNonRutin(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/list-invoice-nonrutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listPaymentGateway(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/list-payment-gateway"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function detailPayments(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/payments/detail-payments");
      // console.log(res)

      return res || [];
    } catch (err) {
      errorAlert({
        title: "Terjadi Kesalahan",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function cancelPayments(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));

      const res = await api.ACADEMICS(payload, "/payments/cancel-payments");
      return res || [];
    } catch (err) {
      errorAlert({
        title: "Terjadi Kesalahan",
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function historyPaymentsRutin(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/history-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function historyPaymentsNonRutin(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/payments/history-non-rutin"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function listStudentClassDetails(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/students-list/listDetails"
      );
      dispatch(commonActions.loadJournalList(res));

      return res;
      // {
      //     result: res.data,
      //     currentPage: res.current_page,
      //     totalPage: res.last_page,
      //     total: res.total,
      //   };
    } catch (err) {
      throw err;
    }
  };
}

export function listScoreStudent(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/daftar-nilai/listScoreStudent"
      );
      dispatch(commonActions.loadJournalList(res));
      // return res.data || [];
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function uploadExcelStudents(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const {
        file,
        organizations_id,
        units_id,
        levels_id,
        entrance_year,
        va_code,
        periods_id,
      } = payload;
      const requestBody = new FormData();
      requestBody.append("file", file);
      requestBody.append("organizations_id", organizations_id);
      requestBody.append("units_id", units_id);
      requestBody.append("levels_id", levels_id);
      requestBody.append("entrance_year", entrance_year);
      requestBody.append("va_code", va_code);
      requestBody.append("periods_id", periods_id);
      const res = await api.ACADEMICS(requestBody, "/upload-students/upload");

      if (res.message == "Failed") {
        errorAlert({
          title: res.data,
        });
      } else {
        console.log(res.message);
        successAlertBack({
          title: "Tambah File Berhasil",
          //   goBack
        });
      }

      //goBack();
    } catch (err) {
      errorAlert({
        title: "Tambah File Gagal",
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function approveClassDivPPDB(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(payload, "/class-div-ppdb/approve");
      return res.data || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listApproveGraduate(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/class-div-ppdb/listApproveGraduateReq"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function approveGraduations(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(
        payload,
        "/class-div-ppdb/approveGraduations"
      );
      return res.data || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function listApproveNextClass(payload = {}) {
  return async (dispatch) => {
    try {
      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/class-div-ppdb/listApproveNextClassReq"
      );
      dispatch(commonActions.loadJournalList(res));
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function approveNextClass(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const formatted = { ...payload };
      const res = await api.ACADEMICS(
        payload,
        "/class-div-ppdb/approveNextClass"
      );
      return res.data || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deletePpdbSetting(payload) {
  return async (dispatch) => {
    try {
      const res = await api.ACADEMICS(payload, "class-div-ppdb/delete");
      return res || [];
    } catch (err) {
      throw err;
    }
  };
}

export function listPPDBApprove(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { school_unit } = user;

      const { page = 1 } = payload;
      const res = await api.ACADEMICS(
        { ...payload, page },
        "/class-div-ppdb/listPPDBApproveReq"
      );
      dispatch(commonActions.loadJournalList(res));
      return res;
    } catch (err) {
      throw err;
    }
  };
}
