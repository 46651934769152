import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { LAPORAN_ABSEN } from '../../../constants/student.constant';
import { ABSENSI_REKAP_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import MengikutiForm from '../components/MengikutiForm.component';


export default class Mengikuti extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          // this._searchCoa = this._searchCoa.bind(this);
          //this.deletePlafon = this.deletePlafon.bind(this);
          //this._onAddList = this._onAddList.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          
          this.state = {
            page: 1,
            form: {
              value: {},
              error: {},
            },
            filtered: false,
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
        }
      
        // async componentDidMount() {
        //   const { handleGetCodeOfAccount, location } = this.props;
        //   const { data = {} } = location.state;
      
        //   this._searchCoa();
      
        //   if (!isEmpty(data)) {
        //     this._setForm(data);
        //   }
        // }
      
        // _searchCoa(keyword = null) {
        //   const { handleGetCodeOfAccount } = this.props;
      
        //   handleGetCodeOfAccount({
        //     keyword,
        //     classes: [50000],
        //   });
        // }
      
        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }
        _onFormChange(event) {
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          });
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
       
      
        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          });
        }
      
        onSubmit(e) {
          e.preventDefault();
          const {history} = this.props;
          history.push('/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas');
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
      
        render() {
          const { form, page } = this.state;
          const {plafon} = form.value;
          const { coa } = this.props;
          return (
            <div className="manage-registration">
              <form onSubmit={this.onSubmit}>
             <div className="manage-registration">
            <div className="accident__content">
              <h1>M - Mengikuti:</h1>
             
                  <MengikutiForm
                    form={form}
                    onFormChange={this._onFormChange}
                    />
               
                  
              </div>
            </div>
          

               
        
              </form>
            </div>
          );
        }
}
Mengikuti.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Mengikuti.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
