import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import {
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Button,
  Select,
} from "../../../../components/base";
import { CREATE_INVOICE_TABLE_FIELD_LIST } from '../../../../constants/finance/spp/create_invoice.constant';
import { Link } from "react-router-dom";
import { errorAlert } from '../../../../utils/alert.util';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onClickRutin = this.onClickRutin.bind(this);
    this.onClickTidakRutin = this.onClickTidakRutin.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          total_siswa: "",
          classes: [],
          // levels_id,
          listsiswa: [],
          classes_id: "",
        },
        error: {
          classes: "",
          levels: "",
          listsiswa: "",
        },
      },
      param: {},
      total_siswa: "",
      filtered: false,
    };
  }

  componentDidMount() {
    const {
      user,
    } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPeriodsOptions();
    this._getClassesOptions({ filters: { levels_id: [levels_id] } });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === 'levels_id') {
          this._getClassesOptions({ filters: { levels_id: [value] }} );
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id: 12,
          });
        }
      }
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  onClickRutin(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/keuangan/spp/create-invoice/rutin", {
        data: val,
        isEdit: true,
      });
    }
  }

  onClickTidakRutin(val) {
    const { history } = this.props;
    if(val.no_va == "" || val.no_va == null){
      errorAlert({
        title: 'No VA belum tersedia.',
        errorMessage: 'Mohon masukkan nomor pendaftaran di Data Siswa'
      });
    }else{
      history.push("/dashboard/keuangan/spp/create-invoice/tidak-rutin", {
        data: val,
        isEdit: true,
      });
    }
  }

  // _onChangePage(page) {
  //   const { listAmount, keywords } = this.state;
  //   const offset = listAmount * (page - 1);
  //   // this.handleGetData({
  //   this._onSearchContent({
  //     limit: listAmount,
  //     keywords,
  //     offset,
  //     page,
  //   });
  // }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListStudentInvoice,
          } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id } = user;
          const { units_id } = school_unit.id;
          // const { units_id } = school_unit.id;
          const result = await handleListStudentInvoice({
            ...params,
            organizations_id: 12,
            units_id: school_unit.id,
            filters,
          });
          // }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    const res = await getClassesOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickRutin(data)} title="Rutin" />
          <Button onClick={() => this.onClickTidakRutin(data)} title="Tidak Rutin" />
        </div>
      </td>
    );
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
    } = this.state;
    const { listsiswa } = form.value;
    const { classrooms_id = "", status_id = "", period = "" } = filters;
    const { classes = {}, levels = {}, user } = this.props;
    const { organizations_id, school_unit } = user;

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Buat Invoice </h1>
          <hr></hr>
        </div>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                data={param.periods}
                placeholder={"Pilihan"}
                value={period}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                // disabled
                placeholder="Pilih Unit"
                data={param.levels}
                onChange={this._onFormChange}
                value={form.value.levels_id}
              />
            </div>
          </div>

          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                type="text"
                name="classes_id"
                label="Tingkat Kelas"
                placeholder="Pilih Kelas"
                data={param.classes_prm}
                onChange={this._onFormChange}
                value={form.value.classes_id}
                error={form.error.classes_id || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                type="text"
                name="classrooms_id"
                label="Kelas"
                placeholder="Pilih Kelas"
                data={param.classrooms}
                onChange={this._onChangeFilter}
                value={classrooms_id}
              />
            </div>
          </div>
        </div>
        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(CREATE_INVOICE_TABLE_FIELD_LIST.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(listsiswa, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(CREATE_INVOICE_TABLE_FIELD_LIST.value, (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "students_name") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/kesiswaan/laporan/cetak-buku-induk",
                                state: { ...list },
                              }}
                            >
                              {list.students_name}
                            </Link>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    })}
                    {this._renderButtons(list)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
CreateInvoice.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CreateInvoice.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
