import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';


export default class Approval extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
        };
      
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/upload');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { handleGetKelas} = this.props;
        return (
          <div className="student-list">
               
                   <div className="nilai-kognitif__custom-form">
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Input
                          type="date"
                          name="namaSaudara1"
                          label="Pilih Bulan & Tahun"
                          onChange={this._onFormChange}
                          data={ '7A', 'kelas7a'}
                          placeholder={'Kelas 7A'}
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                        
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                          name="tahunajaran"
                          label="Unit"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Semua'}
                        />
                      </div>

                      <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                          name="tahunajaran"
                          label="Status"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Semua'}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                          name="tahunajaran"
                          label="Tipe"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'Semua'}
                        />
                      </div>
                      </div>
                    </div>
                    <div className="upload-nilai__body">
                      <table className="upload-nilai__table">
                        <thead>
                          <tr>
                            <th>User ID</th>
                            <th>Nama Pegawai</th>
                            <th>Tanggal</th>
                            <th>Keterangan</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {
                          map(journal.details, (list, idx) => (
                            <tr key={`journal_detail_${idx}`}>
                              <td>{idx + 1}</td>
                              <td>{list.code_of_account}</td>
                              <td>{list.parameter_code.title}</td>
                              <td>{list.description}</td>
                              <td className="nominal">{commaFormatted(list.amount)}</td>
                            </tr>
                          ))
                        } */}
                          <tr>
                              <td>1</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td><div className="table__actions">
                                    
                                      
                                    <Button
                                      onClick={this._onClickEdit}
                                      style={{backgroundColor:'#35E82C'}}
                                      title="Setujui"
                                    />
                                    <Button
                                      style={{backgroundColor:'red'}}
                                      // onClick={this.getClickHandler(list, 'delete')}
                                      title="Tolak"
                                    />
                                </div></td>
                          </tr>
                      
                        </tbody>
                    </table>
                  </div>
                 

          </div>
        );
      }
}
Approval.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Approval.defaultProps = {
    
  handleGetStudent: noop,
};
