import { connect } from 'react-redux';
import CetakBuktiDetail from '../../../../views/Finance/Spp/Pembayaran/CetakBuktiDetail.pembayaran.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
  };
}

export default connect(null, mapDispatchToProps)(CetakBuktiDetail);
