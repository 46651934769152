import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../../utils/transformer.util';
import { UPLOAD_ABSENSI_HARIAN_FORM_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';
import { Button } from '../../../components/base';

export default class UploadAbsensiHarian extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      files,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputType === 'file') {
        [formattedValue] = files;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleUploadStudent, history } = this.props;
    handleUploadStudent(form.value, history.goBack);
  }

  render() {
    const { form } = this.state;
    return (
      <div className="upload-absensi-harian">
        <div className="budget__title">
          <h1>Upload Absensi Harian</h1>
          <hr></hr>

        </div>
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={UPLOAD_ABSENSI_HARIAN_FORM_FIELDS}
            onFormChange={this._onFormChange}
          />
          <div className="upload-absensi-harian">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
UploadAbsensiHarian.propTypes = {
  handleUploadStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
UploadAbsensiHarian.defaultProps = {
  handleUploadStudent: noop,
};
