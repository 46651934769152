import { connect } from 'react-redux';
import NonBudget from '../../../../views/Finance/Budgeting/NonBudget/NonBudget.finance.view';
import { getNonBudget, deleteNonBudget } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetNonBudget: (payload, unit_id) => dispatch(getNonBudget(payload, unit_id)),
    handleDeleteNonBudget: payload => dispatch(deleteNonBudget(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NonBudget);
