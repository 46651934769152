import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../components/base';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { UPLOAD_TABLE_FIELDS } from '../../constants/upload.constant';

export default class Upload extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/upload-file');
  }

  render() {
    const { handleGetUpload, handleDeleteUpload } = this.props;
    return (
      <div className="upload">
        <Button
          title="Upload File"
          onClick={this.onClickAdd}
        />
        <div className="upload__content">
          <ContentTable
            onSearchContent={handleGetUpload}
            tableFields={UPLOAD_TABLE_FIELDS}
            onClickDelete={handleDeleteUpload}
          />
        </div>
      </div>
    );
  }
}
Upload.propTypes = {
  handleGetUpload: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Upload.defaultProps = {
  handleGetUpload: noop,
  handleDeleteUpload: noop,
};
