import React,  {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class IsiPerBidangForm extends Component {
  constructor(props){
    super(props);
    this._onFormChange = this._onFormChange.bind(this);

 this.state ={
     form: {
         values: {
             classes: '',
             keterangan:'',
             kompetensi:'',
             tahunajaran: '',
         },
         error: {
             classes: '',
             keterangan:'',
             kompetensi:'',
             tahunajaran: '',
         }
     }
 }
 }

_onFormChange(e) {
 const { onChange } = this.props;
 let change = {}
 change[e.target.name] = e.target.value
 this.setState(change , () => { console.log(this.state) })

 onChange(e);
}


render(){
  const { onChange, form, classes } = this.props;
  return (
    <div className="isi-bidang__custom-form">
      <div className="isi-bidang__custom-form-column">
        <div className="isi-bidang__custom-form-row__field">
          <Input
            name="classes_year"
            label="Tahun Ajaran"
            onChange={this._onFormChange}
            value={form.value.classes_year}
            disabled
            error={form.error.classes_year || ''}
          />
          
          <div className="isi-bidang__custom-form-row__field"></div>
          <Input
            name="classes"
            label="Kelas"
            data={classes.list}
            disabled
            onChange={this._onFormChange}
            value={form.value.classes}
            error={form.error.classes || ''}
          />
         </div>
       
         
         <div className="isi-bidang__custom-form-column__field">
        <Input
            type="text"
            name="kompetensi"
            label="Kompetensi yang Diajar"
            onChange={this._onFormChange}
            style={{backgroundColor:'#ffff00'}}
            value={form.value.kompetensi || ''}
            error={form.error.kompetensi || ''}
          />

        <Textarea
            type="text"
            name="keterangan"
            label="Keterangan"
            onChange={this._onFormChange}
            value={form.value.keterangan || ''}
            error={form.error.keterangan || ''}
          />
           </div>
           
      </div>
    </div>
  );
}


 
}
IsiPerBidangForm.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.array,
  form: PropTypes.object.isRequired,
};
IsiPerBidangForm.defaultProps = {
  onChange: noop,
  classes: [],
};
