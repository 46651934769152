import { connect } from 'react-redux';
import BudgetType from '../../../../views/Finance/Budgeting/Budget/BudgetType.finance.view';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(BudgetType);
