import { connect } from 'react-redux';
import AbsensiRekapGrafik from '../../../views/Student/absensi/AbsensiRekapGrafik.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getLevels} from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    handleGetLevels: payload => dispatch(getLevels(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsensiRekapGrafik);


