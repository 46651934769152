import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

export default class Path extends PureComponent {
  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    const { onClick, index } = this.props;
    onClick(index);
  }

  render() {
    const { index, path } = this.props;
    return (
      <li className="breadcrumb__item">
        <button onClick={this._onClick}>
          {index === 0 && <span className="icon-home" />}
          {path}
        </button>
      </li>
    );
  }
}
Path.propTypes = {
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
};
Path.defaultProps = {
  onClick: noop,
};
