import { connect } from 'react-redux';
import CatatSiswaKeluar2 from '../../../views/Student/DataSiswa/CatatSiswaKeluar2.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getStudentOptions, getParamOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { saveExitRecord, listExitRecord, getExitRecord, editExitRecord } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetExitRecord: payload => dispatch(getExitRecord(payload)),
    handleSaveExitRecord: (payload, goback) => dispatch(saveExitRecord(payload, goback)),
    handleListExitRecord: (payload, goback) => dispatch(listExitRecord(payload, goback)),
    handleEditExitRecord: (payload, goback) => dispatch(editExitRecord(payload, goback)), 
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatSiswaKeluar2);


