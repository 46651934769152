import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      
     <div className="laporan-ktsp__header-content">
       
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="divPrint" style={{width:800, border: '0px solid black'}}>
       
            <div className="laporan-ktsp__content">
                <div className="laporan-ktsp__header">
                    <div className="laporan-ktsp__custom-form-row-square">
                        <div className="laporan-ktsp__box--square2">
                            Image
                        </div>
                        <div className="laporan-ktsp__header-title">
                            <div className="laporan-ktsp__header-title--column">
                                {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                LAPORAN HASIL BELAJAR SISWA
                            </div>
                            <div className="laporan-ktsp__header-title--column">
                                {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                SEKOLAH MENENGAH PERTAMA
                            </div> 
                        </div>
                    </div>
                </div>
            </div>  
            <div className="laporan-ktsp__header-title3">
                <div className="laporan-ktsp__header-title3--left">
                    <table className="laporan-ktsp__table-test">
                        <tr> 
                            <td></td>    
                            <td>Nama Siswa </td>
                            <td>  : Gange</td> 
                        </tr> 
                        <tr>
                            <td></td>
                            <td>Nomor Induk </td>
                            <td>  : SMP Katolik Ricci</td> 
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td>No.ID.Reg</td>
                            <td>  : SMP Katolik Ricci</td> 
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td>Nama Sekolah</td>
                            <td>  : SMP Katolik Ricci</td> 
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td>Alamat Sekolah</td>
                            <td>  : SMP Katolik Ricci</td> 
                            
                        </tr>
                        
                    </table> 
                </div>

                <div className="laporan-ktsp__header-title3--right">
                    <table className="laporan-ktsp__table-test">
                        <tr> 
                            <td></td>    
                            <td>Tahun Ajaran </td>
                            <td>  : SMP Katolik Ricci</td> 
                        </tr> 
                        <tr>
                            <td></td>
                            <td>Periode</td>
                            <td>  : SMP Katolik Ricci</td> 
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td>Kelas / No.Absen</td>
                            <td>  : SMP Katolik Ricci</td> 
                                
                        </tr>
                    </table>
                </div>
            </div>

            <div className="laporan-ktsp__body">
                <table className="laporan-ktsp__table">
                    <thead>
                    <tr>
                        <th rowSpan={2}>NO</th>
                        <th rowSpan={2}>Mata Pelajaran</th>
                        <th rowSpan={2}>KKM</th>
                        <th colSpan={2}>NILAI</th>
                        <th rowSpan={2}>Catatan</th>
                    </tr>
                    <tr>
                        <th>Angka</th>
                        <th>Huruf</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div className="laporan-ktsp__body">
                <table className="laporan-ktsp__table">
                    <thead>
                        <tr>
                            <th>Ekstrakulikuler</th>
                            <th>NILAI</th>
                            <th >Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="laporan-ktsp__body">
                <table className="laporan-ktsp__table">
                    <thead>
                        <tr>
                            <th colSpan={2}>Ketidakhadiran</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Sakit</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Ijin</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Alpha</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="laporan-ktsp__header-title2">
                <div className="laporan-ktsp__header-title2--column">
                    KESIMPULAN
                    <div className="laporan-ktsp__header-title2--column">
                        Dinyatakan NAIK/TIDAK NAIK Ke Kelas VII
                    </div>
                </div>
            </div>

            <div className="laporan-ktsp__custom-form-row">
                <div className="laporan-ktsp__custom-form-row__field">
                    <div className="laporan-ktsp__custom-form-column">
                        <p>Mengetahui</p>
                        <div className="laporan-ktsp__custom-form-column">
                        <p>Orang Tua Wali</p>
                        </div>
                        <div className="laporan-ktsp__custom-form-column__field">
                        <p>.....................................</p>
                        </div>
                    </div>
                    <div className="laporan-ktsp__custom-form-column2">
                        <p>Jakarta,</p>
                        <div className="laporan-ktsp__custom-form-column">
                            <p>Kepala Sekolah</p>
                        </div>
                        <div className="laporan-ktsp__custom-form-column2__field">
                            <p>(&emsp; &emsp; &emsp; &emsp; &emsp;)</p>
                        </div>
                    </div>
                    <div className="laporan-ktsp__custom-form-column3">
                        <p>Wali Kelas</p>
                        <div className="laporan-ktsp__custom-form-column3__field">
                            <p>(&emsp; &emsp; &emsp; &emsp; &emsp;)</p>
                        </div>
                    </div>
                </div>
            </div>
                   
        </div>
    </div>
    );
  }

}
export default class LaporanHasilKTSP extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
LaporanHasilKTSP.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
LaporanHasilKTSP.defaultProps = {
    handleGetCashJournal: noop,
  };
  