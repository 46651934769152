import { connect } from 'react-redux';
import AddBudget from '../../../../views/Finance/Budgeting/Budget/AddBudget.finance.view';
import { addBudget } from '../../../../states/thunks/finance.thunk';
import { getPeriodeOptions, getExistingPeriodeOptions } from '../../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAddBudget: (payload, goBack, unit_id) => dispatch(addBudget(payload, goBack, unit_id)),
    handleGetPeriodeOptions: (payload) => dispatch(getPeriodeOptions(payload)),
    handleGetExistingPeriodeOptions: (payload) => dispatch(getExistingPeriodeOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBudget);
