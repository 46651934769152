import React from 'react';
import PropTypes from 'prop-types';
import {
  map, noop,
} from 'lodash';
import {
  FileInput, Input, Select, SearchSelect, Checkbox, Textarea,
  Option,
} from '../base';
import SelectCoa from '../SelectCoa/SelectCoa.component';

export default function ListForm2(props) {
  const {
    formFields,
    onFormChange, form,
    children, coa, bank_account, onSearchCoa,
    handleUploadFile,
  } = props;
  let inputField = null;
  return (
    <div className="list-form2__wrapper">
      {
        map(formFields, (field, idx) => {
          const { full = false, isOther = false } = field;
          const fullClassname = full ? 'list-form2__content--full' : 'list-form2__content';
          switch (field.type) {
            case 'select': {
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <Select
                    disabled={field.disabled || false}
                    isOther={isOther}
                    name={field.name}
                    otherName={`${field.name}_other`}
                    label={field.label}
                    data={(field.name === 'rekening') ? bank_account : field.data}
                    onChange={onFormChange}
                    placeholder={field.placeholder}
                    value={form.value[field.name] || ''}
                    otherValue={form.value[`${field.name}_other`] || ''}
                    error={form.error[field.name] || ''}
                  />
                </div>
              );
              break;
            }
            case 'searchSelect': {
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <SearchSelect
                    disabled={field.disabled || false}
                    async={field.async}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    onSearch={field.handleSearchContent}
                    onClick={onFormChange}
                    value={form.value[field.name]}
                    error={form.error[field.name] || ''}
                  />
                </div>
              );
              break;
            }
            case 'selectCoa': {
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <SelectCoa
                    disabled={field.disabled || false}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    coa={coa}
                    onSearchCoa={onSearchCoa}
                    onClick={onFormChange}
                    value={form.value[field.name]}
                    error={form.error[field.name] || ''}
                    rightIcon="icon-search"
                  />
                </div>
              );
              break;
            }
            case 'checkbox': {
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <Checkbox
                    name={field.name}
                    label={field.label}
                    onChange={onFormChange}
                    checked={form.value[field.name] || false}
                  />
                </div>
              );
              break;
            }
            case 'textarea': {
              const { disabled = false } = field;
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <Textarea
                    data-input-type={field.inputType}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[field.name] || ''}
                    error={form.error[field.name] || ''}
                    rows={4}
                  />
                </div>
              );
              break;
            }
            case 'file': {
              const { disabled = false } = field;
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <FileInput
                    data-input-type={field.inputType}
                    type="file"
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    error={form.error[field.name] || ''}
                  />
                </div>
              );
              break;
            }
            case 'dateOfBirth': {
              const {
                disabled = false,
                placeName, placeLabel,
                dateName, dateLabel,
              } = field;
              inputField = (
                <div key={`list_form_${idx}`} className={`${fullClassname} list-form2__date-of-birth`}>
                  <Input
                    data-input-type={field.inputType}
                    type="text"
                    name={placeName}
                    label={placeLabel}
                    placeholder={field.placeholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[placeName] || ''}
                    error={form.error[placeName] || ''}
                  />
                  <Input
                    data-input-type={field.inputType}
                    type="date"
                    name={dateName}
                    label={dateLabel}
                    placeholder={field.placeholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[dateName] || ''}
                    error={form.error[dateName] || ''}
                  />
                </div>
              );
              break;
            }
            case 'dualInput': {
              const {
                disabled = false,
                leftType = 'text', rightType = 'text',
                leftName, leftLabel,
                rightName, rightLabel,
                leftPlaceholder, rightPlaceholder,
              } = field;
              inputField = (
                <div key={`list_form_${idx}`} className={`${fullClassname} list-form2__date-of-birth`}>
                  <Input
                    data-input-type={field.inputType}
                    type={leftType}
                    name={leftName}
                    label={leftLabel}
                    placeholder={leftPlaceholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[leftName] || ''}
                    error={form.error[leftName] || ''}
                  />
                  <Input
                    data-input-type={field.inputType}
                    type={rightType}
                    name={rightName}
                    label={rightLabel}
                    placeholder={rightPlaceholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[rightName] || ''}
                    error={form.error[rightName] || ''}
                  />
                </div>
              );
              break;
            }
            case 'title': {
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <p className="list-form2__title">
                    {field.text}
                  </p>
                </div>
              );
              break;
            }
            case 'option': {
              const { disabled = false } = field;
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <Option
                    name={field.name}
                    label={field.label}
                    data={field.data}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[field.name] || ''}
                    error={form.error[field.name] || ''}
                  />
                </div>
              );
              break;
            }
            case 'empty': {
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname} />
              );
              break;
            }
            default: {
              const { disabled = false } = field;
              inputField = (
                <div key={`list_form_${idx}`} className={fullClassname}>
                  <Input
                    data-input-type={field.inputType}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    onChange={onFormChange}
                    disabled={disabled}
                    value={form.value[field.name] || ''}
                    error={form.error[field.name] || ''}
                  />
                </div>
              );
            }
          }
          return [
            full && <div key={`spacer__${idx}`} />,
            inputField,
          ];
        })
      }
      {children}
    </div>
  );
}
ListForm2.propTypes = {
  formFields: PropTypes.array,
  coa: PropTypes.object,
  onFormChange: PropTypes.func,
  form: PropTypes.object,
  children: PropTypes.node,
  bank_account: PropTypes.array,
  onSearchCoa: PropTypes.func,
  handleUploadFile: PropTypes.func,
};
ListForm2.defaultProps = {
  formFields: [],
  coa: { loading: true, list: [] },
  form: {},
  onFormChange: noop,
  children: <div />,
  bank_account: null,
  onSearchCoa: noop,
  handleUploadFile: noop,
};
