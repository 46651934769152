import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../components/base';
import { Link } from 'react-router-dom';
import ReactToPrint from "react-to-print";


class ComponentToPrint extends Component {

    constructor(props) {
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
      _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
      }
    
      _onDeleteList(idx) {
        const { onDeleteList, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteList(idx, fieldName);
      }
    
      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }
    
      render() {
        const {
          list, error, onChange,
          fieldName,
        } = this.props;
    
        const Matpel = [
          { label: 'Bahasa Indonesia', value: 'bindo' },
          { label: 'Matematika', value: 'mtk' },
          { label: 'Pendidikan Kewarganegaraan', value: 'pkn' },
          { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: 'penjaskes' },
          
        ];
    
        const Kelas = [
          { label: 'Kelas 1', value: 'kelas1'},
          { label: 'kelas 2', value: 'kelas2'},
          { label: 'kelas 3', value: 'kelas3'},
        ]
    
return (
    <div id="divPrint" style={{width:800, border: '0px solid black'}}>
        <div className="table-range__header">
        
          <table className="estimasi__form-table table">
            <thead>
              <tr>
                <th rowSpan={2}>Kelas</th>
                <th colSpan={2}>Islam</th>
                <th colSpan={2}>Katolik</th>
                <th colSpan={2}>Protestan</th>
                <th colSpan={2}>Hindu</th>
                <th colSpan={2}>Budha</th>
                <th colSpan={2}>Kong Hu Cu</th>
                <th colSpan={2}>Katakumen/Simpatisan</th>
                <th rowSpan={2}>Lainnya</th>
                <th rowSpan={2}>Jumlah</th>
              </tr>
              <tr>
                  <th>L</th>
                  <th>P</th>
                  <th>L</th>
                  <th>P</th>
                  <th>L</th>
                  <th>P</th>
                  <th>L</th>
                  <th>P</th>
                  <th>L</th>
                  <th>P</th>
                  <th>L</th>
                  <th>P</th>
                  <th>L</th>
                  <th>P</th>
              </tr>
             
            </thead>
            <tbody>
              {
                map(list, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   
                  </tr>
                ))
              }
             
            </tbody>
          </table>
          </div>
          </div>
        );
      }
}
export default class KomposisiSiswaAgama extends Component {
    constructor(props){
        super(props);
    
        this.state={
          journal: {},
        }
      }
    
      
      render() {
        return (
          <div className="content-wrapper">
              <section className="content-header">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="box">
    
                            <div className="divContainter">
                              <div>
                                <ReactToPrint
                                  trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                                  content={() => this.componentRef}
                                />
                                <ComponentToPrint ref={el => (this.componentRef = el)} />
                              </div>
    
                            </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
          
        );
      }
}

KomposisiSiswaAgama.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  
};
KomposisiSiswaAgama.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};