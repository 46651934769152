import { connect } from 'react-redux';
import LaporanPersensi from '../../../views/Student/report/LaporanPersensi.student.view';
import { manageRegistration } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LaporanPersensi);
