import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  noop, keys, dropWhile, dropRightWhile, cloneDeep, filter, isEqual,
  startsWith, lowerCase } from 'lodash';
import SelectCoa from '../SelectCoa/SelectCoa.component';
import { Input, Pagination, Button, SearchSelect } from '../base';

export default class ReportCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {
          reportType: { value: 'konsolidasi' },
        },
        error: {},
      },
      coaOptions: [],
      filteredCoa: {},
      selectedCoa: {},
    };

    this._onFormChange = this._onFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._renderSelectCoa = this._renderSelectCoa.bind(this);
    this._onSearchCoa = this._onSearchCoa.bind(this);
    this._renderSelectBankAccount = this._renderSelectBankAccount.bind(this);
    this._renderReportType = this._renderReportType.bind(this);
  }

  componentDidMount() {
    const { coa = {}, coaRangeSelector } = this.props;
    const { filteredCoa } = this.state;

    if (Object.keys(filteredCoa).length === 0) {
      this.setState(prevState => ({
        ...prevState,
        coaOptions: coa,
        filteredCoa: (coaRangeSelector) ? {
          from: coa || {},
          to: coa || {},
        } : coa,
        reportType: 'konsolidasi',
        perwakilanId: null,
        unitId: null,
        pos: null,
      }));
    }
  }

  _onFormChange(event) {
    const {
      name,
      value,
      type,
      dataset,
      checked = false,
    } = event.target;
    const { onChangeReportType, onChangePerwakilan } = this.props;
    const { fieldName } = dataset;
    this.setState((prevState) => {
      const { form } = prevState;
      const { value: prevValue } = form;
      const selectedCoa = { ...prevState.selectedCoa };
      let formattedValue = value;
      if (type === 'checkbox') {
        formattedValue = checked;
      }

      if (fieldName === 'coa') {
        selectedCoa[name] = value;
        formattedValue = { ...prevValue[fieldName] };
        formattedValue[name] = value;
      }

      if (name === 'reportType') {
        if (value.value === 'unit') {
          prevValue.perwakilanId = {};
        } else {
          prevValue.unitId = {};
        }
      }

      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            ...(fieldName
              ? { [fieldName]: formattedValue }
              : { [name]: formattedValue }
            ),
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
        selectedCoa,
      };
    }, () => {
      if (fieldName === 'coa') {
        this._onSearchCoa('', name);
      }
      if (fieldName === 'reportType') {
        onChangeReportType(value);
      }
      if (fieldName === 'perwakilan') {
        onChangePerwakilan(value);
      }
    });
  }

  _onSearch() {
    const { form } = this.state;
    const { onSearch } = this.props;
    onSearch(form.value);
  }

  async _onSearchCoa(keyword, field) {
    const { onSearchCoa, coaRangeSelector } = this.props;
    const { selectedCoa = {}, coaOptions: coa, filteredCoa } = this.state;
    let newFilteredCoa = coa || {};

    if (coaRangeSelector) {
      const { from = {}, to = {} } = selectedCoa;
      newFilteredCoa = {
        from: cloneDeep(coa),
        to: cloneDeep(coa)
      };

      if (keys(from).length > 0 && from.code !== '') {
        newFilteredCoa.to.list = await dropWhile(coa.list, (o) => {
          return o.code !== from.code;
        });
      }

      if (keys(to).length > 0 && to.code !== '') {
        newFilteredCoa.from.list = await dropRightWhile(coa.list, (o) => {
          return o.code !== to.code;
        });
      }
    }

    if(keyword) {
      if(coaRangeSelector) {
        newFilteredCoa[field].list = await filter(newFilteredCoa[field].list, (o) => {
          return startsWith(o.code, keyword) || startsWith(lowerCase(o.title), lowerCase(keyword));
        });
      } else {
        newFilteredCoa.list = await filter(newFilteredCoa.list, (o) => {
          return startsWith(o.code, keyword) || startsWith(lowerCase(o.title), lowerCase(keyword));
        });
      }
    }

    this.setState(prevState => ({
      ...prevState,
      filteredCoa: newFilteredCoa || {}
    }))
  }

  _renderSelectBankAccount() {
    const { bankAccountOptions } = this.props;
    const { form } = this.state;
    const { value } = form;
    return (
      <SearchSelect
        async={false}
        name="bank_account"
        label="Pilih Rekening"
        list={bankAccountOptions.list}
        onChange={this._onFormChange}
        placeholder="Pilih Rekening"
        value={value.bank_account}
        labelName="title"
        valueName="id"
      />
    )
  }

  _renderSelectCoa() {
    const { form, filteredCoa = {} } = this.state;
    const { value } = form;
    const { coa: coaValue = {} } = value;
    const { coa: {}, onSearchCoa, coaRangeSelector } = this.props;

    if (coaRangeSelector) {
      return (
        <div className="form-group inline">
          <div className="form-group-label">
            <label className="input__label">Pilih Kode Akun</label>
          </div>
          <div className="form-group-member">
            <SelectCoa
              noMargin
              fieldName="coa"
              name="from"
              placeholder="Dari"
              coa={filteredCoa.from || {}}
              value={coaValue.from}
              rightIcon="icon-search"
              onClick={this._onFormChange}
              onSearchCoa={(keyword) => { this._onSearchCoa(keyword, 'from'); }}
            />
          </div>
          <div className="form-group-member">
            <SelectCoa
              noMargin
              name="to"
              fieldName="coa"
              placeholder="Sampai"
              coa={filteredCoa.to || {}}
              value={coaValue.to}
              rightIcon="icon-search"
              onClick={this._onFormChange}
              onSearchCoa={(keyword) => { this._onSearchCoa(keyword, 'to'); }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="form-group-member">
        <SelectCoa
          noMargin
          inputArray
          arrayPosition={0}
          levelFour
          fieldName="coa"
          name="coa"
          placeholder="Pilih Kode Akun"
          coa={filteredCoa}
          value={form.value.coa}
          rightIcon="icon-search"
          onClick={this._onFormChange}
          onSearchCoa={this._onSearchCoa}
          label="Kode Akun"
        />
      </div>
    );
  }

  _renderReportType() {
    const { form } = this.state;
    const { value } = form;
    const { reportType = {} } = value;
    const { reportTypes, perwakilan, units } = this.props;

    return (
      <>
        <div className="form-group-member">
          <SearchSelect
            async={false}
            name="reportType"
            label="Pilih Jenis Laporan"
            list={reportTypes}
            onChange={this._onFormChange}
            placeholder="Jenis Laporan"
            value={reportType}
            labelName="label"
            valueName="value"
          />
        </div>
        {
        reportType.value === 'konsolidasi' && (
          <div className="form-group-member">
            <SearchSelect
              async={false}
              name="perwakilanId"
              label="Pilih Unit Kerja"
              list={perwakilan}
              onChange={this._onFormChange}
              placeholder="Unit Kerja"
              value={value.perwakilanId}
              labelName="title"
              valueName="id"
            />
          </div>
        )
        }
        {
          reportType.value === 'unit' && (
            <div className="form-group-member">
              <SearchSelect
                async={false}
                name="unitId"
                label="Pilih Unit Kerja"
                list={units}
                onChange={this._onFormChange}
                placeholder="Unit Kerja"
                value={value.unitId}
                labelName="title"
                valueName="id"
              />
            </div>
          )
        }
      </>
    );
  }

  render() {
    const { form } = this.state;
    const { value } = form;
    const {
      paging,
      children,
      header,
      coa,
      currentPage,
      totalPage,
      bankAccountSelector,
      reportTypes,
      pos,
      role,
    } = this.props;
    return (
      <div className="report-card">
        {header}
        {
          reportTypes && (
            <div className="report-card__header">
              <div className="report-card__entries">
                <div className="form-group inline">
                  { (role === 'pusat' || role === 'perwakilan') && this._renderReportType()}
                  {
                    pos && (
                      <div className="form-group-member">
                        <SearchSelect
                          async={false}
                          name="pos"
                          label="Pilih Pos"
                          list={pos}
                          onChange={this._onFormChange}
                          placeholder="Pos"
                          value={value.pos}
                          labelName="title"
                          valueName="id"
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }

        <div className="report-card__header">
          <div className="report-card__entries">
            <div className="form-group inline">
              {coa !== null && (
                <div className="form-group-member">
                  {this._renderSelectCoa()}
                </div>
              )}
              {
                bankAccountSelector && (
                  <div className="form-group-member">
                    {this._renderSelectBankAccount()}
                  </div>
                )
              }
              <div className="form-group-member">
                <Input
                  type="date"
                  label="Dari"
                  name="from"
                  value={form.value.from}
                  onChange={this._onFormChange}
                />
              </div>
              <div className="form-group-member">
                <div className="form-group-member">
                  <Input
                    type="date"
                    label="Sampai"
                    name="to"
                    value={form.value.to}
                    onChange={this._onFormChange}
                  />
                </div>
              </div>
              <div className="form-group-member">
                <Button
                  title="Cari"
                  onClick={this._onSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="report-card__body">
          {children}
        </div>
        {
          paging && (
            <div className="report-card__footer">
              <p className="user-management__page-info">
                {`Halaman ${currentPage} dari ${totalPage}`}
              </p>
              <Pagination
                totalPage={totalPage}
                currentPage={currentPage}
              />
            </div>
          )
        }
      </div>
    );
  }
}
ReportCard.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  paging: PropTypes.bool,
  onSearch: PropTypes.func,
  onSearchCoa: PropTypes.func,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  coa: PropTypes.object,
  coaRangeSelector: PropTypes.bool,
  bankAccountSelector: PropTypes.bool,
  bankAccountOptions: PropTypes.object,
  reportTypes: PropTypes.array,
  perwakilan: PropTypes.array,
  units: PropTypes.array,
  pos: PropTypes.array,
  role: PropTypes.string,
};
ReportCard.defaultProps = {
  paging: true,
  header: null,
  onSearch: noop,
  onSearchCoa: noop,
  currentPage: 1,
  totalPage: 1,
  coa: null,
  coaRangeSelector: false,
  bankAccountSelector: false,
  bankAccountOptions: {},
  reportTypes: null,
  perwakilan: null,
  units: null,
  pos: null,
  role: 'unit',
};
