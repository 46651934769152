import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { journal } = this.state;
    return (
      
     <div className="raport-pts__header-content">
       
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="divPrint" style={{width:800, border: '0px solid black'}}>
       
                <div className="raport-pts__content">
                  <div className="raport-pts__header">
                      <div className="raport-pts__custom-form-row-square">
                          <div className="raport-pts__box--square2">
                              Image
                          </div>
                          <div className="raport-pts__header-title">
                              <div className="raport-pts__header-title--column">
                                  {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                  LAPORAN PENILAIAN TENGAH SEMESTER GANJIL/GENAP
                              </div>
                              <div className="raport-pts__header-title--column2">
                                  {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                  SEKOLAH MENENGAH PERTAMA SPIN INTERNATIONAL
                              </div>
                              <div className="raport-pts__header-title--column2">
                                  {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                                  TAHUN PELAJARAN 2019-2020
                              </div>
                            </div>
                        </div>
                    </div>
                </div>  
                    <div className="raport-pts__header-title3">
                        <div className="raport-pts__header-title3--left">
                            <table className="raport-pts__table-test">
                                <tr> 
                                    <td></td>    
                                    <td>Nama Siswa </td>
                                    <td>  : Gange</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>Nomor Induk </td>
                                    <td>  : SMP Katolik Ricci</td> 
                                    
                                </tr>
                            </table> 
                        </div>
                     </div>

                     <div className="raport-pts__header-title-table">
                        A. Sikap
                    </div>

                     <div className="raport-pts__body">
                         <table className="raport-pts__table">
                             <thead>
                                <tr>
                                    <th rowSpan={3}>NO</th>
                                    <th rowSpan={3}>MATAPELAJARAN</th>
                                    <th rowSpan={3}>KKM </th>
                                    <th colSpan={2}>PENGETAHUAN</th>
                                    <th rowSpan={2}>KETERAMPILAN</th>
                                </tr>
                                <tr>
                                    <th>PENILAIAN HARIAN</th>
                                    <th rowSpan={2}>PTS</th>
                                </tr>
                                <tr>
                                    <th>UH 1</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>NAMA MATA PELAJARAN</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                         </table>
                     </div>
                    <div className="raport-pts__header-title2">
                        <div className="raport-pts__header-title2--column">
                            <div className="raport-pts__header-title2--bold">
                                Kesimpulan
                            </div>
                            <div className="raport-pts__header-title2--row">
                                <div className="raport-pts__header-title2--row">
                                        Dinyatakan &nbsp;
                                </div>
                                <div className="raport-pts__header-title2--bold">
                                    NAIK/TIDAK NAIK
                                </div>
                                <div className="raport-pts__header-title2--row">
                                    &nbsp;ke kelas VII / Program Studi:Ilmu Alam atau Ilmu Sosial atau Ilmu Bahasa
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="raport-pts__custom-form-row">
                        <div className="raport-pts__custom-form-row__field">
                            <div className="raport-pts__custom-form-column">
                                <p>Mengetahui</p>
                                <div className="raport-pts__custom-form-column">
                                    <p>Orang Tua Wali</p>
                                </div>
                                <div className="raport-pts__custom-form-column__field">
                                    <p>.....................................</p>
                                </div>
                            </div>

                            <div className="raport-pts__custom-form-column3">
                                <p>Jakarta,</p>
                                <div className="raport-pts__custom-form-column">
                                    <p>Wali Kelas</p>
                                </div>
                                <div className="raport-pts__custom-form-column3__field2">
                                    <p>(&emsp; &emsp; &emsp; &emsp; &emsp;)</p>
                                </div>
                            </div>
                        </div>
                     </div>
                   
               
        </div>
      </div>
    );
  }

}
export default class RaportPTS extends Component {
  constructor(props){
    super(props);

    this.state={
      journal: {},
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
RaportPTS.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
RaportPTS.defaultProps = {
    handleGetCashJournal: noop,
  };
  