import { connect } from 'react-redux';
import ManageClosingJournal from '../../../../views/Finance/Bookkeeping/ClosingJournal/ManageClosingJournal.bookkeeping.view';
import { getClosingJournalById, manageClosingJournal } from '../../../../states/thunks/finance.thunk';
import { getCoa } from '../../../../states/thunks/common.thunk';

function mapStateToProps(state) {
  return {
    coa: state.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoa(payload)),
    handleGetClosingJournal: payload => dispatch(getClosingJournalById(payload)),
    handleManageClosingJournal: (payload, goBack) => (
      dispatch(manageClosingJournal(payload, goBack))
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageClosingJournal);
