import {
  isEmpty,
  each,
  forEach,
} from 'lodash';
import {
  normalizeAmount, sumActualSemesterAmount, sumBudgetTransferSource,
  sumExpectedSemesterAmount,
} from './transformer.util';
import { BUDGET_REQUIRED_FIELD } from '../constants/finance/budgeting/budget.constant';
import { BUDGET_TRANSFER_REQUIRED_FIELD } from '../constants/finance/budgeting/budgetTransfer.constant';
import { FUND_REQUEST_REQUIRED_FIELD } from '../constants/finance/budgeting/fundRequest.constant';
import {
  VALIDATION_FORM_PENDAFTARAN
} from '../constants/student.constant';

export function validateRequiredFields(values, fields) {
  const errors = {};
  each(fields, ((field) => {
    if (!values[field]) {
      errors[field] = 'Harus di isi';
    }
  }));
  return errors;
}

export function validateManageBudget(payload = {}) {
  const { semesterOne, semesterTwo } = payload;
  const semesterTwoError = [];
  const semesterOneError = [];
  forEach(semesterTwo, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      semesterTwoError.push(error);
    }
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error.amount = 'Jumlah salah';
    }
  });

  forEach(semesterOne, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      semesterOneError.push(error);
    }
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error.amount = 'Jumlah salah';
    }
  });
  return {
    semesterTwo: semesterTwoError,
    semesterOne: semesterOneError,
  };
}

export function checkEmptyObject(objs) {
  const valid = [];
  each(objs, ((val) => {
    if (val) {
      valid.push('valid');
    }
  }));
  return isEmpty(valid);
}

export function validateSemesterAmount(payload) {
  const { semesterOne, semesterTwo } = payload;
  let error = false;
  forEach([...semesterOne, ...semesterTwo], (data) => {
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error = true;
    }
  });
  return error;
}

export function validateBudgetTransfer(payload = {}) {
  const { selectedBudget, rapbuSource } = payload;
  const selectedBudgetError = [];
  const rapbuSourceError = [];
  let totalTransferError = '';
  const totalRapbu = sumBudgetTransferSource(rapbuSource);
  let totalAmount = 0;
  forEach(selectedBudget, (data) => {
    const { remains = 0, total = 0, amount = 0 } = data;
    totalAmount += normalizeAmount(amount);
    const error = validateRequiredFields(data, BUDGET_TRANSFER_REQUIRED_FIELD);
    if (error) {
      selectedBudgetError.push(error);
    }
    const expectedAmount = remains ? normalizeAmount(remains) : normalizeAmount(total);
    if (expectedAmount < normalizeAmount(amount)) {
      error.amount = 'Jumlah tidak cukup';
    }
  });

  forEach(rapbuSource, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      rapbuSourceError.push(error);
    }
  });
  if (totalAmount < totalRapbu) {
    totalTransferError = 'Total pengalihan dana tidak cukup';
  }
  return {
    selectedBudget: selectedBudgetError,
    rapbuSource: rapbuSourceError,
    totalTransferError,
  };
}

export function validateFundRequest(payload = {}) {
  const { selectedBudget = {}, amount = 0 } = payload;
  const { total = 0, remains = 0 } = selectedBudget;
  const error = validateRequiredFields(payload, FUND_REQUEST_REQUIRED_FIELD);
  const expectedAmount = remains ? normalizeAmount(remains) : normalizeAmount(total);
  if (expectedAmount < normalizeAmount(amount)) {
    error.amount = 'Jumlah tidak cukup';
  }
  return error;
}

export function validateAdjustmentJournal(payload = {}) {
  const {
    source,
    type,
    corrections,
    total_debit,
    total_credit,
    selected_ids,
    date,
  } = payload;
  const error = {};

  if (!date) {
    return { date: 'Tanggal belum dipilih' };
  }

  if (!source.id) {
    return { source_journal_id: 'Jurnal belum dipilih' };
  }

  if (!type) {
    return { type: 'Tipe belum dipilih' };
  }

  if (selected_ids.length === 0) {
    return 'Belum ada transaksi yang dipilih';
  }

  if (!corrections || corrections.length === 0) {
    return 'Koreksi tidak boleh kosong';
  }
  corrections.forEach((item, index) => {
    if (!item.code_of_account) {
      if (!error.corrections) {
        error.corrections = {};
      }
      error.corrections[index] = {};
      error.corrections[index].code_of_account = 'Kode belum dipilih';
    } else if (!item.debit && !item.credit) {
      if (!error.corrections) {
        error.corrections = {};
      }
      error.corrections[index] = {};
      error.corrections[index].debit = 'Debit/Kredit belum diisi';
    } else if (!item.description || item.description === '') {
      if (!error.corrections) {
        error.corrections = {};
      }
      error.corrections[index] = {};
      error.corrections[index].description = 'Deskripsi belum diisi';
    }
  });

  if (error.corrections) {
    return { corrections: error.corrections };
  }

  if (type === 'AMOUNT' && total_credit !== total_debit) {
    return 'Total debit dan kredit tidak sesuai';
  }

  return error;
}

export function validateManageRegis(payload = {}) {
  let data = payload;
  const fieldName = VALIDATION_FORM_PENDAFTARAN;
  let flag = true;

  each(fieldName, ((field) => {
    if (data.value[field.name] === undefined || isEmpty(data.value[field.name])) {
      if (field.type === "select" || field.type === "date") {
        data.error[field.name] = "Mohon dipilih ";
      } else {
        data.error[field.name] = "Mohon diisi ";
      }
      flag = false;
    }
  }));

  return ({
    error: data,
    flag: flag
  });
}

