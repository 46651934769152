import { connect } from 'react-redux';
import LaporanPeminjam from '../../../views/Library/LaporanPeminjam/LaporanPeminjam.library.view';
import { getStudent } from '../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(LaporanPeminjam);
