import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import classnames from 'classnames';

export default function Button(props) {
  const {
    type,
    onClick,
    title,
    className,
    white,
    disabled,
    icon,
    children,
  } = props;
  const buttonClassnames = classnames([
    'button',
    white && 'button-white',
    disabled && 'button-disabled',
    className,
  ]);
  const iconClassnames = classnames([
    icon,
    'button__icon',
    (isEmpty(title) && isEmpty(children)) && 'button__icon--no-margin',
  ]);
  return (
    <button
      type={type}
      className={buttonClassnames}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <i className={iconClassnames} />}
      {title || children}
    </button>
  );
}
Button.propTypes = {
  disabled: PropTypes.bool,
  white: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};
Button.defaultProps = {
  disabled: false,
  white: false,
  type: 'button',
  className: '',
  icon: '',
  title: '',
  children: null,
  onClick: noop,
};
