import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty} from 'lodash';
import { Input, Select, Textarea, SelectMultiple, Button, Checkbox } from '../../../../components/base/index';

export default class IsiBobotForm3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*"
      }
    };
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name, inputArray, fieldName, arrayPosition) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name,
      value,
      dataset: {
        inputArray,
        fieldName,
        arrayPosition,
      },
    }});
  }

  _onRemoveMulti(selectedList, removedItem) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name: removedItem,
      value: selectedList,
      dataset: {},
    }});
  }

  render() {
    const {
      list, error, onChange,param,
      fieldName, form, onFormChange
    } = this.props;
    const { value } = form;
    const Status = [
      { label: 'Bobot Rata - Rata NH', value: 'NH' },
      { label: 'Bobot Akhir dengan Ulangan MID', value: 'MID' },
      { label: 'Bobot Ulangan Semester', value: 'Semester' },
    ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2}>Bobot Nilai</th>
            <th rowspan={2}>Detail Nilai</th>
            <th rowSpan={2}>Poin</th>
            <th></th>
          </tr>
         
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <Select
                      value={data.bobot_nilai}
                      data={Status}
                      placeholder='Pilihan'
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name='bobotnilai'
                      name='bobot_nilai'
                      onChange={onFormChange}
                    />
                <td>
                <Select
                      value={data.type_of_exam_id}
                      data={param.type_exam}
                      placeholder='Pilihan'
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name='bobotnilai'
                      name='type_of_exam_id'
                      onChange={onFormChange}
                    />
                

                </td>

                <td>
               
                <Input
                    type='text'
                    value={data.point}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='bobotnilai'
                    name='point'
                    onChange={onFormChange}
               />

               </td>
                <td>
                  <Button onClick={() => this._onDeleteList(idx)}>Delete</Button> 
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={10}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

IsiBobotForm3.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
IsiBobotForm3.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
};