import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { KLAPPER_TABLE_BY_NIK_FIELDS } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import { LAPORAN_KLAPPER_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";
import {
  Button,
  Input,
  Checkbox,
  Pagination,
  Select,
} from "../../../components/base/index";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Klapper extends PureComponent {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          klapperlist: [],
        },
        error: {
          klapperlist: "",
        },
      },
      param: {},
      filtered: false,
    };
    this._onFormChange = this._onFormChange.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._renderStartDates = this._renderStartDates.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { content } = this.state;
    const paramTypes = ["levels", "classrooms"];

    console.log(location);
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    // if (isEdit && id) {
    //   this.setState({id:id})
    //   this._setForm(data.id);
    // }
    // this.handleGetData();
    // this.isMount = true;
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getClassesOptions({ filters: { levels_id: [levels_id] } });
    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    console.log(state);
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms") {
      filters.organizations_id = organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    // filters.levels_id = levels_id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    if (filters.period === "") {
      delete filters.period;
    }
    if (filters.classrooms_id === "") {
      delete filters.classrooms_id;
    }
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListKlapper,
            handleListReportStudent,
            handleListAchievement,
            user,
          } = this.props;
          const { organizations_id } = user;

          const result = await handleListKlapper({
            ...params,
            organizations_id: organizations_id,
            filters,
          });
          // }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                klapperlist: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                klapperlist: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (name === "levels_id") {
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classes_id: "",
                classrooms_id: "",
              },
            },
          }));
        }

        if (name === "levels_id" || name === "classes_id") {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              classrooms_id: "",
            },
          }));
        }

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
          this._getParamOptionsAcademics("classrooms", {
            organizations_id: organizations_id,
          });
          this._onSearchContent();
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id: organizations_id,
          });
          this._onSearchContent();
        }
        if (name === "classrooms_id") {
          this._onSearchContent();
        }
      }
    );
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  // render() {
  //   const { filtered, form } = this.state;
  //   const { handleGetKlapper, handleDeleteKlapper } = this.props;
  //   return (
  //     <div className="klapper">
  //       {
  //         !filtered ? (
  //           <StudentReportFilter
  //             year={form.value.year}
  //             error={form.error.year}
  //             onSearch={this._onSearch}
  //             onChange={this._handleFormChange}
  //           />
  //         ) : (
  //           // <div className="klapper__content">
  //           //   <div className="klapper__content-header">
  //           //     <h1>SMK --</h1>
  //           //     <h3>Klapper Siswa Periode Tahun Ajaran 2016 / 2017</h3>
  //           //   </div>
  //           //   <ContentTable
  //           //     showActions={false}
  //           //     onSearchContent={handleGetKlapper}
  //           //     tableFields={KLAPPER_TABLE_BY_NIK_FIELDS}
  //           //     onClickDelete={handleDeleteKlapper}
  //           //   />
  //           // </div>
  //           <div className="klapper__content">
  //             <div className="klapper__content-header">
  //               <h1>BUKU KLAPPER SISWA</h1>
  //               <h3>YAYASAN RICCI</h3>
  //             </div>
  //             <ContentTable
  //               showActions={false}
  //               onSearchContent={handleGetKlapper}
  //               tableFields={KLAPPER_TABLE_BY_NIK_FIELDS}
  //               onClickDelete={handleDeleteKlapper}
  //             />
  //           </div>

  //         )
  //       }

  //     </div>
  //   );
  // }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderStartDates(student) {
    const { param = {} } = this.state;
    const { classes = [] } = param;
    const { klapper } = student;
    const row = [];
    console.log(klapper, classes);

    map(classes, (data) => {
      const klapperRow =
        klapper.find((item) => item.classes_id == data.value) || {};
      row.push([<td>{klapperRow.start_date || ""}</td>]);
    });

    return row;
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
    } = this.state;
    const { klapperlist } = form.value;
    const {
      date = "",
      period = "",
      // levels_id='',
      classrooms_id = "",
    } = filters;
    const { location } = this.props;
    const loadingProps = { show: content.loading };
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    let length = 0;
    {
      map(param.classes, (name) => length++);
    }
    // console.log(klapperlist)
    return (
      <div className="manage-registration">
        <div className="kode-matpel__custom-form">
          <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
              <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                value={period}
                placeholder={"pilihan"}
                data={param.periods}
                error={form.error.classes_year || ""}
              />
            </div>

            <div className="kode-matpel__custom-form-column__field">
              <Select
                placeholder="Unit"
                label="Unit"
                name="levels_id"
                // disabled
                value={form.value.levels_id}
                data={param.levels}
                onChange={this._onFormChange}
              />
            </div>
          </div>
          <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
              <Select
                name="classes_id"
                label="Tingkat Kelas"
                onChange={this._onFormChange}
                data={param.classes}
                placeholder={"Pilihan"}
                value={form.value.classes_id}
                error={form.error.classes_id || ""}
              />
            </div>

            <div className="kode-matpel__custom-form-column__field">
              <Select
                name="classrooms_id"
                label="Kelas"
                onChange={this._onChangeFilter}
                data={param.classrooms}
                placeholder={"Pilihan"}
                value={classrooms_id}
                error={form.error.classrooms_id || ""}
              />
            </div>
          </div>
        </div>

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {/* {
          map(LAPORAN_KLAPPER_LIST_TABLE_FIELDS.label, (field, idx) => (
            <th key={`table_th_${idx}`}>{field}</th>
        ))
        } */}
                <th rowSpan={2}>No</th>
                <th rowSpan={2}>Nama Siswa</th>
                <th rowSpan={2}>No Induk</th>
                <th rowSpan={2}>NISN</th>
                <th rowSpan={2}>L/P</th>
                <th rowSpan={2}>Agama</th>
                <th rowSpan={2}>Tanggal Masuk</th>
                <th rowSpan={2}>TTL</th>
                <th rowSpan={2}>Nama Orangtua</th>
                <th colSpan={length}>Tanggal Masuk Kelas</th>
                <th rowSpan={2}>Tanggal Keluar Sekolah</th>
                <th rowSpan={2}>Keterangan/Catatan</th>
              </tr>
              <tr>
                {map(param.classes, (name) => (
                  <th>{name.label}</th>
                ))}
                {/* <th>1</th>
        <th>2</th>
        <th>3</th>
        <th>12</th>
        <th>5</th>
        <th>6</th> */}
              </tr>
            </thead>
            <tbody>
              {map(klapperlist, (item, idx) => (
                <tr key={`budget_row_${idx}`}>
                  <td>{idx + 1}</td>
                  <td>{item.student_name}</td>
                  <td>{item.nis}</td>
                  <td>{item.nisn}</td>
                  <td>{item.genders}</td>
                  <td>{item.religions}</td>
                  <td>{item.entrance_year}</td>
                  <td>{item.dob}</td>
                  <td>{item.father_name}</td>
                  {this._renderStartDates(item)}
                  <td>{item.student_exit_date}</td>
                  <td>{item.descriptions}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="user-management__table-footer">
<p className="user-management__page-info">
{`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
</p>
<Pagination
totalPage={content.totalPage}
currentPage={content.currentPage}
onClick={this._onChangePage}
/>
</div> */}
        <div className="student-list__header"></div>
      </div>
    );
  }
}
Klapper.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
Klapper.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
