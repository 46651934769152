import { connect } from 'react-redux';
import Laporan8355 from '../../../views/Student/report/Laporan8355.student.view';
import { manageRegistration } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getSubjectOptions, getParamOptionsAcademics, getPeriodsOptions } from '../../../states/thunks/options.thunk';
import { getReportDispliner, saveReportDispliner, listReportDispliner, editReportDispliner, listLaporan8355} from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleGetReportDispliner: payload => dispatch(getReportDispliner(payload)),
    handleListLaporan8355: (payload, goback) => dispatch(listLaporan8355(payload, goback)),
    handleSaveReportDispliner: (payload, goBack) => dispatch(saveReportDispliner(payload, goBack)),    
    handleListReportDispliner: (payload, goBack) => dispatch(listReportDispliner(payload, goBack)),
    handleEditReportDispliner: (payload, goBack) => dispatch(editReportDispliner(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Laporan8355);
