import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty, filter } from 'lodash';
import SelectCoa from '../../../components/SelectCoa/SelectCoa.component';
//import language from '../../../../../languages';
//import { Button, Input, Pagination } from '../../../../../components/base';
//import { commaFormatted } from '../../../../../utils/transformer.util';

export default class SearchNisCatat extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
          list: [],
          listAmount: 5,
          form:{
            value:{
              tema:'',
            },
          },
          selectedTema :'',
        };
    
        this.setRef = this.setRef.bind(this);
       
        this.onSearchTema = this.onSearchTema.bind(this);
        this._onFormChange = this._onFormChange.bind(this)
        this.onFormChange = this.onFormChange.bind(this);
      }
      
      setRef(el) {
        const { input } = el;
        const { dataset } = input;
        const { pos, referenceid } = dataset;
      }
    
      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }
    
      onSearchTema(tema = null){
        // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:2;
        const { handleGetTemaOptions } = this.props;
        const { target } = tema;
        const { value } = target;
        handleGetTemaOptions({
          tema,
          value
          // divisiValue
        });
        this.setState({ selectedTema : target.value})
      }
    
      onFormChange(event) {
        const {
          name,
          value,
          dataset,
        } = event.target;
        const { onChange, idx } = this.props;
        // console.log(onChange)
        // console.log(idx)
    
        onChange({
          target: {
            name,
            value,
            dataset: {
              ...dataset,
              inputArray: true,
              arrayPosition: idx,
            },
          },
        });
        // console.log(dataset.arrayPosition)
    
      }
      
    
 
      render() {
        const {
         budgetloop, dataloop,  recommendations, error, target, onSearchTema, tema,
           user,
        } = this.props;
        const { list  } = this.state;
    
      
    
       
    
        
    
        return (
        
              <div>
                  <SelectCoa
                      noMargin
                      label="Nis Siswa"
                      // edit={canEdit}
                      name="tema"
                      value={this.state.selectedTema}
                      target={tema}
                      tema={target}
                      // value={target}
                      error={error.tema}
                      coa={tema}
                      onClick={this.onSearchTema}
                      placeholder="Pilih Nis"
                      rightIcon="icon-search"
                      onSearchCoa={onSearchTema}
                    />
              <br></br>
              <br></br>
    
            
    
           
          </div>
        );
      }
    }

    SearchNisCatat.propTypes = {
        handleGetBudgetRapbuRekapDetail: PropTypes.func,
        onSelectBudgetDetail: PropTypes.func,
        rapbuSummary: PropTypes.object.isRequired,
        user: PropTypes.object,
        recommendations: PropTypes.object,
        handleGetTemaOptions : PropTypes.func,
        onSearchTema: PropTypes.object,
      };
      SearchNisCatat.defaultProps = {
        handleGetBudgetRapbuRekapDetail: noop,
        handleGetTemaOptions : noop,
        onSearchCoa: noop,
        onChange: noop,
        onSearchTema: noop,
        onSelectBudgetDetail: noop,
        user: null,
        recommendations: {},
        error: {},
      };   