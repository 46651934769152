import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import { Input, Button } from '../../../components/base';
import { commaFormatted } from '../../../utils/transformer.util';

export default class BalanceReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      details: {},
      report: [],
    };
    this._getReport = this._getReport.bind(this);
    this._onChangeDate = this._onChangeDate.bind(this);
  }

  async _getReport(val) {
    const { handleGetBalanceReport } = this.props;
    const res = await handleGetBalanceReport(val);
    // alert(res);
    this.setState({ details: { test: 'for now' } });
    this.setState({ report: res });
  }

  _onChangeDate(event) {
    const { value } = event.target;
    this.setState({ date: value });
  }

  render() {
    const { details, date, report } = this.state;
    const { user } = this.props;
    const { school_unit } = user;

    return (
      <div className="balance-report">
        <div className="balance-report__form">
          <Input
            type="date"
            label="Tanggal"
            value={date}
            onChange={this._onChangeDate}
          />
          <Button
            title="Cari"
            onClick={this._getReport}
          />
        </div>
        {
          !isEmpty(details) && (
            <div>
              <div className="balance-report__title">
                <h1>Laporan Neraca</h1>
                <h1>{school_unit.name}</h1>
                <h3>Periode Tanggal {date}</h3>
              </div>
              <table className="balance-report__table">
                <tbody>
                  {
                  map(report.code_categories, (list, idx) => ([
                    <tr key={`balance_category_report_${idx}`}>
                      <td className="balance-report__row-title" colSpan={4}>{list.title}</td>
                    </tr>,
                    map(list.category, (lc, idc) => ([
                      <tr key={`balance_group_report_${idc}`}>
                        <td colSpan={4}>{lc.title}</td>
                      </tr>,
                      map(lc.group, (lg, idg) => ([
                        <tr key={`balance_group_report_${idg}`}>
                          <td rowSpan={lg.account.length + 2}>&nbsp;</td>
                          <td colSpan={3}>{lg.title}</td>
                        </tr>,
                        map(lg.account, (la, ida) => ([
                          <tr key={`balance_group_account_report_${ida}`}>
                            <td>{la.code}</td>
                            <td>{la.title}</td>
                            <td className="nominal">{commaFormatted(la.total)}</td>
                          </tr>,
                        ])),
                        <tr key={`balance_group_report__total_${idg}`}>
                          <td colSpan={2}><b>TOTAL {lg.title}</b></td>
                          <td className="nominal"><b>{commaFormatted(lg.total)}</b></td>
                        </tr>,
                        <tr key={`balance_group_report__end_${idg}`}>
                          <td colSpan={4}>&nbsp;</td>
                        </tr>,
                      ])),
                      <tr key={`balance_category_report__total_${idc}`}>
                        <td colSpan={3}><b>TOTAL {lc.title}</b></td>
                        <td className="nominal"><b>{commaFormatted(lc.total)}</b></td>
                      </tr>,
                      <tr key={`balance_category_report__separator_${idc}`}>
                        <td colSpan={4}>&nbsp;</td>
                      </tr>,
                    ])),
                    <tr key={`balance_class_report__total_${idx}`}>
                      <td colSpan={3}><b>TOTAL {list.title}</b></td>
                      <td className="nominal"><b>{commaFormatted(list.total)}</b></td>
                    </tr>,
                    <tr key={`balance_category_report__separator_${idx}`}>
                      <td colSpan={4}>&nbsp;</td>
                    </tr>,
                  ]))
                }
                  <tr>
                    <th colSpan={3}>TOTAL</th>
                    <th className="balance-report__row-title nominal">{commaFormatted(report.total)}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }
      </div>
    );
  }
}
BalanceReport.propTypes = {
  user: PropTypes.object,
  handleGetBalanceReport: PropTypes.func,
};
BalanceReport.defaultProps = {
  user: {},
  handleGetBalanceReport: noop,
};
