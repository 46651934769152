import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../../components/base';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { FINANCE_PAYMENT_JOURNAL_TABLE_FIELDS } from '../../../../constants/finance/bookeeping.constant';

export default class PaymentJournal extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-pembayaran', { isEdit: false });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-pembayaran', { data: { id: val.id }, isEdit: true });
  }

  render() {
    const { handleGetPaymentJournal } = this.props;
    return (
      <div className="payment-journal">
        <Button
          title="Tambah Jurnal Pembayaran"
          onClick={this._onClickAdd}
        />
        <div className="payment-journal__content">
          <ContentTable
            onSearchContent={handleGetPaymentJournal}
            tableFields={FINANCE_PAYMENT_JOURNAL_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            showDelete={false}
            onClickDetail={this.onClickDetail}
          />
        </div>
      </div>
    );
  }
}
PaymentJournal.propTypes = {
  handleGetPaymentJournal: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PaymentJournal.defaultProps = {
  handleGetPaymentJournal: noop,
};
