import { connect } from 'react-redux';
import BuatNilaiEkskul from '../../../views/Student/ekskul/BuatNilaiEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses , getParamOptions, getTeacherEkskulOptions, getParamOptionsAcademics, getPeriodsOptions} from '../../../states/thunks/options.thunk';
import { saveEkskulGrade, editEkskulGrade, getEkskulGrade, listGradeStudentEkskul } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user || {},

  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTeacherEkskulOptions: (payload) => dispatch(getTeacherEkskulOptions(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveEkskulGrade: (payload, goback) => dispatch(saveEkskulGrade(payload, goback)),
    handleEditEkskulGrade: (payload, goback) => dispatch(editEkskulGrade(payload, goback)), 
    handleGetEkskulGrade: payload => dispatch(getEkskulGrade(payload)),
    handleListGradeStudentEkskul: (payload, goback) => dispatch(listGradeStudentEkskul(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatNilaiEkskul);


