import { connect } from 'react-redux';
import ListGroupPrestasi from '../../../views/Student/displiner/ListGroupPrestasi.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(ListGroupPrestasi);


