import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class Jadwal extends PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    // this.getData = this.getData.bind(this);
    this.onClickJadwalKelas = this.onClickJadwalKelas.bind(this);
   
  }

  async onSubmit() {
    const { history, handleSaveSchedule } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleSaveSchedule(value);
  }

  onClickJadwalKelas() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/jadwal/jadwal-per-kelas');
  }


 
  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickJadwalKelas}>
            <img src={budgeting} alt="DaftarNilai" />
            <h3>Jadwal Per Kelas & Karyawan</h3>
          </button>
        </div>
      
      
      </div>
    );
  }
}
Jadwal.propTypes = {
  handleGetSchedule: PropTypes.func,
  handleSaveSchedule: PropTypes.func,
  history: PropTypes.object.isRequired,
};
