import { connect } from 'react-redux';
import JadwalKelasDetail from '../../../views/Kurikulum/Jadwal/JadwalKelasDetail.kurikulum.view';
import { getParamOptions } from '../../../states/thunks/options.thunk';
import { saveSchedule, getSchedule } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
      handleSaveSchedule: payload => dispatch(saveSchedule(payload)),
      handleGetSchedule: payload => dispatch(getSchedule(payload)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(JadwalKelasDetail);