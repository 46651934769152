import { connect } from 'react-redux';
import ManageBudgetRapbu from '../../../../views/Finance/Budgeting/Budget/ManageBudgetRapbu.finance.view';
import { downloadFile } from '../../../../states/thunks/common.thunk';
import {
  getBudgetDetail, saveRecommendations, submitApproval, rejectRapbu,
} from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetDetail: payload => dispatch(getBudgetDetail(payload)),
    handleSaveRecommendations: (payload, goBack) => dispatch(saveRecommendations(payload, goBack)),
    handleSubmitApproval: (payload, goBack) => dispatch(submitApproval(payload)),
    handleReject: (payload, goBack) => dispatch(rejectRapbu(payload, goBack)),
    handleFileDownload: (filename) => dispatch(downloadFile(filename)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudgetRapbu);
