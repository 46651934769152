import { connect } from 'react-redux';
import JenisEkstrakulikuler from '../../../views/Student/ekskul/JenisEkstrakulikuler.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { listJenisEkskul, deleteJenisEkskul } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListJenisEkskul: (payload, goBack) => dispatch(listJenisEkskul(payload, goBack)),
    handleDeleteJenisEKskul: (payload, goback) => dispatch(deleteJenisEkskul(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JenisEkstrakulikuler);


