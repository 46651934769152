import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import { map, noop, filter } from 'lodash';
import { Input, Button, Select, Checkbox } from '../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { InputGroup } from 'react-bootstrap';

const MySwal = withReactContent(Swal);

export default class TabelTambahSoal extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this._onClickModal = this._onClickModal.bind(this);
    this.state = {
      form: {
        value: {
          organization_id: '',
          periode: '',
          tanggal: '',
          number: '',
          upload: '',
          tabel_jawaban: [
            {
              jSET_Jawaban: '',
              Nomor:'',
              Pilihan:'',
              Isi_Jawaban: '',
              Gambar: '',
            }
          ],
        },
        error: {
            tabel_jawaban: '',
        },
      },
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }
  
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onClickModal(data) {
    const { form } = this.state;
    const { tabel_jawaban } = form.value;
    tabel_jawaban.push({
        SET_Jawaban: '',
        Nomor:'',
        Pilihan:'',
        Isi_Jawaban: '',
        Gambar: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabel_jawaban,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
    
  }
  onClickButton(data, list) {
    const filteredList = filter(list, o => (
      // console.log(o)
      o.code === data.code && data.code_tema === o.code_tema
    ));
    // console.log(data)
    // console.log(filteredList)

    MySwal.fire({
      size: 20000,
      // title: <h3>Keterangan Anggaran</h3>,
      width: '1000px',
      height: '1000px',
      footer: '',
      html: this.renderModalContent(filteredList),
    });
  }

  renderHeaderModal() {
    const { selected, checkedAll } = this.state;
    return (
      <thead>
        <tr>
        <th rowSpan={2}>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>SET Jawaban</th>
            <th  className="manage-budget__row table" style={{ width: "10%" }}>No</th>
            <th  className="manage-budget__row table" style={{ width: "10%" }}>Pilihan</th>
            <th  className="manage-budget__row table" style={{ width: "40%" }}>Isi Jawaban</th>
            <th  className="manage-budget__row table" style={{ width: "30%" }}>Gambar</th>
          </tr>
      </thead>

    );
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  renderModalContent(filteredList) {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis, periode, rincian
    } = this.props;
    return (
      <div>
        <div>
          <h1>{filteredList[0].name_tema}</h1>
        </div>
        <table className="manage-budget__form-table table">
          {this.renderHeaderModal()}
          {
          map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td><Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={this._onCheckDetail}
                      // value={budget.id}
                      // checked={has(selected, budget.id) ? true : false}
                      ref={this._setRef}
                      
                    /></td>
                <td></td>
                
                <td className="nominal">
                </td>
                <td className="nominal">
                </td>
                <td className="nominal">
                </td>
                <td className="nominal">
                <Input
                    type="file"
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='plafon'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={this._onFormChange}
                    value={data.no_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                

              </tr>
              
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={13}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onClickModal}
                />
              </div>
              
            </td>
          </tr>
          
        </table>
      </div>
      
    );
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis, periode, rincian, onFormChange
    } = this.props;

    const tipePlafon = [
      { label: '---', value: '---' },
      { label: 'Rutin', value: 'rutin' },
      { label: 'Kegiatan', value: 'kegiatan' },
      { label: 'Swadaya', value: 'swadaya' },
      { label: 'Dana Pemerintah', value: 'dana pemerintah' },
      { label: 'Biaya Pengembangan Pemerintah', value: 'biaya pengembangan pemerintah' },
    ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No</th>
            <th className="manage-budget__row table" style={{ width: "5%" }}>Pertanyaan</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Gambar</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Kelompok Soal</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Tipe Soal</th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>Jawaban</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Penjelasan</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Skor</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Action</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td className="nominal">
                  <Input
                    value={data.plafond_id}
                    placeholder="Pilihan"
                    data={nis}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='tabel_jawaban'
                    name='nis'
                    onChange={onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td className="nominal">
                  <Input
                    type="file"
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabel_jawaban'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={onFormChange}
                    value={data.no_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabel_jawaban'
                    data-input-type="date"
                    name="tgl_invoice"
                   onChange={this._onFormChange}
                    value={data.tgl_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                <td className="nominal">
                  <Select
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabel_jawaban'
                    data-input-type="date"
                    name="tgl_tempo"
                    onChange={this._onFormChange}
                    value={data.tgl_tempo || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
                
                <td className="nominal">
                <Button variant="primary"  onClick={() => this.onClickButton(data, list)} >Tambah Jawaban</Button>
                </td>
                <td className="nominal">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='plafon'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={this._onFormChange}
                    value={data.no_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
               
                <td className="nominal">
                <Input
                    type="file"
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabel_jawaban'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={this._onFormChange}
                    value={data.no_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                 
                </td>

                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
                <td>
                <Button
                type="button"
                title="edit"
                onClick={this.onSubmit}
            />
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={13}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

TabelTambahSoal.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,
};
TabelTambahSoal.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
  plafond_name: [],
};
