import PengisianCatatanAnekdot from '../../../views/Kurikulum/Ulangan/PengisianCatatanAnekdot.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptionsAcademics,  getStudentOptions, getSubjectOptions, getPeriodsOptions} from '../../../states/thunks/options.thunk';
import { listPengisianCatatanAnekdot } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state){
    return{
        classes: state.options.classes,
        result: state.result,
        user: state.user || {},
        levels: state.options.levels,
    }
}

function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: (payload) => dispatch(getClasses(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
        handleListPengisianCatatanAnekdot: (payload, goback) => dispatch(listPengisianCatatanAnekdot(payload, goback)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (PengisianCatatanAnekdot);