import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  map, noop, filter, isEmpty
} from 'lodash';
import Loading from '../Loading/Loading.component';

export default class SearchSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      currentValue: '',
    };
    this.clickHandlers = {};
    this.initialSearch = [];
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClickInput = this.onClickInput.bind(this);
    this.setInitialSearch = this.setInitialSearch.bind(this);
  }

  componentDidMount() {
    const { searchParams } = this.props;
    this.setInitialSearch(searchParams);
  }

  onChange(event) {
    const { onSearch, async, searchParams } = this.props;
    const val = event.target.value;
    if (async) {
      this.setState({
        loading: true,
        list: [],
        currentValue: val,
      }, async () => {
        const result = await onSearch({ ...searchParams, keyword: val });
        this.setState({
          loading: false,
          list: result,
        });
      });
    } else {
      this.setState({
        currentValue: val,
      });
    }
  }

  onClickInput(event) {
    const { target } = event;
    this.setState({
      currentValue: '',
    });
  }

  onClick(obj) {
    const { name, labelName, onChange, inputArray, arrayPosition, fieldName  } = this.props;
    this.setState({
      currentValue: obj[labelName],
    }, () => {
      onChange({
        target: {
          name,
          value: obj,
          dataset: {
            inputType: 'searchSelect',
            inputArray,
            arrayPosition,
            fieldName,
          },
        },
      });
    });
  }

  onClear() {
    const { name, onChange, inputArray, arrayPosition, fieldName } = this.props;
    this.setState({
      currentValue: '',
    }, () => {
      onChange({
        target: {
          name,
          value: { label: '', value: '' },
          dataset: {
            inputType: 'searchSelect',
            inputArray,
            arrayPosition,
            fieldName,
          },
        },
      });
    });

  }

  onBlur() {
    const { async } = this.props;
    this.setState({
      currentValue: '',
    });
    if (async) {
      this.setState({
        list: this.initialSearch,
      });
    }
  }

  setInitialSearch(searchParams = {}) {
    const { onSearch, async } = this.props;
    if (async) {
      this.setState({
        loading: true,
        list: [],
      }, async () => {
        const result = await onSearch(searchParams);
        this.setState({
          loading: false,
          list: result,
        });
        this.initialSearch = result;
      });
    }
  }

  getClickHandler(obj, valueName) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, obj[valueName])) {
      this.clickHandlers[obj.value] = () => this.onClick(obj);
    }
    return this.clickHandlers[obj.value];
  }

  render() {
    const {
      list: stateList,
      loading,
      currentValue,
    } = this.state;
    const {
      list: propsList,
      label,
      placeholder,
      error,
      classNames,
      disabled,
      async,
      loading: propLoading,
      value,
      noMargin,
      theme,
      leftIcon,
      rightIcon,
      labelName,
      valueName,
      inputArray,
      arrayPosition,
      fieldName,
      onSearch,
      searchParams,
      edit,
      ...others
    } = this.props;
    const loadingProps = { show: loading || propLoading };
    const wrapperClassnames = classnames([
      'search-select',
      noMargin && 'search-select__no-margin',
    ]);
    const labelClassnames = classnames([
      'search-select__label',
      error && 'search-select__label--error',
    ]);
    const containerClassnames = classnames([
      'search-select__container',
      disabled && 'search-select__disabled',
      error && 'search-select__container--error',
    ]);
    const inputClassnames = classnames([
      error && 'search-select__text--error',
      value[valueName] && 'search-select__placeholder',
      disabled && 'search-select__text-disabled search-select__placeholder-disabled',

      `search-select__text search-select__text__${theme}`,
      leftIcon && `search-select__text__${theme}--icon-left`,
      rightIcon && `search-select__text__${theme}--icon-right`,
      disabled && 'search-select__text--disabled',
      classNames,
    ]);
    let filteredList = [];
    let listLabelName = labelName || 'label';

    if (!async) {
      filteredList = filter(propsList, list => {
        if(list[listLabelName]) {
          return list[listLabelName].toLowerCase()
          .search(currentValue.toLowerCase()) > -1;
        } else {
          return true;
        }
      })
    }

    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          { (value[valueName] || currentValue)
          && (
            <button
              type="button"
              className="search-select__clear"
              onClick={this.onClear}
            >
              &times;
            </button>
          )
          }
          <input
            type="text"
            placeholder={value[labelName] || placeholder}
            className={inputClassnames}
            value={currentValue}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={disabled}
            data-field-name={fieldName}
            data-array-position={arrayPosition}
            data-input-array={inputArray}
            onClick={this.onClickInput}
            {...others}
          />
          <div className="search-select__list">
            {
              loadingProps.show
                ? <Loading loading={loadingProps} black />
              : isEmpty(async ? stateList : filteredList) ?
                (
                  <p>Kata kunci tidak ditemukan</p>
                ) :
                map(async ? stateList : filteredList, (data, idx) => (
                  <div
                    key={`search-select_list_${idx}`}
                    className="search-select__item"
                  >
                    <div>
                      <button
                        className="search-select__title"
                        onMouseDown={this.getClickHandler(data, valueName)}
                      >
                        <p>
                          {data[labelName]}
                        </p>
                      </button>
                    </div>
                  </div>
                ))
            }
          </div>
          {
            (leftIcon)
            && (
              <span className={`search-select__icon-left search-select__icon-left__${theme}`}>
                {
                  typeof leftIcon === 'string'
                    ? <i className={leftIcon} />
                    : leftIcon
                }
              </span>
            )
          }
          {
            (rightIcon)
            && (
              <span className={`search-select__icon-right search-select__icon-right__${theme}`}>
                {
                  typeof rightIcon === 'string'
                    ? <i className={rightIcon} />
                    : rightIcon
                }
              </span>
            )
          }
        </div>
        {error && <p className="search-select__err">{error}</p>}
      </div>
    );
  }
}
SearchSelect.propTypes = {
  list: PropTypes.array,
  async: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noMargin: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  searchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
};
SearchSelect.defaultProps = {
  list: [],
  async: false,
  disabled: false,
  loading: false,
  noMargin: false,
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  name: '',
  value: { label: '', value: '' },
  searchParams: {},
  onSearch: noop,
  onClick: noop,
  theme: 'default',
  leftIcon: '',
  rightIcon: '',
  labelName: 'title',
  valueName: 'id',
};
