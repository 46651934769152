import { connect } from 'react-redux';
import DataKelas from '../../../views/Akademi/DataKelas/DataKelas.view';
function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      levels: state.options.levels,
      user: state.user || {},
  
    }
  }
export default connect(mapStateToProps, null)(DataKelas);
