import { connect } from 'react-redux';
import TambahStatus from '../../../../views/Finance/Spp/JenisSPP/TambahStatus.JenisSPP.view';
import { getParamOptions } from '../../../../states/thunks/options.thunk';
import { listStatusPayments, saveStatusPayments, deleteStatusPayments, getStatusPayments, editStatusPayments } from '../../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleListStatusPayments: (payload, goback) => dispatch(listStatusPayments(payload, goback)),
    handleSaveStatusPayments: (payload, goback) => dispatch(saveStatusPayments(payload, goback)),
    handleDeleteStatusPayments: (payload, goback) => dispatch(deleteStatusPayments(payload, goback)),
    handleEditStatusPayments: (payload, goBack) => dispatch(editStatusPayments(payload, goBack)),
    handleGetStatusPayments: payload => dispatch(getStatusPayments(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TambahStatus);
