import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import { commaFormatted, dateFormat } from "../../../../utils/transformer.util";
import Button from "../../../../components/base/Button/Button.component";
import { printLaporanDispliner } from "../../../../utils/print.util";
import ListForm from "../../../../components/ListForm/ListForm.component";
import { LAPORAN_8355_FORM } from "../../../../constants/student.constant";
import { Input, Select } from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";
import { Link } from "react-router-dom";
// import { Bar, Pie } from "react-chartjs-2";
// import "chart.piecelabel.js";
// import "chartjs-plugin-labels";
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printLaporanDispliner(header, content, footer);
}


class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
          journal: {},
          chartData: {
            labels: ["test1", "test2", "test3", "test4", "test5", "test6"],
            datasets: [
              {
                label: "Population",
                data: [617594, 181045, 153060, 106519, 105162, 95072],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.6)",
                  "rgba(54, 162, 235, 0.6)",
                  "rgba(255, 206, 86, 0.6)",
                  "rgba(75, 192, 192, 0.6)",
                  "rgba(153, 102, 255, 0.6)",
                  "rgba(255, 159, 64, 0.6)",
                  "rgba(255, 99, 132, 0.6)"
                ],
                borderWith: 1,
                borderColor: "#777",
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }
                }
              }
            ]
          }
        };
        // this.onGetDetails = this.onGetDetails.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }

    //   componentDidMount() {
    //     this.onGetDetails();
    //   }
    
    //   async onGetDetails() {
    //     const { handleGetCashJournal, match } = this.props;
    //     const { id } = match.params;
    //     try {
    //       const payload = await handleGetCashJournal({ id });
    //       this.setState({
    //         journal: payload
    //       });
    //     } catch (err) {
    //       // err action
    //     }
    //   }
    
      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }
    
      render() {
        const { journal } = this.state;
        return (
        
          <div>
           
            <div id="divPrint" style={{width:800, border: '0px solid black'}}>
            {/* <Button
              title="Cetak Ke Printer"
              onClick={() => {
                onClickPrint(
                  "detail-cash-journal__header",
                  "detail-cash-journal__table",
                  "detail-cash-journal__footer"
                );
              }}
            /> */}
    
            {
              <div className="cetak-displiner-pelanggaran__content">
                <div className="cetak-displiner-pelanggaran__header">
                  <div className="cetak-displiner-pelanggaran__header-top">
                    <div className="cetak-displiner-pelanggaran__header-top--left">
                      {/* <p>{journal.unit.name}</p>
                            <p>{journal.unit.addrress || '-'}</p> */}
                    </div>
                    {/* <div className="detail-cash-journal__header-title">
                            <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1>
                          </div> */}
                    {/* <div className="detail-cash-journal__header-top--right">
                            <p>Nomor: {journal.journal_number}</p>
                            <p>Tanggal: {dateFormat(journal.date)}</p>
                          </div> */}
                  </div>
                </div>
                <div className="cetak-displiner-pelanggaran__body">
                  <table className="cetak-displiner-pelanggaran__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kelas</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                            map(journal.details, (list, idx) => (
                              <tr key={`journal_detail_${idx}`}>
                                <td>{idx + 1}</td>
                                <td>{list.code_of_account}</td>
                                <td>{list.parameter_code.title}</td>
                                <td>{list.description}</td>
                                <td className="nominal">{commaFormatted(list.amount)}</td>
                              </tr>
                            ))
                          } */}
    
                      <td>1</td>
                      <td>7A</td>
                      <td>200</td>
                    </tbody>
                    {/* <tfoot>
                            <tr>
                              <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                              <td className="nominal">{commaFormatted(journal.total)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                              <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4" className="nominal">TOTAL NETTO</td>
                              <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                            </tr>
                          </tfoot> */}
                  </table>
                </div>
              </div>
            }
    
            {/* <div className="nilai-kognitif__custom-form">
              <div className="nilai-kognitif__custom-form-row">
                <div className="nilai-kognitif__custom-form-column__field">
                  <Pie
                    data={this.state.chartData}
                    options={{
                      title: {
                        display: false,
                        text: "Test Pie",
                        fontSize: 25
                      },
                      legend: {
                        display: false,
                        position: "bottom"
                      },
                      pieceLabel: {
                        render: "percentage"
                      }
                    }}
                  />
                </div>
                <div className="nilai-kognitif__custom-form-column__field">
                  <Bar
                    data={this.state.chartData}
                    options={{
                      title: {
                        display: false,
                        text: "Test Bar",
                        fontSize: 25
                      },
                      legend: {
                        display: false,
                        position: "bottom"
                      },
                      plugins: {
                        labels: {
                          render: "value"
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div> */}
            
            {
    
              <div className="cetak-displiner-pelanggaran__content">
                <div className="cetak-displiner-pelanggaran__header">
                  <div className="cetak-displiner-pelanggaran__header-top">
                    <div className="cetak-displiner-pelanggaran__header-top--left">
                      {/* <p>{journal.unit.name}</p>
                            <p>{journal.unit.addrress || '-'}</p> */}
                    </div>
                    {/* <div className="detail-cash-journal__header-title">
                            <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1>
                          </div> */}
                    {/* <div className="detail-cash-journal__header-top--right">
                            <p>Nomor: {journal.journal_number}</p>
                            <p>Tanggal: {dateFormat(journal.date)}</p>
                          </div> */}
                  </div>
                </div>
                <div className="cetak-displiner-pelanggaran__body">
                 
                  <h2>Kelas 7A = 100 Juara</h2>
                  <br/>
                  <table className="cetak-displiner-pelanggaran__table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>NIS</th>
                        <th>Nama Siswa</th>
                        <th>Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                            map(journal.details, (list, idx) => (
                              <tr key={`journal_detail_${idx}`}>
                                <td>{idx + 1}</td>
                                <td>{list.code_of_account}</td>
                                <td>{list.parameter_code.title}</td>
                                <td>{list.description}</td>
                                <td className="nominal">{commaFormatted(list.amount)}</td>
                              </tr>
                            ))
                          } */}
    
                      <td>1</td>
                      <td>1231224</td>
                      <td>Nama Panjang Siswa</td>
                      <td>75</td>
                    </tbody>
                    {/* <tfoot>
                            <tr>
                              <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                              <td className="nominal">{commaFormatted(journal.total)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                              <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4" className="nominal">TOTAL NETTO</td>
                              <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                            </tr>
                          </tfoot> */}
                  </table>
                </div>
              </div>
            }
          </div>
          </div>
        );
      }


}

export default class Prestasi extends PureComponent {
    constructor(props){
        super(props);
    
        this.state={
          journal: {},
        }
      }
    
      
      render() {
        return (
          <div className="content-wrapper">
              <section className="content-header">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="box">
    
                            <div className="divContainter">
                            <h1>Analisa Displiner Prestasi </h1>
                            <hr></hr>
                              <div>
                                <ReactToPrint
                                  trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                                  content={() => this.componentRef}
                                />
                                <ComponentToPrint ref={el => (this.componentRef = el)} />
                              </div>
    
                              
                            </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
          
        );
      }

  
}
Prestasi.propTypes = {
  handleGetStudent: PropTypes.func,
  match: PropTypes.object.isRequired
};
Prestasi.defaultProps = {
  handleGetCashStudent: noop
};
