import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, SearchSelect } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';

export default class AbsensiRekapForm extends Component {

constructor(props){
  super(props);
  this._onFormChange = this._onFormChange.bind(this);
   
  this.state ={
      form: {
          values: {
              classes: '',
              classes_id: '',
              levels: '',
              month: '',
          },
          error: {
              classes: '',
              levels: '',
              month: '',
              years: '',
          }
      }
  }
}
_onFormChange(event) {
  const { onChange } = this.props;
  onChange(event);
}



render(){
  const { onChange, form, classes,onFormChange, levels, param, classes_id } = this.props;
  const { value, error } = form;
  const academic_year = [
    { label: '2019', value: '1'},
    { label: '2020', value: '2'},
    { label: '2021', value: '3'},
  ]
  return (
    <div className="absensi-rekap__custom-form">
      <div className="absensi-rekap__custom-form2-row">
        <div className="absensi-rekap__custom-form2-row__field">
        {/* <div className="absensi-rekap__custom-form-column__field"> */}
        {/* <SearchSelect
                    noMargin
                    async={false}
                    name="students_id"
                    list={param.student}
                    inputArray
                    onClick={this._onFormChange}
                    placeholder="Pilih Nama Siswa"
                    value={form.value.students_id}
                    // labelName="label"
                    // valueName="value"
                    rightIcon="icon-search"
                    label= "Nama Siswa"
                    /> */}
          </div>
        <div className="absensi-rekap__custom-form2-row__field">
          <Select
            name="tahun_ajaran"
            label="Tahun Ajaran"
            onChange={this._onFormChange}
            // data={ levels.list}
            value={form.value.tahun_ajaran}
            error={form.error.tahun_ajaran || ''}
            placeholder={'Pilihan'}
            data={academic_year}
            // error={form.error.namaSaudara1 || ''}
          />
          </div>
          {/* <div className="absensi-rekap__custom-form2-row__field">
           <Select
           
           name="classes"
           label="Kelas"
           onChange={this._onFormChange}
           value={form.value.classes || ''}
           error={form.error.classes || ''}
           placeholder={'Pilihan'}
            data={param.classes}
          //  placeholder={'2019 - 2020'}
         />
          </div> */}
          
        {/* </div> */}
        </div>
        
        <div className="absensi-rekap__custom-form2-column__field">
            
        <div className="absensi-rekap__custom-form2-row">
        <div className="absensi-rekap__custom-form2-row__field">
        {/* <div className="absensi-rekap__custom-form-column__field"> */}
          <Input
            type="date"
            name="date"
            label="Tanggal"
            onChange={this._onFormChange}
            value={form.value.date}
            error={form.error.date || ''}
          />  
          </div>
        <div className="absensi-rekap__custom-form2-row__field">
        <Input
          type="date"
           name="date_to"
           label="Sampai Dengan"
           onChange={this._onFormChange}
           value={form.value.date_to || ''}
           error={form.error.date_to || ''}
          //  placeholder={'2019 - 2020'}
         />
          </div>
          <div className="absensi-rekap__custom-form2-row__field">
          <Input
          type="text"
           name="jml_hari"
           label="Jumlah Hari Efektif"
           onChange={this._onFormChange}
           value={form.value.jml_hari || ''}
           error={form.error.jml_hari || ''}
          //  placeholder={'2019 - 2020'}
         />
          </div>
        {/* </div> */}
        </div>
      
        </div>
        
        </div>
        
 
   
  );

}
  
}
AbsensiRekapForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classes: PropTypes.array,
  levels: PropTypes.array,
};
AbsensiRekapForm.defaultProps = {
  onChange: noop,
  classes: [],
  levels: [],
};
