import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';

export default class AdjustmentJournalForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.renderCodeCorrection = this.renderCodeCorrection.bind(this);
  }

  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  renderCodeCorrection(type, data, corrections = [], error = []) {
    const { coa, onChange, selectedIds = [] } = this.props;
    const cIdx = selectedIds.findIndex(item => item.toString() === data.id.toString());
    let index = cIdx;
    if (index === -1) {
      index = corrections.findIndex(item => item.id === data.id);
    }
    if (type !== 'CODE') {
      return false;
    }
    if (selectedIds.includes(data.id.toString())) {
      return (
        <>
          <td className="coa">
            <SelectCoa
              noMargin
              inputArray
              coa={coa}
              name="code_of_account"
              fieldName="corrections"
              arrayPosition={index}
              onClick={onChange}
              value={corrections[index] ? corrections[index].code_of_account : ''}
              rightIcon="icon-search"
              data-source={data.id}
              error={error[index] ? error[index].code_of_account : ''}
              edit={corrections[index] && !corrections[index].id}
            />
          </td>
          <td>
            <Input
              noMargin
              data-input-array
              data-array-position={index}
              data-field-name="corrections"
              name="description"
              onChange={onChange}
              value={corrections[index] ? corrections[index].description : ''}
              data-source={data.id}
              error={error[index] ? error[index].description : ''}
              edit={corrections[index] && !corrections[index].id}
            />
          </td>
        </>
      );
    }
    return (
      <>
        <td></td>
        <td></td>
      </>
    )
  }

  render() {
    const {
      list, error, onChange,
      fieldName, coa, source = {}, corrections = [], type, hasId,
      selectedIds = [],
      total_credit, total_debit, onSubmit
    } = this.props;
    const { journal_details = [] } = source;
    let correctionIndex = -1;
    const allCorrected = journal_details.filter(item => item.is_corrected === 1).length
      === journal_details.length;
    return (
      <>
        <table className="manage-adjustment-journal__form-table table">
          <thead>
            <tr>
              <th>No.</th>
              <th className="coa">Kode</th>
              <th>Keterangan</th>
              <th className="nominal">Nominal</th>
              <th className="selection">Pilih</th>
              {
                (type === 'CODE') && (
                  <>
                    <th className="coa">Koreksi Kode</th>
                    <th>Keterangan Koreksi</th>
                  </>
                )
              }
            </tr>
          </thead>
          <tbody>
            {
              map(source.journal_details, (data, idx) => (
                <tr key={`source-${data.id}`}>
                  <td>
                    {idx + 1}
                  </td>
                  <td className="coa">
                    {data.parameter_code.code}
                  </td>
                  <td>
                    {data.description}
                  </td>
                  <td className="nominal">
                    {commaFormatted(data.amount)}
                  </td>
                  <td className="selection">
                    <input
                      type="checkbox"
                      onChange={onChange}
                      checked={data.is_corrected || selectedIds.includes(data.id.toString())}
                      value={data.id}
                      name="selected_ids"
                      disabled={data.is_corrected}
                    />
                  </td>
                  {
                    this.renderCodeCorrection(type, data, corrections, error)
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
        { (type === 'AMOUNT') && (
          <>
            <h2>Koreksi Nilai</h2>
            <table className="manage-adjustment-journal__form-table table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th className="coa">Kode</th>
                  <th>Keterangan</th>
                  <th className="nominal">Debit</th>
                  <th>Kredit</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  map(corrections, (data, idx) => (
                    <tr key={`adjustment_journal_data__${idx}`}>
                      <td>{idx + 1}</td>
                      <td className="coa">
                        <SelectCoa
                          noMargin
                          inputArray
                          arrayPosition={idx}
                          fieldName={fieldName}
                          name="code_of_account"
                          coa={coa}
                          onClick={onChange}
                          value={data.code_of_account}
                          error={error[idx] ? error[idx].code_of_account : ''}
                          rightIcon="icon-search"
                          disabled={data.disabled === 1}
                        />
                      </td>
                      <td>
                        <Input
                          noMargin
                          data-input-array
                          data-array-position={idx}
                          data-field-name={fieldName}
                          name="description"
                          onChange={onChange}
                          value={data.description || ''}
                          error={error[idx] ? error[idx].description : ''}
                        />
                      </td>
                      <td className="nominal">
                        <Input
                          noMargin
                          data-input-array
                          data-array-position={idx}
                          data-field-name={fieldName}
                          data-input-type="number"
                          name="debit"
                          isNumber
                          onChange={onChange}
                          value={data.debit || ''}
                          error={error[idx] ? error[idx].debit : ''}
                          disabled={data.disabled}
                        />
                      </td>
                      <td className="nominal">
                        <Input
                          noMargin
                          data-input-array
                          data-array-position={idx}
                          data-field-name={fieldName}
                          data-input-type="number"
                          name="credit"
                          isNumber
                          onChange={onChange}
                          value={data.credit || ''}
                          error={error[idx] ? error[idx].credit : ''}
                          disabled={data.disabled}
                        />
                      </td>
                      <td>
                        <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                      </td>
                    </tr>
                  ))
                }
                <tr>
                  <td colSpan={3} />
                  <td
                    className={`nominal ${total_debit !== total_credit ? 'red' : ''}`}
                  >
                    {commaFormatted(total_debit)}
                  </td>
                  <td
                    className={`nominal ${total_debit !== total_credit ? 'red' : ''}`}
                  >
                    {commaFormatted(total_credit)}
                  </td>
                  <td />
                </tr>
                <tr className="manage-adjustment-journal__add-more">
                  <td colSpan={7}>
                    <div>
                      <Button
                        icon="icon-plus"
                        onClick={this._onAddList}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </>
    );
  }
}
AdjustmentJournalForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  coa: PropTypes.object.isRequired,
};
AdjustmentJournalForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};
