import { connect } from 'react-redux';
import CetakLaporan8355 from '../../../../views/Student/report/cetak/CetakLaporan8355.student.view';
import { manageRegistration } from '../../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
  };
}
export default connect(null, mapDispatchToProps)(CetakLaporan8355);
