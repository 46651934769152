import { connect } from 'react-redux';
import UploadPengisianPsikAfek from '../../../views/Kurikulum/Ulangan/UploadNilaiKognitif.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getSubjectOptions,getTeacherSubjectsOptions, getParamOptionsAcademics, getTypeExam, getLevels } from '../../../states/thunks/options.thunk';
import { listStudentCognitiveValue, saveCognitiveValue, editNilaiKognitif, getNilaiKognitif} from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    handleSaveCognitiveValue: (payload, goback) => dispatch(saveCognitiveValue(payload, goback)),
    getTypeExam: (payload) => dispatch(getTypeExam(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListStudentCognitiveValue: (payload, goback) => dispatch(listStudentCognitiveValue(payload, goback)),
    getTeacherSubjectsOptions: (payload) => dispatch(getTeacherSubjectsOptions(payload)),
    handleGetNilaiKognitif: payload => dispatch(getNilaiKognitif(payload)),
    handleEditNilaiKognitif: (payload, goBack) => dispatch(editNilaiKognitif(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadPengisianPsikAfek);


