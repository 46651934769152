import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { DAFTAR_EKSKUL } from "../../../constants/Student/ekskul.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Select,
  Input,
} from "../../../components/base/index";
import language from "../../../languages";
import DaftarEkskulForm from "../components/DaftarEkskulForm.component";
import DaftarPesertaEkskulPage1 from "../components/DaftarPesertaEkskulPage1.component";
import { PESERTA_EKSKUL_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class DaftarPesertaEkskul extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onCetak = this.onCetak.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          daftar_peserta_ekskul: [],
          // levels_id,
          organizations_id: 12,
        },
        error: {
          daftar_peserta_ekskul: "",
        },
      },
      filtered: false,
      param: "",
    };
  }

  componentDidMount() {
    const { location, handleGetClasses } = this.props;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const paramTypes = ["classes", "levels", "classrooms"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 12;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 12;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = 12;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListlistStudentEkskul, user } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;
          const result = await handleListlistStudentEkskul({
            ...params,
            organizations_id: 12,
            // units_id,
            filters,
          });
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                daftar_peserta_ekskul: result,
              },
            },
          }));
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                daftar_peserta_ekskul: [],
              },
            },
          }));
        }
        // }
      }
    );
  }
  // on formchange untuk form

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === 'levels_id') {
          this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: 12 });
        }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    console.log(plafon);
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    console.log(name);
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push(
      "/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas"
    );
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }
  onCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-buku-induk");
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  render() {
    const { form, page, content, param, filters } = this.state;
    const { value } = form;
    const { daftar_peserta_ekskul } = form.value;
    const {
      classrooms_id = "",
      academic = "",
      periods = "",
      order = "",
    } = filters;
    const {
      handleGetKelas,
      handleDeleteKelas,
      classes = {},
      location,
    } = this.props;
    const { state = {} } = location;

    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const period = [
      { label: "Genap", value: "genap" },
      { label: "Ganjil", value: "ganjil" },
    ];

    const urutan = [
      { label: "Berdasarkan Abjad", value: "1" },
      { label: "Berdasarkan Nis", value: "2" },
    ];
    return (
      <div className="manage-registration">
        <h1>Daftar Siswa Ekstrakulikuler</h1>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                type="text"
                name="levels_id"
                label="Unit"
                placeholder="Pilih Unit"
                data={param.levels}
                // disabled
                value={form.value.levels_id}
                onChange={this._onFormChange}
                error={form.error.levels_id || ""}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <Select
                placeholder="Pilih Kelas"
                label="Kelas"
                name="classrooms_id"
                value={classrooms_id}
                data={param.classrooms}
                onChange={this._onChangeFilter}
              />
            </div>
          </div>
        </div>
        <br />

        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  {map(PESERTA_EKSKUL_TABLE_FIELDS.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(daftar_peserta_ekskul, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(
                      PESERTA_EKSKUL_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.type === "link") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <Link
                                to={{
                                  pathname:
                                    "/dashboard/kesiswaan/ekstrakulikuler/buat-daftar-ekskul",
                                  state: { ...list, isEdit: true },
                                }}
                              >
                                {list.name}
                              </Link>
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(list, field)}
                          </td>
                        );
                      }
                    )}
                    {/* {this._renderButtons(list)} */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      // <div className="manage-registration">

      //   {/* <form onSubmit={this.onSubmit}> */}
      //   {
      //      <h1>Daftar Peserta Ekskul</h1>
      //    }
      //    {
      //      <hr></hr>
      //    }

      //    <div className="upload-student__content">

      //     {
      //     page === 1  ? (

      //         // <ListForm
      //         // form={form}
      //         // formFields={ DAFTAR_EKSKUL[page]}
      //         // onFormChange={this._onFormChange}
      //         // />
      //         <DaftarPesertaEkskulPage1
      //         classes={classes}
      //         form={form}
      //         onChange={this._onFormChange}
      //         />

      //         ) : (
      //         <ListForm

      //         form={form}
      //         formFields={ DAFTAR_EKSKUL[page]}
      //         onFormChange={this._onFormChange}
      //             />

      //         )
      //    }

      //   {
      //     page === 2  ? (

      //         <DaftarEkskulForm
      //         form={form}
      //         onFormChange={this._onFormChange}
      //         />

      //         ) : (

      //            ''

      //         )
      //    }

      // <div className="manage-registration">
      // <div className="accident__content">

      //   {
      //     page === 1 ? (
      //       <Button
      //         onClick={this._onClickNext}
      //         title="Submit"
      //       />
      //     ) : (

      //       <Button
      //        onClick={this.onCetak}
      //         title="Simpan Data"

      //         />
      //     )
      //   }
      // </div>
      // </div>
      // </div>
      //   {/* </form> */}
      //   </div>
    );
  }
}
DaftarPesertaEkskul.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DaftarPesertaEkskul.defaultProps = {
  handleGetClasses: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
