import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, map, isEqual
} from 'lodash';
import {
  Pagination,
  Loading,
  Button,
  Select,
  Input
} from '../../../components/base';
import language from '../../../languages';
import { formatData } from '../../../utils/transformer.util';
import { LIST_SOAL_FROM_TABEL } from '../../../constants/kurikulum/kurikulum.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

const monthOptions = () => {
  const d = new Date();
  const month = d.getMonth();
  const options = [{ value: '', label: 'Semua' }];
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  for (let i = 0; i <= month; i += 1) {
    options.push({ value: i, label: months[i] });
  }
  return options;
}

const isPostedOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: '' },
  { value: 0, label: '' },
];

const isCreditOptions = [
  { value: '', label: 'Semua' },
  { value: 1, label: 'Publish' },
  { value: 0, label: 'Belum Publish' },
];

export default class ListSoal extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickActionButton = this._onClickActionButton.bind(this);
    this._onClickPostingButton = this._onClickPostingButton.bind(this);
    this.isMount = false;
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickPreview = this._onClickPreview.bind(this);
    

    this.state = {
      content: initialContent,
      listAmount: 5,
      filters: {}
    };
  }

  componentDidMount() {
    const { content } = this.state;

    this.isMount = true;

    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

//   _onClickAdd() {
//     const { history, location } = this.props;
//     const { state } = location;
//     history.push('/dashboard/kurikulum/soal/list-soal', { isEdit: false, ...state });
//   }

_onClickAdd() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/kurikulum/soal/tambah-soal');
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/soal/list-soal', { data: { id: val.id }, isEdit: true });
  }

  _onClickActionButton(val) {
    const { history } = this.props;
    history.push(`/dashboard/kurikulum/soal/list-soal/${val.id}`);
  }

  _onClickPreview(){
    const { history } = this.props;
    history.push(`/dashboard/keuangan/kurikulum/soal/list-soal/tambah-soal`);
   }

  async _onClickPostingButton(val) {
    const { handlePostJournalPosting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalPosting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }

  async _onClickUnpostButton(val) {
    const { handlePostJournalUnposting } = this.props;
    const { content } = this.state;
    const { currentPage: page } = content;
    const res = await handlePostJournalUnposting({
      id: val.id,
    });
    if (res) {
      this._onSearchContent({ page });
    }
  }

  _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleGetCashJournal } = this.props;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleGetCashJournal({ ...params, filters });
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        }
      } catch (err) {
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    if (data.is_posted === 0) {
      if (permissions.includes('create_journal')) {
        return ([
          <Button
            key="button_edit"
            onClick={() => this._onClickEdit(data)}
            title="Edit"
          />,
          <Button
            key="button_post"
            onClick={() => this._onClickPostingButton(data)}
            title="Post"
          />,
        ]);
      }
    } else {
      return ([
        <Button
          key="button_preview"
          onClick={() => this._onClickActionButton(data)}
          title="Preview"
        />,
        <>
          {permissions.includes('unpost_journal') && (
            <Button
              key="button_unpost"
              onClick={() => this._onClickUnpostButton(data)}
              title="Unpost"
            />
          )}
        </>,
        
      ]);
    }
    return null;
  }

  render() {
    
    const {
      listAmount,
      content,
      filters
    } = this.state;
    const {
      month = '',
      is_credit = '',
      is_posted = '',
      journal_number = '',
    } = filters;
    const loadingProps = { show: content.loading };
    return (
      <div className="list-soal">
        <Button
          title="Tambah Soal"
          onClick={this._onClickAdd}
        />
        <div className="list-soal">
          <div className="list-soal">
            <div className="table-filters">
              <Select
                label="Unit"
                type="text"
                name="unit"
                value={journal_number}
                onChange={this._onChangeFilter}
              />
              <Select
                label="Tanggal"
                name="month"
                value={month}
                data={monthOptions()}
                onChange={this._onChangeFilter}
              />
              <Input
                label="Judul Soal"
                name="is_posted"
                value={is_posted}
                data={isPostedOptions}
                onChange={this._onChangeFilter}
              />
              <Select
                label="Status"
                name="is_credit"
                value={is_credit}
                data={isCreditOptions}
                onChange={this._onChangeFilter}
              />
            </div>
             <div className="cash-journal__content">
             <div className="content-table">
          
           {
              content.loading
                ? <Loading loading={loadingProps} />
                : (
                  <table className="table">
                    <thead>
                      <tr>
                        {
                          map(LIST_SOAL_FROM_TABEL.label, (field, idx) => (
                            <th key={`table_th_${idx}`}>{field}</th>
                          ))
                        }
                      </tr>
                      
                    </thead>
                    <tbody>
                     
                    <tr>
                      
                    </tr>
                    </tbody>
                  </table>
                )
            }
            <div className="user-management__table-footer">
              <p className="user-management__page-info">
                {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
              </p>
              <Pagination
                totalPage={content.totalPage}
                currentPage={content.currentPage}
                onClick={this._onChangePage}
              />
            </div>
          </div>
        </div>
           
           
          </div>
        </div>
      </div>
    );
  }
}

ListSoal.propTypes = {
  handleGetCashJournal: PropTypes.func,
  handlePostJournalPosting: PropTypes.func,
  handlePostJournalUnposting: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ListSoal.defaultProps = {
  handlePostJournalPosting: noop,
  handleGetCashJournal: noop,
  handlePostJournalUnposting: noop,
};
