import { connect } from 'react-redux';
import BuatDaftarEkskul from '../../../views/Student/ekskul/BuatDaftarEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getEkskulOptions, getParamOptions, getEkskulDayOptions} from '../../../states/thunks/options.thunk';
import {saveListStudentEkskul, getListStudentEkskul, editListStudentEkskul} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    classes: state.options.classes,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
    getEkskulOptions: (payload) => dispatch(getEkskulOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetListStudentEkskul: payload => dispatch(getListStudentEkskul(payload)),
    handleEditListStudentEkskul: (payload, goBack) => dispatch(editListStudentEkskul(payload, goBack)),
    handleSaveListStudentEkskul: (payload, goBack) => dispatch(saveListStudentEkskul(payload, goBack)), 
    getEkskulDayOptions: (payload) => dispatch(getEkskulDayOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatDaftarEkskul);


