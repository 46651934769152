import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function LaporanRPPdetailForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="laporan-rpp__custom-form">
      <div className="laporan-rpp__header-title--column">
        <div className="laporan-rpp__header-title--bold">
            {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
            RANCANGAN PENILAIAN PENGETAHUAN TAHUN AJARAN 2019/2020
            </div>
            </div>
      <div className="laporan-rpp__custom-form-row">
        
          <Input
            type="text"
            name="namaSaudara1"
            label="Tahun Ajaran"
            style={{backgroundColor:'#999'}}
            onChange={onFormChange}
            data={ '7A', 'kelas7a'}
            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
          />
       
        </div>
        <div className="laporan-rpp__custom-form-row">
          <Input
            type="text"
            name="namaSaudara1"
            label="Kelas"
            onChange={onFormChange}
            style={{backgroundColor:'#999'}}
            data={ '7A', 'kelas7a'}
            placeholder={'Pilihan'}
           
            // error={form.error.namaSaudara1 || ''}
          />
        </div>
        <div className="laporan-rpp__custom-form-row">
          <Input
            type="text"
            name="namaSaudara1"
            label="Mata Pelajaran"
            onChange={onFormChange}
            style={{backgroundColor:'#999'}}
            data={ '7A', 'kelas7a'}
            placeholder={'Pilihan'}
            // error={form.error.namaSaudara1 || ''}
          />
        </div>

        <div className="laporan-rpp__body">
                  <table className="laporan-rpp__table">
                    <thead>
                      <tr>
                        <th rowSpan={2}>KD NO</th>
                        <th rowSpan={2}>Materi/Tema </th>
                        <th rowSpan={2}>Keterangan KD/Tema/Bukti Belajar</th>
                        <th  colSpan={5}>Bentuk Penilaian</th>
                        <th rowSpan={2}>Tanggal Tes Tertulis</th>
                      </tr>
                      <tr>
                          <th>UH</th>
                          <th>Tugas</th>
                          <th>Praktik</th>
                          <th>PTS</th>
                          <th>PAS</th>
                      </tr>
                    
                    </thead>
                    <tbody>
                      {/* {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    } */}
                    <tr>
                        <td>Bahasa Indonesia</td>
                        <td>Ulangan/Quiz blablablaa</td>
                        <td>Tanggal </td>

                        <td>-</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                       

                    </tr>
                   
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan="1" className="nominal">Catatan Wali Kelas</td>
                        <td colSpan="8" className="nominal"></td>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
        
        </div>
        
 
   
  );
}
LaporanRPPdetailForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
LaporanRPPdetailForm.defaultProps = {
  onFormChange: noop,
};
