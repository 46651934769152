import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input, Select, Textarea } from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";

export default function CatatanAnekdotTemaForm(props) {
  const { onFormChange, form, data, param } = props;
  const semester = [
    { label: "Ganjil", value: "ganjil" },
    { label: "Genap", value: "genap" },
  ];
  const jenis = [
    { label: "Akademis", value: "akademis" },
    { label: "Non Akademis", value: "nonakademis" },
  ];
  const period = [
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
  ];
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian__custom-form-row__field">
          <Input
            name="nis"
            label="NIS"
            value={data ? data.nis : ""}
            // data={data.nis}
            onChange={onFormChange}
            error={form.error.nis || ""}
          />
        </div>
        <div className="lembar-harian2__custom-form-column__field">
          <Input
            name="name"
            label="Nama Anak"
            value={data ? data.name : ""}
            onChange={onFormChange}
            error={form.error.name || ""}
          />
        </div>
      </div>
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian__custom-form-row__field">
          <Select
            name="semester"
            label="Semester"
            placeholder={"Pilihan"}
            onChange={onFormChange}
            data={semester}
            value={data ? data.semester : ""}
            disabled
            // value={form.value.semester}
            // error={form.error.semester || ""}
          />
        </div>
        <div className="lembar-harian2__custom-form-column__field">
          <Select
            type="number"
            name="period_id"
            label="Tahun Ajaran/Period"
            onChange={onFormChange}
            placeholder={"Pilihan"}
            data={param.periods}
            value={form.value.period_id}
            error={form.error.period_id || ""}
          />
        </div>
      </div>
      <div className="lembar-harian2__custom-form-row">
        <div className="lembar-harian__custom-form-row__field">
          <Select
            name="tema_id"
            label="Tema"
            placeholder={"Pilihan"}
            onChange={onFormChange}
            data={param.prm_tema}
            value={form.value.tema_id}
            value={data ? data.tema_id : ""}
            error={form.error.tema_id || ""}
            disabled
          />
        </div>
        <div className="lembar-harian2__custom-form-column__field"></div>
      </div>
    </div>
  );
}
CatatanAnekdotTemaForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
CatatanAnekdotTemaForm.defaultProps = {
  onFormChange: noop,
};
