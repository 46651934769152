import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function BuatKlsKhususForm(props) {


  const { onFormChange, form,  levels } = props;
  return (
    <div className="nilai-kognitif__custom-form">
      <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
            <Input
                
                type='text'
                name="nama_kelas"
                label="Nama Kelas"
                onChange={onFormChange}
                value={form.value.nama_kelas || ''}
                error={form.error.nama_kelas || ''}
            />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
            <Input
                
                type='text'
                name="daya_tampung"
                label="Daya Tampung"
                onChange={onFormChange}
                value={form.value.daya_tampung || ''}
                error={form.error.daya_tampung || ''}
            />
          
       
         
        </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
            <Input
                    
                    type='text'
                    name="nama_guru"
                    label="Nama Guru"
                    onChange={onFormChange}
                    value={form.value.nama_guru || ''}
                    error={form.error.nama_guru || ''}
                />
        </div>
        
        <div className="nilai-kognitif__custom-form-column__field">
            
            <Select
            
                name="sistem"
                label="Sistem Sekolah"
                onChange={onFormChange}
                value={form.value.sistem || ''}
                error={form.error.sistem || ''}
                placeholder={'Semester [6 Bulan]'}
            />
          
       
         
        </div>
        </div>
       
        <div className="nilai-kognitif__custom-form-row">
        <div className="nilai-kognitif__custom-form-row__field">
            <Select
                    name="levels"
                    label="Mata Pelajaran di Kelas tersebut, Untuk Unit"
                    onChange={onFormChange}
                    data={ levels.list}
                    value={form.value.levels}
                    placeholder={'Pilihan'}
                    error={form.error.levels || ''}
                />
        </div>
        </div>

       

    </div>
        
 
   
  );
}
BuatKlsKhususForm.propTypes = {
  levels: PropTypes.array,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatKlsKhususForm.defaultProps = {
  onFormChange: noop,
  levels: [],
};
