import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../components/base/index';
import { Link } from 'react-router-dom';

export default class DataBukuForm extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
    <div className="data-buku__content">
        <div className="komposisi-siswa__custom-form">
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    readOnly
                    name="namaSaudara1"
                    label="Unit Perpustakaan"
                    onChange={onFormChange}
                    style={{backgroundColor:'grey'}}
                    data={ '7A', '2019-2020'}
                    placeholder={''}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    readOnly
                    label="Jenis"
                    onChange={onFormChange}
                    style={{backgroundColor:'grey'}}
                    // value={form.value.tempatLahirSaudara1 || ''}
                    // error={form.error.tempatLahirSaudara1 || ''}
                    />
                </div>
            </div>
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    readOnly
                    name="namaSaudara1"
                    label="Unit Kelas"
                    onChange={onFormChange}
                    style={{backgroundColor:'grey'}}
                    data={ '7A', '2019-2020'}
                    placeholder={''}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    readOnly
                    label="Nomor Barcode/Nomor Induk"
                    onChange={onFormChange}
                    style={{backgroundColor:'grey'}}
                    // value={form.value.tempatLahirSaudara1 || ''}
                    // error={form.error.tempatLahirSaudara1 || ''}
                    />
                </div>
            </div>
            <div className="laporan-keu2__custom-form-row__field">
                <div className="data-buku__custom-form-row3"> 
                    <Input
                        type="text"
                        name="tahunajaran"
                        label="Jumlah Copy Sebanyak"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                   
                </div>
                <div className="data-buku__custom-form-row4">
                    Buku
                </div>
                
                <div className="laporan-keu2__custom-form-row2__field">
                    <div className="data-buku__custom-form-row5"> 
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Format"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-row6">
                        <Input
                            type="text"
                            name="tahunajaran"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-row4">
                    Angka
                    </div>
                </div>
            </div>

            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="DEWAY/Call Number"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    placeholder={''}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Select
                    name="tahunajaran"
                    readOnly
                    label="Kode Klasifikasi"
                    onChange={onFormChange}
                    style={{backgroundColor:'yellow'}}
                    // value={form.value.tempatLahirSaudara1 || ''}
                    // error={form.error.tempatLahirSaudara1 || ''}
                    placeholder={'Pilihan'}
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Select
                    type="number"
                    name="namaSaudara1"
                    label="Group/Kelompok/Jenis"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    placeholder={'Pilihan'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Select
                    name="tahunajaran"
                    readOnly
                    label="Bahasa"
                    onChange={onFormChange}
                    style={{backgroundColor:'yellow'}}
                    // value={form.value.tempatLahirSaudara1 || ''}
                    // error={form.error.tempatLahirSaudara1 || ''}
                    placeholder={'Pilihan'}
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Judul"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="laporan-keu2__custom-form-row2__field">
                    <div className="data-buku__custom-form-row7"> 
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Seri"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-label">
                    Angka Latin
                    </div>
                    <div className="data-buku__custom-form-row8">
                        <Input
                            type="text"
                            name="tahunajaran"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-label">
                    Angka Romawi (Jika Ada)
                    </div>
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Judul Seri"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    label="Judul Asli"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Subjek"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    label="Nama Pengarang"
                    onChange={onFormChange}
                    style={{backgroundColor:'yellow'}}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Pengarang (Tambahan)"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    label=" Nama Alih Bahasa"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Editor"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    label="Penyunting"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Nama Penerbit"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    style={{backgroundColor:'yellow'}}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    label="Kota Penerbit"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Nama Percetakan"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    name="tahunajaran"
                    label="Kota Percetakan"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Input
                    type="number"
                    name="namaSaudara1"
                    label="Nomor ISBN"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    style={{backgroundColor:'yellow'}}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="laporan-keu2__custom-form-row2__field">
                    <div className="data-buku__custom-form-row9"> 
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Tahun Terbit"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    
                    <div className="data-buku__custom-form-row10">
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Terbit Ke"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                  
                </div>
            </div> 
            <div className="data-buku__custom-form-row__field">
                <div className="data-buku__custom-form-row11"> 
                    <Input
                        type="text"
                        name="tahunajaran"
                        label="Jumlah Halaman Romawi"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                   
                </div>
                <div className="data-buku__custom-form-row12">
                    <Input
                        type="text"
                        name="tahunajaran"
                        label="Jumlah Halaman Angka"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                </div>
                
                <div className="laporan-keu2__custom-form-row2__field">
                    <div className="data-buku__custom-form-row9"> 
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Tinggi"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-row4">
                    Cm
                    </div>
                    
                    <div className="data-buku__custom-form-row10">
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Lebar"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-row4">
                    Cm
                    </div>
                    <div className="data-buku__custom-form-row10">
                        <Input
                            type="text"
                            name="tahunajaran"
                            label="Tebal"
                            onChange={onFormChange}
                            // value={form.value.tempatLahirSaudara1 || ''}
                            // error={form.error.tempatLahirSaudara1 || ''}
                            // placeholder={'Pilihan'}
                        />
                    </div>
                    <div className="data-buku__custom-form-row4">
                    Cm
                    </div>
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Select
                    type="number"
                    name="namaSaudara1"
                    label="Cover"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    type="file"
                    name="tahunajaran"
                    label="Upload Cover"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Textarea
                    type="text"
                    name="namaSaudara1"
                    label="NTema/Kategori"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Textarea
                    name="tahunajaran"
                    label="Sumary/Ringkasan"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Select
                    type="number"
                    name="namaSaudara1"
                    label="Asal"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    style={{backgroundColor:'yellow'}}
                    placeholder={'Pilihan'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Input
                    type="date"
                    name="tahunajaran"
                    label="Tanggal Penerimaan"
                    onChange={onFormChange}
                  
                    />
                </div>
            </div> 
            <div className="upload-nilai__custom-header">
                   Lokasi Pada Rak  
                </div>
            <div className="data-buku__custom-form-row__field">
            
                <div className="data-buku__custom-form-row13"> 
                    <Input
                        type="text"
                        name="tahunajaran"
                        label="Nomor"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                </div>
                <div className="data-buku__custom-form-row14">
                    <Input
                        type="text"
                        name="tahunajaran"
                        label="Tingkat"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                </div>
                 
                <div className="data-buku__custom-form-row14">
                    <Input
                        type="text"
                        name="tahunajaran"
                        label="Baris"
                        onChange={onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                </div>
                   
                
                <div className="laporan-keu2__custom-form-row2__field">
                    <div className="data-buku__custom-form-column__field">
                        <Select
                        type="date"
                        name="tahunajaran"
                        label="Boleh Dibawa Pulang"
                        onChange={onFormChange}
                        placeholder={'Pilihan'}
                    
                        />
                    </div>
                </div>
            </div>
            <div className="data-buku__custom-form-row">
                <div className="data-buku__custom-form-row__field">
                    <Select
                    type="number"
                    name="namaSaudara1"
                    label="Show Ke Internet"
                    onChange={onFormChange}
                    data={ '7A', '2019-2020'}
                    placeholder={'Pilihan'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="data-buku__custom-form-column__field">
                    <Select
                    type="date"
                    name="tahunajaran"
                    label="Ada Gambar"
                    onChange={onFormChange}
                    placeholder={'Pilihan'}
                  
                    />
                </div>
            </div> 

        </div>      
     </div>
  );
     }
}
DataBukuForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
DataBukuForm.defaultProps = {
  onFormChange: noop,
};
