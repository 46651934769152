import { SET_LANGUAGE } from '../actions/common.action';

const appConfigState = {
  lang: 'id',
};
export default function appConfig(state = appConfigState, action) {
  switch (action.type) {
    case SET_LANGUAGE: {
      const newState = state;
      newState.lang = action.payload;
      return { ...newState };
    }
    default: {
      return state;
    }
  }
}
