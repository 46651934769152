import { connect } from 'react-redux';
import BudgetTransfer from '../../../../views/Finance/Budgeting/BudgetTransfer/BudgetTransfer.finance.view';
import { getBudgetTransfer, deleteBudgetTransfer } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetTransfer: (payload, unit_id) => dispatch(getBudgetTransfer(payload, unit_id)),
    handleDeleteBudgetTransfer: payload => dispatch(deleteBudgetTransfer(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTransfer);
