import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { commaFormatted } from '../../utils/transformer.util';

export default class SalaryOption extends PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  setRef(input) {
    this.input = input;
  }

  render() {
    const {
      theme,
      type,
      label,
      placeholder,
      value,
      onChange,
      error,
      classNames,
      leftIcon,
      rightIcon,
      disabled,
      noMargin,
      isNumber,
      edit,
      ...others
    } = this.props;
    const wrapperClassnames = classnames([
      'salary-option',
      noMargin && 'salary-option__no-margin',
    ]);
    const labelClassnames = classnames([
      'salary-option__label',
      error && 'salary-option__label--error',
    ]);
    const containerClassnames = classnames([
      'salary-option__container',
      disabled && 'salary-option__disabled',
      error && 'salary-option__container--error',
    ]);
    const inputClassnames = classnames([
      `salary-option__text salary-option__text__${theme}`,
      leftIcon && `salary-option__text__${theme}--icon-left`,
      rightIcon && `salary-option__text__${theme}--icon-right`,
      (theme === 'default' && leftIcon && rightIcon) && 'salary-option__text--no-border',
      disabled && 'salary-option__text--disabled',
      classNames,
    ]);
    const formattedValue = isNumber ? commaFormatted(value) : value;
    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div>
          <input type="radio" name="salary" value="1" /> {'>= 25.000.000'}
        </div>
        <br />
        <div>
          <input type="radio" name="salary" value="2" /> {'>= 15.000.000'}
        </div>
        <br />
        <div>
          <input type="radio" name="salary" value="3" /> {'>= 10.000.000'}
        </div>
        <br />
        <div>
          <input type="radio" name="salary" value="4" /> {'>= 3.000.000'}
        </div>
        <br />
        <div>
          <input type="radio" name="salary" value="5" /> Lain-lainnya
        </div>
        <div className={containerClassnames}>
          {
            edit ? (
              <input
                ref={this.setRef}
                type={type}
                placeholder={placeholder}
                className={inputClassnames}
                value={formattedValue}
                onChange={onChange}
                autoComplete="new-password"
                {...others}
              />
            ) : <p className="salary-option__preview">{formattedValue}</p>
          }
          {
            leftIcon
            && (
              <span className={`salary-option__icon-left salary-option__icon-left__${theme}`}>
                {
                  typeof leftIcon === 'string'
                    ? <i className={leftIcon} />
                    : leftIcon
                }
              </span>
            )
          }
          {
            rightIcon
            && (
              <span className={`salary-option__icon-right salary-option__icon-right__${theme}`}>
                {
                  typeof rightIcon === 'string'
                    ? <i className={rightIcon} />
                    : rightIcon
                }
              </span>
            )
          }
        </div>
        {error && <p className="salary-option__err">{error}</p>}
      </div>
    );
  }
}
SalaryOption.propTypes = {
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  isNumber: PropTypes.bool,
  edit: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
SalaryOption.defaultProps = {
  disabled: false,
  noMargin: false,
  isNumber: false,
  edit: true,
  theme: 'default',
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  classNames: '',
  leftIcon: '',
  rightIcon: '',
  value: '',
  onChange: noop,
};
