import CetakObservasi from '../../../views/Kurikulum/Ulangan/CetakObservasi.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
    };
}
export default connect(null, mapDispatchToProps) (CetakObservasi);