import React, { Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PropTypes from "prop-types";
import { map, noop, filter } from "lodash";
import {
  Input,
  Button,
  Select,
  Checkbox,
} from "../../../../../components/base";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../../utils/transformer.util";

const MySwal = withReactContent(Swal);

export default class ProsesPembayaranTbl extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this.state = {
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    //console.log(fieldName)
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  onClickButton(data, list) {
    const filteredList = filter(
      list,
      (o) =>
        // console.log(o)
        o.code === data.code && data.code_tema === o.code_tema
    );
    // console.log(data)
    // console.log(filteredList)

    MySwal.fire({
      size: 20000,
      // title: <h3>Keterangan Anggaran</h3>,
      width: "1000px",
      height: "1000px",
      footer: "",
      html: this.renderModalContent(filteredList),
    });
  }

  renderHeaderModal() {
    const { selected, checkedAll } = this.state;
    return (
      <thead>
        <tr>
          <th rowSpan={2}>
            <Checkbox
              noMargin
              name="checkAll"
              onChange={this._onCheckAll}
              checked={checkedAll}
            />
          </th>
          <th className="manage-budget__row table" style={{ width: "20%" }}>
            Jenis SPP
          </th>
          <th className="manage-budget__row table" style={{ width: "20%" }}>
            Nominal
          </th>
          <th className="manage-budget__row table" style={{ width: "20%" }}>
            Periode
          </th>
          <th className="manage-budget__row table" style={{ width: "20%" }}>
            Total
          </th>
        </tr>
      </thead>
    );
  }

  renderModalContent(filteredList) {
    const {
      list,
      error,
      onChange,
      fieldName,
      plafond_name,
      nis,
      periode,
      rincian,
    } = this.props;
    return (
      <div>
        <div>
          <h1>{filteredList[0].name_tema}</h1>
        </div>
        <table className="manage-budget__form-table table">
          {this.renderHeaderModal()}
          {map(list, (data, idx) => (
            <tr key={`plafon_journal_data__${idx}`}>
              <td>
                <Checkbox
                  noMargin
                  data-input-array
                  data-array-position={idx}
                  name="selectedBudget"
                  onChange={this._onCheckDetail}
                  // value={budget.id}
                  // checked={has(selected, budget.id) ? true : false}
                  ref={this._setRef}
                />
              </td>
              <td></td>
              <td className="nominal">
                <Input
                  noMargin
                  data-input-array
                  data-array-position={idx}
                  data-field-name="plafon"
                  data-input-type="number"
                  name="nominal"
                  onChange={this._onFormChange}
                  value={data.nominal || ""}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>
              <td className="nominal">
                <Select
                  value={data.plafond_id}
                  placeholder="Pilihan"
                  data={nis}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="nis"
                  name="nis"
                  onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>
              <td className="nominal"></td>
            </tr>
          ))}
        </table>
      </div>
    );
  }

  render() {
    const {
      list,
      error,
      onChange,
      fieldName,
      plafond_name,
      nis,
      periode,
      rincian,
    } = this.props;

    const tipePlafon = [
      { label: "---", value: "---" },
      { label: "Rutin", value: "rutin" },
      { label: "Kegiatan", value: "kegiatan" },
      { label: "Swadaya", value: "swadaya" },
      { label: "Dana Pemerintah", value: "dana pemerintah" },
      {
        label: "Biaya Pengembangan Pemerintah",
        value: "biaya pengembangan pemerintah",
      },
    ];

    return (
      <table className="table">
        <thead>
          <tr>
            <th>No.</th>
            <th className="manage-budget__row table" style={{ width: "5%" }}>
              Nis
            </th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>
              Nama Siswa
            </th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>
              Nomor Invoice
            </th>
            <th className="manage-budget__row table" style={{ width: "12%" }}>
              Nomor Bukti
            </th>
            <th className="manage-budget__row table" style={{ width: "12%" }}>
              Tanggal Pembayaran
            </th>
            <th className="manage-budget__row table" style={{ width: "8%" }}>
              Tipe Pembayaran
            </th>
            <th className="manage-budget__row table" style={{ width: "8%" }}>
              Jenis SPP
            </th>
            <th className="manage-budget__row table" style={{ width: "8%" }}>
              Denda
            </th>
            <th className="manage-budget__row table" style={{ width: "8%" }}>
              Total
            </th>
            <th className="manage-budget__row table" style={{ width: "8%" }}>
              Payment
            </th>
            <th className="manage-budget__row table" style={{ width: "8%" }}>
              Balance
            </th>

            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {map(list, (data, idx) => (
            <tr key={`plafon_journal_data__${idx}`}>
              <td>{idx + 1}</td>
              <td className="nominal">
                <Select
                  value={data.plafond_id}
                  placeholder="Pilihan"
                  data={nis}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="nis"
                  name="nis"
                  onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>
              <td className="nominal"></td>
              <td className="nominal">
                <Select
                  value={data.plafond_id}
                  placeholder="Pilihan"
                  data={nis}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="nis"
                  name="nis"
                  onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>

              <td className="nominal"></td>
              <td className="nominal">
                <Input
                  type="date"
                  noMargin
                  data-input-array
                  data-array-position={idx}
                  data-field-name="plafon"
                  data-input-type="date"
                  name="tgl"
                  onChange={this._onFormChange}
                  value={data.tgl}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>
              <td className="nominal">
                <Select
                  value={data.plafond_id}
                  placeholder="Pilih Tipe Pembayaran"
                  data={nis}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="nis"
                  name="nis"
                  onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>
              <td className="nominal">
                <Button
                  variant="primary"
                  onClick={() => this.onClickButton(data, list)}
                >
                  Tambah Jenis
                </Button>
              </td>
              <td className="nominal">
                <Select
                  value={data.plafond_id}
                  placeholder="Pilihan"
                  data={nis}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="nis"
                  name="nis"
                  onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>

              <td className="nominal">
                <Select
                  value={data.plafond_id}
                  placeholder="Pilih Periode"
                  data={nis}
                  data-input-array
                  arrayPosition={idx}
                  data-array-position={idx}
                  data-field-name="nis"
                  name="nis"
                  onChange={this._onFormChange}
                  // error={error[idx] ? error[idx].nominal : ''}
                />
              </td>

              <td className="nominal"></td>
              <td className="nominal"></td>

              <td>
                <Button
                  onClick={() => this._onDeleteList(idx)}
                  icon="icon-trash-o"
                />
              </td>
            </tr>
          ))}
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={16}>
              <div>
                <Button icon="icon-plus" onClick={this._onAddList} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

ProsesPembayaranTbl.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
};
ProsesPembayaranTbl.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  plafond_name: [],
};
