import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import MataPelajaran from './components/MataPelajaran.component';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import {  BUATDATA_DATAKELASKHUSUS_FORM_FIELDS  } from '../../../constants/Akademi/DataKelas/kelas.constant';
import { Button } from '../../../components/base';
import BuatKlsKhususForm from './components/BuatKlsKhususForm.component';

export default class TambahKelasKhusus extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);

    //this.deletepelajaran = this.deletepelajaran.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    
    this.state = {
      form: {
        value: {
          pelajaran: [
            {
              Matpel: '',
              Kelas:'',
               //divisi: '',
              nominal: 0,
            }                                      
          ],
        },
        error: {
          pelajaran: '',

        },
      },
      
    };
  }
  componentDidMount() {
    const {  handleGetLevels  } = this.props;
    handleGetLevels();
  }
  // on formchange untuk form
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { pelajaran } = form.value;
    
    pelajaran.push({
      Kelas: '',
      Matpel:'',
      // divisi: '',
      nominal: 0,
      //period:'',
    });
    
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          pelajaran,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
    console.log(pelajaran)
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { pelajaran } = prevState.form.value;
      const list = prevState.form.value.pelajaran;

      list.splice(idx, 1);
      return {
        ...prevState,
        pelajaran: list
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newpelajaran = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newpelajaran = prevState.form.value.pelajaran;
        newpelajaran[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { pelajaran: newpelajaran } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    // const { form} = this.state;
    const { form } = this.state;
    const { handleAddBudget, history, user } = this.props;
    const { workingUnit, prm_school_units_id } = user;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    handleAddBudget(form.value, history.goBack, unit_id);
    // handleAddBudget(form.value, pelajaran, history.goBack, unit_id);
    //handleAddBudget(form.value, history.goBack, unit_id);
  }

  deletepelajaran(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({people: array});
    }
  }

  render() {
    const { form } = this.state;
    const {pelajaran} = form.value;
    const { levels={levels}} = this.props;
    return (
      <div className="add-budget">
        <div className="budget__title">
        <h1>Data Kelas Khusus</h1>
        <hr></hr>
        </div>
        <form onSubmit={this.onSubmit}>
      
        <BuatKlsKhususForm
        form={form}
        levels={levels}
        onFormChange={this._onFormChange}
        />
          <MataPelajaran
            onAddList={this._onClickAdd}
            list={pelajaran}
            onDeleteList={this._onDeleteList}
            //deletepelajaran={this._deletepelajaran}
            onChange={this._onFormChange}
            //items={this.state.items} _handleDelete={this.delete.bind(this)}
          />
          <div className="add-budget">
            <Button
              type="submit"
              title="SIMPAN"
            />
          </div>
        </form>
      </div>
    );
  }

    //   render() {
    //     return (
    //         <ul>
    //             {this.props.items.map(item => (
    //                 <li key={item.id}>{item.text}<button onClick={this._handleDelete.bind(this, item.id)}>Delete</button></li>
    //             ))}
    //         </ul>
    //     );
    // } 
}
TambahKelasKhusus.propTypes = {
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
TambahKelasKhusus.defaultProps = {
  handleGetLevels: noop,
  user: null,
};
