import { connect } from 'react-redux';
import CetakAbsensiRekap from '../../../../views/Student/absensi/cetak/CetakAbsensiRekap.student.view';
import { manageRegistration } from '../../../../states/thunks/student.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
  };
}
export default connect(null, mapDispatchToProps)(CetakAbsensiRekap);
