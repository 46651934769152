import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop, isEqual, capitalize } from "lodash";
import ListForm from "../../../components/ListForm/ListForm.component";
import {
  commaFormatted,
  normalizeAmount,
  manageGetClasses,
} from "../../../utils/transformer.util";
import { KENAIKANKELAS_DATAKELAS_FORM_FIELDS } from "../../../constants/Akademi/DataKelas/kelas.constant";
import { Button } from "../../../components/base";
import { Input, Select } from "../../../components/base";
import { formatData } from "../../../utils/transformer.util";
import { LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS } from "../../../constants/Akademi/Akademi.constant";
import ReactToPrint from "react-to-print";
import { errorAlert } from "../../../utils/alert.util";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class KenaikanKelas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._setClassrooms = this._setClassrooms.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getClassroomNextOptions = this._getClassroomNextOptions.bind(this);
    this._getClassroomsOptions = this._getClassroomsOptions.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          classes_id: "",
          graduate: "",
          organizations_id: organizations_id,
          units_id: school_unit.id,

          kenaikan_kelas: [
            {
              students_id: "",
              graduation: 0,
              period: "",
              classrooms_id: "",
              date: "",
              status: "",
              description: "",
              classes_id: "",
            },
          ],
        },
        error: {
          classes: "",
          levels: "",
          kenaikan_kelas: "",
          classes_id: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content, form } = this.state;
    const paramTypes = ["classes", "levels", "classrooms"];

    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    // this.handleGetData();
    // this.isMount = true;
    // else if (isEmpty(content.list)) {
    //   this._onSearchContent({ page: 1 });
    // }
    this._getClassroomNextOptions();
    this._getPeriodsOptions();
    // this._getClassesOptions({ filters: { levels_id: [levels_id] }, units_id:[school_unit.id] });

    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });
    this._getClassroomsOptions();
  }

  // componentDidUpdate(prevProps) {
  //   const { location } = this.props;
  //   const { state = {} } = location;
  //   const { isEdit = false, data } = state;
  //   const { id } = state.data || "";
  //   if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  async _getClassroomNextOptions(filters = {}) {
    const { getClassroomNextOptions, user } = this.props;
    const { form } = this.state;
    const { classrooms_id } = form.value;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;

    const res = await getClassroomNextOptions(filters);
    const selectedClassroom = res.find((item) => item.value == classrooms_id);
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          classes_id: selectedClassroom
            ? selectedClassroom.attributes.classes_id.id
            : "",
          graduate: selectedClassroom ? selectedClassroom.graduation : "",
        },
      },
      param: {
        ...prevState.param,
        classrooms_next: res,
      },
    }));
  }

  async _getClassroomsOptions() {
    const { getClassroomsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { form } = this.state;
    const { classes_id, levels_id, periods_id, classrooms_id } = form.value;

    const res = await getClassroomsOptions({
      classes_id: classes_id,
      levels_id: levels_id,
      periods_id: periods_id,
      units_id: school_unit.id,
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classrooms_capacity: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date);
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          },
        }));
        // tahun ajaran selanjutnya
        if (res[idx + 1]) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                periods_id: res[idx + 1].value,
              },
            },
          }));
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  // async _getPrmLevels(filters = {}) {
  //   const { getPrmLevels, user } = this.props;
  //   const { organizations_id, school_unit } = user;
  //   const { units_id, levels_id } = school_unit;
  //   const res = await getPrmLevels(filters);
  //   filters.organizations_id = 3;

  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       levels: res,
  //     },
  //   }));
  // }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  // async _setForm() {
  //   const { handleGetClasses } = this.props;
  //   try {
  //     const payload = await handleGetClasses({  });
  //     // console.log("paload bor manageStaffFormState")
  //     console.log(payload + "babana")
  //     this.setState(manageGetClasses(payload));
  //   } catch (err) {
  //     // err action
  //   }
  // }

  // on formchange untuk form

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          // this._getParamOptionsAcademics("classrooms", {
          //   levels_id: value,
          //   organizations_id: organizations_id,
          // });
          this._getClassroomNextOptions({ filters: { levels_id: [value] } });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id });
        // }
        if (name === "classrooms_id" && !inputArray) {
          this._setClassrooms(value);
        }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id") {
          const param = this.state.param.classes_prm;
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                // graduate: param.find(i => i.value == value).attributes.graduate,
              },
            },
          }));
        }
      }
    );
  }

  _setClassrooms(classroomsId) {
    const { form, filters, param } = this.state;
    const { value } = form;
    const { kenaikan_kelas, classes_id } = value;
    const newList = JSON.parse(JSON.stringify(kenaikan_kelas));
    newList.forEach((item) => {
      item.classrooms_id = classroomsId;
      // if (classroomsId == "lulus" || classroomsId == "naik") {
      if (classroomsId !== filters.classrooms_id2) {
        item.classes_id = classes_id;
      } else {
        const prm = param.classrooms_next;
        if (filters.classrooms_id2) {
          item.classes_id = prm.find(
            (i) => i.value == filters.classrooms_id2
          ).prev_classes_id;
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          kenaikan_kelas: newList,
        },
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { classes_id } = form.value;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = value.levels_id;
    // filters.units_id = school_unit.id;
    const res = await getClassesOptions(filters);
    const selectedClasses = res.find((item) => item.value == classes_id);

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          // graduate: selectedClasses ? selectedClasses.attributes.graduate : ''
        },
      },
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  async _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    try {
      await this.setState(
        (prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }),
        () => {
          if (name === "classrooms_id2") {
            const param = this.state.param.classrooms_next;
            const classes_id = param.find((i) => i.value == value)
              ? param.find((i) => i.value == value).attributes.classes_id.id
              : "";
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  classrooms_id: "",
                  classes_id: classes_id,
                  graduate: param.find((i) => i.value == value)
                    ? param.find((i) => i.value == value).graduation
                    : "",
                },
              },
            }));
            this._getParamOptionsAcademics("classrooms", {
              classes_id: classes_id,
              organizations_id: organizations_id,
            });
          }
          if (name === "classes_id") {
            const param = this.state.param.classes_prm;
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  // graduate: param.find(i => i.value == value).attributes.graduate,
                },
              },
            }));
          }
        }
      );
    } finally {
      this._onSearchContent();
      this._getClassroomsOptions();
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form, param } = this.state;
    const { value } = form;
    try {
      const { handleListNextClassStudent, user, getPeriodsOptions } =
        this.props;
      const { organizations_id, school_unit } = user;
      const { units_id } = school_unit;

      // const period = await getPeriodsOptions(filters);

      // var thisyear = new Date().getFullYear();
      // var month = new Date().getMonth() + 1;
      // if (month < 7) {
      //   thisyear -= 1;
      // }

      // map(period, (data, idx) => {
      //   // var year = data.label.substring(0, 4);s
      //   var date = new Date();
      //   var start_date = new Date(data.attributes.start_date)
      //   var isfound = false;
      //   if (date > start_date && !isfound) {
      //     filters.period=data.value;
      //   }
      // });

      const result = await handleListNextClassStudent({
        ...params,
        organizations_id: organizations_id,
        units_id: school_unit.id,
        filters,
        classrooms_id: value.classrooms_id,
      });
      const { classes = [], data = [] } = result;
      if (result) {
        const prm = param.classrooms_next;
        var classes_id = "";
        if (filters.classrooms_id2) {
          // console.log(prm.find(i => i.value == filters.classrooms_id2).prev_classes_id)
          classes_id = prm.find(
            (i) => i.value == filters.classrooms_id2
          ).prev_classes_id;
        }
        result.forEach((item) => {
          item.classrooms_id = filters.classrooms_id2;
          if (!item.graduation) {
            item.graduation = value.graduate;
          }
          if (!item.classes_id) {
            item.classes_id = classes_id;
          }
        });
      }
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            kenaikan_kelas: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            kenaikan_kelas: [],
          },
        },
      }));
    }
    // }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      // filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async onSubmit() {
    const { history, handleSaveNextClass } = this.props;
    const { form, filters, param } = this.state;
    const { value } = form;
    const { classrooms_capacity } = param;
    let canSaveA = false;
    if (filters.classrooms_id2) {
      canSaveA = true;
      if (isEmpty(classrooms_capacity)) {
        canSaveA = false;
        errorAlert({
          title: "Daya tampung tidak terdeteksi",
        });
      } else {
        map(classrooms_capacity, (list, idx) => {
          if (list.sisa_cap < 0) {
            canSaveA = false;
            errorAlert({
              title: "Daya Tampung Melebihi Batas",
            });
          }
        });
      }
    } else {
      errorAlert({
        title: "Harap pilih kelas terlebih dahulu",
      });
    }

    if (canSaveA) {
      await handleSaveNextClass(value);
      if (form.value.graduate == 1) {
        history.push(
          "/dashboard/administrasi/data-kelas/pengaturan-kelas-ajaran-baru"
        );
      } else {
        history.push(
          "/dashboard/administrasi/data-kelas/pengaturan-kelas-ajaran-baru"
        );
      }
    }
  }

  render() {
    const { list = {}, param, filters } = this.state;
    const { form } = this.state;
    const { classrooms = [], classrooms_capacity = [] } = param;
    const { value } = form;
    const { classrooms_id2 = "", period = "" } = filters;
    const { kenaikan_kelas } = form.value;
    const { classes = {}, levels = {} } = this.props;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];

    // var nextyear = new Date().getFullYear() + 1;
    // var month = new Date().getMonth() + 1;
    // if (month < 7) {
    //   nextyear -= 1;
    // }
    // map(param.periods, (data, idx) => {
    //   var year = data.label.substring(0, 4);
    //   if (year == nextyear) {
    //     form.value.periods_id = data.value;
    //   }
    // });

    if (!isEmpty(kenaikan_kelas) && !isEmpty(classrooms_capacity)) {
      map(classrooms_capacity, (list, idx) => {
        list.sisa_cap = list.attributes.capacity;
      });

      map(kenaikan_kelas, (list, idx) => {
        list.idx_cap = -1;
        if (
          list.classrooms_id &&
          list.classrooms_id != "" &&
          list.classrooms_id != filters.classrooms_id2
        ) {
          list.idx_cap = classrooms_capacity
            .map((object) => object.value)
            .indexOf(Number(list.classrooms_id));
          if (list.idx_cap != -1) {
            classrooms_capacity[list.idx_cap].sisa_cap -= 1;
            if (classrooms_capacity[list.idx_cap].sisa_cap < 0) {
              classrooms_capacity[list.idx_cap].err_cap =
                "Melebihi daya tampung";
            } else {
              classrooms_capacity[list.idx_cap].err_cap = "";
            }
          }
        }
      });
    }

    const tidak_naik = [{ value: filters.classrooms_id2, label: "Tidak Naik" }];
    const tidak_lulus = [
      { value: filters.classrooms_id2, label: "Tidak Lulus" },
    ];

    const lulus = [{ value: "lulus", label: "Lulus" }];
    const naik = [{ value: "naik", label: "Naik" }];
    const componentRef = React.createRef();
    return (
      <div className="isi-bidang__custom-form">
        <div className="isi-bidang__custom-form-column">
          <div className="isi-bidang__custom-form-row__field">
            {/* <Select
              name="period"
              label="Tahun Ajaran"
              placeholder={"Pilihan"}
              onChange={this._onChangeFilter}
              data={param.periods}
              value={period}
              disabled
            /> */}
            <Select
              type="text"
              name="levels_id"
              label="Unit *"
              placeholder="Pilih Unit"
              // disabled
              data={param.levels}
              value={form.value.levels_id}
              onChange={this._onFormChange}
              error={form.error.levels_id || ""}
            />
            <div className="isi-bidang__custom-form-row__field"></div>
            <Select
              placeholder="Pilih Kelas"
              label="Kelas *"
              name="classrooms_id2"
              value={classrooms_id2}
              data={param.classrooms_next}
              onChange={this._onChangeFilter}
            />
          </div>

          <div className="isi-bidang__custom-form-column">
            <div className="isi-bidang__custom-form-row__field">
              <Select
                placeholder="Pilih Tingkat Kelas"
                label=" Naik ke Tingkat Kelas"
                name="classes_id"
                value={form.value.classes_id}
                data={param.classes_prm}
                onChange={this._onFormChange}
                disabled
              />
              <div className="isi-bidang__custom-form-row__field"></div>
              <Select
                placeholder="Pilihan"
                name="classrooms_id"
                label="Pilih untuk semua murid"
                value={form.value.classrooms_id}
                // data={form.value.graduate === 1?[...lulus, ...tidak_lulus]:[...naik, ...tidak_naik]}
                data={
                  form.value.graduate === 1
                    ? [...lulus, ...tidak_lulus]
                    : [...tidak_naik, ...classrooms]
                }
                onChange={this._onFormChange}
                disabled={classrooms_id2 ? false : true}
              />
            </div>

            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field"></div>

              <div className="isi-bidang__custom-form-row__field"></div>
            </div>

            <div className="kode-matpel__custom-form-column__field"></div>
          </div>
        </div>
        {/* <ReactToPrint
            trigger={() => (
              <div className="form-group-member">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          /> */}
        {!isEmpty(classrooms_capacity) && (
          <div className="fund-request__content">
            <h2>Daftar Kenaikan Kelas</h2>
            <br></br>
            <table className="table" ref={componentRef}>
              <thead>
                <tr>
                  {map(
                    LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS.label,
                    (field, idx) =>
                      ((field != "Daya Tampung" && form.value.graduate === 1) ||
                        form.value.graduate === 0) && (
                        <th key={`table_th_${idx}`}>{field}</th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {map(kenaikan_kelas, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {(() => {
                      // if(list.classrooms_id == "naik" || list.classrooms_id == "lulus") {
                      if (list.classrooms_id !== filters.classrooms_id2) {
                        if (list.classrooms_id == "lulus") {
                          list.graduation = 1;
                        } else {
                          list.graduation = 0;
                        }
                        list.classes_id = value.classes_id;
                      } else {
                        list.graduation = 0;
                        const prm = param.classrooms_next;
                        if (filters.classrooms_id2) {
                          list.classes_id = prm.find(
                            (i) => i.value == filters.classrooms_id2
                          ).prev_classes_id;
                        }
                      }
                      if (list.classrooms_id == "") {
                        list.classrooms_id = classrooms_id2;
                      }
                    })()}
                    {map(
                      LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS.value,
                      (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td
                              key={`table_index_${fieldIdx}_${idx}`}
                              style={{ textAlign: "center" }}
                            >
                              {idx + 1}
                            </td>
                          );
                        }
                        if (field.attribute === "nisn") {
                          return (
                            <td
                              key={`table_${fieldIdx}_${idx}`}
                              style={{ textAlign: "center" }}
                            >
                              {capitalize(list.nisn)}
                            </td>
                          );
                        }
                        if (field.attribute === "no_va") {
                          return (
                            <td
                              key={`table_${fieldIdx}_${idx}`}
                              style={{ textAlign: "center" }}
                            >
                              {capitalize(list.no_va)}
                            </td>
                          );
                        }
                        if (field.attribute === "name") {
                          return (
                            <td
                              key={`table_${fieldIdx}_${idx}`}
                              style={{ textTransform: "capitalize" }}
                            >
                              {capitalize(list.name)}
                            </td>
                          );
                        }
                        if (field.attribute === "classrooms_id") {
                          return (
                            <td
                              key={`table_${fieldIdx}_${idx}`}
                              style={{ verticalAlign: "top" }}
                            >
                              <Select
                                placeholder="Pilihan"
                                data-input-array={true}
                                data-array-position={idx}
                                data-field-name="kenaikan_kelas"
                                name="classrooms_id"
                                value={
                                  list.classrooms_id || filters.classrooms_id2
                                }
                                // data={form.value.graduate === 1?[...lulus, ...tidak_lulus]:[...naik, ...tidak_naik]}
                                data={
                                  form.value.graduate === 1
                                    ? [...lulus, ...tidak_lulus]
                                    : [...tidak_naik, ...classrooms]
                                }
                                onChange={this._onFormChange}
                                disabled={classrooms_id2 ? false : true}
                              />
                              {/* <Select
                            type="text"
                            name="classrooms_id2"
                            // data-input-array={true}
                            // data-array-position={idx}
                            value={form.value.classrooms_id2}
                            data={param.classrooms2}
                            onChange={this._onFormChange}
                        />   */}
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        if (field.attribute === "description") {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}>
                              <Input
                                type="text"
                                name="description"
                                data-input-array={true}
                                data-array-position={idx}
                                data-field-name="kenaikan_kelas"
                                onChange={this._onFormChange}
                                value={list.description}
                              />{" "}
                            </td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        if (field.attribute === "capacity") {
                          return (
                            form.value.graduate === 0 && (
                              <td
                                key={`table_${fieldIdx}_${idx}`}
                                style={{ verticalAlign: "top" }}
                                width="15%"
                              >
                                <Input
                                  name="capacity"
                                  data-input-array={true}
                                  data-array-position={idx}
                                  data-field-name="kenaikan_kelas"
                                  onChange={this._onFormChange}
                                  value={
                                    list.idx_cap != -1
                                      ? classrooms_capacity[list.idx_cap]
                                          .sisa_cap
                                      : ""
                                  }
                                  error={
                                    list.idx_cap != -1
                                      ? classrooms_capacity[list.idx_cap]
                                          .err_cap
                                      : ""
                                  }
                                  disabled
                                  style={{ textAlign: "center" }}
                                />
                              </td>
                            )
                          );
                        }

                        return (
                          <td
                            className={field.type === "number" ? "nominal" : ""}
                            key={`table_${fieldIdx}_${idx}`}
                          >
                            {formatData(list, field)}
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-budget">
              <Button type="submit" title="SIMPAN" onClick={this.onSubmit} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
KenaikanKelas.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
KenaikanKelas.defaultProps = {
  handleGetClasses: noop,
  handleGetLevels: noop,
  user: null,
};
