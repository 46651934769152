import { connect } from 'react-redux';
import Anekdot_Tema from '../../../views/Kurikulum/Ulangan/Anekdot_Tema.view';
import { listTema, deleteTema, saveTema, editTema, getTema } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetTema: payload => dispatch(getTema(payload)),
    handleSaveTema: (payload, goback) => dispatch(saveTema(payload, goback)),
    handleListTema: (payload, goback) => dispatch(listTema(payload, goback)),
    handleEditTema: (payload, goback) => dispatch(editTema(payload, goback)), 
    handleDeleteTema: (payload, goback) => dispatch(deleteTema(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Anekdot_Tema);


