import React, { PureComponent } from 'react';
import ListForm from '../../../components/ListForm/ListForm.component';
import StaffTableForm from '../components/StaffTableForm.component';
import { commaFormatted } from '../../../utils/transformer.util';
import Button from '../../../components/base/Button/Button.component';
import {
  STAFF_EDUCATION_FORM, MANAGE_JOB_APPLICATION_FORM_1,
  STAFF_EXERCISE_FORM, STAFF_LANGUAGE_FORM, MANAGE_JOB_APPLICATION_FORM_2__1,
  STAFF_ORGANIZATION_FORM, STAFF_FAMILY_FORM, MANAGE_JOB_APPLICATION_FORM_2__2,
  STAFF_FAMILY_RESPONSIBLE_FORM, STAFF_FAMILY_TREE_FORM, STAFF_EMERGENCY_CONTACT_FORM,
  STAFF_REFERENCE_FORM, STAFF_JOB_EXPERIENCE_FORM, MANAGE_JOB_APPLICATION_FORM_3,
  MANAGE_JOB_APPLICATION_FORM_4_1, MANAGE_JOB_APPLICATION_FORM_4_2,
} from '../../../constants/personnel.constant';

export default class ManageJobApplication extends PureComponent {
  constructor(props) {
    super(props);
    this._onNextPage = this._onNextPage.bind(this);
    this._onPrevPage = this._onPrevPage.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this.state = {
      page: 1,
      form: {
        value: {
          education: [],
          exercise: [],
          language: [],
          organization: [],
          family: [],
          familyResponsible: [],
          familyTree: [],
          emergencyContact: [],
          reference: [],
          jobExperience: [],
        },
        error: {
          education: [],
          exercise: [],
          language: [],
          organization: [],
          family: [],
          familyResponsible: [],
          familyTree: [],
          emergencyContact: [],
          reference: [],
          jobExperience: [],
        },
      },
    };
  }

  _onNextPage() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  }

  _onPrevPage() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputArray) {
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        newListError[arrayPosition][name] = '';
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {},
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  render() {
    const { form, page } = this.state;
    const {
      education, exercise, language,
      organization, family, familyResponsible,
      familyTree, emergencyContact, reference,
      jobExperience,
    } = form.value;
    const {
      education: educationErrors,
      exercise: exerciseErrors,
      language: languageErrors,
      organization: organizationErrors,
      family: familyErrors,
      familyResponsible: familyResponsibleErrors,
      familyTree: familyTreeErrors,
      emergencyContact: emergencyContactErrors,
      reference: referenceErrors,
      jobExperience: jobExperienceErrors,
    } = form.error;
    return (
      <div className="manage-job-application">
        <div>
          <h1 className="manage-job-application__title">Formulir Lamaran Kerja</h1>
          <h2>Halaman {page}</h2>
        </div>
        <form>
          {
            page === 1 && (
              <div>
                <ListForm
                  form={form}
                  formFields={MANAGE_JOB_APPLICATION_FORM_1}
                  onFormChange={this._onFormChange}
                />
                <div>
                  <StaffTableForm
                    title="B. PENDIDIKAN"
                    listField={STAFF_EDUCATION_FORM}
                    error={educationErrors}
                    list={education}
                    fieldName="education"
                    onAddList={this._onAddList}
                    onChange={this._onFormChange}
                  />
                </div>
                <div>
                  <StaffTableForm
                    title="C. PELATIHAN / KURSUS / SEMINAR"
                    listField={STAFF_EXERCISE_FORM}
                    error={exerciseErrors}
                    list={exercise}
                    fieldName="exercise"
                    onAddList={this._onAddList}
                    onChange={this._onFormChange}
                  />
                </div>
                <div>
                  <StaffTableForm
                    title="D. BAHASA YANG DIKUASAI"
                    listField={STAFF_LANGUAGE_FORM}
                    error={languageErrors}
                    list={language}
                    fieldName="language"
                    onAddList={this._onAddList}
                    onChange={this._onFormChange}
                  />
                </div>
              </div>
            )
          }
          {
            page === 2 && (
              <div>
                <ListForm
                  form={form}
                  formFields={MANAGE_JOB_APPLICATION_FORM_2__1}
                  onFormChange={this._onFormChange}
                />
                <StaffTableForm
                  title="F. PENGALAMAN ORGANISASI"
                  listField={STAFF_ORGANIZATION_FORM}
                  error={organizationErrors}
                  list={organization}
                  fieldName="organization"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
                <StaffTableForm
                  title="G. BAGI YANG SUDAH BERKELUARGA"
                  listField={STAFF_FAMILY_FORM}
                  error={familyErrors}
                  list={family}
                  fieldName="family"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
                <ListForm
                  form={form}
                  formFields={MANAGE_JOB_APPLICATION_FORM_2__2}
                  onFormChange={this._onFormChange}
                />
                <StaffTableForm
                  listField={STAFF_FAMILY_RESPONSIBLE_FORM}
                  error={familyResponsibleErrors}
                  list={familyResponsible}
                  fieldName="familyResponsible"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
                <StaffTableForm
                  title="H. SUSUNAN KELUARGA"
                  listField={STAFF_FAMILY_TREE_FORM}
                  error={familyTreeErrors}
                  list={familyTree}
                  fieldName="familyTree"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
                <StaffTableForm
                  title="I. DALAM KEADAAN MENDESAK ORANG YANG DAPAT DIHUBUNGI"
                  listField={STAFF_EMERGENCY_CONTACT_FORM}
                  error={emergencyContactErrors}
                  list={emergencyContact}
                  fieldName="emergencyContact"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
                <StaffTableForm
                  title="J. REFERENSI (Orang yang dapat dimintai keterangan mengenai diri anda)"
                  listField={STAFF_REFERENCE_FORM}
                  error={referenceErrors}
                  list={reference}
                  fieldName="reference"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
              </div>
            )
          }
          {
            page === 3 && (
              <div>
                <StaffTableForm
                  title="K. PENGALAMAN KERJA"
                  listField={STAFF_JOB_EXPERIENCE_FORM}
                  error={jobExperienceErrors}
                  list={jobExperience}
                  fieldName="jobExperience"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                />
                <ListForm
                  form={form}
                  formFields={MANAGE_JOB_APPLICATION_FORM_3}
                  onFormChange={this._onFormChange}
                />
              </div>
            )
          }
          {
            page === 4 && (
              <div>
                <ListForm
                  form={form}
                  formFields={MANAGE_JOB_APPLICATION_FORM_4_1}
                  onFormChange={this._onFormChange}
                />
                <ListForm
                  form={form}
                  formFields={MANAGE_JOB_APPLICATION_FORM_4_2}
                  onFormChange={this._onFormChange}
                />
              </div>
            )
          }
          <div>
            {
              page === 1 && (
                <div className="manage-job-application__button">
                  <Button
                    title="Selanjutnya"
                    onClick={this._onNextPage}
                  />
                </div>
              )
            }
            {
              (page !== 1 && page !== 4) && (
                <div className="manage-job-application__button">
                  <Button
                    title="Kembali"
                    onClick={this._onPrevPage}
                  />
                  <Button
                    title="Selanjutnya"
                    onClick={this._onNextPage}
                  />
                </div>
              )
            }
            {
              page === 4 && (
                <div className="manage-job-application__button">
                  <Button
                    title="Kembali"
                    onClick={this._onPrevPage}
                  />
                  <Button
                    title="Simpan"
                  />
                </div>
              )
            }
          </div>
        </form>
      </div>
    );
  }
}
