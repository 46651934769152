import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../components/base/index';
import { Link } from 'react-router-dom';


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
        <div >
       
        
            {
                <div>
                  
                  <h1>REKAP PEMINJAMAN BUKU BERDASARKAN KELAS UNIT</h1>
                  <h1>PERIODE:-</h1>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th rowSpan={2}>Kelas</th>
                          <th rowSpan={2}>Jumlah</th>
                          <th colSpan={2}>Jumlah</th>
                          <th colSpan={2}>Persentase</th>
                         
                        </tr>
                        <tr>
                          <th>Fiksi</th>
                          <th>Non Fiksi</th>
                          <th>Fiksi</th>
                          <th>Non Fiksi</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => ( */}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td ></td>
                            <td></td>
                            <td></td>
                            
                          </tr>
                        {/* ))
                      } */}
                      </tbody>
                    </table>
                  </div>

                  <h1>REKAP PEMINJAMAN BUKU BERDASARKAN JENIS KELAMIN UNIT</h1>
                  <h1>PERIODE:-</h1>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                        <th rowSpan={2}>No</th>
                          <th rowSpan={2}>Kelas</th>
                          <th rowSpan={2}>Jumlah</th>
                          <th rowSpan={2}>Jenis Kelamin</th>
                          <th colSpan={2}>Jumlah</th>
                          <th colSpan={2}>Persentase</th>
                         
                        </tr>
                        <tr>
                          <th>Fiksi</th>
                          <th>Non Fiksi</th>
                          <th>Fiksi</th>
                          <th>Non Fiksi</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => ( */}
                         <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                          </tr>
                        {/* ))
                      } */}
                      </tbody>
                    </table>
                  </div>

                  <h1>REKAP PEMINJAMAN BUKU BERDASARKAN BULAN UNIT</h1>
                  <h1>PERIODE:-</h1>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th rowSpan={2}>Golongan Total nnnn Buku</th>
                          <th rowSpan={2}>Jumlah Buku</th>
                          <th rowSpan={2}>%Buku</th>
                          <th colSpan={13}>Tanggal</th>
                          <th rowSpan={2}>Jumlah</th>
                          
                         
                        </tr>
                        <tr>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                          <th>9</th>
                          <th>10</th>
                          <th>11</th>
                          <th>12</th>
                          <th>13</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => ( */}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        {/* ))
                      } */}
                      </tbody>
                    </table>
                  </div>
                
                  

                

                </div>

                

                
              
            }
          
        </div>
  );
     }
}
ComponentToPrint.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ComponentToPrint.defaultProps = {
  onFormChange: noop,
};
