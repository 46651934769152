import { connect } from 'react-redux';
import ProfitAndLost from '../../../views/Finance/Report/ProfitAndLost.report.view';
import { getProfitLossReport } from '../../../states/thunks/report.thunk';

function mapStateToProps(state) {
  return {
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetProfitLossReport: payload => dispatch(getProfitLossReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitAndLost);
