import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  map, noop, drop, dropRight,
} from 'lodash';
import {
  Input, Select,
} from '../../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../../utils/transformer.util';

const TAX_TYPE_LIST = [{
  label: 'Perorangan', value: 'pph21',
}, {
  label: 'Badan Usaha', value: 'pph23',
}, {
  label: 'Konstruksi', value: 'pphp4a2',
}];

export default class CashJournalTax extends Component {
  constructor(props) {
    super(props);

    this._onChange = this._onChange.bind(this);
    this._setTaxPtkpParam = this._setTaxPtkpParam.bind(this);
    this._setTaxRecipientParam = this._setTaxRecipientParam.bind(this);
    this.state = {
      taxPtkpParam: [],
      taxRecipientsParam: {},
    };
  }

  componentDidMount() {
    this._setTaxPtkpParam();
    this._setTaxRecipientParam();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { data: prevData } = prevProps;

    if (data.tax !== prevData.tax) {
      this._setTaxRecipientParam();
    }
  }

  async _setTaxPtkpParam() {
    const { getTaxPtkpParam } = this.props;

    const res = await getTaxPtkpParam();

    this.setState(prevState => ({
      ...prevState,
      taxPtkpParam: res,
    }));
  }

  async _setTaxRecipientParam() {
    const { getTaxRecipientParam, data } = this.props;
    const { taxRecipientsParam } = this.state;

    if (data && data.tax) {
      if (!taxRecipientsParam[data.tax]) {
        const res = await getTaxRecipientParam({
          data: {
            tax: data.tax,
          },
        });

        this.setState(prevState => ({
          ...prevState,
          taxRecipientsParam: {
            ...prevState.taxRecipientsParam,
            [data.tax]: res,
          },
        }));
      }
    }
  }

  _onChange(e) {
    const { onChange } = this.props;
    const { target } = e;
    const { name, value, dataset } = target;
    let formattedValue = value;

    if (dataset.inputType === 'number') {
      formattedValue = normalizeAmount(value);
    }

    onChange(name, formattedValue);
  }

  _show512() {
    const TYPE = [
      {
        label: 'Orang Dalam',
        value: 'ORANG_DALAM',
      }, {
        label: 'Orang Luar',
        value: 'ORANG_LUAR',
      },
    ];

    return (
      <Select
        noMargin
        data-input-array
        data-array-position="1"
        data-field-name="tipe-penerima"
        name="tipe-penerima"
        data={TYPE}
        onChange={this._onChange}
        placeholder="Pilih Tipe Penerima"
        value=""
        error=""
        disabled=""
      />
    );
  }

  _show515() {
    const TYPE = [
      {
        label: 'Personal',
        value: 'PERSONAL',
      }, {
        label: 'Badan Usaha',
        value: 'BADAN_USAHA',
      },
    ];

    return (
      <Select
        noMargin
        data-input-array
        data-array-position="1"
        data-field-name="tipe-penerima"
        name="tipe-penerima"
        data={TYPE}
        onChange={this._onChange}
        placeholder="Pilih Tipe Penerima"
        value=""
        error=""
        disabled=""
      />
    );
  }

  _show517() {
    const TYPE = [
      {
        label: 'Personal',
        value: 'PERSONAL',
      }, {
        label: 'Badan Usaha',
        value: 'BADAN_USAHA',
      },
    ];

    return (
      <Select
        noMargin
        data-input-array
        data-array-position="1"
        data-field-name="tax"
        name="recipient"
        data={TYPE}
        onChange={this._onChange}
        placeholder="Pilih Tipe Penerima"
        value=""
        error=""
        disabled=""
      />
    );
  }

  renderTableHeader() {
    const { type, data } = this.props;
    const { tax } = data;
    const header = ['Jenis Pajak', 'Penerima Pajak', 'Nama', 'NPWP'];

    if (tax === 'pph21' && !type.startsWith('517')) {
      header.push('PTKP', 'Hari Kerja');
    }

    header.push('Jumlah Pembayaran', 'Potongan Pajak');

    return (
      <thead>
        <tr>
          {
          map(header, (title, idx) => (
            <th key={`table_thead_th_${idx}`}>
              {title}
            </th>
          ))
        }
        </tr>
      </thead>
    );
  }

  renderTableDataRow() {
    const { type, data } = this.props;
    const { taxPtkpParam, taxRecipientsParam } = this.state;
    const { fields, tax } = data;
    const taxTypeList = (type.startsWith('517')) ? drop(TAX_TYPE_LIST, 2) : dropRight(TAX_TYPE_LIST);

    return (
      <tbody>
        <tr>
          <td>
            <Select
              noMargin
              data-field-name="tax"
              name="tax"
              data={taxTypeList}
              onChange={this._onChange}
              placeholder="Pilih Tipe Pajak"
              value={data.tax}
              error=""
              disabled=""
            />
          </td>
          <td>
            <Select
              noMargin
              data-field-name="recipient"
              name="recipient"
              data={taxRecipientsParam[tax]}
              onChange={this._onChange}
              placeholder="Pilih Tipe Penerima"
              value={data.recipient || ''}
              error=""
              disabled=""
            />
          </td>
          <td>
            <Input
              noMargin
              data-field-name="recipient_name"
              name="recipient_name"
              type="input"
              data-input-type="text"
              placeholder="Nama"
              onChange={this._onChange}
              value={fields.recipient_name || ''}
              error=""
            />
          </td>
          <td>
            <Input
              noMargin
              data-field-name="npwp"
              name="npwp"
              type="input"
              data-input-type="text"
              placeholder="npwp"
              onChange={this._onChange}
              value={fields.npwp || ''}
              error=""
            />
          </td>
          {
            (!type.startsWith('517') && tax === 'pph21') && (
              <td style={{ width: `${5}%` }}>
                <Select
                  noMargin
                  data-field-name="ptkp"
                  name="ptkp"
                  data={taxPtkpParam}
                  placeholder="ptkp"
                  onChange={this._onChange}
                  value={fields.ptkp || ''}
                  error=""
                />
              </td>
            )
          }
          {
            (!type.startsWith('517') && tax === 'pph21') && (
              <td>
                <Input
                  noMargin
                  data-field-name="working_day"
                  name="working_day"
                  type="text"
                  placeholder="working_day"
                  onChange={this._onChange}
                  value={fields.working_day || 0}
                  error=""
                />
              </td>
            )
          }
          <td>
            <Input
              noMargin
              data-input-array
              data-array-position="1"
              data-field-name="gross_income_per_month"
              name="gross_income_per_month"
              type="text"
              data-input-type="number"
              placeholder="gross_income_per_month"
              onChange={this._onChange}
              disabled
              value={fields.gross_income_per_month ? commaFormatted(fields.gross_income_per_month) : ''}
              error=""
            />
          </td>
          <td>
            <Input
              noMargin
              data-input-array
              data-array-position="1"
              data-field-name="tax_deduction"
              name="tax_deduction"
              type="text"
              data-input-type="number"
              placeholder="tax_deduction"
              onChange={this._onChange}
              value={commaFormatted(data.tax_deduction)}
              error=""
            />
          </td>
        </tr>
      </tbody>
    );
  }

  render() {
    const { type } = this.props;

    return (
      <div>
        {
          type === '512' && this._show512()
        }
        <table border="1" width="100%" cellPadding="5px" cellSpacing="0px">
          {this.renderTableHeader()}
          {this.renderTableDataRow()}
        </table>
      </div>
    );
  }
}

CashJournalTax.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  getTaxRecipientParam: PropTypes.func,
  getTaxPtkpParam: PropTypes.func,
};

CashJournalTax.defaultProps = {
  data: null,
  type: '',
  onChange: noop,
  getTaxRecipientParam: noop,
  getTaxPtkpParam: noop,
};
