import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../components/base/index';
import { Link } from 'react-router-dom';


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
        <div >
       
        
            {
                <div>
                  
                  <h1>REKAP PENGUNJUNG PERPUSTAKAAN BERDASARKAN KELAS UNIT</h1>
                  <h1>PERIODE:-</h1>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th rowSpan={2}>Bulan</th>
                          <th rowSpan={2}>Jumlah Siswa</th>
                          <th colSpan={4}>Kelas</th>
                          <th colSpan={2}>Jumlah Pengunjung</th>
                         
                        </tr>
                        <tr>
                          <th>1A</th>
                          <th>1B</th>
                          <th>2A</th>
                          <th>2B</th>
                          <th>Jumlah</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => ( */}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        {/* ))
                      } */}
                      </tbody>
                    </table>
                  </div>

                  <h1>REKAP PENGUNJUNG PERPUSTAKAAN BERDASARKAN JENIS KELAMIN UNIT</h1>
                  <h1>PERIODE:-</h1>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th rowSpan={2}>Bulan</th>
                          <th rowSpan={2}>Jumlah Siswa</th>
                          <th rowSpan={2}>Jumlah Kelamin</th>
                          <th colSpan={4}>Kelas</th>
                          <th colSpan={2}>Jumlah Pengunjung</th>
                         
                        </tr>
                        <tr>
                          <th>1A</th>
                          <th>1B</th>
                          <th>2A</th>
                          <th>2B</th>
                          <th>Jumlah</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => ( */}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        {/* ))
                      } */}
                      </tbody>
                    </table>
                  </div>

                  <h1>REKAP PENGUNJUNG PERPUSTAKAAN BERDASARKAN TANGGAL UNIT</h1>
                  <h1>PERIODE:-</h1>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th rowSpan={2}>No</th>
                          <th rowSpan={2}>Bulan</th>
                          <th colSpan={13}>Tanggal</th>
                          <th rowSpan={2}>Jumlah</th>
                          <th rowSpan={2}>%</th>
                         
                        </tr>
                        <tr>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                          <th>9</th>
                          <th>10</th>
                          <th>11</th>
                          <th>12</th>
                          <th>13</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => ( */}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        {/* ))
                      } */}
                      </tbody>
                    </table>
                  </div>
                
                  <div className="laporan-rpp__custom-form-row">
                    <Input
                    readOnly
                    type="text"
                    name="namaSaudara1"
                    label="Tahun Ajaran"
                    style={{backgroundColor:'#999'}}
                    onChange={onFormChange}
                    data={ '7A', 'kelas7a'}
                    placeholder={'Pilihan'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="laporan-rpp__custom-form-row">
                    <Input
                    readOnly
                    type="text"
                    name="namaSaudara1"
                    label="Jumlah Siswa"
                    onChange={onFormChange}
                    style={{backgroundColor:'#999'}}
                    data={ '7A', 'kelas7a'}
                    placeholder={'Pilihan'}
                    
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="laporan-rpp__custom-form-row">
                    <Input
                    readOnly
                    type="text"
                    name="namaSaudara1"
                    label="Rata-Rata Harian"
                    onChange={onFormChange}
                    style={{backgroundColor:'#999'}}
                    data={ '7A', 'kelas7a'}
                    placeholder={'Pilihan'}
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="laporan-rpp__custom-form-row">
                    <Input
                    readOnly
                    type="text"
                    name="namaSaudara1"
                    label="Rata-Rata Bulanan"
                    onChange={onFormChange}
                    style={{backgroundColor:'#999'}}
                    data={ '7A', 'kelas7a'}
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>
                <div className="laporan-rpp__custom-form-row">
                    <Input
                    readOnly
                    type="text"
                    name="namaSaudara1"
                    label="Rata-Rata Tahun"
                    onChange={onFormChange}
                    style={{backgroundColor:'#999'}}
                    data={ '7A', 'kelas7a'}
                    // error={form.error.namaSaudara1 || ''}
                    />
                </div>

                

                </div>

                

                
              
            }
          
        </div>
  );
     }
}
ComponentToPrint.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ComponentToPrint.defaultProps = {
  onFormChange: noop,
};
