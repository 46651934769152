import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import SelectCoa from '../SelectCoa/SelectCoa.component';
import { Input, Pagination, Button } from '../base';

export default class ReportSalary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
    };
    this._onFormChange = this._onFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  _onFormChange(event) {
    const {
      name,
      value,
      type,
      checked = false,
    } = event.target;
    this.setState((prevState) => {
      let formattedValue = value;
      if (type === 'checkbox') {
        formattedValue = checked;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onSearch() {
    const { form } = this.state;
    const { onSearch } = this.props;
    onSearch(form.value);
  }

  _renderSelectCoa() {
    const { form } = this.state;
    const { coa, onSearchCoa } = this.props;
    return (
      <div>
        <SelectCoa
          noMargin
          inputArray
          arrayPosition={0}
          levelFour
          fieldName="coa"
          name="coa"
          placeholder="Pilih Kode Akun"
          coa={coa}
          value={form.value.coa}
          rightIcon="icon-search"
          onClick={this._onFormChange}
          onSearchCoa={onSearchCoa}
          label="Kode Akun"
        />
      </div>
    );
  }

  render() {
    const { form } = this.state;
    const {
      paging,
      children,
      header,
      coa,
      currentPage,
      totalPage,
    } = this.props;
    return (
      <div className="report-card">
        {header}
        <div className="report-card__header">
          <div className="report-card__entries">
            { coa != null ? this._renderSelectCoa() : ''}
            <div>
              <Input
                type="date"
                label="Di cetak tanggal"
                name="from"
                value={form.value.from}
                onChange={this._onFormChange}
              />
            </div>
            <div>
              <Input
                type="date"
                label="Bulan"
                name="to"
                value={form.value.to}
                onChange={this._onFormChange}
              />
            </div>
            {/* <div>
              <Button
                title="Cari"
                onClick={this._onSearch}
              />
            </div> */}
          </div>
        </div>
        <div className="report-card__body">
          {children}
        </div>
        {
          paging && (
            <div className="report-card__footer">
              <p className="user-management__page-info">
                {`Halaman ${currentPage} dari ${totalPage}`}
              </p>
              <Pagination
                totalPage={totalPage}
                currentPage={currentPage}
              />
            </div>
          )
        }
      </div>
    );
  }
}
ReportSalary.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  paging: PropTypes.bool,
  onSearch: PropTypes.func,
  onSearchCoa: PropTypes.func,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  coa: PropTypes.object,
};
ReportSalary.defaultProps = {
  paging: true,
  header: null,
  onSearch: noop,
  onSearchCoa: noop,
  currentPage: 1,
  totalPage: 1,
  coa: null,
};
