import { connect } from 'react-redux';
import Logout from '../views/Logout/Logout.view';
import { handleLogout } from '../states/thunks/auth.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogout: (payload, push) => dispatch(handleLogout(payload, push)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
