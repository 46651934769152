import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Button } from '../../../components/base';
import { formatData,  normalizeAmount } from '../../../utils/transformer.util';
import { KELAS_DATAKELAS_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/kelas.constant';
import ControlTablePerpus from '../components/ControlTablePerpus.component';

export default class TableControlPerpus extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onClickSettingan = this._onClickSettingan.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  
    this.state= {
      coa: {
        loading: true,
        list: [],

      },
      form: {
        value: {},
        error: {},
      },
    };
  
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/buat-daftar-unit-perpus');
  }

  _onEdit() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/settingan-uang-sekolah');
  }

  _onClickSettingan() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/settingan-uang-sekolah');
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }


  render() {
    const {form} = this.state;
    const { handleGetKelas, handleDeleteKelas,coa } = this.props;
    return (
      <div className="student-list">
        
          <div className="student-list__button">
            <Button
              title="Buat Daftar Unit Perpus"
              onClick={this.onClickAdd}
            />
          </div>

        <div className="budget__content">
        <ControlTablePerpus
          form={form}
          onClickEdit = {this._onEdit}
          onFormChange={this._onFormChange}
        />
        </div>
      </div>
    );
  }
}
TableControlPerpus.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TableControlPerpus.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
