import { connect } from 'react-redux';
import AnalisaPelanggaran from '../../../../views/Student/displiner/cetak/AnalisaPelanggaran.student.view';
import { getStudent } from '../../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(AnalisaPelanggaran);


