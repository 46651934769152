import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import BaseTable from '../../../../components/BaseTable/BaseTable.component';
import { commaFormatted } from '../../../../utils/transformer.util';

export default function Realization(props) {
  const { handleGetRealization } = props;

  const columns = [{
    name: 'Kode Akun',
    selector: 'code_of_account',
    sortable: true,
  }, {
    name: 'Deskripsi',
    selector: 'desc',
  }, {
    name: 'APBU',
    selector: 'ypl',
    width: '200px',
    right: true,
    sortable: true,
    cell: row => commaFormatted(row.ypl),
  }, {
    name: 'Realisasi',
    selector: 'utilized_budget',
    sortable: true,
    right: true,
    cell: row => commaFormatted(row.utilized_budget),
  }, {
    name: 'Persentase',
    selector: 'percentage',
    sortable: true,
    right: true,
    cell: row => `${commaFormatted(row.percentage)}%`,
  }, {
    name: 'Sisa Anggaran',
    selector: 'remains',
    sortable: true,
    right: true,
    cell: row => commaFormatted(row.remains),
  }];

  const filters = {
    periode: {
      type: 'select',
      data: [
        { value: 0, label: 'Januari' },
        { value: 1, label: 'Februari' },
        { value: 2, label: 'Maret' },
        { value: 3, label: 'April' },
        { value: 4, label: 'Mei' },
        { value: 5, label: 'Juni' },
        { value: 6, label: 'Juli' },
        { value: 7, label: 'Agustus' },
        { value: 8, label: 'September' },
        { value: 9, label: 'Oktober' },
        { value: 10, label: 'November' },
      ],
    },
  };

  return (
    <BaseTable
      columns={columns}
      handleGet={handleGetRealization}
      filters={filters}
      title="Realisasi"
    />
  );
}

Realization.propTypes = {
  handleGetRealization: PropTypes.func,
};
Realization.defaultProps = {
  handleGetRealization: noop,
};
