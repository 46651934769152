import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { Input, Select, Button } from '../../../../components/base/index';

import ProsesPembayaranTbl from './components/ProsesPembayaranTbl.component';
import {PROCESS_CREATE_INVOICE_TABLE_FIELD_FORM} from '../../../../constants/finance/spp/create_invoice.constant';
export default class ProsesPembayaran extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onClickAdd = this._onClickAdd.bind(this);
        this.state= {
          counter:0,
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              plafon: [],
            },
            error: {
              plafon: '',
    
            },
          },
        };
      
      }

      componentDidMount() {
        const { handleGetCodeOfAccount, location, handleGetClasses } = this.props;
       handleGetClasses();
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/upload');
      }

      _renderButtons() {
        const { user, location } = this.props;
        const { data = {} } = location.state
        const { user_groups_id, user_group } = user;
        const { workflow = [] } = data;
        const lastWorkflow = workflow[workflow.length - 1] || {};
        const firstWorkflow = workflow[0] || {};
        const WorkflowPrev_role = workflow[0]?workflow[0].prev_role:'';
        const { permissions = [] } = user_group;
        
       
          return [
            <div key="button_save">
              <Button
                type="submit"
                title="Save"
              />
            </div>,
            <div key="button_submit">
              <Button
                type="button"
                onClick={this._onSubmitApproval}
                title={(user_group.name == 'Hrd' || user_group.name =='Kepala Sekolah' || user_group.name =='Opsdik'
                        || user_group.name =='Sekretariat') ? 'Submit' : 'Approve'}
              />
            </div>,
          ];
      
      }

      _onClickAdd(fieldName) {
        const { form } = this.state;
        const { plafon } = form.value;
    
        const {plafond_name} = form.value;
        // const {code_plafond} = form.value;
    
        const {code_plafond} = form.value;
    
        // const {code_class} = form.value;
    
        plafon.push({
          // code_class :'',
    
          // code_plafond : ',',
          plafond_name:',',
    
          code_plafond : ',',
    
        
        // plafon.push({
          tipePlafon: '',
          // divisi: '',
    
          nominal: 0,
          //period:'',
        });

        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              plafon,
              plafond_name,
              // code_plafond,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
      }
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { plafon } = prevState.form.value;
          const list = prevState.form.value.plafon;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            plafon: list
          };
        });
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form} = this.state;
        const { value, error } = form;
        
        const { classes ={}, location, plafond = {}} = this.props;
        const { isEdit } = location.state;
        const {
          reconciliation,
          standard,
          tipe,
          type,
          is_posted,
          is_reconciliation
        } = form.value;
        return (
          <div className="student-list">
               <div className="budget__title">
                  <h1>Pembayaran</h1>
                  <hr></hr>
                </div>
                <div className="nilai-kognitif__custom-form">
                    
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Select
                          name="classes"
                          label="Kelas"
                          onChange={this._onFormChange}
                          data={ classes.list}
                          placeholder={'Pilihan'}
                          value={value.classes}
                          error={error.classes || ''}
                        />
                      </div>
                      
                      <div className="nilai-kognitif__custom-form-invoice__field">
                      {/* <Select
                          name="all"
                          label=""
                          onChange={this._onFormChange}
                          // data={ classes.list}
                          placeholder={'Semua Siswa'}
                          value={value.all}
                          error={error.all || ''}
                        /> */}
                    </div>
                    </div>
                  
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Select
                          name="periode"
                          label="Periode"
                          onChange={this._onFormChange}
                          // data={ periode.list}
                          placeholder={'Pilihan'}
                          value={value.periode}
                          error={error.periode || ''}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                      <Select
                          name="status"
                          label="Status"
                          onChange={this._onFormChange}
                          // data={ periode.list}
                          placeholder={'Pilihan'}
                          value={value.status}
                          error={error.status || ''}
                        />
                    </div>
                    </div>
                    
                  </div>
                    {
            isEdit
          }
         
              <div>
                <ProsesPembayaranTbl
               plafond_name={plafond.list}
               onAddList={this._onClickAdd}
               list={value.plafon}
               onDeleteList={this._onDeleteList}
               onChange={this._onFormChange}
                  
                />
              </div>
          
          <div className="buttons-wrapper">
            <div key="button_save">
              <Button
                type="submit"
                title="Save"
              />
            </div>,
            <div key="button_submit">
              <Button
                type="button"
                onClick={this._onSubmitApproval}
                title={ 'Submit'}
              />
            </div>
            </div>
                      
                     
          </div>
        );
      }
}
ProsesPembayaran.propTypes = {
  handleGetClasses: PropTypes.func,   
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ProsesPembayaran.defaultProps = {
  handleGetClasses: noop,  
  handleGetStudent: noop,
};
