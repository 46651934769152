import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Button, Input } from '../../../../../components/base';
import RapbuRow from './RapbuRow.component';
import {
  commaFormatted, sumBudgetTransferSource,
} from '../../../../../utils/transformer.util';

export default class RapbuForm extends PureComponent {
  constructor(props) {
    super(props);
    this._onAddRow = this._onAddRow.bind(this);
    this._onDeleteRow = this._onDeleteRow.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  _onAddRow() {
    const { onAddRow } = this.props;
    onAddRow('rapbuSource');
  }

  _onDeleteRow(idx) {
    const { onDeleteRow } = this.props;
    onDeleteRow('rapbuSource', idx);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event, 'rapbuSource');
  }

  render() {
    const {
      list, error, coa,
      totalTransferError,
    } = this.props;
    return (
      <table className="manage-budget-transfer__form-table">
        <thead>
          <tr>
            <th rowSpan={2}>No</th>
            <th rowSpan={2}>Kode</th>
            <th rowSpan={2}>Uraian</th>
            <th rowSpan={2}>Semester</th>
            <th colSpan={3}>Perhitungan</th>
            <th rowSpan={2}>Jumlah</th>
            <th rowSpan={2}>Keterangan</th>
            <th rowSpan={2}>&nbsp;</th>
          </tr>
          <tr>
            <th>Kuantitas</th>
            <th>Satuan</th>
            <th>Periode</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (transaction, idx) => (
              <RapbuRow
                key={`budget_source_list__${idx}`}
                idx={idx}
                coa={coa}
                onDeleteRow={this._onDeleteRow}
                onChange={this._onFormChange}
                data={transaction}
                error={error[idx]}
              />
            ))
          }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>Total pengalihan dana</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td colSpan={3}>
              <Input
                edit={false}
                value={commaFormatted(sumBudgetTransferSource(list))}
                error={totalTransferError}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={10} className="manage-budget-transfer__add-more">
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddRow}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}
RapbuForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  coa: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onAddRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  totalTransferError: PropTypes.string,
};
RapbuForm.defaultProps = {
  onChange: noop,
  onAddRow: noop,
  onDeleteRow: noop,
  totalTransferError: '',
};
