import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import { Link } from 'react-router-dom';

export default class PengajuanKegForm extends Component {
    constructor(props) {
        super(props);
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }

  _onDeleteList() {
    const { onDeleteList, fieldName } = this.props;
    
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, plafond_name,onFormChange, form
    } = this.props;

  
    return (
        <div className="daftar-ekskul__content">
                   
                    <div className="laporan-pegawai__body">
                <div className="komposisi-siswa__custom-form-row">
                    <div className="komposisi-siswa__custom-form-row__field">
                        <Input
                        type="text"
                        label="Nama Pegawai"
                        name="namapegawai"
                        readOnly
                        style={{backgroundColor:'grey'}}  
                        placeholder={'Nama Lengkappegawai/NIK'}
                        />
                    </div>
                    <div className="laporan-keu2__custom-form-row2__field">
                        <div className="laporan-keu2__custom-form-row5"> 
                            <Input
                                type="date"
                                name="tahunajaran"
                                label="Tanggal Kegiatan"
                                onChange={this._onFormChange}
                                // value={form.value.tempatLahirSaudara1 || ''}
                                // error={form.error.tempatLahirSaudara1 || ''}
                                // placeholder={'Pilihan'}
                            />
                        </div>
                        <p className="laporan-pegawai__custom-p">S/D</p>
                        <div className="laporan-pegawai__custom-form-row6">
                            <Input
                                type="date"
                                name="tahunajaran"
                                onChange={this._onFormChange}
                                // value={form.value.tempatLahirSaudara1 || ''}
                                // error={form.error.tempatLahirSaudara1 || ''}
                                // placeholder={'Pilihan'}
                            />
                        </div>
                    </div> 
                </div>
                <div className="komposisi-siswa__custom-form-row">
                <div className="komposisi-siswa__custom-form-row__field">
                    <Select
                        label="Nama Kegiatan"
                        // value={from}
                        name="tahunajaran"
                        onChange={this._onChange}
                    />
                </div>
                <div className="komposisi-siswa__custom-form-column__field">
                    <Input
                        type="text"
                        label="Keterangan Kegiatan"
                        // value={to}
                        name="kelas"
                        onChange={this._onChange}
                    />
                </div>
            </div>
                <div className="manage-registration__footer">
                    <Button
                    onClick={this._onSubmit}
                    title="Simpan Data"
                    />
                </div>
            </div>   
                  </div>
      );
  }


 
}
PengajuanKegForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengajuanKegForm.defaultProps = {
  onFormChange: noop,
};
