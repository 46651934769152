import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import PersonalReportFilter from '../../../components/Filter/PersonalReport.filter.component';
import { validateRequiredFields } from '../../../utils/validation.util';

export default class JobPeriod extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  _handleFormChange(event) {
    const {
      name,
      value,
    } = event.target;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: '',
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ['year']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  render() {
    const { filtered, form } = this.state;
    return (
      <div className="job-period">
        {
          filtered ? (
            <PersonalReportFilter
              year={form.value.year}
              error={form.error.year}
              onSearch={this._onSearch}
              onChange={this._handleFormChange}
            />
          ) : (
            <div className="job-period__content">
              <table className="job-period__form-table">
                <thead>
                  <tr>
                    <th rowSpan={2}>Unit</th>
                    <th colSpan={7}>Masa Kerja Juni</th>
                  </tr>
                  <tr>
                    <th>&#60; 5</th>
                    <th>5 - 10</th>
                    <th>11 - 20</th>
                    <th>21 - 30</th>
                    <th>31 - 35</th>
                    <th>&#62; 35</th>
                    <th>Jml</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TK I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>SD I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>SMP I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>SMA I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Sekre I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Kary I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Dokter I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Jumlah</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>TK II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>SD II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>SMP II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>SMA II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Sekre II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Kary II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Dokter II</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Jumlah</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>RI & R II</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )
        }
      </div>
    );
  }
}
JobPeriod.propTypes = {};
JobPeriod.defaultProps = {};
