import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Loading(props) {
  const {
    alwaysShow,
    full,
    loading,
    white,
  } = props;
  const { show, message } = loading;
  const loadingClassnames = classnames([
    'loading',
    full && 'loading__full',
    !show && 'loading-hide',
    alwaysShow && 'loading-show',
  ]);
  const loadingContentClassnames = classnames([
    'loading-ellipsis',
    white && 'loading-ellipsis--white',
  ]);
  return (
    <div className={loadingClassnames}>
      <div className={loadingContentClassnames}><div /><div /><div /><div /></div>
      {message && <p className="loading__message">{message}</p>}
    </div>
  );
}
Loading.propTypes = {
  alwaysShow: PropTypes.bool,
  full: PropTypes.bool,
  loading: PropTypes.object,
  white: PropTypes.bool,
};
Loading.defaultProps = {
  alwaysShow: false,
  full: false,
  loading: { show: false, message: '' },
  white: false,
};
