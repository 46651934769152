import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map, noop } from 'lodash';

export default class Option extends PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  setRef(input) {
    this.input = input;
  }

  render() {
    const {
      label,
      error,
      disabled,
      noMargin,
      name,
      data,
      onChange,
    } = this.props;
    const wrapperClassnames = classnames([
      'salary-option',
      noMargin && 'option__no-margin',
    ]);
    const labelClassnames = classnames([
      'option__label',
      error && 'option__label--error',
    ]);
    const containerClassnames = classnames([
      'option__container',
      disabled && 'option__disabled',
      error && 'option__container--error',
    ]);
    return (
      <div className={wrapperClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        <div className={containerClassnames}>
          {
            map(data, (list, idx) => [
              <input
                key={`option_${idx}`}
                className="option__select"
                type="radio"
                name={name}
                value={list.value}
                onChange={onChange}
              />,
              <p key={`option_label_${idx}`} className="option__list-label">{list.label}</p>,
            ])
          }
        </div>
        {error && <p className="option__err">{error}</p>}
      </div>
    );
  }
}
Option.propTypes = {
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
Option.defaultProps = {
  disabled: false,
  noMargin: false,
  name: '',
  label: '',
  error: '',
  onChange: noop,
};
