import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { datetimeFormat } from '../utils/transformer.util';

export const WorkflowContext = React.createContext({});

export const roleMap = {
  'Keuangan Sekolah': 'Bendahara Unit',
  'Kepala Sekolah': 'Kepala Sekolah',
  'Bendahara Perwakilan': 'Bendahara Perwakilan',
  'Korektor Perwakilan': 'Perwakilan',
  'Ketua Perwakilan': 'Ketua Perwakilan',
  'Bendahara Pusat': 'Bendahara Pusat',
  'Korektor Pusat': 'Korektor Pusat',
  'Manager Keuangan': 'Manager Keuangan',
  'Accounting Pusat': 'Akunting Pusat',
  Bendahara: 'Pengurus',
};

export function workflowIsEmpty(workflow) {
  return typeof workflow === 'undefined' || (workflow.length === 0) || workflow === null;
}

export function useWorkflow(workflow = [], { user_group = {} }) {
  const isEmpty = workflow.length === 0;

  const containsMe = workflow.filter(flow => flow.nextRole === user_group.name
    || flow.prev_role === user_group.name).length > 0;

  const assignedToMe = !isEmpty
    && workflow[workflow.length - 1].nextRole === user_group.name;

  const workflowList = workflow.map(({
    prev_role, next_role, action, updated_at, remarks, is_done,
  }, idx) => {
    const index = idx + 1;
    const updatedAt = datetimeFormat(updated_at);
    const flowAction = (action === 'reject') ? 'mengembalikan ke' : 'mengajukan ke';
    let flowValue = `${roleMap[prev_role]} ${flowAction} ${roleMap[next_role]}`;
    if (is_done) {
      flowValue = `Telah disetujui oleh ${this.roleMap[next_role]}`;
    }
    return {
      index,
      flowValue,
      remarks,
      updatedAt,
    };
  });

  const isDone = !isEmpty && workflow[workflow.length - 1].is_done;

  const createdByMe = !isEmpty && workflow[0].prevRole === user_group.name;

  const canSubmit = isEmpty || !isDone;

  const canReject = !isEmpty && !isDone && !createdByMe;

  return {
    containsMe,
    assignedToMe,
    workflowList,
    isDone,
    createdByMe,
    canSubmit,
    canReject,
  };
}

export function Workflow({
  workflow, children
}) {
  const authContext = useContext(AuthContext);
  const { user } = authContext.getState();
  const {
    containsMe, assignedToMe, workflowList, isDone, createdByMe, canSubmit,
    canReject,
  } = useWorkflow(workflow, user);

  return (
    <WorkflowContext.Provider
      value={{
        workflow,
        user,
        containsMe,
        assignedToMe,
        workflowList,
        isDone,
        createdByMe,
        canSubmit,
        canReject,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
}

export function WorkflowContextConsumer(children) {
  return (
    <WorkflowContext.Consumer>
      {children}
    </WorkflowContext.Consumer>
  );
}
