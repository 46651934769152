export const BUDGET_TRANSFER_REQUIRED_FIELD = ['parameter_code'];

export const FINANCE_BUDGET_TRANSFER_TABLE_FIELDS = {
  label: [
    'No.',
    'No. Pengajuan',
    'Tanggal',
    'Keterangan',
    'Jumlah Pengalihan Dana',
    'Status',
  ],
  value: [
    {},
    { type: 'string', attribute: 'no_pengajuan' },
    { type: 'date', attribute: 'created_at' },
    { type: 'string', attribute: 'description' },
    { type: 'number', attribute: 'total' },
    { type: 'status', attribute: 'submitted' },
  ],
};
