import { connect } from 'react-redux';
import ManageAdmission from '../../views/Admission/ManageAdmission.admission.view';
import { manageAdmission } from '../../states/thunks/admission.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleManageAdmission: (payload, push) => dispatch(manageAdmission(payload, push)),
  };
}

export default connect(null, mapDispatchToProps)(ManageAdmission);
