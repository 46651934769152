import { connect } from 'react-redux';
import Position from '../../../views/Personnel/Letter/Position.letter.view';
import { getPosition } from '../../../states/thunks/personnel.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetPosition: payload => dispatch(getPosition(payload)),
  };
}

export default connect(null, mapDispatchToProps)(Position);
