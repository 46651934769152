import LembarObsMingguan from '../../../views/Kurikulum/Ulangan/LembarObsMingguan.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editLembarObsMingguan, saveLembarObsMingguan, getLembarObsMingguan } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
      user: state.user,
      result: state.result,
    }
  }

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditLembarObsMingguan: (payload, goBack) => dispatch(editLembarObsMingguan(payload, goBack)),
        handleSaveLembarObsMingguan: (payload, goback) => dispatch(saveLembarObsMingguan(payload, goback)),
        handleGetLembarObsMingguan: payload => dispatch(getLembarObsMingguan(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (LembarObsMingguan);