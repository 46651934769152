import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../components/ListForm/ListForm.component';
import SalaryOption from '../../components/SalaryOption/SalaryOption.component';
import { commaFormatted } from '../../utils/transformer.util';
import { STUDENT_ADMISSION_FORM_FIELDS, PARENT_ADMISSION_FORM_FIELDS } from '../../constants/admission.constant';
import { Button } from '../../components/base';

export default class ManageAdmission extends PureComponent {
  constructor(props) {
    super(props);
    this._onSetForm = this._onSetForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.state = {
      page: 1,
      form: {
        value: {},
        error: {},
      },
    };
  }

  componentDidMount() {
    this._onSetForm();
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState({ page: 2 });
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState({ page: 1 });
  }

  _onSubmit() {
    const { form } = this.state;
    const { handleManageAdmission, history } = this.props;
    handleManageAdmission(form.value, history.push);
  }

  render() {
    const { form, page } = this.state;
    return (
      <div className="manage-admission">
        <h1>Pendaftaran Peserta Didik Baru</h1>
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={page === 1 ? STUDENT_ADMISSION_FORM_FIELDS : PARENT_ADMISSION_FORM_FIELDS}
            onFormChange={this._onFormChange}
          />
          {
            page === 2 && (
              <div className="manage-admission__salary-form">
                <SalaryOption
                  type="text"
                  name="salaryAyah"
                  label="Gaji"
                  placeholder="Isi gaji"
                  onChange={this._onFormChange}
                  value={form.value.salaryAyah || ''}
                  error={form.error.salaryAyah || ''}
                />
                <SalaryOption
                  type="text"
                  name="salaryIbu"
                  label="Gaji"
                  placeholder="Isi gaji"
                  onChange={this._onFormChange}
                  value={form.value.salaryIbu || ''}
                  error={form.error.salaryIbu || ''}
                />
              </div>
            )
          }
          <div className="manage-admission__footer">
            {
              page === 1 ? (
                <div className="manage-admission__button-wrapper">
                  <div>
                    <Button
                      onClick={this._onClickNext}
                      title="Next"
                    />
                  </div>
                </div>
              ) : (
                <div className="manage-admission__button-wrapper">
                  <div>
                    <Button
                      onClick={this._onClickBack}
                      title="Kembali"
                    />
                  </div>
                  <div>
                    <Button
                      onClick={this._onSubmit}
                      title="Simpan"
                    />
                  </div>
                </div>
              )
            }
          </div>
        </form>
      </div>
    );
  }
}
ManageAdmission.propTypes = {
  handleManageAdmission: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
ManageAdmission.defaultProps = {
  handleManageAdmission: noop,
};
