import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea, Button } from '../../../components/base/index';
import { Link } from 'react-router-dom';
// import { Bar, Pie } from "react-chartjs-2";
// import 'chart.piecelabel.js';
// import 'chartjs-plugin-labels'; 


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);

        this.state ={
            chartData: {
                labels: ["test1", "test2", "test3", "test4", "test5", "test6"],
                datasets: [
                  {
                    label: "Population",
                    data: [617594, 181045, 153060, 106519, 105162, 95072],
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.6)",
                      "rgba(54, 162, 235, 0.6)",
                      "rgba(255, 206, 86, 0.6)",
                      "rgba(75, 192, 192, 0.6)",
                      "rgba(153, 102, 255, 0.6)",
                      "rgba(255, 159, 64, 0.6)",
                      "rgba(255, 99, 132, 0.6)"
                    ],
                    borderWith: 0,
                    borderColor: "#777",
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                      padding: {
                        top: 5,
                        left: 15,
                        right: 15,
                        bottom: 15
                      }
                    },
                  }
                ]
              }
        };
       
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
      }
    _onDeleteList() {
        const { onDeleteList, fieldName } = this.props;
        
      }

      _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
      }

render(){
    const {
        list, error, onChange,
        fieldName, plafond_name,onFormChange, form
      } = this.props;
  

  return (
        <div >
       
        
            {
                <div className="cetak-displiner__content">
                  
                  
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Unit</th>
                          <th>Jumlah</th>
                          <th>Chart Grafik</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {/* {
                        map(journal.details, (list, idx) => (
                          <tr key={`journal_detail_${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{list.code_of_account}</td>
                            <td>{list.parameter_code.title}</td>
                            <td>{list.description}</td>
                            <td className="nominal">{commaFormatted(list.amount)}</td>
                          </tr>
                        ))
                      } */}
                      <tr>
                          <td>1</td>
                          <td>abab</td>
                          <td>333</td>
                          <td></td>
                      </tr>
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                          <td className="nominal">{commaFormatted(journal.total)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                          <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL NETTO</td>
                          <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                  <div className="cetak-displiner__body">
                    <table className="cetak-displiner__table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Unit</th>
                          <th>Jumlah</th>
                          <th>Pie Grafik</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td>1</td>
                          <td>abab</td>
                          <td>333</td>
                          {/* <td><Pie
                                
                                data={this.state.chartData}
                                options ={{
                                    title:{
                                    display:false,
                                    text:"Test Pie",
                                    fontSize:25
                                    },
                                    legend:{
                                    display:false,
                                    position:"bottom"
                                    },
                                    pieceLabel: {
                                    render: 'percentage'
                                    }
                                }}
                            /></td> */}
                      </tr>
                        {/* {
                        map(journal.details, (list, idx) => (
                          <tr key={`journal_detail_${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{list.code_of_account}</td>
                            <td>{list.parameter_code.title}</td>
                            <td>{list.description}</td>
                            <td className="nominal">{commaFormatted(list.amount)}</td>
                          </tr>
                        ))
                      } */}
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                          <td className="nominal">{commaFormatted(journal.total)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                          <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="nominal">TOTAL NETTO</td>
                          <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                
                </div>
              
            }
          
        </div>
  );
     }
}
ComponentToPrint.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ComponentToPrint.defaultProps = {
  onFormChange: noop,
};
