import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class BobotNilai extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickIsiBobot = this.onClickIsiBobot.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickPsik = this.onClickPsik.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickMingguan = this.onClickMingguan.bind(this);
    this.onClickDaftarNilaiReport = this.onClickDaftarNilaiReport.bind(this);
  }

  onClickIsiBobot() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/bobot-penilaian/pengisian-bobot-penilaian');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickPsik() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-psik-afek');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }

  onClickMingguan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-mingguan');
  }

  onClickDaftarNilaiReport() {
    const {history} = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai/report');
  }

  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickIsiBobot}>
            <img src={budgeting} alt="kode" />
            <h3>Pengisian Bobot Nilai</h3>
          </button>
        </div>
        {/* <div className="finance__list">
          <button onClick={this.onClickNilaiKognitif}>
            <img src={budgeting} alt="NilaiKognitif" />
            <h3>Pengisian Nilai Kognitif</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPsik}>
            <img src={budgeting} alt="psikafek" />
            <h3>Pengisian Psik & Afek</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={budgeting} alt="observasiharian" />
            <h3>Pengisian Observasi Harian</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickMingguan}>
            <img src={budgeting} alt="observasimingguan" />
            <h3>Pengisian Observasi Mingguan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilaiReport}>
            <img src={budgeting} alt="daftarnilaireport"/>
            <h3>Daftar Nilai / Report</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
BobotNilai.propTypes = {
  history: PropTypes.object.isRequired,
};
