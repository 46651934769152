import { connect } from 'react-redux';
import ManageCashJournal from '../../../../views/Finance/Bookkeeping/CashJournal/ManageCashJournal.finance.view';
import {
  manageCashJournal, getCashJournalById, getTaxRecipientParam,
  calculateTax, getTaxPtkpParam, getAvailableFundRequests,
  deleteJournalItem,
} from '../../../../states/thunks/finance.thunk';
import { getJournalCoaOptions, getCoaOptions, getUnitOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    user: state.user || {},
    unit: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleDeleteJournalDetail: payload => dispatch(deleteJournalItem(payload)),
    handleGetAvailableFundRequests: payload => dispatch(getAvailableFundRequests(payload)),
    handleGetTaxPtkpParam: payload => dispatch(getTaxPtkpParam(payload)),
    handleGetTaxRecipientParam: payload => dispatch(getTaxRecipientParam(payload)),
    handleCalculateTax: payload => dispatch(calculateTax(payload)),
    handleGetJournalCoaOptions: payload => dispatch(getJournalCoaOptions(payload)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetCashJournal: payload => dispatch(getCashJournalById(payload)),
    handleManageCashJournal: (payload, goBack) => dispatch(manageCashJournal(payload, goBack)),
    handleGetUnitOptions: payload => dispatch(getUnitOptions({ show_pusat: true, ...payload })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCashJournal);
