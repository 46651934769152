import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function ParameterDeskForm(props) {


  const { onFormChange, form } = props;
  return (
    <div className="param-deskripsi__custom-form">
          <div className="param-deskripsi__header-title--column">
            <div className="param-deskripsi__header-title--bold">
            {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
            Parameter Deskripsi Otomatis
            </div>
            </div>
        <div className="param-deskripsi__custom-form-column2">
        <p>KOGN KOGNITIF/PENGETAHUAN/KI-3</p>
        <div className="param-deskripsi__custom-form-row">
         
        </div>  
        </div>
      <br></br>
      
      <div className="kode-matpel__custom-form">
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
          <Input
            type="text"
            name="cognitive_foreword"
            label="Kata Pembuka"
            onChange={onFormChange}
            value={form.value.cognitive_foreword || ''}
            error={form.error.cognitive_foreword || ''}
          />
        </div>
         
        
        <div className="kode-matpel__custom-form-column__field">
      
          
          <Input
            type="text"
            name="cognitive_conjunction"
            label="Kata Penyambung"
            onChange={onFormChange}
            value={form.value.cognitive_conjunction || ''}
            error={form.error.cognitive_conjunction || ''}
          />
       
         
        </div>
        </div>
    

       </div>

      
        <div className="param-deskripsi__custom-form-column2">
        <div className="param-deskripsi__custom-form-row">
        <p >PSIK</p> &nbsp; <p>PRAKTEK/KETERAMPILAN/KI 4</p>
        </div>
        <div className="param-deskripsi__custom-form-row">
          
        </div>  
        </div>
      <br></br>
      
      <div className="kode-matpel__custom-form">
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
         <Input
            type="text"
            name="psik_foreword"
            label="Kata Pembuka"
            onChange={onFormChange}
            value={form.value.psik_foreword || ''}
            error={form.error.psik_foreword || ''}
          />
        </div>
         
        
        <div className="kode-matpel__custom-form-column__field">
      
          
         <Input
            type="text"
            name="psik_conjunction"
            label="Kata Penyambung"
            onChange={onFormChange}
            value={form.value.psik_conjunction || ''}
            error={form.error.psik_conjunction || ''}
          />
       
         
        </div>
        </div>
    

       </div>
        </div>
        
 
   
  );
}
ParameterDeskForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
ParameterDeskForm.defaultProps = {
  onFormChange: noop,
};
