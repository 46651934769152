import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, noop, isEqual, capitalize } from "lodash";
import {
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { PENGATURAN_KELAS_PPDB_TABEL_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import { PENGATURAN_KENAIKAN_KELAS_TABEL_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import { PENGATURAN_SISWA_LULUS_TABEL_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import {
  Button,
  Select,
  Loading,
} from "../../../components/base";
import { Link } from "react-router-dom";
import { validateRequiredFields } from '../../../utils/validation.util';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengaturanKlsAjaranBaru extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitNextClass = this.onSubmitNextClass.bind(this);
    this.onSubmitGraduate = this.onSubmitGraduate.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
   
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;

    this.state = {
      content: initialContent,
      listAmount: 5,
      filters: {},
      param: {},
      form: {
        value: {
          organizations_id: organizations_id,
          units_id: school_unit.id,
          levels_id,
          pembagian_kelas: [
            {
              students_id: "",
              period: "",
              classrooms_id: "",
              date: "",
            },
          ],
        },
        error: {
          pembagian_kelas: "",
        },
      },
    };
  }

  componentDidMount() {
    const { location, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { content } = this.state;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const paramTypes = ["classrooms", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPeriodsOptions();
    this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  // on formchange untuk form
  _onFormChange(event) {
    const { user } = this.props;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: organizations_id,
          });
        }
        if (name === "classrooms_id") {
          this._onSearchContent();
        }
        if (name == "class_div"){
          this._onSearchContent();
        }
      }
    );
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { class_div, classes_id, periods_id } = value;
    const { classrooms_id } = filters;
    try {
      const { handleListPPDBApprove, handleListApproveNextClass, handleListApproveGraduate, user } = this.props;
      const { organizations_id, school_unit } = user;
      let result = {};
      if (class_div === undefined) {
        result = await handleListPPDBApprove({
          ...params,
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
          classrooms_id: value.classrooms_id,
        });
      } else if (class_div === 'ppdb'){
        result = await handleListPPDBApprove({
          ...params,
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters,
          classrooms_id: value.classrooms_id,
        });
      } else if (class_div === 'next_class'){
        result = await handleListApproveNextClass({
          ...params,
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters: {period: periods_id, classrooms_id: classrooms_id},
        });
      } else if (class_div === 'lulus'){
        result = await handleListApproveGraduate({
          ...params,
          organizations_id: organizations_id,
          units_id: school_unit.id,
          filters: {period: periods_id, classrooms_id: classrooms_id},
        });
      } 
      
      const { classes = [], data = [] } = result;
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pembagian_kelas: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            pembagian_kelas: [],
          },
        },
      }));
    }
    // }
  }

  async onClickDelete(id, class_div_id) {
    const { handledeletePpdbSetting } = this.props;
    const res = await handledeletePpdbSetting(id, class_div_id);
    if(res){
      this._onSearchContent();
    }
  }

  async onSubmit() {
    const { history, handleApproveClassDivPPDB } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['periods_id', 'levels_id']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      await handleApproveClassDivPPDB(value);
      history.push(
        "/dashboard/administrasi/data-kelas/pengaturan-kelas-ajaran-baru"
      );
    }
  }

  async onSubmitNextClass() {
    const { history, handleApproveNextClass } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['periods_id', 'levels_id']);
    
    if (isEmpty(error)) {
      await handleApproveNextClass(value);
      history.push(
        "/dashboard/administrasi/data-kelas/pengaturan-kelas-ajaran-baru"
      );
    }
  }

  async onSubmitGraduate() {
    const { history, handleApproveGraduations } = this.props;
    const { form } = this.state;
    const { value } = form;
    const error = validateRequiredFields(form.value, ['periods_id', 'levels_id']);
    
    if (isEmpty(error)) {
      await handleApproveGraduations(value);
      history.push(
        "/dashboard/administrasi/data-kelas/pengaturan-kelas-ajaran-baru"
      );
    }
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)

      if (date > start_date) {
        // tahun ajaran depan
        if (res[idx+1]) {
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              year: (res[idx+1].label.substring(0, 4)).substring(2),
            },
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                periods_id : res[idx+1].value,
                entrance_year: (res[idx+1].label.substring(0, 4)),
              }
            }
          }));
        }
       
        
      }
    })

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;

      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return(
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickDelete(data)} title="Hapus" />  
        </div>
      </td>
    );
  }

  render() {
    const { form, content, filters, listAmount, list = {}, param } = this.state;
    const { pembagian_kelas } = form.value;
    const { classrooms_id = "" } = filters;
    const class_div = [{label: 'Pembagian Kelas Siswa PPDB', value: 'ppdb'},
                       {label: 'Pembagian Kelas Siswa Kenaikan Kelas', value: 'next_class'},
                       {label: 'Siswa Lulus', value: 'lulus'}];
    // var nextyear = new Date().getFullYear() + 1;
    // var month = new Date().getMonth() + 1;
    // if (month < 7) {
    //   nextyear -= 1;
    // }

    // map(param.periods, (data, idx) => {
    //   var year = data.label.substring(0, 4);
    //   if (year == nextyear) {
    //     form.value.periods_id = data.value;
    //   }
    // });

    return (
      <div className="add-budget">
        <div className="cash-journal__content">
          <div className="content-table">
            <div className="table-filters">
              <Select
                name="periods_id"
                label="Tahun Ajaran"
                onChange={this._onFormChange}
                value={form.value.periods_id}
                error={form.error.periods_id || ""}
                placeholder={"Pilihan"}
                data={param.periods}
                disabled
              />
              <Select
                name="class_div"
                label="Pilih Pembagian Kelas"
                onChange={this._onFormChange}
                data={class_div}
                placeholder={"Pilihan"}
                value={form.value.class_div}
                error={form.error.class_div || ""}
              />
              {(form.value.class_div != "lulus") && (
                <Select
                  placeholder="Pilih Kelas"
                  label="Kelas"
                  name="classrooms_id"
                  value={classrooms_id}
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                />
              )}
            </div>
            {form.value.class_div === 'ppdb' && (
              <>
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(PENGATURAN_KELAS_PPDB_TABEL_FIELDS.label, (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {map(pembagian_kelas, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          PENGATURAN_KELAS_PPDB_TABEL_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "homeroom_teacher") {
                              return (
                                <td>
                                  {list.homeroom_teacher
                                    ? list.homeroom_teacher
                                    : list.homeroom_teacher_name2}
                                </td>
                              );
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <Link
                                    to={
                                      "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                                    }
                                  >
                                    {list.name}
                                  </Link>
                                </td>
                              );
                            }
                            if(field.attribute === "nisn") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {capitalize(list.nisn)}
                                </td>
                              )
                            }
                            if(field.attribute === "no_va") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {capitalize(list.no_va)}
                                </td>
                              )
                            }
                            if (field.attribute === "fullname") {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                                  {capitalize(list.fullname)}
                                </td>
                              );
                            }
                            return (
                              <td
                                className={
                                  field.type === "number" ? "nominal" : ""
                                }
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(list, field)}
                              </td>
                            );
                          }
                        )}
                        {this._renderButtons(list)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="student-list__button">
                {/* {(() => {
                  if (month == 6) {
                    return ( */}
                      <Button type="button" title="Posting" onClick={this.onSubmit} />
                    {/* )
                  } else {
                    return (
                      <p style={{fontStyle: 'italic', fontWeight:'bold'}}>Data Siswa akan masuk otomatis disaat awal Tahun Ajaran Baru</p>
                    
                })()} */}
              </div>
              </>
             )}
             {form.value.class_div === 'next_class' && (
              <>
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(PENGATURAN_KENAIKAN_KELAS_TABEL_FIELDS.label, (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {map(pembagian_kelas, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          PENGATURAN_KENAIKAN_KELAS_TABEL_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "homeroom_teacher") {
                              return (
                                <td>
                                  {list.homeroom_teacher
                                    ? list.homeroom_teacher
                                    : list.homeroom_teacher_name2}
                                </td>
                              );
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <Link
                                    to={
                                      "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                                    }
                                  >
                                    {list.name}
                                  </Link>
                                </td>
                              );
                            }
                            if(field.attribute === "nisn") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {capitalize(list.nisn)}
                                </td>
                              )
                            }
                            if(field.attribute === "no_va") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {capitalize(list.no_va)}
                                </td>
                              )
                            }
                            if (field.attribute === "fullname") {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                                  {capitalize(list.fullname)}
                                </td>
                              );
                            }
                            return (
                              <td
                                className={
                                  field.type === "number" ? "nominal" : ""
                                }
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(list, field)}
                              </td>
                            );
                          }
                        )}
                        {/* {this._renderButtons(list)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="student-list__button">
               
                <Button type="button" title="Posting" onClick={this.onSubmitNextClass} />
                  
              </div>
              </>
             )}
             {form.value.class_div === 'lulus' && (
              <>
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(PENGATURAN_SISWA_LULUS_TABEL_FIELDS.label, (field, idx) => (
                        <th key={`table_th_${idx}`}>{field}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {map(pembagian_kelas, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        {map(
                          PENGATURAN_SISWA_LULUS_TABEL_FIELDS.value,
                          (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {idx + 1}
                                </td>
                              );
                            }
                            if (field.attribute === "homeroom_teacher") {
                              return (
                                <td>
                                  {list.homeroom_teacher
                                    ? list.homeroom_teacher
                                    : list.homeroom_teacher_name2}
                                </td>
                              );
                            }
                            if (field.type === "link") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}>
                                  <Link
                                    to={
                                      "/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul"
                                    }
                                  >
                                    {list.name}
                                  </Link>
                                </td>
                              );
                            }
                            if(field.attribute === "nisn") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {capitalize(list.nisn)}
                                </td>
                              )
                            }
                            if(field.attribute === "no_va") {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>
                                  {capitalize(list.no_va)}
                                </td>
                              )
                            }
                            if (field.attribute === "fullname") {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`} style={{textTransform:"capitalize"}}>
                                  {capitalize(list.fullname)}
                                </td>
                              );
                            }
                            return (
                              <td
                                className={
                                  field.type === "number" ? "nominal" : ""
                                }
                                key={`table_${fieldIdx}_${idx}`}
                              >
                                {formatData(list, field)}
                              </td>
                            );
                          }
                        )}
                        {/* {this._renderButtons(list)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="student-list__button">
                <Button type="button" title="Posting" onClick={this.onSubmitGraduate} />
              </div>
              </>
             )}
            </div>
        </div>
      </div>
    );
  }
}
PengaturanKlsAjaranBaru.propTypes = {
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
PengaturanKlsAjaranBaru.defaultProps = {
  handleGetclassrooms: noop,
  user: null,
};
