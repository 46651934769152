import { connect } from 'react-redux';
import Pelanggaran from '../../../../views/Student/displiner/cetak/Pelanggaran.student.view';
import { getStudent } from '../../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleGetStudent: () => dispatch(getStudent()),
  };
}

export default connect(null, mapDispatchToProps)(Pelanggaran);


