import { connect } from 'react-redux';
import BuatGroupPrestasi from '../../../views/Student/displiner/BuatGroupPrestasi.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import {listStudentAttendance, listAchievementGroup, deleteAchievementGroup, saveAchievementGroup, editAchievementGroup, getAchievementGroup } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetAchievementGroup: payload => dispatch(getAchievementGroup(payload)),
    handleSaveAchievementGroup: (payload, goback) => dispatch(saveAchievementGroup(payload, goback)),
    handleListAchievementGroup: (payload, goback) => dispatch(listAchievementGroup(payload, goback)),
    handleEditAchievementGroup: (payload, goback) => dispatch(editAchievementGroup(payload, goback)), 
    handleDeleteAchievementGroup: (payload, goback) => dispatch(deleteAchievementGroup(payload, goback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatGroupPrestasi);


