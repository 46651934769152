import { connect } from 'react-redux';
import CatatanAnekdot from '../../../views/Kurikulum/Ulangan/CatatanAnekdot.view';
import { listTema, deleteTema, saveTema, editTema, getTema } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
   
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatanAnekdot);


